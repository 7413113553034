/** @format */

import React from 'react';
import cx from 'classnames';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';

import FilterOperationInstructionDescription from 'components/flowGraph/instructionDescriptions/filterOperationInstructionDescription';
import ChangeSchemaOperationInstructionDescription from 'components/flowGraph/instructionDescriptions/changeSchemaOperationInstructionDescription';
import SortOperationInstructionDescription from 'components/flowGraph/instructionDescriptions/sortOperationInstructionDescription';
import JoinOperationInstructionDescription from 'components/flowGraph/instructionDescriptions/joinOperationInstructionDescription';
import UnionOperationInstructionDescription from 'components/flowGraph/instructionDescriptions/unionOperationInstructionDescription';
import PivotOperationInstructionDescription from 'components/flowGraph/instructionDescriptions/pivotOperationInstructionDescription';
import CalculateOperationInstructionDescription from 'components/flowGraph/instructionDescriptions/calculateOperationInstructionDescription';
import FormulaOperationInstructionDescription from 'components/flowGraph/instructionDescriptions/formulaOperationInstructionDescription';
import DedupOperationInstructionDescription from 'components/flowGraph/instructionDescriptions/dedupOperationInstructionDescription';
import AxisPivotChartInstructionDescription from 'components/flowGraph/instructionDescriptions/axisPivotChartInstructionDescription';

import { OPERATIONS_BY_ID } from 'constants/flowConstants';
import { OperationInstructions } from 'constants/types';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'baseline',
      display: 'flex',
      flexWrap: 'wrap',
    },
  });

export type InstructionComponentProps<T> = {
  operationId: string;
  instructions: T;
};

type PassedProps = {
  className?: string;
  operationId: string;
  operationType: string;
  instructions: OperationInstructions;
};

type Props = PassedProps & WithStyles<typeof styles>;

class OperationInstructionDescription extends React.Component<Props> {
  render() {
    const { classes, className, instructions, operationId } = this.props;

    const InstructionComponent = this.getInstructionComponent();

    return (
      <div className={cx(classes.root, className)}>
        <InstructionComponent instructions={instructions} operationId={operationId} />
      </div>
    );
  }

  getInstructionComponent = () => {
    const { operationType } = this.props;

    switch (operationType) {
      case OPERATIONS_BY_ID.FILTER.id:
        return FilterOperationInstructionDescription;
      case OPERATIONS_BY_ID.CHANGE_SCHEMA.id:
        return ChangeSchemaOperationInstructionDescription;
      case OPERATIONS_BY_ID.SORT.id:
        return SortOperationInstructionDescription;
      case OPERATIONS_BY_ID.ENRICH.id:
        return JoinOperationInstructionDescription;
      case OPERATIONS_BY_ID.UNION.id:
        return UnionOperationInstructionDescription;
      case OPERATIONS_BY_ID.PIVOT.id:
        return PivotOperationInstructionDescription;
      case OPERATIONS_BY_ID.CALCULATE.id:
        return CalculateOperationInstructionDescription;
      case OPERATIONS_BY_ID.FORMULA.id:
        return FormulaOperationInstructionDescription;
      case OPERATIONS_BY_ID.DEDUP.id:
        return DedupOperationInstructionDescription;
      case OPERATIONS_BY_ID.AXIS_PIVOT_CHART.id:
        return AxisPivotChartInstructionDescription;
      default:
        throw new Error('Unsupported operation type to render on graph: ' + operationType);
    }
  };
}

export default withStyles(styles)(OperationInstructionDescription);

/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import {
  Cell,
  Column,
  ColumnHeaderCell,
  SelectionModes,
  Table,
  TableLoadingOption,
  IColumnHeaderRenderer,
} from '@blueprintjs/table';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Schema } from 'constants/types';

const LOADING_OPTIONS = [TableLoadingOption.CELLS, TableLoadingOption.COLUMN_HEADERS];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 4,
    overflow: 'hidden',
    height: 277,
  },
  noBorderRadius: {
    borderRadius: 0,
  },
}));

type Props = {
  className?: any;
  headerList: Schema;
  loading?: boolean;
  maxRows: number;
  rows: any;
  selectedColumns?: any;
  disableRowHeader?: boolean;
  extractCellData?: (rowIndex: number, colIndex: number) => string | number;
  renderCustomeColumnHeaderCell?: (
    columnName: string,
    renderColumnHeaderTextFunction: any,
  ) => IColumnHeaderRenderer;
  preventTimeStampCasting?: boolean;
  grayedColumns?: Set<string>;
  redColumns?: Set<string>;
  fill?: boolean;
  noBorderRadius?: boolean;
};

const LoadingDataTable = (props: Props) => {
  const { loading, noBorderRadius } = props;
  const classes = useStyles();

  const cellRenderer = (rowIndex: number, colIndex: number) => {
    return <Cell></Cell>;
  };

  const headers = Array.from(Array(20).keys());
  const columns = headers.map((headerName: number, index: number) => {
    return (
      <Column
        cellRenderer={cellRenderer}
        columnHeaderCellRenderer={() => <ColumnHeaderCell name={headerName.toString()} />}
        key={index}
        name={headerName.toString()}
      />
    );
  });

  return (
    <div
      className={cx(classes.root, props.className, {
        [classes.noBorderRadius]: noBorderRadius,
      })}>
      <Table
        numRows={props.maxRows}
        selectionModes={SelectionModes.ALL}
        loadingOptions={loading ? LOADING_OPTIONS : []}>
        {columns}
      </Table>
    </div>
  );
};

export default LoadingDataTable;

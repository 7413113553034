/** @format */

import React from 'react';
import _ from 'underscore';
import { withStyles, WithStyles } from '@material-ui/styles';

import ClauseBuilder from 'components/flowBuilder/clauseBuilder';
import ChangeSchemaEditOperationBody from 'components/flowBuilder/editOperationDrawer/changeSchemaEditOperationBody';
import StepBlockTag from 'components/flowBuilder/stepBlockTag';
import { schemaInstructionsChanged, schemaClauseInstructionsComplete } from 'utils/flowUtils';
import { trackEvent } from 'analytics/exploAnalytics';

import { SCHEMA_DATA_TYPES_BY_ID } from 'constants/flowConstants';
import { ChangeSchemaOperationInstructions, SchemaChange } from 'constants/types';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Dataset } from 'actions/types';

const styles = (theme: Theme) => ({
  fillerText: {
    fontSize: 16,
    fontWeight: 400,
  },
});

type PassedProps = {
  clearRecentlyAddedOpId: () => {
    type: string;
  };
  computeLoading?: boolean;
  instructions: ChangeSchemaOperationInstructions;
  openFlyout?: boolean;
  operationId: number;
  scrollToColumn: (columnName: string) => void;
  sourceDataset: Dataset;
  updateOperationInstructionAndCompute: (
    operationId: number,
    newInstructions: any,
    shouldCompute: boolean,
  ) => void;
  summaryView?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  editDrawerOpen: boolean;
};

class ChangeSchemaOperationClauseBuilder extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    if (props.openFlyout) {
      props.clearRecentlyAddedOpId();
    }

    this.state = {
      editDrawerOpen: props.openFlyout || false,
    };
  }

  render() {
    const { computeLoading, instructions, summaryView } = this.props;

    return (
      <ClauseBuilder
        computeLoading={computeLoading}
        editDrawerOpen={this.state.editDrawerOpen}
        openDrawer={() => this.setState({ editDrawerOpen: true })}
        closeDrawer={() => this.setState({ editDrawerOpen: false })}
        editOperationDrawerTitle="Edit the columns"
        tooltipSuggestionIsOpen={instructions.changeSchemaList.length === 0}
        editOperationBody={this.renderOperationEditBody()}
        clauseText={this.renderSchemaText()}
        summaryView={summaryView}
      />
    );
  }

  renderSchemaText = (): JSX.Element[] | null => {
    const { classes, operationId, instructions, scrollToColumn } = this.props;

    // Ignore the changes in changeSchemaList that have default values.
    const completeClauses = instructions.changeSchemaList.filter(schemaClauseInstructionsComplete);

    const clauses = [
      <div className={classes.fillerText} key={`filter_where_${operationId}`}>
        where
      </div>,
    ];
    _.each(completeClauses, (clause, index) => {
      const wasDeleted = 'keepCol' in clause && clause['keepCol'] === false;
      clauses.push(
        <StepBlockTag
          key={`clause_${index}_${operationId}`}
          interactive={!wasDeleted}
          onClick={() => !wasDeleted && scrollToColumn(clause['newColName'] || clause['col'])}
          content={this.formatSingleDisplayText(clause)}
        />,
      );

      if (index !== completeClauses.length - 1) {
        clauses.push(
          <div className={classes.fillerText} key={`clause_${index}_${operationId}_and`}>
            and
          </div>,
        );
      }
    });

    return clauses.length > 1 ? clauses : null; // if no clauses were added, don't return anything
  };

  formatSingleDisplayText = (clause: SchemaChange) => {
    if ('keepCol' in clause && clause['keepCol'] === false) {
      return `${clause['col']} was deleted`;
    }

    let output = `${clause['col']} was `;

    let hasNewType = false;
    if ('newType' in clause && clause['newType']) {
      hasNewType = true;
      const type = SCHEMA_DATA_TYPES_BY_ID[clause['newType']].name;
      output += `converted to a ${type}`;
    }

    if ('newColName' in clause && clause['newColName']) {
      const maybeAndSign = hasNewType ? ' & ' : '';
      output += `${maybeAndSign}renamed to ${clause['newColName']}`;
    }

    return output;
  };

  renderOperationEditBody = () => {
    const {
      updateOperationInstructionAndCompute,
      sourceDataset,
      instructions,
      operationId,
    } = this.props;

    return (
      <ChangeSchemaEditOperationBody
        operationId={operationId}
        instructions={instructions}
        sourceDataset={sourceDataset}
        onSubmit={(newInstructions: ChangeSchemaOperationInstructions) => {
          const instructionHasChanged = schemaInstructionsChanged(instructions, newInstructions);

          updateOperationInstructionAndCompute(operationId, newInstructions, instructionHasChanged);
          this.setState({ editDrawerOpen: false });
          trackEvent('Update and compute schema change', {
            operation_id: operationId,
            new_instructions: newInstructions,
            filter_instructions_changed: instructionHasChanged,
          });
        }}
      />
    );
  };
}

export default withStyles(styles)(ChangeSchemaOperationClauseBuilder);

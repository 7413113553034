/** @format */

import React, { ReactNode } from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { AppToaster } from 'toaster';
import { Intent } from '@blueprintjs/core';

import StarParallaxBg from 'components/marketing/starParallaxBg';
import NavHeader from 'components/marketing/navHeader';
import SignupHero from 'components/marketing/signupHero';
import EcommLogos from 'components/marketing/ecommLogos';
import EcommBenefit from 'components/marketing/ecommBenefit';
import SignupFooter from 'components/marketing/signupFooter';
import Footer from 'components/marketing/footer';

import { HEADER_PAGES } from 'components/marketing/navHeader';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.black100,
    height: '100vh',
    padding: `0 ${theme.spacing(7)}px`,
    display: 'flex',
    justifyContent: 'center',
    fontFamily: "'Inter', sans-serif",
    perspective: '1px',
    overflowY: 'auto',
  },
  container: {
    width: '100%',
    maxWidth: 1040,
    zIndex: 2,
  },
  ecommLogos: {
    marginTop: 200,

    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
    },
  },
  demoVideoContainer: {
    width: '100%',
    marginTop: 160,
    marginBottom: 120,

    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
    },
  },
  ecommBenefitsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: theme.spacing(17),
    gridRowGap: theme.spacing(17),

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  signupBanner: {
    marginTop: 160,

    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
    },
  },
  signupFooter: {
    marginTop: 160,

    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
    },
  },
  footer: {
    marginTop: 120,
    marginBottom: 40,
  },
}));

type Props = {
  className?: string;
  children: ReactNode;
};

const HomePage = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <StarParallaxBg />

      <div className={classes.container}>
        <NavHeader selectedHeaderPage={HEADER_PAGES.USE_CASES} />
        <SignupHero
          title1="Explo for "
          title2="marketing teams"
          description="The easiest way to analyze all your marketing data without reliance on data teams. Do all your ad hoc analysis in minutes."
          onSignupSuccess={renderSuccessSignUpToast}
          onSignupFail={renderFailedSignUpToast}
          noSignup
        />
        <EcommLogos className={classes.ecommLogos} />
        <DemoVideo />
        <div className={classes.ecommBenefitsContainer}>
          <EcommBenefit
            title1="Discover "
            title2="loyal customers"
            description="Easily create cohorts and lists of customers for targeted ads."
          />

          <EcommBenefit
            title1="Identify "
            title2="trends"
            description="Perform quick ad hoc analysis without the limitations of prebuilt dashboards."
          />

          <EcommBenefit
            title1="Measure "
            title2="conversion"
            description="Track key metrics on dashboards and share with team member or external stakeholders."
          />
        </div>

        <SignupFooter
          className={classes.signupBanner}
          onSignupSuccess={renderSuccessSignUpToast}
          onSignupFail={renderFailedSignUpToast}
        />
        <Footer className={classes.footer} />
      </div>
    </div>
  );
};

const DemoVideo = () => {
  const classes = useStyles();

  return (
    <video className={classes.demoVideoContainer} loop controls>
      <source
        src="https://explo-marketing-material.s3-us-west-1.amazonaws.com/Ecom+Demo+(3+August%2C+2020).mp4"
        type="video/mp4"
      />
    </video>
  );
};

const renderSuccessSignUpToast = (email: string): void => {
  AppToaster.show({
    message: `Thanks for signing up, ${email}.  We'll be in touch shortly!`,
    icon: 'tick-circle',
    timeout: 5000,
    intent: Intent.SUCCESS,
  });
};

const renderFailedSignUpToast = (msg: string): void => {
  AppToaster.show({
    message: msg,
    icon: 'issue',
    timeout: 5000,
    intent: Intent.DANGER,
  });
};

export default HomePage;

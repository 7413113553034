/** @format */

import React, { ReactNode, useEffect } from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { AppToaster } from 'toaster';
import { Intent } from '@blueprintjs/core';

import StarParallaxBg from 'components/marketing/starParallaxBg';
import NavHeader from 'components/marketing/navHeader';
import SignupHero from 'components/marketing/signupHero';
import ProductFeatureSection from 'components/marketing/productFeatureSection';
import IntegrateSourcesSection from 'components/marketing/integrateSourcesSection';
import MidPageSignupBanner from 'components/marketing/midPageSignupBanner';
import FeatureListSection from 'components/marketing/featureListSection';
import FaqSection from 'components/marketing/faqSection';
import SignupFooter from 'components/marketing/signupFooter';
import Footer from 'components/marketing/footer';

import HeroImg from 'images/marketing/landing_page_explo.png';

import { pageView } from 'analytics/exploAnalytics';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.black100,
    height: '100vh',
    padding: `0 ${theme.spacing(7)}px`,
    display: 'flex',
    justifyContent: 'center',
    fontFamily: "'Inter', sans-serif",
    perspective: '1px',
    overflowY: 'auto',
  },
  container: {
    width: '100%',
    maxWidth: 1040,
    zIndex: 2,
    transform: 'translateZ(0)',
  },
  demoVideoContainer: {
    width: '100%',
    marginTop: 200,
    marginBottom: 60,
    position: 'relative',

    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
      marginBottom: 20,
    },
  },
  heroImg: {
    width: '100%',
  },
  productFeatureSection: {
    marginTop: 100,

    [theme.breakpoints.down('xs')]: {
      marginTop: 50,
    },
  },
  prepareDataSection: {
    position: 'absolute',
    right: 0,
    top: '55.5%',
    width: '45%',
  },
  analyzeDataSection: {
    position: 'absolute',
    right: 0,
    top: '69.5%',
    width: '45%',
  },
  visualizeDataSection: {
    position: 'absolute',
    right: 0,
    top: '86.5%',
    width: '45%',
  },
  integrationSourcesSection: {
    marginTop: 160,

    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
    },
  },
  featureListSection: {
    marginTop: 160,

    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
    },
  },
  faqSection: {
    marginTop: 160,

    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
    },
  },
  signupFooter: {
    marginTop: 160,

    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
    },
  },
  footer: {
    marginTop: 120,
    marginBottom: 40,
  },
}));

type Props = {
  className?: string;
  children: ReactNode;
};

const HomePage = (props: Props) => {
  const classes = useStyles();

  useEffect(() => {
    pageView('Landing Page');
  });

  return (
    <div className={classes.root}>
      <StarParallaxBg />

      <div className={classes.container}>
        <NavHeader />
        <SignupHero
          caption="Limited Beta Release"
          title1="Explore your data "
          title2="without code"
          description="Explo enables you to better understand your data and discover new insights with a simple point-and-click interface. You'll have the power of SQL while connecting directly to your data sources."
          onSignupSuccess={renderSuccessSignUpToast}
          onSignupFail={renderFailedSignUpToast}
        />
        <ExploDemoImg />
        <IntegrateSourcesSection className={classes.integrationSourcesSection} />
        <MidPageSignupBanner
          calltoActionText="Start analyzing aross your data sources"
          btnText="Request Early Access"
          onSignupSuccess={renderSuccessSignUpToast}
          onSignupFail={renderFailedSignUpToast}
        />
        <FeatureListSection className={classes.featureListSection} />
        <FaqSection className={classes.faqSection} />
        <SignupFooter
          className={classes.signupFooter}
          onSignupSuccess={renderSuccessSignUpToast}
          onSignupFail={renderFailedSignUpToast}
        />
        <Footer className={classes.footer} />
      </div>
    </div>
  );
};

const ExploDemoImg = () => {
  const classes = useStyles();

  return (
    <div className={classes.demoVideoContainer}>
      <img className={classes.heroImg} src={HeroImg} alt="large img" />
      <ProductFeatureSection
        className={classes.prepareDataSection}
        titleBeginning="Prepare"
        titleEnd=" your data"
        description="Save time combining and wrangling your datasets."
        hideDescOnXs
      />
      <ProductFeatureSection
        className={classes.analyzeDataSection}
        titleBeginning="Analyze"
        titleEnd=" your data"
        description="Discover new insights without relying on data teams."
        hideDescOnXs
      />
      <ProductFeatureSection
        className={classes.visualizeDataSection}
        titleBeginning="Visualize"
        titleEnd=" your data"
        description="Bring your data to life and share results with your team."
        hideDescOnXs
      />
    </div>
  );
};

const renderSuccessSignUpToast = (email: string): void => {
  AppToaster.show({
    message: `Thanks for signing up, ${email}.  We'll be in touch shortly!`,
    icon: 'tick-circle',
    timeout: 5000,
    intent: Intent.SUCCESS,
  });
};

const renderFailedSignUpToast = (msg: string): void => {
  AppToaster.show({
    message: msg,
    icon: 'issue',
    timeout: 5000,
    intent: Intent.DANGER,
  });
};

export default HomePage;

/** @format */

import { DropResult } from 'react-beautiful-dnd';
import { FlowReducerState } from 'reducers/flowReducer';
import {
  FlowGraph,
  NodeMap,
  NODE_TYPES,
  NODE_POSITIONS,
  SelectedNode,
} from 'pages/flowGraphPage/flowGraphPageV3';
import { Operation, DatasetFlowNode, Dataset, Flow } from 'actions/types';
import _ from 'underscore';
import { AppToaster } from 'toaster';
import { Intent } from '@blueprintjs/core';
import { ActionFn } from 'actions/actionUtils';
import { array_move } from 'utils/general';
import {
  instructionReadyToCompute,
  filterClauseComplete,
  sortClauseComplete,
  formulaClauseComplete,
  dedupClauseComplete,
  schemaClauseInstructionsComplete,
} from 'utils/flowUtils';
import { useEffect } from 'react';
import {
  OPERATION_TYPES,
  OPERATIONS_BY_ID,
  BASE_OP_INSTRUCTION_BY_TYPE,
  GRAPH_OPERATIONS_SET,
} from 'constants/flowConstants';
import {
  FilterClause,
  SortClause,
  AggregationColumnInfo,
  OperationInstructions,
} from 'constants/types';
import { BACKGROUND_CANVAS } from './flowGraphContent';
import { trackEvent } from 'analytics/exploAnalytics';

export const isDatasetFlowNode = (node: Operation | DatasetFlowNode): node is DatasetFlowNode => {
  return (node as DatasetFlowNode).operation_nodes !== undefined;
};

export const deriveFlowGraphFromFlowData = (flowData: FlowReducerState): FlowGraph => {
  // Recursively assign a source dataset for children of dataset nodes
  flowData.datasetNodes.forEach((datasetNode: DatasetFlowNode) => {
    datasetNode.operation_nodes.forEach((childOperation: { id: number }) => {
      assignSourceDatasetForOperationNodes(
        childOperation.id.toString(),
        flowData.flowOperations,
        datasetNode.dataset,
      );
    });
  });

  return {
    datasetFlowNodes: flowData.datasetNodes,
    nodeMap: flowData.flowOperations,
  };
};

export const assignSourceDatasetForOperationNodes = (
  operationId: string,
  nodeMap: NodeMap,
  sourceDataset: Dataset,
) => {
  const operation = nodeMap[operationId];
  operation.sourceDataset = sourceDataset;
  const childrenSourceDataset =
    GRAPH_OPERATIONS_SET.has(operation.operation_type as string) || !operation.results_dataset
      ? sourceDataset
      : operation.results_dataset;

  // Each child node has the source dataset of the closest parent with a source dataset
  operation.child_operations.forEach((childOperation: { id: number }) => {
    assignSourceDatasetForOperationNodes(
      childOperation.id.toString(),
      nodeMap,
      childrenSourceDataset,
    );
  });
};

const updateNodePosition = (
  dropResult: DropResult,
  nodeType: NODE_TYPES,
  flowId: string,
  updateFlowNodePos: ActionFn,
) => {
  updateFlowNodePos(
    {
      id: flowId,
      postData: {
        node_type: nodeType,
        node_id: dropResult.draggableId,
        new_order: dropResult.destination!.index,
      },
    },
    undefined,
    () => {
      AppToaster.show({
        message: 'There was an error positioning the flow node, please contact Explo support.',
        icon: 'error',
        timeout: 5000,
        intent: Intent.DANGER,
      });
    },
  );
};

// will revise...
export const onTreeDragEnd = (
  dropResult: DropResult,
  flowGraph: FlowGraph,
  updateFlowNodePos: ActionFn,
  flowId: string,
) => {
  if (!dropResult.destination) return;
  array_move(flowGraph.datasetFlowNodes, dropResult.source.index, dropResult.destination!.index);
  updateNodePosition(dropResult, NODE_TYPES.DATASET_FLOW_NODE, flowId, updateFlowNodePos);
};

// set FlowGraph and update backend
export const onBranchDragEnd = (
  dropResult: DropResult,
  flowGraph: FlowGraph,
  updateFlowNodePos: ActionFn,
  flowId: string,
) => {
  if (!dropResult.destination) return;
  const parentTypeAndId = dropResult.destination.droppableId.split('-');
  const parentType = parentTypeAndId[0];
  const parentId = parentTypeAndId[1];
  switch (parentType) {
    case NODE_TYPES.DATASET_FLOW_NODE:
      const datasetFlowNode = _.findWhere(flowGraph.datasetFlowNodes, { id: parseInt(parentId) });
      array_move(
        datasetFlowNode!.operation_nodes,
        dropResult.source.index,
        dropResult.destination!.index,
      );
      break;
    case NODE_TYPES.OPERATION:
      const operation = flowGraph.nodeMap![parentId];
      array_move(
        operation.child_operations,
        dropResult.source.index,
        dropResult.destination!.index,
      );
      break;
    default:
      throw new Error('Node drop parent unrecognized');
  }
  updateNodePosition(dropResult, NODE_TYPES.OPERATION, flowId, updateFlowNodePos);
};

// will be a use for this later..
export const getListStyle = (isDraggingOver: boolean) => ({});

export const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  ...draggableStyle,
});

export const updateOperationInstructionAndCompute = (
  operationId: number,
  newInstructions: any,
  shouldCompute: boolean,
  flowData: FlowReducerState,
  updateFlowOperation: any,
  fetchOperationRowCount: ActionFn,
  computeExistingStepPreview: any,
) => {
  const operation = flowData.flowOperations[operationId];
  operation.instructions = newInstructions;
  updateFlowOperation(operationId, operation, () => {
    shouldCompute &&
      computeCurrentStepPreview(
        operationId,
        flowData,
        fetchOperationRowCount,
        computeExistingStepPreview,
      );
  });
};

const computeCurrentStepPreview = (
  operationId: number,
  flowData: FlowReducerState,
  fetchOperationRowCount: ActionFn,
  computeExistingStepPreview: any,
) => {
  const operation = flowData.flowOperations[operationId];
  if (!operation) {
    return;
  }

  if (!instructionReadyToCompute(operation['instructions'], operation['operation_type'])) {
    AppToaster.show({
      message:
        'This operation is not fully configured. Please edit the configuration and then try again.',
      intent: Intent.WARNING,
      timeout: 5000,
    });
    return;
  }

  computeExistingStepPreview(
    operationId,
    false,
    (response: any) => {
      if (response.operation.results_dataset.total_row_count === null) {
        fetchOperationRowCount({ id: operationId });
      }
    },
    (outdatedOps: number[]) => {
      if (outdatedOps.length > 0) {
        AppToaster.show({
          message: 'An operation was changed. Some operations are now out-of-date.',
          intent: Intent.WARNING,
          timeout: -1,
          action: {
            icon: 'refresh',
            text: 'Update',
            onClick: () => {
              // outdatedOps.forEach((opId) => {
              //   if (opId in flowData.flowOperations) {
              //     const operation = flowData.flowOperations[opId];
              //     instructionReadyToCompute(
              //       operation['instructions'],
              //       operation['operation_type'],
              //     ) && computeExistingStepPreview(opId, true, undefined);
              //   }
              // });
              outdatedOps.forEach((opId) => {
                if (opId in flowData.flowOperations) {
                  const operation = flowData.flowOperations[opId];
                  instructionReadyToCompute(
                    operation['instructions'],
                    operation['operation_type'],
                  ) &&
                    computeExistingStepPreview(
                      opId,
                      true,
                      (response: any) => {
                        if (response.operation.results_dataset.total_row_count === null) {
                          fetchOperationRowCount({ id: opId });
                        }
                      },
                      undefined,
                    );
                }
              });
            },
          },
        });
      }
    },
  );
};

export const getNodePosition = (index: number, arrayCount: number) => {
  switch (index) {
    case 0:
      return arrayCount === 1 ? NODE_POSITIONS.ONLY_CHILD : NODE_POSITIONS.FIRST;
    case arrayCount - 1:
      return NODE_POSITIONS.LAST;
    default:
      return NODE_POSITIONS.MIDDLE;
  }
};

// EVENT LISTENERS

export const useOutsideAlerter = (ref: any, setSelectedNode: (node?: SelectedNode) => void) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        event.target.id === BACKGROUND_CANVAS
      ) {
        setSelectedNode(undefined);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setSelectedNode]);
};

export const useCmdKAlerter = (
  openAddStepModal: () => void,
  flowInfo?: Flow | null,
  selectedNode?: SelectedNode,
) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (flowInfo && e.metaKey && e.keyCode === 75) {
        if (selectedNode && selectedNode.id) {
          openAddStepModal();
        } else {
          AppToaster.show({
            message: 'Please select a dataset or operation to add a step to',
            intent: Intent.WARNING,
            timeout: 5000,
          });
        }
        e.preventDefault();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedNode, openAddStepModal, flowInfo]);
};

export const getSelectedPreviewCols = (
  operationType: string,
  operationInstructions: OperationInstructions,
) => {
  const selectedCols: Set<string> = new Set([]);

  if (!operationType || !operationInstructions) {
    return selectedCols;
  }

  if (operationType === OPERATION_TYPES.FILTER.id) {
    const filterClauses: FilterClause[] = Array.isArray(operationInstructions)
      ? operationInstructions
      : operationInstructions.filterClauses;
    _.each(filterClauses, (clause: any) => {
      if (filterClauseComplete(clause)) {
        selectedCols.add(clause.filterColumn.name);
      }
    });
  } else if (operationType === OPERATION_TYPES.SORT.id) {
    _.each(operationInstructions.sortColumns, (clause: SortClause) => {
      if (sortClauseComplete(clause)) {
        selectedCols.add(clause.column!.name);
      }
    });
  } else if (operationType === OPERATION_TYPES.FORMULA.id) {
    _.each(operationInstructions, (clause: any) => {
      if (formulaClauseComplete(clause)) {
        selectedCols.add(clause.newColName);
      }
    });
  } else if (operationType === OPERATION_TYPES.CHANGE_SCHEMA.id) {
    const schemaChanges: any[] = operationInstructions.changeSchemaList.filter(
      schemaClauseInstructionsComplete,
    );
    _.each(schemaChanges, (change: any) => {
      if (!('keepCol' in change && change['keepCol'] === false)) {
        selectedCols.add(change['newColName'] || change['col']);
      }
    });
  } else if (operationType === OPERATION_TYPES.DEDUP.id) {
    _.each(operationInstructions.colsToDedup, (dedupClause: AggregationColumnInfo) => {
      if (dedupClauseComplete(dedupClause)) {
        selectedCols.add(dedupClause.name);
      }
    });
    _.each(operationInstructions.sortColumns, (sortClause: SortClause) => {
      if (sortClauseComplete(sortClause)) {
        selectedCols.add(sortClause.column!.name);
      }
    });
  }
  return selectedCols;
};

const joinGraphNodes = (
  node: Operation | DatasetFlowNode,
  addFlowStep: ActionFn,
  selectedNode?: SelectedNode,
) => {
  if (
    !selectedNode ||
    selectedNode.type === NODE_TYPES.DATASET_FLOW_NODE ||
    isDatasetFlowNode(node)
  )
    return;

  if (!node.results_dataset) {
    AppToaster.show({
      message: 'You cannot join with a step that is not fully configured',
      intent: Intent.WARNING,
      timeout: 5000,
    });
    return;
  }

  const instructions = BASE_OP_INSTRUCTION_BY_TYPE[OPERATIONS_BY_ID.ENRICH.id]();
  instructions.joinTable.datasetId = node.results_dataset.id;
  instructions.joinTable.name = 'Graph Node';
  instructions.joinTable.isGraphNode = true;
  instructions.joinTable.graphNodeOperation = node.id;
  addFlowStep({
    id: node.flow_id.toString(),
    postData: {
      parent_node_type: selectedNode.type,
      parent_node_id: selectedNode.id,
      operation_type: OPERATIONS_BY_ID.ENRICH.id,
      instructions,
    },
  });
  trackEvent('Add flow step', {
    flow_id: node.flow_id.toString(),
    operation_type: OPERATIONS_BY_ID.ENRICH.id,
  });
};

export const joinFlowGraphNodes = _.debounce(joinGraphNodes, 2000, true);

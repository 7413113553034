/** @format */

import { identify, resetUser } from 'analytics/exploAnalytics';
import { Action } from 'reducers/rootReducer';
import cloneDeep from 'lodash/cloneDeep';
import { User } from 'actions/types';
import Cookies from 'js-cookie';
import { SetupFlowStepKey } from 'constants/setupPageConstants';

export interface AuthReducerState extends User {
  loginLoading?: boolean;
  registrationLoading?: boolean;
  joinTeamLoading?: boolean;
  createTeamLoading?: boolean;
}

const authReducerInitialState: AuthReducerState = {};

export default (
  state: AuthReducerState = authReducerInitialState,
  action: Action,
): AuthReducerState => {
  const newState = cloneDeep(state);

  switch (action.type) {
    case 'LOGIN_USER_REQUEST':
      newState.loginLoading = true;
      return newState;
    case 'LOGIN_USER_ERROR':
      newState.loginLoading = false;
      return newState;
    case 'LOGIN_USER_SUCCESS':
      const user = cloneDeep(Object.assign({}, state, action.payload));
      identify(user.id, {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        root_folder_id: user.root_folder_id,
        has_team: user.has_team,
        profile_data: user.profile_data,
      });

      user.loginLoading = false;

      if (
        user.setup_info &&
        user.setup_info.setup_flow_step_key === SetupFlowStepKey.CSV_VERIFY_UPLOAD
      ) {
        user.setup_info.setup_flow_step_key = SetupFlowStepKey.CSV_UPLOAD;
      }

      return user;
    case 'UPDATE_SETUP_FLOW_STEP_KEY_SUCCESS':
      newState.setup_info!.setup_flow_step_key = action.payload.postData.updated_setup_flow_key;
      return newState;
    case 'REGISTER_USER_REQUEST':
      newState.registrationLoading = true;
      return newState;
    case 'REGISTER_USER_ERROR':
      newState.registrationLoading = false;
      return newState;
    case 'REGISTER_USER_SUCCESS':
      const newUser = cloneDeep(Object.assign({}, state, action.payload));
      identify(newUser.id, {
        first_name: newUser.first_name,
        last_name: newUser.last_name,
        email: newUser.email,
        profile_data: newUser.profile_data,
      });

      newUser.registrationLoading = false;
      return newUser;
    case 'VERIFY_EMAIL':
      if (!state) {
        return newState;
      } else {
        newState.email_verified = true;
        return newState;
      }
    case 'LOGOUT_USER':
      resetUser();
      Cookies.remove('spheres_auth_token');
      return { loginLoading: false, registrationLoading: false };
    case 'ADD_USER_TO_TEAM_REQUEST':
      newState.joinTeamLoading = true;
      return newState;
    case 'CREATE_TEAM_REQUEST':
      newState.createTeamLoading = true;
      return newState;
    case 'ADD_USER_TO_TEAM_ERROR':
    case 'ADD_USER_TO_TEAM_SUCCESS':
      newState.joinTeamLoading = false;
      return newState;
    case 'CREATE_TEAM_ERROR':
    case 'CREATE_TEAM_SUCCESS':
      newState.createTeamLoading = false;
      return newState;
    default:
      return newState;
  }
};

/** @format */

import React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Intent, Button, Card, Elevation } from '@blueprintjs/core';
import { createStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { updateSetupFlowStepKey } from 'actions/onboardingActions';
import { SetupFlowStepKey, STEP_KEY_TO_DATA_SELECTION_PATH } from 'constants/setupPageConstants';
import { CSV_UPLOADS_DS_NAME } from 'constants/flowConstants';
import { fetchDataSourceList } from 'actions/dataSourceActions';
import { DEMO_DATA_DB_NAME } from 'components/onboardingSetup/demoDataSelection';
import {
  DATABASE_OPTIONS_DICTIONARY,
  DatabaseTypes,
} from 'components/onboardingSetup/setupFlowDatabaseSelection';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      minWidth: '50vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(3),
    },
    nextButton: { minWidth: 120, marginTop: theme.spacing(10) },
    datasourceList: {
      display: 'flex',
      flexDirection: 'column',
    },
    databaseCard: {
      width: '40vw',
      padding: `${theme.spacing(4)}px ${theme.spacing(7)}`,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      borderRadius: 3,
    },
    datasourceLogoContainer: {
      marginRight: theme.spacing(7),
    },
  });

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles>;

class ConnectedDatasourceDisplay extends React.Component<Props> {
  componentDidMount = () => {
    const { fetchDataSourceList } = this.props;
    fetchDataSourceList();
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.datasourceList}>{this.renderConnectedDatasources()}</div>
        <Button
          className={classes.nextButton}
          intent={Intent.PRIMARY}
          onClick={this.onNextButtonPress}>
          Next
        </Button>
      </div>
    );
  }

  onNextButtonPress = () => {
    const { updateSetupFlowStepKey } = this.props;
    updateSetupFlowStepKey({
      postData: {
        updated_setup_flow_key: SetupFlowStepKey.DATABASE_ALREADY_CONNECTED_PATH_ENTER_EXPLO,
        data_selection_path:
          STEP_KEY_TO_DATA_SELECTION_PATH[
            SetupFlowStepKey.DATABASE_ALREADY_CONNECTED_PATH_ENTER_EXPLO
          ],
      },
    });
  };

  renderConnectedDatasources = () => {
    const { classes, dataSourceList } = this.props;
    return dataSourceList
      .filter((datasource) => {
        return (
          datasource.name !== CSV_UPLOADS_DS_NAME &&
          datasource.name !== (DEMO_DATA_DB_NAME as string)
        );
      })
      .map((datasource) => {
        const databaseDisplayInfo =
          DATABASE_OPTIONS_DICTIONARY[datasource.source_type! as DatabaseTypes];
        return (
          <Card className={classes.databaseCard} elevation={Elevation.TWO}>
            <div className={classes.datasourceLogoContainer}>{databaseDisplayInfo.image}</div>
            {`${datasource.name}, a ${databaseDisplayInfo.name} database`}
          </Card>
        );
      });
  };
}

const mapStateToProps = (state: ReduxState) => ({
  dataSourceList: state.dataSourceList,
});

const mapDispatchToProps = {
  updateSetupFlowStepKey,
  fetchDataSourceList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ConnectedDatasourceDisplay));

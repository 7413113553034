/** @format */

import React from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

import { Icon } from '@blueprintjs/core';

import IntegrationDescription from 'explo-ds/typography/marketing/integrationDescription';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.exploBlue,
    marginRight: theme.spacing(7),
  },
  name: {
    color: theme.palette.grey100,
  },
}));

type Props = {
  className?: string;
  icon: string;
  name: string;
};

const FeatureName = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Icon icon={props.icon as any} iconSize={20} className={classes.icon} />
      <IntegrationDescription className={classes.name}>{props.name}</IntegrationDescription>
    </div>
  );
};

export default FeatureName;

/** @format */

import React from 'react';

import FileUploadButton from 'components/fileSystem/fileUploadButton';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { ReduxState } from 'reducers/rootReducer';
import { updateSetupFlowStepKey, setupFlowCSVFileUpload } from 'actions/onboardingActions';
import { SetupFlowStepKey } from 'constants/setupPageConstants';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(3),
      maxWidth: '60%',
    },
  });

type Props = WithStyles<typeof styles> & typeof mapDispatchToProps;

class SetupFlowFileUploadButton extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <FileUploadButton
        className={classes.root}
        fill
        buttonText="Upload new dataset"
        uploadFile={this.onUploadFile}
      />
    );
  }

  onUploadFile = (file: File) => {
    const { setupFlowCSVFileUpload, updateSetupFlowStepKey } = this.props;
    setupFlowCSVFileUpload(file);
    updateSetupFlowStepKey({
      postData: {
        updated_setup_flow_key: SetupFlowStepKey.CSV_VERIFY_UPLOAD,
      },
    });
  };
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = {
  updateSetupFlowStepKey,
  setupFlowCSVFileUpload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(SetupFlowFileUploadButton));

/** @format */

import React from 'react';
import _ from 'underscore';

import StepBlockTag from 'components/flowBuilder/stepBlockTag';
import ClauseBuilder from 'components/flowBuilder/clauseBuilder';
import FormulaOperationBody from 'components/flowBuilder/editOperationDrawer/formulaOperationBody';
import { formulaClauseComplete, formulaInstructionsChanged } from 'utils/flowUtils';
import { trackEvent } from 'analytics/exploAnalytics';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { FormulaOperationInstructions } from 'constants/types';
import { Dataset } from 'actions/types';

const styles = (theme: Theme) =>
  createStyles({
    fillerText: {
      fontSize: 16,
      fontWeight: 400,
    },
  });

type PassedProps = {
  clearRecentlyAddedOpId: () => {
    type: string;
  };
  computeLoading?: boolean;
  instructions: FormulaOperationInstructions;
  openFlyout?: boolean;
  operationId: number;
  scrollToColumn: (columnName: string) => void;
  sourceDataset: Dataset;
  updateOperationInstructionAndCompute: (
    operationId: number,
    newInstructions: FormulaOperationInstructions,
    shouldCompute: boolean,
  ) => void;
  summaryView?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  editDrawerOpen: boolean;
};

class FormulaOperationClauseBuilder extends React.Component<Props, State> {
  readonly state: State = {
    editDrawerOpen: this.props.openFlyout || false,
  };
  constructor(props: Props) {
    super(props);

    if (props.openFlyout) {
      props.clearRecentlyAddedOpId();
    }
  }

  render() {
    const { computeLoading, instructions, summaryView } = this.props;

    return (
      <ClauseBuilder
        computeLoading={computeLoading}
        editDrawerOpen={this.state.editDrawerOpen}
        openDrawer={() => this.setState({ editDrawerOpen: true })}
        closeDrawer={() => this.setState({ editDrawerOpen: false })}
        editOperationDrawerTitle="Enter custom formulas"
        tooltipSuggestionIsOpen={instructions.filter(formulaClauseComplete).length === 0}
        editOperationBody={this.renderOperationEditBody()}
        clauseText={this.renderFormulaText()}
        summaryView={summaryView}
      />
    );
  }

  renderOperationEditBody = () => {
    const {
      sourceDataset,
      operationId,
      updateOperationInstructionAndCompute,
      instructions,
    } = this.props;

    return (
      <FormulaOperationBody
        instructions={instructions}
        operationId={operationId}
        sourceDataset={sourceDataset}
        onSubmit={(newInstructions: FormulaOperationInstructions) => {
          const instructionHasChanged = formulaInstructionsChanged(instructions, newInstructions);
          updateOperationInstructionAndCompute(operationId, newInstructions, instructionHasChanged);
          this.setState({ editDrawerOpen: false });
          trackEvent('Update and compute formula', {
            operation_id: operationId,
            new_instructions: newInstructions,
            formula_instructions_changed: instructionHasChanged,
          });
        }}
      />
    );
  };

  renderFormulaText = (): JSX.Element[] | null => {
    const { classes, operationId } = this.props;

    const completeClauses = this.props.instructions.filter(formulaClauseComplete);
    const clauses = [
      <div className={classes.fillerText} key={`filter_where_${operationId}`}>
        to create columns:{' '}
      </div>,
    ];
    _.each(completeClauses, (clause, index) => {
      clauses.push(
        <StepBlockTag
          key={`clause_${index}_${operationId}`}
          interactive={true}
          onClick={() => this.props.scrollToColumn(clause.newColName)}
          content={clause.newColName}
        />,
      );

      if (index !== completeClauses.length - 1) {
        clauses.push(
          <div className={classes.fillerText} key={`clause_${index}_${operationId}_and`}>
            and
          </div>,
        );
      }
    });

    return clauses.length > 1 ? clauses : null; // if no clauses were added, don't return anything
  };
}

export default withStyles(styles)(FormulaOperationClauseBuilder);

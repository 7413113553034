/** @format */

import React from 'react';
import _ from 'underscore';
import uuidv4 from 'uuid/v4';
import cx from 'classnames';
import Papa from 'papaparse';
import Cookies from 'js-cookie';

import { AppToaster } from 'toaster';
import { trackEvent } from 'analytics/exploAnalytics';
import { ProgressBar, Classes, Intent, IconName } from '@blueprintjs/core';

export const getResultTextHighlighted = (
  text: string,
  matchIndices: number[][],
  highlightClassName: string,
) => {
  let result = [];
  let start = 0;
  matchIndices.map((indices) => {
    const before = text.slice(start, indices[0]);
    result.push(<span key={_.uniqueId('search_result_')}>{before}</span>);
    const middle = text.slice(indices[0], indices[1] + 1);
    result.push(
      <span className={highlightClassName} key={_.uniqueId('search_result_')}>
        {middle}
      </span>,
    );
    start = indices[1] + 1;
    return null;
  });
  result.push(<span key={_.uniqueId('search_result_')}>{text.slice(start)}</span>);
  return <>{result}</>;
};

const getSignedGSCUrlToUpload = (
  fileName: string,
  onSuccess: (signedUrl: string, bucket: string) => void,
) => {
  fetch(process.env.REACT_APP_API_URL + `datasets/make_signed_gsc_upload/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Token ' + Cookies.get('spheres_auth_token'),
    },
    body: JSON.stringify({
      file_name: fileName,
    }),
  })
    .then((resp) => resp.json())
    .then((responseData) => {
      onSuccess(responseData.signed_url, responseData.bucket);
    });
};

const uploadToGSC = (signedUrl: string, file: File, toastKey: string, onSuccess: () => void) => {
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', signedUrl, true);
  xhr.onload = () => {
    const status = xhr.status;
    if (status === 200) {
      onSuccess();
    } else {
      console.log('there was an error uploading to GSC');
    }
  };

  xhr.onerror = () => {
    AppToaster.show(
      renderErrorToast(
        'There was an error uploading your Dataset. Please try again or reach out to us at support@explo.co and someone from our team will help you within the next 10 minutes.',
      ),
      toastKey,
    );
  };

  xhr.upload.addEventListener(
    'progress',
    function (e) {
      // @ts-ignore
      const pc = parseFloat((e.loaded / e.total) * 100).toFixed(2);
      // @ts-ignore
      AppToaster.show(renderProgressToast(pc, xhr), toastKey);
    },
    false,
  );

  xhr.setRequestHeader('Content-Type', file.type);
  xhr.send(file);
};

export const getTablePreview = (
  file: File,
  delimiterValue: string | null,
  previewRowCount: number,
  onSuccess: (results: any) => void,
) => {
  if (!file) {
    return;
  }
  return Papa.parse(file, {
    delimiter: delimiterValue ? delimiterValue : undefined,
    preview: previewRowCount,
    complete: (results) => onSuccess(results),
  });
};

export const uploadToBQ = (
  parentFolderId: number,
  gscURI: string,
  tableName: string,
  fileName: string,
  fieldNames: string[],
  toastKey: string,
  numHeaderRows: number,
  delimiterId: string,
  onSuccess: (newDatast: any) => void,
) => {
  fetch(process.env.REACT_APP_API_URL + `datasets/upload_bq_from_gcs/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Token ' + Cookies.get('spheres_auth_token'),
    },
    body: JSON.stringify({
      gsc_uri: gscURI,
      table_name: tableName,
      field_names: fieldNames,
      file_name: fileName,
      parent_folder_id: parentFolderId,
      num_header_rows: numHeaderRows,
      delimiter_id: delimiterId,
    }),
  })
    .then((resp) => {
      if (resp.status && resp.status >= 400) {
        throw resp.statusText;
      }
      return resp.json();
    })
    .then((responseData) => {
      onSuccess(responseData);
    })
    .catch((error) => {
      AppToaster.show(
        { message: `Error uploading your dataset: ${error}`, intent: Intent.DANGER, icon: 'error' },
        toastKey,
      );
    });
};

export const uploadFileAsDataset = (
  file: File,
  parentFolderId: number,
  numHeaderRows: number,
  delimiterId: string,
  columnNames: string[],
  onSuccess: (resourceData: any) => void,
) => {
  // Occurs when existing files in the file system are dragged and dropped.
  if (!file) {
    return;
  }

  trackEvent('Upload csv', {
    file_type: file.type,
    parent_folder_id: parentFolderId,
    num_header_rows: numHeaderRows,
    delimiter_id: delimiterId,
  });

  const toastKey = AppToaster.show(renderProgressToast(0));
  const fileName = `${uuidv4()}-${file.name.replace(/\W+/g, '_')}`;

  getSignedGSCUrlToUpload(fileName, (signedUrl: string, bucket: string) => {
    uploadToGSC(signedUrl, file, toastKey, () => {
      const tableName = 'table_' + fileName.split('.')[0].replace(/-/g, '_');
      uploadToBQ(
        parentFolderId,
        `gs://${bucket}/${fileName}`,
        tableName,
        file.name,
        columnNames,
        toastKey,
        numHeaderRows,
        delimiterId,
        (newDataset) => {
          onSuccess(newDataset);
        },
      );
    });
  });
};

const renderErrorToast = (msg: string) => ({
  icon: 'warning-sign' as IconName,
  message: msg,
  timeout: 10000,
  intent: Intent.DANGER,
});

const renderProgressToast = (amountPct: number, xhr?: any) => {
  return {
    icon: 'cloud-upload' as IconName,
    message: (
      <ProgressBar
        className={cx('docs-toast-progress', { [Classes.PROGRESS_NO_STRIPES]: amountPct >= 100 })}
        intent={amountPct < 100 ? Intent.PRIMARY : Intent.SUCCESS}
        value={amountPct / 100}
      />
    ),
    timeout: amountPct < 100 ? 0 : 2000,
    onDismiss: () => {
      if (xhr) {
        xhr.abort();
      }
    },
  };
};

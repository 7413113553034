/** @format */

import React from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderTop: '1px solid #1B3257',
    paddingTop: theme.spacing(10),
  },
  footerSection: {
    fontSize: 14,
    marginRight: theme.spacing(10),
  },
  footerSectionHeader: {
    color: theme.palette.grey100,
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
  },
  footerLinkContainer: {
    marginBottom: theme.spacing(2),

    '&:last-child': {
      marginRight: 0,
    },
  },
  footerLink: {
    color: theme.palette.grey100,
    '&:hover': {
      color: theme.palette.grey100,
    },
  },
}));

type Props = {
  className?: string;
};

const FeatureName = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.root, props.className)}>
      <div className={classes.footerSection}>
        <div className={classes.footerSectionHeader}>Company</div>
        <div className={classes.footerLinkContainer}>
          <a className={classes.footerLink} href="/about-us">
            About Us
          </a>
        </div>

        <div className={classes.footerLinkContainer}>
          <a
            className={classes.footerLink}
            href="mailto:support@explo.co"
            target="_blank"
            rel="noopener noreferrer">
            Contact
          </a>
        </div>
      </div>

      <div className={classes.footerSection}>
        <div className={classes.footerSectionHeader}>Legal</div>
        <div className={classes.footerLinkContainer}>
          <a
            className={classes.footerLink}
            href="/terms_of_service.pdf"
            target="_blank"
            rel="noopener noreferrer">
            Terms of Service
          </a>
        </div>

        <div className={classes.footerLinkContainer}>
          <a
            className={classes.footerLink}
            href="/privacy_policy.pdf"
            target="_blank"
            rel="noopener noreferrer">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default FeatureName;

/** @format */

import React, { Component } from 'react';
import _ from 'underscore';
import { withStyles, WithStyles } from '@material-ui/styles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Fuse from 'fuse.js';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Menu, MenuItem } from '@blueprintjs/core';
import { Omnibar } from '@blueprintjs/select';
import { DashboardInfo } from 'reducers/dashboardReducer';
import { createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      top: '40%',
      left: 'calc(50% - 250px)',
      zIndex: 20,
      width: 500,
    },
    searchResultContainer: {
      padding: theme.spacing(2),
    },
  });

const constructItemsFromWorkspace = (workspaceData: any, dashboards: any) => {
  const items: any[] = [];
  workspaceData.source_datasets.map((dataset: any) => {
    items.push({
      type: 'Source Dataset',
      id: dataset.id,
      name: dataset.name || dataset.data_source_table_name,
      schema: dataset.cached_schema,
      icon: 'th',
      href: `/datasets/${dataset.id}`,
    });

    return null;
  });

  workspaceData.saved_datasets.map((dataset: any) => {
    items.push({
      type: 'Saved Dataset',
      id: dataset.id,
      name: dataset.name || dataset.data_source_table_name,
      schema: dataset.cached_schema,
      icon: 'th',
      href: `/datasets/${dataset.id}`,
    });

    return null;
  });

  workspaceData.flows.map((flow: any) => {
    items.push({
      type: 'Flow',
      id: flow.id,
      name: flow.name,
      icon: 'data-lineage',
      href: `/flows/${flow.id}`,
    });

    return null;
  });

  dashboards.map((dashboard: any) => {
    items.push({
      type: 'Dashboard',
      id: dashboard.id,
      name: dashboard.name,
      icon: 'timeline-line-chart',
      href: `/dashboards/${dashboard.id}`,
    });

    return null;
  });

  return items;
};

const createSearchIndex = (items: any) => {
  return new Fuse(items, {
    isCaseSensitive: false,
    threshold: 0.6,
    distance: 100,
    includeMatches: true,
    keys: ['name'],
  });
};

type PassedProps = {
  isOpen: boolean;
  onClose: () => void;
  workspaceData: any;
  dashboardsList?: DashboardInfo[];
};

type State = {
  searchItems: any[];
  workspaceId: number;
  fuseIndex: any;
};

type Props = PassedProps & WithStyles<typeof styles> & RouteComponentProps;

class WorkspaceSearchBar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const searchItems = constructItemsFromWorkspace(props.workspaceData, props.dashboardsList);

    this.state = {
      searchItems,
      workspaceId: props.workspaceData.id,
      fuseIndex: createSearchIndex(searchItems),
    };
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.workspaceData && nextProps.workspaceData.id === prevState.workspaceId) {
      return null;
    }

    const searchItems = constructItemsFromWorkspace(
      nextProps.workspaceData,
      nextProps.dashboardsList,
    );

    return {
      workspaceId: nextProps.workspaceData.id,
      fuseIndex: createSearchIndex(searchItems),
    };
  }

  render = () => {
    const { classes } = this.props;
    return (
      // @ts-ignore
      <Omnibar
        className={classes.root}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        items={this.state.searchItems}
        itemRenderer={this.renderResult}
        itemListRenderer={this.renderList}
        itemListPredicate={this.filterQuery}
      />
    );
  };

  filterQuery = (query: any) => {
    const results = this.state.fuseIndex.search(query).slice(0, 10);
    results.map((result: any) => {
      if (result.matches && result.matches.length > 0) {
        result.item.matches = result.matches[0].indices;
      }
      return null;
    });
    return _.pluck(results, 'item');
  };

  renderList = ({ filteredItems, items, query, renderItem, itemsParentRef }: any) => {
    let itemsList;
    if (query.length === 0) {
      itemsList = (
        <MenuItem
          disabled={true}
          text="Start typing to search through datasets, dashboards, and explorations"
        />
      );
    } else if (filteredItems.length === 0) {
      itemsList = <MenuItem disabled={true} text="No results." />;
    } else {
      itemsList = filteredItems.map((item: any) => renderItem(item));
    }

    return (
      <Menu large ref={(ref) => (itemsParentRef = ref)}>
        {itemsList}
      </Menu>
    );
  };

  renderResult = (item: any) => {
    return (
      <MenuItem
        key={`search-result-${item.id}`}
        icon={item.icon}
        text={this.getResultTextHighlighted(item.name, item.matches)}
        onClick={() => {
          this.props.onClose();
          this.props.history.push(item.href);
        }}
      />
    );
  };

  getResultTextHighlighted = (text: string, matchIndices: number[][]) => {
    let result = [];
    let start = 0;
    matchIndices.map((indices: number[]) => {
      const before = text.slice(start, indices[0]);
      result.push(<span key={_.uniqueId('search_result_')}>{before}</span>);
      const middle = text.slice(indices[0], indices[1] + 1);
      result.push(<b key={_.uniqueId('search_result_')}>{middle}</b>);
      start = indices[1] + 1;
      return null;
    });
    result.push(<span key={_.uniqueId('search_result_')}>{text.slice(start)}</span>);
    return <>{result}</>;
  };
}

export default withRouter(withStyles(styles)(WorkspaceSearchBar));

/** @format */

import React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { Icon, IconName, H4, Intent, Tag, Classes, Spinner } from '@blueprintjs/core';
import OperationInstructionDescription from 'components/flowGraph/operationInstructionDescription';
import {
  OPERATIONS_BY_ID,
  MAX_ROWS_TO_PREVIEW,
  OPERATION_TYPES,
  SCHEMA_DATA_TYPES_BY_ID,
} from 'constants/flowConstants';
import { OperationInstructions } from 'constants/types';
import { FLOW_NODE_BORDER_RADIUS } from './flowNodeV3';
import { Dataset, Operation } from 'actions/types';
import LoadingDataTable from 'components/dataTable/loadingDataTable';
import BaseDataTable from 'components/dataTable/baseDataTable';
import { getSelectedPreviewCols } from 'pages/flowGraphPage/flowGraphPageUtils';
import ColumnHeaderText from 'components/dataTable/columnHeaderText';
import cx from 'classnames';
import FlowGraphStepActions from './flowGraphStepActions';
import { ReduxState } from 'reducers/rootReducer';
import { connect } from 'react-redux';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    metaInfo: {
      display: 'flex',
      alignItems: 'flex-start',
      borderRadius: `${FLOW_NODE_BORDER_RADIUS}px ${FLOW_NODE_BORDER_RADIUS}px 0px 0px`,
      position: 'relative',
      padding: theme.spacing(5),
    },
    operationIconContainer: {
      backgroundColor: theme.palette.grey.blueprintGrey,
      width: 70,
      height: 70,
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
    },
    operationInfoContainer: {
      marginLeft: theme.spacing(6),
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    operationInstructionDescription: {
      overflow: 'hidden',
      height: 30,
    },
    tableDataFooter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: theme.spacing(8),
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      // 7px border radius looks better
      borderRadius: `0px 0px ${FLOW_NODE_BORDER_RADIUS - 1}px ${FLOW_NODE_BORDER_RADIUS - 1}px`,
      backgroundColor: theme.palette.grey.slight,
    },
    hoverPointer: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    footerText: {
      color: theme.palette.grey800,
      display: 'flex',
      flexDirection: 'row',
    },
    errorFooterText: {
      color: theme.palette.dangerRed,
    },
    rowCountSpinner: {
      marginRight: theme.spacing(1),
    },
    tableColumnHeaderText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    tableColumnHeaderTypeIcon: {
      marginRight: theme.spacing(2),
      minWidth: 'unset',
    },
    iconHeaderText: {
      overflow: 'hidden',
    },
    endorsedIcon: {
      marginLeft: theme.spacing(1),
    },
    loadingSkeletonPlaceholder: {
      width: 150,
      height: 30,
    },
    operationErrorText: {
      color: theme.palette.dangerRed,
    },
  });

type PassedProps = {
  operation: Operation;
  operationType: string;
  instructions: OperationInstructions;
  dataset: Dataset | null;
  sourceDataset?: Dataset;
  computeLoading?: boolean;
  operationInstructions: OperationInstructions;
  addDatasetModalOpen: boolean;
  addStepModalOpen: boolean;
  isSelected: boolean;
};

type Props = PassedProps & WithStyles<typeof styles> & ReturnType<typeof mapStateToProps>;

type State = {
  isTableDataCollapsed: boolean;
  editDrawerOpen: boolean;
};

class TransformOperationNode extends React.PureComponent<Props, State> {
  state: State = {
    isTableDataCollapsed: true,
    editDrawerOpen: this.props.recentlyAddedOpId === this.props.operation.id,
  };

  render() {
    const { classes } = this.props;

    return (
      <div
        className={classes.root}
        onDoubleClick={() => {
          this.setState({ editDrawerOpen: true });
        }}>
        {/* <div {...dragHandleProps} className={classes.metaInfo}> */}
        <div className={classes.metaInfo}>
          {this.renderOperationIcon()}
          {this.renderOperationInfo()}
          {this.renderStepActions()}
        </div>
        {this.renderTableData()}
      </div>
    );
  }

  // Also takes responsibility for rendering modals and flyout drawers for this node
  renderStepActions = () => {
    const {
      operation,
      addDatasetModalOpen,
      addStepModalOpen,
      isSelected,
      sourceDataset,
      dataset,
    } = this.props;
    const { editDrawerOpen } = this.state;

    return (
      <FlowGraphStepActions
        operation={operation}
        addDatasetModalOpen={addDatasetModalOpen}
        addStepModalOpen={addStepModalOpen}
        isSelected={isSelected}
        sourceDataset={sourceDataset}
        isResultsEmpty={!dataset || !dataset.cached_preview || dataset.cached_preview.length === 0}
        editDrawerOpen={editDrawerOpen}
        setEditDrawerOpen={(editDrawerOpen) => this.setState({ editDrawerOpen })}
      />
    );
  };

  renderOperationIcon = () => {
    const { classes, operationType } = this.props;
    const opInfo = OPERATIONS_BY_ID[operationType];

    return (
      <div className={classes.operationIconContainer}>
        <Icon icon={opInfo.icon as IconName} iconSize={40} color={'#ffffff'} />
      </div>
    );
  };

  renderOperationInfo = () => {
    const { classes, operationType, operation, instructions } = this.props;
    const opInfo = OPERATIONS_BY_ID[operationType];
    return (
      <div className={classes.operationInfoContainer}>
        <H4>{opInfo.name}</H4>
        {operation.results_job_error ? (
          <div className={classes.operationErrorText}>
            There was an error in computing this operation
          </div>
        ) : (
          <div
            className={cx({
              [Classes.SKELETON]: operation.compute_loading,
            })}>
            <OperationInstructionDescription
              className={classes.operationInstructionDescription}
              operationType={operationType}
              instructions={instructions}
              operationId={operation.id.toString()}
            />
          </div>
        )}
      </div>
    );
  };

  renderTableData = () => {
    const { operation, dataset } = this.props;
    if (operation.compute_loading || !dataset) return <></>;
    return (
      <div>
        {this.renderTableContent()}
        {this.renderTableDataFooter(dataset)}
      </div>
    );
  };

  renderTableContent = () => {
    const { computeLoading, dataset, operationType, operationInstructions } = this.props;
    const { isTableDataCollapsed } = this.state;
    if (
      isTableDataCollapsed ||
      !dataset ||
      (dataset.cached_preview && dataset.cached_preview.length === 0)
    )
      return;

    const DataTable = !dataset.cached_preview ? LoadingDataTable : BaseDataTable;

    return (
      <DataTable
        loading={!dataset.cached_preview}
        rows={dataset.cached_preview || []}
        headerList={dataset.cached_schema!}
        maxRows={MAX_ROWS_TO_PREVIEW}
        selectedColumns={getSelectedPreviewCols(operationType, operationInstructions)}
        renderCustomNameRenderer={computeLoading ? null : this.renderCustomNameRenderer}
        noBorderRadius
        columnWidthFill
        truncateEmptyRowSpace
      />
    );
  };

  renderCustomNameRenderer = (header: string, index: number) => {
    const { classes, operationType, operationInstructions, dataset } = this.props;

    switch (operationType) {
      case OPERATION_TYPES.UNION.id:
        if (
          index < operationInstructions.columnsToUnionOn.length &&
          operationInstructions.columnsToUnionOn[index].sourceColumn &&
          operationInstructions.columnsToUnionOn[index].unionColumn
        ) {
          return (
            <div className={cx('bp3-table-truncated-text', classes.tableColumnHeaderText)}>
              <div className={classes.iconHeaderText}>
                <Tag
                  className={classes.tableColumnHeaderTypeIcon}
                  icon={
                    SCHEMA_DATA_TYPES_BY_ID[dataset!.cached_schema![index].type].icon as IconName
                  }
                  minimal={true}></Tag>
                {`${header}`}
              </div>
              <Icon className={classes.endorsedIcon} intent={Intent.PRIMARY} icon="endorsed" />
            </div>
          );
        } // eslint-disable-next-line no-fallthrough
      default:
        return (
          <ColumnHeaderText headerList={dataset!.cached_schema!} index={index} header={header} />
        );
    }
  };

  renderTableDataFooter = (dataset: Dataset) => {
    const { classes } = this.props;
    const { isTableDataCollapsed } = this.state;
    return (
      <div
        className={cx(classes.tableDataFooter, {
          [classes.hoverPointer]: dataset.total_row_count !== 0,
        })}
        onClick={() => {
          dataset.total_row_count !== 0 &&
            this.setState({ isTableDataCollapsed: !isTableDataCollapsed });
        }}>
        {this.renderRowCount(dataset.total_row_count)}

        {dataset.cached_preview && dataset.cached_preview.length !== 0 && (
          <Icon
            icon={(isTableDataCollapsed ? 'chevron-down' : 'chevron-up') as IconName}
            color={'#697B97'}
          />
        )}
      </div>
    );
  };

  renderRowCount = (numRows: number | null) => {
    const { classes, operation } = this.props;
    if (operation.rowError) {
      return <div className={classes.errorFooterText}>Error fetching row count</div>;
    }
    return (
      <div className={classes.footerText}>
        {numRows === null ? (
          <Spinner size={17} className={classes.rowCountSpinner} />
        ) : (
          numRows.toLocaleString()
        )}{' '}
        resulting rows
      </div>
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  recentlyAddedOpId: state.flowData.recentlyAddedOpId,
});

export default connect(mapStateToProps, {})(withStyles(styles)(TransformOperationNode));

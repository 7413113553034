/** @format */

export enum ROUTES {
  HOME = '/',
  EXPLO_HOME = '/business-intelligence',
  ABOUT = '/about-us',
  LOGIN = '/login',
  COVID = '/covid',
  USE_CASE_ECOMMERCE = '/ecommerce',
  ALERTING = '/alerting',
  SIGNUP = '/signup',
  CHECK_YOUR_EMAIL = '/check-your-email',
  VERIFY_EMAIL = '/verify-email/:verification_id',
  ONBOARDING = '/onboarding',
  SETUP = '/setup',
  DASHBOARD = '/dashboard',
  HOME_APP_PAGE = '/home',
  FILES = '/files/:folderId?',
  FILES_HOME = '/files/',
  DATASETS = '/datasets/:datasetId',
  FLOWS_GRAPH = '/flows/:flowId',
  PUBLIC_FLOW = '/public-flows/:flowId',
  NEW_FLOW = '/new-flow',
  FLOWS_BASE = '/flows/',
  DASHBOARDS = '/dashboards/:dashboardId',
  DATASOURCE = '/datasources',
  ADD_DATA_SOURCE = '/add-data-source',
  JOIN_TEAM = '/join-team',
  TEAM = '/team',
  TRIAL_EXPIRED = '/trial-expired',
  SHARED_DASHBOARD = '/share/:dashboardHash/dashboard',
  ANALYTICS = '/analytics',

  // Tabs
  DATA_SOURCE_LIST = '/data-source-list',
  DASHBOARD_LIST = '/dashboard-list',
  DATASETS_VIEW_PAGE = '/datasets-view-page/:datasourceId',
}

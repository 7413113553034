/** @format */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { trackEvent } from 'analytics/exploAnalytics';

import ClauseBuilder from 'components/flowBuilder/clauseBuilder';

import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { AxisPivotChartInstructions } from 'constants/types';
import { RouteComponentProps } from 'react-router';
import { Dataset } from 'actions/types';

import AxisPivotChartEditOperationBody from 'components/flowBuilder/editOperationDrawer/axisPivotChartEditOperationBody';

import {
  axisPivotChartInstructionsChanged,
  axisPivotChartInstructionsComplete,
} from 'utils/flowUtils';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    fillerText: {
      fontSize: 16,
      fontWeight: 400,
    },
  });

type PassedProps = {
  clearRecentlyAddedOpId: () => {
    type: string;
  };
  computeLoading?: boolean;
  instructions: AxisPivotChartInstructions;
  openFlyout?: boolean;
  operationId: number;
  sourceDataset: Dataset;
  updateOperationInstructionAndCompute: (
    operationId: number,
    newInstructions: AxisPivotChartInstructions,
    shouldCompute: boolean,
  ) => void;
  summaryView?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles> & RouteComponentProps;
type State = {
  editDrawerOpen: boolean;
};

class AxisPivotChartClauseBuilder extends React.PureComponent<Props, State> {
  readonly state: State = {
    editDrawerOpen: this.props.openFlyout || false,
  };
  constructor(props: Props) {
    super(props);

    if (props.openFlyout) {
      props.clearRecentlyAddedOpId();
    }
  }

  render() {
    const { summaryView, classes, computeLoading } = this.props;
    const { editDrawerOpen } = this.state;

    return (
      <div className={classes.root}>
        <ClauseBuilder
          computeLoading={computeLoading}
          editDrawerOpen={editDrawerOpen}
          openDrawer={() => this.setState({ editDrawerOpen: true })}
          closeDrawer={() => this.setState({ editDrawerOpen: false })}
          editOperationDrawerTitle="Define the Axis Pivot Chart"
          tooltipSuggestionIsOpen={false}
          editOperationBody={this.renderOperationEditBody()}
          clauseText={<></>}
          summaryView={summaryView}
        />
      </div>
    );
  }

  renderOperationEditBody = () => {
    const {
      sourceDataset,
      instructions,
      operationId,
      updateOperationInstructionAndCompute,
    } = this.props;

    return (
      <AxisPivotChartEditOperationBody
        instructions={instructions}
        operationId={operationId}
        sourceDataset={sourceDataset}
        onSubmit={(newInstructions) => {
          const instructionHasChanged = axisPivotChartInstructionsChanged(
            instructions,
            newInstructions,
          );
          updateOperationInstructionAndCompute(
            operationId,
            newInstructions,
            instructionHasChanged && axisPivotChartInstructionsComplete(newInstructions),
          );
          this.setState({ editDrawerOpen: false });
          trackEvent('Update and compute axis puvot chart', {
            operation_id: operationId,
            new_instructions: newInstructions,
            line_chart_instructions_changed: instructionHasChanged,
          });
        }}
      />
    );
  };
}

export default withRouter(withStyles(styles)(AxisPivotChartClauseBuilder));

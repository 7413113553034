/** @format */

import { Action } from 'reducers/rootReducer';
import { DatabaseConfig } from 'constants/types';

export interface OnboardingReducerState {
  setupPageLoading: boolean;
  selectedDatabaseConfig?: DatabaseConfig;
  selectedCSVFile?: File;
}

const onboardingReducerInitialState: OnboardingReducerState = {
  setupPageLoading: false,
};

export default (
  state: OnboardingReducerState = onboardingReducerInitialState,
  action: Action,
): OnboardingReducerState => {
  const newState = Object.assign({}, state);
  const { payload } = action;

  switch (action.type) {
    case 'FETCH_DATABASE_HOSTED_SELECTION_LIST_REQUEST':
      newState.setupPageLoading = true;
      return newState;
    case 'FETCH_DATABASE_HOSTED_SELECTION_LIST_SUCCESS':
      newState.setupPageLoading = false;
      return newState;
    case 'UPDATE_SETUP_FLOW_STEP_KEY_REQUEST':
      newState.setupPageLoading = true;
      return newState;
    case 'UPDATE_SETUP_FLOW_STEP_KEY_SUCCESS':
      newState.setupPageLoading = false;
      return newState;
    case 'CREATE_DATABASE_HOSTED_SELECTION_MODEL_REQUEST':
      newState.setupPageLoading = true;
      return newState;
    case 'CREATE_DATABASE_HOSTED_SELECTION_MODEL_SUCCESS':
      newState.setupPageLoading = false;
      newState.selectedDatabaseConfig = payload.db_config;
      return newState;
    case 'SETUP_FLOW_CSV_FILE_UPLOAD':
      newState.selectedCSVFile = payload.file;
      return newState;
    default:
      return state;
  }
};

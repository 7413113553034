/** @format */

import React from 'react';
import { Link } from 'react-scroll';

import ExploLogo from 'images/explo_logo.png';
import { ROUTES } from 'constants/routes.tsx';

const NavHeader = (props) => {
  const productLink = props.notHomePage ? (
    <a href="/#product">Product</a>
  ) : (
    <Link to="product" smooth={true} duration={500}>
      Product
    </Link>
  );

  const signUpLink = props.notHomePage ? (
    <a href="/#sign-up-footer">
      <button className="kazds-btn btn-primary">Sign Up</button>
    </a>
  ) : (
    <Link to="sign-up-footer" smooth={true} duration={500}>
      <button className="kazds-btn btn-primary">Sign Up</button>
    </Link>
  );

  return (
    <div className="nav">
      <div className="nav-logo">
        <a href="/">
          <img src={ExploLogo} className="" alt="logo for explo" />
        </a>
      </div>
      <div className="nav-option-section">
        <div className="nav-option">
          <a href="/covid">COVID-19</a>
        </div>
        <div className="nav-option">{productLink}</div>
        <div className="nav-option">
          <a href="/ecommerce">Use Cases</a>
        </div>
        <div className="nav-option">
          <a href="/about-us">About Us</a>
        </div>
        {!props.userLoggedIn && (
          <div className="nav-option">
            <a href="/login">Sign In</a>
          </div>
        )}
        {!props.userLoggedIn && <div className="nav-option">{signUpLink}</div>}
        {props.userLoggedIn && (
          <div className="nav-option">
            <a href={ROUTES.HOME_APP_PAGE}>
              <button className="kazds-btn btn-primary">Go to app</button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavHeader;

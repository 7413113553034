/** @format */

import React, { Component } from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import DashboardFilter from 'components/dashboard/dashboardFilter';
import EditableDashboardFilter from 'components/dashboard/editableDashboardFilter';

import { DashboardFilter as DashboardFilterType } from 'actions/types';

const styles = (theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    columnGap: theme.spacing(10),
    margin: 'auto',
    maxWidth: 1100,
    padding: `0 ${theme.spacing(12)}px`,
    marginTop: theme.spacing(6),
    marginBottom: -theme.spacing(6),
  },
});

type PassedProps = {
  filters: DashboardFilterType[];
  editMode: boolean;
  onEditingFilterSelected: (filterId: number) => void;
  onRemoveFilterSelected: (filterId: number) => void;
  currentlyEditingFilterId?: number;
  onFilterValueSubmit: (filterId: number, newValue: string) => void;
  onFilterOperatorSelect: (filterId: number, newOperator: any) => void;
  filterEditSubmissionClicked: boolean;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {};

export class DashboardFilterContainer extends Component<Props, State> {
  render = () => {
    const { classes, filters } = this.props;
    return <div className={classes.root}>{filters.map(this.renderFilter)}</div>;
  };

  renderFilter = (filter: DashboardFilterType) => {
    const {
      editMode,
      onEditingFilterSelected,
      onRemoveFilterSelected,
      currentlyEditingFilterId,
      onFilterValueSubmit,
      onFilterOperatorSelect,
      filterEditSubmissionClicked,
    } = this.props;

    if (editMode) {
      return (
        <EditableDashboardFilter
          name={filter.name}
          onEditClicked={() => onEditingFilterSelected(filter.id)}
          onRemoveClicked={() => onRemoveFilterSelected(filter.id)}
          isCurrentlyEditing={currentlyEditingFilterId === filter.id}
          key={`editable-dashboard-filter-${filter.id}`}
          error={filterEditSubmissionClicked && Object.keys(filter.operation_fields).length === 0}
        />
      );
    }

    return (
      <DashboardFilter
        key={`dashboard-filter-${filter.id}`}
        onFilterValueSubmit={(newValue: string) => onFilterValueSubmit(filter.id, newValue)}
        onFilterOperatorSelect={(newOperator: any) =>
          onFilterOperatorSelect(filter.id, newOperator)
        }
        type={filter.filter_type}
        name={filter.name}
        value={filter.value}
        operator={filter.filter_operator}
      />
    );
  };
}

export default withStyles(styles)(DashboardFilterContainer);

/** @format */

import React from 'react';
import _ from 'underscore';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/styles';
import find from 'lodash/find';
import CopyToClipboard from 'react-copy-to-clipboard';
import { AppToaster } from 'toaster';
import { DashboardReducerState } from 'reducers/dashboardReducer';
import {
  NonIdealState,
  Spinner,
  Intent,
  Button,
  Popover,
  Menu,
  MenuItem,
  Dialog,
  Classes,
  InputGroup,
  Position,
} from '@blueprintjs/core';

import DashboardLayout from 'components/dashboard/dashboardLayout';
import ConfigureDashboardFilterModal from 'components/dashboard/configureDashboardFilterModal';
import DashboardFilterContainer from 'components/dashboard/dashboardFilterContainer';
import { switchSelectedDataSourceId } from 'actions/dataSourceActions';
import { pageView } from 'analytics/exploAnalytics';
import {
  fetchDashboard,
  refreshDashboardOperation,
  refreshDashboard,
  getDashboardShareHash,
  updateDashboardLayout,
  updateOperationTitle,
  removeOperationFromDashboard,
  deleteDashboard,
  renameDashboard,
  createDashboardFilter,
  deleteDashboardFilter,
  updateDashboardFilterField,
  updateFilterValue,
  updateFilterOperator,
  fetchDashboardOperationRowCount,
} from 'actions/dashboardActions';
import ConfirmationModal from 'components/modals/confirmationModal';
import TextFieldModal from 'components/TextFieldModal';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION, Operation, DashboardFilter as DashboardFilterType } from 'actions/types';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { ReduxState } from 'reducers/rootReducer';

import LoadingSpinner from 'images/loading_spinner.gif';
import { ColumnInfo } from 'constants/types';
import { updateDashboard } from './dashboardPageUtils';
import { connect } from 'react-redux';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    pageHeaderContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: `${theme.spacing(8)}px ${theme.spacing(6)}px`,
    },
    pageHeaderLeftContent: {
      fontWeight: 500,
      fontSize: 24,
    },
    pageHeaderRightContent: {
      display: 'flex',
      flexDirection: 'row',
    },
    refreshButton: {
      padding: `7px 14px`,
      borderRadius: 4,
      backgroundColor: theme.palette.grey.slight,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.black,
      fontWeight: 500,
      '&:hover': {
        backgroundColor: 'rgba(167,182,194,.3)',
        cursor: 'pointer',
      },
    },
    shareButton: {
      padding: `7px 14px`,
      borderRadius: 4,
      backgroundColor: theme.palette.exploBlue,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.white,
      fontWeight: 500,
      marginLeft: theme.spacing(6),
      marginRight: theme.spacing(6),
      '&:hover': {
        cursor: 'pointer',
      },
    },
    loadingBody: {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    loadingSpinner: {
      width: 75,
    },
    dashboardContainer: {
      maxWidth: 1100,
      margin: 'auto',
      marginTop: theme.spacing(12),
      padding: `0 ${theme.spacing(12)}px`,
    },
    dashboardElement: {
      backgroundColor: theme.palette.white,
      borderRadius: 4,
      border: `1px solid ${theme.palette.grey.border}`,
    },
    shareUrlTextCopy: {
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
    },
    shareUrlInput: {
      marginRight: theme.spacing(2),
    },
    loadingPage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      width: '100%',
    },
    pageBanner: {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `${theme.spacing(3)}px ${theme.spacing(6)}px`,
    },
    pageBannerText: {
      fontSize: 18,
      color: theme.palette.white,
      textAlign: 'center',
    },
    pageBannerBtn: {
      '&.bp3-button': {
        boxShadow: 'none',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
      },
    },
    bodyContainer: {
      height: 'calc(100vh - 64px)',
      overflow: 'auto',
    },
  });

type State = {
  shareDashboardModalOpen: boolean;
  dashboardId: string;
  deleteDashboardModalOpen: boolean;
  renameDashboardModalOpen: boolean;
  confgureDashboardFilterModalOpen: boolean;
  shareDashboardHash: string | null;
  editDashboardModeOn: boolean;
  currentlyEditingFilterId?: number;
  filterEditSubmissionClicked: boolean;
};

type MatchParams = {
  dashboardId: string;
};

type Props = WithStyles<typeof styles> &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams>;
class DashboardPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const {
      refreshDashboardOperation,
      fetchDashboardOperationRowCount,
      refreshDashboard,
    } = this.props;

    props.fetchDashboard({ id: props.match.params.dashboardId }, (response: any) => {
      updateDashboard(
        response.dashboard,
        refreshDashboardOperation,
        fetchDashboardOperationRowCount,
        refreshDashboard,
        response.dashboard.id,
      );
    });

    this.state = {
      dashboardId: props.match.params.dashboardId,
      deleteDashboardModalOpen: false,
      renameDashboardModalOpen: false,
      shareDashboardModalOpen: false,
      confgureDashboardFilterModalOpen: false,
      shareDashboardHash: null,
      editDashboardModeOn: false,
      currentlyEditingFilterId: undefined,
      filterEditSubmissionClicked: false,
    };
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const nextDashboardId = nextProps.match.params.dashboardId;

    if (nextDashboardId !== prevState.dashboardId) {
      nextProps.fetchDashboard({ id: nextDashboardId }, (response: any) => {
        updateDashboard(
          response.dashboard,
          nextProps.refreshDashboardOperation,
          nextProps.fetchDashboardOperationRowCount,
          nextProps.refreshDashboard,
          response.dashboard.id,
        );
      });
      return {
        dashboardId: nextDashboardId,
      };
    }
    return null;
  }

  componentDidMount() {
    pageView('Dashboard');
  }

  render() {
    const { dashboardData, dashboardListLoading, classes } = this.props;
    const { dashboardId } = this.state;
    const dashboard = dashboardListLoading
      ? undefined
      : find(dashboardData.dashboardList, ['id', parseInt(dashboardId)]);

    return dashboard ? (
      <div className={classes.root}>
        {this.renderPageHeader()}
        <div className={classes.bodyContainer}>
          {this.renderEditFiltersBanner()}
          {this.renderDashboardFilters()}
          {this.renderDashboardBody(dashboardData)}
          {this.renderShareDashboardModal()}
          {this.renderDeleteDashboardModal()}
          {this.renderRenameDashboardModal()}
          {this.renderAddDashboardFilterModal()}
        </div>
      </div>
    ) : (
      <div className={classes.loadingPage}>
        <img className={classes.loadingSpinner} src={LoadingSpinner} alt="loading spinner" />
      </div>
    );
  }

  renderPageHeader = () => {
    const {
      classes,
      dashboardData,
      refreshDashboardOperation,
      fetchDashboardOperationRowCount,
      refreshDashboard,
      dashboardListLoading,
    } = this.props;
    const { dashboardId } = this.state;
    const dashboard = dashboardListLoading
      ? undefined
      : find(dashboardData.dashboardList, ['id', parseInt(dashboardId)]);
    return (
      <div className={classes.pageHeaderContainer}>
        <div className={classes.pageHeaderLeftContent}>{dashboard!.name}</div>

        <div className={classes.pageHeaderRightContent}>
          <div
            onClick={() => {
              updateDashboard(
                dashboardData.data!,
                refreshDashboardOperation,
                fetchDashboardOperationRowCount,
                refreshDashboard,
                dashboardData.selectedDashBoardId,
              );
            }}
            className={classes.refreshButton}>
            Refresh Data
          </div>

          <div onClick={this.handleShareDashboard} className={classes.shareButton}>
            Share Dashboard
          </div>
          <Popover position={Position.BOTTOM_RIGHT}>
            <Button minimal icon="filter" />
            <Menu>
              <MenuItem
                disabled={!dashboardData}
                icon="filter"
                text="Add Filter"
                onClick={this.addDashboardFilter}
              />
              {dashboardData.data && dashboardData.data.filters.length > 0 && (
                <MenuItem
                  icon="edit"
                  text="Edit Filters"
                  onClick={() => this.setState({ editDashboardModeOn: true })}
                />
              )}
            </Menu>
          </Popover>
        </div>
      </div>
    );
  };

  renderPageHeaderActions = () => {
    const {
      dashboardData,
      refreshDashboardOperation,
      fetchDashboardOperationRowCount,
      refreshDashboard,
    } = this.props;

    return (
      <Popover>
        <Button rightIcon={'caret-down'}>Actions</Button>
        <Menu>
          {dashboardData && (
            <MenuItem
              icon="refresh"
              text="Refresh Dashboard"
              onClick={() =>
                updateDashboard(
                  dashboardData.data!,
                  refreshDashboardOperation,
                  fetchDashboardOperationRowCount,
                  refreshDashboard,
                  dashboardData.selectedDashBoardId,
                )
              }
            />
          )}
          {dashboardData && (
            <MenuItem icon="people" text="Share Dashboard" onClick={this.handleShareDashboard} />
          )}
          <MenuItem
            disabled={!dashboardData}
            icon="filter"
            text="Add Filter"
            onClick={this.addDashboardFilter}
          />
          {dashboardData.data && dashboardData.data.filters.length > 0 && (
            <MenuItem
              icon="edit"
              text="Edit Filters"
              onClick={() => this.setState({ editDashboardModeOn: true })}
            />
          )}
        </Menu>
      </Popover>
    );
  };

  handleShareDashboard = () => {
    const { dashboardData, getDashboardShareHash } = this.props;

    getDashboardShareHash({ id: dashboardData.data!.id }, (response) => {
      this.setState({ shareDashboardHash: response.share_hash });
    });
    this.setState({ shareDashboardModalOpen: true, shareDashboardHash: null });
  };

  renderEditFiltersBanner = () => {
    const { editDashboardModeOn } = this.state;
    const {
      classes,
      dashboardData,
      refreshDashboardOperation,
      fetchDashboardOperationRowCount,
      refreshDashboard,
    } = this.props;

    if (editDashboardModeOn) {
      return (
        <div className={classes.pageBanner}>
          <div className={classes.pageBannerText}>You are editing the dashboard</div>
          <Button
            className={classes.pageBannerBtn}
            onClick={() => {
              this.setState({ filterEditSubmissionClicked: true });
              if (!dashboardData.data) return;
              if (
                _.find(dashboardData.data.filters, (filter: DashboardFilterType) => {
                  return Object.keys(filter.operation_fields).length === 0;
                })
              ) {
                AppToaster.show({
                  message: 'Please select columns to filter on for the highlighted filters',
                  icon: 'error',
                  timeout: 5000,
                  intent: Intent.DANGER,
                });
              } else {
                updateDashboard(
                  dashboardData.data!,
                  refreshDashboardOperation,
                  fetchDashboardOperationRowCount,
                  refreshDashboard,
                  dashboardData.selectedDashBoardId,
                );
                this.setState({ filterEditSubmissionClicked: false });
                this.setState({ editDashboardModeOn: false });
              }
            }}>
            Done
          </Button>
        </div>
      );
    }
  };

  renderDashboardFilters = () => {
    const {
      editDashboardModeOn,
      currentlyEditingFilterId,
      filterEditSubmissionClicked,
    } = this.state;
    const { dashboardData } = this.props;

    if (!dashboardData.data) return;

    return (
      <DashboardFilterContainer
        filters={dashboardData.data.filters}
        editMode={editDashboardModeOn}
        onEditingFilterSelected={(filterId: number) =>
          this.setState({ currentlyEditingFilterId: filterId })
        }
        onRemoveFilterSelected={this.onRemoveFilter}
        onFilterValueSubmit={this.onFilterValueSubmit}
        onFilterOperatorSelect={this.onFilterOperatorSelect}
        currentlyEditingFilterId={currentlyEditingFilterId}
        filterEditSubmissionClicked={filterEditSubmissionClicked}
      />
    );
  };

  addDashboardFilter = () => {
    this.setState({ confgureDashboardFilterModalOpen: true });
  };

  onFilterValueSubmit = (filterId: number, newValue: string) => {
    const {
      dashboardData,
      refreshDashboardOperation,
      fetchDashboardOperationRowCount,
      refreshDashboard,
    } = this.props;
    if (!dashboardData.data) return;

    this.props.updateFilterValue(filterId, newValue);

    // the reducer updates this when calling this.props.updateFilterValue() above
    // however that doesn't update synchronously and so we need to set manullay so that
    // the call to updateDashboard() works
    const filter = _.findWhere(dashboardData.data.filters, {
      id: filterId,
    });
    filter!.value = newValue;

    const updatedOperationIds = _.map(Object.keys(filter!.operation_fields), (id) => parseInt(id));

    updateDashboard(
      dashboardData.data!,
      refreshDashboardOperation,
      fetchDashboardOperationRowCount,
      refreshDashboard,
      dashboardData.selectedDashBoardId,
      updatedOperationIds,
    );
  };

  onFilterOperatorSelect = (filterId: number, newOperator: any) => {
    const {
      dashboardData,
      refreshDashboardOperation,
      fetchDashboardOperationRowCount,
      refreshDashboard,
    } = this.props;
    if (!dashboardData.data) return;

    this.props.updateFilterOperator(filterId, newOperator);

    // the reducer updates this when calling this.props.updateFilterValue() above
    // however that doesn't update synchronously and so we need to set manullay so that
    // the call to updateDashboard() works
    const filter = _.findWhere(dashboardData.data.filters, {
      id: filterId,
    });
    filter!.filter_operator = newOperator;

    if (filter!.value !== undefined && filter!.value !== '') {
      const updatedOperationIds = _.map(Object.keys(filter!.operation_fields), (id) =>
        parseInt(id),
      );
      updateDashboard(
        dashboardData.data!,
        refreshDashboardOperation,
        fetchDashboardOperationRowCount,
        refreshDashboard,
        dashboardData.selectedDashBoardId,
        updatedOperationIds,
      );
    }
  };

  refreshDashboardPanel = (operationId: number) => {
    const { dashboardData, refreshDashboardOperation } = this.props;

    const operationIds = dashboardData.data!.operations.map((op) => op.id);
    refreshDashboardOperation({
      id: operationId,
      postData: {
        updating_op_ids: operationIds,
      },
    });
  };

  getPageHeaderTitleActions = (): React.ComponentProps<typeof MenuItem>[] => {
    return [
      {
        text: 'Rename Dashboard',
        icon: 'edit',
        onClick: () => this.setState({ renameDashboardModalOpen: true }),
      },
      {
        text: 'Delete Dashboard',
        icon: 'trash',
        intent: Intent.DANGER,
        onClick: () => this.setState({ deleteDashboardModalOpen: true }),
      },
    ];
  };

  renderDeleteDashboardModal = () => {
    const { deleteDashboard, history, switchSelectedDataSourceId, dataSourceList } = this.props;
    const { deleteDashboardModalOpen, dashboardId } = this.state;

    if (!deleteDashboardModalOpen) {
      return;
    }

    return (
      <ConfirmationModal
        modalOpen={deleteDashboardModalOpen}
        closeModal={() => this.setState({ deleteDashboardModalOpen: false })}
        modalTitle="Are you sure you want to delete this dashboard?"
        modalText="This action cannot be undone"
        submitBtnText="Delete"
        danger
        onSubmit={() => {
          deleteDashboard(
            { id: dashboardId },
            (response) => {
              AppToaster.show({
                message: 'The dashboard was successfully deleted',
                icon: 'tick',
                timeout: 3000,
                intent: Intent.SUCCESS,
              });
              history.push('/home');
              switchSelectedDataSourceId(dataSourceList[0].id);
            },
            (response) => {
              AppToaster.show({
                // Sometimes an object is inputted and, if not, the direct error message is inputed.
                message: response['msg'] || response,
                icon: 'error',
                timeout: 5000,
                intent: Intent.DANGER,
              });
            },
          );
        }}
      />
    );
  };

  renderRenameDashboardModal = () => {
    const { renameDashboard, dashboardData, dashboardPageLoading } = this.props;
    const { renameDashboardModalOpen, dashboardId } = this.state;

    if (!renameDashboardModalOpen) {
      return;
    }

    return (
      <TextFieldModal
        resourceName={
          !dashboardPageLoading
            ? dashboardData.data
              ? dashboardData.data.name
              : undefined
            : undefined
        }
        modalOpen={renameDashboardModalOpen}
        closeModal={() => this.setState({ renameDashboardModalOpen: false })}
        modalTitle="Rename this dashboard"
        textFieldLabel="Dashboard Name"
        buttonName="Save"
        onSubmit={(newDashboardTitle) => {
          renameDashboard({
            id: dashboardId,
            postData: { name: newDashboardTitle },
          });
        }}
      />
    );
  };

  renderDashboardBody = (dashboardData: DashboardReducerState) => {
    const { classes, dashboardPageLoading } = this.props;

    if (dashboardData.error) {
      return this.renderFailedDashboard();
    } else if (dashboardPageLoading) {
      return (
        <div className={classes.loadingBody}>
          <img className={classes.loadingSpinner} src={LoadingSpinner} alt="loading spinner" />
        </div>
      );
    } else {
      return this.renderDashboard();
    }
  };

  renderFailedDashboard = () => {
    return (
      <div style={{ height: '100vh' }}>
        <NonIdealState
          icon={'error'}
          title="Dashboard not found."
          description={"The dashboard either doesn't exist or hasn't been shared with you."}
        />
      </div>
    );
  };

  renderDashboard = () => {
    const { editDashboardModeOn, currentlyEditingFilterId } = this.state;
    const { dashboardData } = this.props;

    if (dashboardData.data)
      return (
        <DashboardLayout
          dashboardData={dashboardData.data}
          refreshDashboardPanel={this.refreshDashboardPanel}
          editFiltersOn={editDashboardModeOn && currentlyEditingFilterId !== undefined}
          editingFilter={this.getCurrentDashboardFilter()}
          onFilterColumnSelect={this.onFilterColumnSelect}
        />
      );
  };

  getCurrentDashboardFilter = () => {
    const { dashboardData } = this.props;
    const { editDashboardModeOn, currentlyEditingFilterId } = this.state;
    if (!editDashboardModeOn || currentlyEditingFilterId === undefined || !dashboardData.data)
      return;

    return _.findWhere(dashboardData.data.filters, { id: currentlyEditingFilterId });
  };

  renderShareDashboardModal = () => {
    const { shareDashboardModalOpen, shareDashboardHash } = this.state;

    return (
      <Dialog
        isOpen={shareDashboardModalOpen}
        onClose={() => this.setState({ shareDashboardModalOpen: false })}
        title="Share Dashboard">
        <div className={Classes.DIALOG_BODY}>
          {shareDashboardHash === null ? (
            <Spinner intent={Intent.PRIMARY} />
          ) : (
            this.renderShareDashboardText()
          )}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => this.setState({ shareDashboardModalOpen: false })}>Done</Button>
          </div>
        </div>
      </Dialog>
    );
  };

  renderShareDashboardText = () => {
    const { classes } = this.props;
    const { shareDashboardHash } = this.state;
    const shareUrl = `${process.env.REACT_APP_URL}share/${shareDashboardHash}/dashboard`;
    return (
      <div>
        <div>Anyone with the link below can view the dashboard</div>
        <div className={classes.shareUrlTextCopy}>
          <InputGroup className={classes.shareUrlInput} value={shareUrl} fill />{' '}
          <CopyToClipboard
            text={shareUrl}
            onCopy={() =>
              AppToaster.show({
                icon: 'clipboard',
                intent: Intent.PRIMARY,
                message: 'Dashboard URL copied to clipboard',
                timeout: 5000,
              })
            }>
            <Button icon="duplicate" minimal />
          </CopyToClipboard>
        </div>
      </div>
    );
  };

  renderAddDashboardFilterModal = () => {
    const { confgureDashboardFilterModalOpen } = this.state;
    if (!confgureDashboardFilterModalOpen) return;

    return (
      <ConfigureDashboardFilterModal
        isOpen={confgureDashboardFilterModalOpen}
        onClose={() => this.setState({ confgureDashboardFilterModalOpen: false })}
        onFilterSubmit={this.onFilterConfigurationSubmit}
        acceptableFilterTypes={this.getAcceptableFilterTypes()}
      />
    );
  };

  getAcceptableFilterTypes = (): string[] => {
    const { dashboardData } = this.props;
    const acceptableFilterTypes: string[] = [];

    if (!dashboardData.data) return acceptableFilterTypes;
    dashboardData.data.operations.forEach((operation: Operation) => {
      operation.results_dataset.cached_schema &&
        operation.results_dataset.cached_schema.forEach((columnInfo: ColumnInfo) => {
          acceptableFilterTypes.push(columnInfo.type);
        });
    });

    return acceptableFilterTypes;
  };

  onFilterConfigurationSubmit = (filterName: string, filterType: string) => {
    const { dashboardData, createDashboardFilter } = this.props;

    createDashboardFilter(
      {
        id: dashboardData.data!.id,
        postData: {
          name: filterName,
          type: filterType,
        },
      },
      (data) => {
        this.setState({
          editDashboardModeOn: true,
          currentlyEditingFilterId: data.dashboard_filter.id,
        });
      },
    );
  };

  onRemoveFilter = (filterId: number) => {
    const { dashboardData, deleteDashboardFilter } = this.props;
    deleteDashboardFilter({ id: dashboardData.data!.id, postData: { filter_id: filterId } });
  };

  onFilterColumnSelect = (
    filterId: number,
    operationId: number,
    colName: string,
    colType: string,
    sourceType: string,
  ) => {
    const { dashboardData, updateDashboardFilterField } = this.props;

    updateDashboardFilterField({
      id: dashboardData.data!.id,
      postData: {
        filter_id: filterId,
        operation_id: operationId,
        col_name: colName,
        col_type: colType,
        source_type: sourceType,
      },
    });
  };
}

const dashboardPageLoadingSelector = createLoadingSelector([ACTION.FETCH_DASHBOARD]);
const isTitleLoadingSelector = createLoadingSelector([
  ACTION.RENAME_DASHBOARD,
  ACTION.DELETE_DASHBOARD,
]);
const isDashboardListLoadingSelector = createLoadingSelector([ACTION.FETCH_DASHBOARD_LIST]);

const mapStateToProps = (state: ReduxState) => ({
  isTitleLoading: isTitleLoadingSelector(state),
  dashboardPageLoading: dashboardPageLoadingSelector(state),
  dashboardListLoading: isDashboardListLoadingSelector(state),
  dashboardData: state.dashboardData,
  dataSourceList: state.dataSourceList,
});

const mapDispatchToProps = {
  fetchDashboard,
  refreshDashboardOperation,
  refreshDashboard,
  getDashboardShareHash,
  renameDashboard,
  deleteDashboard,
  updateDashboardLayout,
  updateOperationTitle,
  removeOperationFromDashboard,
  switchSelectedDataSourceId,
  createDashboardFilter,
  deleteDashboardFilter,
  updateDashboardFilterField,
  updateFilterValue,
  updateFilterOperator,
  fetchDashboardOperationRowCount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(DashboardPage)));

/** @format */

import { defineAction } from 'actions/actionUtils';
import { ACTION } from 'actions/types';

export const fetchCovidOperations = defineAction(
  ACTION.FETCH_COVID_OPERATIONS,
  'covid',
  'operations',
  'GET',
);

export const fetchCovidDailyStats = defineAction(
  ACTION.FETCH_COVID_DAILY_STATS,
  'covid',
  'get_daily_stats',
  'GET',
);

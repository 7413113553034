/** @format */

import { defineAction } from 'actions/actionUtils';

export const fetchDatasetData = defineAction('FETCH_DATASET_DATA', 'datasets', '', 'GET');

export const getDatasetPreview = defineAction('GET_DATASET_PREVIEW', 'datasets', 'preview', 'GET');

export const getEphemeralDatasetPreview = defineAction(
  'GET_EPHEMERAL_DATASET_PREVIEW',
  'datasets',
  'ephemeral_preview',
  'POST',
);

export const fetchDatasetRelatedFlows = defineAction(
  'FETCH_DATASET_RELATED_FLOWS',
  'datasets',
  'related_flows',
  'GET',
);

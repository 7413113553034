/** @format */

import React from 'react';
import cx from 'classnames';
import _ from 'underscore';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { ReduxState } from 'reducers/rootReducer';
import { ControlGroup, Button, InputGroup, Intent, Spinner } from '@blueprintjs/core';
import { AppToaster } from 'toaster';

import ViewOnlyOperation from 'components/flowBuilder/viewOnlyOperation';
import NavHeader from 'components/landingPage/navHeader';
import Footer from 'components/landingPage/footer';

import { pageView } from 'analytics/exploAnalytics';
import { fetchCovidOperations, fetchCovidDailyStats } from 'actions/covidActions';
import { Operation, ReportSection } from 'actions/types';

import { addEmailToWaitlist } from 'utils/landingPageUtils';
import { numberWithCommas } from 'utils/general';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';

const styles = (theme: Theme) => ({
  root: {
    minHeight: '100vh',
    backgroundColor: theme.palette.primary.veryLight,
  },
  loadingSpinner: {
    marginTop: theme.spacing(8),
  },
  reportContainer: {
    marginTop: theme.spacing(10),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column' as 'column',
    padding: `0 ${theme.spacing(6)}px`,
  },
  sectionContainer: {
    width: '100%',
    maxWidth: 1100,
    marginBottom: theme.spacing(25),
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: 'bold' as 'bold',
    marginBottom: theme.spacing(3),
  },
  sectionDescription: {
    marginBottom: theme.spacing(8),
  },
  sectionOperationsContainer: {},
  padOperations: {
    marginBottom: theme.spacing(8),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  signupPopup: {
    border: `1px solid ${theme.palette.grey.border}`,
    position: 'fixed' as 'fixed',
    bottom: 100,
    left: 0,
    backgroundColor: theme.palette.white,
    width: 325,
    boxShadow: theme.customShadows.basic,
    padding: theme.spacing(4),
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,

    transition: 'all 0.75s ease-in',

    '&.hidden': {
      left: -350,
    },
  },
  signupPopupHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContents: 'space-betwee',
  },
  signupPopupText: {
    marginBottom: theme.spacing(3),
    fontWeight: 'bold' as 'bold',
    fontSize: 16,
    marginRight: theme.spacing(2),
  },
  statPageContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column' as 'column',
    padding: `0 ${theme.spacing(6)}px`,
    marginTop: theme.spacing(4),
  },
  statSectionContainer: {
    width: '100%',
    maxWidth: 1100,
    marginBottom: theme.spacing(10),
  },
  dailyStatsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: theme.spacing(10),
  },
  statContainer: {
    backgroundColor: theme.palette.white,
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    borderRadius: 4,
    border: `1px solid ${theme.palette.grey.border}`,
  },
  statTitle: {
    fontWeight: 'bold' as 'bold',
    fontSize: 20,
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey.border}`,
    width: '100%',
    textAlign: 'center' as 'center',
  },
  statIncrease: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  statNumber: {
    fontSize: 16,
    marginBottom: theme.spacing(2),
  },
  sourcesText: {},
});

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles>;

type State = {
  signupPopupOpen: boolean;
  signupEmail: string;
};

class CovidPage extends React.Component<Props, State> {
  state: State = {
    signupPopupOpen: false,
    signupEmail: '',
  };

  constructor(props: Props) {
    super(props);

    props.fetchCovidOperations();
    props.fetchCovidDailyStats();
  }

  componentDidMount() {
    pageView('Covid');

    setTimeout(() => {
      this.setState({ signupPopupOpen: true });
    }, 5000);
  }

  render() {
    const { classes, userLoggedIn } = this.props;

    return (
      <div className={cx('App', classes.root)}>
        <NavHeader notHomePage={true} userLoggedIn={userLoggedIn} />

        {this.renderCovidDailyStats()}
        {this.renderCovidDashboard()}
        {this.renderSources()}
        {this.renderSignupPopup()}

        <Footer />
      </div>
    );
  }

  renderSignupPopup = () => {
    const { classes } = this.props;
    const { signupPopupOpen, signupEmail } = this.state;

    return (
      <div className={cx(classes.signupPopup, { hidden: !signupPopupOpen })}>
        <div className={classes.signupPopupHeader}>
          <div className={classes.signupPopupText}>
            Interested in easily exploring your own data?
          </div>
          <Button minimal icon="cross" onClick={() => this.setState({ signupPopupOpen: false })} />
        </div>
        <ControlGroup fill={true} vertical={false}>
          <InputGroup
            large
            value={signupEmail}
            onChange={(e: any) => this.setState({ signupEmail: e.target.value })}
            placeholder="Email Address"
          />
          <Button large intent={Intent.PRIMARY} onClick={this.emailSubmitted}>
            Join Beta
          </Button>
        </ControlGroup>
      </div>
    );
  };

  emailSubmitted = (e: any) => {
    const { signupEmail } = this.state;

    addEmailToWaitlist(
      signupEmail,
      () => {
        this.setState({ signupEmail: '' });
        window.open('https://form.typeform.com/to/jyPnu9GP', '_blank');
      },
      (response: any) => {
        AppToaster.show({
          message: response.msg,
          icon: 'issue',
          timeout: 5000,
          intent: Intent.DANGER,
        });
      },
      'covid_page',
    );
    e.preventDefault();
  };

  renderCovidDailyStats = () => {
    const { classes, covidData, covidStatsLoading } = this.props;

    if (covidStatsLoading || !covidData.stats) {
      return <Spinner className={classes.loadingSpinner} intent={Intent.PRIMARY} />;
    }

    return (
      <div className={classes.statPageContainer}>
        <div className={classes.statSectionContainer}>
          <div className={classes.sectionTitle}>Summary</div>
          <div className={cx(classes.sectionDescription, 'bp3-text-muted')}>
            Data as of: {covidData.dateFor}
          </div>
          <div className={classes.dailyStatsContainer}>
            <div className={classes.statContainer}>
              <div className={classes.statTitle}>US Cases</div>
              <div className={classes.statIncrease}>
                +{numberWithCommas(covidData.stats.positive_increase__sum)}
              </div>
              <div className={classes.statNumber}>
                {numberWithCommas(covidData.stats.positive__sum)}
              </div>
            </div>
            <div className={classes.statContainer}>
              <div className={classes.statTitle}>US Deaths</div>
              <div className={classes.statIncrease}>
                +{numberWithCommas(covidData.stats.death_increase__sum)}
              </div>
              <div className={classes.statNumber}>
                {numberWithCommas(covidData.stats.death__sum)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderCovidDashboard = () => {
    const { covidData, classes, covidOperationsLoading } = this.props;

    if (!covidOperationsLoading && covidData.report) {
      return (
        <div className={classes.reportContainer}>
          {_.sortBy(covidData.report.sections, (section) => section.order).map(
            this.renderReportSection,
          )}
        </div>
      );
    }
  };

  renderReportSection = (section: ReportSection, index: number) => {
    const { classes } = this.props;

    return (
      <div className={classes.sectionContainer} key={`covid-section-${index}`}>
        <div className={classes.sectionTitle}>{section.title}</div>
        <div
          className={cx(classes.sectionDescription, 'bp3-text-muted')}
          dangerouslySetInnerHTML={{ __html: section.description }}></div>
        <div className={classes.sectionOperationsContainer}>
          {section.operations.map((operation) =>
            this.renderOperation(operation, section.operations.length > 1),
          )}
        </div>
      </div>
    );
  };

  renderOperation = (operation: Operation, areMultipleSections: boolean) => {
    const { classes } = this.props;

    return (
      <ViewOnlyOperation
        key={`covid-operation-${operation.id}`}
        className={cx({ [classes.padOperations]: areMultipleSections })}
        resultsData={operation.results_dataset && operation.results_dataset.cached_preview}
        operationInstructions={operation.instructions}
        operationType={operation.operation_type}
        resultsJobError={operation.results_job_error}
        resultSchema={
          operation.results_dataset
            ? operation.results_dataset.cached_schema
              ? operation.results_dataset.cached_schema
              : undefined
            : undefined
        }
        numRows={operation.results_dataset!.total_row_count}
        flowId={operation.flow_id}
        title={operation.title}
      />
    );
  };

  renderSources = () => {
    const { classes } = this.props;
    return (
      <div className={classes.sectionContainer}>
        <div className={classes.sectionTitle}>Sources</div>
        <div className={classes.sourcesText}>
          <a href="https://covidtracking.com/" target="_blank" rel="noopener noreferrer">
            COVID Tracking Project
          </a>
        </div>
        <div className={classes.sourcesText}>
          <a
            href="https://en.wikipedia.org/wiki/List_of_battles_with_most_United_States_military_fatalities"
            target="_blank"
            rel="noopener noreferrer">
            US War Casualty data
          </a>
        </div>
        <div className={classes.sourcesText}>
          <a
            href="https://fred.stlouisfed.org/series/ICSA"
            target="_blank"
            rel="noopener noreferrer">
            Unemployment claims
          </a>
        </div>
        <div className={classes.sourcesText}>
          <a href="http://www.sca.isr.umich.edu/" target="_blank" rel="noopener noreferrer">
            Consumer Confidence Index
          </a>
        </div>
      </div>
    );
  };
}
const covidOperationsLoadingSelector = createLoadingSelector([ACTION.FETCH_COVID_OPERATIONS]);
const covidStatsLoadingSelector = createLoadingSelector([ACTION.FETCH_COVID_DAILY_STATS]);

const mapStateToProps = (state: ReduxState) => ({
  userLoggedIn: state.currentUser.id !== null && state.currentUser.id !== undefined,
  covidData: state.covidData,
  covidOperationsLoading: covidOperationsLoadingSelector(state),
  covidStatsLoading: covidStatsLoadingSelector(state),
});

const mapDispatchToProps = {
  fetchCovidOperations,
  fetchCovidDailyStats,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CovidPage));

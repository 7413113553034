/** @format */

import React from 'react';
import _ from 'underscore';
import { withStyles, WithStyles } from '@material-ui/styles';

import { NonIdealState, H4, Spinner } from '@blueprintjs/core';

import BarChartGraph from 'components/flowBuilder/graphs/barChartGraph';
import LineOrBarChart from 'components/flowBuilder/graphs/lineOrBarChart';

import { OPERATION_TYPES, OPERATIONS_BY_ID } from 'constants/flowConstants';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { Schema, TableData } from 'constants/types';
import { isMultiAxis } from 'utils/graphUtils';
import FlowGraphStepActions from './flowGraphStepActions';
import { Operation, Dataset } from 'actions/types';
import { ReduxState } from 'reducers/rootReducer';
import { connect } from 'react-redux';

const styles = (theme: Theme) =>
  createStyles({
    tablePadding: {
      padding: `${theme.spacing(6)}px ${theme.spacing(2)}px`,
      position: 'relative',
    },
    errorBody: {
      padding: theme.spacing(3),
      color: theme.palette.dangerRed,
    },
    nonConfinguredMsg: {
      margin: `${theme.spacing(17)}px 0px`,
    },
    operationInfoContainer: {
      marginLeft: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
    chartTitle: {
      marginBottom: 0,
    },
    loadingSpinner: {
      marginLeft: theme.spacing(3),
    },
    bigLoadingSpinner: {
      height: 300,
    },
  });

type PassedProps = {
  resultsJobError?: any;
  operation: Operation;
  operationType: string | number;
  operationInstructions?: any;
  graphData?: TableData;
  resultSchema?: Schema;
  addDatasetModalOpen: boolean;
  addStepModalOpen: boolean;
  isSelected: boolean;
  sourceDataset?: Dataset;
};

type Props = PassedProps & WithStyles<typeof styles> & ReturnType<typeof mapStateToProps>;

type State = {
  editDrawerOpen: boolean;
};

class GraphNode extends React.Component<Props, State> {
  state: State = {
    editDrawerOpen: this.props.recentlyAddedOpId === this.props.operation.id,
  };

  shouldComponentUpdate = (nextProps: Props, nextState: State) => {
    if (
      !_.isEqual(this.props, nextProps) ||
      this.state.editDrawerOpen !== nextState.editDrawerOpen
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        className={classes.tablePadding}
        // {...dragHandleProps}
        onDoubleClick={() => {
          this.setState({ editDrawerOpen: true });
        }}>
        {this.renderOperationtInfo()}
        {this.renderStepActions()}
        <div>{this.renderGraph()}</div>
      </div>
    );
  }

  renderOperationtInfo = () => {
    const { classes, operationType } = this.props;
    const opInfo = OPERATIONS_BY_ID[operationType];
    return (
      <div className={classes.operationInfoContainer}>
        <H4 className={classes.chartTitle}>{opInfo.name}</H4>
      </div>
    );
  };

  renderStepActions = () => {
    const {
      operation,
      addDatasetModalOpen,
      addStepModalOpen,
      isSelected,
      sourceDataset,
    } = this.props;
    const { editDrawerOpen } = this.state;

    return (
      <FlowGraphStepActions
        operation={operation}
        addDatasetModalOpen={addDatasetModalOpen}
        addStepModalOpen={addStepModalOpen}
        isSelected={isSelected}
        sourceDataset={sourceDataset}
        editDrawerOpen={editDrawerOpen}
        setEditDrawerOpen={(editDrawerOpen) => this.setState({ editDrawerOpen })}
      />
    );
  };
  renderGraph = () => {
    const {
      classes,
      graphData,
      resultSchema,
      operationType,
      operationInstructions,
      resultsJobError,
      operation,
    } = this.props;

    if (operation.compute_loading) {
      return <Spinner size={Spinner.SIZE_STANDARD} className={classes.bigLoadingSpinner} />;
    }

    if (resultsJobError || this.operationInErrorState()) {
      return (
        <div className={classes.errorBody}>
          Check that the columns being used have not changed. If this problem continues to exist,
          please reach out to support@explo.co.
        </div>
      );
    }

    if (!graphData || !resultSchema) {
      const operation = OPERATION_TYPES[operationType];

      return (
        <NonIdealState
          className={classes.nonConfinguredMsg}
          icon={operation.icon as any}
          title="This graph is not yet configured"
          description="Click the edit button in the menu to configure the graph."
        />
      );
    }

    if (graphData.length === 0) {
      return (
        <div className={classes.errorBody}>
          Check that the previous step is correctly configured and then rerun this operation.
        </div>
      );
    }

    if (operationType === OPERATION_TYPES.BAR_CHART.id) {
      const aggedOnColumnName = operationInstructions.aggregation.aggedOnColumn
        ? operationInstructions.aggregation.aggedOnColumn.name ||
          operationInstructions.aggregation.aggedOnColumn
        : operationInstructions.aggregation.aggedOnColumn;
      const addType = operationInstructions.aggregation.type
        ? operationInstructions.aggregation.type.id || operationInstructions.aggregation.type
        : operationInstructions.aggregation.type;
      return (
        <BarChartGraph
          aggedOnColumn={aggedOnColumnName || ''}
          data={graphData}
          schema={resultSchema}
          type={addType || ''}
          xAxis={operationInstructions.xAxisColumn.name}
        />
      );
    } else if (operationType === OPERATION_TYPES.LINE_CHART.id) {
      return (
        <LineOrBarChart
          chartType={'line'}
          multiYAxis={isMultiAxis(operationInstructions)}
          xAxis={operationInstructions.xAxisColumn.name}
          xAxisFormat={operationInstructions.xAxisFormat && operationInstructions.xAxisFormat.id}
          columns={operationInstructions.lineColumns}
          data={graphData}
          schema={resultSchema}
        />
      );
    } else if (operationType === OPERATION_TYPES.GROUPED_BAR_CHART.id) {
      return (
        <LineOrBarChart
          chartType={'bar'}
          multiYAxis={isMultiAxis(operationInstructions)}
          xAxis={operationInstructions.xAxisColumn.name}
          xAxisFormat={operationInstructions.xAxisFormat && operationInstructions.xAxisFormat.id}
          columns={operationInstructions.lineColumns}
          data={graphData}
          schema={resultSchema}
        />
      );
    }
  };

  operationInErrorState = () => {
    const { graphData, operationInstructions } = this.props;
    return graphData && !operationInstructions.xAxisColumn;
  };
}

const mapStateToProps = (state: ReduxState) => ({
  recentlyAddedOpId: state.flowData.recentlyAddedOpId,
});

export default connect(mapStateToProps, {})(withStyles(styles)(GraphNode));

/** @format */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';

import PrivateRoute from 'components/privateRoute';
import ShareRoute from 'components/shareRoute.tsx';
import SignUpPage from 'pages/signUpPageV2.tsx';
import SignInPage from 'pages/signInPageV2.tsx';
import CheckYourEmailPage from 'pages/checkYourEmailPageV2.tsx';
import VerifyEmailPage from 'pages/verifyEmailPageV2.tsx';
import ExploHomePage from 'pages/homePageV2.tsx';
import EmbeddoHomePage from 'pages/embeddoHomePage.tsx';
import AboutUsPage from 'pages/aboutUsPageV2.tsx';
import AlertingPage from 'pages/alertingPage.tsx';
import HomeAppPage from 'pages/homeAppPage.tsx';
import DatasetPage from 'pages/datasetPage.tsx';
import FlowGraphPageV3 from 'pages/flowGraphPage/flowGraphPageV3.tsx';
import DataSourceListPage from 'pages/dataSourceListPage/dataSourceListPage.tsx';
import PublicFlowGraph from 'pages/publicFlowGraph.tsx';
import DashboardPage from 'pages/dashboardPage/dashboardPage.tsx';
import DataSourcePage from 'pages/dataSourcePage.tsx';
import AddDataSourcePage from 'pages/addDataSourcePage';
import JoinTeamPage from 'pages/joinTeamPageV2.tsx';
import TeamPage from 'pages/teamPage';
import CovidPage from 'pages/covidPage.tsx';
import UseCaseEcommercePage from 'pages/useCaseEcommercePageV2.tsx';
import SetupPage from 'pages/setupPage.tsx';
import TrialExpiredPage from 'pages/trialExpiredPage.tsx';
import SharedDashboardPage from 'pages/sharedDashboardPage.tsx';
import AnalyticsPage from 'pages/analyticsPage.tsx';
import StarParallaxBg from 'components/marketing/starParallaxBg.tsx';
import DashboardListPage from 'pages/dashboardListPage/dashboardListPage';

import { loginUserSuccess, logoutUser } from './actions/authAction.tsx';
import { fetchProfile } from './auth/userAuth';

import { ROUTES } from 'constants/routes.tsx';

import LoadingSpinner from 'images/loading_spinner.gif';

import 'index.scss';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/table/lib/css/table.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import DatasetsViewPage from 'pages/datasetsViewPage/datasetsViewPage';

const styles = (theme) => ({
  root: {
    minHeight: '100vh',
    fontFamily: "'Inter', sans-serif",
  },
  loadingRoot: {
    height: '100vh',
    perspective: '1px',
    overflowY: 'auto',
  },
  loadingPage: {
    backgroundColor: theme.palette.black100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  loadingSpinner: {
    width: 75,
    zIndex: 2,
  },
});

class Routes extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    fetchProfile(
      (user) => {
        this.props.loginUserSuccess(user);
        this.setState({ loading: false });
      },
      () => {
        this.props.logoutUser();
        this.setState({ loading: false });
      },
    );
  }

  render() {
    const { classes } = this.props;

    return this.state.loading ? (
      <div className={classes.loadingRoot}>
        <StarParallaxBg />
        <div className={classes.loadingPage}>
          <img className={classes.loadingSpinner} src={LoadingSpinner} alt="loading spinner" />
        </div>
      </div>
    ) : (
      <Router>
        <div className={classes.root}>
          <Switch>
            <Route exact path={ROUTES.HOME} component={EmbeddoHomePage} />
            <Route exact path={ROUTES.EXPLO_HOME} component={ExploHomePage} />
            <Route exact path={ROUTES.ABOUT} component={AboutUsPage} />
            <Route exact path={ROUTES.COVID} component={CovidPage} />
            <Route exact path={ROUTES.USE_CASE_ECOMMERCE} component={UseCaseEcommercePage} />
            <Route exact path={ROUTES.ALERTING} component={AlertingPage} />
            <Route exact path={ROUTES.VERIFY_EMAIL} component={VerifyEmailPage} />
            <Route exact path={ROUTES.PUBLIC_FLOW} component={PublicFlowGraph} />
            <Route exact path={ROUTES.SIGNUP} component={SignUpPage} />
            <ShareRoute exact path={ROUTES.SHARED_DASHBOARD} component={SharedDashboardPage} />
            <PrivateRoute exact path={ROUTES.LOGIN} component={SignInPage} />
            <PrivateRoute exact path={ROUTES.TRIAL_EXPIRED} component={TrialExpiredPage} />
            <PrivateRoute exact path={ROUTES.SETUP} component={SetupPage} />
            <PrivateRoute exact path={ROUTES.CHECK_YOUR_EMAIL} component={CheckYourEmailPage} />
            <PrivateRoute exact path={ROUTES.JOIN_TEAM} component={JoinTeamPage} />
            <PrivateRoute
              exact
              path={ROUTES.DASHBOARD_LIST}
              withNavigation
              component={DashboardListPage}
            />
            <PrivateRoute
              exact
              withNavigation
              path={ROUTES.DATA_SOURCE_LIST}
              component={DataSourceListPage}
            />
            <PrivateRoute
              exact
              path={ROUTES.HOME_APP_PAGE}
              withNavigation
              component={HomeAppPage}
            />
            <PrivateRoute exact path={ROUTES.DATASETS} withNavigation component={DatasetPage} />
            <PrivateRoute
              exact
              path={ROUTES.FLOWS_GRAPH}
              withNavigation
              component={FlowGraphPageV3}
            />
            <PrivateRoute exact path={ROUTES.DASHBOARDS} withNavigation component={DashboardPage} />
            <PrivateRoute
              exact
              path={ROUTES.DATASETS_VIEW_PAGE}
              withNavigation
              component={DatasetsViewPage}
            />
            <PrivateRoute
              exact
              path={ROUTES.DATASOURCE}
              withNavigation
              component={DataSourcePage}
            />
            <PrivateRoute
              exact
              path={ROUTES.ADD_DATA_SOURCE}
              withNavigation
              component={AddDataSourcePage}
            />
            <PrivateRoute exact path={ROUTES.ANALYTICS} withNavigation component={AnalyticsPage} />
            <PrivateRoute exact path={ROUTES.TEAM} withNavigation component={TeamPage} />
            <PrivateRoute noMatch component={EmbeddoHomePage} />
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  loginUserSuccess: (user) => dispatch(loginUserSuccess(user)),
  logoutUser: () => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Routes));

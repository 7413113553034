/** @format */

import React, { ReactNode } from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

import { Button, IButtonProps, Intent } from '@blueprintjs/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&.bp3-button': {
      borderRadius: 4,
      border: 'none',
      outline: 'none',
      fontSize: 14,
      boxShadow: 'none',
    },
  },
  large: {
    height: 48,
    padding: `0 ${theme.spacing(5)}px`,
  },
  primary: {
    '&.bp3-button': {
      backgroundColor: theme.palette.exploBlue,
      color: theme.palette.white,
      fontWeight: 'bold',

      '&:hover': {
        backgroundColor: '#025BCC',
      },
      '&:active': {
        backgroundColor: '#034DAB',
      },
    },

    '&.bp3-button.dark-mode': {
      border: `2px solid ${theme.palette.exploBlue100}`,
      backgroundColor: '#082347',
      color: theme.palette.white,
      backgroundImage: 'none',

      '&:hover': {
        backgroundColor: 'rgba(43, 69, 113, 0.8)',
      },
    },
  },
  linkBtn: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

type PassedProps = {
  children?: ReactNode;
  className?: string;
  href?: string;
  target?: string;
};

type Props = IButtonProps & PassedProps;

const renderButton = (props: Props, classes: ReturnType<typeof useStyles>) => {
  const { href, target, ...otherProps } = props;
  return (
    <Button
      {...otherProps}
      className={cx(props.className, classes.root, {
        [classes.primary]: props.intent === Intent.PRIMARY,
        [classes.large]: props.large,
      })}>
      {props.children}
    </Button>
  );
};

const ExploButton = (props: Props) => {
  const { href, target } = props;
  const classes = useStyles();
  if (!href) {
    return renderButton(props, classes);
  }

  return (
    <a className={classes.linkBtn} href={href} target={target}>
      {renderButton(props, classes)}
    </a>
  );
};

export default ExploButton;

/** @format */

import React from 'react';
import _ from 'underscore';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import Fuse from 'fuse.js';
import { Dataset } from 'actions/types';

import { createNewDatasetSuccess, datasetUploadComplete } from 'actions/fileSystemActions';
import SortByButton from 'components/dataSource/sortByButton';
import { getDatasetPreview, getEphemeralDatasetPreview } from 'actions/datasetActions';
import { fetchDatasetRowCount } from 'actions/flowActions';
import FileUploadButton from 'components/fileSystem/fileUploadButton';
import FileUploadModal from 'components/fileSystem/fileUploadModal';
import ConfirmationModal from 'components/modals/confirmationModal';
import { fetchWorkspaceData, switchWorkspaceData } from 'actions/dataSourceActions';
import TextFieldModal from 'components/TextFieldModal';
import DeleteDatasetConfirmationModal from 'components/modals/deleteDatasetConfirmationModal';

import {
  SORT_OPTIONS_BY_ID,
  SortOptionType,
  DATASETS_ORDERED_SORT_OPTIONS,
} from 'constants/flowConstants';
import DatasetsViewSelectorPanel from 'pages/datasetsViewPage/datasetsViewSelectorPanel';
import { trackEvent } from 'analytics/exploAnalytics';
import { AppToaster } from 'toaster';
import { Intent } from '@blueprintjs/core';
import {
  renameDataset,
  deleteDataset,
  renameFlow,
  moveResource,
  bulkMoveResource,
} from 'actions/fileSystemActions';

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing(6),
    display: 'flex',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    flexDirection: 'column' as 'column',
  },
  header: {
    display: 'flex',
    marginBottom: theme.spacing(4),
    justifyContent: 'space-between',
    fontSize: 24,
    fontWeight: 500,
  },
  uploadCSVButon: {
    backgroundColor: theme.palette.exploBlue,
    color: theme.palette.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '7px 12px',
    borderRadius: 4,
    fontSize: 14,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  footer: {
    backgroundColor: theme.palette.white,
    borderTop: `1px solid ${theme.palette.grey.border}`,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    zIndex: 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footerLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  footerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    marginRight: theme.spacing(4),
  },
  modalTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 0',
    marginRight: theme.spacing(2),
  },
  datasetOrderingBar: {
    margin: `0 ${theme.spacing(2)}px`,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

type MatchParams = {
  datasourceId: string;
};
type PassedProps = {
  editingDisabled?: boolean;
  teamResourceIds?: Set<number>;
};

type Props = PassedProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams> &
  WithStyles<typeof styles>;

type State = {
  selectedDataset: any | null;
  searchQuery: string;
  searchIndex: any | null;
  workspaceId: number | null;
  uploadFileModalOpen: boolean;
  uploadFileWarningOpen: boolean;
  uploadFileFile: any | null;
  datasetsSortOrder: SortOptionType;
  renameDatasetModalOpen: boolean;
  deleteDatasetModalOpen: boolean;
  moveDatasetModalOpen: boolean;
};

class DatasetsViewPage extends React.Component<Props, State> {
  state: State = {
    selectedDataset: null,
    searchQuery: '',
    searchIndex: DatasetsViewPage.createSearchIndex(this.props),
    workspaceId: null,
    uploadFileModalOpen: false,
    uploadFileWarningOpen: false,
    uploadFileFile: null,
    datasetsSortOrder: SORT_OPTIONS_BY_ID.LAST_EDITED,
    renameDatasetModalOpen: false,
    deleteDatasetModalOpen: false,
    moveDatasetModalOpen: false,
  };

  componentDidMount = () => {
    const { fetchWorkspaceData, match, datasourceDictionary, switchWorkspaceData } = this.props;
    if (!datasourceDictionary[match.params.datasourceId]) {
      fetchWorkspaceData({ id: match.params.datasourceId });
    } else {
      switchWorkspaceData(match.params.datasourceId);
    }
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.workspaceData.searchIndex === prevState.searchIndex) {
      return null;
    }
    return {
      searchIndex: DatasetsViewPage.createSearchIndex(nextProps),
    };
  }

  static createSearchIndex(props: Props): any {
    const { datasourceDictionary, match } = props;
    const dataSource = datasourceDictionary[match.params.datasourceId];
    if (!dataSource) {
      return;
    }

    const datasets = dataSource.source_datasets.concat(dataSource.saved_datasets);
    datasets.forEach((dataset: Dataset) => {
      if (!dataset.name) dataset.name = dataset.data_source_table_name;
    });
    return new Fuse(datasets, {
      isCaseSensitive: false,
      threshold: 0.3,
      ignoreLocation: true,
      includeMatches: true,
      keys: ['name'],
    });
  }

  render() {
    const { selectedDataset, searchQuery } = this.state;
    const {
      classes,
      editingDisabled,
      teamResourceIds,
      currentUser,
      match,
      datasourceDictionary,
    } = this.props;
    const dataSource = datasourceDictionary[match.params.datasourceId];
    return (
      <div className={classes.root}>
        <div className={classes.header}>
          Data
          {this.renderUploadCSVButton()}
        </div>
        <DatasetsViewSelectorPanel
          currentUserId={currentUser.id}
          datasetsLoading={!dataSource}
          datasetTabsList={this.getFilteredDatasets()}
          onQueryChange={(query: any) => this.setState({ searchQuery: query })}
          searchQuery={searchQuery}
          onDatasetSelected={this.onDatasetSelected}
          selectedDataset={selectedDataset}
          isExploSource={dataSource && dataSource.explo_source}
          openRenameDatasetModal={this.openRenameDatasetModal}
          openDeleteDatasetModal={this.openDeleteDatasetModal}
          openMoveDatasetModal={this.openMoveDatasetModal}
          editingDisabled={editingDisabled}
          teamResourceIds={teamResourceIds}
          maxPageNumber={selectedDataset && Math.ceil(selectedDataset.total_row_count / 50)}
          getEphemeralDatasetPreview={this.props.getEphemeralDatasetPreview}
          datasetOrderDropdown={this.renderDatasetOrderDropdown()}
        />
        {this.renderFileUploadWarning()}
        {this.renderUploadFileModal()}
        {this.renderRenameDatasetModal()}
        {this.renderDeleteDatasetModal()}
        {this.renderMoveDatasetModal()}
      </div>
    );
  }

  renderUploadCSVButton = () => {
    const { classes, datasourceDictionary, match } = this.props;
    const dataSource = datasourceDictionary[match.params.datasourceId];

    if (dataSource && dataSource.explo_source) {
      return (
        <FileUploadButton
          buttonText="Upload a CSV"
          uploadFile={this.onFileUpload}
          buttonElement={<div className={classes.uploadCSVButon}>Upload a CSV</div>}
        />
      );
    }
  };

  renderDatasetOrderDropdown = () => {
    const { datasetsSortOrder } = this.state;
    const { classes } = this.props;

    return (
      <SortByButton
        className={classes.datasetOrderingBar}
        selectedSort={datasetsSortOrder}
        setSort={(newSort) => this.setState({ datasetsSortOrder: newSort })}
        sortOptions={DATASETS_ORDERED_SORT_OPTIONS}
      />
    );
  };

  renderFileUploadWarning = () => {
    const { uploadFileWarningOpen } = this.state;
    if (!uploadFileWarningOpen) return;

    return (
      <ConfirmationModal
        modalOpen={uploadFileWarningOpen}
        closeModal={() => this.setState({ uploadFileWarningOpen: false })}
        modalTitle="Warning"
        modalText="The File you are uploading might not be a supported format."
        submitBtnText="Continue"
        danger
        onSubmit={() =>
          this.setState({
            uploadFileWarningOpen: false,
            uploadFileModalOpen: true,
          })
        }
      />
    );
  };

  renderUploadFileModal = () => {
    const { uploadFileModalOpen, uploadFileFile } = this.state;
    const {
      currentUser,
      createNewDatasetSuccess,
      datasetUploadComplete,
      datasourceDictionary,
      match,
    } = this.props;

    if (!uploadFileModalOpen) {
      return;
    }
    const dataSource = datasourceDictionary[match.params.datasourceId];
    let allDatasets = dataSource.source_datasets.concat(dataSource.saved_datasets);
    return (
      <FileUploadModal
        file={uploadFileFile}
        isOpen={uploadFileModalOpen}
        onModalClose={() => this.setState({ uploadFileModalOpen: false })}
        currentFolderId={currentUser.root_folder_id!}
        createNewDatasetSuccess={createNewDatasetSuccess}
        datasetUploadComplete={datasetUploadComplete}
        existingUploadedCSVNames={_.pluck(allDatasets, 'name')}
      />
    );
  };

  onDatasetSelected = (dataset: any) => {
    const { selectedDataset } = this.state;
    if (selectedDataset && selectedDataset.id === dataset.id) {
      this.setState({ selectedDataset: null });
    } else {
      if (!dataset.uploading && !dataset.results_last_updated_at) {
        this.props.getDatasetPreview({ id: dataset.id });
      }
      this.props.fetchDatasetRowCount({ id: dataset.id });
      this.setState({ selectedDataset: dataset });
    }
  };

  sortDatasets = (datasets: Dataset[]) => {
    const { datasetsSortOrder } = this.state;
    if (datasetsSortOrder.id === SORT_OPTIONS_BY_ID.LAST_EDITED.id) {
      datasets.sort((a: Dataset, b: Dataset) =>
        Date.parse(a.results_last_updated_at) < Date.parse(b.results_last_updated_at) ? 1 : -1,
      );
    } else if (datasetsSortOrder.id === SORT_OPTIONS_BY_ID.NAME.id) {
      datasets.sort((a: Dataset, b: Dataset) => (a.name > b.name ? 1 : -1));
    }
    return datasets;
  };

  getDatabaseName = (name: string) => {
    const splitList = name.split('.');
    if (splitList.length === 3) {
      return splitList[0];
    }
  };

  getSchemaName = (name: string) => {
    const splitList = name.split('.');
    if (splitList.length === 2) {
      return splitList[0];
    } else if (splitList.length === 3) {
      return splitList[1];
    }
  };

  getTableName = (name: string) => {
    const splitList = name.split('.');
    if (splitList.length === 1) {
      return splitList[0];
    } else if (splitList.length === 2) {
      return splitList[1];
    } else if (splitList.length === 3) {
      return splitList[2];
    }
  };

  getFilteredDatasets = () => {
    const { datasourceDictionary, match, workspaceData } = this.props;
    const { searchIndex, searchQuery } = this.state;
    const dataSource = datasourceDictionary[match.params.datasourceId];
    if (!dataSource) {
      return [];
    }

    let allDatasets = dataSource.source_datasets.concat(dataSource.saved_datasets);

    let dataSourceFolders: Set<string> = new Set();
    allDatasets.forEach((dataset: Dataset) => {
      if (dataset.is_source_dataset) {
        const schemaName = this.getSchemaName(dataset.data_source_table_name);
        schemaName && dataSourceFolders.add(schemaName);
      }
    });

    if (searchQuery.length > 0 && searchIndex) {
      const results = searchIndex.search(searchQuery);
      allDatasets = _.pluck(results, 'item');
    }

    let datasetTabsList = [];

    // New datasets.
    let newDatasets = [...allDatasets];
    newDatasets = _.filter(newDatasets, (dataset) => this.isNewDataset(dataset));
    if (newDatasets.length > 0) {
      datasetTabsList.push({ name: 'New', data: this.sortDatasets(newDatasets) });
    }

    // Personal datasets.
    let userDatasets = [...allDatasets];
    userDatasets = _.filter(
      userDatasets,
      (dataset) =>
        !this.isNewDataset(dataset) &&
        workspaceData.dataSourcePageInfo.user_resource_ids.has(dataset.id),
    );
    if (userDatasets.length > 0) {
      datasetTabsList.push({ name: 'Personal', data: this.sortDatasets(userDatasets) });
    }

    // Team datasets.
    let teamDatasets = [...allDatasets];
    teamDatasets = _.filter(
      teamDatasets,
      (dataset) =>
        !this.isNewDataset(dataset) &&
        workspaceData.dataSourcePageInfo.team_resource_ids.has(dataset.id),
    );
    if (teamDatasets.length > 0) {
      datasetTabsList.push({ name: 'Team', data: this.sortDatasets(teamDatasets) });
    }

    // Data Source datasets.
    let dataSourceDatasets = [...allDatasets];
    dataSourceDatasets = _.filter(dataSourceDatasets, (dataset) => dataset.is_source_dataset);
    // Filter the Data Source tab for datasets not in sub folders.
    const newDataSourceTab: Dataset[] = dataSourceDatasets.filter(
      (dataset: Dataset) => !dataset.data_source_table_name.includes('.'),
    );
    if (newDataSourceTab.length > 0) {
      datasetTabsList.push({
        name: 'Data Source',
        data: this.sortDatasets(newDataSourceTab),
      });
    }
    // Construct sub folders for the Data Source.
    dataSourceFolders.forEach((newFolder: string) => {
      const newDataSourceTab: Dataset[] = dataSourceDatasets
        .filter((dataset: Dataset) => dataset.data_source_table_name.includes(newFolder + '.'))
        .map((dataset: Dataset) => {
          const tableName = this.getTableName(dataset.data_source_table_name);
          if (tableName) {
            dataset.name = dataset.name || tableName;
          }
          return dataset;
        });
      datasetTabsList.push({
        name: newFolder,
        data: this.sortDatasets(newDataSourceTab),
      });
    });

    return datasetTabsList;
  };

  isNewDataset = (dataset: Dataset) => {
    return !!(dataset.new || dataset.uploading);
  };

  onFileUpload = (file: File) => {
    if (file.name.split('.').pop() !== 'csv') {
      this.setState({
        selectedDataset: null,
        uploadFileWarningOpen: true,
        uploadFileFile: file,
      });
    } else {
      this.setState({
        selectedDataset: null,
        uploadFileModalOpen: true,
        uploadFileFile: file,
      });
    }
  };

  closeRenameDatasetModal = () => {
    this.setState({
      renameDatasetModalOpen: false,
      selectedDataset: null,
    });
  };

  openRenameDatasetModal = (selectedDataset: any | null) => {
    this.setState({
      renameDatasetModalOpen: true,
      selectedDataset,
    });
  };

  openDeleteDatasetModal = (selectedDataset: any | null) => {
    this.setState({
      deleteDatasetModalOpen: true,
      selectedDataset,
    });
  };

  openMoveDatasetModal = (selectedDataset: any | null) => {
    this.setState({
      moveDatasetModalOpen: true,
      selectedDataset,
    });
  };

  renameDataset = (resourceId: number | null, oldName: string, newName: string) => {
    this.props.renameDataset(
      { id: resourceId, postData: { name: newName } },
      () => this.successToast('Resource was succesfully renamed.'),
      (errorData: any) => this.errorToast(errorData),
    );

    trackEvent('Rename resource', {
      resource_id: resourceId,
      orig_name: oldName,
      new_name: newName,
      resource_type: 'dataset',
    });
  };

  moveResource = (
    resourceId: number,
    currFolderId: number,
    destFolderId: number,
    resourceType: string,
  ) => {
    const args = {
      id: resourceId,
      postData: {
        curr_folder_id: currFolderId,
        dest_folder_id: destFolderId,
      },
    };

    this.props.moveResource(args, undefined, undefined);
    trackEvent('Move resource', {
      resource_id: resourceId,
      curr_folder: currFolderId,
      dest_folder: destFolderId,
      resource_type: resourceType,
    });
  };

  deleteDataset = (resourceId: number, name: string, resourceType: string) => {
    this.props.deleteDataset(
      { id: resourceId },
      () => {
        if (resourceType === 'dataset') {
          this.setState({
            selectedDataset: null,
          });
        }
        this.successToast('Resource, ' + name + ', was successfully deleted');
      },
      (response: any) => this.errorToast(response),
    );
    trackEvent('Delete resource', {
      resource_id: resourceId,
      name: name,
      resource_type: resourceType,
    });
  };

  errorToast = (responseData: any) => {
    AppToaster.show({
      // Sometimes an object is inputted and, if not, the direct error message is inputed.
      message: responseData['msg'] || responseData,
      icon: 'error',
      timeout: 5000,
      intent: Intent.DANGER,
    });
  };

  successToast = (msg = 'Success!') => {
    AppToaster.show({
      message: msg,
      icon: 'tick',
      timeout: 3000,
      intent: Intent.SUCCESS,
    });
  };

  textFieldModalErrorState = (textFieldVal?: string) => {
    const { dataSourceData } = this.props;
    let allDatasets = dataSourceData.data.source_datasets.concat(
      dataSourceData.data.saved_datasets,
    );
    const isErrorState =
      !!textFieldVal && _.contains(_.pluck(allDatasets, 'name'), textFieldVal.trim());
    return {
      isErrorState,
      errorMsg: isErrorState ? 'Name already exists' : undefined,
    };
  };

  renderRenameDatasetModal = () => {
    const { selectedDataset, renameDatasetModalOpen } = this.state;
    if (!renameDatasetModalOpen) return;

    return (
      <TextFieldModal
        modalOpen={renameDatasetModalOpen}
        closeModal={this.closeRenameDatasetModal}
        modalTitle="Rename Dataset"
        textFieldPlaceholder="New Name"
        resourceName={
          selectedDataset && (selectedDataset.name || selectedDataset.data_source_table_name)
        }
        buttonName="Rename"
        onSubmit={(newName: string) =>
          this.renameDataset(selectedDataset.id, selectedDataset.name, newName)
        }
        errorState={this.textFieldModalErrorState}
      />
    );
  };

  renderDeleteDatasetModal = () => {
    const { deleteDatasetModalOpen, selectedDataset } = this.state;

    if (!deleteDatasetModalOpen || !selectedDataset) {
      return;
    }

    return (
      <DeleteDatasetConfirmationModal
        dataset={selectedDataset}
        isOpen={deleteDatasetModalOpen}
        onClose={() => this.setState({ deleteDatasetModalOpen: false })}
        deleteDataset={this.deleteDataset}
      />
    );
  };

  renderMoveDatasetModal = () => {
    const { workspaceData, teamData, currentUser } = this.props;
    const { moveDatasetModalOpen, selectedDataset } = this.state;

    if (!selectedDataset || !workspaceData.data || !teamData.data || !currentUser.root_folder_id) {
      return;
    }

    const movingDatasetInfo = !workspaceData.dataSourcePageInfo!.team_resource_ids.has(
      selectedDataset.id,
    )
      ? {
          destinationName: `${teamData.data.team_name} Team Datasets`,
          destinationFolderId: teamData.data.team_folder.id,
          currFolderId: currentUser.root_folder_id,
        }
      : {
          destinationName: 'Personal Datasets',
          destinationFolderId: currentUser.root_folder_id,
          currFolderId: teamData.data.team_folder.id,
        };

    return (
      <ConfirmationModal
        modalOpen={moveDatasetModalOpen}
        closeModal={() => this.setState({ moveDatasetModalOpen: false })}
        modalTitle="Share Dataset Confirmation"
        modalText={`Are you sure you want to move this dataset to ${movingDatasetInfo.destinationName}.`}
        submitBtnText="Move"
        danger={false}
        onSubmit={() =>
          this.moveResource(
            selectedDataset.id,
            movingDatasetInfo.currFolderId,
            movingDatasetInfo.destinationFolderId,
            'dataset',
          )
        }
      />
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  dataSourceData: state.dataSource,
  workspaceData: state.workspaceData,
  datasourceDictionary: state.workspaceData.datasourceDictionary,
  currentUser: state.currentUser,
  dataSourceList: state.dataSourceList,
  teamData: state.teamData,
});

const mapDispatchToProps = {
  createNewDatasetSuccess,
  datasetUploadComplete,
  getDatasetPreview,
  fetchDatasetRowCount,
  getEphemeralDatasetPreview,
  fetchWorkspaceData,
  switchWorkspaceData,
  renameDataset,
  deleteDataset,
  renameFlow,
  moveResource,
  bulkMoveResource,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatasetsViewPage)),
);

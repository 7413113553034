/** @format */

import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { FormGroup, Intent, Checkbox } from '@blueprintjs/core';
import { ReduxState } from 'reducers/rootReducer';

import SetupFlowPage from 'components/pages/setupFlowPage';
import InputField from 'explo-ds/forms/marketing/inputField';
import Button from 'explo-ds/buttons/button';

import { registerUser } from 'actions/authAction';
import { onRegistrationSubmit } from 'utils/landingPageUtils';
import { pageView } from 'analytics/exploAnalytics';

import { ROUTES } from 'constants/routes';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.black100,
    height: '100vh',
    padding: `0 ${theme.spacing(7)}px`,
    display: 'flex',
    justifyContent: 'center',
    fontFamily: "'Inter', sans-serif",
    perspective: '1px',
    overflowY: 'auto',
  },
  signupInput: {
    backgroundColor: theme.palette.grey200,
    width: '100%',
    height: 40,
    marginTop: 2,
  },
  passwordInputGroup: {
    marginTop: theme.spacing(5),
  },
  signInButton: {
    width: '100%',
    marginTop: theme.spacing(5),
  },
  checkboxInput: {
    marginTop: theme.spacing(5),

    '& .bp3-control-indicator': {
      boxShadow: 'none !important',
    },

    '& input:checked ~ .bp3-control-indicator': {
      backgroundColor: `${theme.palette.exploBlue} !important`,
    },
  },
  checkboxText: {
    color: theme.palette.white,
    fontSize: 14,
    marginTop: '-21px',
  },
  checkboxLinks: {
    color: theme.palette.white,
    textDecoration: 'underline',
  },
}));

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & RouteComponentProps;

const SignupPageV2 = (props: Props) => {
  return <SetupFlowPage title="Sign up" pageBody={<SignupBody {...props} />} />;
};

const onSubmit = (
  termsChecked: boolean,
  password: string,
  password2: string,
  signupEmail: string,
  firstName: string,
  lastName: string,
  registerUser: any,
  onSuccess: () => void,
) => {
  onRegistrationSubmit(
    termsChecked,
    password,
    password2,
    signupEmail,
    firstName,
    lastName,
    registerUser,
    () => {
      onSuccess();
    },
    (errorMsg) => {},
  );
};

const SignupBody = (props: Props) => {
  const { registrationLoading, registerUser } = props;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [signupEmail, setSignupEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [termsChecked, setTermsChecked] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    pageView('Signup');

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.keyCode === 13) {
        onSubmit(
          termsChecked,
          password,
          password2,
          signupEmail,
          firstName,
          lastName,
          registerUser,
          () => {
            props.history.push(ROUTES.CHECK_YOUR_EMAIL);
          },
        );
        return;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <div>
      <FormGroup className="bp3-dark" label="First name">
        <InputField
          value={firstName}
          onChange={(e: any) => setFirstName(e.target.value)}
          className={classes.signupInput}
          placeholder=""
        />
      </FormGroup>

      <FormGroup className="bp3-dark" label="Last name">
        <InputField
          value={lastName}
          onChange={(e: any) => setLastName(e.target.value)}
          className={classes.signupInput}
          placeholder=""
        />
      </FormGroup>

      <FormGroup className="bp3-dark" label="Email address">
        <InputField
          value={signupEmail}
          onChange={(e: any) => setSignupEmail(e.target.value)}
          className={classes.signupInput}
          placeholder=""
        />
      </FormGroup>

      <FormGroup className={cx('bp3-dark', classes.passwordInputGroup)} label="Password">
        <InputField
          value={password}
          type="password"
          onChange={(e: any) => setPassword(e.target.value)}
          className={classes.signupInput}
          placeholder=""
        />
      </FormGroup>
      <FormGroup className={cx('bp3-dark', classes.passwordInputGroup)} label="Confirm password">
        <InputField
          value={password2}
          type="password"
          onChange={(e: any) => setPassword2(e.target.value)}
          className={classes.signupInput}
          placeholder=""
        />
      </FormGroup>
      <Checkbox
        className={classes.checkboxInput}
        large={true}
        checked={termsChecked}
        onChange={() => setTermsChecked(!termsChecked)}>
        <div className={classes.checkboxText}>
          Check here to indicate that you have read and agree to the Explo{' '}
          <a href="/terms_of_service.pdf" className={classes.checkboxLinks} target="_blank">
            Terms of Service
          </a>{' '}
          and{' '}
          <a href="/privacy_policy.pdf" className={classes.checkboxLinks} target="_blank">
            Privacy Policy
          </a>
          .
        </div>
      </Checkbox>
      <Button
        className={classes.signInButton}
        intent={Intent.PRIMARY}
        onClick={() =>
          onSubmit(
            termsChecked,
            password,
            password2,
            signupEmail,
            firstName,
            lastName,
            registerUser,
            () => {
              props.history.push(ROUTES.CHECK_YOUR_EMAIL);
            },
          )
        }
        large
        loading={registrationLoading}>
        Sign Up
      </Button>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  registrationLoading: state.currentUser.registrationLoading,
});

const mapDispatchToProps = {
  registerUser,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignupPageV2));

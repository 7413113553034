/** @format */

import React from 'react';
import { withStyles } from '@material-ui/styles';

import ClauseBuilder from 'components/flowBuilder/clauseBuilder';
import LineChartEditOperationBody from 'components/flowBuilder/editOperationDrawer/lineChartEditOperationBody';

import {
  lineOrBarChartInstructionsComplete,
  lineOrBarChartInstructionsChanged,
} from 'utils/flowUtils';
import { trackEvent } from 'analytics/exploAnalytics';

const styles = (theme) => ({
  fillerText: {
    fontSize: 16,
    fontWeight: 400,
  },
  filterClauseText: {
    margin: `0 ${theme.spacing(2)}px`,
    marginBottom: theme.spacing(2),
    fontSize: 16,
  },
});

class LineChartClauseBuilder extends React.Component {
  constructor(props) {
    super(props);

    if (props.openFlyout) {
      props.clearRecentlyAddedOpId();
    }

    this.state = {
      editDrawerOpen: props.openFlyout || false,
    };
  }

  render() {
    const { computeLoading, instructions, summaryView } = this.props;

    return (
      <ClauseBuilder
        computeLoading={computeLoading}
        editDrawerOpen={this.state.editDrawerOpen}
        openDrawer={() => this.setState({ editDrawerOpen: true })}
        closeDrawer={() => this.setState({ editDrawerOpen: false })}
        editOperationDrawerTitle="Configure the line chart"
        tooltipSuggestionIsOpen={!lineOrBarChartInstructionsComplete(instructions)}
        editOperationBody={this.renderOperationEditBody()}
        clauseText=""
        summaryView={summaryView}
      />
    );
  }

  renderOperationEditBody = () => {
    const {
      updateOperationInstructionAndCompute,
      sourceDataset,
      instructions,
      operationId,
    } = this.props;

    return (
      <LineChartEditOperationBody
        instructions={instructions}
        operationId={operationId}
        sourceDataset={sourceDataset}
        selectionTitle="Configure values"
        addSelectionBtn="Add Value"
        onSubmit={(newInstructions) => {
          const instructionHasChanged = lineOrBarChartInstructionsChanged(
            instructions,
            newInstructions,
          );
          updateOperationInstructionAndCompute(
            operationId,
            newInstructions,
            instructionHasChanged && lineOrBarChartInstructionsComplete(newInstructions),
          );
          this.setState({ editDrawerOpen: false });
          trackEvent('Update and compute line chart', {
            operation_id: operationId,
            new_instructions: newInstructions,
            line_chart_instructions_changed: instructionHasChanged,
          });
        }}
      />
    );
  };
}

export default withStyles(styles)(LineChartClauseBuilder);

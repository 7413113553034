/** @format */

import { Colors } from '@blueprintjs/core';

export const BLUE = {
  primary: '#348CF7',
  secondary: '#48AFF0',
};

export const GREEN = {
  primary: '#0A6640',
  secondary: '#3DCC91',
};

export const ORANGE = {
  primary: '#A66321',
  secondary: '#FFB366',
};

export const RED = {
  primary: '#A82A2A',
  secondary: '#FF7373',
};

export const INDIGO = {
  primary: '#5642A6',
  secondary: '#AD99FF',
};

export const GOLD = {
  primary: '#A67908',
  secondary: '#FFC940',
};

export const COLOR_LIST = [BLUE, GREEN, ORANGE, RED, INDIGO, GOLD];

export const BP_BLUE = {
  primary: Colors.BLUE3,
};

export const BP_SEPIA = {
  primary: Colors.SEPIA3,
};

export const BP_ORANGE = {
  primary: Colors.ORANGE3,
};

export const BP_INDIGO = {
  primary: Colors.INDIGO3,
};

export const BP_TURQUOISE = {
  primary: Colors.TURQUOISE3,
};

export const BP_GOLD = {
  primary: Colors.GOLD3,
};

export const BP_COLORS = [BP_BLUE, BP_SEPIA, BP_ORANGE, BP_INDIGO, BP_TURQUOISE, BP_GOLD];

/** @format */

import React from 'react';
import _ from 'underscore';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';

import { defineDispatchProps } from 'actions/actionUtils.tsx';
import {
  fetchSupportedDataSources,
  testDataSourceConnection,
  connectDataSource,
  switchSelectedDataSourceId,
  fetchWorkspaceData,
} from 'actions/dataSourceActions';

import {
  H2,
  FormGroup,
  InputGroup,
  Intent,
  Button,
  Callout,
  Checkbox,
  Tooltip,
  Position,
  Icon,
} from '@blueprintjs/core';
import SimpleSuggestInput from 'components/flowBuilder/simpleSuggestInput';

import { pageView } from 'analytics/exploAnalytics';
import { updateSetupFlowStepKey } from 'actions/onboardingActions';
import { SetupFlowStepKey, STEP_KEY_TO_DATA_SELECTION_PATH } from 'constants/setupPageConstants';
import { ROUTES } from 'constants/routes';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 16,
    overflow: 'auto',
    margin: `${theme.spacing(15)}px auto`,
    maxWidth: 600,
    padding: theme.spacing(8),
    minHeight: 500,
  },
  setupFlowRoot: {
    borderRadius: 4,
    overflow: 'auto',
    padding: `${theme.spacing(4)}px ${theme.spacing(1)}px ${theme.spacing(5)}px`,
    width: 500,
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  skipButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
  actionButton: {
    marginLeft: theme.spacing(3),
    width: 150,
  },
  testingConnectionContainer: {
    marginBottom: theme.spacing(5),
  },
  helpTooltip: {
    marginLeft: theme.spacing(2),
  },
  bodyContainer: {
    height: '100vh',
    width: '100%',
    overflow: 'auto',
    position: 'relative',
  },
  backButton: {
    marginRight: theme.spacing(4),
    position: 'absolute',
    top: theme.spacing(4),
    left: theme.spacing(4),
  },
});

class AddDataSourcePage extends React.Component {
  constructor(props) {
    super(props);
    const { inSetupFlowContext, setupFlowSelectedDatabaseConfig } = props;

    props.fetchSupportedDataSources();
    this.props.switchSelectedDataSourceId(null);

    this.state = {
      name: '',
      type: inSetupFlowContext ? setupFlowSelectedDatabaseConfig : null,
      configuration: {},
      testingConnWorked: null,
      testingConnLoading: false,
      testingConnError: '',
      addDataSetLoading: false,
    };
  }

  componentDidMount() {
    pageView('Add Data Source');
  }

  render() {
    const { classes, inSetupFlowContext, history } = this.props;
    const rootStyle = inSetupFlowContext ? classes.setupFlowRoot : classes.root;
    return (
      <div className={classes.bodyContainer}>
        <Button
          className={classes.backButton}
          icon="arrow-left"
          large
          minimal
          onClick={() => history.push(ROUTES.DATA_SOURCE_LIST)}
        />
        <div className={rootStyle}>
          {!inSetupFlowContext && <H2>Add Data Source</H2>}
          {this.renderFormBody()}
        </div>
      </div>
    );
  }

  renderFormBody = () => {
    const { supportedDataSources, inSetupFlowContext } = this.props;

    const sourcesByType = _.indexBy(supportedDataSources.sources, 'type');

    return (
      <div>
        <FormGroup label="Nickname" labelInfo="*">
          <InputGroup
            type="text"
            value={this.state.name}
            placeholder="e.g. Acme Inc. Replica"
            onChange={(e) => this.setState({ name: e.target.value })}
          />
        </FormGroup>

        <FormGroup label="Type" labelInfo="*">
          <SimpleSuggestInput
            fill={true}
            selectedItem={
              this.state.type && { id: this.state.type.type, name: this.state.type.name }
            }
            onChange={(item) =>
              this.setState({
                type: sourcesByType[item.id],
                configuration: this.defaultConfigForType(sourcesByType[item.id]),
              })
            }
            options={supportedDataSources.sources.map((source) => ({
              id: source.type,
              name: source.name,
            }))}
            loading={!inSetupFlowContext && supportedDataSources.loading}
            noSelectionText="e.g. PostgreSQL, BigQuery, etc."
          />
        </FormGroup>

        {this.state.type && this.renderSourceOptions()}
        {this.renderTestingConnection()}
        {this.state.type && this.renderActions()}
        {inSetupFlowContext && this.renderSkipButton()}
      </div>
    );
  };

  onSkipButtonPress = () => {
    const { updateSetupFlowStepKey } = this.props;
    updateSetupFlowStepKey({
      postData: {
        updated_setup_flow_key: SetupFlowStepKey.CSV_OR_DEMO_DATA_OPTION,
      },
    });
  };

  renderSkipButton = () => {
    const { classes } = this.props;
    const { addDataSetLoading } = this.state;
    return (
      <div className={classes.skipButtonContainer}>
        <Button
          intent={Intent.NONE}
          onClick={this.onSkipButtonPress}
          minimal={true}
          disabled={addDataSetLoading}>
          Skip for now
        </Button>
      </div>
    );
  };

  renderSourceOptions = () => {
    const { type, configuration } = this.state;
    const { classes } = this.props;
    const properties = type.configuration_schema.properties;

    return (
      <>
        {type.configuration_schema.order.map((propertyName) => {
          if (properties[propertyName].type !== 'checkbox') {
            return (
              <FormGroup
                label={properties[propertyName].label}
                labelInfo="*"
                key={`add_source_field_${type.type}_${propertyName}`}>
                <InputGroup
                  type={properties[propertyName].type}
                  value={configuration[propertyName] || ''}
                  placeholder={properties[propertyName].placeholder}
                  onChange={(e) => {
                    configuration[propertyName] = e.target.value;
                    this.setState({ configuration });
                  }}
                />
              </FormGroup>
            );
          } else {
            return (
              <FormGroup key={`add_source_field_${type.type}_${propertyName}`}>
                <Checkbox
                  value={configuration[propertyName] || false}
                  onChange={() => {
                    configuration[propertyName] = !configuration[propertyName];
                    this.setState({ configuration });
                  }}>
                  {properties[propertyName].label}
                  {configuration[propertyName] && properties[propertyName].checked_label}
                  {properties[propertyName].help_tooltip && (
                    <Tooltip
                      className={classes.helpTooltip}
                      position={Position.TOP}
                      content={properties[propertyName].help_tooltip}>
                      <Icon icon="help" />
                    </Tooltip>
                  )}
                </Checkbox>
              </FormGroup>
            );
          }
        })}
      </>
    );
  };

  renderTestingConnection = () => {
    const { classes } = this.props;
    const { testingConnWorked, testingConnLoading, testingConnError } = this.state;

    let contents, title, intent;
    if (testingConnWorked) {
      title = 'Test connection worked!';
      intent = Intent.SUCCESS;
    } else if (testingConnLoading) {
      title = 'Loading connection test...';
      intent = Intent.PRIMARY;
    } else if (testingConnError) {
      title = 'Test connection failed';
      contents = <div>{testingConnError}</div>;
      intent = Intent.DANGER;
    } else {
      return;
    }

    return (
      <Callout intent={intent} title={title} className={classes.testingConnectionContainer}>
        {contents}
      </Callout>
    );
  };

  renderActions = () => {
    const { classes } = this.props;

    return (
      <div className={classes.actionsContainer}>
        <Button
          className={classes.actionButton}
          intent={Intent.NONE}
          onClick={() => this.configurationComplete() && this.testConnection()}
          disabled={!this.configurationComplete() || this.state.testingConnLoading}
          loading={this.state.testingConnLoading}>
          Test
        </Button>
        <Button
          className={classes.actionButton}
          intent={Intent.PRIMARY}
          disabled={!this.configurationComplete() || this.state.addDataSetLoading}
          loading={this.state.addDataSetLoading}
          onClick={() => this.configurationComplete() && this.connectDataSource()}>
          Connect
        </Button>
      </div>
    );
  };

  defaultConfigForType = (type) => {
    const configuration = {};

    _.each(Object.keys(type.configuration_schema.properties), (propertyName) => {
      configuration[propertyName] =
        type.configuration_schema.properties[propertyName].default !== undefined
          ? type.configuration_schema.properties[propertyName].default
          : null;
    });

    return configuration;
  };

  configurationComplete = () => {
    const { name, type, configuration } = this.state;

    if (type === null) {
      return false;
    } else if (name.trim() === '') {
      return false;
    }

    return _.all(
      _.map(type.configuration_schema.order, (propertyName) => {
        return configuration[propertyName] !== null;
      }),
    );
  };

  parseJsonFields = () => {
    const { type, configuration } = this.state;
    const parsedConfig = cloneDeep(configuration);
    const properties = type.configuration_schema.properties;

    let error = null;
    _.map(type.configuration_schema.order, (propertyName) => {
      if (properties[propertyName].type === 'json') {
        try {
          const noNewLines = configuration[propertyName].replace(/\n/g, '\\n');
          parsedConfig[propertyName] = JSON.parse(noNewLines);
        } catch (err) {
          error = err;
        }
      }
    });

    return { parsedConfig, error };
  };

  testConnection = () => {
    this.setState({ testingConnLoading: true, testingConnWorked: null, testingConnError: '' });

    const { parsedConfig, error } = this.parseJsonFields();
    if (error !== null) {
      this.setState({
        testingConnLoading: false,
        testingConnWorked: false,
        testingConnError: error.toString(),
      });
      return;
    }

    this.props.testDataSourceConnection(
      {
        postData: {
          name: this.state.name,
          type: this.state.type.type,
          configuration: parsedConfig,
        },
      },
      (response) => {
        this.setState({ testingConnLoading: false, testingConnWorked: true, testingConnError: '' });
      },
      (response) => {
        this.setState({
          testingConnLoading: false,
          testingConnWorked: false,
          testingConnError:
            response.error_msg ||
            'There was an error with your credentials. Please ensure they are correct.',
        });
      },
    );
  };

  connectDataSource = () => {
    const {
      inSetupFlowContext,
      updateSetupFlowStepKey,
      history,
      connectDataSource,
      fetchWorkspaceData,
      switchSelectedDataSourceId,
    } = this.props;
    const { name, type } = this.state;
    this.setState({
      testingConnLoading: true,
      testingConnWorked: null,
      testingConnError: '',
      addDataSetLoading: true,
    });
    const { parsedConfig, error } = this.parseJsonFields();
    if (error !== null) {
      this.setState({
        testingConnLoading: false,
        testingConnWorked: false,
        testingConnError: error.toString(),
      });
      return;
    }

    connectDataSource(
      {
        postData: {
          name: name,
          type: type.type,
          configuration: parsedConfig,
        },
      },
      (response) => {
        this.setState({
          testingConnLoading: false,
          testingConnWorked: true,
          testingConnError: '',
          addDataSetLoading: false,
        });
        if (inSetupFlowContext) {
          updateSetupFlowStepKey({
            postData: {
              updated_setup_flow_key: SetupFlowStepKey.DATABASE_PATH_ENTER_EXPLO,
              data_selection_path:
                STEP_KEY_TO_DATA_SELECTION_PATH[SetupFlowStepKey.DATABASE_PATH_ENTER_EXPLO],
            },
          });
        } else {
          fetchWorkspaceData({ id: response.data_source.id });
          history.push(`/datasources/${response.data_source.id}`);
        }
        switchSelectedDataSourceId(response.data_source.id);
      },
      (response) => {
        this.setState({
          testingConnLoading: false,
          testingConnWorked: false,
          testingConnError:
            response.error_msg ||
            'There was an error with your credentials. Please ensure they are correct.',
          addDataSetLoading: false,
        });
      },
    );
  };
}

const mapStateToProps = (state) => ({
  supportedDataSources: state.supportedDataSources,
  setupFlowSelectedDatabaseConfig: state.onboardingData.selectedDatabaseConfig,
});

const mapDispatchToProps = defineDispatchProps(
  {
    fetchSupportedDataSources,
    testDataSourceConnection,
    connectDataSource,
    fetchWorkspaceData,
    updateSetupFlowStepKey,
  },
  (dispatch) => ({
    switchSelectedDataSourceId: (dataSource) => dispatch(switchSelectedDataSourceId(dataSource)),
  }),
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(AddDataSourcePage)));

/** @format */

import React, { useState } from 'react';
import _ from 'underscore';
import cx from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import { Button, InputGroup, Dialog, Intent, Icon, Spinner, Classes } from '@blueprintjs/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { ReduxState } from 'reducers/rootReducer';
import {
  deleteDashboard,
  getDashboardShareHash,
  setProjectsIsCollapsed,
} from 'actions/dashboardActions';
import { DashboardInfo, DashboardReducerState } from 'reducers/dashboardReducer';
import PageListItem from 'components/pageListItem';
import LoadingSpinner from 'images/loading_spinner.gif';
import ConfirmationModal from 'components/modals/confirmationModal';
import { AppToaster } from 'toaster';
import CopyToClipboard from 'react-copy-to-clipboard';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 'calc(100vh - 80px)',
    display: 'flex',
    overflowY: 'auto' as 'auto',
    width: '100%',
    flexDirection: 'column',
    padding: `${theme.spacing(8)}px ${theme.spacing(6)}px 0px`,
  },
  title: {
    marginBottom: theme.spacing(12),
    fontWeight: 500,
    fontSize: 24,
  },
  sectionTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    fontWeight: 600,
    alignItems: 'center',
    fontSize: 20,
  },
  sectionTitleExpanded: {
    marginBottom: theme.spacing(6),
  },
  caretIcon: {
    marginRight: theme.spacing(1),
  },
  dashboardsSection: {
    marginBottom: theme.spacing(10),
  },
  loadingBody: {
    height: '75%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingSpinner: {
    width: 75,
  },
  shareUrlTextCopy: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  shareUrlInput: {
    marginRight: theme.spacing(2),
  },
  emptyListState: {
    borderRadius: 8,
    border: `2px dashed ${theme.palette.grey.slight}`,
    backgroundColor: theme.palette.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.grey.bold,
    fontSize: 16,
    fontWeight: 600,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & RouteComponentProps;

const renderShareDashboardText = (classes: any, shareDashboardHash: number) => {
  const shareUrl = `${process.env.REACT_APP_URL}share/${shareDashboardHash}/dashboard`;
  return (
    <div>
      <div>Anyone with the link below can view the dashboard</div>
      <div className={classes.shareUrlTextCopy}>
        <InputGroup className={classes.shareUrlInput} value={shareUrl} fill />{' '}
        <CopyToClipboard
          text={shareUrl}
          onCopy={() =>
            AppToaster.show({
              icon: 'clipboard',
              intent: Intent.PRIMARY,
              message: 'Dashboard URL copied to clipboard',
              timeout: 5000,
            })
          }>
          <Button icon="duplicate" minimal />
        </CopyToClipboard>
      </div>
    </div>
  );
};

const renderShareDashboardModal = (
  classes: any,
  props: Props,
  shareDashboardModalOpen: boolean,
  setShareDashboardModalOpen: any,
  shareDashboardHash?: number,
) => {
  return (
    <Dialog
      isOpen={shareDashboardModalOpen}
      onClose={() => setShareDashboardModalOpen(false)}
      title="Share Dashboard">
      <div className={Classes.DIALOG_BODY}>
        {shareDashboardHash === null ? (
          <Spinner intent={Intent.PRIMARY} />
        ) : (
          renderShareDashboardText(classes, shareDashboardHash!)
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={() => setShareDashboardModalOpen(false)}>Done</Button>
        </div>
      </div>
    </Dialog>
  );
};

const renderDeleteDashboardModal = (
  props: Props,
  deleteDashboardModalOpen: boolean,
  setDeleteDashboardModalOpen: any,
  dashboard?: DashboardInfo,
) => {
  const { deleteDashboard } = props;

  if (!deleteDashboardModalOpen || !dashboard) {
    return;
  }

  return (
    <ConfirmationModal
      modalOpen={deleteDashboardModalOpen}
      closeModal={() => setDeleteDashboardModalOpen(false)}
      modalTitle="Are you sure you want to delete this dashboard?"
      modalText="This action cannot be undone"
      submitBtnText="Delete"
      danger
      onSubmit={() => {
        deleteDashboard(
          { id: dashboard.id },
          (response: any) => {
            AppToaster.show({
              message: 'The dashboard was successfully deleted',
              icon: 'tick',
              timeout: 3000,
              intent: Intent.SUCCESS,
            });
          },
          (response: any) => {
            AppToaster.show({
              // Sometimes an object is inputted and, if not, the direct error message is inputed.
              message: response['msg'] || response,
              icon: 'error',
              timeout: 5000,
              intent: Intent.DANGER,
            });
          },
        );
      }}
    />
  );
};

const renderDashboardItem = (
  props: Props,
  dashboardId: number,
  dashboardData: DashboardReducerState,
  setDeleteDashboardModalOpen: any,
  setSelectedDashboard: any,
  setShareDashboardModalOpen: any,
  setShareDashboardHash: any,
) => {
  const { history, getDashboardShareHash } = props;
  const dashboard = _.findWhere(dashboardData.dashboardList!, { id: dashboardId });
  if (!dashboard) return;
  return (
    <PageListItem
      key={dashboard.id}
      title={dashboard.name}
      secondText={`Created ${moment(dashboard.created).fromNow()}`}
      thirdText={`Last edited ${moment(dashboard.modified).fromNow()}`}
      loading={dashboard.loading}
      itemActions={[
        {
          text: 'Delete Dashboard',
          onClick: () => {
            setSelectedDashboard(dashboard);
            setDeleteDashboardModalOpen(true);
          },
        },
        {
          text: 'Share Dashboard',
          onClick: () => {
            getDashboardShareHash({ id: dashboard.id }, (response) => {
              setShareDashboardHash(response.share_hash);
            });
            setShareDashboardModalOpen(true);
            setShareDashboardHash(undefined);
          },
        },
      ]}
      onClick={() => {
        history.push(`/dashboards/${dashboard.id}`);
      }}
    />
  );
};

const renderEmptyListState = (classes: any) => {
  return <div className={classes.emptyListState}>No dashboards in this project.</div>;
};

const renderDashboardGroup = (
  props: Props,
  dashboardGroup: any,
  dashboardData: DashboardReducerState,
  projectsIsCollapsed: boolean[],
  classes: any,
  index: number,
  setProjectsIsCollapsed: any,
  setDeleteDashboardModalOpen: any,
  setSelectedDashboard: any,
  setShareDashboardModalOpen: any,
  setShareDashboardHash: any,
) => {
  let dashboardItemList = dashboardGroup.resource_ids.map((dashboardId: number) =>
    renderDashboardItem(
      props,
      dashboardId,
      dashboardData,
      setDeleteDashboardModalOpen,
      setSelectedDashboard,
      setShareDashboardModalOpen,
      setShareDashboardHash,
    ),
  );
  dashboardItemList = dashboardItemList.filter((item: any) => !!item);

  return (
    <div key={dashboardGroup.id} className={classes.dashboardsSection}>
      <div
        className={cx(classes.sectionTitle, {
          [classes.sectionTitleExpanded]: !projectsIsCollapsed[index],
        })}>
        <Button
          className={classes.caretIcon}
          icon={
            <Icon icon={!projectsIsCollapsed[index] ? 'caret-down' : 'caret-right'} iconSize={18} />
          }
          minimal
          onClick={() => {
            projectsIsCollapsed[index] = !projectsIsCollapsed[index];
            setProjectsIsCollapsed(cloneDeep(projectsIsCollapsed));
          }}
        />
        {dashboardGroup.name}
      </div>
      {!projectsIsCollapsed[index] && dashboardItemList}
      {!projectsIsCollapsed[index] &&
        dashboardItemList.length === 0 &&
        renderEmptyListState(classes)}
    </div>
  );
};

const DashboardListPage = (props: Props) => {
  const classes = useStyles();
  const { dashboardData, projectsIsCollapsed, setProjectsIsCollapsed } = props;
  const [deleteDashboardModalOpen, setDeleteDashboardModalOpen] = useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState<undefined | DashboardInfo>(undefined);
  const [shareDashboardModalOpen, setShareDashboardModalOpen] = useState(false);
  const [shareDashboardHash, setShareDashboardHash] = useState<undefined | number>(undefined);

  return (
    <div className={classes.root}>
      <div className={classes.title}>Dashboards</div>
      {!dashboardData.dashboardGroupingsList || !projectsIsCollapsed ? (
        <div className={classes.loadingBody}>
          <img className={classes.loadingSpinner} src={LoadingSpinner} alt="loading spinner" />
        </div>
      ) : (
        dashboardData.dashboardGroupingsList.map((dashboardGroup: any, index: number) =>
          renderDashboardGroup(
            props,
            dashboardGroup,
            dashboardData,
            projectsIsCollapsed!,
            classes,
            index,
            setProjectsIsCollapsed,
            setDeleteDashboardModalOpen,
            setSelectedDashboard,
            setShareDashboardModalOpen,
            setShareDashboardHash,
          ),
        )
      )}
      {renderDeleteDashboardModal(
        props,
        deleteDashboardModalOpen,
        setDeleteDashboardModalOpen,
        selectedDashboard,
      )}
      {renderShareDashboardModal(
        classes,
        props,
        shareDashboardModalOpen,
        setShareDashboardModalOpen,
        shareDashboardHash,
      )}
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  dashboardData: state.dashboardData,
  projectsIsCollapsed: state.dashboardData.projectsIsCollapsed,
});

const mapDispatchToProps = {
  deleteDashboard,
  getDashboardShareHash,
  setProjectsIsCollapsed,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardListPage));

/** @format */

import _ from 'underscore';
import Cookies from 'js-cookie';

import { AGGREGATIONS_TYPES, FILTER_OPS_NO_VALUE, DATE_TYPES } from 'constants/flowConstants.tsx';

export const pollForResultsData = (resultsDatasetId, operations, onSuccess, onError) => {
  fetch(process.env.REACT_APP_API_URL + `datasets/${resultsDatasetId}/get_dataset_preview/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Token ' + Cookies.get('spheres_auth_token'),
    },
    body: JSON.stringify({
      operations: operations,
    }),
  })
    .then((resp) => resp.json())
    .then((responseData) => {
      if (!responseData.success) {
        if ('error_msg' in responseData) {
          onError && onError(responseData['error_msg']);
        } else {
          setTimeout(() => {
            pollForResultsData(resultsDatasetId, operations, onSuccess, onError);
          }, 500);
        }
      } else {
        onSuccess && onSuccess(responseData.preview_data);
      }
    });
};

export const columnNamePostAggregation = (aggedOnColumn, aggType) => {
  if (aggType === AGGREGATIONS_TYPES.COUNT.id && !aggedOnColumn) {
    return 'count';
  }
  return `${aggedOnColumn}_${aggType.toLowerCase()}`;
};

export const joinClauseComplete = (clause) => {
  return !(clause.joinColumn === '' || clause.sourceColumn === '');
};

export const dedupClauseComplete = (clause) => {
  if (!clause.type) {
    return false;
  }

  // simplify after verification
  if (DATE_TYPES.has(clause.type)) {
    return clause.aggBucket;
  }
  return true;
};

const unionClauseComplete = (clause) => {
  return clause.resultColumnName !== '';
};

export const filterClauseComplete = (clause) => {
  if (clause.filterColumn === '' || clause.filterOperation === '') {
    return false;
  } else if (FILTER_OPS_NO_VALUE.has(clause.filterOperation.id)) {
    return true;
  } else {
    return clause.filterValue !== '';
  }
};

export const filterInstructionsChanged = (oldInstructions, newInstructions) => {
  const oldFilterClauses = Array.isArray(oldInstructions)
    ? oldInstructions
    : oldInstructions.filterClauses;
  const newFilterClauses = Array.isArray(newInstructions)
    ? newInstructions
    : newInstructions.filterClauses;

  const oldMatchOnAll = Array.isArray(oldInstructions) ? true : oldInstructions.matchOnAll;
  const newMatchOnAll = Array.isArray(newInstructions) ? true : newInstructions.matchOnAll;

  if (oldMatchOnAll !== newMatchOnAll) {
    return true;
  }

  const oldClauses = oldFilterClauses.filter(filterClauseComplete);
  const newClauses = newFilterClauses.filter(filterClauseComplete);

  if (oldClauses.length !== newClauses.length) {
    return true;
  }

  return _.any(oldClauses.map((oldClause, index) => !_.isEqual(oldClause, newClauses[index])));
};

export const joinInstructionsComplete = (instructions) => {
  return instructions.columnsToJoinOn.filter(joinClauseComplete).length >= 1;
};

export const unionInstructionsComplete = (instructions) => {
  return instructions.columnsToUnionOn.filter(unionClauseComplete).length >= 1;
};

export const joinInstructionsChanged = (oldInstructions, newInstructions) => {
  if (oldInstructions.joinTable.datasetId !== newInstructions.joinTable.datasetId) {
    return true;
  }

  const oldCompletecolumnsToJoinOn = oldInstructions.columnsToJoinOn.filter(joinClauseComplete);
  const newCompletecolumnsToJoinOn = newInstructions.columnsToJoinOn.filter(joinClauseComplete);

  if (oldCompletecolumnsToJoinOn.length !== newCompletecolumnsToJoinOn.length) {
    return true;
  }

  const isAnyCompleteJoinClauseDifferent = _.any(
    oldCompletecolumnsToJoinOn.map(
      (oldCompleteJoinClause, index) =>
        !_.isEqual(oldCompleteJoinClause, newCompletecolumnsToJoinOn[index]),
    ),
  );
  if (isAnyCompleteJoinClauseDifferent) {
    return true;
  }

  return oldInstructions.joinType !== newInstructions.joinType;
};

export const unionInstructionsChanged = (oldInstructions, newInstructions) => {
  if (oldInstructions.unionTable.datasetId !== newInstructions.unionTable.datasetId) {
    return true;
  }

  const oldCompletecolumnsToUnionOn = oldInstructions.columnsToUnionOn.filter(unionClauseComplete);
  const newCompletecolumnsToUnionOn = newInstructions.columnsToUnionOn.filter(unionClauseComplete);

  if (oldCompletecolumnsToUnionOn.length !== newCompletecolumnsToUnionOn.length) {
    return true;
  }

  const isAnyCompleteUnionClauseDifferent = _.any(
    oldCompletecolumnsToUnionOn.map(
      (oldCompleteUnionClause, index) =>
        !_.isEqual(oldCompleteUnionClause, newCompletecolumnsToUnionOn[index]),
    ),
  );
  if (isAnyCompleteUnionClauseDifferent) return isAnyCompleteUnionClauseDifferent;
  return oldInstructions.unionType !== newInstructions.unionType;
};

export const schemaClauseComplete = (clause) => {
  if (!clause.col) {
    return false;
  } else {
    return true;
  }
};

export const schemaClauseInstructionsComplete = (clause) => {
  if ('keepCol' in clause && clause['keepCol'] !== true) {
    return true;
  }
  if ('newType' in clause && clause['newType']) {
    return true;
  }
  if ('safeCast' in clause && clause['safeCast'] !== false) {
    return true;
  }
  if ('newColName' in clause && clause['newColName']) {
    return true;
  }
  return false;
};

export const schemaInstructionsChanged = (oldInstructions, newInstructions) => {
  const oldClauses = oldInstructions.changeSchemaList.filter(schemaClauseComplete);
  const newClauses = newInstructions.changeSchemaList.filter(schemaClauseComplete);

  if (oldClauses.length !== newClauses.length) {
    return true;
  }

  return _.any(oldClauses.map((oldClause, index) => !_.isEqual(oldClause, newClauses[index])));
};

export const formulaClauseComplete = (clause) => {
  return clause.newColName !== '' && clause.colFormula !== null && clause.colFormula !== '';
};

export const formulaInstructionsChanged = (oldInstructions, newInstructions) => {
  const oldClauses = oldInstructions.filter(formulaClauseComplete);
  const newClauses = newInstructions.filter(formulaClauseComplete);

  if (oldClauses.length !== newClauses.length) {
    return true;
  }

  return _.any(oldClauses.map((oldClause, index) => !_.isEqual(oldClause, newClauses[index])));
};

export const pivotAggComplete = (agg) => {
  return agg.aggedOnColumn !== null && agg.type !== null;
};

export const pivotInstructionsComplete = (instructions) => {
  if (Object.keys(instructions.pivotedOnCols).length === 0) {
    return false;
  } else if (instructions.aggregations.length === 0) {
    return false;
  } else {
    return _.some(instructions.aggregations, pivotAggComplete);
  }
};

export const pivotInstructionsChanged = (oldInstructions, newInstructions) => {
  const oldCols = oldInstructions.pivotedOnCols;
  const newCols = newInstructions.pivotedOnCols;

  if (Object.keys(oldCols).length !== Object.keys(newCols).length) {
    return true;
  }

  if (_.any(Object.keys(oldCols).map((oldCol, index) => !_.isEqual(oldCol, newCols[index])))) {
    return true;
  }

  const oldAggs = oldInstructions.aggregations.filter(pivotAggComplete);
  const newAggs = newInstructions.aggregations.filter(pivotAggComplete);

  if (oldAggs.length !== newAggs.length) {
    return true;
  }

  return _.any(
    oldAggs.map((oldAgg, index) => {
      return (
        !_.isEqual(oldAgg.aggedOnColumn, newAggs[index].aggedOnColumn) ||
        !_.isEqual(oldAgg.type, newAggs[index].type)
      );
    }),
  );
};

export const dedupInstructionsComplete = (instructions) => {
  return instructions.colsToDedup.filter(dedupClauseComplete).length >= 1;
};

export const dedupInstructionsChanged = (oldInstructions, newInstructions) => {
  const oldDedupCols = oldInstructions.colsToDedup.filter(dedupClauseComplete);
  const newDedupCols = newInstructions.colsToDedup.filter(dedupClauseComplete);

  if (oldDedupCols.length !== newDedupCols.length) {
    return true;
  }

  if (_.any(oldDedupCols.map((oldCol, index) => !_.isEqual(oldCol, newDedupCols[index])))) {
    return true;
  }

  const oldClauses = oldInstructions.sortColumns.filter(sortClauseComplete);
  const newClauses = newInstructions.sortColumns.filter(sortClauseComplete);

  if (oldClauses.length !== newClauses.length) {
    return true;
  }

  return _.any(oldClauses.map((oldClause, index) => !_.isEqual(oldClause, newClauses[index])));
};

export const calculateInstructionsComplete = (instructions) => {
  if (instructions.aggregations.length === 0) {
    return false;
  } else {
    return _.some(instructions.aggregations, pivotAggComplete);
  }
};

export const calculateInstructionsChanged = (oldInstructions, newInstructions) => {
  const oldAggs = oldInstructions.aggregations.filter(pivotAggComplete);
  const newAggs = newInstructions.aggregations.filter(pivotAggComplete);

  if (oldAggs.length !== newAggs.length) {
    return true;
  }

  return _.any(
    oldAggs.map((oldAgg, index) => {
      return (
        !_.isEqual(oldAgg.aggedOnColumn, newAggs[index].aggedOnColumn) ||
        !_.isEqual(oldAgg.type, newAggs[index].type) ||
        !_.isEqual(oldAgg.additionalCol, newAggs[index].additionalCol)
      );
    }),
  );
};

export const lineOrBarChartInstructionsComplete = (instructions) => {
  if (!instructions.xAxisColumn || instructions.lineColumns.length === 0) {
    return false;
  }

  return _.any(instructions.lineColumns.map((lineConfig) => !!lineConfig.column));
};

export const lineOrBarChartInstructionsChanged = (oldInstructions, newInstructions) => {
  if (!_.isEqual(oldInstructions.xAxisColumn, newInstructions.xAxisColumn)) {
    return true;
  }

  const oldCols = oldInstructions.lineColumns;
  const newCols = newInstructions.lineColumns;

  if (oldCols.length !== newCols.length) {
    return true;
  }

  if (!_.isEqual(oldInstructions.chartSortColumn, newInstructions.chartSortColumn)) {
    return true;
  }
  if (!_.isEqual(oldInstructions.chartSortOrder, newInstructions.chartSortOrder)) {
    return true;
  }

  return _.any(
    oldCols.map((oldCol, index) => {
      return !_.isEqual(oldCol, newCols[index]);
    }),
  );
};

export const pivotChartInstructionsComplete = (instructions) => {
  if (
    !instructions.xAxisColumn ||
    !instructions.aggregation.type ||
    !instructions.aggregation.aggedOnColumn
  ) {
    return false;
  }
  return true;
};

export const pivotChartInstructionsChanged = (oldInstructions, newInstructions) => {
  if (!_.isEqual(oldInstructions.xAxisColumn, newInstructions.xAxisColumn)) {
    return true;
  }

  if (!_.isEqual(oldInstructions.aggregation, newInstructions.aggregation)) {
    return true;
  }

  return false;
};

export const sortClauseComplete = (clause) => {
  return clause.column && clause.order;
};

export const sortInstructionsComplete = (instructions) => {
  return instructions.sortColumns.filter(sortClauseComplete).length >= 1;
};

export const sortInstructionsChanged = (oldInstructions, newInstructions) => {
  const oldClauses = oldInstructions.sortColumns.filter(sortClauseComplete);
  const newClauses = newInstructions.sortColumns.filter(sortClauseComplete);

  if (oldClauses.length !== newClauses.length) {
    return true;
  }

  return _.any(oldClauses.map((oldClause, index) => !_.isEqual(oldClause, newClauses[index])));
};

export const axisPivotChartInstructionsChanged = (oldInstructions, newInstructions) => {
  return (
    !_.isEqual(oldInstructions.rowColumn, newInstructions.rowColumn) ||
    !_.isEqual(oldInstructions.colColumn, newInstructions.colColumn) ||
    !_.isEqual(oldInstructions.aggregation, newInstructions.aggregation)
  );
};

export const axisPivotChartInstructionsComplete = (instructions) => {
  return (
    instructions.rowColumn &&
    instructions.colColumn &&
    instructions.aggregation.type &&
    instructions.aggregation.aggedOnColumn
  );
};

export const instructionReadyToCompute = (instructions, operationType) => {
  switch (operationType) {
    case 'FILTER':
      // Having at least 1 good filter instruction is good enough.
      return instructions.filterClauses.filter(filterClauseComplete).length >= 1;
    case 'SORT':
      return sortInstructionsComplete(instructions);
    case 'CHANGE_SCHEMA':
      // Having at least 1 good schema change instruction is good enough.
      return instructions.changeSchemaList.filter(schemaClauseInstructionsComplete).length >= 1;
    case 'ENRICH':
      return joinInstructionsComplete(instructions);
    case 'UNION':
      return unionInstructionsComplete(instructions);
    case 'PIVOT':
      return pivotInstructionsComplete(instructions);
    case 'CALCULATE':
      return calculateInstructionsComplete(instructions);
    case 'FORMULA':
      // Having at least 1 good formula instruction is good enough.
      return instructions.filter(formulaClauseComplete).length >= 1;
    case 'LINE_CHART':
      return lineOrBarChartInstructionsComplete(instructions);
    case 'BAR_CHART':
      return pivotChartInstructionsComplete(instructions);
    case 'GROUPED_BAR_CHART':
      return lineOrBarChartInstructionsComplete(instructions);
    case 'DEDUP':
      return dedupInstructionsComplete(instructions);
    case 'AXIS_PIVOT_CHART':
      return axisPivotChartInstructionsComplete(instructions);
    default:
      return false;
  }
};

/** @format */

import { Intent } from '@blueprintjs/core';
import _ from 'underscore';

import {
  FILTER_OPS_NO_VALUE,
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
} from 'constants/flowConstants';
import { FilterClause } from 'constants/types';
import { OperationFooterErrorInformation } from 'utils/operationErrorInfoUtils/generalOperationErrorInfoUtil';

enum ROW_ERROR_TEXT {
  EMPTY_VALUE = 'Please enter a value',
}

enum FOOTER_ERROR_TEXT {
  NO_ERROR = 'No Error',
  FILL_ROWS = 'Please fill in the incomplete rows to submit',
}

export interface FilterRowErrorInformation {
  isFilterColumnButtonErrorState: boolean;
  isFilterOperationButtonErrorState: boolean;
  filterValueErrorText: string | null;
  filterValueInputIntent: Intent;
  filterValueStartDateError: boolean;
  filterValueEndDateError: boolean;
}

export const getFilterRowErrorInformation = (
  filterClause: FilterClause,
  index: number,
): FilterRowErrorInformation => {
  let filterRowErrorInformation: FilterRowErrorInformation = {
    isFilterColumnButtonErrorState: false,
    isFilterOperationButtonErrorState: false,
    filterValueErrorText: null,
    filterValueInputIntent: Intent.NONE,
    filterValueStartDateError: false,
    filterValueEndDateError: false,
  };

  // If its the first row and its empty, give an error
  if (index === 0 && filterClause.filterColumn === '' && filterClause.filterOperation === '') {
    filterRowErrorInformation.isFilterColumnButtonErrorState = true;
    filterRowErrorInformation.isFilterOperationButtonErrorState = true;
    return filterRowErrorInformation;
  }

  // if column is selected but operator is not, give an error
  if (filterClause.filterColumn !== '' && filterClause.filterOperation === '') {
    filterRowErrorInformation.isFilterOperationButtonErrorState = true;
    return filterRowErrorInformation;
  }

  // if the operator is selected and it is the type of operator to need value input
  // ... and that value input is empty - then give detailed errors
  if (filterClause.filterOperation && !FILTER_OPS_NO_VALUE.has(filterClause.filterOperation.id)) {
    if (filterClause.filterValue === '') {
      filterRowErrorInformation.filterValueInputIntent = Intent.DANGER;
      filterRowErrorInformation.filterValueErrorText = ROW_ERROR_TEXT.EMPTY_VALUE;
    } else if (FILTER_OPS_DATE_PICKER.has(filterClause.filterOperation.id)) {
      filterRowErrorInformation.filterValueStartDateError =
        filterClause.filterValue.startDate === null;
    } else if (FILTER_OPS_DATE_RANGE_PICKER.has(filterClause.filterOperation.id)) {
      filterRowErrorInformation.filterValueStartDateError =
        filterClause.filterValue.startDate === null;
      filterRowErrorInformation.filterValueEndDateError = filterClause.filterValue.endDate === null;
    }
  }
  return filterRowErrorInformation;
};

export const getFilterOperationFooterErrorInformation = (
  filterClauses: FilterClause[],
): OperationFooterErrorInformation => {
  const operationFooterErrorInformation: OperationFooterErrorInformation = {
    footerErrorState: false,
    footerErrorText: FOOTER_ERROR_TEXT.NO_ERROR,
  };

  if (
    !!_.find(filterClauses, (columnToUnionOn, index) => {
      const {
        isFilterColumnButtonErrorState,
        isFilterOperationButtonErrorState,
        filterValueErrorText,
        filterValueStartDateError,
        filterValueEndDateError,
      } = getFilterRowErrorInformation(columnToUnionOn, index);
      return (
        !!filterValueErrorText ||
        isFilterOperationButtonErrorState ||
        isFilterColumnButtonErrorState ||
        filterValueStartDateError ||
        filterValueEndDateError
      );
    })
  ) {
    operationFooterErrorInformation.footerErrorState = true;
    operationFooterErrorInformation.footerErrorText = FOOTER_ERROR_TEXT.FILL_ROWS;
  }
  return operationFooterErrorInformation;
};

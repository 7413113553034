/** @format */

import React from 'react';
import _ from 'underscore';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Dataset, ACTION } from 'actions/types';

import { Dialog, Spinner } from '@blueprintjs/core';

import { createNewDatasetSuccess, datasetUploadComplete } from 'actions/fileSystemActions';
import SortByButton from 'components/dataSource/sortByButton';
import DatasetSelectorPanel from 'components/fileSystem/datasetSelectorPanel';
import { getDatasetPreview, getEphemeralDatasetPreview } from 'actions/datasetActions';
import { fetchDatasetRowCount } from 'actions/flowActions';
import FileUploadButton from 'components/fileSystem/fileUploadButton';
import FileUploadModal from 'components/fileSystem/fileUploadModal';
import ConfirmationModal from 'components/modals/confirmationModal';
import { fetchWorkspaceData, switchWorkspaceData } from 'actions/dataSourceActions';

import {
  SORT_OPTIONS_BY_ID,
  SortOptionType,
  DATASETS_ORDERED_SORT_OPTIONS,
} from 'constants/flowConstants';
import { createLoadingSelector } from 'reducers/api/selectors';

const styles = (theme: Theme) => ({
  root: {
    width: '85vw',
    height: '80vh',
    paddingBottom: 0,
    '& .bp3-dialog-header': {
      marginBottom: 1,
    },
    '& .bp3-spinner': {
      height: '100%',
    },
  },
  footer: {
    backgroundColor: theme.palette.white,
    borderTop: `1px solid ${theme.palette.grey.border}`,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    zIndex: 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footerLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  footerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    marginRight: theme.spacing(4),
  },
  modalTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 0',
    marginRight: theme.spacing(2),
  },
  datasetOrderingBar: {
    margin: `0 ${theme.spacing(2)}px`,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

type MatchParams = {
  flowId: string;
};

type PassedProps = {
  isOpen: boolean;
  onClose: () => void;
  renderDatasetActions: (selectedDataset: any | null, onClose: () => void) => React.ReactElement;
  modalTitle?: string;
  openRenameDatasetModal?: (selectedDataset: Dataset) => void;
  openDeleteDatasetModal?: (selectedDataset: Dataset) => void;
  openMoveDatasetModal?: (selectedDataset: Dataset) => void;
  editingDisabled?: boolean;
  teamResourceIds?: Set<number>;
  currentUserId?: number;
  loading?: boolean;
  selectFromAllDatasources?: boolean;
};

type Props = PassedProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams> &
  WithStyles<typeof styles>;

type State = {
  selectedDataset: any | null;
  searchQuery: string;
  workspaceId: number | null;
  uploadFileModalOpen: boolean;
  uploadFileWarningOpen: boolean;
  uploadFileFile: any | null;
  datasetsSortOrder: SortOptionType;
  selectedDataSourceId?: number;
};

class SelectDatasetModal extends React.Component<Props, State> {
  state: State = {
    selectedDataset: null,
    searchQuery: '',
    workspaceId: null,
    uploadFileModalOpen: false,
    uploadFileWarningOpen: false,
    uploadFileFile: null,
    datasetsSortOrder: SORT_OPTIONS_BY_ID.LAST_EDITED,
  };

  render() {
    const { selectedDataset, searchQuery } = this.state;
    const {
      isOpen,
      onClose,
      classes,
      workspaceData,
      openRenameDatasetModal,
      openDeleteDatasetModal,
      openMoveDatasetModal,
      editingDisabled,
      teamResourceIds,
      currentUserId,
      loading,
      workspaceDataLoading,
      dataSourceList,
      selectFromAllDatasources,
    } = this.props;
    const isModalLoading = selectFromAllDatasources ? loading : loading || workspaceDataLoading;

    return (
      <Dialog
        className={classes.root}
        isOpen={isOpen}
        onClose={onClose}
        title={this.renderModalHeaderTitle()}>
        {isModalLoading ? (
          <Spinner size={70} />
        ) : (
          <DatasetSelectorPanel
            currentUserId={currentUserId}
            datasetsLoading={workspaceData.data === null}
            datasetTabsList={this.getFilteredDatasets()}
            onQueryChange={(query) => this.setState({ searchQuery: query })}
            searchQuery={searchQuery}
            onDatasetSelected={this.onDatasetSelected}
            selectedDataset={selectedDataset}
            datasetActions={this.renderDatasetActions()}
            searchActions={this.renderUploadCSVButton()}
            isExploSource={this.isExploSource()}
            openRenameDatasetModal={openRenameDatasetModal}
            openDeleteDatasetModal={openDeleteDatasetModal}
            openMoveDatasetModal={openMoveDatasetModal}
            editingDisabled={editingDisabled}
            teamResourceIds={teamResourceIds}
            maxPageNumber={selectedDataset && Math.ceil(selectedDataset.total_row_count / 50)}
            getEphemeralDatasetPreview={this.props.getEphemeralDatasetPreview}
            datasetOrderDropdown={this.renderDatasetOrderDropdown()}
            dataSourceList={dataSourceList}
            selectFromAllDatasources={selectFromAllDatasources}
            onNewDataSourceSelect={this.onNewDataSourceSelect}
            isDatasourceLoading={this.isDatasetsLoading()}
          />
        )}
        {this.renderFileUploadWarning()}
        {this.renderUploadFileModal()}
      </Dialog>
    );
  }

  isDatasetsLoading = () => {
    const { datasourceDictionary } = this.props;
    const { selectedDataSourceId } = this.state;
    if (!selectedDataSourceId) return false;
    return !datasourceDictionary[selectedDataSourceId.toString()];
  };

  isExploSource = () => {
    const { workspaceData, selectFromAllDatasources, datasourceDictionary } = this.props;
    const { selectedDataSourceId } = this.state;
    if (selectFromAllDatasources && selectedDataSourceId) {
      return !datasourceDictionary[selectedDataSourceId.toString()];
    }
    return workspaceData.data && workspaceData.data.explo_source;
  };

  onNewDataSourceSelect = (dataSourceId: number) => {
    const { datasourceDictionary, fetchWorkspaceData, switchWorkspaceData } = this.props;

    this.setState({ selectedDataSourceId: dataSourceId });
    if (!datasourceDictionary[dataSourceId.toString()]) {
      fetchWorkspaceData({ id: dataSourceId });
    } else {
      switchWorkspaceData(dataSourceId.toString());
    }
  };

  renderDatasetOrderDropdown = () => {
    const { datasetsSortOrder } = this.state;
    const { classes } = this.props;

    return (
      <SortByButton
        className={classes.datasetOrderingBar}
        selectedSort={datasetsSortOrder}
        setSort={(newSort) => this.setState({ datasetsSortOrder: newSort })}
        sortOptions={DATASETS_ORDERED_SORT_OPTIONS}
      />
    );
  };

  renderModalHeaderTitle = () => {
    const { classes, modalTitle } = this.props;
    return (
      <div className={classes.modalTitle}>
        <div>{modalTitle || 'Select a dataset'}</div>
      </div>
    );
  };

  renderFileUploadWarning = () => {
    const { uploadFileWarningOpen } = this.state;
    if (!uploadFileWarningOpen) return;

    return (
      <ConfirmationModal
        modalOpen={uploadFileWarningOpen}
        closeModal={() => this.setState({ uploadFileWarningOpen: false })}
        modalTitle="Warning"
        modalText="The File you are uploading might not be a supported format."
        submitBtnText="Continue"
        danger
        onSubmit={() =>
          this.setState({
            uploadFileWarningOpen: false,
            uploadFileModalOpen: true,
          })
        }
      />
    );
  };

  renderUploadFileModal = () => {
    const { uploadFileModalOpen, uploadFileFile } = this.state;
    const {
      currentUser,
      createNewDatasetSuccess,
      datasetUploadComplete,
      workspaceData,
    } = this.props;

    if (!uploadFileModalOpen) {
      return;
    }

    let allDatasets = workspaceData.CSVUploadDatasource.source_datasets.concat(
      workspaceData.CSVUploadDatasource.saved_datasets,
    );
    return (
      <FileUploadModal
        file={uploadFileFile}
        isOpen={uploadFileModalOpen}
        onModalClose={() => this.setState({ uploadFileModalOpen: false })}
        currentFolderId={currentUser.root_folder_id!}
        createNewDatasetSuccess={createNewDatasetSuccess}
        datasetUploadComplete={datasetUploadComplete}
        existingUploadedCSVNames={_.pluck(allDatasets, 'name')}
      />
    );
  };

  onDatasetSelected = (dataset: any) => {
    const { selectedDataset } = this.state;
    if (selectedDataset && selectedDataset.id === dataset.id) {
      this.setState({ selectedDataset: null });
    } else {
      if (!dataset.uploading && !dataset.results_last_updated_at) {
        this.props.getDatasetPreview({ id: dataset.id });
      }
      this.props.fetchDatasetRowCount({ id: dataset.id });
      this.setState({ selectedDataset: dataset });
    }
  };

  sortDatasets = (datasets: Dataset[]) => {
    const { datasetsSortOrder } = this.state;
    if (datasetsSortOrder.id === SORT_OPTIONS_BY_ID.LAST_EDITED.id) {
      datasets.sort((a: Dataset, b: Dataset) =>
        Date.parse(a.results_last_updated_at) < Date.parse(b.results_last_updated_at) ? 1 : -1,
      );
    } else if (datasetsSortOrder.id === SORT_OPTIONS_BY_ID.NAME.id) {
      datasets.sort((a: Dataset, b: Dataset) => (a.name > b.name ? 1 : -1));
    }
    return datasets;
  };

  getDatabaseName = (name: string) => {
    const splitList = name.split('.');
    if (splitList.length === 3) {
      return splitList[0];
    }
  };

  getSchemaName = (name: string) => {
    const splitList = name.split('.');
    if (splitList.length === 2) {
      return splitList[0];
    } else if (splitList.length === 3) {
      return splitList[1];
    }
  };

  getTableName = (name: string) => {
    const splitList = name.split('.');
    if (splitList.length === 1) {
      return splitList[0];
    } else if (splitList.length === 2) {
      return splitList[1];
    } else if (splitList.length === 3) {
      return splitList[2];
    }
  };

  getFilteredDatasets = () => {
    const { workspaceData, selectFromAllDatasources, datasourceDictionary } = this.props;
    const { searchQuery, selectedDataSourceId } = this.state;

    if ((!selectedDataSourceId && selectFromAllDatasources) || this.isDatasetsLoading()) return [];
    const workspaceDataInfo = selectFromAllDatasources
      ? datasourceDictionary[selectedDataSourceId!.toString()]
      : workspaceData.data;
    if (workspaceDataInfo === null) return [];

    let allDatasets = workspaceDataInfo.source_datasets.concat(workspaceDataInfo.saved_datasets);

    let dataSourceFolders: Set<string> = new Set();
    allDatasets.forEach((dataset: Dataset) => {
      if (dataset.is_source_dataset) {
        const schemaName = this.getSchemaName(dataset.data_source_table_name);
        schemaName && dataSourceFolders.add(schemaName);
      }
    });

    if (searchQuery.length > 0 && workspaceData.searchIndex) {
      const results = workspaceData.searchIndex.search(searchQuery);
      allDatasets = _.pluck(results, 'item');
    }

    let datasetTabsList = [];

    // New datasets.
    let newDatasets = [...allDatasets];
    newDatasets = _.filter(newDatasets, (dataset) => this.isNewDataset(dataset));
    if (newDatasets.length > 0) {
      datasetTabsList.push({ name: 'New', data: this.sortDatasets(newDatasets) });
    }

    // Personal datasets.
    let userDatasets = [...allDatasets];
    userDatasets = _.filter(
      userDatasets,
      (dataset) =>
        !this.isNewDataset(dataset) &&
        workspaceData.dataSourcePageInfo.user_resource_ids.has(dataset.id),
    );
    if (userDatasets.length > 0) {
      datasetTabsList.push({ name: 'Personal', data: this.sortDatasets(userDatasets) });
    }

    // Team datasets.
    let teamDatasets = [...allDatasets];
    teamDatasets = _.filter(
      teamDatasets,
      (dataset) =>
        !this.isNewDataset(dataset) &&
        workspaceData.dataSourcePageInfo.team_resource_ids.has(dataset.id),
    );
    if (teamDatasets.length > 0) {
      datasetTabsList.push({ name: 'Team', data: this.sortDatasets(teamDatasets) });
    }

    // Data Source datasets.
    let dataSourceDatasets = [...allDatasets];
    dataSourceDatasets = _.filter(dataSourceDatasets, (dataset) => dataset.is_source_dataset);
    // Filter the Data Source tab for datasets not in sub folders.
    const newDataSourceTab: Dataset[] = dataSourceDatasets.filter(
      (dataset: Dataset) => !dataset.data_source_table_name.includes('.'),
    );
    if (newDataSourceTab.length > 0) {
      datasetTabsList.push({
        name: 'Data Source',
        data: this.sortDatasets(newDataSourceTab),
      });
    }
    // Construct sub folders for the Data Source.
    dataSourceFolders.forEach((newFolder: string) => {
      const newDataSourceTab: Dataset[] = dataSourceDatasets
        .filter((dataset: Dataset) => dataset.data_source_table_name.includes(newFolder + '.'))
        .map((dataset: Dataset) => {
          const tableName = this.getTableName(dataset.data_source_table_name);
          if (tableName) {
            dataset.name = dataset.name || tableName;
          }
          return dataset;
        });
      datasetTabsList.push({
        name: newFolder,
        data: this.sortDatasets(newDataSourceTab),
      });
    });

    return datasetTabsList;
  };

  isNewDataset = (dataset: Dataset) => {
    return !!(dataset.new || dataset.uploading);
  };

  renderUploadCSVButton = () => {
    const { workspaceData, selectFromAllDatasources } = this.props;

    if ((workspaceData.data && workspaceData.data.explo_source) || selectFromAllDatasources) {
      return (
        <FileUploadButton fill buttonText="Upload new dataset" uploadFile={this.onFileUpload} />
      );
    }
  };

  onFileUpload = (file: File) => {
    if (file.name.split('.').pop() !== 'csv') {
      this.setState({
        selectedDataset: null,
        uploadFileWarningOpen: true,
        uploadFileFile: file,
      });
    } else {
      this.setState({
        selectedDataset: null,
        uploadFileModalOpen: true,
        uploadFileFile: file,
      });
    }
  };

  renderDatasetActions = () => {
    const { selectedDataset } = this.state;
    const { renderDatasetActions, onClose } = this.props;
    return renderDatasetActions(selectedDataset, onClose);
  };
}

const workspaceDataLoadingSelector = createLoadingSelector([ACTION.FETCH_WORKSPACE_DATA]);
const mapStateToProps = (state: ReduxState) => ({
  workspaceDataLoading: workspaceDataLoadingSelector(state),
  workspaceData: state.workspaceData,
  currentUser: state.currentUser,
  dataSourceList: state.dataSourceList,
  datasourceDictionary: state.workspaceData.datasourceDictionary,
});

const mapDispatchToProps = {
  createNewDatasetSuccess,
  datasetUploadComplete,
  getDatasetPreview,
  fetchDatasetRowCount,
  getEphemeralDatasetPreview,
  fetchWorkspaceData,
  switchWorkspaceData,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SelectDatasetModal)),
);

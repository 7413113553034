/** @format */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { NonIdealState, Spinner, Intent, Button } from '@blueprintjs/core';

import { pageView } from 'analytics/exploAnalytics';

import SelectDatasetModal from 'components/fileSystem/selectDatasetModal';
import TextFieldModal from 'components/TextFieldModal';

import { createFlowFromDataset } from 'actions/flowActions';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';

const styles = (theme: Theme) => ({
  root: {
    margin: `${theme.spacing(6)}px auto`,
    maxWidth: 1100,
  },
  loadingState: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },
  selectDatasetButton: {
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    borderRadius: 10,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.grey.middle,
    },
  },
  selectDatasetInfo: {
    height: 200,
    border: `1px solid ${theme.palette.grey.blueprintGrey}`,
    borderStyle: 'dashed',
    borderRadius: 10,
  },
});

type PassedProps = {
  selectDatasetModalOpen: boolean;
  setDatasetModalOpen: (datasetModalOpen: boolean) => void;
  destFolderId?: number;
};

type MatchParams = {
  flowId: string;
};
type Props = PassedProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams> &
  WithStyles<typeof styles>;

type State = {
  selectedDataset: any;
  nameFlowModalOpen: boolean;
};

class NewFlowModal extends React.Component<Props, State> {
  state: State = {
    selectedDataset: null,
    nameFlowModalOpen: false,
  };

  componentDidMount() {
    pageView('New Flow');
  }

  render() {
    const { nameFlowModalOpen } = this.state;
    const {
      createFlowLoading,
      classes,
      dataSourceListLoading,
      selectDatasetModalOpen,
      setDatasetModalOpen,
    } = this.props;

    if (createFlowLoading) {
      return (
        <div className={classes.loadingState}>
          <Spinner intent={Intent.PRIMARY} />
        </div>
      );
    }

    return (
      <>
        {selectDatasetModalOpen && (
          <SelectDatasetModal
            isOpen={selectDatasetModalOpen}
            onClose={() => {
              setDatasetModalOpen(false);
            }}
            renderDatasetActions={this.renderSelectDatasetActions}
            modalTitle="Select a dataset to create an exploration"
            loading={dataSourceListLoading}
            editingDisabled
            selectFromAllDatasources
          />
        )}
        {nameFlowModalOpen && (
          <TextFieldModal
            modalOpen={this.state.nameFlowModalOpen}
            closeModal={() => this.setState({ nameFlowModalOpen: false })}
            modalTitle="Name New Exploration"
            textFieldPlaceholder="Exploration Name"
            buttonName="Create"
            onSubmit={this.onFlowNamed}
          />
        )}
      </>
    );
  }

  renderSelectDatasetActions = (selectedDataset: any | null, onClose: () => void) => {
    return (
      <Button
        intent={Intent.PRIMARY}
        disabled={selectedDataset === null || !selectedDataset.results_last_updated_at}
        onClick={() => {
          this.onDatasetSelected(selectedDataset);
        }}>
        Start Exploration
      </Button>
    );
  };

  getNewFlowBreadCrumbs = () => {
    return [
      {
        icon: 'data-lineage',
        text: 'Untitled Exploration',
      },
    ];
  };

  renderSelectDatasetButton = () => {
    const { classes, setDatasetModalOpen } = this.props;

    return (
      <div className={classes.root}>
        <div
          className={classes.selectDatasetButton}
          onClick={() => {
            this.setState({
              selectedDataset: null,
            });
            setDatasetModalOpen(true);
          }}>
          <div className={classes.selectDatasetInfo}>
            <NonIdealState
              icon="th"
              title="Select a dataset to get started!"
              description="In order to start an exploration, click here to select a dataset."
            />
          </div>
        </div>
      </div>
    );
  };

  onDatasetSelected = (dataset: any): void => {
    const { setDatasetModalOpen } = this.props;
    this.setState({
      selectedDataset: dataset,
      nameFlowModalOpen: true,
    });
    setDatasetModalOpen(false);
  };

  onFlowNamed = (newFlowName: string) => {
    const { createFlowFromDataset, currentUser, destFolderId } = this.props;
    const { selectedDataset } = this.state;

    if (selectedDataset === null) {
      return;
    }

    const destinationFolderId = destFolderId || currentUser.root_folder_id;

    createFlowFromDataset(selectedDataset.id, destinationFolderId, newFlowName, (flowId: number) =>
      this.props.history.push(`/flows/${flowId}`),
    );
  };
}

const dataSourceListLoadingSelector = createLoadingSelector([ACTION.FETCH_DATA_SOURCE_LIST]);
const mapStateToProps = (state: ReduxState) => ({
  dataSourceListLoading: dataSourceListLoadingSelector(state),
  currentUser: state.currentUser,
  createFlowLoading: state.flowData.createFlowLoading,
});

const mapDispatchToProps = {
  createFlowFromDataset,
};

export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NewFlowModal)),
);

/** @format */

import React, { Component } from 'react';
import _ from 'underscore';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import { InputGroup, Button } from '@blueprintjs/core';

import OperationDropdownInput from 'components/flowBuilder/operationDropdownInput';

import { FILTER_OPERATORS, INTEGER_DATA_TYPE } from 'constants/flowConstants';

const styles = (theme: Theme) => ({
  name: {
    textTransform: 'uppercase' as 'uppercase',
    fontWeight: 'bold' as 'bold',
    fontSize: 12,
    marginBottom: theme.spacing(1),
  },
  raiseName: {},
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  operatorSelector: {
    marginRight: theme.spacing(2),
  },
});

type PassedProps = {
  name: string;
  initialValue?: string;
  onNewValueSubmitted: (newValue: string) => void;
  onFilterOperatorSelect: (newOperator: any) => void;
  operator: any;
};

type Props = WithStyles<typeof styles> & PassedProps;

type State = {
  value?: string;
  inputFocused: boolean;
};

export class InputWithNameAndOperator extends Component<Props, State> {
  inputRef: any;

  state: State = {
    value: this.props.initialValue,
    inputFocused: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState({ value: this.props.initialValue });
      this.inputRef.blur();
    }
  }

  render = () => {
    const { value } = this.state;
    const { name, classes, initialValue, operator, onFilterOperatorSelect } = this.props;

    const relevantOperators = _.filter(FILTER_OPERATORS, (op) =>
      op.supported_column_types.has(INTEGER_DATA_TYPE),
    );

    return (
      <div>
        <div className={classes.name}>{name}</div>
        <div className={classes.inputContainer}>
          <OperationDropdownInput
            className={classes.operatorSelector}
            selectedItem={operator}
            onChange={onFilterOperatorSelect}
            options={relevantOperators.map((op) => ({ name: op.selectionValue, id: op.id }))}
            noSelectionText=""
            hideBtnCaret
            filterable={false}
          />
          <InputGroup
            value={value || ''}
            onFocus={() => {
              this.setState({ inputFocused: true });
            }}
            onBlur={() => {
              this.setState({ value: initialValue, inputFocused: false });
            }}
            onKeyPress={this.onKeyPress}
            onChange={(e: any) => {
              this.setState({ value: e.target.value });
            }}
            rightElement={this.renderRightElement()}
            inputRef={(ref) => (this.inputRef = ref)}
          />
        </div>
      </div>
    );
  };

  renderRightElement = () => {
    const { inputFocused } = this.state;
    const { onNewValueSubmitted } = this.props;

    const icon = inputFocused ? 'key-enter' : 'cross';
    const disabled = inputFocused;
    const onClick = inputFocused ? undefined : () => onNewValueSubmitted('');

    return <Button icon={icon} disabled={disabled} minimal onClick={onClick} />;
  };

  onKeyPress = (event: any) => {
    const { onNewValueSubmitted } = this.props;
    const { value } = this.state;
    var code = event.keyCode || event.which;
    if (code === 13 && value) {
      onNewValueSubmitted(value || '');
    }
  };
}

export default withStyles(styles)(InputWithNameAndOperator);

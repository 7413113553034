/** @format */
import _ from 'underscore';

import { DashboardFilter, DashboardFilterField } from 'actions/types';
import { ActionFn } from 'actions/actionUtils';

const _getOperationFilterLookupFromFilters = (filters: DashboardFilter[]) => {
  const operationFilterMap: { [operationId: string]: DashboardFilterField[] } = {};

  filters.map((filter) => {
    Object.keys(filter.operation_fields).map((operationId: string) => {
      if (operationFilterMap[operationId] === undefined) {
        operationFilterMap[operationId] = [];
      }
      operationFilterMap[operationId].push({
        ...filter.operation_fields[operationId],
        value: filter.value,
        filter_operator: filter.filter_operator,
      });
      return null;
    });
    return null;
  });

  return operationFilterMap;
};

export const updateDashboard = (
  dashboardDataInfo: any,
  refreshDashboardOperation: ActionFn,
  fetchDashboardOperationRowCount: any,
  refreshDashboard: ActionFn,
  selectedDashBoardId?: number | null,
  updatedOperationIds?: number[],
) => {
  const { operations, filters } = dashboardDataInfo;

  const filtrsByOperation = _getOperationFilterLookupFromFilters(filters);

  // Updating Operations
  const allOperationIds = operations.map((op: any) => op.id);
  const updateOperationIds = updatedOperationIds || _.pluck(operations, 'id');

  updateOperationIds.forEach((id) =>
    refreshDashboardOperation(
      {
        id: id,
        postData: {
          updating_op_ids: allOperationIds,
          filters: filtrsByOperation[id],
        },
      },
      (response: any) => {
        fetchDashboardOperationRowCount({ id, postData: { sql_query: response.sql_query } });
      },
    ),
  );

  // Updating Dashboard
  refreshDashboard({ id: selectedDashBoardId });
};

/** @format */

import React from 'react';
import _ from 'underscore';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { ReduxState } from 'reducers/rootReducer';
import { connect } from 'react-redux';

import ConfirmationModal from 'components/modals/confirmationModal';

import { fetchDatasetRelatedFlows } from 'actions/datasetActions';

import { FlowMini, Dataset } from 'actions/types';

const styles = (theme: Theme) => ({
  root: {},
});

type PassedProps = {
  dataset: Dataset;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  deleteDataset: (resourceId: number, name: string, resourceType: string) => void;
};

type Props = PassedProps & WithStyles<typeof styles> & typeof mapDispatchToProps;

type State = {
  deleteDatasetRelatedFlows: FlowMini[] | null;
};

class DeleteDatasetConfirmationModal extends React.Component<Props, State> {
  state: State = {
    deleteDatasetRelatedFlows: null,
  };

  componentDidMount() {
    const { fetchDatasetRelatedFlows, dataset } = this.props;

    fetchDatasetRelatedFlows(
      { id: dataset.id },
      (response: any) => {
        this.setState({ deleteDatasetRelatedFlows: response.flows });
      },
      () => {},
    );
  }

  render() {
    const { isOpen, onClose, title, dataset, deleteDataset } = this.props;
    return (
      <ConfirmationModal
        modalOpen={isOpen}
        closeModal={onClose}
        modalTitle={title || 'Are you sure you want to delete this dataset?'}
        modalText={this.getModalText()}
        submitBtnText="Delete"
        danger
        onSubmit={() => deleteDataset(dataset.id, dataset.name, 'dataset')}
      />
    );
  }

  getModalText = () => {
    const { deleteDatasetRelatedFlows } = this.state;

    return deleteDatasetRelatedFlows &&
      _.size(_.map(deleteDatasetRelatedFlows, (item: any) => item.name)) > 0 ? (
      <div>
        <div>This will delete operations from the following flows:</div>
        <ul>
          {_.map(deleteDatasetRelatedFlows, (item: any) => (
            <li key={item.id}>
              <a href={`/flows/${item.id}`} target="_blank" rel="noopener noreferrer">
                {item.name}
              </a>
            </li>
          ))}
        </ul>
        <div>This action cannot be undone.</div>
      </div>
    ) : (
      <div>This action cannot be undone.</div>
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = {
  fetchDatasetRelatedFlows,
};

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(DeleteDatasetConfirmationModal));

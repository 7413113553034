/** @format */

import { Intent } from '@blueprintjs/core';
import { Schema, SchemaChange } from 'constants/types';
import { SchemaChangeMap } from 'components/flowBuilder/editOperationDrawer/changeSchemaEditOperationBody';
import _ from 'underscore';

// CHANGE SCHEMA EDIT OPERATION BODY

export const getChangeSchemaOperationRowErrorInformation = (
  name: string,
  instructions: SchemaChangeMap,
) => {
  const noValidationErrors = { validationText: null, inputIntent: Intent.NONE };

  const columnRemoved =
    name in instructions && 'keepCol' in instructions[name]
      ? !instructions[name]['keepCol']
      : false;

  if (columnRemoved || !(name in instructions) || instructions[name]['newColName'] === null) {
    return noValidationErrors;
  } else if (!isNaN(parseInt(instructions[name]['newColName']!.charAt(0)))) {
    return {
      validationText: 'Please use a column name that does not begin with a number',
      inputIntent: Intent.DANGER,
    };
  } else if (instructions[name]['newColName'] === '') {
    return {
      validationText: 'Please give the column a name',
      inputIntent: Intent.DANGER,
    };
  }
  return noValidationErrors;
};

const colIsRemoved = (schemaChange: SchemaChange) => {
  return 'keepCol' in schemaChange && !schemaChange['keepCol'];
};

export const isChangeSchemaOperationInstructionsIncomplete = (
  instructions: SchemaChangeMap,
  schema: Schema,
) => {
  const schemaChanges = Object.values(instructions);
  let allRowsRemoved: boolean = false;
  if (schemaChanges.length === 0) {
    allRowsRemoved = false;
  } else {
    allRowsRemoved = _.every(schema, (column) => {
      if (column.name in instructions) {
        return colIsRemoved(instructions[column.name]);
      }
      return false;
    });
  }

  if (allRowsRemoved) {
    return {
      isFooterError: true,
      footerErrorText: 'You may not remove all the rows in the table',
    };
  }

  // @ts-ignore
  const isRowError = !!_.find(Object.values(instructions), (schemaChange: SchemaChange) => {
    const { validationText } = getChangeSchemaOperationRowErrorInformation(
      schemaChange.col,
      instructions,
    );
    return !!validationText;
  });

  if (isRowError) {
    return {
      isFooterError: true,
      footerErrorText: 'Please enter valid Column names to submit',
    };
  }

  return {
    isFooterError: false,
    footerErrorText: '',
  };
};

/** @format */

export default {
  currentUser: {
    loginLoading: false,
    registrationLoading: false,
  },
  datasetData: {
    loading: true,
    error: null,
    datasetInfo: null,
    schemaChangeLoading: false,
  },
  dashboardData: {
    listLoading: true,
    loading: true,
    error: null,
    data: null,
    dashboardList: null,
  },
  dataSourceList: [],
  dataSource: {
    loading: true,
    error: null,
    data: null,
  },
  supportedDataSources: {
    loading: true,
    sources: [],
  },
  teamData: {
    loading: true,
    data: null,
  },
  covidData: {
    operations: {},
  },
};

/** @format */
import {
  Button,
  H3,
  Icon,
  NumericInput,
  Position,
  Tooltip,
  RadioGroup,
  Radio,
} from '@blueprintjs/core';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Dataset } from 'actions/types';
import cx from 'classnames';
import CancelClauseButton from 'components/flowBuilder/editOperationDrawer/cancelClauseButton';
import EditDrawerDataPreview from 'components/flowBuilder/editOperationDrawer/editDrawerDataPreview';
import DedupTiebreakTable from 'components/flowBuilder/editOperationDrawer/dedupTiebreakTable';
import OperationDropdownInput from 'components/flowBuilder/operationDropdownInput';
import {
  DATE_PIVOT_AGGS,
  DATE_TYPES,
  NUMBER_TYPES,
  PIVOT_AGG_TYPES,
  OPERATION_TYPES,
} from 'constants/flowConstants';
import {
  AggregationColumnInfo,
  DedupOperationInstructions,
  SelectedDropdownInputItem,
  SortClause,
  SortOrder,
} from 'constants/types';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import _ from 'underscore';
import { dedupClauseComplete, sortClauseComplete } from 'utils/flowUtils';
import { getDedupOperationFooterErrorInformation } from 'utils/operationErrorInfoUtils/dedupOperationErrorInfoUtil';
import { getPivotRowErrorInformation } from 'utils/operationErrorInfoUtils/pivotOperationErrorInfoUtil';
import OperationDrawerFooter from './operationDrawerFooter';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    body: {
      height: 'calc(100% - 54px)',
      overflowY: 'scroll',
    },
    aggregationSelectorContainer: {
      margin: `0 ${theme.spacing(6)}px`,
      marginBottom: theme.spacing(6),
      backgroundColor: theme.palette.white,
      borderRadius: '4px',
      boxShadow: theme.customShadows.basic,
    },
    aggregationSelectorTable: {
      width: '100%',
    },
    addAggregationBtnContainer: {
      padding: theme.spacing(3),
    },
    helpTooltip: {
      marginLeft: theme.spacing(2),
    },
    sortHeaderToolTip: {
      paddingTop: 5,
    },
    helpTooltipPopover: {
      width: 250,
    },
    dedupColNameCol: {
      width: 224,
    },
    bucketSizeIntegerInput: {
      width: 150,
    },
    selectionTitle: {
      margin: theme.spacing(6),
    },
    sectionTitleWithInputContainer: {
      margin: theme.spacing(6),
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    sortClausesContainer: {
      margin: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
      marginBottom: theme.spacing(6),
      backgroundColor: theme.palette.white,
      boxShadow: theme.customShadows.basic,
      borderRadius: 4,
    },
    clauseTable: {
      width: '100%',
    },
    addClauseButton: {
      margin: theme.spacing(3),
      marginLeft: 52,
    },
    sortColumnsFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    sortByTextCol: {
      width: 100,
      paddingTop: `${theme.spacing(4)}px !important`,
    },
    columnSelectCol: {
      width: 200,
    },
    orderSelectCol: {
      width: 200,
    },
    ButtonGroup: {},
    matchOptionRadio: {
      marginBottom: theme.spacing(6),
    },
    matchOptionPanel: {
      margin: theme.spacing(6),
      backgroundColor: theme.palette.white,
      borderRadius: 4,
      boxShadow: theme.customShadows.basic,
      padding: theme.spacing(4),
      paddingBottom: 1,
    },
  });

type PassedProps = {
  instructions: DedupOperationInstructions;
  operationId: number;
  sourceDataset: Dataset;
  onSubmit: (newInstructions: DedupOperationInstructions) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  instructions: DedupOperationInstructions;
};

class DedupOperationBody extends React.Component<Props, State> {
  readonly state: State = {
    instructions: cloneDeep(this.props.instructions),
  };

  render() {
    const { classes, sourceDataset } = this.props;
    const { instructions } = this.state;
    const { footerErrorState, footerErrorText } = getDedupOperationFooterErrorInformation(
      instructions,
    );
    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <EditDrawerDataPreview sourceDataset={sourceDataset} dataPreviewHeader="Source Data" />
          {this.renderDedupOptions()}
          {this.renderUniqueColumnsSelector()}
          {this.renderTieBreakerSelector()}
        </div>
        <OperationDrawerFooter
          operationType={OPERATION_TYPES.DEDUP.id}
          errorState={footerErrorState}
          errorText={footerErrorText}
          onSubmit={() => this.onSubmit(instructions)}
        />
      </div>
    );
  }

  updateSortClauseInstructions = (tableElements: SortClause[]) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.sortColumns = tableElements;
      return { instructions };
    });
  };
  // add function that changes actual instructions..
  renderDedupOptions = () => {
    const { classes } = this.props;
    const { instructions } = this.state;
    return (
      <>
        <H3 className={classes.selectionTitle}>Row removal</H3>
        <RadioGroup
          className={classes.matchOptionPanel}
          onChange={(e: any) => this.handleDedupOptionChange(e.target.value === '0')}
          selectedValue={instructions.isAllColsSelected === true ? 0 : 1}>
          <Radio className={classes.matchOptionRadio} value={0}>
            <b>Remove rows where every column matches</b>
          </Radio>
          <Radio className={classes.matchOptionRadio} value={1}>
            <b>Remove any rows that have duplicate values in the following columns</b>
          </Radio>
        </RadioGroup>
      </>
    );
  };

  handleDedupOptionChange = (isAllColsSelected: boolean) => {
    const { sourceDataset } = this.props;
    this.setAllColsSelected(isAllColsSelected);
    if (isAllColsSelected) {
      this.setState((prevState) => {
        const { instructions } = prevState;
        instructions.colsToDedup = sourceDataset.cached_schema! as AggregationColumnInfo[];
        instructions.colsToDedup.forEach((aggregationColumnInfo, index) => {
          instructions.colsToDedup[index] = this.setAggBuckets(aggregationColumnInfo);
        });
        instructions.sortColumns = [];
        return { instructions };
      });
    } else {
      this.setState((prevState) => {
        const { instructions } = prevState;
        instructions.colsToDedup = [
          {
            name: 'Select a Column',
            type: '',
          },
        ];

        instructions.sortColumns = [
          {
            column: null,
            order: SortOrder.ASC,
          },
        ];

        return { instructions };
      });
    }
  };

  renderUniqueColumnsSelector = () => {
    const { classes } = this.props;
    const { instructions } = this.state;
    const { colsToDedup } = instructions;

    if (instructions.isAllColsSelected) {
      return;
    }

    return (
      <>
        <H3 className={classes.selectionTitle}>Select which column to identify duplicates by</H3>
        <div className={classes.aggregationSelectorContainer}>
          <table className={cx(classes.aggregationSelectorTable, 'bp3-html-table')}>
            <thead>
              <tr>
                <th></th>
                <th>Column</th>
                <th>
                  Bucket Size
                  <Tooltip
                    className={classes.helpTooltip}
                    popoverClassName={classes.helpTooltipPopover}
                    position={Position.TOP}
                    content="Number fields and date fields may be grouped into buckets. Bucket sizes determine how we determine duplicate data.">
                    <Icon icon="help" />
                  </Tooltip>
                </th>
              </tr>
            </thead>
            <tbody>{Object.values(colsToDedup).map(this.renderDedupColumnRow)}</tbody>
          </table>
          <div className={classes.addAggregationBtnContainer}>
            <Button icon="add" onClick={this.addColumnToDedup}>
              Add Column
            </Button>
          </div>
        </div>
      </>
    );
  };

  renderDedupColumnRow = (selectedColumn: AggregationColumnInfo, index: number) => {
    const { classes, operationId, sourceDataset } = this.props;
    const { instructions } = this.state;

    const selectedColumnNames = new Set(
      instructions.colsToDedup
        .filter((col: AggregationColumnInfo) => col.type)
        .map((col: AggregationColumnInfo) => col.name),
    );

    const { isPivotColButtonErrorState, isDateButtonErrorState } = getPivotRowErrorInformation(
      selectedColumn,
      index,
    );

    return (
      <tr key={`dedup_agg_${operationId}}_${index}`}>
        <CancelClauseButton index={index} onClick={() => this.removeColumnToDedup(index)} />
        <td className={classes.dedupColNameCol}>
          <OperationDropdownInput
            buttonErrorState={isPivotColButtonErrorState}
            selectedItem={
              selectedColumn.type
                ? ({
                    ...selectedColumn,
                    id: selectedColumn.name,
                  } as SelectedDropdownInputItem)
                : undefined
            }
            onChange={(item) => {
              this.setColumnToDedup(index, item.name);
            }}
            noSelectionText="Select a Column"
            selectedOptionNames={selectedColumnNames}
            options={sourceDataset.cached_schema!.map((col) => ({ ...col, id: col.name }))}
          />
        </td>
        <td className={classes.dedupColNameCol}>
          {this.renderAggSelector(selectedColumn, index, isDateButtonErrorState)}
        </td>
      </tr>
    );
  };

  renderAggSelector = (
    colData: AggregationColumnInfo,
    index: number,
    isDateButtonErrorState: boolean,
  ) => {
    const { classes } = this.props;

    if (DATE_TYPES.has(colData.type)) {
      return (
        <OperationDropdownInput
          buttonErrorState={isDateButtonErrorState}
          selectedItem={colData.aggBucket as SelectedDropdownInputItem}
          onChange={(item) => this.setDedupColAggBucket(item, index)}
          noSelectionText="Select Date Group"
          options={DATE_PIVOT_AGGS}
        />
      );
    } else if (NUMBER_TYPES.has(colData.type)) {
      return (
        <NumericInput
          className={classes.bucketSizeIntegerInput}
          placeholder={'1, 10, 100...'}
          value={(colData.aggBucket as number) || 0}
          onValueChange={(newNum) => this.setDedupColAggBucket(newNum, index)}
          min={0}
        />
      );
    } else {
      return '-';
    }
  };

  renderTieBreakerSelector = () => {
    const { sourceDataset } = this.props;
    const { instructions } = this.state;

    if (instructions.isAllColsSelected) {
      return;
    }

    return (
      <DedupTiebreakTable
        sortClauses={instructions.sortColumns}
        updateInstructions={this.updateSortClauseInstructions}
        cachedSchema={sourceDataset.cached_schema!}
      />
    );
  };

  setDedupColAggBucket = (agg: number, index: number) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.colsToDedup[index].aggBucket = agg;
      return { instructions };
    });
  };

  addColumnToDedup = () => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.colsToDedup.push({
        name: 'Select a Column',
        type: '',
      });
      return { instructions };
    });
  };

  removeColumnToDedup = (index: number) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.colsToDedup.splice(index, 1);
      return { instructions };
    });
  };

  setColumnToDedup = (index: number, name: string) => {
    const { sourceDataset } = this.props;
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.colsToDedup[index] = this.setAggBuckets(
        _.findWhere(sourceDataset.cached_schema!, { name: name })!,
      );
      return { instructions };
    });
  };

  setAllColsSelected = (isAllColsSelected: boolean) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.isAllColsSelected = isAllColsSelected;
      return { instructions };
    });
  };

  setAggBuckets = (aggregationColumnInfo: AggregationColumnInfo) => {
    if (NUMBER_TYPES.has(aggregationColumnInfo.type)) {
      aggregationColumnInfo.aggBucket = 1;
    } else if (DATE_TYPES.has(aggregationColumnInfo.type)) {
      aggregationColumnInfo.aggBucket = PIVOT_AGG_TYPES.DATE_DAY;
    }
    return aggregationColumnInfo;
  };

  onSubmit = (instructions: DedupOperationInstructions) => {
    const { onSubmit } = this.props;
    instructions.colsToDedup = _.filter(instructions.colsToDedup, (col) =>
      dedupClauseComplete(col),
    );
    instructions.sortColumns = _.filter(instructions.sortColumns, (sortClause) =>
      sortClauseComplete(sortClause),
    );
    onSubmit(instructions);
  };
}

export default withStyles(styles)(DedupOperationBody);

/** @format */

import { Action } from 'reducers/rootReducer';
import { Dataset, Operation } from 'actions/types';

export interface DatasetCacheReducerState {
  [datasetId: number]: Dataset | null;
}

const datasetCacheReducerInitialState: DatasetCacheReducerState = {};

export interface DatasetCacheReducerState {}

export default (
  state: DatasetCacheReducerState = datasetCacheReducerInitialState,
  action: Action,
): DatasetCacheReducerState => {
  const { payload } = action;
  const newState = Object.assign({}, state);

  switch (action.type) {
    case 'BULK_FETCH_DATASETS_REQUEST':
      payload.postData.dataset_ids.map((datasetId: number) => (newState[datasetId] = null));
      return newState;
    case 'BULK_FETCH_DATASETS_SUCCESS':
      payload.datasets.map((datasetData: Dataset) => (newState[datasetData.id] = datasetData));
      return newState;
    case 'FETCH_FLOW_DATA_SUCCESS':
      payload.operations.map((operation: Operation) => {
        if (!operation.results_dataset) return null;
        newState[operation.results_dataset.id] = operation.results_dataset;
        return null;
      });
      return newState;
    case 'COMPUTE_EXISTING_STEP_PREVIEW_SUCCESS':
      newState[payload.operation.results_dataset.id] = payload.operation.results_dataset;
      return newState;
    case 'FETCH_OPERATION_ROW_COUNT_SUCCESS':
      const newRowsDataset = newState[payload.dataset_id];
      if (newRowsDataset) {
        newRowsDataset.total_row_count = payload.row_count;
      }
      return newState;
    default:
      return newState;
  }
};

/** @format */

import React from 'react';
import _ from 'underscore';
import { withStyles, WithStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

import {
  Button,
  Menu,
  MenuItem,
  Popover,
  Intent,
  Callout,
  EditableText,
  Tag,
  Spinner,
} from '@blueprintjs/core';

import LineChartClauseBuilder from 'components/flowBuilder/lineChartClauseBuilder';
import PivotChartClauseBuilder from 'components/flowBuilder/pivotChartClauseBuilder';
import GroupedBarChartClauseBuilder from 'components/flowBuilder/groupedBarChartClauseBuilder';
import AxisPivotChartClauseBuilder from 'components/flowBuilder/axisPivotChartClauseBuilder';
import BarChartGraph from 'components/flowBuilder/graphs/barChartGraph';
import LineOrBarChart from 'components/flowBuilder/graphs/lineOrBarChart';
import { RouteComponentProps } from 'react-router';
import OperationFilterColumnSelect from 'components/dashboard/operationFIlterColumnSelect';

import { clearRecentlyAddedOpId } from 'actions/flowActions';

import { OPERATION_TYPES, OPERATIONS_BY_ID } from 'constants/flowConstants';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { ThunkDispatch } from 'redux-thunk';
import { Dataset } from 'actions/types';
import { Schema, TableData } from 'constants/types';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      height: 370,
    },
    tableBody: {
      paddingTop: 0,
    },
    tablePadding: {
      padding: theme.spacing(2),
    },
    tableFooter: {
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      backgroundColor: theme.palette.grey.light,
      display: 'flex',
      justifyContent: 'flex-start',
    },
    header: {
      padding: theme.spacing(3),
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    titleText: {
      fontWeight: 'bold',
      fontSize: 18,
      display: 'flex',
      alignItems: 'baseline',
      width: '100%',
    },
    dashboardHeader: {
      padding: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    dashboardTitleText: {
      fontWeight: 'bold',
      fontSize: 18,
      display: 'flex',
      alignItems: 'baseline',
      width: '90%',
    },
    upToDate: {
      padding: `0 ${theme.spacing(1)}px`,
      fontSize: 14,
      color: theme.palette.white,
      fontWeight: 600,
    },
    graphTypeSelector: {
      margin: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
    fillerText: {
      fontSize: 16,
      fontWeight: 400,
    },
    operationName: {
      marginRight: theme.spacing(2),
      whiteSpace: 'nowrap',
    },
    errorBody: {
      padding: theme.spacing(3),
    },
    loadingBody: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 200,
    },
  });

type PassedProps = {
  computeLoading?: boolean;
  resultsJobError?: any;
  operationId: number;
  operationType: string | number;
  operationInstructions?: any;
  lastStep?: boolean;
  numRows?: any;
  graphData?: TableData;
  resultSchema?: Schema;
  sourceDataset: Dataset;
  resultsDataId?: number;
  updateOperationInstructionAndCompute: (
    operationId: number,
    newInstructions: any,
    shouldCompute: boolean,
  ) => void;
  dataTableLoading?: boolean;
  dashboardView?: boolean;
  updateOperationTitle?: (newTitle: string) => void;
  removeOperationFromDashboard?: () => void;
  title?: string;
  flowId?: number;
  upToDate: boolean;
  viewOnly?: boolean;
  refreshDashboardPanel?: (operationId: number) => void;
  editFiltersOn?: boolean;
  editingFilterType?: string;
  editingFilterCol?: { name: string; type: string; sourceType: string };
  onFilterColumnSelect: (colName: string, colType: string, sourceType: string) => void;
};

type Props = PassedProps &
  WithStyles<typeof styles> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

class GraphBuilderBlock extends React.Component<Props> {
  shouldComponentUpdate = (nextProps: Props) => {
    const unimportantProps = [
      'updateOperationInstructionAndCompute',
      'updateOperationTitle',
      'removeOperationFromDashboard',
    ];
    if (!_.isEqual(_.omit(this.props, unimportantProps), _.omit(nextProps, unimportantProps))) {
      return true;
    }
    return false;
  };

  render() {
    const { classes, dashboardView } = this.props;

    if (dashboardView) {
      return this.renderDashboardView();
    }

    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.titleText}>
            {this.renderGraphTypeName()}
            {this.renderGraphInstructionsSelector()}
          </div>
        </div>
        {<div className={classes.tableBody}>{this.renderGraphBody()}</div>}
      </div>
    );
  }

  renderDashboardView = () => {
    const { classes, editFiltersOn } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.dashboardHeader}>
          <div className={classes.dashboardTitleText}>{this.renderEditableOperationTitle()}</div>
          {this.renderDashboardMenu()}
        </div>
        {!editFiltersOn && <div className={classes.tableBody}>{this.renderGraphBody()}</div>}
        {editFiltersOn && this.renderFilterColumnSelect()}
      </div>
    );
  };

  renderFilterColumnSelect = () => {
    const {
      operationId,
      resultSchema,
      editingFilterType,
      onFilterColumnSelect,
      editingFilterCol,
    } = this.props;
    if (!resultSchema) return;

    return (
      <OperationFilterColumnSelect
        operationId={operationId}
        resultSchema={resultSchema}
        editingFilterType={editingFilterType}
        editingFilterCol={editingFilterCol}
        onFilterColumnSelect={onFilterColumnSelect}
      />
    );
  };

  renderGraphTypeName = () => {
    const { classes, operationType } = this.props;
    return <div className={classes.operationName}>{OPERATIONS_BY_ID[operationType].name}</div>;
  };

  renderEditableOperationTitle = () => {
    const { updateOperationTitle, title, viewOnly } = this.props;
    if (!updateOperationTitle) {
      throw new Error(
        'Error: If using GraphBuilderBlock as a dashboard block, then updateOperationTitle must be passed in to the props',
      );
    }

    return (
      <EditableText
        placeholder="Edit Title..."
        selectAllOnFocus={true}
        onConfirm={(newTitle) => updateOperationTitle(newTitle)}
        defaultValue={title || ''}
        disabled={viewOnly}
      />
    );
  };

  renderGraphInstructionsSelector = () => {
    const {
      sourceDataset,
      computeLoading,
      operationType,
      operationInstructions,
      operationId,
      updateOperationInstructionAndCompute,
    } = this.props;

    const sharedProps = {
      clearRecentlyAddedOpId: clearRecentlyAddedOpId,
      computeLoading: computeLoading,
      instructions: operationInstructions,
      operationId: operationId,
      sourceDataset: sourceDataset,
      updateOperationInstructionAndCompute: updateOperationInstructionAndCompute,
    };

    if (operationType === OPERATION_TYPES.BAR_CHART.id) {
      return <PivotChartClauseBuilder {...sharedProps} />;
    } else if (operationType === OPERATION_TYPES.LINE_CHART.id) {
      return <LineChartClauseBuilder {...sharedProps} />;
    } else if (operationType === OPERATION_TYPES.GROUPED_BAR_CHART.id) {
      return <GroupedBarChartClauseBuilder {...sharedProps} />;
    } else if (operationType === OPERATION_TYPES.AXIS_PIVOT_CHART.id) {
      return <AxisPivotChartClauseBuilder {...sharedProps} />;
    }
  };

  renderGraphBody = () => {
    const { classes, graphData, upToDate } = this.props;

    return (
      <>
        <div className={classes.tablePadding}>{this.renderGraph()}</div>
        {/* don't show out of date if there is no graph data because the op hasn't been computed yet*/}
        {!graphData || upToDate ? null : (
          <div className={classes.tableFooter}>
            <Tag className={classes.upToDate} intent={Intent.NONE}>
              Out of Date
            </Tag>
          </div>
        )}
      </>
    );
  };

  renderGraph = () => {
    const {
      classes,
      graphData,
      resultSchema,
      operationType,
      operationInstructions,
      resultsJobError,
      computeLoading,
    } = this.props;

    if (resultsJobError || this.operationInErrorState()) {
      return (
        <div className={classes.errorBody}>
          <Callout
            intent={Intent.DANGER}
            icon="error"
            title="An error occured while computing the results">
            {/* {resultsJobError.message} */}
            Check that the columns being used have not changed. If this problem continues to exist,
            please reach out to support@explo.co.
          </Callout>
        </div>
      );
    }

    if (!graphData || !resultSchema) {
      return;
    }

    if (computeLoading) {
      return (
        <Spinner
          className={classes.loadingBody}
          intent={Intent.PRIMARY}
          size={Spinner.SIZE_STANDARD}
        />
      );
    }

    if (graphData.length === 0) {
      return (
        <div className={classes.errorBody}>
          <Callout intent={Intent.NONE} icon="error" title="There was no data to graph">
            {/* {resultsJobError.message} */}
            Check that the previous step is correctly configured and then rerun this operation.
          </Callout>
        </div>
      );
    }

    if (operationType === OPERATION_TYPES.BAR_CHART.id) {
      const aggedOnColumnName = operationInstructions.aggregation.aggedOnColumn
        ? operationInstructions.aggregation.aggedOnColumn.name ||
          operationInstructions.aggregation.aggedOnColumn
        : operationInstructions.aggregation.aggedOnColumn;
      const addType = operationInstructions.aggregation.type
        ? operationInstructions.aggregation.type.id || operationInstructions.aggregation.type
        : operationInstructions.aggregation.type;
      return (
        <BarChartGraph
          aggedOnColumn={aggedOnColumnName || ''}
          data={graphData}
          schema={resultSchema}
          type={addType || ''}
          xAxis={operationInstructions.xAxisColumn.name}
        />
      );
    } else if (operationType === OPERATION_TYPES.LINE_CHART.id) {
      return (
        <LineOrBarChart
          chartType={'line'}
          multiYAxis={operationInstructions.multiAxis}
          xAxis={operationInstructions.xAxisColumn.name}
          xAxisFormat={operationInstructions.xAxisFormat && operationInstructions.xAxisFormat.id}
          columns={operationInstructions.lineColumns}
          data={graphData}
          schema={resultSchema}
        />
      );
    } else if (operationType === OPERATION_TYPES.GROUPED_BAR_CHART.id) {
      return (
        <LineOrBarChart
          chartType={'bar'}
          multiYAxis={operationInstructions.multiAxis}
          xAxis={operationInstructions.xAxisColumn.name}
          xAxisFormat={operationInstructions.xAxisFormat && operationInstructions.xAxisFormat.id}
          columns={operationInstructions.lineColumns}
          data={graphData}
          schema={resultSchema}
        />
      );
    }
  };

  operationInErrorState = () => {
    const { graphData, operationInstructions } = this.props;
    return graphData && !operationInstructions.xAxisColumn;
  };

  renderDashboardMenu = () => {
    const { flowId, viewOnly, operationId, refreshDashboardPanel } = this.props;
    if (viewOnly) {
      return;
    }

    return (
      <Popover>
        <Button icon="more" />
        <Menu key="menu">
          <MenuItem
            icon="refresh"
            text="Refresh Chart"
            onClick={() => refreshDashboardPanel && refreshDashboardPanel(operationId)}
          />
          <MenuItem
            icon="data-lineage"
            text="View Exploration"
            onClick={() => {
              this.props.history.push(`/flows/${flowId}`);
            }}
          />
          <MenuItem
            icon="delete"
            text="Remove from Dashboard"
            onClick={this.props.removeOperationFromDashboard}
          />
        </Menu>
      </Popover>
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  clearRecentlyAddedOpId: () => dispatch(clearRecentlyAddedOpId()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GraphBuilderBlock)),
);

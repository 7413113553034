/** @format */

import React from 'react';
import cx from 'classnames';
import { withStyles, WithStyles } from '@material-ui/styles';

import { Button, MenuItem } from '@blueprintjs/core';
import { connect } from 'react-redux';
import EditOperationDrawer from 'components/flowBuilder/editOperationDrawer/editOperationDrawer';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { updateOperationFlyoutState } from 'actions/flowActions';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'baseline',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    clauseContainer: {
      alignItems: 'baseline',
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    editClauseButton: {
      marginRight: theme.spacing(2),
    },
  });

type PassedProps = {
  computeLoading?: boolean;
  editDrawerOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
  editOperationDrawerTitle: string;
  tooltipSuggestionIsOpen: boolean;
  editOperationBody: JSX.Element;
  clauseText: JSX.Element | JSX.Element[] | null;
  summaryView?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles> & typeof mapDispatchToProps;

class ClauseBuilder extends React.Component<Props> {
  componentDidMount = () => {
    const { updateOperationFlyoutState, editDrawerOpen } = this.props;
    updateOperationFlyoutState(editDrawerOpen);
  };

  componentDidUpdate(prevProps: Props) {
    const { updateOperationFlyoutState, editDrawerOpen } = this.props;
    if (prevProps.editDrawerOpen !== editDrawerOpen) {
      updateOperationFlyoutState(editDrawerOpen);
    }
  }

  render() {
    const { classes, clauseText, summaryView } = this.props;

    if (summaryView) {
      return this.renderEditButton();
    }

    return (
      <div className={classes.root}>
        <div className={classes.clauseContainer}>{clauseText}</div>
        {this.renderEditButton()}
      </div>
    );
  }

  renderEditButton = () => {
    const {
      classes,
      summaryView,
      computeLoading,
      editOperationBody,
      editOperationDrawerTitle,
      editDrawerOpen,
      openDrawer,
      closeDrawer,
    } = this.props;

    return (
      <>
        {summaryView ? (
          <MenuItem label="⌘..." text="Edit" shouldDismissPopover={false} onClick={openDrawer} />
        ) : (
          <Button
            fill={summaryView}
            className={cx(classes.editClauseButton)}
            disabled={computeLoading}
            loading={computeLoading}
            icon="edit"
            onClick={openDrawer}
            text={summaryView ? 'Edit' : undefined}
          />
        )}
        <EditOperationDrawer
          isOpen={editDrawerOpen}
          onClose={closeDrawer}
          title={editOperationDrawerTitle}
          editOperationBody={editOperationBody}
        />
      </>
    );
  };
}

const mapDispatchToProps = {
  updateOperationFlyoutState,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(ClauseBuilder));

/** @format */

import _ from 'underscore';
import { COLOR_LIST } from 'constants/colorConstants';

export const chartConfigIsSame = (oldConfig: any, newConfig: any) => {
  return (
    _.isEqual(oldConfig.data.labels, newConfig.data.labels) &&
    oldConfig.data.datasets.length === newConfig.data.datasets.length &&
    _.isEqual(
      _.map(oldConfig.data.datasets, (d: any) => d.data),
      _.map(newConfig.data.datasets, (d: any) => d.data),
    ) &&
    _.isEqual(
      _.map(oldConfig.data.datasets, (d: any) => d.label),
      _.map(newConfig.data.datasets, (d: any) => d.label),
    ) &&
    _.isEqual(
      _.map(oldConfig.data.datasets, (d: any) => d.backgroundColor),
      _.map(newConfig.data.datasets, (d: any) => d.backgroundColor),
    ) &&
    _.isEqual(
      _.map(oldConfig.data.datasets, (d: any) => d.borderColor),
      _.map(newConfig.data.datasets, (d: any) => d.borderColor),
    ) &&
    _.isEqual(
      _.map(oldConfig.data.datasets, (d: any) => d.yAxisID),
      _.map(newConfig.data.datasets, (d: any) => d.yAxisID),
    ) &&
    _.isEqual(
      _.map(oldConfig.options.scales.yAxes, (d: any) => d.id),
      _.map(newConfig.options.scales.yAxes, (d: any) => d.id),
    )
  );
};

// TODO: type the instructions once the line/bar chart is typed
export const getSelectedLineColor = (instructions: any, lineIndex: number) => {
  const { multiAxis, lineColumns } = instructions;

  if (!multiAxis) {
    return lineColumns[lineIndex].color || COLOR_LIST[lineIndex % COLOR_LIST.length].primary;
  }

  const axisId = lineColumns[lineIndex].yAxis || 'left-axis';
  const axisRepresentativeLineIndex = indexOffirstLineOfAxis(lineColumns, axisId);
  return lineColumns[axisRepresentativeLineIndex].color;
};

export const indexOffirstLineOfAxis = (lines: any, axisId: string) => {
  return _.findIndex(lines, (line: any) =>
    axisId === 'left-axis'
      ? line.yAxis === undefined || line.yAxis === axisId
      : line.yAxis === axisId,
  );
};

export const colorLuminance = (hex: string, lum: number) => {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  var rgb = '#',
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }
  return rgb;
};

export const isMultiAxis = (instructions: any) => {
  if (!instructions.multiAxis) {
    return false;
  }

  const { lineColumns } = instructions;
  const axisTypes = new Set();

  lineColumns.map((column: any) => {
    if (column.yAxis === undefined) {
      axisTypes.add('left-axis');
    } else {
      axisTypes.add(column.yAxis);
    }
    return null;
  });

  return axisTypes.size >= 2;
};

/** @format */

import React from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { withStyles, WithStyles } from '@material-ui/styles';
import FileUploadPanel from 'components/fileSystem/fileUploadPanel';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { updateSetupFlowStepKey } from 'actions/onboardingActions';
import { SetupFlowStepKey, STEP_KEY_TO_DATA_SELECTION_PATH } from 'constants/setupPageConstants';
import { CSV_UPLOADS_DS_NAME } from 'constants/flowConstants';
import { fetchDataSourceList, switchSelectedDataSourceId } from 'actions/dataSourceActions';
import _ from 'underscore';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '70vh',
      width: 'calc(100vw - 400px)',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: 5,
      borderColor: theme.palette.grey.border,
      display: 'flex',
    },
  });

type Props = WithStyles<typeof styles> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

class SetupFlowFileUploadPanel extends React.Component<Props> {
  componentDidMount = () => {
    const { fetchDataSourceList } = this.props;
    fetchDataSourceList();
  };

  render() {
    const { classes, file, currentUser } = this.props;
    return (
      <div className={classes.root}>
        <FileUploadPanel
          file={file!}
          onCancel={this.onCancel}
          onSave={this.onSave}
          currentFolderId={currentUser.root_folder_id!}
          createNewDatasetSuccess={() => {}}
          datasetUploadComplete={() => {}}
          setupFlowStyling
        />
      </div>
    );
  }

  onCancel = () => {
    const { updateSetupFlowStepKey } = this.props;
    updateSetupFlowStepKey({
      postData: {
        updated_setup_flow_key: SetupFlowStepKey.CSV_UPLOAD,
      },
    });
  };

  onSave = () => {
    const { updateSetupFlowStepKey, dataSourceList, switchSelectedDataSourceId } = this.props;
    const csvUploadDataSource = _.findWhere(dataSourceList, { name: CSV_UPLOADS_DS_NAME });
    switchSelectedDataSourceId(csvUploadDataSource!.id);
    updateSetupFlowStepKey({
      postData: {
        updated_setup_flow_key: SetupFlowStepKey.CSV_PATH_ENTER_EXPLO,
        data_selection_path: STEP_KEY_TO_DATA_SELECTION_PATH[SetupFlowStepKey.CSV_PATH_ENTER_EXPLO],
      },
    });
  };
}

const mapStateToProps = (state: ReduxState) => ({
  file: state.onboardingData.selectedCSVFile,
  currentUser: state.currentUser,
  dataSourceList: state.dataSourceList,
});

const mapDispatchToProps = {
  updateSetupFlowStepKey,
  fetchDataSourceList,
  switchSelectedDataSourceId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(SetupFlowFileUploadPanel));

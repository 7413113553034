/** @format */

import React from 'react';
import _ from 'underscore';
import cx from 'classnames';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import {
  H3,
  Button,
  InputGroup,
  TextArea,
  Popover,
  Position,
  Icon,
  PopoverInteractionKind,
  FormGroup,
} from '@blueprintjs/core';
import EditDrawerDataPreview from 'components/flowBuilder/editOperationDrawer/editDrawerDataPreview';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { FormulaOperationInstructions, FormulaOperationInstructionItem } from 'constants/types';
import {
  FLYOUT_DATA_PREVIEW_HEADER,
  SOURCE_TYPE_TO_FORMULA_HELP_LINK,
} from 'constants/flowConstants';
import { Dataset } from 'actions/types';
import CancelClauseButton from 'components/flowBuilder/editOperationDrawer/cancelClauseButton';
import {
  getFormulaOperationRowErrorInformation,
  getFormulaOperationFooterErrorInformation,
} from 'utils/operationErrorInfoUtils/formulaOperationErrorInfoUtil';
import OperationDrawerFooter from './operationDrawerFooter';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    body: {
      height: 'calc(100% - 54px)',
      overflowY: 'auto',
    },
    sectionTitle: {
      margin: theme.spacing(6),
    },
    selectorsContainer: {
      boxShadow: theme.customShadows.basic,
      borderRadius: 4,
      backgroundColor: theme.palette.white,
      margin: `0 ${theme.spacing(6)}px`,
    },
    formulaInput: {
      resize: 'none',
      width: '100%',
    },
    addClauseButtonContainer: {
      padding: theme.spacing(6),
      paddingTop: theme.spacing(2),
    },
    clauseTable: {
      width: '100%',
    },
    helpTooltip: {
      marginLeft: theme.spacing(2),
    },
    helpTooltipPopover: {
      width: 250,
    },
    helpContent: {
      padding: theme.spacing(4),
      borderRadius: 8,
    },
    formGroupValidationErrorState: {
      marginBottom: 0,
    },
    formGroupValidationNoError: {
      marginBottom: 20,
    },
  });

type PassedProps = {
  instructions: FormulaOperationInstructions;
  operationId: number;
  sourceDataset: Dataset;
  onSubmit: (newInstructions: FormulaOperationInstructions) => void;
};

type Props = ReturnType<typeof mapStateToProps> & PassedProps & WithStyles<typeof styles>;

type State = {
  instructions: FormulaOperationInstructions;
};

class FormulaOperationBody extends React.Component<Props, State> {
  readonly state: State = {
    instructions: this.props.instructions,
  };

  render() {
    const { classes, onSubmit, sourceDataset } = this.props;
    const { instructions } = this.state;
    const { footerErrorState, footerErrorText } = getFormulaOperationFooterErrorInformation(
      instructions,
    );

    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <EditDrawerDataPreview
            sourceDataset={sourceDataset}
            dataPreviewHeader={FLYOUT_DATA_PREVIEW_HEADER}
          />
          <H3 className={classes.sectionTitle}>New Columns</H3>
          <div className={classes.selectorsContainer}>
            <table className={cx(classes.clauseTable, 'bp3-html-table')}>
              <thead>
                <tr>
                  <th></th>
                  <th>Column Name</th>
                  <th>
                    Formula
                    <Popover
                      interactionKind={PopoverInteractionKind.HOVER}
                      className={classes.helpTooltip}
                      popoverClassName={classes.helpTooltipPopover}
                      position={Position.TOP}
                      content={this.renderHelpContent()}>
                      <Icon icon="help" />
                    </Popover>
                  </th>
                </tr>
              </thead>
              <tbody>{instructions.map(this.renderFormulaColSelector)}</tbody>
            </table>

            <div className={classes.addClauseButtonContainer}>
              <Button icon="add" onClick={this.addNewColumn}>
                Add New Column
              </Button>
            </div>
          </div>
        </div>
        <OperationDrawerFooter
          errorState={footerErrorState}
          errorText={footerErrorText}
          onSubmit={() => onSubmit(instructions)}
        />
      </div>
    );
  }

  renderHelpContent = () => {
    const { classes } = this.props;
    return (
      <div className={classes.helpContent}>
        Type custom formulas using SQL syntax{' '}
        <a href={this.getFormulaHelpLink()} target="_blank" rel="noopener noreferrer">
          here
        </a>
        . Please reference the documentation here for functions and operations.
      </div>
    );
  };

  renderFormulaColSelector = (
    formulaInstructions: FormulaOperationInstructionItem,
    index: number,
  ) => {
    const { classes, operationId } = this.props;

    const {
      colNameErrorText,
      colNameInputIntent,
      formulaErrorText,
      formulaInputIntent,
    } = getFormulaOperationRowErrorInformation(formulaInstructions, index);

    return (
      <tr key={`formula_col_selector_${operationId}_${index}`}>
        <CancelClauseButton index={index} onClick={() => this.removeFormulaColumn(index)} />
        <td>
          <FormGroup
            className={
              colNameErrorText
                ? classes.formGroupValidationErrorState
                : classes.formGroupValidationNoError
            }
            helperText={colNameErrorText}
            intent={colNameInputIntent}
            labelFor="text-input">
            <InputGroup
              intent={colNameInputIntent}
              placeholder="Column Name"
              value={formulaInstructions.newColName}
              onChange={(e: any) => {
                const instructions = this.copyInstructions();
                instructions[index].newColName = e.target.value;
                this.setState({ instructions });
              }}
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup
            className={
              formulaErrorText
                ? classes.formGroupValidationErrorState
                : classes.formGroupValidationNoError
            }
            helperText={formulaErrorText}
            intent={formulaInputIntent}
            labelFor="text-input">
            <TextArea
              intent={formulaInputIntent}
              className={classes.formulaInput}
              placeholder="Enter Formula"
              growVertically={true}
              onChange={(e) => {
                const instructions = this.copyInstructions();
                instructions[index].colFormula = e.target.value;
                this.setState({ instructions });
              }}
              value={formulaInstructions.colFormula || ''}
            />
          </FormGroup>
        </td>
      </tr>
    );
  };

  copyInstructions = () => {
    return this.state.instructions.map((formulaCol) => {
      return Object.assign({}, formulaCol);
    });
  };

  addNewColumn = () => {
    const instructions = this.copyInstructions();

    instructions.push({
      newColName: '',
      colFormula: null,
    });

    this.setState({ instructions });
  };

  removeFormulaColumn = (index: number) => {
    const instructions = this.copyInstructions();
    instructions.splice(index, 1);
    this.setState({ instructions });
  };

  getFormulaHelpLink = () => {
    const sourceType = this.getCurrentDataSourceType();

    //@ts-ignore
    return SOURCE_TYPE_TO_FORMULA_HELP_LINK[sourceType];
  };

  getCurrentDataSourceType = () => {
    const { selectedDataSourceId, dataSourceList } = this.props;

    const selectedDS = _.find(dataSourceList, (ds) => ds.id === selectedDataSourceId);
    return selectedDS!.source_type;
  };
}

const mapStateToProps = (state: ReduxState) => ({
  selectedDataSourceId: state.workspaceData.selectedDataSourceId,
  dataSourceList: state.dataSourceList,
});

export default connect(mapStateToProps, {})(withStyles(styles)(FormulaOperationBody));

/** @format */

import React from 'react';
import _ from 'underscore';
import cloneDeep from 'lodash/cloneDeep';

import { H3, Button, Intent, NumericInput } from '@blueprintjs/core';
import EditDrawerDataPreview from 'components/flowBuilder/editOperationDrawer/editDrawerDataPreview';
import OperationDropdownInput from 'components/flowBuilder/operationDropdownInput';
import { createStyles } from '@material-ui/core/styles';
import {
  DATE_TYPES,
  DATE_PIVOT_AGGS,
  NUMBER_TYPES,
  AGGREGATIONS_LIST,
  NON_NUMBER_AGGREGATIONS_LIST,
  FLYOUT_DATA_PREVIEW_HEADER,
} from 'constants/flowConstants';
import {
  AxisPivotChartInstructions,
  PivotOperationAggregation,
  DataType,
  AggregationColumnInfo,
  SelectedDropdownInputItem,
} from 'constants/types';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Dataset } from 'actions/types';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    body: {
      height: 'calc(100% - 54px)',
      overflowY: 'auto',
    },
    clauseTable: {
      width: '100%',
      backgroundColor: theme.palette.white,
      borderRadius: '4px',
      boxShadow: theme.customShadows.basic,
    },
    drawerFooter: {
      padding: `${theme.spacing(3)}px ${theme.spacing(6)}px`,
      display: 'flex',
      justifyContent: 'flex-end',
      borderTop: `1px solid ${theme.palette.grey.border}`,
      backgroundColor: theme.palette.white,
    },
    sectionTitleWithInput: {
      whiteSpace: 'nowrap',
      marginRight: theme.spacing(10),
    },
    sectionTitleWithInputContainer: {
      margin: theme.spacing(6),
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    sectionTitle: {
      margin: theme.spacing(6),
    },
    cancelClauseCol: {
      width: 50,
    },
    menuList: {
      maxHeight: 200,
      overflow: 'auto',
    },
    columnSelector: {},
    columnSelectorInput: {
      fontSize: 14,
    },
    tagInputColSelector: {
      minWidth: 200,
    },
    selectedColsContainer: {
      margin: `0 ${theme.spacing(6)}px`,
    },
    noColsSelected: {
      height: 200,
      marginBottom: theme.spacing(10),
    },
    pivotedColTableName: {
      fontSize: 16,
      height: 30,
      display: 'flex',
      alignItems: 'center',
    },
    pivotColNameCol: {
      width: 224,
    },
    pivotedColNoAggText: {
      fontSize: 18,
      height: 30,
      display: 'flex',
      alignItems: 'center',
    },
    bucketSizeIntegerInput: {
      width: 150,
    },
    helpTooltip: {
      marginLeft: theme.spacing(2),
    },
    helpTooltipPopover: {
      width: 250,
    },
    aggregationSelectorContainer: {
      margin: `0 ${theme.spacing(6)}px`,
      marginBottom: theme.spacing(6),
      backgroundColor: theme.palette.white,
      borderRadius: '4px',
      boxShadow: theme.customShadows.basic,
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(5),
    },
    aggregationSelectorTable: {
      width: '100%',
    },
    addAggregationBtnContainer: {
      padding: theme.spacing(3),
    },
    selectionDescription: {
      fontWeight: 'bold',
      fontSize: 16,
      marginRight: theme.spacing(3),
    },
  });

type PassedProps = {
  instructions: AxisPivotChartInstructions;
  operationId: number;
  sourceDataset: Dataset;
  onSubmit: (newInstructions: AxisPivotChartInstructions) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  instructions: AxisPivotChartInstructions;
};

class AxisPivotChartEditOperationBody extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      instructions: cloneDeep(props.instructions),
    };
  }

  render() {
    const { classes, sourceDataset } = this.props;
    const { instructions } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <EditDrawerDataPreview
            sourceDataset={sourceDataset}
            dataPreviewHeader={FLYOUT_DATA_PREVIEW_HEADER}
          />
          <H3 className={classes.sectionTitle}>Rows</H3>
          {this.renderPivotColumnRow(instructions.rowColumn, this.updateRowCol)}

          <H3 className={classes.sectionTitle}>Columns</H3>
          {this.renderPivotColumnRow(instructions.colColumn, this.updateColCol, true)}

          <H3 className={classes.sectionTitle}>Values</H3>
          {this.renderAggregationSelector(instructions.aggregation)}
        </div>
        <div className={classes.drawerFooter}>
          <Button intent={Intent.PRIMARY} onClick={this.onSubmit}>
            Submit
          </Button>
        </div>
      </div>
    );
  }

  onSubmit = () => {
    const { onSubmit } = this.props;
    const { instructions } = this.state;
    onSubmit(instructions);
  };

  renderPivotColumnRow = (
    selectedCol: AggregationColumnInfo | null,
    setColumn: (col: AggregationColumnInfo) => void,
    preventAggs?: boolean,
  ) => {
    const { classes, sourceDataset } = this.props;

    return (
      <div className={classes.aggregationSelectorContainer}>
        <div className={classes.selectionDescription}>Column: </div>
        <div className={classes.pivotColNameCol}>
          <OperationDropdownInput
            selectedItem={
              selectedCol && selectedCol.type ? { ...selectedCol, id: selectedCol.name } : undefined
            }
            onChange={(item) => {
              setColumn(
                _.findWhere(sourceDataset.cached_schema!, {
                  name: item.name,
                })!,
              );
            }}
            noSelectionText="Select a Column"
            options={sourceDataset.cached_schema!.map((col) => ({ ...col, id: col.name }))}
          />
        </div>
        {!preventAggs && (
          <>
            <div className={classes.selectionDescription}>Bucket: </div>
            <div className={classes.pivotColNameCol}>
              {this.renderAggSelector(selectedCol, setColumn)}
            </div>
          </>
        )}
      </div>
    );
  };

  renderAggregationSelector = (aggregation: PivotOperationAggregation) => {
    const { classes, sourceDataset } = this.props;

    return (
      <div className={classes.aggregationSelectorContainer}>
        <div className={classes.selectionDescription}>Column: </div>
        <div className={classes.pivotColNameCol}>
          <OperationDropdownInput
            selectedItem={
              aggregation.aggedOnColumn
                ? ({
                    ...aggregation.aggedOnColumn,
                    id: aggregation.aggedOnColumn.name,
                  } as SelectedDropdownInputItem)
                : undefined
            }
            onChange={(item) => {
              this.setState((prevState) => {
                const { instructions } = prevState;
                if (
                  instructions.aggregation.aggedOnColumn &&
                  instructions.aggregation.aggedOnColumn.type !== item.type
                ) {
                  instructions.aggregation.type = null;
                }
                instructions.aggregation.aggedOnColumn = item;
                return { instructions };
              });
            }}
            noSelectionText="Select a Column"
            options={sourceDataset.cached_schema!.map((col) => ({ ...col, id: col.name }))}
          />
        </div>
        <div className={classes.selectionDescription}>Aggregation: </div>
        <div className={classes.pivotColNameCol}>
          <OperationDropdownInput
            disabled={aggregation.aggedOnColumn === null}
            selectedItem={aggregation.type as SelectedDropdownInputItem}
            onChange={(item) => {
              this.setState((prevState) => {
                const { instructions } = prevState;
                instructions.aggregation.type = item;
                return { instructions };
              });
            }}
            noSelectionText="Select an Aggregation"
            options={
              this.getAggListForColType(
                aggregation.aggedOnColumn && (aggregation.aggedOnColumn.type as DataType),
              ) as SelectedDropdownInputItem[]
            }
          />
        </div>
      </div>
    );
  };

  getAggListForColType = (colType: DataType | null) => {
    if (!colType) {
      return [];
    }
    if (NUMBER_TYPES.has(colType)) {
      return AGGREGATIONS_LIST;
    } else {
      return NON_NUMBER_AGGREGATIONS_LIST;
    }
  };

  renderAggSelector = (
    colData: AggregationColumnInfo | null,
    setColumn: (col: AggregationColumnInfo) => void,
  ) => {
    const { classes } = this.props;

    if (!colData) {
      return '-';
    } else if (DATE_TYPES.has(colData.type)) {
      return (
        <OperationDropdownInput
          selectedItem={colData.aggBucket as SelectedDropdownInputItem}
          onChange={(item) => {
            colData.aggBucket = item;
            setColumn(colData);
          }}
          noSelectionText="Select Date Group"
          options={DATE_PIVOT_AGGS}
        />
      );
    } else if (NUMBER_TYPES.has(colData.type)) {
      return (
        <NumericInput
          className={classes.bucketSizeIntegerInput}
          placeholder={'1, 10, 100...'}
          value={(colData.aggBucket as number) || 0}
          onValueChange={(item) => {
            colData.aggBucket = item;
            setColumn(colData);
          }}
          min={0}
        />
      );
    } else {
      return '-';
    }
  };

  updateRowCol = (col: AggregationColumnInfo) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.rowColumn = col;
      return { instructions };
    });
  };

  updateColCol = (col: AggregationColumnInfo) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.colColumn = col;
      return { instructions };
    });
  };
}

export default withStyles(styles)(AxisPivotChartEditOperationBody);

/** @format */

import _ from 'underscore';
import { Schema } from 'constants/types';
import { DATE_TYPES, NUMBER_TYPES, STRING } from 'constants/flowConstants';
import { DASHBOARD_FILTER_TYPES_BY_ID } from 'constants/dashboardConstants';

export const filterDashboardFilterColsForType = (schema: Schema, filterType: string) => {
  if (filterType === DASHBOARD_FILTER_TYPES_BY_ID.DATE.id) {
    return _.filter(schema, (col) => DATE_TYPES.has(col.type));
  } else if (filterType === DASHBOARD_FILTER_TYPES_BY_ID.VALUES.id) {
    return _.filter(schema, (col) => col.type === STRING);
  } else if (filterType === DASHBOARD_FILTER_TYPES_BY_ID.NUMBERS.id) {
    return _.filter(schema, (col) => NUMBER_TYPES.has(col.type));
  }
  return schema;
};

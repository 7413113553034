/** @format */
import React, { Dispatch, SetStateAction } from 'react';
import { Operation } from 'actions/types';
import { AppToaster } from 'toaster';
import { Intent, TextArea, Button, Dialog, Classes } from '@blueprintjs/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import CopyToClipboard from 'react-copy-to-clipboard';
import sqlFormatter from 'sql-formatter';

const useStyles = makeStyles((theme: Theme) => ({
  shareSqlTextCopy: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  shareSqlInput: {
    marginRight: theme.spacing(2),
    height: 150,
  },
}));

interface PassedProps {
  operation: Operation;
  generateSqlModalOpen: boolean;
  setGenerateSqlModalOpen: Dispatch<SetStateAction<boolean>>;
}

type Props = PassedProps & RouteComponentProps;

const renderGenerateSqlText = (classes: ReturnType<typeof useStyles>, props: Props) => {
  const { operation } = props;
  return (
    <div>
      <div className={classes.shareSqlTextCopy}>
        <TextArea
          className={classes.shareSqlInput}
          value={
            operation.results_dataset &&
            sqlFormatter.format(operation.results_dataset.cached_query!)
          }
          fill
        />{' '}
        <CopyToClipboard
          text={
            operation.results_dataset &&
            sqlFormatter.format(operation.results_dataset.cached_query!)
          }
          onCopy={() =>
            AppToaster.show({
              icon: 'clipboard',
              intent: Intent.PRIMARY,
              message: 'Generated SQL copied to clipboard',
              timeout: 5000,
            })
          }>
          <Button icon="duplicate" minimal />
        </CopyToClipboard>
      </div>
    </div>
  );
};
const GenerateSQLModal = (props: Props) => {
  const classes = useStyles();
  const { generateSqlModalOpen, setGenerateSqlModalOpen } = props;
  if (!generateSqlModalOpen) return <></>;

  return (
    <Dialog
      isOpen={generateSqlModalOpen}
      onClose={() => setGenerateSqlModalOpen(false)}
      title="Generated SQL Query">
      <div className={Classes.DIALOG_BODY}>{renderGenerateSqlText(classes, props)}</div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={() => setGenerateSqlModalOpen(false)}>Done</Button>
        </div>
      </div>
    </Dialog>
  );
};

export default withRouter(GenerateSQLModal);

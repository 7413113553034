/** @format */

import { Dataset } from 'actions/types';
import { Dictionary } from 'underscore';

export interface JoinType {
  id: string;
  name: string;
  icon: string;
}

export interface JoinTypes {
  [joinType: string]: JoinType;
}

export interface ColumnInfo {
  name: string;
  friendly_name?: string;
  type: string;
}

export type DataType = string;

export type SelectableDataTypes = DataType[];

export type Schema = ColumnInfo[];

export interface FilterOperatorType {
  id: string;
  name: string;
  selectionValue: string;
  supported_column_types: Set<string>;
}

export interface FilterOperatorTypes {
  [FilterOperatorName: string]: FilterOperatorType;
}

export interface FilterOperatorsById {
  [filterOperatorType: string]: FilterOperatorType;
}

export interface OperationType {
  id: string;
  name: string;
  icon?: string;
  disabled?: boolean;
  help_center_link?: string;
}

export interface OperationTypes {
  [OperationName: string]: OperationType;
}

export interface AggregationType {
  id: string;
  name: string;
  selectionValue: string;
}

export interface AggregationTypes {
  [AggregationName: string]: AggregationType;
}
export interface OperationsByIdType {
  [operationType: string]: OperationType;
}

export interface FilterClause {
  filterColumn: any;
  filterOperation: any;
  filterValue: any;
}

export interface FilterOperationInstructions {
  matchOnAll: boolean;
  filterClauses: FilterClause[];
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SortClause {
  column: ColumnInfo | null;
  order: SortOrder | null;
}

export interface SortOperationInstructions {
  sortColumns: SortClause[];
}

export interface TableMetaInfo {
  flowId: number;
  operationId: number;
  datasetId: number;
  name: string;
}

export type TableRow = any;

export type TableData = TableRow[];

export interface EnrichOperationInstructions {
  joinTable: TableMetaInfo;
  joinType: string;
  columnsToJoinOn: ColumnToJoinOn[];
}

export interface ColumnToJoinOn {
  joinColumn: string;
  sourceColumn: string;
}

export interface ColumnToUnionOn {
  resultColumnName?: string;
  sourceColumn?: string | null;
  unionColumn?: string | null;
}

export interface UnionOperationInstructions {
  unionTable: TableMetaInfo;
  columnsToUnionOn: ColumnToUnionOn[];
  unionType: string;
  unionColumnMatchType: string;
}

export interface FormulaOperationInstructionItem {
  newColName: string;
  colFormula: any;
}

export interface SchemaChange {
  col: string;
  newType?: DataType | null;
  safeCast: boolean;
  newColName?: string | null;
  keepCol: boolean;
  subitem?: boolean;
  format?: any;
}

export interface ChangeSchemaOperationInstructions {
  changeSchemaList: SchemaChange[];
}

export type FormulaOperationInstructions = FormulaOperationInstructionItem[];

export type AggregationBucket = number | SelectedDropdownInputItem;

export interface AggregationColumnInfo extends ColumnInfo {
  aggBucket?: AggregationBucket;
}

export interface AdvancedColumnInfo extends ColumnInfo {
  friendly_name: string;
  id: string;
}

export type PivotOperationInstructions = {
  pivotedOnCols: AggregationColumnInfo[];
  aggregations: PivotOperationAggregation[];
};

export type PivotOperationAggregation = {
  type: AggregationType | null;
  aggedOnColumn: AdvancedColumnInfo | null;
  additionalCol?: ColumnInfo;
};

export type AxisPivotChartInstructions = {
  rowColumn: AggregationColumnInfo | null;
  colColumn: AggregationColumnInfo | null;
  aggregation: PivotOperationAggregation;
};

export type PivotOperationInstructionsDTO = {
  pivotedOnCols: Dictionary<AggregationColumnInfo>;
  aggregations: PivotOperationAggregation[];
};

export type DedupOperationInstructions = {
  colsToDedup: AggregationColumnInfo[];
  sortColumns: SortClause[];
  isAllColsSelected: boolean;
};

export type OperationInstructions =
  | FilterOperationInstructions
  | EnrichOperationInstructions
  | UnionOperationInstructions
  | FormulaOperationInstructions
  | ChangeSchemaOperationInstructions
  | PivotOperationInstructions
  | PivotOperationInstructionsDTO
  | DedupOperationInstructions
  | SortOperationInstructions
  | AxisPivotChartInstructions
  | any;

export interface BaseOperationInstructionByType {
  [operationId: string]: () => OperationInstructions;
}

export interface DatasetData {
  cached_schema: Schema;
  cached_preview: TableData;
}

export type DatasetsData = DatasetData[];

export interface SelectedDropdownInputItem {
  name: string;
  id: string;
  icon?: any;
}

export interface DelimiterType {
  id: string;
  name: string;
  value: string | null;
}

export interface DelimiterTypes {
  [delimiterType: string]: DelimiterType;
}

export interface TimeStampItem {
  format: string;
  name: string;
  parentType: string;
}

export interface SchemaDataType {
  name: string;
  icon: any;
  subitems?: TimeStampItem[];
}

export interface SchemaDataTypesById {
  [schemaDataTypeName: string]: SchemaDataType;
}

export interface MenuOption {
  name: string;
  onClick: () => void;
  icon: string;
}

export interface DatasetTabMap {
  name: string;
  data: Dataset[];
}

// will use Unions (|) to expand this in the future
export type OperationInputTableRow = SortClause;
export type OperationDropdownInputType = ColumnInfo;

export interface DatabaseConfig {
  name: string;
  type: string;
  configuration_schema: any;
}

/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import BaseDataTable from 'components/dataTable/baseDataTable';
import LoadingDataTable from 'components/dataTable/loadingDataTable';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Dataset } from 'actions/types';

const useStyles = makeStyles((theme: Theme) => ({
  sourcePreviewContainer: {
    margin: theme.spacing(6),
    boxShadow: theme.customShadows.basic,
    borderRadius: 4,
    overflow: 'hidden',
  },
  sourcePreviewHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    fontWeight: 'bold',
    fontSize: 18,
    backgroundColor: theme.palette.white,
  },
  sourcePreviewFooter: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    height: 34,
    backgroundColor: theme.palette.grey.light,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  rowCount: {
    padding: `0 ${theme.spacing(1)}px`,
    fontSize: 14,
    color: theme.palette.black,
    fontWeight: 300,
    display: 'flex',
    alignItems: 'center',
  },
}));

type Props = {
  dataPreviewHeader: string;
  sourceDataset: Dataset;
  selectedColumns?: any;
};

const EditDrawerDataPreview = (props: Props) => {
  const classes = useStyles();
  const { sourceDataset, selectedColumns, dataPreviewHeader } = props;
  const DataTable = !sourceDataset.cached_preview ? LoadingDataTable : BaseDataTable;

  return (
    <div className={classes.sourcePreviewContainer}>
      <div className={classes.sourcePreviewHeader}>{dataPreviewHeader}</div>
      <DataTable
        headerList={sourceDataset.cached_schema!!}
        loading={!sourceDataset.cached_preview}
        maxRows={30}
        rows={sourceDataset.cached_preview || []}
        selectedColumns={selectedColumns}
      />
      <div className={classes.sourcePreviewFooter}>
        <div className={classes.rowCount}>
          {sourceDataset.total_row_count && sourceDataset.total_row_count.toLocaleString()} total
          rows
        </div>
      </div>
    </div>
  );
};

export default EditDrawerDataPreview;

/** @format */

import { Intent } from '@blueprintjs/core';
import { FormulaOperationInstructionItem, FormulaOperationInstructions } from 'constants/types';
import _ from 'underscore';
import { OperationFooterErrorInformation } from 'utils/operationErrorInfoUtils/generalOperationErrorInfoUtil';

enum ROW_ERROR_TEXT {
  EMPTY_COLUMN_NAME = 'Please give the column a name',
  COLUMN_NAME_BEGINS_WITH_NUMBER = 'Please use a column name that does not begin with a number',
  EMPTY_FORMULA = 'Please give the column a formula',
}

enum FOOTER_ERROR_TEXT {
  NO_ERROR = 'No Error',
  FILL_ROWS = 'Please fill in the incomplete rows to submit',
}

export interface FormulaOperationRowErrorInformation {
  colNameErrorText: string | null;
  colNameInputIntent: Intent;
  formulaErrorText: string | null;
  formulaInputIntent: Intent;
}

export const getFormulaOperationRowErrorInformation = (
  instructionItem: FormulaOperationInstructionItem,
  index: number,
): FormulaOperationRowErrorInformation => {
  let rowErrorInformation: FormulaOperationRowErrorInformation = {
    colNameErrorText: null,
    colNameInputIntent: Intent.NONE,
    formulaErrorText: null,
    formulaInputIntent: Intent.NONE,
  };

  // If the row is empty, but its not the first row - that should not throw an error
  if (instructionItem.newColName === '' && !instructionItem.colFormula) {
    if (index === 0) {
      rowErrorInformation.colNameErrorText = ROW_ERROR_TEXT.EMPTY_COLUMN_NAME;
      rowErrorInformation.colNameInputIntent = Intent.DANGER;
      rowErrorInformation.formulaErrorText = ROW_ERROR_TEXT.EMPTY_FORMULA;
      rowErrorInformation.formulaInputIntent = Intent.DANGER;
    }
    return rowErrorInformation;
  }

  if (instructionItem.newColName === '' && instructionItem.colFormula) {
    rowErrorInformation.colNameErrorText = ROW_ERROR_TEXT.EMPTY_COLUMN_NAME;
    rowErrorInformation.colNameInputIntent = Intent.DANGER;
  }

  if (!isNaN(parseInt(instructionItem.newColName.charAt(0)))) {
    rowErrorInformation.colNameErrorText = ROW_ERROR_TEXT.COLUMN_NAME_BEGINS_WITH_NUMBER;
    rowErrorInformation.colNameInputIntent = Intent.DANGER;
  }

  if (instructionItem.newColName !== '' && !instructionItem.colFormula) {
    rowErrorInformation.formulaErrorText = ROW_ERROR_TEXT.EMPTY_FORMULA;
    rowErrorInformation.formulaInputIntent = Intent.DANGER;
  }

  return rowErrorInformation;
};

export const getFormulaOperationFooterErrorInformation = (
  instructions: FormulaOperationInstructions,
): OperationFooterErrorInformation => {
  const operationFooterErrorInformation: OperationFooterErrorInformation = {
    footerErrorState: false,
    footerErrorText: FOOTER_ERROR_TEXT.NO_ERROR,
  };
  // if I find an incomplete row, then the instructions are incomplete
  const isInstructionsIncomplete = !!_.find(instructions, (formulaInstructions, index) => {
    const { colNameErrorText, formulaErrorText } = getFormulaOperationRowErrorInformation(
      formulaInstructions,
      index,
    );
    return !!colNameErrorText || formulaErrorText;
  });
  if (isInstructionsIncomplete) {
    operationFooterErrorInformation.footerErrorState = true;
    operationFooterErrorInformation.footerErrorText = FOOTER_ERROR_TEXT.FILL_ROWS;
  }
  return operationFooterErrorInformation;
};

/** @format */

import React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { H4, Icon } from '@blueprintjs/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.white,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.grey.border}`,
      borderStyle: 'dashed',

      '&:hover': {
        color: theme.palette.black,
        backgroundColor: theme.palette.grey.light,
        textDecoration: 'none',
      },

      '&:active': {
        backgroundColor: theme.palette.grey.middle,
      },
    },
    mainText: {
      color: theme.palette.grey.backgroundGrey,
      textAlign: 'center',
      marginBottom: theme.spacing(10),
      padding: `0 ${theme.spacing(4)}px`,
    },
    icon: {
      marginTop: theme.spacing(12),
      marginBottom: theme.spacing(10),
    },
  });

type PassedProps = {
  openNewFlowModal: () => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

class CreateFlowPanel extends React.PureComponent<Props> {
  render() {
    const { classes, openNewFlowModal } = this.props;

    return (
      <div className={classes.root} onClick={openNewFlowModal}>
        <Icon icon="plus" className={classes.icon} color="#5C7080" iconSize={48} />
        <H4 className={classes.mainText}>Start a New Exploration</H4>
      </div>
    );
  }
}

export default withStyles(styles)(CreateFlowPanel);

/** @format */

import React, { ReactNode, useEffect } from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { AppToaster } from 'toaster';
import { Intent } from '@blueprintjs/core';

import StarParallaxBg from 'components/marketing/starParallaxBg';
import NavHeader from 'components/marketing/navHeader';
import SignupHero from 'components/marketing/signupHero';
import Footer from 'components/marketing/footer';

import { pageView } from 'analytics/exploAnalytics';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.black100,
    height: '100vh',
    padding: `0 ${theme.spacing(7)}px`,
    display: 'flex',
    justifyContent: 'center',
    fontFamily: "'Inter', sans-serif",
    perspective: '1px',
    overflowY: 'auto',
  },
  container: {
    width: '100%',
    maxWidth: 1040,
    zIndex: 2,
    transform: 'translateZ(0)',
  },
  demoVideoContainer: {
    width: '100%',
    marginTop: 200,
    marginBottom: 60,
    position: 'relative',

    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
      marginBottom: 20,
    },
  },
  heroImg: {
    width: '100%',
  },
  productFeatureSection: {
    marginTop: 100,

    [theme.breakpoints.down('xs')]: {
      marginTop: 50,
    },
  },
  prepareDataSection: {
    position: 'absolute',
    right: 0,
    top: '55.5%',
    width: '45%',
  },
  analyzeDataSection: {
    position: 'absolute',
    right: 0,
    top: '69.5%',
    width: '45%',
  },
  visualizeDataSection: {
    position: 'absolute',
    right: 0,
    top: '86.5%',
    width: '45%',
  },
  integrationSourcesSection: {
    marginTop: 160,

    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
    },
  },
  featureListSection: {
    marginTop: 160,

    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
    },
  },
  faqSection: {
    marginTop: 160,

    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
    },
  },
  signupFooter: {
    marginTop: 160,

    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
    },
  },
  footer: {
    marginTop: 120,
    marginBottom: 40,
  },
}));

type Props = {
  className?: string;
  children: ReactNode;
};

const EmbeddoHomePage = (props: Props) => {
  const classes = useStyles();

  useEffect(() => {
    pageView('Embeddo Landing Page');
  });

  return (
    <div className={classes.root}>
      <StarParallaxBg />

      <div className={classes.container}>
        <NavHeader />
        <SignupHero
          caption="Limited Beta Release"
          title1="Embed customer analytics, "
          title2="lightning fast"
          description="Explo enables you to embed analytics, like dashboards and reports, directly into your product. Save months of engineering time and give your users a beautifully designed interface to view and explore their data."
          onSignupSuccess={renderSuccessSignUpToast}
          onSignupFail={renderFailedSignUpToast}
        />
        <Footer className={classes.footer} />
      </div>
    </div>
  );
};

const renderSuccessSignUpToast = (email: string): void => {
  AppToaster.show({
    message: `Thanks for signing up, ${email}.  We'll be in touch shortly!`,
    icon: 'tick-circle',
    timeout: 5000,
    intent: Intent.SUCCESS,
  });
};

const renderFailedSignUpToast = (msg: string): void => {
  AppToaster.show({
    message: msg,
    icon: 'issue',
    timeout: 5000,
    intent: Intent.DANGER,
  });
};

export default EmbeddoHomePage;

/** @format */

import React from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

import SectionTitle from 'explo-ds/typography/marketing/sectionTitle';
import IntegrationDescription from 'explo-ds/typography/marketing/integrationDescription';

import BigQuery from 'images/marketing/integration_logos/bigquery.png';
import Snowflake from 'images/marketing/integration_logos/snowflake.png';
import Redshift from 'images/marketing/integration_logos/redshift.png';
import DBT from 'images/marketing/integration_logos/dbt.png';
import Airflow from 'images/marketing/integration_logos/airflow.png';

import Slack from 'images/marketing/integration_logos/slack.png';
import Gmail from 'images/marketing/integration_logos/gmail.png';
import PagerDuty from 'images/marketing/integration_logos/pager_duty.png';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  title1: {
    color: theme.palette.white,
    fontWeight: 'bold',
  },
  title2: {
    color: theme.palette.white,
  },
  integrationRow: {
    display: 'grid',
    gridTemplateColumns: '0.14fr 0.14fr 0.14fr 0.14fr 0.14fr 0.3fr',
    gridColumnGap: theme.spacing(6),
    marginTop: 45,
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '0.25fr 0.25fr 0.25fr 0.25fr',
      gridRowGap: theme.spacing(6),
    },
  },
  integrationImg: {
    width: '75%',
  },
  integrationDescription: {
    color: theme.palette.grey100,
  },
  integrationDescriptionContainer: {
    [theme.breakpoints.down('xs')]: {
      gridColumnStart: 1,
      gridColumnEnd: 5,
      textAlign: 'center',
    },
  },
}));

type Props = {
  className?: string;
};

const IntegrateAlertingSection = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.root, props.className)}>
      <div>
        <SectionTitle className={classes.title1}>Easily Integrate </SectionTitle>
        <SectionTitle className={classes.title2}>into any data stack</SectionTitle>
      </div>
      <div className={classes.integrationRow}>
        <img className={classes.integrationImg} src={Airflow} alt="airflow logo" />
        <img className={classes.integrationImg} src={DBT} alt="mongDBTo logo" />
        <img className={classes.integrationImg} src={Snowflake} alt="snowflake logo" />
        <img className={classes.integrationImg} src={BigQuery} alt="big query logo" />
        <img className={classes.integrationImg} src={Redshift} alt="redshift logo" />
        <div className={classes.integrationDescriptionContainer}>
          <IntegrationDescription className={classes.integrationDescription}>
            Seamlessly plug in to your <b>data pipelines</b>
          </IntegrationDescription>
        </div>
      </div>
      <div className={classes.integrationRow}>
        <img className={classes.integrationImg} src={Slack} alt="Slack logo" />
        <img className={classes.integrationImg} src={Gmail} alt="GMail logo" />
        <img className={classes.integrationImg} src={PagerDuty} alt="Pager duty  logo" />
        <div></div>
        <div></div>
        <div className={classes.integrationDescriptionContainer}>
          <IntegrationDescription className={classes.integrationDescription}>
            Alert your team <b>wherever they work</b>
          </IntegrationDescription>
        </div>
      </div>
    </div>
  );
};

export default IntegrateAlertingSection;

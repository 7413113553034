/** @format */

import React from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    left: 0,
  },
  layer1: {
    transform: 'translateZ(-1px) scale(2) translateX(-500px)',
  },
  layer2: {
    transform: 'translateZ(-3px) scale(4) translateX(-700px) translateY(-225px)',
  },
  layer3: {
    transform: 'translateZ(-0.5px) scale(1.5) translateX(-470px)',
  },
}));

type Props = {};

const StarParallaxBg = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.layer1}>
        <div id="stars" />
      </div>
      <div className={classes.layer2}>
        <div id="stars2" />
      </div>
      <div className={classes.layer3}>
        <div id="stars3" />
      </div>
    </div>
  );
};

export default StarParallaxBg;

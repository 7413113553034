/** @format */

import React from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import DotsDropdown from 'components/flowGraph/dotsDropdown';
import { H6, MenuItem, Spinner } from '@blueprintjs/core';

const useStyles = makeStyles((theme: Theme) => ({
  dataSourceItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.white,
    borderRadius: 8,
    padding: `${theme.spacing(4)}px 0px`,
    marginBottom: theme.spacing(4),
    height: 50,
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  itemHeading: {
    marginLeft: theme.spacing(4),
    marginBottom: 0,
    marginRight: theme.spacing(2),
  },
  actionsMenu: {
    marginRight: theme.spacing(2),
  },
  secondText: {
    position: 'absolute',
    left: '30vw',
  },
  thirdText: {
    position: 'absolute',
    left: '60vw',
  },
  spinner: {
    marginRight: theme.spacing(3),
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

interface Props {
  title: string;
  subTitle?: string;
  secondText?: string;
  thirdText: string;
  loading?: boolean;
  itemActions: React.ComponentProps<typeof MenuItem>[];
  onClick: () => void;
  disableActionsMenu?: boolean;
}

const PageListItem = (props: Props) => {
  const classes = useStyles();
  const {
    title,
    secondText,
    thirdText,
    itemActions,
    loading,
    onClick,
    disableActionsMenu,
    subTitle,
  } = props;

  return (
    <div onClick={onClick} className={classes.dataSourceItem}>
      <div className={classes.titleContainer}>
        <H6 className={classes.itemHeading}>{title}</H6>
        <div>{subTitle}</div>
      </div>
      <div className={classes.secondText}>{secondText}</div>
      <div className={classes.thirdText}>{thirdText}</div>
      {!disableActionsMenu &&
        (loading ? (
          <Spinner className={classes.spinner} size={20} />
        ) : (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <DotsDropdown nodeActions={itemActions} className={classes.actionsMenu} />
          </div>
        ))}
    </div>
  );
};

export default PageListItem;

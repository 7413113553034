/** @format */
import React from 'react';

import { Slider } from '@blueprintjs/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

const SLIDER_INITIAL_VALUE = 0;
const SLIDER_STEP_SIZE = 1;

const useStyles = makeStyles((theme: Theme) => ({
  slider: {
    position: 'absolute' as 'absolute',
    bottom: 50,
    left: 60,
    width: 150,
  },
}));

interface Props {
  scaleIndex: number;
  scaleList: number[];
  onChange: (scaleIndex: number) => void;
}

const GraphCanvasSlider = (props: Props) => {
  const { scaleIndex, onChange, scaleList } = props;
  const classes = useStyles();

  if (scaleList[0] === scaleList[1] && scaleList[1] === scaleList[2]) return <></>;
  const sliderMaxValue = scaleList[1] === scaleList[2] ? 1 : 2;
  return (
    <Slider
      className={classes.slider}
      initialValue={SLIDER_INITIAL_VALUE}
      stepSize={SLIDER_STEP_SIZE}
      max={sliderMaxValue}
      value={scaleIndex}
      onChange={onChange}
    />
  );
};

export default GraphCanvasSlider;

/** @format */

import React from 'react';
import _ from 'underscore';
import { withStyles } from '@material-ui/styles';

import { Tooltip, Position, Classes, Tag } from '@blueprintjs/core';

import { SCHEMA_DATA_TYPES_BY_ID } from 'constants/flowConstants.tsx';

const styles = (theme) => ({
  schemaColumns: {
    overflow: 'auto',
    height: 'calc(100% - 34px - 55px)',
  },
  schemaColumnEditor: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.grey.light}`,
    alignItems: 'center',
  },
  editorNamePopover: {
    width: '50%',
    paddingRight: theme.spacing(2),
    overflow: 'hidden',
  },
  editorName: {},
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  formatInput: {
    width: 130,
    marginTop: theme.spacing(2),
  },
});

class SchemaColumnEditor extends React.PureComponent {
  constructor(props) {
    super(props);

    const formatDataByColName = {};
    _.each(
      props.colNameList,
      (colName) => (formatDataByColName[colName] = props.colByName[colName].data_format),
    );

    this.state = {
      formatDataByColName: formatDataByColName,
    };
  }

  render() {
    const { classes, colNameList, colByName, loading } = this.props;

    if (loading) {
      return this.renderSchemaEditorColumnsLoading();
    }

    return (
      <div className={classes.schemaColumns}>
        {colNameList.map((colName) => {
          const col = colByName[colName];
          return (
            <div className={classes.schemaColumnEditor} key={`column-editor-${col.name}`}>
              <Tooltip
                content={col.name}
                className={classes.editorNamePopover}
                position={Position.TOP_LEFT}>
                <div className={classes.editorName}>{col.name}</div>
              </Tooltip>
              <div className={classes.inputContainer}>{this.renderColumnTypeSelector(col)}</div>
            </div>
          );
        })}
      </div>
    );
  }

  renderColumnTypeSelector = (col) => {
    return (
      <>
        <Tag icon={SCHEMA_DATA_TYPES_BY_ID[col.type].icon} large={true} minimal={true}>
          {SCHEMA_DATA_TYPES_BY_ID[col.type].name}
        </Tag>
      </>
    );
  };

  renderSchemaEditorColumnsLoading = () => {
    const { classes } = this.props;

    return (
      <div className={classes.schemaColumns}>
        <div className={classes.schemaColumnEditor}>
          <div className={Classes.SKELETON}>LoadingLoadingLoadingLoading</div>
        </div>
        <div className={classes.schemaColumnEditor}>
          <div className={Classes.SKELETON}>LoadingLoadingLoadingLoading</div>
        </div>
        <div className={classes.schemaColumnEditor}>
          <div className={Classes.SKELETON}>LoadingLoadingLoadingLoading</div>
        </div>
        <div className={classes.schemaColumnEditor}>
          <div className={Classes.SKELETON}>LoadingLoadingLoadingLoading</div>
        </div>
        <div className={classes.schemaColumnEditor}>
          <div className={Classes.SKELETON}>LoadingLoadingLoadingLoading</div>
        </div>
        <div className={classes.schemaColumnEditor}>
          <div className={Classes.SKELETON}>LoadingLoadingLoadingLoading</div>
        </div>
        <div className={classes.schemaColumnEditor}>
          <div className={Classes.SKELETON}>LoadingLoadingLoadingLoading</div>
        </div>
        <div className={classes.schemaColumnEditor}>
          <div className={Classes.SKELETON}>LoadingLoadingLoadingLoading</div>
        </div>
        <div className={classes.schemaColumnEditor}>
          <div className={Classes.SKELETON}>LoadingLoadingLoadingLoading</div>
        </div>
        <div className={classes.schemaColumnEditor}>
          <div className={Classes.SKELETON}>LoadingLoadingLoadingLoading</div>
        </div>
        <div className={classes.schemaColumnEditor}>
          <div className={Classes.SKELETON}>LoadingLoadingLoadingLoading</div>
        </div>
        <div className={classes.schemaColumnEditor}>
          <div className={Classes.SKELETON}>LoadingLoadingLoadingLoading</div>
        </div>
        <div className={classes.schemaColumnEditor}>
          <div className={Classes.SKELETON}>LoadingLoadingLoadingLoading</div>
        </div>
        <div className={classes.schemaColumnEditor}>
          <div className={Classes.SKELETON}>LoadingLoadingLoadingLoading</div>
        </div>
        <div className={classes.schemaColumnEditor}>
          <div className={Classes.SKELETON}>LoadingLoadingLoadingLoading</div>
        </div>
        <div className={classes.schemaColumnEditor}>
          <div className={Classes.SKELETON}>LoadingLoadingLoadingLoading</div>
        </div>
        <div className={classes.schemaColumnEditor}>
          <div className={Classes.SKELETON}>LoadingLoadingLoadingLoading</div>
        </div>
      </div>
    );
  };
}

export default withStyles(styles)(SchemaColumnEditor);

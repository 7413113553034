/** @format */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { NonIdealState } from '@blueprintjs/core';

import ExploLogo from 'images/explo_logo.png';

import { pageView } from 'analytics/exploAnalytics';

const styles = (theme: Theme) => ({
  root: {
    minHeight: '100vh',
    width: '100%',
    backgroundColor: theme.palette.primary.veryLight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  expirationPanel: {
    backgroundColor: theme.palette.white,
    width: '60vw',
    maxWidth: 600,
    border: `1px solid ${theme.palette.grey.border}`,
    borderRadius: 4,
    padding: `${theme.spacing(12)}px ${theme.spacing(6)}px`,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
  },
  exploLogo: {
    width: 200,
    marginBottom: theme.spacing(10),
  },
});

type MatchParams = {
  flowId: string;
};

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<MatchParams> &
  WithStyles<typeof styles>;

class NewFlowPage extends React.Component<Props> {
  componentDidMount() {
    pageView('Trial Expired Page');
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.expirationPanel}>
          <img src={ExploLogo} className={classes.exploLogo} alt="logo for explo" />
          <NonIdealState
            icon="error"
            title="Your trial has expired"
            description="Thanks for trying Explo! Your trial period has expired. In order to continue using Explo, you will have to move to a paid plan. Please contact us at support@explo.co to sign up for a plan."
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NewFlowPage)),
);

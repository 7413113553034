/** @format */

import React from 'react';
import cx from 'classnames';
import { withStyles, WithStyles } from '@material-ui/styles';

import { Intent, Callout, Button } from '@blueprintjs/core';

import BaseDataTable from 'components/dataTable/baseDataTable';
import BarChartGraph from 'components/flowBuilder/graphs/barChartGraph';
import LineOrBarChart from 'components/flowBuilder/graphs/lineOrBarChart';

import { OPERATION_TYPES } from 'constants/flowConstants';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { Schema } from 'constants/types';

import { MAX_ROWS_TO_PREVIEW, GRAPH_OPERATIONS_SET } from 'constants/flowConstants';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
      borderRadius: 4,
      border: `1px solid ${theme.palette.grey.border}`,
      position: 'relative',
      width: '100%',
    },
    tableBody: {
      paddingTop: 0,
    },
    tablePadding: {
      padding: theme.spacing(2),
    },
    footer: {
      padding: theme.spacing(2),
      position: 'relative' as 'relative',
    },
    footerButton: {
      position: 'absolute' as 'absolute',
      bottom: 10,
      right: 10,
    },
    titleText: {
      fontWeight: 'bold',
      fontSize: 18,
      display: 'flex',
      alignItems: 'baseline',
      width: '100%',
    },
    dashboardHeader: {
      padding: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    dashboardTitleText: {
      fontWeight: 'bold',
      fontSize: 18,
      display: 'flex',
      alignItems: 'baseline',
      width: '90%',
    },
    errorBody: {
      padding: theme.spacing(3),
    },
    tableFooterLeft: {
      display: 'flex',
      flexDirection: 'row',
    },
    rowCount: {
      padding: `0 ${theme.spacing(1)}px`,
      fontSize: 14,
      color: theme.palette.black,
      fontWeight: 300,
    },
    tableFooter: {
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      backgroundColor: theme.palette.grey.light,
      display: 'flex',
      justifyContent: 'space-between',
    },
  });

type PassedProps = {
  className?: string;
  resultsJobError?: any;
  operationType: string | number;
  operationInstructions?: any;
  operationOrder?: number;
  numRows?: any;
  resultsData?: any;
  resultSchema?: Schema;
  resultsDataId?: number;
  title?: string;
  flowId?: number;
};

type Props = PassedProps & WithStyles<typeof styles>;

class ViewOnlyOperation extends React.Component<Props> {
  render() {
    const { classes, className } = this.props;

    return (
      <div className={cx(classes.root, className)}>
        <div className={classes.tableBody}>{this.renderOperationBody()}</div>
        {this.renderOperationFooter()}
      </div>
    );
  }

  renderOperationFooter = () => {
    const { classes, flowId } = this.props;

    if (window.screen.width < 750) {
      return;
    }

    return (
      <div className={classes.footer}>
        <Button
          icon="data-lineage"
          className={classes.footerButton}
          onClick={() => window.open(`/public-flows/${flowId}`)}>
          View Analysis
        </Button>
      </div>
    );
  };

  renderOperationBody = () => {
    const { classes, operationType } = this.props;

    if (GRAPH_OPERATIONS_SET.has(operationType as string)) {
      return <div className={classes.tablePadding}>{this.renderGraph()}</div>;
    } else {
      return this.renderOperationPreviewTable();
    }
  };

  renderGraph = () => {
    const {
      classes,
      resultsData,
      resultSchema,
      operationType,
      operationInstructions,
      resultsJobError,
    } = this.props;

    if (resultsJobError || this.operationInErrorState()) {
      return (
        <div className={classes.errorBody}>
          <Callout
            intent={Intent.DANGER}
            icon="error"
            title="An error occured while computing the results">
            {/* {resultsJobError.message} */}
            Check that the columns being used have not changed. If this problem continues to exist,
            please reach out to support@explo.co.
          </Callout>
        </div>
      );
    }

    if (!resultsData || !resultSchema) {
      return;
    }

    if (resultsData.length === 0) {
      return (
        <div className={classes.errorBody}>
          <Callout intent={Intent.NONE} icon="error" title="There was no data to graph">
            {/* {resultsJobError.message} */}
            Check that the previous step is correctly configured and then rerun this operation.
          </Callout>
        </div>
      );
    }

    if (operationType === OPERATION_TYPES.BAR_CHART.id) {
      const aggedOnColumnName = operationInstructions.aggregation.aggedOnColumn
        ? operationInstructions.aggregation.aggedOnColumn.name ||
          operationInstructions.aggregation.aggedOnColumn
        : operationInstructions.aggregation.aggedOnColumn;
      const addType = operationInstructions.aggregation.type
        ? operationInstructions.aggregation.type.id || operationInstructions.aggregation.type
        : operationInstructions.aggregation.type;
      return (
        <BarChartGraph
          aggedOnColumn={aggedOnColumnName || ''}
          data={resultsData}
          schema={resultSchema}
          type={addType || ''}
          xAxis={operationInstructions.xAxisColumn.name}
        />
      );
    } else if (operationType === OPERATION_TYPES.LINE_CHART.id) {
      return (
        <LineOrBarChart
          chartType="line"
          multiYAxis={operationInstructions.multiAxis}
          xAxis={operationInstructions.xAxisColumn.name}
          xAxisFormat={operationInstructions.xAxisFormat && operationInstructions.xAxisFormat.id}
          columns={operationInstructions.lineColumns}
          data={resultsData}
          schema={resultSchema}
        />
      );
    } else if (operationType === OPERATION_TYPES.GROUPED_BAR_CHART.id) {
      return (
        <LineOrBarChart
          chartType="bar"
          multiYAxis={operationInstructions.multiAxis}
          xAxis={operationInstructions.xAxisColumn.name}
          xAxisFormat={operationInstructions.xAxisFormat && operationInstructions.xAxisFormat.id}
          columns={operationInstructions.lineColumns}
          data={resultsData}
          schema={resultSchema}
        />
      );
    }
  };

  renderOperationPreviewTable = () => {
    const { classes, resultsData, resultSchema, numRows, resultsJobError } = this.props;

    if (resultsJobError) {
      return (
        <div className={classes.errorBody}>
          <Callout
            intent={Intent.DANGER}
            icon="error"
            title="An error occured while computing the results">
            {/* {resultsJobError.message} */}
            Check that the columns being used have not changed. If this problem continues to exist,
            please reach out to support@explo.co.
          </Callout>
        </div>
      );
    }

    if (resultsData && resultSchema) {
      return (
        <>
          <div>
            <BaseDataTable rows={resultsData || []} headerList={resultSchema} maxRows={30} />
          </div>
          <div className={classes.tableFooter}>
            <div className={classes.tableFooterLeft}></div>
            <div className={classes.rowCount}>
              {numRows && numRows.toLocaleString()} total rows{' '}
              {numRows > MAX_ROWS_TO_PREVIEW ? ` (previewing ${MAX_ROWS_TO_PREVIEW} rows)` : ''}
            </div>
          </div>
        </>
      );
    }
  };

  operationInErrorState = () => {
    const { resultsData, operationInstructions } = this.props;

    return resultsData && !operationInstructions.xAxisColumn;
  };
}

export default withStyles(styles)(ViewOnlyOperation);

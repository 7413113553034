/** @format */

import React from 'react';
import _ from 'underscore';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';

import StepBlockTag from 'components/flowBuilder/stepBlockTag';

import { filterClauseComplete } from 'utils/flowUtils';
import { FILTER_OPS_NO_VALUE } from 'constants/flowConstants';

import { InstructionComponentProps } from 'components/flowGraph/operationInstructionDescription';
import { FilterOperationInstructions, FilterClause } from 'constants/types';

const styles = (theme: Theme) =>
  createStyles({
    fillerText: {
      fontSize: 16,
      fontWeight: 400,
      margin: `0 ${theme.spacing(2)}px`,
    },
  });

type PassedProps = InstructionComponentProps<FilterOperationInstructions>;

type Props = PassedProps & WithStyles<typeof styles>;

class FilterOperationInstructionDescription extends React.Component<Props> {
  render() {
    const { classes, operationId, instructions } = this.props;

    const completeClauses = instructions.filterClauses.filter(filterClauseComplete);
    const clauses: any[] = [];

    _.each(completeClauses, (clause: FilterClause, index: number) => {
      let value = clause.filterValue;
      if (typeof clause.filterValue === 'object') {
        value = clause.filterValue.startDate;
        value += clause.filterValue.endDate ? ' - ' + clause.filterValue.endDate : '';
      }
      clauses.push(
        <StepBlockTag
          key={`clause_${index}_${operationId}_col_name`}
          content={`${clause.filterColumn.name}`}
        />,
      );

      clauses.push(
        <div className={classes.fillerText} key={`clause_${index}_${operationId}_op_type`}>
          {clause.filterOperation.name}
        </div>,
      );

      if (!FILTER_OPS_NO_VALUE.has(clause.filterOperation.id)) {
        clauses.push(
          <StepBlockTag key={`clause_${index}_${operationId}_value`} content={`${value}`} />,
        );
      }

      if (index !== completeClauses.length - 1) {
        const conjunction = instructions.matchOnAll ? 'and' : 'or';

        clauses.push(
          <div className={classes.fillerText} key={`clause_${index}_${operationId}_and`}>
            {conjunction}
          </div>,
        );
      }
    });

    return clauses.length > 1 ? <>{clauses}</> : <></>;
  }
}

export default withStyles(styles)(FilterOperationInstructionDescription);

/** @format */
import React, { useContext } from 'react';

import { Dialog } from '@blueprintjs/core';
import { ActionFn } from 'actions/actionUtils';
import { Theme, makeStyles } from '@material-ui/core/index';
import { FlowReducerState } from 'reducers/flowReducer';
import AddStepBlock from 'pages/flowGraphPage/addStepBlock';
import { SelectedNode } from './flowGraphPageV3';
import { BASE_OP_INSTRUCTION_BY_TYPE } from 'constants/flowConstants';
import { trackEvent } from 'analytics/exploAnalytics';
import { FlowGraphApi } from 'pages/flowGraphPage/flowGraphPageContext';

const useStyles = makeStyles((theme: Theme) => ({
  addStepModal: {
    maxWidth: 1100,
    width: '80%',
    padding: 0,
  },
}));

type Props = {
  addStepModalOpen: boolean;
  flowId: string;
  addFlowStep: ActionFn;
  selectedNode?: SelectedNode;
  flowData: FlowReducerState;
};

const createFlowStep = (
  selectedOperationType: string,
  flowId: string,
  addFlowStep: ActionFn,
  selectedNode: SelectedNode,
  flowData: FlowReducerState,
) => {
  if (!flowData.flowInfo) {
    throw new Error(`flowInfo undefined: ${flowData}`);
  }

  addFlowStep({
    id: flowId,
    postData: {
      parent_node_type: selectedNode.type,
      parent_node_id: selectedNode.id,
      operation_type: selectedOperationType,
      instructions: BASE_OP_INSTRUCTION_BY_TYPE[selectedOperationType](),
    },
  });
  trackEvent('Add flow step', {
    flow_id: flowId,
    operation_type: selectedOperationType,
  });
};

const FlowGraphAddStepModal = (props: Props) => {
  const { addStepModalOpen, flowId, addFlowStep, selectedNode, flowData } = props;
  const classes = useStyles();
  const { closeAddStepModal } = useContext(FlowGraphApi)!;
  if (!addStepModalOpen) return <></>;

  return (
    <Dialog className={classes.addStepModal} isOpen={addStepModalOpen} onClose={closeAddStepModal}>
      <AddStepBlock
        closeAddStep={closeAddStepModal}
        addFlowStep={(opType: string) =>
          createFlowStep(opType, flowId, addFlowStep, selectedNode!, flowData)
        }
      />
    </Dialog>
  );
};

export default FlowGraphAddStepModal;

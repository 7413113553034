/** @format */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';

import LeftNav from 'components/pages/leftNav';

import {
  fetchDataSourceList,
  fetchWorkspaceData,
  switchSelectedDataSourceId,
} from 'actions/dataSourceActions';
import { fetchDashboardList } from 'actions/dashboardActions';
import { fetchUserTeam } from 'actions/teamActions';
import { ACTION } from 'actions/types';
import { createLoadingSelector } from 'reducers/api/selectors';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
    },
    body: {
      backgroundColor: theme.palette.primary.veryLight,
      height: '100vh',
      width: 'calc(100vw - 250px)',
      overflow: 'hidden',
    },
  });

type PassedProps = {};

type Props = PassedProps &
  WithStyles<typeof styles> &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps;

class PageWithLeftNav extends Component<Props> {
  componentDidMount() {
    if (PageWithLeftNav.shouldShowPage(this.props.location.pathname)) {
      this.props.fetchDataSourceList({}, (response) => {
        if (this.shouldntInitializeWorkspace()) {
          return;
        }
        const firstDatasource = response.dataSources[0];
        const currentDataSourceId =
          this.getDataSourceIdFromUrl() === undefined
            ? firstDatasource.id
            : this.getDataSourceIdFromUrl();

        if (currentDataSourceId) {
          this.props.switchSelectedDataSourceId(Number.parseInt(currentDataSourceId));
          this.props.fetchWorkspaceData({ id: currentDataSourceId });
        }
      });
      this.props.fetchDashboardList();
      this.props.fetchUserTeam();
    }
  }

  static shouldShowPage = (urlPath: string) => {
    if (urlPath === '/') {
      return false;
    }
    const unAuthedPageUrls = [
      'about-us',
      'login',
      'signup',
      'check-your-email',
      'verify-email',
      'covid',
      'public-flows',
      'ecommerce',
      'join-team',
      'setup',
    ];

    for (let i = 0; i < unAuthedPageUrls.length; i++) {
      if (urlPath.indexOf(unAuthedPageUrls[i]) >= 0) {
        return false;
      }
    }

    return true;
  };

  getDataSourceIdFromUrl = () => {
    const url = this.props.location.pathname;

    if (url.startsWith('/datasources')) {
      return url.split('/')[2];
    } else if (url === '/add-data-source') {
      return null;
    }
  };

  shouldntInitializeWorkspace = () => {
    const path = this.props.location.pathname;
    return path.indexOf('flows') >= 0 || path.indexOf('dashboards') >= 0;
  };

  render() {
    const {
      classes,
      dataSourceList,
      workspaceData,
      dashboardData,
      dashboardDataListLoading,
    } = this.props;

    if (!PageWithLeftNav.shouldShowPage(this.props.location.pathname)) {
      return <></>;
    }

    return (
      <div className={classes.root}>
        <LeftNav
          dataSourceList={dataSourceList}
          dashboardsLoading={dashboardDataListLoading}
          dashboardsList={dashboardData.dashboardList}
          selectedDataSourceId={workspaceData.selectedDataSourceId}
          selectedDashBoardId={dashboardData.selectedDashBoardId}
          switchWorkspaces={this.switchWorkspaces}
          workspaceData={workspaceData.data}
        />
        <div className={classes.body}>{this.props.children}</div>
      </div>
    );
  }

  switchWorkspaces = (dataSourceId: number) => {
    this.props.switchSelectedDataSourceId(dataSourceId);
    if (dataSourceId) {
      this.props.fetchWorkspaceData({ id: dataSourceId });
    }
  };
}

const dashboardDataListLoadingSelector = createLoadingSelector([ACTION.FETCH_DASHBOARD_LIST]);
const mapStateToProps = (state: ReduxState) => ({
  dashboardDataListLoading: dashboardDataListLoadingSelector(state),
  dataSourceList: state.dataSourceList,
  workspaceData: state.workspaceData,
  dashboardData: state.dashboardData,
});

const mapDispatchToProps = {
  fetchDataSourceList,
  fetchWorkspaceData,
  fetchDashboardList,
  switchSelectedDataSourceId,
  fetchUserTeam,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PageWithLeftNav)),
);

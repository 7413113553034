/** @format */

import React, { ReactNode } from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: 18,
    lineHeight: '22px',
  },
}));

type Props = {
  className?: string;
  children: ReactNode;
};

const ProductSectionDescription = (props: Props) => {
  const classes = useStyles();
  return <span className={cx(props.className, classes.root)}>{props.children}</span>;
};

export default ProductSectionDescription;

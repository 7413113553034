/** @format */

import React from 'react';
import _ from 'underscore';
import ClauseBuilder from 'components/flowBuilder/clauseBuilder';
import FilterEditOperationBody from 'components/flowBuilder/editOperationDrawer/filterEditOperationBody';
import StepBlockTag from 'components/flowBuilder/stepBlockTag';

import { filterClauseComplete, filterInstructionsChanged } from 'utils/flowUtils';
import { trackEvent } from 'analytics/exploAnalytics';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { FilterOperationInstructions, FilterClause } from 'constants/types';
import { Dataset } from 'actions/types';

const styles = (theme: Theme) =>
  createStyles({
    fillerText: {
      fontSize: 16,
      fontWeight: 400,
    },
  });

type PassedProps = {
  clearRecentlyAddedOpId: () => {
    type: string;
  };
  computeLoading?: boolean;
  instructions: FilterOperationInstructions;
  openFlyout?: boolean;
  operationId: number;
  scrollToColumn: (columnName: string) => void;
  sourceDataset: Dataset;
  updateOperationInstructionAndCompute: (
    operationId: number,
    newInstructions: FilterOperationInstructions,
    shouldCompute: boolean,
  ) => void;
  summaryView?: boolean;
};
type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  editDrawerOpen: boolean;
};

class FilterOperationClauseBuilder extends React.Component<Props, State> {
  readonly state: State = {
    editDrawerOpen: this.props.openFlyout || false,
  };
  constructor(props: Props) {
    super(props);

    if (props.openFlyout) {
      props.clearRecentlyAddedOpId();
    }
  }

  render() {
    const { computeLoading, instructions, summaryView } = this.props;

    return (
      <ClauseBuilder
        computeLoading={computeLoading}
        editDrawerOpen={this.state.editDrawerOpen}
        openDrawer={() => this.setState({ editDrawerOpen: true })}
        closeDrawer={() => this.setState({ editDrawerOpen: false })}
        editOperationDrawerTitle="Define the filter operation"
        tooltipSuggestionIsOpen={
          instructions.filterClauses.filter(filterClauseComplete).length === 0
        }
        editOperationBody={this.renderOperationEditBody()}
        clauseText={this.renderFilterText()}
        summaryView={summaryView}
      />
    );
  }

  renderFilterText = (): JSX.Element[] | null => {
    const { classes, operationId, instructions, scrollToColumn } = this.props;

    const completeClauses = instructions.filterClauses.filter(filterClauseComplete);
    const clauses = [
      <div className={classes.fillerText} key={`filter_where_${operationId}`}>
        where
      </div>,
    ];
    _.each(completeClauses, (clause: FilterClause, index: number) => {
      let value = clause.filterValue;
      if (typeof clause.filterValue === 'object') {
        value = clause.filterValue.startDate;
        value += clause.filterValue.endDate ? ' - ' + clause.filterValue.endDate : '';
      }
      clauses.push(
        <StepBlockTag
          key={`clause_${index}_${operationId}`}
          interactive={true}
          onClick={() => scrollToColumn(clause.filterColumn.name)}
          content={`${clause.filterColumn.name} ${clause.filterOperation.name} ${value}`}
        />,
      );

      if (index !== completeClauses.length - 1) {
        const conjunction = instructions.matchOnAll ? 'and' : 'or';

        clauses.push(
          <div className={classes.fillerText} key={`clause_${index}_${operationId}_and`}>
            {conjunction}
          </div>,
        );
      }
    });

    return clauses.length > 1 ? clauses : null; // if no clauses were added, don't return anything
  };

  renderOperationEditBody = () => {
    const { sourceDataset, instructions, operationId } = this.props;

    return (
      <FilterEditOperationBody
        filterClauses={instructions.filterClauses}
        matchOnAll={instructions.matchOnAll}
        operationId={operationId}
        sourceDataset={sourceDataset}
        onSubmit={(newInstructions: FilterOperationInstructions) => {
          const { updateOperationInstructionAndCompute, operationId, instructions } = this.props;
          const instructionHasChanged = filterInstructionsChanged(instructions, newInstructions);
          updateOperationInstructionAndCompute(operationId, newInstructions, instructionHasChanged);
          this.setState({ editDrawerOpen: false });
          trackEvent('Update and compute filter', {
            operation_id: operationId,
            new_instructions: newInstructions,
            filter_instructions_changed: instructionHasChanged,
          });
        }}
      />
    );
  };
}

export default withStyles(styles)(FilterOperationClauseBuilder);

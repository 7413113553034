/** @format */

import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withStyles, WithStyles } from '@material-ui/styles';

import EditDrawerDataPreview from 'components/flowBuilder/editOperationDrawer/editDrawerDataPreview';
import SortOperationInputTable from 'components/flowBuilder/editOperationDrawer/sortOperationInputTable';
import OperationDrawerFooter from './operationDrawerFooter';

import { Dataset } from 'actions/types';
import { SortClause, SortOperationInstructions } from 'constants/types';
import { getSortOperationFooterErrorInformation } from 'utils/operationErrorInfoUtils/sortOperationErrorInfoUtil';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    body: {
      height: 'calc(100% - 54px)',
      overflowY: 'auto',
    },
  });

type PassedProps = {
  instructions: SortOperationInstructions;
  operationId: number;
  sourceDataset: Dataset;
  onSubmit: (newInstructions: SortOperationInstructions) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  instructions: SortOperationInstructions;
};

class SortEditOperationBody extends React.Component<Props, State> {
  readonly state: State = {
    instructions: cloneDeep(this.props.instructions),
  };

  render() {
    const { classes, onSubmit, sourceDataset } = this.props;
    const { instructions } = this.state;
    const { footerErrorState, footerErrorText } = getSortOperationFooterErrorInformation(
      instructions,
    );
    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <EditDrawerDataPreview dataPreviewHeader="Source Data" sourceDataset={sourceDataset} />
          <SortOperationInputTable
            sortClauses={instructions.sortColumns}
            updateInstructions={this.updateInstructions}
            cachedSchema={sourceDataset.cached_schema!}
          />
        </div>
        <OperationDrawerFooter
          errorState={footerErrorState}
          errorText={footerErrorText}
          onSubmit={() => onSubmit(instructions)}
        />
      </div>
    );
  }
  updateInstructions = (tableElements: SortClause[]) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.sortColumns = tableElements;
      return { instructions };
    });
  };
}

export default withStyles(styles)(SortEditOperationBody);

/** @format */

import { defineAction } from 'actions/actionUtils';

export const updateFlowViewConfig = defineAction(
  'UPDATE_FLOW_VIEW_CONFIG',
  'flows',
  'update_view_config',
  'POST',
);

export const updateFlowNodePos = defineAction(
  'UPDATE_FLOW_NODE_POSITION',
  'flows',
  'update_node_position',
  'POST',
);

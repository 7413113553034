/** @format */

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from 'reducers/rootReducer';
import initialState from './initialState';
import { composeWithDevTools } from 'redux-devtools-extension';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
  }
}

export default function configureStore(state = initialState) {
  const enhancers = window.__REDUX_DEVTOOLS_EXTENSION__
    ? composeWithDevTools(applyMiddleware(thunk))
    : applyMiddleware(thunk);
  // @ts-ignore
  return createStore(rootReducer, state, enhancers);
}

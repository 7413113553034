/** @format */

import React from 'react';
import _ from 'underscore';
import cx from 'classnames';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';

import StepBlockTag from 'components/flowBuilder/stepBlockTag';

import { formulaClauseComplete } from 'utils/flowUtils';

import { InstructionComponentProps } from 'components/flowGraph/operationInstructionDescription';
import { FormulaOperationInstructions } from 'constants/types';

const styles = (theme: Theme) =>
  createStyles({
    fillerText: {
      fontSize: 16,
      fontWeight: 400,
      margin: `0 ${theme.spacing(2)}px`,
    },
    startingFillterText: {
      marginLeft: 0,
    },
  });

type PassedProps = InstructionComponentProps<FormulaOperationInstructions>;

type Props = PassedProps & WithStyles<typeof styles>;

class FilterOperationInstructionDescription extends React.Component<Props> {
  render() {
    const { classes, operationId } = this.props;

    const completeClauses = this.props.instructions.filter(formulaClauseComplete);
    const clauses = [
      <div
        className={cx(classes.fillerText, classes.startingFillterText)}
        key={`filter_where_${operationId}`}>
        to create columns:{' '}
      </div>,
    ];
    _.each(completeClauses, (clause, index) => {
      clauses.push(
        <StepBlockTag key={`clause_${index}_${operationId}`} content={clause.newColName} />,
      );

      if (index !== completeClauses.length - 1) {
        clauses.push(
          <div className={classes.fillerText} key={`clause_${index}_${operationId}_and`}>
            and
          </div>,
        );
      }
    });

    return clauses.length > 1 ? <>{clauses}</> : <></>;
  }
}

export default withStyles(styles)(FilterOperationInstructionDescription);

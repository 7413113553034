/** @format */

import { Action } from 'reducers/rootReducer';
import { Dataset } from 'actions/types';

import cloneDeep from 'lodash/cloneDeep';

export interface DatasetReducerState {
  loading?: boolean;
  error?: boolean;
  datasetInfo?: Dataset | null;
  schemaChangeLoading?: boolean;
}

const datasetReducerInitialState: DatasetReducerState = {};

export default (
  state: DatasetReducerState = datasetReducerInitialState,
  action: Action,
): DatasetReducerState => {
  const newState = cloneDeep(state);

  switch (action.type) {
    case 'FETCH_DATASET_DATA_REQUEST':
      newState.loading = true;
      newState.error = false;
      newState.datasetInfo = null;
      return newState;
    case 'FETCH_DATASET_DATA_SUCCESS':
      newState.loading = false;
      newState.error = false;
      newState.datasetInfo = action.payload.dataset;
      return newState;
    case 'DATASET_UPLOAD_COMPLETE':
      if (action.payload.previewData && newState.datasetInfo) {
        newState.datasetInfo.cached_schema = action.payload.previewData.cached_schema;
        newState.datasetInfo.cached_preview = action.payload.previewData.cached_preview;
        newState.datasetInfo.total_row_count = action.payload.previewData.num_total_rows;
        newState.datasetInfo.loading = false;
      }
      return newState;
    case 'FETCH_DATASET_DATA_ERROR':
      newState.loading = false;
      newState.error = true;
      newState.datasetInfo = null;
      return newState;
    case 'FETCH_DATASET_ROW_COUNT_SUCCESS':
      if (newState.datasetInfo) {
        newState.datasetInfo.total_row_count = action.payload['row_count'];
      }
      return newState;
    case 'GET_DATASET_PREVIEW_SUCCESS':
      if (newState.datasetInfo) {
        newState.datasetInfo.cached_preview = action.payload.preview_rows;
        newState.datasetInfo.results_last_updated_at = action.payload.results_last_updated_at;
        newState.datasetInfo.loading = false;
      }
      newState.schemaChangeLoading = false;
      return newState;
    default:
      return newState;
  }
};

/** @format */

import React, { useEffect } from 'react';
import cx from 'classnames';
import { Icon, Spinner, Popover, Menu, MenuItem } from '@blueprintjs/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { fetchDashboardList } from 'actions/dashboardActions';
import { ReduxState } from 'reducers/rootReducer';
import { ROUTES } from 'constants/routes';
import ExploLogo from 'images/branding/logo_primary.png';
import theme from 'styleThemes';
import { logoutUser } from 'actions/authAction';
import {
  updateWorkspaceSearchState,
  fetchDataSourceList,
  fetchWorkspaceData,
} from 'actions/dataSourceActions';
import WorkspaceSearchBar from 'components/pages/workspaceSearchBar';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { getExplorations } from 'actions/flowActions';
import { DATA_PAGES, EXPLORATION_PAGES, DASHBOARD_PAGES } from 'constants/flowConstants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 80,
    backgroundColor: theme.palette.black100,
    display: 'flex',
    justifyContent: 'space-between',
    padding: `22px ${theme.spacing(6)}px`,
  },
  navBarButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.grey100,
    marginRight: theme.spacing(2),
    height: theme.spacing(9),
    width: 145,
    fontWeight: 500,
    fontSize: 14,
    '&:hover': {
      color: theme.palette.white,
      backgroundColor: theme.palette.navSelectBlue,
      borderRadius: 4,
      cursor: 'pointer',
    },
  },
  navBarButtonSelected: {
    color: theme.palette.white,
    backgroundColor: theme.palette.navSelectBlue,
    borderRadius: 4,
  },
  buttonIcon: {
    marginRight: theme.spacing(4),
  },
  logo: {
    height: 30,
    marginRight: 80,
  },
  leftNavOptions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightNavOptions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightNavItem: {
    marginLeft: theme.spacing(8),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  questionItem: {
    marginRight: theme.spacing(8),
  },
  spinner: {
    color: theme.palette.grey.navBarItemGrey,
  },
  profileIcon: {
    borderRadius: '50%',
    backgroundColor: theme.palette.grey.navBarItemGrey,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    fontSize: 14,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

type Props = typeof mapDispatchToProps & RouteComponentProps & ReturnType<typeof mapStateToProps>;

const onDataTabClick = (props: Props) => {
  const { history } = props;
  history.push(ROUTES.DATA_SOURCE_LIST);
};

const onExplorationsTabClick = (props: Props) => {
  const { history } = props;
  history.push(ROUTES.DATASOURCE);
};

const onDashboardTabClick = (props: Props) => {
  const { history } = props;
  history.push(ROUTES.DASHBOARD_LIST);
};

const renderSearchOmniBar = (props: Props) => {
  const { isWorkspaceSearchOpen, updateWorkspaceSearchState, workspaceData, dashboardList } = props;
  if (!isWorkspaceSearchOpen) return;
  return (
    <WorkspaceSearchBar
      isOpen={isWorkspaceSearchOpen}
      onClose={() => updateWorkspaceSearchState(false)}
      workspaceData={workspaceData.data}
      dashboardsList={dashboardList}
    />
  );
};

const NavBar = (props: Props) => {
  const classes = useStyles();
  const {
    fetchDashboardList,
    history,
    updateWorkspaceSearchState,
    workspaceSearchInfoLoading,
    getExplorations,
    currentUser,
    logoutUser,
    fetchDataSourceList,
    fetchWorkspaceData,
  } = props;

  useEffect(() => {
    fetchDashboardList();
    getExplorations();
    fetchDataSourceList();

    fetchWorkspaceData({
      id: 'csv',
    });
  }, [fetchDashboardList, getExplorations, fetchDataSourceList, fetchWorkspaceData]);

  const formattedPathName = ('/' + history.location.pathname.split('/')[1]) as ROUTES;
  return (
    <div className={classes.root}>
      <div className={classes.leftNavOptions}>
        <div>
          <a href="/home">
            <img className={classes.logo} src={ExploLogo} alt="logo for explo" />
          </a>
        </div>
        <div
          className={cx(classes.navBarButton, {
            [classes.navBarButtonSelected]: DATA_PAGES.has(formattedPathName),
          })}
          onClick={() => onDataTabClick(props)}>
          <Icon className={classes.buttonIcon} icon="database" iconSize={20} />
          Data
        </div>
        <div
          className={cx(classes.navBarButton, {
            [classes.navBarButtonSelected]: EXPLORATION_PAGES.has(formattedPathName),
          })}
          onClick={() => onExplorationsTabClick(props)}>
          <Icon className={classes.buttonIcon} icon="layout-hierarchy" iconSize={20} />
          Explorations
        </div>
        <div
          className={cx(classes.navBarButton, {
            [classes.navBarButtonSelected]: DASHBOARD_PAGES.has(formattedPathName),
          })}
          onClick={() => onDashboardTabClick(props)}>
          <Icon className={classes.buttonIcon} icon="timeline-bar-chart" iconSize={20} />
          Dashboards
        </div>
      </div>
      <div className={classes.rightNavOptions}>
        {workspaceSearchInfoLoading ? (
          <Spinner className={classes.spinner} size={20} />
        ) : (
          <Icon
            className={classes.rightNavItem}
            iconSize={20}
            color={theme.palette.grey.navBarItemGrey}
            icon="search"
            onClick={() => updateWorkspaceSearchState(true)}
          />
        )}
        <Icon
          className={cx(classes.rightNavItem, classes.questionItem)}
          iconSize={20}
          color={theme.palette.grey.navBarItemGrey}
          icon="help"
          onClick={() => {
            window.open('http://help.explo.co/');
          }}
        />
        <Popover>
          <div className={classes.profileIcon}>
            {currentUser.first_name ? currentUser.first_name.charAt(0).toUpperCase() : 'A'}
          </div>
          <Menu key="user-menu">
            <MenuItem icon="people" text="Team Settings" onClick={() => history.push(`/team`)} />
            <MenuItem icon="people" text="Analytics" onClick={() => history.push(`/analytics`)} />
            <MenuItem icon="log-out" text="Logout" onClick={() => logoutUser()} />
          </Menu>
        </Popover>
      </div>
      {renderSearchOmniBar(props)}
    </div>
  );
};

const workspaceSearchInfoLoadingSelector = createLoadingSelector([
  ACTION.GET_EXPLORATIONS,
  ACTION.FETCH_DASHBOARD_LIST,
]);
const mapStateToProps = (state: ReduxState) => ({
  workspaceSearchInfoLoading: workspaceSearchInfoLoadingSelector(state),
  isWorkspaceSearchOpen: state.workspaceData.isWorkspaceSearchOpen,
  workspaceData: state.workspaceData,
  dashboardList: state.dashboardData.dashboardList,
  userFirstName: state.currentUser.first_name,
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  logoutUser,
  updateWorkspaceSearchState,
  fetchDashboardList,
  getExplorations,
  fetchDataSourceList,
  fetchWorkspaceData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));

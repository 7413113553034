/** @format */

import React from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

import Shopify from 'images/marketing/integration_logos/shopify.png';
import Segment from 'images/marketing/integration_logos/segment.png';
import Klaviyo from 'images/marketing/integration_logos/klaviyo.png';
import GoogleAnalytics from 'images/marketing/integration_logos/google_analytics.png';
import GoogleAdwords from 'images/marketing/integration_logos/google_adwords.png';
import Salesforce from 'images/marketing/integration_logos/salesforce.png';
import Stripe from 'images/marketing/integration_logos/stripe.png';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'grid',
    gridColumnGap: theme.spacing(6),
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridColumnGap: theme.spacing(10),
      gridRowGap: theme.spacing(10),
    },
  },
  integrationImg: {
    width: '100%',
  },
}));

type Props = {
  className?: string;
};

const EcommLogos = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.root, props.className)}>
      <img className={classes.integrationImg} src={Shopify} alt="Shopify logo" />
      <img className={classes.integrationImg} src={Segment} alt="Segment logo" />
      <img className={classes.integrationImg} src={Klaviyo} alt="Klaviyo query logo" />
      <img className={classes.integrationImg} src={Salesforce} alt="Salesforce logo" />
      <img className={classes.integrationImg} src={Stripe} alt="Stripe logo" />
      <img className={classes.integrationImg} src={GoogleAnalytics} alt="GoogleAnalytics logo" />
      <img className={classes.integrationImg} src={GoogleAdwords} alt="GoogleAdwords logo" />
    </div>
  );
};

export default EcommLogos;

/** @format */

import Cookies from 'js-cookie';

import { defineAction } from 'actions/actionUtils.tsx';

import { RESOURCE_TYPES } from 'constants/fileSystemConstants';
import { ACTION } from './types';

const createFlowFromDatasetRequest = () => ({
  type: 'CREATE_FLOW_FROM_DATA_SET_REQUEST',
});

const createFlowFromDatasetError = (errorMsg) => ({
  type: 'CREATE_FLOW_FROM_DATA_SET_ERROR',
  payload: errorMsg,
});

const createFlowFromDatasetSuccess = (newResourceData) => ({
  type: 'CREATE_FLOW_FROM_DATA_SET_SUCCESS',
  payload: newResourceData,
});

export function createFlowFromDataset(
  datasetId,
  parentFolderId,
  flowName,
  routeToNewFlow,
  errorCallback,
  successCallback,
) {
  return function (dispatch) {
    dispatch(createFlowFromDatasetRequest());
    return fetch(process.env.REACT_APP_API_URL + `filesystem/${parentFolderId}/create_new_flow/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + Cookies.get('spheres_auth_token'),
      },
      body: JSON.stringify({
        resource_id: datasetId,
        resource_type: RESOURCE_TYPES.DATASET,
        name: flowName,
      }),
    })
      .then((resp) => resp.json())
      .then((responseData) => {
        if (!responseData.success) {
          errorCallback && errorCallback(responseData);
          dispatch(createFlowFromDatasetError(responseData));
        } else {
          dispatch(createFlowFromDatasetSuccess(responseData));
          routeToNewFlow(responseData['id']);
          successCallback && successCallback(`Successfully created flow: ${flowName}`);
        }
      });
  };
}

const fetchFlowDataRequest = () => ({
  type: 'FETCH_FLOW_DATA_REQUEST',
});

const fetchFlowDataError = (errorMsg) => ({
  type: 'FETCH_FLOW_DATA_ERROR',
  payload: errorMsg,
});

const fetchFlowDataSuccess = (flowData, flowViewData) => ({
  type: 'FETCH_FLOW_DATA_SUCCESS',
  payload: {
    ...flowData,
    flow_view_config: flowViewData,
  },
});

export function fetchFlowData(flowId, onSuccess) {
  return function (dispatch) {
    dispatch(fetchFlowDataRequest());
    return fetch(process.env.REACT_APP_API_URL + `flows/${flowId}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + Cookies.get('spheres_auth_token'),
      },
    })
      .then((resp) => resp.json())
      .then((responseData) => {
        if (!responseData.success) {
          dispatch(fetchFlowDataError(responseData));
        } else {
          onSuccess && onSuccess(responseData.flow);
          dispatch(fetchFlowDataSuccess(responseData.flow, responseData.flow_view_config));
        }
      });
  };
}

export function fetchFlowDataPublic(flowId, onSuccess) {
  return function (dispatch) {
    dispatch(fetchFlowDataRequest());
    return fetch(process.env.REACT_APP_API_URL + `readonly/${flowId}/fetch_flow_data`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((resp) => resp.json())
      .then((responseData) => {
        if (!responseData.success) {
          dispatch(fetchFlowDataError(responseData));
        } else {
          dispatch(fetchFlowDataSuccess(responseData.flow, responseData.flow_view_config));
          onSuccess && onSuccess(responseData.flow);
        }
      });
  };
}

export const updateOperationFlyoutState = (isFlyoutOpen) => ({
  type: 'UPDATE_OPERATION_FLYOUT_STATE',
  payload: {
    isFlyoutOpen,
  },
});

export const fetchDatasetRowCount = defineAction(
  'FETCH_DATASET_ROW_COUNT',
  'datasets',
  'row_count',
  'GET',
);

export const fetchDatasetRowCountPublic = defineAction(
  'FETCH_DATASET_ROW_COUNT',
  'readonly',
  'row_count',
  'GET',
);

export const updateOperationPreview = (operationId, cachedPreview, cachedSchema, totalRows) => ({
  type: 'UPDATE_OPERATION_PREVIEW',
  payload: {
    operationId,
    cachedPreview,
    cachedSchema,
    totalRows,
  },
});

export const addFlowStep = defineAction('ADD_FLOW_STEP', 'flows', 'add_operation', 'POST');

export const computeExistingStepPreviewRequest = (operationId) => ({
  type: 'COMPUTE_EXISTING_STEP_PREVIEW_REQUEST',
  payload: {
    operationId,
  },
});

export const computeExistingStepPreviewSuccess = (operationId, operation, outOfDateOperations) => ({
  type: 'COMPUTE_EXISTING_STEP_PREVIEW_SUCCESS',
  payload: {
    operationId,
    operation,
    outOfDateOperations,
  },
});

export const computeExistingStepPreviewError = (operationId, operation, outOfDateOperations) => ({
  type: 'COMPUTE_EXISTING_STEP_PREVIEW_ERROR',
  payload: {
    operationId,
    operation,
    outOfDateOperations,
  },
});

export const stepPreviewFailed = (operationId, resultsJobError) => ({
  type: 'STEP_PREVIEW_FAILED',
  payload: {
    operationId,
    resultsJobError,
  },
});

export function computeExistingStepPreview(
  operationId,
  partOfBulkUpdate,
  onSuccess,
  outDatedOpsCallback,
) {
  return function (dispatch) {
    dispatch(computeExistingStepPreviewRequest(operationId));
    return fetch(
      process.env.REACT_APP_API_URL +
        'operations/' +
        operationId +
        '/compute_existing_step_preview/',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + Cookies.get('spheres_auth_token'),
        },
        body: JSON.stringify({
          is_bulk_update: partOfBulkUpdate,
        }),
      },
    )
      .then((resp) => resp.json())
      .then((responseData) => {
        if (responseData.success) {
          dispatch(
            computeExistingStepPreviewSuccess(
              operationId,
              responseData.operation,
              partOfBulkUpdate ? [] : responseData.out_of_date_operations,
            ),
          );
          onSuccess && onSuccess(responseData);
          outDatedOpsCallback && outDatedOpsCallback(responseData.out_of_date_operations);
        } else {
          console.error('resultsJobError', responseData.error);
          dispatch(stepPreviewFailedCallback(operationId, responseData.error));
        }
      });
  };
}

export function stepPreviewFailedCallback(operationId, error) {
  return function (dispatch) {
    dispatch(stepPreviewFailed(operationId, error));
  };
}

export function downloadResultsTableAsCsv(datasetId, setOperationDownloading, onSuccess, onError) {
  return function (dispatch) {
    setOperationDownloading(true);
    return fetch(process.env.REACT_APP_API_URL + 'datasets/' + datasetId + '/download_csv/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + Cookies.get('spheres_auth_token'),
      },
    })
      .then((resp) => resp.json())
      .then((responseData) => {
        if (responseData.success) {
          setOperationDownloading(false);
          window.open(responseData['url']);
          onSuccess(responseData['url']);
        } else {
          setOperationDownloading(false);
          onError(responseData['error_msg']);
        }
      });
  };
}

export const updateFlowOperationOptimistic = (operationId, newOperationData) => ({
  type: 'UPDATE_FLOW_OPERATION_REQUEST',
  payload: {
    operationId,
    newOperationData,
  },
});

export const updateFlowOperationSucces = (operationId) => ({
  type: 'UPDATE_FLOW_OPERATION_SUCCESS',
  payload: { operationId },
});

export function updateFlowOperation(operationId, newOperationData, onSuccess) {
  return function (dispatch) {
    dispatch(updateFlowOperationOptimistic(operationId, newOperationData));
    return fetch(process.env.REACT_APP_API_URL + `operations/${operationId}/update_operation/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + Cookies.get('spheres_auth_token'),
      },
      body: JSON.stringify({
        operation_type: newOperationData.operation_type,
        instructions: newOperationData.instructions,
      }),
    })
      .then((resp) => resp.json())
      .then((responseData) => {
        if (!responseData.success) {
        } else {
          dispatch(updateFlowOperationSucces(operationId));
          onSuccess && onSuccess();
        }
      });
  };
}

export const deleteFlowOperationRequest = (operationId) => ({
  type: 'DELETE_OPERATION_REQUEST',
  payload: {
    operationId,
  },
});

export const deleteFlowOperationSuccess = (operationId, flow) => ({
  type: 'DELETE_OPERATION_SUCCESS',
  payload: {
    operationId,
    flow,
  },
});

export function deleteFlowOperation(operationId) {
  return function (dispatch) {
    dispatch(deleteFlowOperationRequest(operationId));
    return fetch(process.env.REACT_APP_API_URL + `operations/${operationId}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + Cookies.get('spheres_auth_token'),
      },
    })
      .then((resp) => resp.json())
      .then((responseData) => {
        if (!responseData.success) {
        } else {
          dispatch(deleteFlowOperationSuccess(operationId, responseData.flow));
        }
      });
  };
}

export const clearRecentlyAddedOpId = () => ({
  type: 'CLEAR_RECENTLY_ADDED_OP_ID',
});

export const fetchFlowRelatedDashboards = defineAction(
  'FETCH_FLOW_RELATED_DASHBOARDS',
  'flows',
  'related_dashboards',
  'GET',
);

export const removeDatasetFromFlow = defineAction(
  'REMOVE_DATASET_FROM_FLOW',
  'flows',
  'remove_dataset_node',
  'POST',
);

export const fetchOperationRowCount = defineAction(
  'FETCH_OPERATION_ROW_COUNT',
  'operations',
  'row_count',
  'GET',
);

export const addDatasetToFlow = defineAction('ADD_DATASET_TO_FLOW', 'flows', 'add_dataset', 'POST');

export const refreshFlowOperation = defineAction(
  'REFRESH_FLOW_OPERATION',
  'operations',
  'refresh',
  'POST',
);

export const refreshDatasetFlowNode = defineAction(
  'REFRESH_DATASET_FLOW_NODE',
  'datasets',
  'refresh_flow_node',
  'GET',
);

export const refreshFlow = defineAction('REFRESH_FLOW', 'flows', 'refresh', 'POST');

export const getExplorations = defineAction(
  ACTION.GET_EXPLORATIONS,
  'flows',
  'explorations',
  'GET',
);

/** @format */

import React from 'react';
import _ from 'underscore';
import { withRouter } from 'react-router-dom';
import ClauseBuilder from 'components/flowBuilder/clauseBuilder';
import DedupOperationBody from 'components/flowBuilder/editOperationDrawer/dedupOperationBody';
import StepBlockTag from 'components/flowBuilder/stepBlockTag';
import {
  dedupInstructionsComplete,
  dedupInstructionsChanged,
  sortClauseComplete,
  dedupClauseComplete,
} from 'utils/flowUtils';
import { trackEvent } from 'analytics/exploAnalytics';
import { withStyles, WithStyles } from '@material-ui/styles';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { DedupOperationInstructions, SortClause } from 'constants/types';
import { Dataset } from 'actions/types';

const styles = (theme: Theme) =>
  createStyles({
    fillerText: {
      fontSize: 16,
      fontWeight: 400,
    },
  });

type PassedProps = {
  clearRecentlyAddedOpId: () => {
    type: string;
  };
  computeLoading?: boolean;
  instructions: DedupOperationInstructions;
  openFlyout?: boolean;
  operationId: number;
  scrollToColumn: (columnName: string) => void;
  sourceDataset: Dataset;
  updateOperationInstructionAndCompute: (
    operationId: number,
    newInstructions: any,
    shouldCompute: boolean,
  ) => void;
  summaryView?: boolean;
};
type Props = PassedProps & WithStyles<typeof styles> & RouteComponentProps;

type State = {
  editDrawerOpen: boolean;
};

class DedupOperationClauseBuilder extends React.PureComponent<Props, State> {
  readonly state: State = {
    editDrawerOpen: this.props.openFlyout || false,
  };
  constructor(props: Props) {
    super(props);

    if (props.openFlyout) {
      props.clearRecentlyAddedOpId();
    }
  }

  render() {
    const { computeLoading, instructions, summaryView } = this.props;
    const { editDrawerOpen } = this.state;

    return (
      <ClauseBuilder
        computeLoading={computeLoading}
        editDrawerOpen={editDrawerOpen}
        openDrawer={() => this.setState({ editDrawerOpen: true })}
        closeDrawer={() => this.setState({ editDrawerOpen: false })}
        editOperationDrawerTitle="Define the remove duplicates operation"
        tooltipSuggestionIsOpen={!dedupInstructionsComplete(instructions)}
        editOperationBody={this.renderOperationEditBody()}
        clauseText={this.renderDedupText()}
        summaryView={summaryView}
      />
    );
  }

  renderOperationEditBody = () => {
    const { sourceDataset, instructions, operationId } = this.props;

    return (
      <DedupOperationBody
        instructions={instructions}
        operationId={operationId}
        sourceDataset={sourceDataset}
        onSubmit={(newInstructions: DedupOperationInstructions) => {
          const { operationId, updateOperationInstructionAndCompute, instructions } = this.props;
          const instructionHasChanged = dedupInstructionsChanged(instructions, newInstructions);
          updateOperationInstructionAndCompute(
            operationId,
            newInstructions,
            instructionHasChanged && dedupInstructionsComplete(newInstructions),
          );
          this.setState({ editDrawerOpen: false });
          trackEvent('Update and compute Dedup', {
            operation_id: operationId,
            new_instructions: newInstructions,
            formula_instructions_changed: instructionHasChanged,
          });
        }}
      />
    );
  };

  renderDedupText = (): JSX.Element[] | null => {
    const { classes, operationId, instructions, scrollToColumn } = this.props;
    const completeDedupClauses = instructions.colsToDedup.filter(dedupClauseComplete);
    const completeSortClauses = instructions.sortColumns.filter(sortClauseComplete);

    const phrases = [
      <div className={classes.fillerText} key={`pivot_on_${operationId}`}>
        on
      </div>,
    ];
    _.each(completeDedupClauses, (col, index) => {
      phrases.push(
        <StepBlockTag
          key={`pivot_col_${index}_${operationId}`}
          interactive={true}
          onClick={() => scrollToColumn(col.name)}
          content={col.name}
        />,
      );

      if (index !== completeDedupClauses.length - 1) {
        phrases.push(
          <div className={classes.fillerText} key={`pivot_filler_on${index}_${operationId}_and`}>
            and
          </div>,
        );
      }
    });

    if (completeSortClauses.length === 0) {
      return completeDedupClauses.length >= 1 ? phrases : null;
    }

    phrases.push(
      <div className={classes.fillerText} key={`filter_where_${operationId}`}>
        by
      </div>,
    );

    _.each(completeSortClauses, (clause: SortClause, index: number) => {
      if (index !== 0) {
        phrases.push(
          <div className={classes.fillerText} key={`sort_clause_${index}_${operationId}_filler`}>
            , then by
          </div>,
        );
      }

      phrases.push(
        <StepBlockTag
          key={`sort_clause_${index}_${operationId}`}
          interactive={true}
          onClick={() => scrollToColumn(clause.column!.name)}
          content={`${clause.column!.name} (${clause.order!})`}
        />,
      );
    });

    return completeDedupClauses.length >= 1 ? phrases : null;
  };
}

export default withRouter(withStyles(styles)(DedupOperationClauseBuilder));

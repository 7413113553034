/** @format */
import { Action } from 'reducers/rootReducer';
import { DataSource } from 'actions/types';

export interface DataSourceReducerState {
  loading: boolean;
  error?: string;
  data?: any;
  selectedDataSource?: DataSource;
  dataSourceData?: any;
  dataSourceDataError: boolean;
}

const dataSourceReducerInitialState: DataSourceReducerState = {
  loading: false,
  dataSourceDataError: false,
};

export default (
  state: DataSourceReducerState = dataSourceReducerInitialState,
  action: Action,
): DataSourceReducerState => {
  const newState = Object.assign({}, state);
  const { payload } = action;

  switch (action.type) {
    case 'FETCH_DATA_SOURCE_REQUEST':
      newState.loading = true;
      return newState;
    case 'FETCH_DATA_SOURCE_SUCCESS':
      newState.loading = false;
      newState.data = payload.data_source;
      return newState;
    case 'SET_SELECTED_DATA_SOURCE':
      newState.selectedDataSource = payload.dataSource;
      return newState;
    case 'FETCH_WORKSPACE_DATA_REQUEST':
      newState.data = null;
      newState.dataSourceDataError = false;
      return newState;
    case 'FETCH_WORKSPACE_DATA_ERROR':
      newState.dataSourceDataError = true;
      return newState;
    case 'FETCH_WORKSPACE_DATA_SUCCESS':
      newState.data = payload.workspace;
      newState.dataSourceDataError = false;
      if (newState.data === null) {
        return newState;
      }
      newState.data.user_resource_ids = new Set(newState.data.user_resource_ids);
      newState.data.team_resource_ids = new Set(newState.data.team_resource_ids);
      return newState;
    default:
      return state;
  }
};

/** @format */

import React from 'react';
import cx from 'classnames';
import _ from 'underscore';
import { withStyles } from '@material-ui/styles';

import { H3 } from '@blueprintjs/core';

import OperationDropdownInput from 'components/flowBuilder/operationDropdownInput';
import EditDrawerDataPreview from 'components/flowBuilder/editOperationDrawer/editDrawerDataPreview';

import {
  NUMBER_TYPES,
  AGGREGATIONS_TYPES,
  AGGREGATIONS_LIST,
  NON_NUMBER_AGGREGATIONS_LIST,
  FLYOUT_DATA_PREVIEW_HEADER,
} from 'constants/flowConstants.tsx';

import { getPivotChartOperationFooterErrorInformation } from 'utils/operationErrorInfoUtils/pivotChartOperationErrorInfoUtil';
import OperationDrawerFooter from './operationDrawerFooter';

const styles = (theme) => ({
  root: {
    height: '100%',
  },
  body: {
    height: 'calc(100% - 54px)',
    overflowY: 'auto',
  },
  selectionTitle: {
    margin: theme.spacing(6),
  },
  selectionContainer: {
    margin: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    marginBottom: theme.spacing(6),
    backgroundColor: theme.palette.white,
    boxShadow: theme.customShadows.basic,
    borderRadius: 4,
    padding: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
  },
  selectionText: {
    fontSize: 16,
    fontWeight: 'bold',
    marginRight: theme.spacing(3),
  },
  tableContainer: {
    margin: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    marginBottom: theme.spacing(6),
    backgroundColor: theme.palette.white,
    boxShadow: theme.customShadows.basic,
    borderRadius: 4,
  },
  clauseTable: {
    width: '100%',
  },
  addLineButton: {
    margin: theme.spacing(3),
    marginLeft: 52,
  },
  cancelClauseCol: {
    width: 30,
    paddingRight: '0px !important',
  },
  helpTooltip: {
    marginLeft: theme.spacing(2),
  },
  helpTooltipPopover: {
    width: 250,
  },
  pivotColNameCol: {
    width: 224,
  },
});

class PivotChartEditOperationBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      instructions: props.instructions,
    };
  }

  render() {
    const { classes, onSubmit, sourceDataset } = this.props;
    const { instructions } = this.state;
    const { footerErrorState, footerErrorText } = getPivotChartOperationFooterErrorInformation(
      instructions,
    );
    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <EditDrawerDataPreview
            sourceDataset={sourceDataset}
            dataPreviewHeader={FLYOUT_DATA_PREVIEW_HEADER}
          />
          <H3 className={classes.selectionTitle}>Select the column to group by</H3>
          <div className={classes.selectionContainer}>{this.renderXAxisSelector()}</div>
          <H3 className={classes.selectionTitle}>Configure values</H3>
          <div className={classes.tableContainer}>
            <table className={cx(classes.clauseTable, 'bp3-html-table')}>
              <thead>
                <tr>
                  <th>Column</th>
                  <th>Calculation Type</th>
                </tr>
              </thead>
              <tbody>{this.renderAggregation()}</tbody>
            </table>
          </div>
        </div>
        <OperationDrawerFooter
          errorState={footerErrorState}
          errorText={footerErrorText}
          onSubmit={() => {
            const cleanInstructions = this.copyInstructions();
            onSubmit(cleanInstructions);
          }}
        />
      </div>
    );
  }

  renderXAxisSelector = () => {
    const { instructions } = this.state;
    const { classes, sourceDataset } = this.props;
    const schemaByColumnName = _.indexBy(sourceDataset.cached_schema, 'name');

    return (
      <>
        <div className={classes.selectionText}>Group by: </div>
        <OperationDropdownInput
          buttonErrorState={instructions.xAxisColumn === null}
          selectedItem={
            instructions.xAxisColumn && {
              name: instructions.xAxisColumn.name,
              id: instructions.xAxisColumn.name,
            }
          }
          onChange={(item) => {
            this.selectXAxisColum(schemaByColumnName[item.id]);
          }}
          options={sourceDataset.cached_schema.map((col) => ({ name: col.name, id: col.name }))}
          noSelectionText="Select Column"
        />
      </>
    );
  };

  renderAggregation = () => {
    const { instructions } = this.state;
    const { classes, sourceDataset } = this.props;
    const { aggregation } = instructions;

    const schemaByColumnName = _.indexBy(sourceDataset.cached_schema, 'name');

    return (
      <tr>
        <td className={classes.pivotColNameCol}>
          <OperationDropdownInput
            buttonErrorState={instructions.aggregation.aggedOnColumn === null}
            selectedItem={
              aggregation.aggedOnColumn && {
                name: aggregation.aggedOnColumn,
                id: aggregation.aggedOnColumn,
              }
            }
            onChange={this.setAggregationColumn}
            noSelectionText="Select a Column"
            options={sourceDataset.cached_schema.map((col) => ({ ...col, id: col.name }))}
          />
        </td>
        <td className={classes.pivotColNameCol}>
          <OperationDropdownInput
            buttonErrorState={instructions.aggregation.type === null}
            disabled={aggregation.aggedOnColumn === null}
            selectedItem={
              aggregation.type
                ? aggregation.type.id
                  ? aggregation.type
                  : this.getAggItemFromId(aggregation.type)
                : undefined
            }
            onChange={this.setAggregationType}
            noSelectionText="Select an Aggregation"
            options={this.getAggListForColType(
              aggregation.aggedOnColumn && schemaByColumnName[aggregation.aggedOnColumn].type,
            )}
          />
        </td>
      </tr>
    );
  };

  getAggListForColType = (colType) => {
    if (!colType) {
      return [];
    }
    if (NUMBER_TYPES.has(colType)) {
      return AGGREGATIONS_LIST;
    } else {
      return NON_NUMBER_AGGREGATIONS_LIST;
    }
  };

  getAggItemFromId = (aggType) => {
    return AGGREGATIONS_TYPES[aggType];
  };

  copyInstructions = () => {
    const { instructions } = this.state;
    return {
      xAxisColumn: instructions.xAxisColumn && Object.assign({}, instructions.xAxisColumn),
      aggregation: instructions.aggregation && Object.assign({}, instructions.aggregation),
    };
  };

  setAggregationType = (agg) => {
    const newInstructions = this.copyInstructions();
    newInstructions.aggregation.type = agg;
    this.setState({ instructions: newInstructions });
  };

  setAggregationColumn = (column) => {
    const newInstructions = this.copyInstructions();
    newInstructions.aggregation.type = null;
    newInstructions.aggregation.additionalCol = null;
    newInstructions.aggregation.aggedOnColumn = column.name;
    this.setState({ instructions: newInstructions });
  };

  setAdditionalColumn = (additionalCol) => {
    const newInstructions = this.copyInstructions();
    newInstructions.aggregation.additionalCol = additionalCol.name;
    this.setState({ instructions: newInstructions });
  };

  selectXAxisColum = (col) => {
    const instructions = this.copyInstructions(this.state.instructions);
    instructions.xAxisColumn = col;
    this.setState({ instructions });
  };
}

export default withStyles(styles)(PivotChartEditOperationBody);

/** @format */

import React from 'react';
import Chart from 'chart.js';
import _ from 'underscore';
import moment from 'moment';

import { columnNamePostAggregation } from 'utils/flowUtils';
import { numberWithCommas } from 'utils/general';

import { BLUE } from 'constants/colorConstants';
import { DATE_TYPES, DEFAULT_DATE_FORMAT } from 'constants/flowConstants';
import { Schema } from 'constants/types';
import { chartConfigIsSame } from 'utils/graphUtils';

type Props = {
  aggedOnColumn: string;
  data: any[];
  type: string;
  xAxis: string;
  schema: Schema;
};

class BarChartGraph extends React.PureComponent<Props> {
  chartRef: React.RefObject<any> = React.createRef();
  chart: Chart | null = null;

  componentDidMount() {
    const myChartRef = this.chartRef.current.getContext('2d');

    this.chart = new Chart(myChartRef, this.getChartConfig());
  }

  componentDidUpdate() {
    if (this.props.data[0] === undefined || this.props.data[0][this.props.xAxis] === undefined) {
      return;
    }

    const newConfig = this.getChartConfig();

    // Don't update the chart if the chart config did not change
    if (!this.chart || chartConfigIsSame(newConfig, this.chart)) {
      return;
    }

    this.chart.options = newConfig.options;
    this.chart.data = newConfig.data;
    this.chart.update();
  }

  formatLabel = (value: any, colType: string) => {
    if (DATE_TYPES.has(colType)) {
      const dateVal = moment(value);
      return dateVal.isValid() ? dateVal.format(DEFAULT_DATE_FORMAT) : 'Invalid Date';
    }

    return value;
  };

  getChartConfig = () => {
    const { data, xAxis, schema, aggedOnColumn, type } = this.props;
    const columnName = columnNamePostAggregation(aggedOnColumn, type);
    const colByName = _.indexBy(schema, 'name');
    const xAxisCol = colByName[xAxis];

    return {
      type: 'bar',
      data: {
        //Bring in data
        labels: this.props.data.map((obj) => this.formatLabel(obj[xAxis], xAxisCol.type)),
        datasets: [
          {
            label: columnName,
            data: data.map((obj) => obj['agg']),
            backgroundColor: BLUE.primary,
            hoverBackgroundColor: BLUE.secondary,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: xAxis,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                userCallback: function (value: any, index: number, values: any) {
                  return numberWithCommas(value);
                },
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem: any, data: any) {
              var value = data.datasets[0].data[tooltipItem.index];
              return numberWithCommas(value);
            },
          },
        },
      },
    };
  };

  render() {
    return <canvas id="myChart" height="297px" ref={this.chartRef} />;
  }
}

export default BarChartGraph;

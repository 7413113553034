/** @format */

import React from 'react';
import moment from 'moment';
import _ from 'underscore';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { Intent } from '@blueprintjs/core';
import {
  Cell,
  Column,
  ColumnHeaderCell,
  SelectionModes,
  Table,
  TableLoadingOption,
  RenderMode,
  IColumnHeaderRenderer,
} from '@blueprintjs/table';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { TableData, Schema, ColumnInfo } from 'constants/types';
import ColumnHeaderText from 'components/dataTable/columnHeaderText';
import { DATE_TYPES, DEFAULT_DATE_FORMAT } from 'constants/flowConstants';
import { NODE_WIDTH } from 'pages/flowGraphPage/flowNodeV3';

const LOADING_OPTIONS = [TableLoadingOption.CELLS, TableLoadingOption.COLUMN_HEADERS];
export const GENERIC_TABLE_HEIGHT = 277;
const TABLE_CORNER_DIMENSIONS = 30;
const ROW_HEIGHT = 20;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    borderRadius: 4,
    overflow: 'hidden',
  },
  tableHeight: {
    height: GENERIC_TABLE_HEIGHT,
  },
  noBorderRadius: {
    borderRadius: 0,
    borderRight: `1px solid ${theme.palette.grey.lightBorder}`,
  },
  tableColumnHeaderText: {
    display: 'flex',
    alignItems: 'center',
  },
  tableColumnHeaderTypeIcon: {
    marginRight: theme.spacing(2),
  },
  grayedColumn: {
    opacity: 0.3,
  },
  table: {
    flex: 1,
  },
  tableTheme: {
    '& .bp3-table-selection-enabled.bp3-table-column-headers .bp3-table-header': {
      backgroundColor: theme.palette.grey.slight,
    },
    '& .bp3-table-cell': {
      backgroundColor: theme.palette.white,
    },
    '& .bp3-table-menu': {
      backgroundColor: theme.palette.grey.slight,
    },
    '& .bp3-table-row-headers .bp3-table-header': {
      backgroundColor: theme.palette.white,
    },
  },
}));

type Props = {
  className?: any;
  headerList: Schema;
  loading?: boolean;
  maxRows: number;
  rows: TableData;
  selectedColumns?: Set<string>;
  setTableRef?: (ref: any) => void;
  renderCustomNameRenderer?: any;
  disableRowHeader?: boolean;
  extractCellData?: (rowIndex: number, colIndex: number, rows: TableData) => string | number;
  renderCustomColumnHeaderCell?: (
    columnInfo: ColumnInfo,
    renderColumnHeaderTextFunction: any,
    index: number,
  ) => IColumnHeaderRenderer;
  numFrozenColumns?: number;
  preventTimeStampCasting?: boolean;
  grayedColumns?: Set<string>;
  redColumns?: Set<string>;
  fill?: boolean;
  noBorderRadius?: boolean;
  columnWidthFill?: boolean;
  truncateEmptyRowSpace?: boolean;
  unrestrictedHeight?: boolean;
};

const BaseDataTable = (props: Props) => {
  const {
    headerList,
    rows,
    loading,
    renderCustomNameRenderer,
    extractCellData,
    renderCustomColumnHeaderCell,
    preventTimeStampCasting,
    noBorderRadius,
    columnWidthFill,
    truncateEmptyRowSpace,
    unrestrictedHeight,
  } = props;
  const classes = useStyles();

  const getCellData = (rowIndex: number, colIndex: number) => {
    const header = headerList[colIndex].name;
    const cellData = rows[rowIndex][header];
    return cellData === undefined ? '' : cellData;
  };

  const cellRenderer = (rowIndex: number, colIndex: number) => {
    const cellData = extractCellData
      ? extractCellData(rowIndex, colIndex, rows)
      : getCellData(rowIndex, colIndex);
    const column = headerList[colIndex];

    let cellIntent: Intent =
      props.selectedColumns && props.selectedColumns.has(column.name)
        ? Intent.PRIMARY
        : Intent.NONE;
    cellIntent = props.redColumns && props.redColumns.has(column.name) ? Intent.DANGER : cellIntent;

    return <Cell intent={cellIntent}>{sanitizeCellData(cellData, column.type)}</Cell>;
  };

  const sanitizeCellData = (cellData: any | null, colType: string) => {
    if (cellData === null) {
      return '';
    }

    if (_.isNumber(cellData)) {
      return String(Math.round(cellData * 100) / 100);
    }

    if (_.isBoolean(cellData)) {
      return String(cellData);
    }

    if (!preventTimeStampCasting && DATE_TYPES.has(colType)) {
      const dateVal = moment.utc(cellData);
      return dateVal.isValid() ? dateVal.format(DEFAULT_DATE_FORMAT) : 'Invalid Date';
    }

    return String(cellData);
  };

  const renderDefaultNameRenderer = (header: string, index: number) => {
    return (
      <ColumnHeaderText
        headerList={headerList}
        index={index}
        header={header}
        grayOut={props.grayedColumns && props.grayedColumns.has(header)}
      />
    );
  };

  const nameRenderer = (columnName: string, renderColumnHeaderTextFunction: any) => {
    return () => (
      <ColumnHeaderCell
        name={columnName}
        // @ts-ignore
        truncated={false}
        nameRenderer={renderColumnHeaderTextFunction}
      />
    );
  };

  const columns =
    headerList &&
    headerList.map((columnInfo, index) => {
      return (
        <Column
          className={cx({
            [classes.grayedColumn]: props.grayedColumns && props.grayedColumns.has(columnInfo.name),
          })}
          cellRenderer={cellRenderer}
          columnHeaderCellRenderer={
            renderCustomColumnHeaderCell
              ? renderCustomColumnHeaderCell(columnInfo, renderDefaultNameRenderer, index)
              : nameRenderer(columnInfo.name, renderCustomNameRenderer || renderDefaultNameRenderer)
          }
          key={index}
          name={columnInfo.name}
        />
      );
    });

  return (
    <div
      className={cx(classes.root, props.className, {
        [classes.noBorderRadius]: noBorderRadius,
        [classes.tableHeight]: !unrestrictedHeight,
      })}
      style={
        truncateEmptyRowSpace && rows.length < 13
          ? { height: rows.length * ROW_HEIGHT + TABLE_CORNER_DIMENSIONS }
          : undefined
      }>
      <Table
        className={cx({ [classes.table]: props.fill }, classes.tableTheme)}
        renderMode={RenderMode.NONE}
        numFrozenColumns={props.numFrozenColumns}
        enableRowHeader={!props.disableRowHeader}
        numRows={rows ? Math.min(rows.length, props.maxRows) : props.maxRows}
        selectionModes={SelectionModes.COLUMNS_AND_CELLS}
        loadingOptions={loading ? LOADING_OPTIONS : []}
        ref={props.setTableRef}
        getCellClipboardData={(row, col) =>
          getCellData(row, col) === null ? '' : String(getCellData(row, col))
        }
        defaultColumnWidth={
          columnWidthFill && headerList.length < 5
            ? (NODE_WIDTH - 2 - TABLE_CORNER_DIMENSIONS) / headerList.length
            : undefined
        }>
        {columns}
      </Table>
    </div>
  );
};

export default BaseDataTable;

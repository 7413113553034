/** @format */

import React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import _ from 'underscore';
import { ReduxState } from 'reducers/rootReducer';
import { connect } from 'react-redux';
import GraphCanvasSlider from 'components/flowGraph/graphCanvasSlider';

const styles = (theme: Theme) => ({
  root: {
    position: 'relative' as 'relative',
    display: 'flex',
    flex: 1,
    backgroundColor: theme.palette.primary.veryLight,
  },
  scroll: {
    overflow: 'auto',
    display: 'flex',
    flex: 1,
    position: 'relative' as 'relative',
  },
  innerCanvas: {
    position: 'absolute' as 'absolute',
  },
});

type PassedProps = {
  setCanvasYScrollOffset: React.Dispatch<React.SetStateAction<number>>;
  flowDataLoading: boolean;
  children: any;
};

type Props = PassedProps & WithStyles<typeof styles> & ReturnType<typeof mapStateToProps>;

type State = {
  scaleIndex: number;
  scaleList: number[];
  translateX: number;
  translateY: number;
  previousCanvasHeight?: number;
  previousCanvasWidth?: number;
};

class GraphCanvas extends React.Component<Props, State> {
  state: State = {
    scaleIndex: 0,
    scaleList: [1, 0.5, 0.5],
    translateX: 0,
    translateY: 0,
  };
  private graphElement: React.RefObject<HTMLDivElement>;
  private innerCanvas: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    this.adjustScale = _.throttle(this.adjustScale, 250, { trailing: false });
    this.graphElement = React.createRef();
    this.innerCanvas = React.createRef();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.previousCanvasHeight !== this.innerCanvas.current!.clientHeight ||
      prevState.previousCanvasWidth !== this.innerCanvas.current!.clientHeight ||
      prevProps.flowData.datasetNodes.length !== this.props.flowData.datasetNodes.length ||
      Object.keys(prevProps.flowData.flowOperations).length !==
        Object.keys(this.props.flowData.flowOperations).length
    ) {
      let { scaleIndex, scaleList } = this.state;
      scaleList[2] = Math.min(
        Math.min(
          window.innerWidth / this.innerCanvas.current!.clientWidth,
          (window.innerHeight - 80) / this.innerCanvas.current!.clientHeight,
        ),
        1,
      );
      scaleList[1] = scaleList[2] > 0.5 ? scaleList[2] : 0.5;
      scaleIndex = Math.min(scaleIndex, this.getMaxZoomIndex(scaleList));
      const scaleDiff = scaleList[scaleIndex] - 1;
      const translateX = (scaleDiff * this.innerCanvas.current!.clientWidth) / 2;
      const translateY = (scaleDiff * this.innerCanvas.current!.clientHeight) / 2;
      this.setState({
        scaleIndex,
        scaleList,
        translateX,
        translateY,
        previousCanvasWidth: this.innerCanvas.current!.clientHeight,
        previousCanvasHeight: this.innerCanvas.current!.clientHeight,
      });
    }
  }

  componentDidMount() {
    this.graphElement.current!.addEventListener('wheel', this.handleTouchStart);
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    this.graphElement.current!.removeEventListener('wheel', this.handleTouchStart);
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleTouchStart = (e: any) => {
    if (e.ctrlKey) {
      e.preventDefault();
      this.adjustScale(e);
    }
  };

  getMaxZoomIndex = (scaleList: number[]) => {
    if (scaleList[0] === scaleList[1] && scaleList[1] === scaleList[2]) return 0;
    return scaleList[1] === scaleList[2] ? 1 : 2;
  };

  handleKeyDown = (e: any) => {
    if ((e.keyCode === 189 || e.keyCode === 187) && e.metaKey) {
      e.preventDefault();
      let { scaleList, scaleIndex } = this.state;
      if (e.keyCode === 187) {
        scaleIndex = Math.max(scaleIndex - 1, 0);
      } else if (e.keyCode === 189) {
        scaleIndex = Math.min(scaleIndex + 1, this.getMaxZoomIndex(scaleList));
      }
      const scaleDiff = scaleList[scaleIndex] - 1;
      const translateX = (scaleDiff * this.innerCanvas.current!.clientWidth) / 2;
      const translateY = (scaleDiff * this.innerCanvas.current!.clientHeight) / 2;
      this.setState({ scaleIndex, scaleList, translateX, translateY });
    }
  };

  adjustScale = (e: any) => {
    let { scaleIndex, scaleList } = this.state;
    const previousScaleIndex = scaleIndex;
    if (e.deltaY > 0) {
      // zoom out
      scaleIndex = Math.min(scaleIndex + 1, this.getMaxZoomIndex(scaleList));
    } else {
      // zoom in
      scaleIndex = Math.max(scaleIndex - 1, 0);
    }
    const scaleDiff = scaleList[scaleIndex] - 1;
    const translateX = (scaleDiff * this.innerCanvas.current!.clientWidth) / 2;
    const translateY = (scaleDiff * this.innerCanvas.current!.clientHeight) / 2;
    this.setState({ scaleIndex, scaleList, translateX, translateY }, () => {
      this.offsetScale(
        (scaleList[scaleIndex] - scaleList[previousScaleIndex]) / scaleList[previousScaleIndex],
        scaleList[previousScaleIndex],
        e.x,
        e.y,
      );
    });
  };

  offsetScale = (zoomFactor: number, scale: number, cursorPageX: number, cursorPageY: number) => {
    const { cursorGraphX, cursorGraphY } = this.getCursorGraphPositionFromPagePosition(
      cursorPageX,
      cursorPageY,
      scale,
    );
    const deltaX = cursorGraphX * zoomFactor;
    const delyaY = cursorGraphY * zoomFactor;

    this.graphElement.current!.scrollLeft = Math.max(
      this.graphElement.current!.scrollLeft + deltaX * scale,
      0,
    );
    this.graphElement.current!.scrollTop = Math.max(
      this.graphElement.current!.scrollTop + delyaY * scale,
      0,
    );
  };

  getCursorGraphPositionFromPagePosition = (
    cursorPageX: number,
    cursorPageY: number,
    scale: number,
  ) => {
    const cursorGraphX = (this.graphElement.current!.scrollLeft + cursorPageX) * (1 / scale);
    const cursorGraphY = (this.graphElement.current!.scrollTop + cursorPageY) * (1 / scale);
    return { cursorGraphX, cursorGraphY };
  };

  render() {
    const { classes, setCanvasYScrollOffset, flowDataLoading } = this.props;
    const { scaleIndex, scaleList, translateX, translateY } = this.state;

    return (
      <div className={classes.root}>
        <div
          className={classes.scroll}
          ref={this.graphElement}
          id="scrollableCanvas"
          onScroll={() => {
            setCanvasYScrollOffset(document.getElementById('scrollableCanvas')!.scrollTop);
          }}>
          <div
            className={classes.innerCanvas}
            ref={this.innerCanvas}
            style={{
              transform: `translate(${translateX}px, ${translateY}px) scale(${scaleList[scaleIndex]})`,
              width: flowDataLoading ? '100%' : undefined,
              height: flowDataLoading ? '100%' : undefined,
            }}>
            {this.props.children}
          </div>
        </div>
        {!flowDataLoading && (
          <GraphCanvasSlider
            scaleIndex={scaleIndex}
            scaleList={scaleList}
            onChange={(scaleIndex) => {
              let { scaleList } = this.state;
              const scaleDiff = scaleList[scaleIndex] - 1;
              const translateX = (scaleDiff * this.innerCanvas.current!.clientWidth) / 2;
              const translateY = (scaleDiff * this.innerCanvas.current!.clientHeight) / 2;
              this.setState({ scaleIndex, scaleList, translateX, translateY });
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  flowData: state.flowData,
});

export default connect(mapStateToProps, {})(withStyles(styles)(GraphCanvas));

/** @format */

import { AggregationColumnInfo, DedupOperationInstructions } from 'constants/types';
import _ from 'underscore';
import { OperationFooterErrorInformation } from 'utils/operationErrorInfoUtils/generalOperationErrorInfoUtil';
import { getPivotRowErrorInformation } from 'utils/operationErrorInfoUtils/pivotOperationErrorInfoUtil';

enum FOOTER_ERROR_TEXT {
  NO_ERROR = 'No Error',
  FILL_ROWS = 'Please fill in missing rows',
  SELECT_COLUMN_TO_SORT_BY = 'Please select atleast one column to sort by',
}

export const getDedupOperationFooterErrorInformation = (
  instructions: DedupOperationInstructions,
): OperationFooterErrorInformation => {
  const operationFooterErrorInformation: OperationFooterErrorInformation = {
    footerErrorState: false,
    footerErrorText: FOOTER_ERROR_TEXT.NO_ERROR,
  };
  if (instructions.isAllColsSelected) return operationFooterErrorInformation;

  if (
    !!_.find(instructions.colsToDedup, (dedupCol: AggregationColumnInfo, index) => {
      const { isPivotColButtonErrorState, isDateButtonErrorState } = getPivotRowErrorInformation(
        dedupCol,
        index,
      );
      return isPivotColButtonErrorState || isDateButtonErrorState;
    })
  ) {
    operationFooterErrorInformation.footerErrorState = true;
    operationFooterErrorInformation.footerErrorText = FOOTER_ERROR_TEXT.FILL_ROWS;
    return operationFooterErrorInformation;
  }

  if (instructions.sortColumns[0].column === null) {
    operationFooterErrorInformation.footerErrorState = true;
    operationFooterErrorInformation.footerErrorText = FOOTER_ERROR_TEXT.SELECT_COLUMN_TO_SORT_BY;
  }

  return operationFooterErrorInformation;
};

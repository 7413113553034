/** @format */

import * as Redux from 'redux';
import { User, Team } from 'actions/types';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

export const verifyEmail = () => (dispatch: Redux.Dispatch<any>) => {
  dispatch({
    type: 'VERIFY_EMAIL',
    payload: {},
  });
};

export const logoutUser = () => ({
  type: 'LOGOUT_USER',
  payload: {},
});

const loginUserRequest = () => ({
  type: 'LOGIN_USER_REQUEST',
});

const loginUserError = (errorMsg: string) => ({
  type: 'LOGIN_USER_ERROR',
  payload: errorMsg,
});

export const loginUserSuccess = (user: User) => ({
  type: 'LOGIN_USER_SUCCESS',
  payload: user,
});

export function loginUser(
  email: string,
  password: string,
  successCallback: (user: User) => void,
  errorCallback: (response: Object) => void,
) {
  return function (dispatch: Redux.Dispatch<any>) {
    dispatch(loginUserRequest());
    return fetch(process.env.REACT_APP_API_URL + 'rest-auth/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (!data.key) {
          dispatch(loginUserError(''));
          errorCallback(data);
        } else {
          Cookies.set('spheres_auth_token', data.key);
          dispatch(loginUserSuccess(data.user));
          successCallback(data.user);
        }
      });
  };
}

const registerUserRequest = () => ({
  type: 'REGISTER_USER_REQUEST',
});

const registerUserError = (errorMsg: string) => ({
  type: 'REGISTER_USER_ERROR',
  payload: errorMsg,
});

const registerUserSuccess = (user: User) => ({
  type: 'REGISTER_USER_SUCCESS',
  payload: user,
});

export function registerUser(
  first_name: string,
  last_name: string,
  email: string,
  password1: string,
  password2: string,
  sucessCallback: (user: User) => void,
  errorCallback: (response: Object) => void,
) {
  return function (dispatch: Redux.Dispatch<any>) {
    dispatch(registerUserRequest());
    return fetch(process.env.REACT_APP_API_URL + 'rest-auth/registration/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        first_name: first_name,
        last_name: last_name,
        email: email,
        password1: password1,
        password2: password2,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (!data.key) {
          dispatch(registerUserError(''));
          errorCallback(data);
        } else {
          Cookies.set('spheres_auth_token', data.key);
          const user = data.user;
          const userId = data.user.id;

          fetch(process.env.REACT_APP_API_URL + 'signup/user/' + userId + '/create_root_folder/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Token ' + data.key,
            },
          })
            .then((resp) => resp.json())
            .then((data) => {
              dispatch(registerUserSuccess(user));
              sucessCallback(user);
            });
        }
      });
  };
}

const createTeamRequest = () => ({
  type: 'CREATE_TEAM_REQUEST',
});

const createTeamError = () => ({
  type: 'CREATE_TEAM_ERROR',
});

const createTeamSuccess = (team: Team) => ({
  type: 'CREATE_TEAM_SUCCESS',
  payload: {
    team,
  },
});

export const createTeam = (
  teamName: string,
  onSuccess: () => void,
  onError: (errorMsg: string) => void,
) => {
  return function (dispatch: Redux.Dispatch<any>) {
    dispatch(createTeamRequest());
    return fetch(process.env.REACT_APP_API_URL + 'teams/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + Cookies.get('spheres_auth_token'),
      },
      body: JSON.stringify({
        team_name: teamName,
        join_team: true,
      }),
    })
      .then((resp) => {
        if (resp.status && resp.status >= 400) {
          throw resp.statusText;
        }
        return resp.json();
      })
      .then((data) => {
        if (!data.success) {
          dispatch(createTeamError());
          onError(data.msg);
        } else {
          createTeamSuccess(data);
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(createTeamError());
        onError(error);
      });
  };
};

const addUserToTeamRequest = () => ({
  type: 'ADD_USER_TO_TEAM_REQUEST',
});

const addUserToTeamError = () => ({
  type: 'ADD_USER_TO_TEAM_ERROR',
});

const addUserToTeamSuccess = (team: Team) => ({
  type: 'ADD_USER_TO_TEAM_SUCCESS',
  payload: {
    team,
  },
});

export const addUserToTeam = (
  userId: number | string,
  inviteCode: string,
  onSuccess: () => void,
  onError: (errorMsg: any) => void,
) => {
  return function (dispatch: Redux.Dispatch<any>) {
    dispatch(addUserToTeamRequest());
    return axios
      .request<any>({
        url: process.env.REACT_APP_API_URL + 'teams/add_team_member/',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + Cookies.get('spheres_auth_token'),
        },
        data: {
          invite_code: inviteCode,
          user_id: userId,
        },
      })
      .then((response: AxiosResponse) => {
        const { data } = response;
        if (!data.success) {
          dispatch(addUserToTeamError());
          onError(data.msg);
        } else {
          addUserToTeamSuccess(data);
          onSuccess();
        }
      })
      .catch((error: AxiosError) => {
        dispatch(addUserToTeamError());
        onError(error.response);
      });
  };
};

/** @format */

import { ColumnToJoinOn, EnrichOperationInstructions, ColumnInfo } from 'constants/types';
import _ from 'underscore';
import { OperationFooterErrorInformation } from 'utils/operationErrorInfoUtils/generalOperationErrorInfoUtil';

enum FOOTER_ERROR_TEXT {
  NO_ERROR = 'No Error',
  SELECT_TABLE = 'Please select a table to join with',
  FILL_ROWS = 'Please fill in the incomplete rows to submit',
  DIFF_TYPE = 'Please ensure the join columns are the same type',
}

export interface JoinOperationRowErrorInformation {
  isSourceColButtonErrorState: boolean;
  isJoinColButtonErrorState: boolean;
  errorText?: string;
}

export const getJoinOperationRowErrorInformation = (
  columnToJoinOn: ColumnToJoinOn,
  index: number,
  sourceSchemaByColumnName: _.Dictionary<ColumnInfo>,
  joinSchemaByColumnName: _.Dictionary<ColumnInfo>,
): JoinOperationRowErrorInformation => {
  // For the first row, both dropdowns need to be selected
  let joinOperationRowErrorInformation: JoinOperationRowErrorInformation = {
    isSourceColButtonErrorState: false,
    isJoinColButtonErrorState: false,
  };

  if (columnsHaveDiffTypes(columnToJoinOn, sourceSchemaByColumnName, joinSchemaByColumnName)) {
    joinOperationRowErrorInformation.isSourceColButtonErrorState = true;
    joinOperationRowErrorInformation.isJoinColButtonErrorState = true;
    joinOperationRowErrorInformation.errorText = FOOTER_ERROR_TEXT.DIFF_TYPE;
    return joinOperationRowErrorInformation;
  }

  const colIncompleteErrorState = columnsAreIncomplete(columnToJoinOn, index);
  if (colIncompleteErrorState.sourceCol || colIncompleteErrorState.joinCol) {
    joinOperationRowErrorInformation.isSourceColButtonErrorState =
      colIncompleteErrorState.sourceCol;
    joinOperationRowErrorInformation.isJoinColButtonErrorState = colIncompleteErrorState.joinCol;
    joinOperationRowErrorInformation.errorText = FOOTER_ERROR_TEXT.FILL_ROWS;
  }

  return joinOperationRowErrorInformation;
};

const columnsAreIncomplete = (columnToJoinOn: ColumnToJoinOn, index: number) => {
  let colErrorState = {
    sourceCol: columnToJoinOn.sourceColumn === '',
    joinCol: columnToJoinOn.joinColumn === '',
  };

  // After the first row, a dropdown only shows an error if the other one is selected
  if (index !== 0) {
    colErrorState.sourceCol = colErrorState.sourceCol && columnToJoinOn.joinColumn !== '';
    colErrorState.joinCol = colErrorState.joinCol && columnToJoinOn.sourceColumn !== '';
  }
  return colErrorState;
};

const columnsHaveDiffTypes = (
  columnToJoinOn: ColumnToJoinOn,
  sourceSchemaByColumnName: _.Dictionary<ColumnInfo>,
  joinSchemaByColumnName: _.Dictionary<ColumnInfo>,
) => {
  if (
    !columnToJoinOn.joinColumn ||
    columnToJoinOn.joinColumn === '' ||
    !columnToJoinOn.sourceColumn ||
    columnToJoinOn.sourceColumn === ''
  ) {
    return false;
  }

  const joinType = joinSchemaByColumnName[columnToJoinOn.joinColumn].type;
  const sourceType = sourceSchemaByColumnName[columnToJoinOn.sourceColumn].type;
  return joinType !== sourceType;
};

const footerErrorPrecedence = (errorsToColIndex: { [error: string]: number[] }) => {
  if (errorsToColIndex[FOOTER_ERROR_TEXT.FILL_ROWS].length > 0) {
    return FOOTER_ERROR_TEXT.FILL_ROWS;
  } else if (errorsToColIndex[FOOTER_ERROR_TEXT.DIFF_TYPE].length > 0) {
    return FOOTER_ERROR_TEXT.DIFF_TYPE;
  } else {
    return null;
  }
};

export const getJoinOperationFooterErrorInformation = (
  instructions: EnrichOperationInstructions,
  sourceSchemaByColumnName: _.Dictionary<ColumnInfo>,
  joinSchemaByColumnName: _.Dictionary<ColumnInfo>,
): OperationFooterErrorInformation => {
  const operationFooterErrorInformation: OperationFooterErrorInformation = {
    footerErrorState: false,
    footerErrorText: FOOTER_ERROR_TEXT.NO_ERROR,
    errorsToColIndex: {
      [FOOTER_ERROR_TEXT.FILL_ROWS]: [],
      [FOOTER_ERROR_TEXT.DIFF_TYPE]: [],
    },
  };
  if (instructions.joinTable.name === '') {
    return {
      footerErrorState: true,
      footerErrorText: FOOTER_ERROR_TEXT.SELECT_TABLE,
    };
  } else {
    _.each(instructions.columnsToJoinOn, (columnToJoinOn, index) => {
      const { errorText } = getJoinOperationRowErrorInformation(
        columnToJoinOn,
        index,
        sourceSchemaByColumnName,
        joinSchemaByColumnName,
      );
      if (errorText) {
        if (errorText === FOOTER_ERROR_TEXT.FILL_ROWS) {
          operationFooterErrorInformation.errorsToColIndex![FOOTER_ERROR_TEXT.FILL_ROWS].push(
            index,
          );
        } else if (errorText === FOOTER_ERROR_TEXT.DIFF_TYPE) {
          operationFooterErrorInformation.errorsToColIndex![FOOTER_ERROR_TEXT.DIFF_TYPE].push(
            index,
          );
        }
      }
    });

    const footerErrorText = footerErrorPrecedence(
      operationFooterErrorInformation.errorsToColIndex!,
    );
    if (footerErrorText) {
      operationFooterErrorInformation.footerErrorState = true;
      operationFooterErrorInformation.footerErrorText = footerErrorText;
    }
  }
  return operationFooterErrorInformation;
};

/** @format */

import React from 'react';
import { IconName, Icon } from '@blueprintjs/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

const BUTTON_SIZE = 24;
const ICON_SIZE = 18;

const useStyles = makeStyles((theme: Theme) => ({
  addStepIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: -BUTTON_SIZE / 2,
    left: `calc(50% - 10px)`,
    zIndex: 1,
    borderRadius: '50%',
    height: BUTTON_SIZE,
    width: BUTTON_SIZE,
    backgroundColor: theme.palette.darkBlue,
    '&:hover': {
      opacity: '100%',
      cursor: 'pointer',
    },
  },
}));

type Props = {
  openAddStepModal: () => void;
};

const FlowGraphAddStepButton = (props: Props) => {
  const classes = useStyles();
  const { openAddStepModal } = props;
  return (
    <div onClick={openAddStepModal} className={classes.addStepIconContainer}>
      <Icon
        onClick={openAddStepModal}
        icon={'plus' as IconName}
        iconSize={ICON_SIZE}
        color={'#ffffff'}
      />
    </div>
  );
};

export default FlowGraphAddStepButton;

/** @format */

import { Button, Menu, MenuItem, MenuDivider } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import cx from 'classnames';
import { SelectedDropdownInputItem } from 'constants/types';
import React from 'react';
import _ from 'underscore';

const useStyles = makeStyles((theme: Theme) => ({
  menuList: {
    maxHeight: 200,
    overflow: 'auto',
  },
  placeholderText: {
    opacity: '0.72',
  },
  buttonErrorState: {
    boxShadow: `inset 0 0 0 1px ${theme.palette.dangerRed} !important`,
  },
  dropdownBtn: {
    '& .bp3-button-text': {
      maxWidth: 150,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

type Props = {
  className?: any;
  buttonErrorState?: boolean;
  selectedItem?: SelectedDropdownInputItem;
  onChange: (item: any) => void;
  options: SelectedDropdownInputItem[];
  noSelectionText: string;
  isSourceDataset?: boolean;
  filterable?: boolean;
  showIcon?: boolean;
  disabled?: boolean;
  selectedOptionNames?: Set<string>;
  hideBtnCaret?: boolean;
  dontShiftSelected?: boolean;
};

const OperationDropdownInput = (props: Props) => {
  const classes = useStyles();
  const simpleFilterPredicate = (query: any, item: any) => {
    return item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  const itemRenderer = (item: any, { handleClick, modifiers, query }: any) => {
    let itemText;
    if (query.length > 0) {
      const text = item.name.split(query);
      itemText = [];
      for (let i = 0; i <= text.length - 2; i++) {
        itemText.push(text[i]);
        itemText.push(<b key={_.uniqueId('query_text')}>{query}</b>);
      }
      itemText.push(text[text.length - 1]);
    } else {
      itemText = item.name;
    }

    if (item.type === 'divider') {
      return <MenuDivider title={item.name} key={_.uniqueId('menu_divider_')} />;
    }

    return (
      <MenuItem
        active={modifiers.active}
        text={itemText}
        key={_.uniqueId(`${item.id}_`)}
        onClick={handleClick}
        icon={item.icon}
      />
    );
  };

  const itemListRenderer = ({ filteredItems, items, query, renderItem, itemsParentRef }: any) => {
    let itemsList;
    if (filteredItems.length === 0) {
      itemsList = <MenuItem disabled={true} text="No results." />;
    } else if (query.length === 0) {
      itemsList = items.map((item: any) => renderItem(item));
    } else {
      itemsList = filteredItems.map((item: any) => renderItem(item));
    }

    return (
      <Menu className={classes.menuList} ulRef={itemsParentRef}>
        {itemsList}
      </Menu>
    );
  };

  const getItemsList = () => {
    const { options, selectedOptionNames, dontShiftSelected, selectedItem } = props;
    let filteredOptions = options;

    if (selectedOptionNames) {
      filteredOptions = options.filter(
        (col: any) =>
          !selectedOptionNames.has(col.name) || (selectedItem && col.name === selectedItem.name),
      );
    }
    if (selectedItem && !dontShiftSelected) {
      filteredOptions = _.without(
        filteredOptions,
        _.findWhere(filteredOptions, { name: selectedItem.name })!,
      );

      filteredOptions.unshift(selectedItem);
    }
    return filteredOptions;
  };

  return (
    <Select
      className={props.className}
      disabled={props.disabled || props.isSourceDataset}
      items={getItemsList()}
      itemPredicate={simpleFilterPredicate}
      itemRenderer={itemRenderer}
      itemListRenderer={itemListRenderer}
      noResults={<MenuItem disabled={true} text="No results." />}
      onItemSelect={props.onChange}
      filterable={props.filterable === undefined ? true : props.filterable}>
      <Button
        className={cx(
          classes.dropdownBtn,
          { [classes.placeholderText]: !props.selectedItem },
          { [classes.buttonErrorState]: props.buttonErrorState },
        )}
        icon={props.showIcon ? props.selectedItem && props.selectedItem.icon : undefined}
        rightIcon={props.hideBtnCaret ? undefined : 'caret-down'}
        text={props.selectedItem ? props.selectedItem.name : props.noSelectionText}
        disabled={props.disabled || props.isSourceDataset}
      />
    </Select>
  );
};

export default OperationDropdownInput;

/** @format */
import cloneDeep from 'lodash/cloneDeep';
import { Action } from 'reducers/rootReducer';

interface pythonObjectDictionary {
  [property: string]: string;
}

export interface SupportedDataSourcesReducerState {
  loading: boolean;
  sources?: pythonObjectDictionary; // a python class converted to a dictionary
}
const supportedDataSourcesReducerInitialState: SupportedDataSourcesReducerState = {
  loading: false,
};

export default (
  state: SupportedDataSourcesReducerState = supportedDataSourcesReducerInitialState,
  action: Action,
) => {
  const newState = cloneDeep(state);
  const { payload } = action;

  switch (action.type) {
    case 'FETCH_SUPPORTED_DATA_SOURCES_REQUEST':
      newState.loading = true;
      return newState;
    case 'FETCH_SUPPORTED_DATA_SOURCES_SUCCESS':
      newState.loading = false;
      newState.sources = payload.supported_sources;
      return newState;
    default:
      return state;
  }
};

/** @format */

import React, { Component } from 'react';
import _ from 'underscore';
import cx from 'classnames';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/styles';
import { withRouter, Link } from 'react-router-dom';
import { Button, Popover, Menu, MenuItem, Icon, H6, Intent, Spinner } from '@blueprintjs/core';
import { trackEvent } from 'analytics/exploAnalytics';

import WorkspaceSearchBar from 'components/pages/workspaceSearchBar';
import DataSourceMenuItem from 'components/fileSystem/dataSourceMenuItem';
import { createStyles } from '@material-ui/core/styles';

import ExploLogo from 'images/branding/logo_primary.png';
import { logoutUser } from 'actions/authAction';
import { ROUTES } from 'constants/routes';
import { updateSetupFlowStepKey } from 'actions/onboardingActions';
import { SetupFlowStepKey } from 'constants/setupPageConstants';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { RouteComponentProps } from 'react-router';
import { DashboardInfo } from 'reducers/dashboardReducer';
import { updateWorkspaceSearchState } from 'actions/dataSourceActions';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 250,
      height: '100vh',
      backgroundColor: theme.palette.grey900,
      boxShadow: theme.customShadows.sideNav,
      zIndex: 1,
    },
    leftNavBody: {
      height: 'calc(100vh - 90px)',
      overflow: 'auto',
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    },
    logoImg: {
      width: 100,
      marginBottom: -5,
    },
    dataSourceSelector: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
    searchBarButton: {
      color: theme.palette.grey.blueprintMutedGray,
      padding: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
      '&:hover': {
        color: theme.palette.grey.blueprintDarkWhite,
        backgroundImage: 'linear-gradient(90deg,rgba(48,64,77,0) 0,#30404d 40%)',
        cursor: 'pointer',
      },
    },
    searchText: {
      marginLeft: theme.spacing(2),
    },
    navDivider: {
      backgroundImage: 'linear-gradient(90deg,rgba(167,182,194,0) 0,rgba(167,182,194,.4) 40%)',
      width: '100%',
      height: 1,
    },
    searchBar: {
      top: '20vh',
      left: 'calc(50% - 250px)',
      zIndex: 20,
      width: 500,
    },
    sectionTitle: {
      color: `${theme.palette.grey.blueprintMutedGray} !important`,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      padding: `0 ${theme.spacing(3)}px`,
    },
    sectionTitleIcon: {
      marginRight: theme.spacing(2),
    },
    sectionMenu: {
      backgroundColor: 'inherit !important',
    },
    addDataSourceButton: {
      color: `${theme.palette.grey.blueprintMutedGray} !important`,
    },
    sectionMenuSelected: {
      backgroundColor: 'rgba(255,255,255,.16)',
    },
    leftNavLinks: {
      color: 'inherit !important',
      '&:hover': {
        textDecoration: 'none',
      },
      '&:active': {},
    },
    dashboardMenuItemContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flex: 1,
    },
  });

type PassedProps = {
  dataSourceList: any[];
  dashboardsLoading: boolean;
  dashboardsList?: DashboardInfo[];
  selectedDataSourceId: number | null;
  selectedDashBoardId?: number | null;
  switchWorkspaces: any;
  workspaceData: any;
};

type Props = PassedProps &
  WithStyles<typeof styles> &
  typeof mapDispatchToProps &
  RouteComponentProps;

type State = {
  searchOpen: boolean;
};

class LeftNav extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchOpen: false,
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={cx(classes.root, 'bp3-dark')}>
        {this.renderLogoContainer()}
        <div className={classes.navDivider}></div>
        {this.renderSearchBar()}
        {this.renderSearchOmniBar()}
        <div className={classes.navDivider}></div>
        <div className={classes.leftNavBody}>
          {this.renderDataSourceList()}
          <div className={classes.navDivider}></div>
          {this.renderDashboardList()}
        </div>
      </div>
    );
  }

  renderLogoContainer = () => {
    const { classes, logoutUser } = this.props;

    return (
      <div className={classes.logoContainer}>
        <Link to={ROUTES.HOME_APP_PAGE}>
          <img src={ExploLogo} className={classes.logoImg} alt="explo logo" />
        </Link>
        <div>
          <Popover>
            <Button icon="user" minimal={true}></Button>
            <Menu key="user-menu">
              <MenuItem
                icon="people"
                text="Team Settings"
                onClick={() => this.props.history.push(`/team`)}
              />
              <MenuItem
                icon="help"
                text="Help Center"
                href="http://help.explo.co/"
                target="_blank"
                onClick={() =>
                  trackEvent('Help Center Clicked', {
                    source: 'Left Nav',
                    type: 'help_center_home',
                  })
                }
              />
              <MenuItem icon="log-out" text="Logout" onClick={() => logoutUser()} />
              {process.env.NODE_ENV === 'development' && (
                <MenuItem
                  icon="refresh"
                  text="Refresh Setup"
                  onClick={() => this.takeUserToBeginningOfSetup()}
                />
              )}
            </Menu>
          </Popover>
        </div>
      </div>
    );
  };

  takeUserToBeginningOfSetup = () => {
    const { updateSetupFlowStepKey, history } = this.props;
    updateSetupFlowStepKey(
      {
        postData: { updated_setup_flow_key: SetupFlowStepKey.WELCOME_TO_EXPLO },
      },
      () => {
        history.push('/setup');
      },
    );
  };

  renderSearchBar = () => {
    const { classes } = this.props;
    return (
      <div className={classes.searchBarButton} onClick={() => this.toggleSearchOpen(true)}>
        <Icon icon="search" />
        <span className={classes.searchText}>Search Workspace...</span>
      </div>
    );
  };

  renderDataSourceList = () => {
    const { classes, dataSourceList, selectedDataSourceId } = this.props;
    return (
      <>
        <H6 className={classes.sectionTitle}>
          <Icon icon="database" className={classes.sectionTitleIcon} /> Data Sources
        </H6>
        <Menu className={classes.sectionMenu}>
          {dataSourceList.map((dataSource) => (
            <DataSourceMenuItem
              key={`datasource-menu-item-${dataSource.id}`}
              dataSource={dataSource}
              selectedDataSourceId={selectedDataSourceId}
              switchWorkspaces={this.props.switchWorkspaces}
              dataSourceList={dataSourceList}
            />
          ))}
          <MenuItem
            className={classes.addDataSourceButton}
            icon="plus"
            text="Add Data Source"
            onClick={() => {
              this.props.history.push('/add-data-source');
            }}
          />
        </Menu>
      </>
    );
  };

  renderDashboardList = () => {
    const { classes, dashboardsLoading, dashboardsList, selectedDashBoardId } = this.props;

    if (dashboardsLoading) {
      return;
    }
    const orderedDashboards = _.sortBy(dashboardsList!, (dashboard) => dashboard.name);

    return (
      <>
        <H6 className={classes.sectionTitle}>
          <Icon icon="timeline-line-chart" className={classes.sectionTitleIcon} />
          Dashboards
        </H6>
        <Menu className={classes.sectionMenu}>
          {orderedDashboards &&
            orderedDashboards.map((dashboard) => (
              <Link
                className={cx(
                  {
                    [classes.sectionMenuSelected]: selectedDashBoardId === dashboard.id,
                  },
                  'bp3-menu-item bp3-text-overflow-ellipsis bp3-fill',
                  classes.leftNavLinks,
                )}
                key={`left-nav-dashboard-${dashboard.id}`}
                to={`/dashboards/${dashboard.id}`}>
                <div className={classes.dashboardMenuItemContainer}>
                  {dashboard.name}
                  {dashboard.loading && <Spinner intent={Intent.PRIMARY} size={20} />}
                </div>
              </Link>
            ))}
        </Menu>
      </>
    );
  };

  renderSearchOmniBar = () => {
    if (!this.props.workspaceData || !this.state.searchOpen) {
      return;
    }
    return (
      <WorkspaceSearchBar
        isOpen={this.state.searchOpen}
        onClose={() => this.toggleSearchOpen(false)}
        workspaceData={this.props.workspaceData}
        dashboardsList={this.props.dashboardsList}
      />
    );
  };

  toggleSearchOpen = (searchOpen: boolean) => {
    const { updateWorkspaceSearchState } = this.props;
    this.setState({ searchOpen });
    updateWorkspaceSearchState(searchOpen);
  };
}

const mapDispatchToProps = {
  logoutUser,
  updateSetupFlowStepKey,
  updateWorkspaceSearchState,
};

export default withRouter(connect(undefined, mapDispatchToProps)(withStyles(styles)(LeftNav)));

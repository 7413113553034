/** @format */

import React, { Component } from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import {
  updateDashboardLayout,
  updateOperationTitle,
  removeOperationFromDashboard,
} from 'actions/dashboardActions';

import GraphBuilderBlock from 'components/flowBuilder/graphBuilderBlock';
import StepBuilderBlock from 'components/flowBuilder/stepBuilderBlock';

import { GRAPH_OPERATIONS_SET } from 'constants/flowConstants';
import { DashboardFilter } from 'actions/types';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const ResponsiveGridLayout = WidthProvider(Responsive);

const styles = (theme: Theme) => ({
  root: {
    maxWidth: 1100,
    margin: 'auto',
    marginTop: theme.spacing(12),
    padding: `0 ${theme.spacing(12)}px`,
    paddingBottom: theme.spacing(12),
  },
  dashboardElement: {
    backgroundColor: theme.palette.white,
    borderRadius: 4,
    border: `1px solid ${theme.palette.grey.border}`,
  },
});

type PassedProps = {
  viewOnly?: boolean;
  dashboardData: any;
  refreshDashboardPanel?: (operationId: number) => void;
  editFiltersOn?: boolean;
  editingFilter?: DashboardFilter;
  onFilterColumnSelect?: (
    filterId: number,
    operationId: number,
    colName: string,
    colType: string,
    sourceType: string,
  ) => void;
};

type Props = PassedProps & WithStyles<typeof styles> & typeof mapDispatchToProps;

export class DashboardLayout extends Component<Props> {
  render = () => {
    const { classes, dashboardData, viewOnly } = this.props;
    const operations = dashboardData.operations;
    const gridConfig = dashboardData.grid_configuration;
    const configById = gridConfig && _.indexBy(gridConfig, 'i');

    return (
      <div className={classes.root}>
        <ResponsiveGridLayout
          className="layout"
          breakpoints={{ lg: 770, sm: 0 }}
          cols={{ lg: 2, sm: 1 }}
          rowHeight={380}
          draggableCancel="input,textarea,.bp3-popover-wrapper"
          isDraggable={!viewOnly}
          isResizable={!viewOnly}
          onLayoutChange={this.handleLayoutChanged}>
          {operations.map((op: any) =>
            this.renderGridOperation(op, configById && configById[`operation-${op.id}`]),
          )}
        </ResponsiveGridLayout>
      </div>
    );
  };

  renderGridOperation = (operation: any, gridLayout: any) => {
    const {
      classes,
      viewOnly,
      refreshDashboardPanel,
      editFiltersOn,
      editingFilter,
      onFilterColumnSelect,
    } = this.props;
    const opGridKey = `operation-${operation.id}`;
    const opLayout = gridLayout || {
      i: opGridKey,
      x: 0,
      y: 99,
      w: 2,
      h: 1,
    };
    opLayout.maxH = 1;

    const dashboardFilterProps = {
      editFiltersOn: editFiltersOn,
      editingFilterType: editingFilter && editingFilter.filter_type,
      editingFilterCol: editingFilter &&
        editingFilter.operation_fields[operation.id] && {
          name: editingFilter.operation_fields[operation.id].column_name,
          type: editingFilter.operation_fields[operation.id].column_type,
          sourceType: editingFilter.operation_fields[operation.id].source_type,
        },
      onFilterColumnSelect: (colName: string, colType: string, sourceType: string) =>
        editingFilter &&
        onFilterColumnSelect &&
        onFilterColumnSelect(editingFilter.id, operation.id, colName, colType, sourceType),
    };

    let operationView = null;
    if (GRAPH_OPERATIONS_SET.has(operation.operation_type)) {
      operationView = (
        // @ts-ignore
        <GraphBuilderBlock
          operationId={operation.id}
          graphData={operation.results_dataset && operation.results_dataset.cached_preview}
          operationInstructions={operation.instructions}
          operationType={operation.operation_type}
          resultsJobError={operation.resultsJobError}
          resultSchema={operation.results_dataset && operation.results_dataset.cached_schema}
          flowId={operation.flow_id}
          dashboardView={true}
          updateOperationTitle={(newTitle: string) =>
            this.updateOperationTitle(newTitle, operation.id)
          }
          removeOperationFromDashboard={() => this.removeOperationFromDashboard(operation.id)}
          title={operation.title}
          upToDate={operation.up_to_date}
          computeLoading={operation.loading}
          viewOnly={viewOnly}
          refreshDashboardPanel={refreshDashboardPanel}
          {...dashboardFilterProps}
        />
      );
    } else {
      operationView = (
        // @ts-ignore
        <StepBuilderBlock
          operationId={operation.id}
          operationInstructions={operation.instructions}
          operationType={operation.operation_type}
          resultsJobError={operation.resultsJobError}
          previewData={operation.results_dataset && operation.results_dataset.cached_preview}
          resultSchema={operation.results_dataset && operation.results_dataset.cached_schema}
          numRows={operation.results_dataset && operation.results_dataset.total_row_count}
          dashboardView={true}
          flowId={operation.flow_id}
          updateOperationTitle={(newTitle: string) =>
            this.updateOperationTitle(newTitle, operation.id)
          }
          removeOperationFromDashboard={() => this.removeOperationFromDashboard(operation.id)}
          title={operation.title}
          upToDate={operation.up_to_date}
          computeLoading={operation.loading}
          viewOnly={viewOnly}
          refreshDashboardPanel={refreshDashboardPanel}
          {...dashboardFilterProps}
        />
      );
    }

    return (
      <div key={opGridKey} data-grid={opLayout}>
        <div className={classes.dashboardElement}>{operationView}</div>
      </div>
    );
  };

  handleLayoutChanged = (layout: any) => {
    const { viewOnly, dashboardData, updateDashboardLayout } = this.props;

    if (viewOnly) {
      return;
    }
    if (this.layoutChanged(dashboardData.grid_configuration, layout)) {
      updateDashboardLayout({
        id: dashboardData.id,
        postData: { layout_config: layout },
      });
    }
  };

  layoutChanged = (oldLayout: any, newLayout: any) => {
    const oldConfigById = (oldLayout && _.indexBy(oldLayout, 'i')) || {};
    const newConfigById = (newLayout && _.indexBy(newLayout, 'i')) || {};

    if (Object.keys(oldConfigById).length !== Object.keys(newConfigById).length) {
      return true;
    }

    return _.any(
      Object.keys(oldConfigById).map(
        (configId) => !_.isMatch(newConfigById[configId], oldConfigById[configId]),
      ),
    );
  };

  updateOperationTitle = (newTitle: string, operationId: number) => {
    const { dashboardData, updateOperationTitle } = this.props;

    updateOperationTitle({
      id: dashboardData.id,
      postData: { title: newTitle, operation_id: operationId },
    });
  };

  removeOperationFromDashboard = (operationId: number) => {
    const { dashboardData, removeOperationFromDashboard } = this.props;

    removeOperationFromDashboard({
      id: dashboardData.id,
      postData: { operation_id: operationId },
    });
  };
}

const mapDispatchToProps = {
  updateDashboardLayout,
  updateOperationTitle,
  removeOperationFromDashboard,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(DashboardLayout));

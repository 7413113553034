/** @format */

import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

import SharedPageWithLeftNav from 'components/pages/sharedPageWithLeftNav';
import PageWithLeftNav from 'components/pages/pageWithLeftNav';

type Props = any;

const ShareRoute = (props: Props) => {
  const { Component, currentUser, ...rest } = props;
  const routeComponent = <Route {...rest} render={(props) => <Component {...props} />} />;

  if (currentUser.id === null || currentUser.id === undefined) {
    return <SharedPageWithLeftNav>{routeComponent}</SharedPageWithLeftNav>;
  }
  return <PageWithLeftNav>{routeComponent}</PageWithLeftNav>;
};

const mapStateToProps = (state: ReduxState) => ({
  currentUser: state.currentUser,
});

export default connect(mapStateToProps)(ShareRoute);

/** @format */

import React from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 48,
    padding: `0 ${theme.spacing(4)}px`,
    borderRadius: 4,
    backgroundColor: theme.palette.grey400,
    color: theme.palette.white,
    border: 'none',
    fontSize: 14,

    '&::placeholder': {
      color: theme.palette.white,
    },
  },
}));

type Props = {
  className?: string;
  placeholder: string;
  value?: string;
  onChange?: (e: any) => void;
  type?: string;
};

const InputField = (props: Props) => {
  const classes = useStyles();
  return (
    <input
      className={cx(props.className, classes.root)}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      type={props.type}
    />
  );
};

export default InputField;

/** @format */

import React, { ReactNode } from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  imgContainer: {
    position: 'relative',
  },
  imgMask: {
    width: 170,
    height: 170,
    backgroundColor: '#0D1A33',
    mixBlendMode: 'color',
  },
  faceImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 170,
    borderRadius: 4,
    zIndex: -1,
  },
  nameText: {
    color: theme.palette.white,
    fontSize: 24,
    marginTop: theme.spacing(5),
    fontWeight: 'bold',
  },
  descriptionText: {
    color: theme.palette.grey100,
    fontSize: 16,
    marginTop: theme.spacing(3),
  },
}));

type Props = {
  className?: string;
  imgUrl: string;
  name: string;
  description: ReactNode;
};

const AboutUsPersonInfo = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.root, props.className)}>
      <div className={classes.imgContainer}>
        <div className={classes.imgMask}></div>
        <img className={classes.faceImg} src={props.imgUrl} alt="person face shot" />
      </div>
      <div className={classes.nameText}>{props.name}</div>
      <div className={classes.descriptionText}>{props.description}</div>
    </div>
  );
};

export default AboutUsPersonInfo;

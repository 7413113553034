/** @format */

import React from 'react';
import _ from 'underscore';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Popover, Position, Button } from '@blueprintjs/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 220,
      backgroundColor: 'white',
      borderRadius: 3,
      border: `1px solid ${theme.palette.blue}`,
    },
    actionsMenu: {
      overflow: 'auto',
    },
    operationActionsPopover: {
      boxShadow: 'none',
    },
    portal: {
      '& .bp3-transition-container': {
        zIndex: 0,
      },
    },
    dotContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: 15.2,
      justifyContent: 'space-between',
    },
    dot: {
      backgroundColor: '#697B97', // will standardize later
      borderRadius: '50%',
      width: 4,
      height: 4,
    },
  });

type PassedProps = {
  nodeActions: React.ComponentProps<typeof MenuItem>[];
  className?: any;
};

type Props = PassedProps & WithStyles<typeof styles>;

class DotsDropdown extends React.Component<Props> {
  render() {
    const { classes, className } = this.props;

    return (
      <Popover
        portalClassName={classes.portal}
        popoverClassName={classes.operationActionsPopover}
        targetClassName={className}
        position={Position.BOTTOM_RIGHT}
        // captureDismiss={true}
        minimal
        content={<div className={classes.root}>{this.renderPanelMenu()}</div>}>
        <Button minimal>{this.renderActionsIcon()}</Button>
      </Popover>
    );
  }

  renderPanelMenu = () => {
    const { classes, nodeActions } = this.props;
    return (
      <Menu className={classes.actionsMenu}>
        {nodeActions.map((actionConfig: any) => (
          <MenuItem key={_.uniqueId('flow_action_item')} {...actionConfig} />
        ))}
      </Menu>
    );
  };

  renderActionsIcon = () => {
    const { classes } = this.props;
    return (
      <div className={classes.dotContainer}>
        <div className={classes.dot}></div>
        <div className={classes.dot}></div>
        <div className={classes.dot}></div>
      </div>
    );
  };
}

export default withStyles(styles)(DotsDropdown);

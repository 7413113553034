/** @format */

import React from 'react';
import cx from 'classnames';
import _ from 'underscore';
import { withStyles } from '@material-ui/styles';
import cloneDeep from 'lodash/cloneDeep';
import { BlockPicker } from 'react-color';

import {
  H3,
  Button,
  Tooltip,
  Position,
  Icon,
  Switch,
  InputGroup,
  Popover,
  Menu,
  FormGroup,
} from '@blueprintjs/core';

import OperationDropdownInput from 'components/flowBuilder/operationDropdownInput';
import EditDrawerDataPreview from 'components/flowBuilder/editOperationDrawer/editDrawerDataPreview';

import {
  NUMBER_TYPES,
  FLYOUT_DATA_PREVIEW_HEADER,
  Y_AXIS_OPTIONS,
  DATE_TYPES,
  MOMENT_DATE_FORMATS,
  OPERATION_TYPES,
  SORT_ORDERS_BY_TYPE,
} from 'constants/flowConstants.tsx';
import { COLOR_LIST } from 'constants/colorConstants';
import { getSelectedLineColor } from 'utils/graphUtils';
import {
  getLineChartOperationFooterErrorInformation,
  getLineChartperationRowErrorInformation,
} from 'utils/operationErrorInfoUtils/lineChartOperationErrorInfoUtil.tsx';
import OperationDrawerFooter from './operationDrawerFooter.tsx';
import CancelClauseButton from 'components/flowBuilder/editOperationDrawer/cancelClauseButton.tsx';

const styles = (theme) => ({
  root: {
    height: '100%',
  },
  body: {
    height: 'calc(100% - 54px)',
    overflowY: 'auto',
  },
  drawerFooter: {
    padding: `${theme.spacing(3)}px ${theme.spacing(6)}px`,
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${theme.palette.grey.border}`,
    backgroundColor: theme.palette.white,
  },
  selectionTitle: {
    margin: theme.spacing(6),
  },
  selectionContainer: {
    margin: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    marginBottom: theme.spacing(6),
    backgroundColor: theme.palette.white,
    boxShadow: theme.customShadows.basic,
    borderRadius: 4,
    padding: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
  },
  selectionText: {
    fontSize: 16,
    fontWeight: 'bold',
    marginRight: theme.spacing(3),
  },
  tableContainer: {
    margin: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    marginBottom: theme.spacing(6),
    backgroundColor: theme.palette.white,
    boxShadow: theme.customShadows.basic,
    borderRadius: 4,
  },
  clauseTable: {
    width: '100%',
  },
  addLineButton: {
    margin: theme.spacing(3),
    marginLeft: 52,
  },
  cancelClauseCol: {
    width: theme.spacing(7),
    paddingRight: '0px !important',
  },
  cancelClauseColPlaceholder: {
    width: theme.spacing(10),
  },
  tableFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  multiAxisSwitchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  multiAxisExplanation: {
    display: 'flex',
    margin: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  multiAxisText: {
    paddingRight: theme.spacing(2),
  },
  multiAxisSwitch: {
    margin: theme.spacing(3),
  },
  displayNameInputGroup: {
    width: 200,
  },
  xAxisFormatText: {
    fontSize: 16,
    fontWeight: 'bold',
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(10),
  },
  colorPickerMenu: {
    padding: 0,
  },
  buttonErrorState: {
    boxShadow: `inset 0 0 0 1px ${theme.palette.dangerRed} !important`,
  },
});

class LineChartEditOperationBody extends React.Component {
  constructor(props) {
    super(props);

    const { instructions, sourceDataset } = props;
    this.state = {
      instructions: cloneDeep(instructions),
      numberColumns: cloneDeep(sourceDataset.cached_schema).filter((col) =>
        NUMBER_TYPES.has(col.type),
      ),
    };
  }

  render() {
    const { instructions, numberColumns } = this.state;
    const { classes, onSubmit, sourceDataset, selectionTitle, addSelectionBtn } = this.props;
    const { footerErrorState, footerErrorText } = getLineChartOperationFooterErrorInformation(
      instructions,
    );
    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <EditDrawerDataPreview
            sourceDataset={sourceDataset}
            dataPreviewHeader={FLYOUT_DATA_PREVIEW_HEADER}
          />
          <H3 className={classes.selectionTitle}>Select the X Axis Column</H3>
          <div className={classes.selectionContainer}>{this.renderXAxisSelector()}</div>
          <H3 className={classes.selectionTitle}>{selectionTitle}</H3>
          <div className={classes.tableContainer}>
            <table className={cx(classes.clauseTable, 'bp3-html-table')}>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>Column</th>
                  <th>Display Name</th>
                  {instructions.multiAxis && <th>Y Axis</th>}
                </tr>
              </thead>
              <tbody>{instructions.lineColumns.map(this.renderLineConfigSelector)}</tbody>
            </table>
            <div className={classes.tableFooter}>
              <Button
                disabled={numberColumns.length === instructions.lineColumns.length}
                className={classes.addLineButton}
                icon="add"
                onClick={this.addLine}>
                {addSelectionBtn}
              </Button>
              <div className={classes.multiAxisSwitchContainer}>
                <div className={classes.multiAxisExplanation}>
                  <div className={classes.multiAxisText}>Multiple Y-Axes</div>
                  <Tooltip
                    position={Position.TOP}
                    content="If selected, your chart will have multiple y axes and you can configure which axis each value will go on.">
                    <Icon icon="help" />
                  </Tooltip>
                </div>
                <Switch
                  className={classes.multiAxisSwitch}
                  checked={Boolean(instructions.multiAxis)}
                  onChange={this.toggleMultiAxisSelection}
                  innerLabelChecked="Multi-axis"
                  innerLabel="Single Axis"
                />
              </div>
            </div>
          </div>
          <H3 className={classes.selectionTitle}>Order the chart</H3>
          <div className={classes.selectionContainer}>{this.renderChartSortSelector()}</div>
        </div>
        <OperationDrawerFooter
          operationType={OPERATION_TYPES.LINE_CHART.id}
          errorState={footerErrorState}
          errorText={footerErrorText}
          onSubmit={() => {
            const cleanInstructions = instructions;
            cleanInstructions.lineColumns = cleanInstructions.lineColumns.filter(
              (config) => !!config.column,
            );
            onSubmit(cleanInstructions);
          }}
        />
      </div>
    );
  }

  renderLineConfigSelector = (lineConfig, index) => {
    const { instructions } = this.state;
    const { classes, operationId, sourceDataset } = this.props;
    const schemaByColumnName = _.indexBy(sourceDataset.cached_schema, 'name');

    const {
      isSelectColumnButtonErrorState,
      displayNameErrorText,
      displayNameInputIntent,
    } = getLineChartperationRowErrorInformation(lineConfig, index);

    return (
      <tr key={`line_chart_clause_${operationId}}_${index}`}>
        <CancelClauseButton index={index} onClick={() => this.removeLine(index)} />
        <td>
          <Popover minimal>
            <Button
              icon={
                <Icon
                  icon="symbol-square"
                  color={lineConfig.color || COLOR_LIST[index & COLOR_LIST.length].primary}
                />
              }
            />
            <Menu key="menu" className={classes.colorPickerMenu}>
              <BlockPicker
                width={180}
                triangle="hide"
                color={lineConfig.color || COLOR_LIST[index & COLOR_LIST.length].primary}
                colors={_.pluck(COLOR_LIST, 'primary')}
                onChange={(color) => this.updateLineColor(color.hex, index)}
              />
            </Menu>
          </Popover>
        </td>
        <td>
          <OperationDropdownInput
            buttonErrorState={isSelectColumnButtonErrorState}
            selectedItem={
              lineConfig.column && {
                name: lineConfig.column.name,
                id: lineConfig.column.name,
              }
            }
            onChange={(item) => {
              this.selectLineColumn(schemaByColumnName[item.id], index);
            }}
            options={this.remainingLineColumns().map((col) => ({
              name: col.name,
              id: col.name,
            }))}
            noSelectionText="Select Column"
          />
        </td>
        <td>
          <FormGroup
            className={
              displayNameErrorText
                ? classes.formGroupValidationErrorState
                : classes.formGroupValidationNoError
            }
            helperText={displayNameErrorText}
            intent={displayNameInputIntent}
            labelFor="text-input">
            <InputGroup
              className={classes.displayNameInputGroup}
              disabled={!lineConfig.column}
              value={
                lineConfig.display_name === undefined
                  ? (lineConfig.column && lineConfig.column.name) || ''
                  : lineConfig.display_name
              }
              placeholder="Enter a display name"
              onChange={(e) => this.updateLineDisplayName(e.target.value, index)}
            />
          </FormGroup>
        </td>
        {instructions.multiAxis && (
          <td>
            <OperationDropdownInput
              selectedItem={
                lineConfig.yAxis ? Y_AXIS_OPTIONS[lineConfig.yAxis] : Y_AXIS_OPTIONS['left-axis']
              }
              onChange={(item) => {
                this.selectYAxis(index, item.id);
              }}
              options={Object.values(Y_AXIS_OPTIONS)}
              noSelectionText="Select Axis"
            />
          </td>
        )}
      </tr>
    );
  };

  renderXAxisSelector = () => {
    const { instructions } = this.state;
    const { classes, sourceDataset } = this.props;
    const schemaByColumnName = _.indexBy(sourceDataset.cached_schema, 'name');

    return (
      <>
        <div className={classes.selectionText}>X Axis Column: </div>
        <OperationDropdownInput
          buttonErrorState={instructions.xAxisColumn === null}
          selectedItem={
            instructions.xAxisColumn && {
              name: instructions.xAxisColumn.name,
              id: instructions.xAxisColumn.name,
            }
          }
          onChange={(item) => {
            this.selectXAxisColum(schemaByColumnName[item.id]);
          }}
          options={sourceDataset.cached_schema.map((col) => ({ name: col.name, id: col.name }))}
          noSelectionText="Select Column"
        />
        {this.renderXAxisFormat()}
      </>
    );
  };

  renderXAxisFormat = () => {
    const { instructions } = this.state;
    const { classes } = this.props;

    if (instructions.xAxisColumn && DATE_TYPES.has(instructions.xAxisColumn.type)) {
      return (
        <>
          <div className={classes.xAxisFormatText}>Date Format: </div>
          <OperationDropdownInput
            selectedItem={instructions.xAxisFormat || MOMENT_DATE_FORMATS[0]}
            onChange={this.selectXAxisFormat}
            options={MOMENT_DATE_FORMATS}
            noSelectionText="Select Date Format"
          />
        </>
      );
    }
  };

  renderChartSortSelector = () => {
    const { instructions } = this.state;
    const { classes, sourceDataset } = this.props;
    const schemaByColumnName = _.indexBy(sourceDataset.cached_schema, 'name');

    let options = _.pluck(instructions.lineColumns, 'column');
    options.push(instructions.xAxisColumn);
    options = _.compact(options);

    return (
      <>
        <div className={classes.selectionText}>Column to Sort: </div>
        <OperationDropdownInput
          selectedItem={
            instructions.chartSortColumn && {
              id: instructions.chartSortColumn.name,
              name: instructions.chartSortColumn.name,
            }
          }
          onChange={(col) => this.selectChartSortColumn(schemaByColumnName[col.name])}
          options={options.map((col) => ({ name: col.name, id: col.name }))}
          noSelectionText="Select Column"
        />

        <div className={classes.xAxisFormatText}>Order: </div>
        <OperationDropdownInput
          selectedItem={
            instructions.chartSortOrder ||
            (instructions.chartSortColumn &&
              SORT_ORDERS_BY_TYPE[instructions.chartSortColumn.type][0])
          }
          onChange={this.selectChartSortOrder}
          options={
            instructions.chartSortColumn
              ? SORT_ORDERS_BY_TYPE[instructions.chartSortColumn.type]
              : []
          }
          noSelectionText="Select Order"
          disabled={instructions.chartSortColumn === undefined}
          showIcon
        />
      </>
    );
  };

  remainingLineColumns = () => {
    const { instructions, numberColumns } = this.state;

    return numberColumns.filter(
      (col) =>
        !_.find(instructions.lineColumns, (line) => line.column && line.column.name === col.name),
    );
  };

  addLine = () => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      const lineIndex = instructions.lineColumns.length;
      instructions.lineColumns.push({
        column: null,
        color: COLOR_LIST[lineIndex % COLOR_LIST.length].primary,
      });
      return { instructions };
    });
  };

  removeLine = (index) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      const lineCol = instructions.lineColumns[index];
      if (
        instructions.chartSortColumn &&
        instructions.chartSortColumn.name === lineCol.column.name
      ) {
        instructions.chartSortColumn = undefined;
      }
      instructions.lineColumns.splice(index, 1);
      return { instructions };
    });
  };

  selectXAxisColum = (col) => {
    this.setState((prevState) => {
      const { instructions } = prevState;

      if (
        instructions.xAxisColumn &&
        instructions.chartSortColumn &&
        instructions.chartSortColumn.name === instructions.xAxisColumn.name
      ) {
        instructions.chartSortColumn = undefined;
      }

      instructions.xAxisColumn = col;
      return { instructions };
    });
  };

  selectLineColumn = (col, index) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.lineColumns[index].column = col;
      return { instructions };
    });
  };

  selectYAxis = (index, axisId) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.lineColumns[index].yAxis = axisId;
      return { instructions };
    });
  };

  toggleMultiAxisSelection = () => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.multiAxis = !instructions.multiAxis;

      // If it was set to a multiAxis graph, ensure that each line on an axis is the same color
      if (instructions.multiAxis) {
        instructions.lineColumns.map((column, index) => {
          column.color = getSelectedLineColor(instructions, index);
          return null;
        });
      }
      return { instructions };
    });
  };

  updateLineDisplayName = (newName, index) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.lineColumns[index].display_name = newName;
      return { instructions };
    });
  };

  updateLineColor = (colorHex, index) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.lineColumns[index].color = colorHex;
      const changedLineAxis = instructions.lineColumns[index].yAxis;

      // If it is a multiAxis graph, when one line's color is updated, we must updated the colors
      // of all lines on that axis
      if (instructions.multiAxis) {
        instructions.lineColumns.map((column) => {
          if (changedLineAxis === 'left-axis' || changedLineAxis === undefined) {
            if (column.yAxis === 'left-axis' || column.yAxis === undefined) {
              column.color = colorHex;
            }
          } else if (changedLineAxis === 'right-axis' && column.yAxis === 'right-axis') {
            column.color = colorHex;
          }
          return null;
        });
      }
      return { instructions };
    });
  };

  selectXAxisFormat = (newFormat) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.xAxisFormat = newFormat;
      return { instructions };
    });
  };

  selectChartSortColumn = (column) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.chartSortColumn = column;
      instructions.chartSortOrder = undefined;
      return { instructions };
    });
  };

  selectChartSortOrder = (order) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.chartSortOrder = order;
      return { instructions };
    });
  };
}

export default withStyles(styles)(LineChartEditOperationBody);

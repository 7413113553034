/** @format */

import { defineAction } from 'actions/actionUtils';

import { Dataset } from 'actions/types';

export const bulkFetchDatasetsSuccess = (datasets: Dataset[]) => ({
  type: 'BULK_FETCH_DATASETS_SUCCESS',
  payload: {
    datasets,
  },
});

export const bulkFetchDatasets = defineAction(
  'BULK_FETCH_DATASETS',
  'datasets',
  'get_bulk',
  'POST',
);

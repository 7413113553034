/** @format */

import React from 'react';
import cx from 'classnames';
import { withStyles, WithStyles } from '@material-ui/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Icon, Popover, Button, Menu, MenuItem, Position } from '@blueprintjs/core';

import { SortOptionType } from 'constants/flowConstants';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

type PassedProps = {
  className?: string;
  selectedSort: SortOptionType;
  setSort: (newSort: SortOptionType) => void;
  sortOptions: SortOptionType[];
};

type Props = PassedProps & WithStyles<typeof styles>;

class SortByButton extends React.PureComponent<Props> {
  render() {
    const { classes, className } = this.props;

    return <div className={cx(classes.root, className)}>{this.renderSortSelector()}</div>;
  }

  renderSortSelector = () => {
    const { selectedSort } = this.props;
    return (
      <Popover enforceFocus={false} minimal position={Position.BOTTOM_RIGHT}>
        <Button minimal>
          Sort by <b>{selectedSort.sortText}</b>
          <Icon icon="caret-down" />
        </Button>
        {this.renderSortSelectionMenu()}
      </Popover>
    );
  };

  renderSortSelectionMenu = () => {
    const { selectedSort, setSort, sortOptions } = this.props;

    return (
      <Menu>
        {sortOptions.map((sortOption) => (
          <MenuItem
            key={`flow-sort-${sortOption.id}`}
            text={`Sort by ${sortOption.sortText}`}
            active={sortOption.id === selectedSort.id}
            onClick={() => setSort(sortOption)}
          />
        ))}
      </Menu>
    );
  };
}

export default withStyles(styles)(SortByButton);

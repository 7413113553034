/** @format */

import React from 'react';
import _ from 'underscore';
import cx from 'classnames';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { OPERATION_TYPES } from 'constants/flowConstants';
import Fuse from 'fuse.js';
import cloneDeep from 'lodash/cloneDeep';

import { Icon, InputGroup } from '@blueprintjs/core';

import { getResultTextHighlighted } from 'utils/fileUtils';

const SECTIONS: { [sectionType: string]: { name: string; id: string } } = {
  FILTER: { name: 'Filter and Sort', id: 'FILTER' },
  COMBINE: { name: 'Combine', id: 'COMBINE' },
  EDIT_AND_CLEAN: { name: 'Edit and Clean', id: 'EDIT_AND_CLEAN' },
  AGGREGATE: { name: 'Calculate', id: 'AGGREGATE' },
  ADD_COLUMN: { name: 'Formulas', id: 'ADD_COLUMN' },
  VISUAlIZE: { name: 'Visualize', id: 'VISUAlIZE' },
};
const ALL_SECTIONS_ID = 'ALL_SECTIONS';

const OPERATIONS = [
  {
    ...OPERATION_TYPES.FILTER,
    section: SECTIONS.FILTER.id,
    description: 'Display only rows that meet specific criteria.',
  },
  {
    ...OPERATION_TYPES.SORT,
    section: SECTIONS.FILTER.id,
    description: 'Order data based on specific columns.',
  },
  {
    ...OPERATION_TYPES.ENRICH,
    section: SECTIONS.COMBINE.id,
    description: 'Combine and enrich data from two tables based on related column(s).',
  },
  {
    ...OPERATION_TYPES.UNION,
    section: SECTIONS.COMBINE.id,
    description: 'Stack two tables on top of each other to merge similar data.',
  },
  {
    ...OPERATION_TYPES.PIVOT,
    section: SECTIONS.AGGREGATE.id,
    description: 'Calculate and summarize your data for groups of values.',
  },
  {
    ...OPERATION_TYPES.CALCULATE,
    section: SECTIONS.AGGREGATE.id,
    description: 'Calculate values for a single column such as the sum, average, min, max, etc.',
  },
  {
    ...OPERATION_TYPES.FORMULA,
    section: SECTIONS.ADD_COLUMN.id,
    description: 'Write custom formulas to perform text or numerical operations on columns.',
  },
  {
    ...OPERATION_TYPES.CHANGE_SCHEMA,
    section: SECTIONS.EDIT_AND_CLEAN.id,
    description: 'Change column types (cast), remove columns, and rename columns (edit schemas).',
  },
  {
    ...OPERATION_TYPES.LINE_CHART,
    section: SECTIONS.VISUAlIZE.id,
    description: 'Create a line chart to find trends in data over time.',
  },
  {
    ...OPERATION_TYPES.BAR_CHART,
    section: SECTIONS.VISUAlIZE.id,
    description:
      'Calculate and summarize your data for groups of values and graph results in a bar chart.',
  },
  {
    ...OPERATION_TYPES.GROUPED_BAR_CHART,
    section: SECTIONS.VISUAlIZE.id,
    description: 'Create a bar chart to compare individual items.',
  },
  {
    ...OPERATION_TYPES.AXIS_PIVOT_CHART,
    section: SECTIONS.AGGREGATE.id,
    description: 'Pivot your data to summarize and visualize results by columns.',
  },
  {
    ...OPERATION_TYPES.DEDUP,
    section: SECTIONS.EDIT_AND_CLEAN.id,
    description: 'Remove duplicates data based on specific columns',
  },
];

const operationSearchIndex = new Fuse(cloneDeep(OPERATIONS), {
  isCaseSensitive: false,
  threshold: 0.2,
  distance: 100,
  includeMatches: true,
  keys: ['name', 'description'],
});

const styles = (theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      backgroundColor: theme.palette.white,
      borderRadius: 4,
      border: `1px solid ${theme.palette.grey.border}`,
      position: 'relative',
      overflow: 'hidden',
    },
    header: {
      padding: theme.spacing(3),
      borderBottom: '1px solid rgba(16, 22, 26, 0.15)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    titleText: {
      fontWeight: 'bold',
      fontSize: 18,
      display: 'flex',
      alignItems: 'baseline',
      width: '100%',
    },
    searchBarContainer: {
      padding: theme.spacing(3),
    },
    searchBarInput: {},
    resultsContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    sectionsContainer: {
      width: 200,
      height: 300,
      borderRight: `1px solid ${theme.palette.grey.border}`,
    },
    stepListContainer: {
      width: 'calc(100% - 200px)',
      height: 300,
      overflow: 'auto',
    },
    sectionSelection: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      '&:hover': {
        backgroundColor: theme.palette.grey.light,
        cursor: 'pointer',
      },
      '&:active': {
        backgroundColor: theme.palette.primary.selected,
        color: theme.palette.primary.selectedText,
      },
      '&.selected': {
        backgroundColor: theme.palette.primary.selected,
        color: theme.palette.primary.selectedText,
      },
    },
    operationSection: {
      fontWeight: 'bold',
      margin: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    },
    operationContainer: {
      margin: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
      padding: theme.spacing(2),
      borderRadius: 8,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      '&:hover': {
        cursor: 'pointer',
      },
      '&.hovered': {
        backgroundColor: theme.palette.grey.light,
      },
      '&:active': {
        backgroundColor: theme.palette.primary.selected,
      },
    },
    operationIconContainer: {
      height: 44,
      width: 44,
      backgroundColor: theme.palette.grey.blueprintGrey,
      marginRight: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 8,
    },
    operationInfo: {
      color: theme.palette.grey.dark,
    },
    operationName: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
    },
    operationDescription: {},
    highlightedText: {
      backgroundColor: theme.palette.primary.highlighted,
    },
  });

type OperationSearchResults = Array<typeof OPERATIONS[0] & { matches?: any }>;

type PassedProps = {
  closeAddStep: () => void;
  addFlowStep: (opType: string) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  selectedSection: string;
  searchQuery: string;
  filteredOperations: OperationSearchResults;
  hoveredOperationIndex: number;
};

class AddStepBlock extends React.Component<Props, State> {
  state: State = {
    selectedSection: ALL_SECTIONS_ID,
    searchQuery: '',
    filteredOperations: OPERATIONS,
    hoveredOperationIndex: 0,
  };

  searchInput: any;

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
    this.searchInput.focus();
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (e: KeyboardEvent) => {
    const { addFlowStep, closeAddStep } = this.props;
    const { hoveredOperationIndex, filteredOperations } = this.state;

    let newHoverIndex = hoveredOperationIndex;
    if (e.keyCode === 40) {
      newHoverIndex = Math.min(hoveredOperationIndex + 1, filteredOperations.length);
    } else if (e.keyCode === 38) {
      newHoverIndex = Math.max(hoveredOperationIndex - 1, 0);
    } else if (e.keyCode === 13) {
      addFlowStep(this.getSelectedOperationId(filteredOperations, hoveredOperationIndex));
      closeAddStep();
      return;
    }
    if (newHoverIndex !== hoveredOperationIndex) {
      this.setState({ hoveredOperationIndex: newHoverIndex });
    }
  };

  getSelectedOperationId = (
    operationList: OperationSearchResults,
    selectionIndex: number,
  ): string => {
    const opBySection = _.groupBy(operationList, 'section');
    let operationsOrderedBySection: Array<any> = [];
    _.each(
      Object.keys(opBySection),
      (sectionId) =>
        (operationsOrderedBySection = operationsOrderedBySection.concat(opBySection[sectionId])),
    );

    return operationsOrderedBySection[selectionIndex].id;
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    );
  }

  renderHeader = () => {
    const { classes } = this.props;

    return (
      <div className={classes.header}>
        <div className={classes.titleText}>Add an Action</div>
      </div>
    );
  };

  renderBody = () => {
    const { classes } = this.props;

    return (
      <div>
        {this.renderSearchBar()}
        <div className={classes.resultsContainer}>
          {this.renderSections()}
          {this.renderStepList()}
        </div>
      </div>
    );
  };

  renderSearchBar = () => {
    const { classes } = this.props;
    const { searchQuery } = this.state;

    return (
      <div className={classes.searchBarContainer}>
        <InputGroup
          inputRef={(ref) => (this.searchInput = ref)}
          value={searchQuery}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            this.updateSearchQuery(e.target.value)
          }
          round={false}
          leftIcon="search"
          placeholder="Search for an action..."
          className={classes.searchBarInput}
        />
      </div>
    );
  };

  renderSections = () => {
    const { classes } = this.props;
    const { selectedSection } = this.state;

    return (
      <div className={classes.sectionsContainer}>
        <div
          className={cx(classes.sectionSelection, {
            selected: selectedSection === ALL_SECTIONS_ID,
          })}
          key="add-step-section-all"
          onClick={() => this.selectSection(ALL_SECTIONS_ID)}>
          All Actions
        </div>
        {Object.values(SECTIONS).map((section) => (
          <div
            className={cx(classes.sectionSelection, {
              selected: selectedSection === section.id,
            })}
            key={`add-step-section-select-${section.id}`}
            onClick={() => this.selectSection(section.id)}>
            {section.name}
          </div>
        ))}
      </div>
    );
  };

  selectSection = (sectionId: string) => {
    const { searchQuery } = this.state;

    this.setState({
      selectedSection: sectionId,
      filteredOperations: this.getFilteredResults(sectionId, searchQuery),
      hoveredOperationIndex: 0,
    });
  };

  updateSearchQuery = (newQuery: string) => {
    const { selectedSection } = this.state;

    this.setState({
      searchQuery: newQuery,
      filteredOperations: this.getFilteredResults(selectedSection, newQuery),
      hoveredOperationIndex: 0,
    });
  };

  renderStepList = () => {
    const { classes } = this.props;
    const { filteredOperations } = this.state;

    const opBySection = _.groupBy(filteredOperations, 'section');
    if (Object.keys(opBySection).length === 1) {
      return (
        <div className={classes.stepListContainer}>
          {filteredOperations.map(this.renderOperation)}
        </div>
      );
    }
    let opIndex = -1;
    return (
      <div className={classes.stepListContainer}>
        {Object.keys(opBySection).map((sectionId: string) => (
          <div key={`add-step-section-${sectionId}`}>
            <div className={classes.operationSection}>{SECTIONS[sectionId].name}</div>
            {opBySection[sectionId].map((operation) => {
              opIndex += 1;
              return this.renderOperation(operation, opIndex);
            })}
          </div>
        ))}
      </div>
    );
  };

  renderOperation = (operation: any, index: number) => {
    const { classes, addFlowStep, closeAddStep } = this.props;
    const { hoveredOperationIndex } = this.state;

    const matchesByKey: { [key: string]: any } = _.indexBy(operation.matches || [], 'key');
    const highlightedName = getResultTextHighlighted(
      operation.name,
      (matchesByKey.name && matchesByKey.name.indices) || [],
      classes.highlightedText,
    );
    const highlightedDescription = getResultTextHighlighted(
      operation.description,
      (matchesByKey.description && matchesByKey.description.indices) || [],
      classes.highlightedText,
    );

    return (
      <div
        className={cx(classes.operationContainer, { hovered: hoveredOperationIndex === index })}
        key={`add-step-op-${operation.id}`}
        onClick={() => {
          addFlowStep(operation.id);
          closeAddStep();
        }}
        onMouseOver={() =>
          index !== hoveredOperationIndex && this.setState({ hoveredOperationIndex: index })
        }>
        <div className={classes.operationIconContainer}>
          <Icon icon={operation.icon} color={'#ffffff'} />
        </div>
        <div className={classes.operationInfo}>
          <div className={classes.operationName}>{highlightedName}</div>
          <div className={classes.operationDescription}>
            {highlightedDescription}{' '}
            {operation.help_center_link && (
              <a
                href={operation.help_center_link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e: any) => {
                  e.stopPropagation();
                }}>
                Learn more.
              </a>
            )}
          </div>
        </div>
      </div>
    );
  };

  getFilteredResults = (selectedSection: string, query: string): OperationSearchResults => {
    let operations = OPERATIONS;
    if (query.length > 0) {
      const results = operationSearchIndex.search(query);
      // @ts-ignore
      _.each(results, (result) => (result.item.matches = result.matches));
      operations = _.pluck(results, 'item');
    }

    if (selectedSection === ALL_SECTIONS_ID) {
      return operations;
    }
    const opBySection = _.groupBy(operations, 'section');
    return opBySection[selectedSection];
  };
}

export default withStyles(styles)(AddStepBlock);

/** @format */

import React from 'react';
import _ from 'underscore';
import cx from 'classnames';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { Tag, Icon, IconName, Intent, Button, Spinner } from '@blueprintjs/core';

import { DATETIME, TIMESTAMP, INTEGER_DATA_TYPE, FLOAT } from 'constants/flowConstants';
import BaseDataTable from 'components/dataTable/baseDataTable';
import LoadingDataTable from 'components/dataTable/loadingDataTable';
import ColumnHeaderText from 'components/dataTable/columnHeaderText';

import { Dataset } from 'actions/types';

import {
  OPERATION_TYPES,
  MAX_ROWS_TO_PREVIEW,
  SCHEMA_DATA_TYPES_BY_ID,
} from 'constants/flowConstants';

import {
  filterClauseComplete,
  sortClauseComplete,
  formulaClauseComplete,
  schemaClauseInstructionsComplete,
  dedupClauseComplete,
} from 'utils/flowUtils';
import {
  FilterClause,
  OperationInstructions,
  SortClause,
  AggregationColumnInfo,
  SelectedDropdownInputItem,
} from 'constants/types';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 'calc(100% - 300px)',
      backgroundColor: theme.palette.white,
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
      margin: theme.spacing(3),
      marginTop: theme.spacing(2),
      borderRadius: 4,
      position: 'absolute' as 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 1,
    },
    empty: {
      width: '100%',
    },
    header: {
      width: '100%',
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.grey.border}`,
    },
    headerText: {
      fontWeight: 'bold' as 'bold',
      fontSize: 18,
    },
    tableColumnHeaderText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    tableColumnHeaderTypeIcon: {
      marginRight: theme.spacing(2),
      minWidth: 'unset',
    },
    iconHeaderText: {
      overflow: 'hidden',
    },
    endorsedIcon: {
      marginLeft: theme.spacing(1),
    },
    tableFooter: {
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      backgroundColor: theme.palette.grey.light,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    rowCount: {
      padding: `0 ${theme.spacing(1)}px`,
      fontSize: 14,
      color: theme.palette.black,
      fontWeight: 300,
      display: 'flex',
      alignItems: 'center',
    },
    rowCountSpinner: {
      marginRight: theme.spacing(2),
    },
    datasetPreviewTable: {
      height: '20vh',
    },
  });

type PassedProps = {
  dataset?: Dataset;
  operationType?: string;
  operationInstructions?: OperationInstructions;
  computeLoading?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  expanded: boolean;
};

class FlowDatasetPreviewPanel extends React.PureComponent<Props, State> {
  state: State = {
    expanded: true,
  };

  render() {
    const { classes, dataset } = this.props;

    if (!dataset) {
      return <div className={classes.empty}></div>;
    }

    return (
      <div className={classes.root}>
        {this.renderHeader()}
        {this.renderTable(dataset)}
      </div>
    );
  }

  renderHeader = () => {
    const { classes } = this.props;
    const { expanded } = this.state;

    return (
      <div className={classes.header}>
        <div className={classes.headerText}>Preview Data</div>
        <Button
          icon={expanded ? 'caret-down' : 'caret-up'}
          onClick={() => this.setState({ expanded: !expanded })}
        />
      </div>
    );
  };

  renderTable = (dataset: Dataset) => {
    const { expanded } = this.state;

    if (!expanded) {
      return;
    }

    return (
      <>
        {this.renderTableData(dataset)}
        {this.renderTableFooter(dataset)}
      </>
    );
  };

  renderTableData = (dataset: Dataset) => {
    const { classes, computeLoading } = this.props;
    const DataTable = !dataset.cached_preview ? LoadingDataTable : BaseDataTable;

    return (
      <DataTable
        className={classes.datasetPreviewTable}
        loading={!dataset.cached_preview}
        rows={dataset.cached_preview || []}
        headerList={dataset.cached_schema!}
        maxRows={MAX_ROWS_TO_PREVIEW}
        selectedColumns={this.getSelectedPreviewCols()}
        renderCustomNameRenderer={computeLoading ? null : this.renderCustomNameRenderer}
      />
    );
  };

  renderTableFooter = (dataset: Dataset) => {
    const { classes } = this.props;

    return <div className={classes.tableFooter}>{this.renderRowCount(dataset)}</div>;
  };

  renderRowCount = (dataset: Dataset) => {
    const { classes } = this.props;

    if (!dataset.total_row_count) {
      return (
        <div className={classes.rowCount}>
          <Spinner size={14} className={classes.rowCountSpinner} /> total rows
        </div>
      );
    }

    return (
      <div className={classes.rowCount}>
        {dataset.total_row_count.toLocaleString()} total rows{' '}
        {dataset.total_row_count && dataset.total_row_count > MAX_ROWS_TO_PREVIEW
          ? ` (previewing ${MAX_ROWS_TO_PREVIEW} rows)`
          : ''}
      </div>
    );
  };

  getSelectedPreviewCols = () => {
    const { operationType, operationInstructions } = this.props;

    const selectedCols: Set<string> = new Set([]);

    if (!operationType || !operationInstructions) {
      return selectedCols;
    }

    if (operationType === OPERATION_TYPES.FILTER.id) {
      const filterClauses: FilterClause[] = Array.isArray(operationInstructions)
        ? operationInstructions
        : operationInstructions.filterClauses;
      _.each(filterClauses, (clause: any) => {
        if (filterClauseComplete(clause)) {
          selectedCols.add(clause.filterColumn.name);
        }
      });
    } else if (operationType === OPERATION_TYPES.SORT.id) {
      _.each(operationInstructions.sortColumns, (clause: SortClause) => {
        if (sortClauseComplete(clause)) {
          selectedCols.add(clause.column!.name);
        }
      });
    } else if (operationType === OPERATION_TYPES.FORMULA.id) {
      _.each(operationInstructions, (clause: any) => {
        if (formulaClauseComplete(clause)) {
          selectedCols.add(clause.newColName);
        }
      });
    } else if (operationType === OPERATION_TYPES.CHANGE_SCHEMA.id) {
      const schemaChanges: any[] = operationInstructions.changeSchemaList.filter(
        schemaClauseInstructionsComplete,
      );
      _.each(schemaChanges, (change: any) => {
        if (!('keepCol' in change && change['keepCol'] === false)) {
          selectedCols.add(change['newColName'] || change['col']);
        }
      });
    } else if (operationType === OPERATION_TYPES.DEDUP.id) {
      _.each(operationInstructions.colsToDedup, (dedupClause: AggregationColumnInfo) => {
        if (dedupClauseComplete(dedupClause)) {
          if (dedupClause.type === DATETIME || dedupClause.type === TIMESTAMP) {
            if (dedupClause.aggBucket) {
              const aggBucketTime = dedupClause.aggBucket as SelectedDropdownInputItem;
              selectedCols.add(aggBucketTime.name.toLowerCase() + '_' + dedupClause.name);
              selectedCols.add(aggBucketTime.name.toUpperCase() + '_' + dedupClause.name);
            }
          } else if (dedupClause.type === INTEGER_DATA_TYPE || dedupClause.type === FLOAT) {
            if (dedupClause.aggBucket) {
              const aggBucketNumber = dedupClause.aggBucket as number;
              selectedCols.add(dedupClause.name + '_bucket_' + aggBucketNumber);
              selectedCols.add(dedupClause.name + '_BUCKET_' + aggBucketNumber);
            }
          } else {
            selectedCols.add(dedupClause.name);
          }
        }
      });
      _.each(operationInstructions.sortColumns, (sortClause: SortClause) => {
        if (sortClauseComplete(sortClause)) {
          selectedCols.add(sortClause.column!.name);
        }
      });
    }
    return selectedCols;
  };

  renderCustomNameRenderer = (header: string, index: number) => {
    const { classes, operationType, operationInstructions, dataset } = this.props;

    switch (operationType) {
      case OPERATION_TYPES.UNION.id:
        if (
          index < operationInstructions.columnsToUnionOn.length &&
          operationInstructions.columnsToUnionOn[index].sourceColumn &&
          operationInstructions.columnsToUnionOn[index].unionColumn
        ) {
          return (
            <div className={cx('bp3-table-truncated-text', classes.tableColumnHeaderText)}>
              <div className={classes.iconHeaderText}>
                <Tag
                  className={classes.tableColumnHeaderTypeIcon}
                  icon={
                    SCHEMA_DATA_TYPES_BY_ID[dataset!.cached_schema![index].type].icon as IconName
                  }
                  minimal={true}></Tag>
                {`${header}`}
              </div>
              <Icon className={classes.endorsedIcon} intent={Intent.PRIMARY} icon="endorsed" />
            </div>
          );
        } // eslint-disable-next-line no-fallthrough
      default:
        return (
          <ColumnHeaderText headerList={dataset!.cached_schema!} index={index} header={header} />
        );
    }
  };
}

export default withStyles(styles)(FlowDatasetPreviewPanel);

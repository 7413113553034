/** @format */

import { Intent } from '@blueprintjs/core';
import _ from 'underscore';
import { OperationFooterErrorInformation } from 'utils/operationErrorInfoUtils/generalOperationErrorInfoUtil';

enum ROW_ERROR_TEXT {
  EMPTY_DISPLAY_NAME = 'Please give a display name',
}

enum FOOTER_ERROR_TEXT {
  NO_ERROR = 'No Error',
  SELECT_X_AXIS = 'Please select the X axis column',
  FILL_ROWS = 'Please fill in the incomplete rows to submit',
}

export interface LineChartOperationRowErrorInformation {
  isSelectColumnButtonErrorState: boolean;
  displayNameErrorText: string | null;
  displayNameInputIntent: Intent;
}

export const getLineChartperationRowErrorInformation = (
  lineColumn: any,
  index: number,
): LineChartOperationRowErrorInformation => {
  let rowErrorInformation: LineChartOperationRowErrorInformation = {
    isSelectColumnButtonErrorState: false,
    displayNameErrorText: null,
    displayNameInputIntent: Intent.NONE,
  };
  // If the row is empty, but its not the first row - that should not throw an error
  if (index !== 0 && lineColumn.column === null) {
    return rowErrorInformation;
  }

  if (lineColumn.column === null) {
    rowErrorInformation.isSelectColumnButtonErrorState = true;
  }

  // If the display name is empty give an error
  if (lineColumn.display_name === '') {
    rowErrorInformation.displayNameErrorText = ROW_ERROR_TEXT.EMPTY_DISPLAY_NAME;
    rowErrorInformation.displayNameInputIntent = Intent.DANGER;
  }
  return rowErrorInformation;
};

export const getLineChartOperationFooterErrorInformation = (
  instructions: any,
): OperationFooterErrorInformation => {
  const operationFooterErrorInformation: OperationFooterErrorInformation = {
    footerErrorState: false,
    footerErrorText: FOOTER_ERROR_TEXT.NO_ERROR,
  };
  if (instructions.xAxisColumn === null) {
    return {
      footerErrorState: true,
      footerErrorText: FOOTER_ERROR_TEXT.SELECT_X_AXIS,
    };
  } else {
    // if I find an incomplete row, then the instructions are incomplete
    if (
      !!_.find(instructions.lineColumns, (lineColumn, index) => {
        const {
          isSelectColumnButtonErrorState,
          displayNameErrorText,
        } = getLineChartperationRowErrorInformation(lineColumn, index);
        return isSelectColumnButtonErrorState || displayNameErrorText;
      })
    ) {
      operationFooterErrorInformation.footerErrorState = true;
      operationFooterErrorInformation.footerErrorText = FOOTER_ERROR_TEXT.FILL_ROWS;
    }
  }
  return operationFooterErrorInformation;
};

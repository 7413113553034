/** @format */

import React, { Component } from 'react';
import cx from 'classnames';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import { Button } from '@blueprintjs/core';

const styles = (theme: Theme) => ({
  root: {},
  name: {
    textTransform: 'uppercase' as 'uppercase',
    fontWeight: 'bold' as 'bold',
    fontSize: 12,
    marginBottom: theme.spacing(1),

    '&.editing': {
      color: theme.palette.primary.main,
    },
  },
  editActions: {
    border: `1px solid ${theme.palette.grey.border}`,
    borderRadius: 4,
    width: 'fit-content',
    padding: theme.spacing(1),

    '&.editing': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '&.error': {
      border: `1px solid ${theme.palette.dangerRed}`,
    },
  },
});

type PassedProps = {
  name: string;
  onEditClicked: () => void;
  onRemoveClicked: () => void;
  isCurrentlyEditing: boolean;
  error: boolean;
};

type Props = WithStyles<typeof styles> & PassedProps;

type State = {};

export class EditableDashboardFilter extends Component<Props, State> {
  render = () => {
    const { classes, name, onEditClicked, onRemoveClicked, isCurrentlyEditing, error } = this.props;
    return (
      <div className={classes.root}>
        <div className={cx(classes.name, { editing: isCurrentlyEditing })}>{name}</div>
        <div className={cx(classes.editActions, { editing: isCurrentlyEditing, error })}>
          <Button icon="edit" text="Edit" minimal small onClick={onEditClicked} />
          <Button icon="cross" text="Remove" minimal small onClick={onRemoveClicked} />
        </div>
      </div>
    );
  };
}

export default withStyles(styles)(EditableDashboardFilter);

/** @format */

import React from 'react';

import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';

type Props = {
  modalOpen: boolean;
  closeModal: () => void;
  modalTitle: string;
  modalText: string | React.ReactElement;
  submitBtnText: string;
  danger?: boolean;
  onSubmit: () => void;
};

const ConfirmationModal = (props: Props) => {
  if (!props.modalOpen) return <></>;
  return (
    <Dialog isOpen={props.modalOpen} onClose={props.closeModal} title={props.modalTitle}>
      <div className={Classes.DIALOG_BODY}>
        <div>{props.modalText}</div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button intent={Intent.NONE} onClick={() => props.closeModal()}>
            Cancel
          </Button>
          <Button
            intent={props.danger ? Intent.DANGER : Intent.PRIMARY}
            onClick={() => {
              props.onSubmit();
              props.closeModal();
            }}>
            {props.submitBtnText}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;

/** @format */

import React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';

import StepBlockTag from 'components/flowBuilder/stepBlockTag';

import { InstructionComponentProps } from 'components/flowGraph/operationInstructionDescription';
import { AxisPivotChartInstructions } from 'constants/types';

import { axisPivotChartInstructionsComplete } from 'utils/flowUtils';

const styles = (theme: Theme) =>
  createStyles({
    fillerText: {
      fontSize: 16,
      fontWeight: 400,
      margin: `0 ${theme.spacing(2)}px`,
    },
    startingFillterText: {
      marginLeft: 0,
    },
  });

type PassedProps = InstructionComponentProps<AxisPivotChartInstructions>;

type Props = PassedProps & WithStyles<typeof styles>;

class AxisPivotChartInstructionDescription extends React.Component<Props> {
  render() {
    const { classes, operationId, instructions } = this.props;

    if (!axisPivotChartInstructionsComplete(instructions)) {
      return <></>;
    }

    const phrases = [];

    phrases.push(
      <StepBlockTag
        key={`axis_pivot_agg_${operationId}`}
        content={`${instructions.aggregation.type!.name}( ${
          instructions.aggregation.aggedOnColumn!.name
        } )`}
      />,
    );

    phrases.push(
      <div className={classes.fillerText} key={`axis_pivot_by_${operationId}`}>
        by
      </div>,
    );

    phrases.push(
      <StepBlockTag key={`axis_pivot_row_${operationId}`} content={instructions.rowColumn!.name} />,
    );

    phrases.push(
      <div className={classes.fillerText} key={`axis_pivot_and_${operationId}`}>
        and
      </div>,
    );

    phrases.push(
      <StepBlockTag key={`axis_pivot_col_${operationId}`} content={instructions.colColumn!.name} />,
    );

    return phrases;
  }
}

export default withStyles(styles)(AxisPivotChartInstructionDescription);

/** @format */

import React, { useState } from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@blueprintjs/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.grey300,
    borderRadius: 6,

    '&:hover': {
      backgroundColor: 'rgba(43, 69, 113, 0.8)',
      cursor: 'pointer',
    },
    '&:active': {
      backgroundColor: 'rgba(43, 69, 113, 0.7)',
    },
  },
  questionContainer: {
    padding: `${theme.spacing(5)}px ${theme.spacing(5)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  question: {
    color: theme.palette.white,
    fontSize: 16,
  },
  answer: {
    padding: `${theme.spacing(5)}px ${theme.spacing(5)}px`,
    paddingTop: 0,
    display: 'none',
    color: theme.palette.grey100,

    '&.isOpen': {
      display: 'block',
    },
  },
  icon: {
    color: theme.palette.black100,
  },
}));

type Props = {
  className?: string;
  question: string;
  answer: string;
};

const FaqQuestionDropdown = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  return (
    <div className={cx(classes.root, props.className)} onClick={() => setIsOpen(!isOpen)}>
      <div className={classes.questionContainer}>
        <div className={classes.question}>{props.question}</div>
        <Icon className={classes.icon} icon={isOpen ? 'caret-up' : 'caret-down'} iconSize={20} />
      </div>
      <div className={cx(classes.answer, { isOpen })}>{props.answer}</div>
    </div>
  );
};

export default FaqQuestionDropdown;

/** @format */

import React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Intent, Button, Card, Elevation, H6, Icon } from '@blueprintjs/core';
import { createStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { updateSetupFlowStepKey } from 'actions/onboardingActions';
import { SetupFlowStepKey, STEP_KEY_TO_DATA_SELECTION_PATH } from 'constants/setupPageConstants';
import {
  connectDataSource,
  fetchDataSourceList,
  switchSelectedDataSourceId,
} from 'actions/dataSourceActions';
import { AppToaster } from 'toaster';

import _ from 'underscore';
import cx from 'classnames';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      minWidth: '50vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(3),
    },
    nextButton: { minWidth: 120, marginTop: theme.spacing(10) },
    demoDataSelectionPanel: {
      display: 'grid',
      // gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridColumnGap: theme.spacing(10),
      gridRowGap: theme.spacing(10),
    },
    demoDataCard: {
      width: 180,
      height: 135,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 5,
      padding: '10px 10px 10px 10px',
      position: 'relative',
    },
    demoDataCardSelected: {
      backgroundColor: theme.palette.primary.veryLight,
      borderColor: theme.palette.primary.selectedText,
      borderWidth: 3,
      borderStyle: 'solid',
    },
    cardTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cardText: {
      fontWeight: 500,
    },
    cardSubText: {
      textAlign: 'center',
    },
    checkIcon: {
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(2),
    },
  });

interface DemoDataOption {
  text: string;
  subtext: string;
}

export const DEMO_DATA_DB_NAME = 'Demo Data';

const DEMO_DATA_OPTION_LIST: DemoDataOption[] = [
  {
    text: 'COVID-19 Data',
    subtext: 'See how COVID-19 impacts all areas of the economy.',
  },
  // {
  //   text: 'Pokemon Data',
  //   subtext: 'You could do so many things with this data.',
  // },
  // {
  //   text: 'COVID-19 Data',
  //   subtext: 'This is a Descriptionof what you could look at with this data.',
  // },
  // {
  //   text: 'Pokemon Data',
  //   subtext: 'You could do so many things with this data.',
  // },
  // {
  //   text: 'COVID-19 Data',
  //   subtext: 'This is a Descriptionof what you could look at with this data.',
  // },
  // {
  //   text: 'Pokemon Data',
  //   subtext: 'You could do so many things with this data.',
  // },
  // {
  //   text: 'COVID-19 Data',
  //   subtext: 'This is a Descriptionof what you could look at with this data.',
  // },
  // {
  //   text: 'Pokemon Data',
  //   subtext: 'You could do so many things with this data.',
  // },
];

type State = {
  selectedIndexSet: Set<number>;
  nextLoading: boolean;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles>;

class DemoDataSelection extends React.Component<Props, State> {
  readonly state: State = {
    selectedIndexSet: new Set(),
    nextLoading: false,
  };

  componentDidMount = () => {
    const { fetchDataSourceList } = this.props;
    fetchDataSourceList();
  };

  render() {
    const { classes } = this.props;
    const { selectedIndexSet, nextLoading } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.demoDataSelectionPanel}>{this.renderDemoDataToChooseFrom()}</div>
        <Button
          className={classes.nextButton}
          intent={Intent.PRIMARY}
          onClick={this.onNextButtonPress}
          disabled={selectedIndexSet.size === 0}
          loading={nextLoading}>
          Next
        </Button>
      </div>
    );
  }

  onNextButtonPress = () => {
    const {
      updateSetupFlowStepKey,
      connectDataSource,
      dataSourceList,
      switchSelectedDataSourceId,
    } = this.props;

    const demoDataSource = _.findWhere(dataSourceList, { name: DEMO_DATA_DB_NAME });
    if (demoDataSource !== undefined) {
      AppToaster.show({
        message: 'The Demo Data You selected is already connected',
        timeout: 5000,
        intent: Intent.SUCCESS,
      });
      updateSetupFlowStepKey({
        postData: {
          updated_setup_flow_key: SetupFlowStepKey.DATABASE_PATH_ENTER_EXPLO,
          data_selection_path:
            STEP_KEY_TO_DATA_SELECTION_PATH[SetupFlowStepKey.DATABASE_PATH_ENTER_EXPLO],
        },
      });
      switchSelectedDataSourceId(demoDataSource.id);
    } else {
      this.setState({ nextLoading: true });
      connectDataSource(
        {
          postData: {
            name: DEMO_DATA_DB_NAME,
            type: 'postgres',
            isDemoData: true,
            configuration: {
              database: 'da4h5osoc4qrkq',
              firewall: false,
              host: 'ec2-52-205-196-238.compute-1.amazonaws.com',
              password: 'p330e65edae7913c23849afa13481ae72317280a9a6bd745feecbd143cbacf2f5',
              port: 5432,
              username: 'u7vavnatbbjcim',
            },
          },
        },
        (response: any) => {
          this.setState({ nextLoading: false });
          updateSetupFlowStepKey({
            postData: {
              updated_setup_flow_key: SetupFlowStepKey.DATABASE_PATH_ENTER_EXPLO,
              data_selection_path:
                STEP_KEY_TO_DATA_SELECTION_PATH[SetupFlowStepKey.DATABASE_PATH_ENTER_EXPLO],
            },
          });
          switchSelectedDataSourceId(response.data_source.id);
        },
        (response: any) => {
          this.setState({ nextLoading: false });
          AppToaster.show({
            message:
              'There was an internal error connecting to the demo data, please try again or contact explo support',
            timeout: 5000,
            intent: Intent.DANGER,
          });
        },
      );
    }
  };

  renderDemoDataToChooseFrom = () => {
    const { classes } = this.props;
    const { selectedIndexSet } = this.state;

    return DEMO_DATA_OPTION_LIST.map((demoDataOption: DemoDataOption, index: number) => {
      return (
        <Card
          className={cx(classes.demoDataCard, {
            [classes.demoDataCardSelected]: selectedIndexSet.has(index),
          })}
          interactive={true}
          elevation={Elevation.TWO}
          onClick={() => this.updateSelectedIndexSet(index)}>
          {selectedIndexSet.has(index) && (
            <Icon
              className={classes.checkIcon}
              iconSize={Icon.SIZE_LARGE}
              icon="tick-circle"
              intent={Intent.PRIMARY}
            />
          )}
          <div className={classes.cardTextContainer}>
            <H6 className={classes.cardText}>{demoDataOption.text}</H6>
            <div className={classes.cardSubText}>{demoDataOption.subtext}</div>
          </div>
        </Card>
      );
    });
  };

  updateSelectedIndexSet = (index: number) => {
    this.setState((prevState) => {
      const { selectedIndexSet } = prevState;
      if (selectedIndexSet.has(index)) {
        selectedIndexSet.delete(index);
      } else {
        selectedIndexSet.add(index);
      }
      return { selectedIndexSet };
    });
  };
}

const mapStateToProps = (state: ReduxState) => ({
  dataSourceList: state.dataSourceList,
});

const mapDispatchToProps = {
  updateSetupFlowStepKey,
  connectDataSource,
  fetchDataSourceList,
  switchSelectedDataSourceId,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DemoDataSelection));

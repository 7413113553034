/** @format */

import { User } from 'actions/types';
import _ from 'underscore';
import { Regions } from '@blueprintjs/table';
import { Table } from '@blueprintjs/table';

const COLUMN_WIDTH = 150;

export const userName = (user: User) => {
  return user.first_name + ' ' + user.last_name;
};

export const numberWithCommas = (x: number) => {
  const roundedX = Math.round(x * 100) / 100;
  return roundedX.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const isUUID = (potentialUUIDString: string) => {
  return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
    potentialUUIDString,
  );
};

export const isFirstCharacterANumber = (name: string) => {
  var firstChar = name.charAt(0);
  return firstChar <= '9' && firstChar >= '0';
};

export const namesWithLeadingNumbersExist = (names: string[]) => {
  return (
    _.filter(names, (name) => {
      return isFirstCharacterANumber(name);
    }).length > 0
  );
};

export const duplicateNamesExist = (names: string[]) => {
  return names.some((item, idx) => {
    return names.indexOf(item) !== idx;
  });
};

export const removeAccentsFromString = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const accentMarksExistInNames = (names: string[]) => {
  return (
    _.filter(names, (name) => {
      return removeAccentsFromString(name) !== name;
    }).length > 0
  );
};

export const nonAlphaNumericCharactersExistInNames = (names: string[]) => {
  return (
    _.filter(names, (name) => {
      return name.match(/^[a-z0-9_]+$/i) === null;
    }).length > 0
  );
};

export const hasUpperCase = (str: string) => {
  const stri = /[A-Z]/.test(str);
  return stri;
};

export const upperCaseCharactersExistInNames = (names: string[]) => {
  return (
    _.filter(names, (name) => {
      return hasUpperCase(name);
    }).length > 0
  );
};

const columnIndexIsUnviewable = (columnIndex: number, previewTableRef: Table) => {
  // @ts-ignore
  const scrollLeftOffset = previewTableRef.scrollContainerElement.scrollLeft;
  const bottomIndex = Math.ceil(scrollLeftOffset / COLUMN_WIDTH);
  // @ts-ignore
  const topIndex = Math.ceil(
    // @ts-ignore
    (previewTableRef.scrollContainerElement.clientWidth + scrollLeftOffset) / COLUMN_WIDTH,
  );
  return columnIndex + 1 <= bottomIndex || columnIndex + 1 >= topIndex;
};

export const safeScrollToColumn = (columnIndex: number, previewTableRef?: Table) => {
  if (!previewTableRef) {
    console.log(
      'Internal Error: PreviewTable Reference (previewTableRef) is missing when scrolling to a table column.',
    );
  } else {
    if (columnIndexIsUnviewable(columnIndex, previewTableRef)) {
      previewTableRef.scrollToRegion(Regions.column(columnIndex));
    }
  }
};

// https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
export function array_move(arr: any[], old_index: number, new_index: number) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

// _.findIndex returns -1 if the element cannot be found in the array, this checks for that
export const removeElementAtIndex = (index: number, array: any[]) => {
  return index !== -1 && array.splice(index, 1);
};

export const findCommonElements = (arr1: any[], arr2: any[]) => {
  return arr1.some((item) => arr2.includes(item));
};

/** @format */

import React from 'react';
import { connect } from 'react-redux';

import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';
import { AppToaster } from 'toaster';

import SimpleSuggestInput from 'components/flowBuilder/simpleSuggestInput';
import { addOperationToDashboard, createDashboard } from 'actions/dashboardActions';
import { ReduxState } from 'reducers/rootReducer';
import { clearRecentlyAddedOpId } from 'actions/flowActions';

type PassedProps = {
  closeModal: () => void;
  modalOpen: boolean;
  operationId: number;
};

type Props = PassedProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type State = {
  selectedDashboard?: any;
  selectionLoading: boolean;
};

class AddToDashboardModal extends React.Component<Props, State> {
  readonly state: State = {
    selectedDashboard: null,
    selectionLoading: false,
  };

  render() {
    const { selectedDashboard, selectionLoading } = this.state;
    const {
      modalOpen,
      closeModal,
      dashboardData,
      addOperationToDashboard,
      operationId,
      clearRecentlyAddedOpId,
    } = this.props;

    if (!modalOpen) return <></>;
    return (
      <Dialog isOpen={modalOpen} onClose={closeModal} title="Choose a dashboard to add to">
        <div className={Classes.DIALOG_BODY}>
          <SimpleSuggestInput
            fill={true}
            selectedItem={selectedDashboard}
            onChange={(item: any) => this.setState({ selectedDashboard: item })}
            options={dashboardData.dashboardList || []}
            loading={!dashboardData.dashboardList}
            noSelectionText="Search for a dashboard"
            onCreateNewItem={(newDashboardName: any) => {
              const newDashboardNameTrimmed = newDashboardName.trim();

              if (newDashboardNameTrimmed.length === 0) {
                AppToaster.show({
                  message: 'Please enter a valid dashboard name',
                  icon: 'error',
                  timeout: 5000,
                  intent: Intent.WARNING,
                });
                return;
              }

              this.setState({ selectionLoading: true });
              this.props.createDashboard(
                {
                  postData: {
                    name: newDashboardName,
                    parent_folder_id: this.getNewDashboardParentFolderId(),
                    operation_ids: [operationId],
                  },
                },
                (response: any) => {
                  AppToaster.show({
                    message: 'The dashboard was successfully created: ' + newDashboardName,
                    icon: 'tick-circle',
                    timeout: 5000,
                    intent: Intent.SUCCESS,
                    action: {
                      icon: 'document-open',
                      text: 'View Dashboard',
                      href: `/dashboards/${response.new_dashboard.id}`,
                      target: '_blank',
                    },
                  });
                  this.setState({ selectionLoading: false });
                  clearRecentlyAddedOpId();
                  closeModal();
                },
              );
            }}
          />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              loading={selectionLoading}
              intent={Intent.PRIMARY}
              onClick={() => {
                this.setState({ selectionLoading: true });
                addOperationToDashboard(
                  {
                    id: selectedDashboard.id,
                    postData: { operation_id: operationId },
                  },
                  () => {
                    AppToaster.show({
                      message: 'The board was added to the dashboard: ' + selectedDashboard.name,
                      icon: 'tick-circle',
                      timeout: 5000,
                      intent: Intent.SUCCESS,
                      action: {
                        icon: 'document-open',
                        text: 'View Dashboard',
                        href: `/dashboards/${selectedDashboard.id}`,
                        target: '_blank',
                      },
                    });
                    this.setState({ selectionLoading: false });
                    clearRecentlyAddedOpId();
                    closeModal();
                  },
                );
              }}
              disabled={selectedDashboard === null}>
              Add to Dashboard
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }

  getNewDashboardParentFolderId = () => {
    const { currentUser, teamData } = this.props;
    if (!teamData.data) {
      return currentUser.root_folder_id;
    }
    return teamData.data.team_folder.id;
  };
}

const mapStateToProps = (state: ReduxState) => ({
  currentUser: state.currentUser,
  dashboardData: state.dashboardData,
  teamData: state.teamData,
});

const mapDispatchToProps = {
  addOperationToDashboard,
  createDashboard,
  clearRecentlyAddedOpId,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToDashboardModal);

/** @format */

import React from 'react';
import _ from 'underscore';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { ReduxState } from 'reducers/rootReducer';
import { Intent } from '@blueprintjs/core';
import { AppToaster } from 'toaster';
import { connect } from 'react-redux';

import ConfirmationModal from 'components/modals/confirmationModal';

import { deleteFlow } from 'actions/fileSystemActions';
import { fetchFlowRelatedDashboards } from 'actions/flowActions';

import { DashboardItem } from 'actions/types';

const styles = (theme: Theme) => ({
  root: {},
});

type PassedProps = {
  flowId: number;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  onDelete: () => void;
};

type Props = PassedProps & WithStyles<typeof styles> & typeof mapDispatchToProps;

type State = {
  deleteFlowRelatedDashboards: DashboardItem[] | null;
};

class DeleteFlowConfirmationModal extends React.Component<Props, State> {
  state: State = {
    deleteFlowRelatedDashboards: null,
  };

  componentDidMount() {
    const { fetchFlowRelatedDashboards, flowId } = this.props;

    fetchFlowRelatedDashboards(
      { id: flowId },
      (response: any) => {
        this.setState({ deleteFlowRelatedDashboards: response.dashboards });
      },
      () => {},
    );
  }

  render() {
    const { isOpen, onClose, title } = this.props;

    return (
      <ConfirmationModal
        modalOpen={isOpen}
        closeModal={onClose}
        modalTitle={title || 'Are you sure you want to delete this exploration?'}
        modalText={this.getModalText()}
        submitBtnText="Delete"
        danger
        onSubmit={this.onSubmit}
      />
    );
  }

  getModalText = () => {
    const { deleteFlowRelatedDashboards } = this.state;

    return deleteFlowRelatedDashboards &&
      _.size(_.map(deleteFlowRelatedDashboards, (item: any) => item.name)) > 0 ? (
      <div>
        <div>This will delete operations from the following dashboards:</div>
        <ul>
          {_.map(deleteFlowRelatedDashboards, (item: any) => (
            <li key={item.id}>
              <a href={`/dashboards/${item.id}`} target="_blank" rel="noopener noreferrer">
                {item.name}
              </a>
            </li>
          ))}
        </ul>
        <div>This action cannot be undone.</div>
      </div>
    ) : (
      <div>This action cannot be undone.</div>
    );
  };

  onSubmit = () => {
    const { deleteFlow, onDelete, flowId } = this.props;

    deleteFlow(
      { id: flowId },
      () => {
        AppToaster.show({
          message: 'The exploration was successfully deleted',
          icon: 'tick',
          timeout: 3000,
          intent: Intent.SUCCESS,
        });
      },
      (response: any) => {
        AppToaster.show({
          // Sometimes an object is inputted and, if not, the direct error message is inputed.
          message: response['msg'] || response,
          icon: 'error',
          timeout: 5000,
          intent: Intent.DANGER,
        });
      },
    );
    onDelete();
  };
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = {
  deleteFlow,
  fetchFlowRelatedDashboards,
};

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(DeleteFlowConfirmationModal));

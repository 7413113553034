/** @format */

import { Tag, Intent } from '@blueprintjs/core';
import { Tooltip, Position } from '@blueprintjs/core';
import React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

const styles = (theme: Theme) => ({
  stepBlockClauseTag: {
    fontSize: 16,
  },
  content: {
    maxWidth: '50vw',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

type PassedProps = {
  key: string;
  interactive?: boolean;
  onClick?: () => void;
  content: string;
  href?: string;
  toolTipContent?: string;
};

type Props = PassedProps & WithStyles<typeof styles>;

class StepBlockTag extends React.Component<Props> {
  render() {
    const { classes, key, interactive, onClick, content, href, toolTipContent } = this.props;
    return (
      <Tooltip disabled={!toolTipContent} content={toolTipContent} position={Position.TOP}>
        <a href={href} target="_blank" rel="noopener noreferrer">
          <Tag
            className={classes.stepBlockClauseTag}
            key={key}
            minimal={true}
            large={true}
            intent={Intent.PRIMARY}
            interactive={interactive}
            onClick={onClick}>
            <div className={classes.content}>{content}</div>
          </Tag>
        </a>
      </Tooltip>
    );
  }
}

export default withStyles(styles)(StepBlockTag);

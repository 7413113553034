/** @format */

import React from 'react';
import SetupFlowDatabaseSelection from 'components/onboardingSetup/setupFlowDatabaseSelection';
import AddDataSourcePage from 'pages/addDataSourcePage';
import DemoDataSelection from 'components/onboardingSetup/demoDataSelection';
import ConnectedDatasourceDisplay from 'components/onboardingSetup/connectedDatasourceDisplay';
import SetupFlowFileUploadButton from 'components/onboardingSetup/setupFlowFileUploadButton';
import CatTypingGif from 'images/cat_typing.gif';
import SetupFlowFileUploadPanel from 'components/onboardingSetup/setupFlowFileUploadPanel';
import { Dictionary } from 'underscore';

export const STEP_KEY_TO_DATA_SELECTION_PATH: Dictionary<string> = {
  database_already_connected_path_enter_explo: 'database_path',
  database_path_enter_explo: 'database_path',
  csv_path_enter_explo: 'csv_path',
  demo_data_path_enter_explo: 'demo_data_path',
};

export enum SetupFlowStepKey {
  WELCOME_TO_EXPLO = 'welcome_to_explo',
  WELCOME_TO_EXPLO_DB_ALREADY_CONNECTED = 'welcome_to_explo_db_already_connected',
  TEAM_IS_ALREADY_CONNECTED_TO_DB = 'team_is_already_connected_to_db',
  DATABASE_ALREADY_CONNECTED_PATH_ENTER_EXPLO = 'database_already_connected_path_enter_explo',
  IS_DATABASE_USED = 'is_database_used',
  SELECT_DATABASE = 'select_database',
  DATABASE_SUPPORTED = 'database_supported',
  CONNECT_DATABASE = 'connect_database',
  DATABASE_PATH_ENTER_EXPLO = 'database_path_enter_explo',
  DATABASE_NOT_SUPPORTED = 'database_not_supported',
  CSV_OR_DEMO_DATA_OPTION = 'csv_or_demo_data_option',
  CSV_UPLOAD = 'csv_upload',
  CSV_VERIFY_UPLOAD = 'csv_verify_upload',
  CSV_PATH_ENTER_EXPLO = 'csv_path_enter_explo',
  DEMO_DATA_SELECTION = 'demo_data_selection',
  DEMO_DATA_PATH_ENTER_EXPLO = 'demo_data_path_enter_explo',
  POST_SETUP_FLOW_PAGE = 'post_setup_flow_page',
}

export interface SetupFlowPageContent {
  header: string;
  subHeader?: string;
  nextButtonText?: string;
  nextButtonSetupFlowKey?: SetupFlowStepKey;
  skipButtonSetupFlowKey?: SetupFlowStepKey;
  backButtonSetupFlowKey?: SetupFlowStepKey;
  altButtonText?: string;
  altButtonSetupFlowKey?: SetupFlowStepKey;
  renderMiddleContent?: () => any;
  renderContentAboveHeader?: () => any;
}

export type SetupFlowKeyToPageContent = {
  [setupFlowStepKey in SetupFlowStepKey]: SetupFlowPageContent;
};

export const SETUP_FLOW_KEY_TO_PAGE_CONTENT: SetupFlowKeyToPageContent = {
  [SetupFlowStepKey.WELCOME_TO_EXPLO]: {
    header: 'Welcome to Explo!',
    subHeader: 'We are super excited to get you started!',
    nextButtonText: 'Get Started',
    nextButtonSetupFlowKey: SetupFlowStepKey.IS_DATABASE_USED,
  },
  [SetupFlowStepKey.WELCOME_TO_EXPLO_DB_ALREADY_CONNECTED]: {
    header: 'Welcome to Explo!',
    subHeader: 'We are super excited to get you started!',
    nextButtonText: 'Get Started',
    nextButtonSetupFlowKey: SetupFlowStepKey.TEAM_IS_ALREADY_CONNECTED_TO_DB,
  },
  [SetupFlowStepKey.TEAM_IS_ALREADY_CONNECTED_TO_DB]: {
    header: "Your team's connected a database",
    subHeader: 'This means you should be able to analyze this data right away!',
    renderMiddleContent: () => {
      // @ts-ignore
      return <ConnectedDatasourceDisplay />;
    },
  },
  [SetupFlowStepKey.DATABASE_ALREADY_CONNECTED_PATH_ENTER_EXPLO]: {
    header: "You're ready to get started",
    subHeader: "Let's jump into the platform!",
    nextButtonText: 'Enter Explo',
    nextButtonSetupFlowKey: SetupFlowStepKey.POST_SETUP_FLOW_PAGE,
  },
  [SetupFlowStepKey.IS_DATABASE_USED]: {
    header: 'Does your team use a database?',
    nextButtonText: 'Yes, we do!',
    nextButtonSetupFlowKey: SetupFlowStepKey.SELECT_DATABASE,
    altButtonText: "No, we don't",
    altButtonSetupFlowKey: SetupFlowStepKey.CSV_OR_DEMO_DATA_OPTION,
  },
  [SetupFlowStepKey.SELECT_DATABASE]: {
    header: 'Great! Where is it hosted?',
    subHeader: 'If you have multiple databases, choose your most important one',
    renderMiddleContent: () => {
      return <SetupFlowDatabaseSelection />;
    },
    skipButtonSetupFlowKey: SetupFlowStepKey.CSV_OR_DEMO_DATA_OPTION,
  },
  [SetupFlowStepKey.DATABASE_SUPPORTED]: {
    header: "Great! Let's get it connected",
    subHeader: 'Once your database is connected we can start analysis!',
    nextButtonText: 'Next',
    nextButtonSetupFlowKey: SetupFlowStepKey.CONNECT_DATABASE,
  },
  [SetupFlowStepKey.CONNECT_DATABASE]: {
    header: 'Connecting a database',
    subHeader: "If you don't have access to your credentials, as your database admin for help!",
    renderMiddleContent: () => {
      // @ts-ignore
      return <AddDataSourcePage inSetupFlowContext />;
    },
  },
  [SetupFlowStepKey.DATABASE_PATH_ENTER_EXPLO]: {
    header: 'Great work!',
    subHeader: "Now that you've a database, let's jump into the platform.",
    nextButtonText: 'Enter Explo',
    nextButtonSetupFlowKey: SetupFlowStepKey.POST_SETUP_FLOW_PAGE,
  },
  [SetupFlowStepKey.DATABASE_NOT_SUPPORTED]: {
    renderContentAboveHeader: () => <img width="60%" src={CatTypingGif} alt="loading..." />,
    header: "We're working on adding this to the platform",
    subHeader:
      "We're working hard to make this available to you soon. Once we've added your host we'll reach out and let you know.",
    nextButtonText: 'Next',
    nextButtonSetupFlowKey: SetupFlowStepKey.CSV_OR_DEMO_DATA_OPTION,
    backButtonSetupFlowKey: SetupFlowStepKey.SELECT_DATABASE,
  },
  [SetupFlowStepKey.CSV_OR_DEMO_DATA_OPTION]: {
    header: 'Which data would you like to start with?',
    nextButtonText: 'Upload a CSV',
    nextButtonSetupFlowKey: SetupFlowStepKey.CSV_UPLOAD,
    altButtonText: 'Use Demo Data',
    altButtonSetupFlowKey: SetupFlowStepKey.DEMO_DATA_SELECTION,
  },
  [SetupFlowStepKey.CSV_UPLOAD]: {
    header: 'Select a file to upload',
    subHeader: 'Any CSV should work.',
    renderMiddleContent: () => {
      return <SetupFlowFileUploadButton />;
    },
    skipButtonSetupFlowKey: SetupFlowStepKey.DEMO_DATA_SELECTION,
  },
  [SetupFlowStepKey.CSV_VERIFY_UPLOAD]: {
    header: 'Next, Verify the delimiter and data types',
    subHeader: 'This is also an opportunity to rename and delete columns.',
    renderMiddleContent: () => {
      return <SetupFlowFileUploadPanel />;
    },
  },
  [SetupFlowStepKey.CSV_PATH_ENTER_EXPLO]: {
    header: 'Great work!',
    subHeader: "Now that you've added a dataset, let's jump into the platform",
    nextButtonText: 'Enter Explo',
    nextButtonSetupFlowKey: SetupFlowStepKey.POST_SETUP_FLOW_PAGE,
  },
  [SetupFlowStepKey.DEMO_DATA_SELECTION]: {
    header: "Select the demo data you'd like to import",
    // subHeader: "Pick as many as you'd like!",
    renderMiddleContent: () => {
      // @ts-ignore
      return <DemoDataSelection />;
    },
  },
  [SetupFlowStepKey.DEMO_DATA_PATH_ENTER_EXPLO]: {
    header: 'Great choice!',
    subHeader: 'Now you can analyze this data within the platform',
    nextButtonText: 'Enter Explo',
    nextButtonSetupFlowKey: SetupFlowStepKey.POST_SETUP_FLOW_PAGE,
  },
  [SetupFlowStepKey.POST_SETUP_FLOW_PAGE]: {
    header: 'It Appears an Error has occured',
    nextButtonText: 'Please Contact explo support',
    nextButtonSetupFlowKey: SetupFlowStepKey.WELCOME_TO_EXPLO,
  },
};

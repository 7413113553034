/** @format */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import LoadingSpinner from 'images/loading_spinner.gif';

import { switchSelectedDataSourceId } from 'actions/dataSourceActions';
import { ROUTES } from 'constants/routes';

const styles = (theme: Theme) => ({
  loadingBody: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingSpinner: {
    width: 75,
  },
});

type MatchParams = {};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams> &
  WithStyles<typeof styles>;

type State = {
  selectDatasetModalOpen: boolean;
};

class NewFlowPage extends React.Component<Props, State> {
  state: State = {
    selectDatasetModalOpen: false,
  };

  render() {
    const { classes } = this.props;
    this.props.history.push(ROUTES.DATASOURCE);

    return (
      <div className={classes.loadingBody}>
        <img className={classes.loadingSpinner} src={LoadingSpinner} alt="loading spinner" />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  workspaceData: state.workspaceData,
  dataSourceList: state.dataSourceList,
});

const mapDispatchToProps = {
  switchSelectedDataSourceId,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NewFlowPage)),
);

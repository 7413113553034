/** @format */

import React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Intent, Button, Card, Elevation, H6 } from '@blueprintjs/core';
import { createStyles } from '@material-ui/core/styles';
import { createDatabaseHostedSelectionModel } from 'actions/onboardingActions';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { updateSetupFlowStepKey } from 'actions/onboardingActions';
import { SetupFlowStepKey } from 'constants/setupPageConstants';
import { ReactComponent as PostgresLogo } from 'images/postgresql-icon.svg';
import { ReactComponent as MysqlLogo } from 'images/mysql-icon.svg';
import { ReactComponent as BigQueryLogo } from 'images/google_bigquery-icon.svg';
import { ReactComponent as SQLiteLogo } from 'images/sqlite-icon.svg';
import Redshift from 'images/marketing/integration_logos/redshift.png';

import cx from 'classnames';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      minWidth: '50vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(3),
    },
    nextButton: { minWidth: 120, marginTop: theme.spacing(10) },
    databaseSelectionPanel: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridColumnGap: theme.spacing(10),
      gridRowGap: theme.spacing(10),
    },
    databaseCard: {
      width: 160,
      height: 135,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 4,
      padding: theme.spacing(3),
    },
    databaseCardSelected: {
      backgroundColor: theme.palette.primary.veryLight,
      borderColor: theme.palette.primary.main,
      borderWidth: 3,
      borderStyle: 'solid',
    },
    cardText: {
      marginBottom: 0,
      fontWeight: 500,
      marginTop: theme.spacing(3),
    },
  });

interface DatabaseDisplayInfo {
  name: string;
  type: DatabaseTypes;
  image: any;
}

export enum DatabaseTypes {
  MYSQL = 'mysql',
  BIG_QUERY = 'big_query',
  POSTGRES = 'postgres',
  SQLITE = 'sqlite',
  REDSHIFT = 'redshift',
}

type DatabaseOptionsDictionary = {
  [databaseType in DatabaseTypes]: DatabaseDisplayInfo;
};

export const DATABASE_OPTIONS_DICTIONARY: DatabaseOptionsDictionary = {
  [DatabaseTypes.MYSQL]: {
    name: 'MySQL',
    type: DatabaseTypes.MYSQL,
    image: <MysqlLogo />,
  },
  [DatabaseTypes.BIG_QUERY]: {
    name: 'Google BigQuery',
    type: DatabaseTypes.BIG_QUERY,
    image: <BigQueryLogo />,
  },
  [DatabaseTypes.POSTGRES]: {
    name: 'PostgreSQL',
    type: DatabaseTypes.POSTGRES,
    image: <PostgresLogo />,
  },
  [DatabaseTypes.SQLITE]: {
    name: 'SQLite',
    type: DatabaseTypes.SQLITE,
    image: <SQLiteLogo />,
  },
  [DatabaseTypes.REDSHIFT]: {
    name: 'Redshift',
    type: DatabaseTypes.REDSHIFT,
    image: <img src={Redshift} width={64} alt="redshift logo" />,
  },
};

type State = {
  selectedDatabaseType?: DatabaseTypes;
};

type Props = typeof mapDispatchToProps & WithStyles<typeof styles>;

class SetupFlowDatabaseSelection extends React.Component<Props, State> {
  readonly state: State = {};

  render() {
    const { classes } = this.props;
    const { selectedDatabaseType } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.databaseSelectionPanel}>{this.renderDatabasesToChooseFrom()}</div>
        <Button
          className={classes.nextButton}
          intent={Intent.PRIMARY}
          onClick={this.onNextButtonPress}
          disabled={selectedDatabaseType === undefined}>
          Next
        </Button>
      </div>
    );
  }

  onNextButtonPress = () => {
    const { selectedDatabaseType } = this.state;
    const { createDatabaseHostedSelectionModel, updateSetupFlowStepKey } = this.props;

    createDatabaseHostedSelectionModel(
      {
        postData: { database_type: DATABASE_OPTIONS_DICTIONARY[selectedDatabaseType!].type },
      },
      (data: any) => {
        updateSetupFlowStepKey({
          postData: {
            updated_setup_flow_key: data.is_supported
              ? SetupFlowStepKey.DATABASE_SUPPORTED
              : SetupFlowStepKey.DATABASE_NOT_SUPPORTED,
          },
        });
      },
    );
  };

  renderDatabasesToChooseFrom = () => {
    const { classes } = this.props;
    const { selectedDatabaseType } = this.state;

    return Object.values(DATABASE_OPTIONS_DICTIONARY).map(
      (databaseDisplayInfo: DatabaseDisplayInfo) => {
        return (
          <Card
            className={cx(classes.databaseCard, {
              [classes.databaseCardSelected]: databaseDisplayInfo.type === selectedDatabaseType,
            })}
            interactive={true}
            elevation={Elevation.TWO}
            onClick={() => this.setState({ selectedDatabaseType: databaseDisplayInfo.type })}>
            {databaseDisplayInfo.image}
            <H6 className={classes.cardText}>{databaseDisplayInfo.name}</H6>
          </Card>
        );
      },
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = {
  createDatabaseHostedSelectionModel,
  updateSetupFlowStepKey,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(SetupFlowDatabaseSelection));

/** @format */

import React from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

import SectionTitle from 'explo-ds/typography/marketing/sectionTitle';
import FeatureName from 'components/marketing/featureName';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  title: {
    color: theme.palette.white,
  },
  featureListContainer: {
    display: 'grid',
    gridTemplateColumns: '0.33fr 0.33fr 0.33fr',
    gridRowGap: theme.spacing(7),
    gridColumnGap: theme.spacing(2),
    marginTop: theme.spacing(10),

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '0.5fr 0.5fr',
    },
  },
}));

type Props = {
  className?: string;
};

const FeatureListSection = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.root, props.className)}>
      <div>
        <SectionTitle className={classes.title}>
          Explore your data <b>step-by-step</b>
        </SectionTitle>
      </div>
      <div className={classes.featureListContainer}>
        <FeatureName icon="filter" name="Filter" />
        <FeatureName icon="add-row-bottom" name="Union" />
        <FeatureName icon="pivot-table" name="Pivot axis chart" />
        <FeatureName icon="sort" name="Sort" />
        <FeatureName icon="inner-join" name="Join" />
        <FeatureName icon="vertical-bar-chart-desc" name="Bar charts" />
        <FeatureName icon="duplicate" name="Remove duplicates" />
        <FeatureName icon="search-text" name="Replace text" />
        <FeatureName icon="timeline-line-chart" name="Line charts" />
        <FeatureName icon="pivot" name="Pivot" />
        <FeatureName icon="derive-column" name="Custom formulas" />
        <FeatureName icon="map" name="Map charts" />
      </div>
    </div>
  );
};

export default FeatureListSection;

/** @format */

import React from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

import ProductSectionTitle from 'explo-ds/typography/marketing/productSectionTitle';
import ProductSectionDescription from 'explo-ds/typography/marketing/productSectionDescription';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  title: {
    color: theme.palette.white,

    [theme.breakpoints.down('xs')]: {
      fontSize: 28,
      lineHeight: '28px',
    },
  },
  titleBeginning: {
    fontWeight: 'bold',
  },
  descriptionContainer: {
    marginTop: theme.spacing(3),
  },

  hideDesc: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  description: {
    color: theme.palette.grey100,
  },
  videoFirst: {
    gridRowStart: 1,

    [theme.breakpoints.down('xs')]: {
      gridRowStart: 'auto',
    },
  },
  productVideo: {
    width: '100%',
  },
}));

type Props = {
  className?: string;
  titleBeginning: string;
  titleEnd: string;
  description: string;
  hideDescOnXs?: boolean;
};

const ProductFeatureSection = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.root, props.className)}>
      <div>
        <ProductSectionTitle className={cx(classes.titleBeginning, classes.title)}>
          {props.titleBeginning}
        </ProductSectionTitle>
        <ProductSectionTitle className={classes.title}>{props.titleEnd}</ProductSectionTitle>
      </div>
      <div className={cx(classes.descriptionContainer, { [classes.hideDesc]: props.hideDescOnXs })}>
        <ProductSectionDescription className={classes.description}>
          {props.description}
        </ProductSectionDescription>
      </div>
    </div>
  );
};

export default ProductFeatureSection;

/** @format */

import React from 'react';
import _ from 'underscore';
import cx from 'classnames';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';

import StepBlockTag from 'components/flowBuilder/stepBlockTag';

import { dedupClauseComplete, sortClauseComplete } from 'utils/flowUtils';

import { InstructionComponentProps } from 'components/flowGraph/operationInstructionDescription';
import { DedupOperationInstructions, SortClause } from 'constants/types';

const styles = (theme: Theme) =>
  createStyles({
    fillerText: {
      fontSize: 16,
      fontWeight: 400,
      margin: `0 ${theme.spacing(2)}px`,
    },
    startingFillterText: {
      marginLeft: 0,
    },
  });

type PassedProps = InstructionComponentProps<DedupOperationInstructions>;

type Props = PassedProps & WithStyles<typeof styles>;

class DedupOperationInstructionDescription extends React.Component<Props> {
  render() {
    const { classes, operationId, instructions } = this.props;
    const completeDedupClauses = instructions.colsToDedup.filter(dedupClauseComplete);
    const completeSortClauses = instructions.sortColumns.filter(sortClauseComplete);

    const phrases = [
      <div
        className={cx(classes.fillerText, classes.startingFillterText)}
        key={`pivot_on_${operationId}`}>
        on
      </div>,
    ];
    _.each(completeDedupClauses, (col, index) => {
      phrases.push(<StepBlockTag key={`pivot_col_${index}_${operationId}`} content={col.name} />);

      if (index !== completeDedupClauses.length - 1) {
        phrases.push(
          <div className={classes.fillerText} key={`pivot_filler_on${index}_${operationId}_and`}>
            and
          </div>,
        );
      }
    });

    if (completeSortClauses.length === 0) {
      return completeDedupClauses.length >= 1 ? <>{phrases}</> : <></>;
    }

    phrases.push(
      <div className={classes.fillerText} key={`filter_where_${operationId}`}>
        by
      </div>,
    );

    _.each(completeSortClauses, (clause: SortClause, index: number) => {
      if (index !== 0) {
        phrases.push(
          <div className={classes.fillerText} key={`sort_clause_${index}_${operationId}_filler`}>
            , then by
          </div>,
        );
      }

      phrases.push(
        <StepBlockTag
          key={`sort_clause_${index}_${operationId}`}
          content={`${clause.column!.name} (${clause.order!})`}
        />,
      );
    });

    return completeDedupClauses.length >= 1 ? <>{phrases}</> : <></>;
  }
}

export default withStyles(styles)(DedupOperationInstructionDescription);

/** @format */

import React from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 40,
    color: theme.palette.white,
  },
  description: {
    fontSize: 18,
    color: theme.palette.grey100,
  },
}));

type Props = {
  className?: string;
  title1: string;
  title2: string;
  description: string;
};

const EcommBenefit = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.root, props.className)}>
      <div className={classes.title}>
        <b>{props.title1}</b>
        {props.title2}
      </div>
      <div className={classes.description}>{props.description}</div>
    </div>
  );
};

export default EcommBenefit;

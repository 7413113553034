/** @format */

import _ from 'underscore';
import { Action } from 'reducers/rootReducer';
import { Operation } from 'actions/types';

export interface ShareReducerState {
  dashboard: {
    loading: boolean;
    data?: any;
  };
}

const shareReducerInitialState: ShareReducerState = {
  dashboard: {
    loading: true,
  },
};

export default (
  state: ShareReducerState = shareReducerInitialState,
  action: Action,
): ShareReducerState => {
  const newState = Object.assign({}, state);
  const { payload } = action;

  switch (action.type) {
    case 'FETCH_SHARED_DASHBOARD_REQUEST':
      newState.dashboard.loading = true;
      return newState;
    case 'FETCH_SHARED_DASHBOARD_ERROR':
      newState.dashboard.loading = false;
      return newState;
    case 'FETCH_SHARED_DASHBOARD_SUCCESS':
      newState.dashboard.data = payload.dashboard;
      newState.dashboard.loading = false;
      return newState;
    case 'FETCH_SHARED_DASHBOARD_OPERATION_ROW_COUNT_SUCCESS':
      const newData = Object.assign({}, newState.dashboard.data);
      let operation: Operation | null | undefined = _.findWhere(newData.operations, {
        id: parseInt(payload.postData.operation_id),
      });
      operation!.results_dataset.total_row_count = payload.row_count;
      newState.dashboard.data = newData;
      return newState;
    default:
      return state;
  }
};

/** @format */

import { Dataset, DatasetFlowNode, Flow, FlowViewConfig, Operation } from 'actions/types';
import cloneDeep from 'lodash/cloneDeep';
import { Action } from 'reducers/rootReducer';
import { removeElementAtIndex } from 'utils/general';
import { NODE_TYPES } from 'pages/flowGraphPage/flowGraphPageV3';
import _ from 'underscore';

export type OperationDictionary = { [id: number]: Operation };

export interface FlowReducerState {
  loading?: boolean;
  error?: boolean;
  flowInfo?: Flow | null;
  flowOperations: OperationDictionary;
  datasetNodes: DatasetFlowNode[];
  recentlyAddedOpId?: number | null;
  disableAddStepBlock?: boolean;
  sourceDataRowCount?: number;
  newFlowStepLoading?: boolean;
  createFlowLoading?: boolean;
  flowViewConfig?: FlowViewConfig;
  addDatasetLoading?: boolean;
  isFlyoutOpen: boolean;
}

const flowReducerInitialState: FlowReducerState = {
  loading: true,
  error: undefined,
  flowInfo: null,
  flowOperations: {},
  datasetNodes: [],
  recentlyAddedOpId: null,
  disableAddStepBlock: false,
  sourceDataRowCount: undefined,
  newFlowStepLoading: false,
  createFlowLoading: false,
  isFlyoutOpen: false,
};

// CASCADE Effect of deleting an operation
const removeOperationNodeAndChildrenFromOperations = (
  flowOperations: OperationDictionary,
  operationIdsToRemove: number[],
) => {
  operationIdsToRemove.forEach((operationId: number) => {
    const removedOperation = flowOperations[operationId];
    delete flowOperations[operationId];
    removedOperation &&
      removeOperationNodeAndChildrenFromOperations(
        flowOperations,
        removedOperation.child_operations.map((operation: { id: number }) => operation.id),
      );
  });
};

const addOperationToState = (newState: FlowReducerState, payload: any) => {
  // Add to Operations List
  newState.flowOperations[payload.operation.id] = payload.operation;

  // Add to Children of a node
  if (payload.parent_node_type === NODE_TYPES.OPERATION) {
    newState.flowOperations[payload.parent_node_id].child_operations.push({
      id: payload.operation.id,
      order: payload.operation.order,
    });
  } else if (payload.parent_node_type === NODE_TYPES.DATASET_FLOW_NODE) {
    const parentNode = _.findWhere(newState.datasetNodes, { id: payload.parent_node_id });
    parentNode!.operation_nodes.push({ id: payload.operation.id, order: payload.operation.order });
  } else {
    throw new Error('Invalid parent node type in flow graph: ' + payload.parent_node_type);
  }
};

export default (
  state: FlowReducerState = flowReducerInitialState,
  action: Action,
): FlowReducerState => {
  const { payload } = action;
  const newState = cloneDeep(state);

  switch (action.type) {
    case 'FETCH_FLOW_DATA_REQUEST':
      newState.loading = true;
      newState.error = false;
      newState.flowInfo = null;
      return newState;
    case 'FETCH_FLOW_DATA_ERROR':
      newState.loading = false;
      newState.error = true;
      newState.flowInfo = null;
      return newState;
    case 'FETCH_FLOW_DATA_SUCCESS':
      newState.loading = false;
      newState.error = false;
      newState.flowInfo = {
        id: payload.id,
        name: payload.name,
        data_source: payload.data_source,
        dataset_nodes: [],
        last_refreshed_at: payload.last_refreshed_at,
      };

      const sortedOps: Operation[] = _.sortBy(action.payload.operations, 'order');
      const operationData: OperationDictionary = {};
      sortedOps.map((operation: Operation) => {
        return (operationData[operation.id] = {
          ...operation,
          collapsed: true,
          error_msg: null,
          compute_loading: operation.results_dataset && operation.results_dataset.loading,
          results_job_error:
            operation.results_dataset && operation.results_dataset.results_job_error,
        });
      });
      newState.flowOperations = operationData;
      newState.datasetNodes = payload.dataset_nodes;

      newState.flowViewConfig = payload.flow_view_config || {
        scale: 1,
        offset_left: 4500,
        offset_top: 0,
        selected_operation_id: null,
      };

      return newState;
    case 'FETCH_DATASET_ROW_COUNT_REQUEST':
      const node = _.findWhere(newState.datasetNodes, { id: payload.datasetNodeId });
      if (node) {
        node.rowError = false;
      }
      return newState;
    case 'FETCH_DATASET_ROW_COUNT_ERROR':
      const dataNode = _.findWhere(newState.datasetNodes, { id: payload.datasetNodeId });
      if (dataNode) {
        dataNode.rowError = true;
      }
      return newState;
    case 'FETCH_DATASET_ROW_COUNT_SUCCESS':
      const datasetNode = _.findWhere(newState.datasetNodes, { id: payload.datasetNodeId });
      if (datasetNode) {
        datasetNode.dataset.total_row_count = payload.row_count;
        datasetNode.rowError = false;
      }
      return newState;
    case 'CREATE_FLOW_FROM_DATA_SET_REQUEST':
      newState.createFlowLoading = true;
      return newState;
    case 'CREATE_FLOW_FROM_DATA_SET_ERROR':
    case 'CREATE_FLOW_FROM_DATA_SET_SUCCESS':
      newState.createFlowLoading = false;
      return newState;
    case 'ADD_FLOW_STEP_REQUEST':
      newState.disableAddStepBlock = true;
      newState.newFlowStepLoading = true;
      return newState;
    case 'ADD_FLOW_STEP_SUCCESS':
      // Delete loading board
      newState.disableAddStepBlock = false;
      newState.newFlowStepLoading = false;
      newState.recentlyAddedOpId = payload.operation.id;
      addOperationToState(newState, payload);
      return newState;
    case 'ADD_DATASET_TO_FLOW_REQUEST':
      newState.addDatasetLoading = true;
      return newState;
    case 'ADD_DATASET_TO_FLOW_SUCCESS':
      newState.addDatasetLoading = false;
      newState.datasetNodes.push(payload.dataset_node);
      return newState;

    case 'ADD_DATASET_TO_FLOW_ERROR':
      newState.addDatasetLoading = false;
      return newState;
    case 'CLEAR_RECENTLY_ADDED_OP_ID':
      newState.recentlyAddedOpId = null;
      return newState;
    case 'COMPUTE_EXISTING_STEP_PREVIEW_REQUEST':
      newState.flowOperations[payload.operationId].compute_loading = true;
      newState.flowOperations[payload.operationId].results_job_error = null;
      return newState;
    case 'COMPUTE_EXISTING_STEP_PREVIEW_SUCCESS':
      newState.flowOperations[payload.operationId].results_dataset =
        payload.operation.results_dataset;
      newState.flowOperations[payload.operationId].compute_loading = false;
      newState.flowOperations[payload.operationId].up_to_date = true;

      for (let i = 0; i < payload.outOfDateOperations.length; i++) {
        const outdatedOpId = payload.outOfDateOperations[i];
        if (outdatedOpId in newState.flowOperations) {
          newState.flowOperations[outdatedOpId].up_to_date = false;
        }
      }
      return newState;
    case 'STEP_PREVIEW_FAILED':
      const res_dataset: Dataset = newState.flowOperations[payload.operationId].results_dataset;

      if (res_dataset) {
        res_dataset.total_row_count = null;
        res_dataset.cached_preview = null;
        res_dataset.cached_schema = null;
      }
      newState.flowOperations[payload.operationId].results_job_error = payload.resultsJobError;
      newState.flowOperations[payload.operationId].compute_loading = false;
      return newState;
    case 'UPDATE_FLOW_OPERATION_REQUEST':
      newState.flowOperations[payload.operationId] = payload.newOperationData;
      newState.flowOperations[payload.operationId].compute_loading = true;
      return newState;
    case 'UPDATE_FLOW_OPERATION_SUCCESS':
      newState.flowOperations[payload.operationId].compute_loading = false;
      return newState;
    case 'DELETE_OPERATION_REQUEST':
      removeOperationNodeAndChildrenFromOperations(newState.flowOperations, [payload.operationId]);

      // Delete from Dataset Flow node children
      newState.datasetNodes.forEach((datasetNode: DatasetFlowNode) => {
        const index = _.findIndex(datasetNode.operation_nodes, (op: { id: number }) => {
          return op.id === payload.operationId;
        });
        removeElementAtIndex(index, datasetNode.operation_nodes);
      });

      // Delete from Operation node children
      Object.values(newState.flowOperations).forEach((operation: Operation) => {
        if (operation.child_operations) {
          const index = _.findIndex(operation.child_operations, (op: { id: number }) => {
            return op.id === payload.operationId;
          });
          removeElementAtIndex(index, operation.child_operations);
        }
      });
      return newState;
    case 'UPDATE_OPERATION_PREVIEW':
      const results_dataset = newState.flowOperations[payload.operationId].results_dataset;
      results_dataset.total_row_count = payload.totalRows;
      results_dataset.cached_preview = payload.cachedPreview;
      results_dataset.cached_schema = payload.cachedSchema;
      newState.flowOperations[payload.operationId].results_job_error = null;
      newState.flowOperations[payload.operationId].compute_loading = false;
      return newState;
    case 'REMOVE_DATASET_FROM_FLOW_REQUEST':
      const datasetNodeId = payload.postData.dataset_node_id;

      // Remove all Dataset Node descendants from the Operations List
      const dataset = _.findWhere(newState.datasetNodes, { id: datasetNodeId })!;
      removeOperationNodeAndChildrenFromOperations(
        newState.flowOperations,
        dataset.operation_nodes.map((operation: { id: number }) => operation.id),
      );

      // Splice the dataset node out of the list
      newState.datasetNodes.splice(
        _.findIndex(newState.datasetNodes, (node: DatasetFlowNode) => node.id === datasetNodeId),
        1,
      );
      return newState;
    case 'FETCH_OPERATION_ROW_COUNT_REQUEST':
      if (newState.flowOperations[payload.id]) newState.flowOperations[payload.id].rowError = false;
      return newState;
    case 'FETCH_OPERATION_ROW_COUNT_ERROR':
      newState.flowOperations[payload.id].rowError = true;
      return newState;
    case 'FETCH_OPERATION_ROW_COUNT_SUCCESS':
      const operationToUpdateRowCount = newState.flowOperations[payload.id];
      operationToUpdateRowCount.results_dataset.total_row_count = payload.row_count;
      operationToUpdateRowCount.rowError = false;
      return newState;
    case 'REFRESH_FLOW_OPERATION_REQUEST':
      newState.flowOperations[payload.id].compute_loading = true;
      return newState;
    case 'REFRESH_FLOW_OPERATION_SUCCESS':
      newState.flowOperations[payload.id] = payload['operation'];
      newState.flowOperations[payload.id].compute_loading = false;
      return newState;
    case 'REFRESH_FLOW_OPERATION_ERROR':
      newState.flowOperations[payload.id].compute_loading = false;
      return newState;
    case 'REFRESH_FLOW_SUCCESS':
      if (newState.flowInfo) newState.flowInfo.last_refreshed_at = payload.last_refreshed_at;
      return newState;
    case 'REFRESH_DATASET_FLOW_NODE_REQUEST':
      let newDataNodes = newState.datasetNodes.map((datasetNode: DatasetFlowNode) => {
        if (datasetNode.dataset.id === payload.id) {
          datasetNode.compute_loading = true;
          datasetNode.dataset.total_row_count = null;
        }
        return datasetNode;
      });
      newState.datasetNodes = newDataNodes;
      return newState;
    case 'REFRESH_DATASET_FLOW_NODE_SUCCESS':
      let newDatasetNodes = newState.datasetNodes.map((datasetNode: DatasetFlowNode) => {
        if (datasetNode.dataset.id === payload.id) {
          datasetNode.dataset = payload.dataset;
          datasetNode.compute_loading = false;
        }
        return datasetNode;
      });
      newState.datasetNodes = newDatasetNodes;
      return newState;
    case 'REFRESH_DATASET_FLOW_NODE_ERROR':
      let newDNodes = newState.datasetNodes.map((datasetNode: DatasetFlowNode) => {
        if (datasetNode.dataset.id === payload.id) {
          datasetNode.compute_loading = false;
        }
        return datasetNode;
      });
      newState.datasetNodes = newDNodes;
      return newState;
    case 'RENAME_FLOW_SUCCESS':
      newState.flowInfo!.name = payload.postData.name;
      return newState;
    case 'UPDATE_OPERATION_FLYOUT_STATE':
      newState.isFlyoutOpen = payload.isFlyoutOpen;
      return newState;
    default:
      return newState;
  }
};

/** @format */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Spinner, Intent } from '@blueprintjs/core';

import DashboardLayout from 'components/dashboard/dashboardLayout';
import PageHeader from 'components/pages/pageHeader';

import {
  fetchSharedDashboard,
  checkUserDashboardAccess,
  fetchSharedDashboardOperationRowCount,
} from 'actions/shareActions';
import { Operation } from 'actions/types';

const styles = (theme: Theme) => ({
  root: {},
  loadingBody: {
    height: 'calc(100vh - 64px)',
    display: 'flex',
    justifyContent: 'center',
  },
});

type PassedProps = {};

type MatchParams = {
  dashboardHash: string;
};

type Props = PassedProps &
  RouteComponentProps<MatchParams> &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles>;

export class SharedPageWithLeftNav extends Component<Props> {
  constructor(props: Props) {
    super(props);
    if (props.userLoggedIn) {
      props.checkUserDashboardAccess({ id: props.match.params.dashboardHash }, (response: any) => {
        if (response.has_access) {
          props.history.push(`/dashboards/${response.dashboard_id}`);
        } else {
          props.fetchSharedDashboard({ id: props.match.params.dashboardHash }, (response: any) => {
            this.fetchDashboardOperationRowCounts(
              response.dashboard.operations,
              props.match.params.dashboardHash,
            );
          });
        }
      });
    } else {
      props.fetchSharedDashboard({ id: props.match.params.dashboardHash }, (response: any) => {
        this.fetchDashboardOperationRowCounts(
          response.dashboard.operations,
          props.match.params.dashboardHash,
        );
      });
    }
  }

  fetchDashboardOperationRowCounts = (operations: Operation[], dashboardHash: string) => {
    operations.map((operation: Operation) =>
      this.props.fetchSharedDashboardOperationRowCount({
        id: dashboardHash,
        postData: { operation_id: operation.id },
      }),
    );
  };

  render() {
    return (
      <>
        {this.renderHeader()}
        {this.renderDashboardBody()}
      </>
    );
  }

  renderHeader = () => {
    const { dashboardData } = this.props;

    return (
      <PageHeader title={dashboardData ? dashboardData.name : ''} titleIcon="timeline-line-chart" />
    );
  };

  renderDashboardBody() {
    const { classes, dashboardLoading } = this.props;

    if (dashboardLoading) {
      return (
        <div className={classes.loadingBody}>
          <Spinner intent={Intent.PRIMARY} size={Spinner.SIZE_STANDARD} />
        </div>
      );
    } else {
      return this.renderDashboard();
    }
  }

  renderDashboard = () => {
    const { dashboardData } = this.props;
    return <DashboardLayout viewOnly dashboardData={dashboardData} />;
  };
}

const mapStateToProps = (state: ReduxState) => ({
  userLoggedIn: state.currentUser.id !== null && state.currentUser.id !== undefined,
  dashboardData: state.shareData.dashboard.data,
  dashboardLoading: state.shareData.dashboard.loading,
});

const mapDispatchToProps = {
  fetchSharedDashboard,
  fetchSharedDashboardOperationRowCount,
  checkUserDashboardAccess,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SharedPageWithLeftNav)),
);

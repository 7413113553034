/** @format */

import React from 'react';
import _ from 'underscore';
import { withRouter } from 'react-router-dom';

import ClauseBuilder from 'components/flowBuilder/clauseBuilder';
import JoinEditOperationBody from 'components/flowBuilder/editOperationDrawer/joinEditOperationBody';
import StepBlockTag from 'components/flowBuilder/stepBlockTag';

import {
  joinClauseComplete,
  joinInstructionsChanged,
  joinInstructionsComplete,
} from 'utils/flowUtils';
import { trackEvent } from 'analytics/exploAnalytics';
import { JOIN_TYPES } from 'constants/flowConstants';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { DatasetCacheReducerState } from 'reducers/datasetCacheReducer';
import { EnrichOperationInstructions } from 'constants/types';
import { RouteComponentProps } from 'react-router';
import { Dataset } from 'actions/types';

const styles = (theme: Theme) =>
  createStyles({
    fillerText: {
      fontSize: 16,
      fontWeight: 400,
    },
  });

type PassedProps = {
  clearRecentlyAddedOpId: () => {
    type: string;
  };
  computeLoading?: boolean;
  datasetCacheData: DatasetCacheReducerState;
  flowId: number;
  instructions: EnrichOperationInstructions;
  openFlyout?: boolean;
  operationId: number;
  scrollToColumn: (columnName: string) => void;
  sourceDataset: Dataset;
  sourceDataSourceId: number;
  updateOperationInstructionAndCompute: (
    operationId: number,
    newInstructions: EnrichOperationInstructions,
    shouldCompute: boolean,
  ) => void;
  summaryView?: boolean;
};
type Props = PassedProps & WithStyles<typeof styles> & RouteComponentProps;
type State = {
  editDrawerOpen: boolean;
};

class JoinOperationClauseBuilder extends React.PureComponent<Props, State> {
  readonly state: State = {
    editDrawerOpen: this.props.openFlyout || false,
  };
  constructor(props: Props) {
    super(props);

    if (props.openFlyout) {
      props.clearRecentlyAddedOpId();
    }
  }

  render() {
    const { computeLoading, instructions, summaryView } = this.props;
    const { editDrawerOpen } = this.state;

    return (
      <ClauseBuilder
        computeLoading={computeLoading}
        editDrawerOpen={editDrawerOpen}
        openDrawer={() => this.setState({ editDrawerOpen: true })}
        closeDrawer={() => this.setState({ editDrawerOpen: false })}
        editOperationDrawerTitle="Define the join operation"
        tooltipSuggestionIsOpen={!instructions.joinTable || !joinInstructionsComplete(instructions)}
        editOperationBody={this.renderOperationEditBody()}
        clauseText={this.renderJoinText()}
        summaryView={summaryView}
      />
    );
  }

  renderOperationEditBody = () => {
    const {
      sourceDataset,
      instructions,
      operationId,
      sourceDataSourceId,
      datasetCacheData,
    } = this.props;

    const joinTableData = datasetCacheData && datasetCacheData[instructions.joinTable.datasetId];

    return (
      <JoinEditOperationBody
        instructions={instructions}
        joinDataset={joinTableData}
        operationId={operationId}
        sourceDataset={sourceDataset}
        sourceDataSourceId={sourceDataSourceId}
        onSubmit={(newInstructions: EnrichOperationInstructions) => {
          const {
            operationId,
            updateOperationInstructionAndCompute,
            flowId,
            instructions,
          } = this.props;
          newInstructions.joinTable.flowId = flowId;
          newInstructions.joinTable.operationId = operationId;

          const instructionHasChanged = joinInstructionsChanged(instructions, newInstructions);
          updateOperationInstructionAndCompute(
            operationId,
            newInstructions,
            instructionHasChanged && joinInstructionsComplete(newInstructions),
          );
          this.setState({ editDrawerOpen: false });
          trackEvent('Update and compute join', {
            operation_id: operationId,
            new_instructions: newInstructions,
            join_instructions_changed: instructionHasChanged,
          });
        }}
      />
    );
  };

  renderJoinText = (): JSX.Element[] | null => {
    const { classes, operationId, instructions, scrollToColumn } = this.props;
    const completeClauses = instructions.columnsToJoinOn.filter(joinClauseComplete);

    if (!instructions.joinTable.datasetId) {
      return null;
    }
    const stepBuilderBlockInfo = [
      <div className={classes.fillerText} key={`join_with_${operationId}`}>
        with
      </div>,
    ];
    stepBuilderBlockInfo.push(
      <StepBlockTag
        key={`table_name_${operationId}`}
        interactive={true}
        toolTipContent={'View the dataset'}
        href={`/datasets/${instructions.joinTable.datasetId}`}
        content={instructions.joinTable.name}
      />,
    );
    if (completeClauses.length === 0) {
      return stepBuilderBlockInfo;
    }
    stepBuilderBlockInfo.push(
      <div className={classes.fillerText} key={`join_on_${operationId}`}>
        on
      </div>,
    );
    _.each(completeClauses, (clause, index) => {
      stepBuilderBlockInfo.push(
        <StepBlockTag
          key={`clause_${index}_${operationId}`}
          interactive={true}
          onClick={() => scrollToColumn(clause.sourceColumn)}
          content={`${clause.sourceColumn} = ${clause.joinColumn}`}
        />,
      );

      if (index !== completeClauses.length - 1) {
        stepBuilderBlockInfo.push(
          <div className={classes.fillerText} key={`clause_${index}_${operationId}_and`}>
            and
          </div>,
        );
      }
    });
    stepBuilderBlockInfo.push(
      <div className={classes.fillerText} key={`join_using_${operationId}`}>
        using
      </div>,
    );
    stepBuilderBlockInfo.push(
      <StepBlockTag
        key={`join_type_${operationId}`}
        content={JOIN_TYPES[instructions.joinType].name}
      />,
    );
    return stepBuilderBlockInfo;
  };
}

export default withRouter(withStyles(styles)(JoinOperationClauseBuilder));

/** @format */

import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import { Button, Popover, Menu, MenuItem, H6, Icon, Classes } from '@blueprintjs/core';

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    border: `1px solid ${theme.palette.grey.border}`,
    borderRadius: 4,
    color: theme.palette.black,

    '&:hover': {
      color: theme.palette.black,
      backgroundColor: theme.palette.grey.light,
      textDecoration: 'none',
    },

    '&:active': {
      backgroundColor: theme.palette.grey.middle,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 24,
  },
  name: {
    'white-space': 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 250,
  },
  cardTopContainer: {
    width: '100%',
    backgroundColor: theme.palette.grey800,
    position: 'relative' as 'relative',
  },
  cardTopImg: {
    width: '100%',
  },
  cardInfo: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
  },
  extraInfoText: {
    color: theme.palette.grey.backgroundGrey,
  },
  menuPopoverWrapper: {
    position: 'absolute' as 'absolute',
    top: 0,
    right: 0,
  },
  menuBtn: {
    margin: theme.spacing(2),
  },
});

type PassedProps = {
  className?: string;
  id: number;
  name: string;
  onRenameClicked: () => void;
  onMoveClicked: () => void;
  onDeleteClicked: () => void;
  isPersonalFlowPanel: boolean;
  extraInfoText: string;
  flowVizImg: any;
};

type Props = PassedProps & WithStyles<typeof styles>;

class FlowPanel extends React.Component<Props> {
  render() {
    const { className, classes, id } = this.props;

    return (
      <Link className={cx(classes.root, className)} to={`/flows/${id}`}>
        {this.renderCardTop()}
        {this.renderCardInfo()}
      </Link>
    );
  }

  renderCardInfo = () => {
    const { classes, name, extraInfoText } = this.props;

    return (
      <div className={classes.cardInfo}>
        <div className={classes.header}>
          <H6 className={classes.name}>{name}</H6>
        </div>
        <div className={cx('bp3-text-small', classes.extraInfoText)}>{extraInfoText}</div>
      </div>
    );
  };

  renderCardTop = () => {
    const { classes, flowVizImg } = this.props;
    //
    return (
      <div className={classes.cardTopContainer}>
        <img src={flowVizImg} className={classes.cardTopImg} alt="Exploration visualization pic" />
        {this.renderActionMenuButton()}
      </div>
    );
  };

  renderActionMenuButton = () => {
    const {
      classes,
      isPersonalFlowPanel,
      onRenameClicked,
      onMoveClicked,
      onDeleteClicked,
    } = this.props;
    return (
      <Popover enforceFocus={false} hoverCloseDelay={0} className={classes.menuPopoverWrapper}>
        <Button
          icon={<Icon color="#FFF" icon="more" />}
          minimal
          onClick={(e: any) => e.preventDefault()}
          className={classes.menuBtn}
        />
        <Menu>
          <MenuItem
            className={Classes.POPOVER_DISMISS}
            icon="edit"
            text="Rename"
            onClick={(e: any) => {
              onRenameClicked();
              e.preventDefault();
            }}
          />
          <MenuItem
            icon={isPersonalFlowPanel ? 'people' : 'person'}
            text={isPersonalFlowPanel ? 'Share with team' : 'Make private'}
            onClick={(e: any) => {
              onMoveClicked();
              e.preventDefault();
            }}
          />
          <MenuItem
            icon="trash"
            text="Delete"
            onClick={(e: any) => {
              onDeleteClicked();
              e.preventDefault();
            }}
          />
        </Menu>
      </Popover>
    );
  };
}

export default withStyles(styles)(FlowPanel);

/** @format */

import { createContext, SetStateAction, Dispatch } from 'react';
import { flowGraphMapDispatchToProps, SelectedNode } from 'pages/flowGraphPage/flowGraphPageV3';

interface FlowGraphHooks {
  closeAddStepModal: () => void;
  openAddStepModal: () => void;
  unselectNode: () => void;
  setSelectedNode: Dispatch<SetStateAction<SelectedNode | undefined>>;
}

type ContextProps = typeof flowGraphMapDispatchToProps & FlowGraphHooks;
export const FlowGraphApi = createContext<ContextProps | undefined>(undefined);

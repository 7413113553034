/** @format */

import React from 'react';
import { ReduxState } from 'reducers/rootReducer';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { withStyles, WithStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

type MatchParams = {};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams> &
  WithStyles<typeof styles>;

class SetupPage extends React.Component<Props> {
  render() {
    let { classes } = this.props;

    return <div className={classes.root}>Analytics Page</div>;
  }
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SetupPage));

/** @format */

import React from 'react';
import cx from 'classnames';

import { Intent, Button, Spinner } from '@blueprintjs/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { ActionFn } from 'actions/actionUtils';
import SelectDatasetModal from 'components/fileSystem/selectDatasetModal';
import { Dataset, DatasetFlowNode, ACTION, Flow } from 'actions/types';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { createLoadingSelector } from 'reducers/api/selectors';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(6),
    backgroundColor: theme.palette.primary.veryLight,
    width: '100vw',
    height: theme.spacing(20),
  },
  bottomBorder: {
    borderBottom: `1px solid ${theme.palette.grey.slight}`,
  },
  backButton: {
    marginRight: theme.spacing(4),
  },
  genericButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    padding: `7px ${theme.spacing(3)}px`,
    fontWeight: 500,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  updateDataButton: {
    margin: `0px ${theme.spacing(4)}px`,
    backgroundColor: theme.palette.grey.slight,
  },
  addDatasetButton: {
    backgroundColor: theme.palette.darkBlue,
    color: theme.palette.white,
  },
  leftHeaderContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 24,
  },
  rightHeaderContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

type PassedProps = {
  addDatasetModalOpen: boolean;
  addDatasetToFlow: ActionFn;
  fetchDatasetRowCount: ActionFn;
  fetchOperationRowCount: ActionFn;
  refreshDatasetFlowNode: ActionFn;
  refreshFlow: ActionFn;
  refreshFlowOperation: ActionFn;
  setAddDatasetModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  displayBottomBorder: boolean;
  navigateBackToDataSource: () => void;
};

type Props = ReturnType<typeof mapStateToProps> & PassedProps;

const refreshFlowOnClick = (props: Props) => {
  const {
    flowData,
    refreshDatasetFlowNode,
    refreshFlowOperation,
    refreshFlow,
    fetchDatasetRowCount,
    fetchOperationRowCount,
  } = props;

  // update dataset nodes
  flowData.datasetNodes.forEach((datasetNode: DatasetFlowNode) =>
    refreshDatasetFlowNode({ id: datasetNode.dataset.id }, () =>
      //@ts-ignore
      fetchDatasetRowCount({ id: datasetNode.dataset.id, datasetNodeId: datasetNode.id }),
    ),
  );

  // Updating Operations
  const opIds: number[] = Object.keys(flowData.flowOperations).map((key: string) => parseInt(key));
  opIds.forEach((opId: number) =>
    refreshFlowOperation(
      {
        id: opId,
        postData: {
          updating_op_ids: opIds,
        },
      },
      () => fetchOperationRowCount({ id: opId }),
    ),
  );

  // Updating Flow
  if (flowData.flowInfo) refreshFlow({ id: flowData.flowInfo.id });
};

const renderAddDatasetSubmitBtn = (
  selectedDataset: Dataset | null,
  onClose: () => void,
  props: Props,
) => {
  const { flowData, fetchDatasetRowCount, addDatasetToFlow } = props;
  return (
    <Button
      loading={flowData.addDatasetLoading}
      intent={Intent.PRIMARY}
      disabled={selectedDataset === null || flowData.addDatasetLoading}
      onClick={() => {
        selectedDataset &&
          addDatasetToFlow(
            {
              id: flowData.flowInfo!.id,
              postData: { dataset_id: selectedDataset.id },
            },
            (response: any) => {
              onClose();
              fetchDatasetRowCount({
                id: response.dataset_node.dataset.id,
                // @ts-ignore
                datasetNodeId: response.dataset_node.id,
              });
            },
          );
      }}>
      Add to Exploration
    </Button>
  );
};

const renderAddDatasetModal = (props: Props) => {
  const { addDatasetModalOpen, setAddDatasetModalOpen } = props;
  return (
    <SelectDatasetModal
      isOpen={addDatasetModalOpen}
      onClose={() => setAddDatasetModalOpen(false)}
      renderDatasetActions={(selectedDataset: Dataset | null, onClose: () => void) =>
        renderAddDatasetSubmitBtn(selectedDataset, onClose, props)
      }
      modalTitle="Select a dataset to add to the exploration"
      editingDisabled
    />
  );
};

const renderLastRefreshedAt = (refreshFLowLoading: boolean, flowInfo?: Flow | null) => {
  if (refreshFLowLoading) {
    return <Spinner size={17} />;
  } else {
    return (
      flowInfo &&
      flowInfo.last_refreshed_at &&
      `Last refreshed ${moment(flowInfo.last_refreshed_at).fromNow()}`
    );
  }
};

const FlowGraphPageHeader = (props: Props) => {
  const classes = useStyles();
  const {
    flowDataLoading,
    flowData,
    setAddDatasetModalOpen,
    displayBottomBorder,
    navigateBackToDataSource,
    refreshFLowLoading,
  } = props;

  if (flowDataLoading) return <></>;
  return (
    <div
      className={cx(classes.root, {
        [classes.bottomBorder]: displayBottomBorder,
      })}>
      <div className={classes.leftHeaderContent}>
        <Button
          className={classes.backButton}
          icon="arrow-left"
          large
          minimal
          onClick={navigateBackToDataSource}
        />
        {/* <Icon className={classes.icon} icon="arrow-left" iconSize={Icon.SIZE_LARGE} /> */}
        {flowData.flowInfo && flowData.flowInfo.name}
      </div>
      <div className={classes.rightHeaderContent}>
        {renderLastRefreshedAt(refreshFLowLoading, flowData.flowInfo)}
        {/* Will be wrapped in button component soon */}
        <div
          className={cx(classes.updateDataButton, classes.genericButton)}
          onClick={() => refreshFlowOnClick(props)}>
          Refresh Data
        </div>
        <div
          className={cx(classes.addDatasetButton, classes.genericButton)}
          onClick={() => setAddDatasetModalOpen(true)}>
          Add Dataset
        </div>
      </div>
      {renderAddDatasetModal(props)}
    </div>
  );
};

const isFlowDataLoadingSelector = createLoadingSelector([ACTION.FETCH_FLOW_DATA]);
const isRefreshFlowLoadingSelector = createLoadingSelector([ACTION.REFRESH_FLOW], false);
const mapStateToProps = (state: ReduxState) => ({
  flowData: state.flowData,
  flowDataLoading: isFlowDataLoadingSelector(state),
  refreshFLowLoading: isRefreshFlowLoadingSelector(state),
  loadingIndividualActions: state.workspaceData.loadingIndividualActions,
});

export default connect(mapStateToProps, {})(FlowGraphPageHeader);

/** @format */

import React from 'react';
import moment from 'moment';
import { FormGroup, InputGroup, Intent } from '@blueprintjs/core';

import {
  FILTER_OPS_NO_VALUE,
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
} from 'constants/flowConstants';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const filterStyles = makeStyles((theme) => ({
  datePicker: {
    padding: '6px 10px 5px',
    borderWidth: 0,
    borderRadius: 3,
    fontSize: 14,
    boxShadow: `inset 0 0 0 1px ${theme.palette.grey.border} !important`,
  },
  datePickerError: {
    boxShadow: `inset 0 0 0 1px ${theme.palette.dangerRed} !important`,
  },
  dateRange: {
    display: 'flex',
    flexDirection: 'row',
  },
  formGroupValidationErrorState: {
    marginBottom: 0,
  },
  formGroupValidationNoError: {
    marginBottom: 20,
  },
}));

const convertDashDateToSlashDate = (date: string) => {
  return date.replace(/-/g, '/');
};

interface Props {
  filterOperation: any;
  filterColumn: any;
  filterValue: any;
  index: number;
  updateFilterValue: (index: number, value: any) => void;
  updateStartDate: (index: number, startDate: string | null) => void;
  updateEndDate: (index: number, endDate: string | null) => void;
  filterValueStartDateError: boolean;
  filterValueEndDateError: boolean;
  filterValueErrorText?: string;
  filterValueInputIntent: Intent;
}

const FilterClauseEditorRow = (props: Props) => {
  const { filterValue, filterOperation, filterColumn, index } = props;
  const styles = filterStyles();

  if (!filterOperation || !filterColumn || FILTER_OPS_NO_VALUE.has(filterOperation.id)) {
    return <></>;
  }

  const VIEW_DATE_FORMAT = 'MM/DD/YYYY'; // we might want to support granular hours/minutes in the future
  const DATE_FORMAT = 'YYYY-MM-DD';
  if (FILTER_OPS_DATE_PICKER.has(filterOperation.id)) {
    let date = null;
    if (filterValue) {
      date = filterValue.startDate && new Date(convertDashDateToSlashDate(filterValue.startDate));
    }

    return (
      <DatePicker
        className={cx(styles.datePicker, {
          [styles.datePickerError]: props.filterValueStartDateError,
        })}
        selected={date}
        onChange={(date) => {
          props.updateStartDate(index, date ? moment(date).format(DATE_FORMAT) : null);
        }}
        placeholderText={VIEW_DATE_FORMAT}
        showMonthDropdown
        showYearDropdown
      />
    );
  } else if (FILTER_OPS_DATE_RANGE_PICKER.has(filterOperation.id)) {
    let startDate = null;
    let endDate = null;
    if (filterValue) {
      startDate =
        filterValue.startDate && new Date(convertDashDateToSlashDate(filterValue.startDate));
      endDate = filterValue.endDate && new Date(convertDashDateToSlashDate(filterValue.endDate));
    }
    return (
      <div className={styles.dateRange}>
        <DatePicker
          className={cx(styles.datePicker, {
            [styles.datePickerError]: props.filterValueStartDateError,
          })}
          selected={startDate}
          onChange={(startDate) => {
            props.updateStartDate(index, startDate ? moment(startDate).format(DATE_FORMAT) : null);
          }}
          placeholderText={VIEW_DATE_FORMAT}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          showMonthDropdown
          showYearDropdown
        />
        <DatePicker
          className={cx(styles.datePicker, {
            [styles.datePickerError]: props.filterValueEndDateError,
          })}
          selected={endDate}
          onChange={(endDate) => {
            props.updateEndDate(index, endDate ? moment(endDate).format(DATE_FORMAT) : null);
          }}
          placeholderText={VIEW_DATE_FORMAT}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          showMonthDropdown
          showYearDropdown
        />
      </div>
    );
  }

  return (
    <FormGroup
      className={
        props.filterValueErrorText
          ? styles.formGroupValidationErrorState
          : styles.formGroupValidationNoError
      }
      helperText={props.filterValueErrorText}
      intent={props.filterValueInputIntent}
      labelFor="text-input">
      <InputGroup
        type="text"
        intent={props.filterValueInputIntent}
        value={filterValue}
        className={cx('bp3-fill')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          props.updateFilterValue(index, e.target.value);
        }}
      />
    </FormGroup>
  );
};

export default FilterClauseEditorRow;

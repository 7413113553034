/** @format */

import { defineAction } from 'actions/actionUtils';
import { DataSource } from './types';

export const fetchDataSourceList = defineAction('FETCH_DATA_SOURCE_LIST', 'datasources', '', 'GET');

export const fetchDataSource = defineAction('FETCH_DATA_SOURCE', 'datasources', '', 'GET');

export const fetchSupportedDataSources = defineAction(
  'FETCH_SUPPORTED_DATA_SOURCES',
  'datasources',
  'supported_data_sources',
  'GET',
);

export const testDataSourceConnection = defineAction(
  'TEST_DATA_SOURCE_CONNECTION',
  'datasources',
  'test_connection',
  'POST',
);

export const connectDataSource = defineAction(
  'CONNECT_DATA_SOURCE',
  'datasources',
  'connect',
  'POST',
);

export const syncDataSource = defineAction('SYNC_DATA_SOURCE', 'datasources', 'sync', 'POST');

export const fetchWorkspaceData = defineAction(
  'FETCH_WORKSPACE_DATA',
  'datasources',
  'workspace',
  'GET',
);

export const deleteDataSource = defineAction('DELETE_DATA_SOURCE', 'datasources', '', 'DELETE');

export const switchSelectedDataSourceId = (dataSourceId: string | number) => ({
  type: 'SWITCH_SELECTED_DATA_SOURCE',
  payload: {
    dataSourceId,
  },
});

export const switchWorkspaceData = (dataSourceId: string) => ({
  type: 'SWITCH_WORKSPACE_DATA',
  payload: {
    dataSourceId,
  },
});

export const updateWorkspaceSearchState = (isWorkspaceSearchOpen: boolean) => ({
  type: 'UPDATE_WORKSPACE_SEARCH_STATE',
  payload: {
    isWorkspaceSearchOpen,
  },
});

export const setUserFlowsExpanded = (userFlowsExpanded: boolean) => ({
  type: 'UPDATE_USER_FLOWS_EXPANDED',
  payload: {
    userFlowsExpanded,
  },
});

export const setTeamFlowsExpanded = (teamFlowsExpanded: boolean) => ({
  type: 'UPDATE_TEAM_FLOWS_EXPANDED',
  payload: {
    teamFlowsExpanded,
  },
});

export const setUserCreatedFoldersIsCollapsed = (userCreatedFoldersIsCollapsed: boolean[]) => ({
  type: 'UPDATE_CREATED_FOLDERS_IS_COLLAPSED',
  payload: {
    userCreatedFoldersIsCollapsed,
  },
});

export const setSelectedDataSource = (dataSource: DataSource) => ({
  type: 'SET_SELECTED_DATA_SOURCE',
  payload: {
    dataSource,
  },
});

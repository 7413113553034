/** @format */
import { COLOR_LIST } from 'constants/colorConstants';
import { DECIMAL_ICON, TEXT_ICON, NUMBER_ICON } from 'constants/iconConstants';
import * as types from 'constants/types';
import { Dictionary } from 'underscore';
import { ROUTES } from './routes';
import { DatabaseTypes } from 'components/onboardingSetup/setupFlowDatabaseSelection';

export const INTEGER_DATA_TYPE: types.DataType = 'INTEGER';
export const UNSIGNED_INTEGER: types.DataType = 'unsigned integer';
export const FLOAT: types.DataType = 'FLOAT';
export const STRING: types.DataType = 'STRING';
export const BOOLEAN: types.DataType = 'BOOLEAN';
export const DATETIME: types.DataType = 'DATETIME';
export const DATE: types.DataType = 'DATE';
export const TIMESTAMP: types.DataType = 'TIMESTAMP';

export const SELECTABLE_DATA_TYPES: types.SelectableDataTypes = [
  STRING,
  INTEGER_DATA_TYPE,
  FLOAT,
  BOOLEAN,
  TIMESTAMP,
];

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

export const MOMENT_DATE_FORMATS = [
  {
    name: '09/20/1995',
    id: 'MM/DD/YYYY',
  },
  {
    name: '09-20-1995',
    id: 'MM-DD-YYYY',
  },
  {
    name: '09/20',
    id: 'MM/DD',
  },
  {
    name: 'Sep-20',
    id: 'MMM-DD',
  },
  {
    name: 'September 20th',
    id: 'MMMM Do',
  },
];

export const NUMBER_TYPES = new Set([INTEGER_DATA_TYPE, FLOAT]);

export const DATE_TYPES = new Set([TIMESTAMP, DATETIME, DATE]);

export const PREVIEW_ROW_COUNT = 30;

export const DATALIB_TYPES_TO_EXPLO_TYPES: Dictionary<string | null> = {
  boolean: BOOLEAN,
  integer: INTEGER_DATA_TYPE,
  number: FLOAT,
  date: null, // canot infer timestamp format
  string: STRING,
};

export const SCHEMA_DATA_TYPES_BY_ID: types.SchemaDataTypesById = {
  [INTEGER_DATA_TYPE]: {
    name: 'Integer',
    icon: [NUMBER_ICON],
  },
  [UNSIGNED_INTEGER]: {
    name: 'Unsigned Integer',
    icon: [NUMBER_ICON],
  },
  [FLOAT]: {
    name: 'Decimal',
    icon: [DECIMAL_ICON],
  },
  [STRING]: {
    name: 'Text',
    icon: [TEXT_ICON],
  },
  [BOOLEAN]: {
    name: 'Boolean',
    icon: 'tick',
  },
  [DATETIME]: {
    name: 'Date',
    icon: 'calendar',
  },
  [DATE]: {
    name: 'Date',
    icon: 'calendar',
  },
  [TIMESTAMP]: {
    name: 'Timestamp',
    icon: 'calendar',
    subitems: [
      {
        format: 'MM-dd-yy',
        name: '09-20-95',
        parentType: TIMESTAMP,
      },
      {
        format: 'MM-dd-yyyy',
        name: '09-20-1995',
        parentType: TIMESTAMP,
      },
      {
        format: 'MM/dd/yy',
        name: '09/20/95',
        parentType: TIMESTAMP,
      },
      {
        format: 'MM/dd/yyyy',
        name: '09/20/1995',
        parentType: TIMESTAMP,
      },
      {
        format: 'MM/dd/yy hh:mm:ss',
        name: '09/20/95 13:36:23',
        parentType: TIMESTAMP,
      },
      {
        format: 'MM/dd/yyyy hh:mm:ss',
        name: '09/20/1995 13:36:23',
        parentType: TIMESTAMP,
      },
      {
        format: 'Month dd, yyyy',
        name: 'September 20, 1995',
        parentType: TIMESTAMP,
      },
      // new addition
      {
        format: 'day, dd Month, yyyy',
        name: 'Wednesday, 20 September, 1995',
        parentType: TIMESTAMP,
      },
      {
        format: 'yyyyMMdd',
        name: '19950920',
        parentType: TIMESTAMP,
      },
      {
        format: 'Mon/yy',
        name: 'Sep/95',
        parentType: TIMESTAMP,
      },
      // new addition
      {
        format: 'yyyy/MM/dd hh:mm',
        name: '1995/09/20 13:36',
        parentType: TIMESTAMP,
      },
      // new addition
      {
        format: 'yyyy-MM-dd hh:mm',
        name: '1995-09-20 13:36',
        parentType: TIMESTAMP,
      },
      // new addition
      {
        format: 'yyyy-MM-ddThh:mm:ssZ',
        name: '2020-04-27T20:00:00Z',
        parentType: TIMESTAMP,
      },
    ],
  },
};

const DATE_TYPES_SORT_ORDERS = [
  {
    id: types.SortOrder.ASC,
    order: types.SortOrder.ASC,
    icon: 'sort-asc',
    name: 'Ascending',
  },
  {
    id: types.SortOrder.DESC,
    order: types.SortOrder.DESC,
    icon: 'sort-desc',
    name: 'Descending',
  },
];

export const SORT_ORDERS_BY_TYPE: {
  [type: string]: { id: types.SortOrder; icon: string; order: types.SortOrder; name: string }[];
} = {
  [INTEGER_DATA_TYPE]: [
    {
      id: types.SortOrder.ASC,
      order: types.SortOrder.ASC,
      icon: 'sort-numerical',
      name: 'Ascending',
    },
    {
      id: types.SortOrder.DESC,
      order: types.SortOrder.DESC,
      icon: 'sort-numerical-desc',
      name: 'Descending',
    },
  ],
  [FLOAT]: [
    {
      id: types.SortOrder.ASC,
      order: types.SortOrder.ASC,
      icon: 'sort-numerical',
      name: 'Ascending',
    },
    {
      id: types.SortOrder.DESC,
      order: types.SortOrder.DESC,
      icon: 'sort-numerical-desc',
      name: 'Descending',
    },
  ],
  [STRING]: [
    {
      id: types.SortOrder.ASC,
      order: types.SortOrder.ASC,
      icon: 'sort-alphabetical',
      name: 'Ascending',
    },
    {
      id: types.SortOrder.DESC,
      order: types.SortOrder.DESC,
      icon: 'sort-alphabetical-desc',
      name: 'Descending',
    },
  ],
  [BOOLEAN]: [
    {
      id: types.SortOrder.ASC,
      order: types.SortOrder.ASC,
      icon: 'sort-asc',
      name: 'False First',
    },
    {
      id: types.SortOrder.DESC,
      order: types.SortOrder.DESC,
      icon: 'sort-desc',
      name: 'True First',
    },
  ],
  [TIMESTAMP]: DATE_TYPES_SORT_ORDERS,
  [DATETIME]: DATE_TYPES_SORT_ORDERS,
  [DATE]: DATE_TYPES_SORT_ORDERS,
};

export const DEDUP_ORDERS_BY_TYPE: {
  [type: string]: { id: types.SortOrder; icon: string; order: types.SortOrder; name: string }[];
} = {
  [INTEGER_DATA_TYPE]: [
    {
      id: types.SortOrder.ASC,
      order: types.SortOrder.ASC,
      icon: 'sort-numerical',
      name: 'Minimum Value',
    },
    {
      id: types.SortOrder.DESC,
      order: types.SortOrder.DESC,
      icon: 'sort-numerical-desc',
      name: 'Maximum Value',
    },
  ],
  [FLOAT]: [
    {
      id: types.SortOrder.ASC,
      order: types.SortOrder.ASC,
      icon: 'sort-numerical',
      name: 'Minimum Value',
    },
    {
      id: types.SortOrder.DESC,
      order: types.SortOrder.DESC,
      icon: 'sort-numerical-desc',
      name: 'Maximum Value',
    },
  ],
  [STRING]: [
    {
      id: types.SortOrder.ASC,
      order: types.SortOrder.ASC,
      icon: 'sort-alphabetical',
      name: 'First Alphabetical',
    },
    {
      id: types.SortOrder.DESC,
      order: types.SortOrder.DESC,
      icon: 'sort-alphabetical-desc',
      name: 'Last Alphabetical',
    },
  ],
  [BOOLEAN]: [
    {
      id: types.SortOrder.ASC,
      order: types.SortOrder.ASC,
      icon: 'sort-asc',
      name: 'First False',
    },
    {
      id: types.SortOrder.DESC,
      order: types.SortOrder.DESC,
      icon: 'sort-desc',
      name: 'First True',
    },
  ],
  [TIMESTAMP]: DATE_TYPES_SORT_ORDERS,
  [DATETIME]: DATE_TYPES_SORT_ORDERS,
  [DATE]: DATE_TYPES_SORT_ORDERS,
};

export const OPERATION_TYPES: types.OperationTypes = {
  LOADING: {
    id: 'LOADING',
    name: 'Loading',
  },
  PREVIEW: {
    id: 'PREVIEW',
    name: 'Preview',
    icon: 'eye-open',
  },
  FILTER: {
    id: 'FILTER',
    name: 'Filter',
    icon: 'filter',
  },
  ENRICH: {
    id: 'ENRICH',
    name: 'Join',
    icon: 'inner-join',
  },
  BAR_CHART: {
    id: 'BAR_CHART',
    name: 'Pivot Chart',
    icon: 'vertical-bar-chart-desc',
  },
  PIVOT: {
    id: 'PIVOT',
    name: 'Pivot',
    icon: 'pivot',
    help_center_link: 'https://help.explo.co/pivots',
  },
  CHANGE_SCHEMA: {
    id: 'CHANGE_SCHEMA',
    name: 'Edit Columns',
    icon: 'edit',
    help_center_link: 'https://help.explo.co/how-do-i-edit-columns-on-a-table',
  },
  CALCULATE: {
    id: 'CALCULATE',
    name: 'Column Calculations',
    icon: 'calculator',
    help_center_link: 'https://help.explo.co/column-statistics',
  },
  FORMULA: {
    id: 'FORMULA',
    name: 'Formula',
    icon: 'derive-column',
  },
  LINE_CHART: {
    id: 'LINE_CHART',
    name: 'Line Chart',
    icon: 'timeline-line-chart',
    help_center_link: 'https://help.explo.co/line-chart',
  },
  GROUPED_BAR_CHART: {
    id: 'GROUPED_BAR_CHART',
    name: 'Bar Chart',
    icon: 'grouped-bar-chart',
  },
  UNION: {
    id: 'UNION',
    name: 'Union',
    icon: 'add-row-bottom',
  },
  DEDUP: {
    id: 'DEDUP',
    name: 'Remove Duplicates',
    icon: 'duplicate',
    help_center_link: 'https://help.explo.co/remove-duplicates',
  },
  MAP: {
    id: 'MAP',
    name: 'Map',
    icon: 'map',
    disabled: true,
  },
  SORT: {
    id: 'SORT',
    name: 'Sort',
    icon: 'sort',
  },
  AXIS_PIVOT_CHART: {
    id: 'AXIS_PIVOT_CHART',
    name: 'Axis Pivot Table',
    icon: 'pivot-table',
  },
};

export const GRAPHS = [
  OPERATION_TYPES.BAR_CHART,
  OPERATION_TYPES.LINE_CHART,
  OPERATION_TYPES.GROUPED_BAR_CHART,
];

const OPERATIONS_BY_ID: types.OperationsByIdType = {};
Object.keys(OPERATION_TYPES).map((key) => (OPERATIONS_BY_ID[key] = OPERATION_TYPES[key]));

export const GRAPH_OPERATIONS_SET = new Set(GRAPHS.map((graph) => graph.id));

export const AGGREGATIONS_TYPES: types.AggregationTypes = {
  COUNT: {
    id: 'COUNT',
    name: 'Count',
    selectionValue: 'Count',
  },
  COUNT_DISTINCT: {
    id: 'COUNT_DISTINCT',
    name: 'Count Distinct',
    selectionValue: 'Count Distinct',
  },
  AVG: {
    id: 'AVG',
    name: 'Avg',
    selectionValue: 'Avg',
  },
  SUM: {
    id: 'SUM',
    name: 'Sum',
    selectionValue: 'Sum',
  },
  MIN: {
    id: 'MIN',
    name: 'Min',
    selectionValue: 'Min',
  },
  MAX: {
    id: 'MAX',
    name: 'Max',
    selectionValue: 'Max',
  },
  // CORRELATION: {
  //   id: 'CORRELATION',
  //   name: 'Correlation',
  //   selectionValue: 'Correlation',
  // },
};

export const AGGREGATIONS_LIST = Object.keys(AGGREGATIONS_TYPES).map(
  (key) => AGGREGATIONS_TYPES[key],
);
export const NON_NUMBER_AGGREGATIONS_LIST = Object.keys(AGGREGATIONS_TYPES)
  .filter((id) => id === AGGREGATIONS_TYPES.COUNT.id || id === AGGREGATIONS_TYPES.COUNT_DISTINCT.id)
  .map((key) => AGGREGATIONS_TYPES[key]);
export const VALID_AGGREGATIONS_SET = new Set(AGGREGATIONS_LIST.map((agg) => agg.id));

export const BASE_OP_INSTRUCTION_BY_TYPE: types.BaseOperationInstructionByType = {
  [OPERATION_TYPES.FILTER.id]: () => ({
    matchOnAll: true,
    filterClauses: [
      {
        filterColumn: '',
        filterOperation: '',
        filterValue: '',
      },
    ],
  }),
  [OPERATION_TYPES.SORT.id]: () => ({
    sortColumns: [
      {
        column: null,
        order: types.SortOrder.ASC,
      },
    ],
  }),
  [OPERATION_TYPES.PREVIEW.id]: () => [],
  [OPERATION_TYPES.ENRICH.id]: () => ({
    joinTable: {
      flowId: '',
      operationId: '',
      datasetId: '',
      name: '',
    },
    joinType: JOIN_TYPES.INNER.id,
    columnsToJoinOn: [
      {
        joinColumn: '',
        sourceColumn: '',
      },
    ],
  }),
  [OPERATION_TYPES.UNION.id]: () => ({
    unionTable: {
      flowId: '',
      operationId: '',
      datasetId: '',
      name: '',
    },
    columnsToUnionOn: [
      {
        resultColumnName: '',
        sourceColumn: '',
        unionColumn: '',
      },
    ],
    unionType: UNION_TYPES.ALL.id,
    unionColumnMatchType: UNION_COLUMN_MATCH_TYPES.NAME.id,
  }),
  [OPERATION_TYPES.BAR_CHART.id]: () => ({
    xAxisColumn: null,
    aggregation: {
      type: null,
      aggedOnColumn: null,
      additionalCol: null,
    },
  }),
  [OPERATION_TYPES.LINE_CHART.id]: () => ({
    xAxisColumn: null,
    lineColumns: [{ column: null, color: COLOR_LIST[0].primary }],
  }),
  [OPERATION_TYPES.GROUPED_BAR_CHART.id]: () => ({
    xAxisColumn: null,
    lineColumns: [{ column: null, color: COLOR_LIST[0].primary }],
  }),
  [OPERATION_TYPES.PIVOT.id]: () => ({
    pivotedOnCols: {},

    // Array of objects {type: X, aggedOnColumn: Y}
    aggregations: [],
  }),
  [OPERATION_TYPES.DEDUP.id]: () => ({
    colsToDedup: [
      {
        name: 'Select a Column',
        type: '',
      },
    ],
    sortColumns: [
      {
        column: null,
        order: types.SortOrder.ASC,
      },
    ],
  }),
  [OPERATION_TYPES.CHANGE_SCHEMA.id]: () => ({
    // Array of objects {col: X, newType: Y, safeCast: Z, newColName: A, keepCol: B}
    changeSchemaList: [],
  }),
  [OPERATION_TYPES.CALCULATE.id]: () => ({
    // Array of objects {type: X, aggedOnColumn: Y, additionalCol: Z}
    aggregations: [],
  }),
  [OPERATION_TYPES.FORMULA.id]: () => [
    {
      newColName: '',
      colFormula: null,
    },
  ],
  [OPERATION_TYPES.AXIS_PIVOT_CHART.id]: () => ({
    rowColumn: null,
    colColumn: null,
    aggregation: {
      type: null,
      aggedOnColumn: null,
    },
  }),
};

export const UNION_TYPES = {
  DISTINCT: {
    id: 'DISTINCT',
    name: 'Union Distinct',
    icon: 'remove',
  },
  ALL: {
    id: 'ALL',
    name: 'Union All',
    icon: 'add',
  },
};

export const UNIONS = [UNION_TYPES.DISTINCT, UNION_TYPES.ALL];

export const UNION_COLUMN_MATCH_TYPES = {
  NAME: {
    id: 'NAME',
    name: 'By Name',
    description: 'Columns without matching names will be left un-unioned.',
  },
  POSITION: {
    id: 'POSITION',
    name: 'By Position',
    description:
      'If columns in the same position have different types then they will both be kept.',
  },
  MANUAL: {
    id: 'MANUAL',
    name: 'Match Manually',
    description: 'Match the columns from one table to another one by one.',
  },
};

export const UNION_COLUMN_MATCHES = [
  UNION_COLUMN_MATCH_TYPES.NAME,
  UNION_COLUMN_MATCH_TYPES.POSITION,
  UNION_COLUMN_MATCH_TYPES.MANUAL,
];

export const JOIN_TYPES: types.JoinTypes = {
  INNER: {
    id: 'INNER',
    name: 'Inner Join',
    icon: 'inner-join',
  },
  LEFT: {
    id: 'LEFT',
    name: 'Left Join',
    icon: 'left-join',
  },
  RIGHT: {
    id: 'RIGHT',
    name: 'Right Join',
    icon: 'right-join',
  },
  OUTER: {
    id: 'OUTER',
    name: 'Outer Join',
    icon: 'resolve',
  },
};

export const JOINS = [JOIN_TYPES.INNER, JOIN_TYPES.LEFT, JOIN_TYPES.RIGHT, JOIN_TYPES.OUTER];

export const FILTER_OPERATOR_TYPES: types.FilterOperatorTypes = {
  STRING_IS: {
    id: 'STRING_IS',
    name: 'is',
    selectionValue: 'is...',
    supported_column_types: new Set([STRING]),
  },
  STRING_IS_NOT: {
    id: 'STRING_IS_NOT',
    name: 'is not',
    selectionValue: 'is not...',
    supported_column_types: new Set([STRING]),
  },
  STRING_CONTAINS: {
    id: 'STRING_CONTAINS',
    name: 'contains',
    selectionValue: 'contains...',
    supported_column_types: new Set([STRING]),
  },
  STRING_DOES_NOT_CONTAIN: {
    id: 'STRING_DOES_NOT_CONTAIN',
    name: 'does not contain',
    selectionValue: 'does not contain...',
    supported_column_types: new Set([STRING]),
  },
  NUMBER_EQ: {
    id: 'NUMBER_EQ',
    name: '=',
    selectionValue: '=',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT]),
  },
  NUMBER_NEQ: {
    id: 'NUMBER_NEQ',
    name: '≠',
    selectionValue: '≠',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT]),
  },
  NUMBER_LT: {
    id: 'NUMBER_LT',
    name: '<',
    selectionValue: '<',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT]),
  },
  NUMBER_GT: {
    id: 'NUMBER_GT',
    name: '>',
    selectionValue: '>',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT]),
  },
  NUMBER_LTE: {
    id: 'NUMBER_LTE',
    name: '≤',
    selectionValue: '≤',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT]),
  },
  NUMBER_GTE: {
    id: 'NUMBER_GTE',
    name: '≥',
    selectionValue: '≥',
    supported_column_types: new Set([INTEGER_DATA_TYPE, FLOAT]),
  },
  DATE_IS: {
    id: 'DATE_IS',
    name: 'is',
    selectionValue: 'is...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_IS_NOT: {
    id: 'DATE_IS_NOT',
    name: 'is not',
    selectionValue: 'is not...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_IS_BETWEEN: {
    id: 'DATE_IS_BETWEEN',
    name: 'is between',
    selectionValue: 'is between...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_LT: {
    id: 'DATE_LT',
    name: 'is before',
    selectionValue: 'is before...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_LTE: {
    id: 'DATE_LTE',
    name: 'is on or before',
    selectionValue: 'is on or before...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_GT: {
    id: 'DATE_GT',
    name: 'is after',
    selectionValue: 'is after...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  DATE_GTE: {
    id: 'DATE_GTE',
    name: 'is on or after',
    selectionValue: 'is on or after...',
    supported_column_types: new Set([TIMESTAMP, DATETIME, DATE]),
  },
  BOOLEAN_IS_TRUE: {
    id: 'BOOLEAN_IS_TRUE',
    name: 'is true',
    selectionValue: 'is true',
    supported_column_types: new Set([BOOLEAN]),
  },
  BOOLEAN_IS_FALSE: {
    id: 'BOOLEAN_IS_FALSE',
    name: 'is false',
    selectionValue: 'is false',
    supported_column_types: new Set([BOOLEAN]),
  },
  IS_EMPTY: {
    id: 'IS_EMPTY',
    name: 'is empty',
    selectionValue: 'is empty',
    supported_column_types: new Set([STRING, INTEGER_DATA_TYPE, FLOAT, TIMESTAMP, BOOLEAN]),
  },
  IS_NOT_EMPTY: {
    id: 'IS_NOT_EMPTY',
    name: 'is not empty',
    selectionValue: 'is not empty',
    supported_column_types: new Set([STRING, INTEGER_DATA_TYPE, FLOAT, TIMESTAMP, BOOLEAN]),
  },
};

export const FILTER_OPS_NO_VALUE = new Set([
  FILTER_OPERATOR_TYPES.BOOLEAN_IS_TRUE.id,
  FILTER_OPERATOR_TYPES.BOOLEAN_IS_FALSE.id,
  FILTER_OPERATOR_TYPES.IS_EMPTY.id,
  FILTER_OPERATOR_TYPES.IS_NOT_EMPTY.id,
]);

export const FILTER_OPS_DATE_PICKER = new Set([
  FILTER_OPERATOR_TYPES.DATE_IS.id,
  FILTER_OPERATOR_TYPES.DATE_IS_NOT.id,
  FILTER_OPERATOR_TYPES.DATE_LT.id,
  FILTER_OPERATOR_TYPES.DATE_LTE.id,
  FILTER_OPERATOR_TYPES.DATE_GT.id,
  FILTER_OPERATOR_TYPES.DATE_GTE.id,
]);

export const FILTER_OPS_DATE_RANGE_PICKER = new Set([FILTER_OPERATOR_TYPES.DATE_IS_BETWEEN.id]);

export const FILTER_OPERATORS = Object.keys(FILTER_OPERATOR_TYPES).map(
  (key) => FILTER_OPERATOR_TYPES[key],
);

const FILTER_OPERATORS_BY_ID: types.FilterOperatorsById = {};
Object.keys(FILTER_OPERATOR_TYPES).map(
  (key) => (FILTER_OPERATORS_BY_ID[key] = FILTER_OPERATOR_TYPES[key]),
);

export { FILTER_OPERATORS_BY_ID, OPERATIONS_BY_ID };

export const PIVOT_AGG_TYPES = {
  DATE_DAY: {
    id: 'DATE_DAY',
    name: 'Day',
  },
  DATE_WEEK: {
    id: 'DATE_WEEK',
    name: 'Week',
  },
  DATE_MONTH: {
    id: 'DATE_MONTH',
    name: 'Month',
  },
  DATE_YEAR: {
    id: 'DATE_YEAR',
    name: 'Year',
  },
};

export const DATE_PIVOT_AGGS = [
  PIVOT_AGG_TYPES.DATE_DAY,
  PIVOT_AGG_TYPES.DATE_WEEK,
  PIVOT_AGG_TYPES.DATE_MONTH,
  PIVOT_AGG_TYPES.DATE_YEAR,
];

export const DELIMITERS: types.DelimiterTypes = {
  COMMA: {
    id: 'COMMA',
    name: 'Comma',
    value: ',',
  },
  TAB: {
    id: 'TAB',
    name: 'Tab',
    value: '\t',
  },
  COLON: {
    id: 'COLON',
    name: 'Colon',
    value: ':',
  },
  SEMI_COLON: {
    id: 'SEMI_COLON',
    name: 'Semi-colon',
    value: ';',
  },
  PIPES: {
    id: 'PIPES',
    name: 'Pipes',
    value: '|',
  },
};

export const FLYOUT_DATA_PREVIEW_HEADER = 'Source Data Preview';

export const MAX_ROWS_TO_PREVIEW = 30;

export const Y_AXIS_OPTIONS = {
  'left-axis': {
    id: 'left-axis',
    name: 'Left Axis',
  },
  'right-axis': {
    id: 'right-axis',
    name: 'Right Axis',
  },
};

export const CSV_UPLOADS_DS_NAME = 'CSV Uploads';

export const SOURCE_TYPE_TO_FORMULA_HELP_LINK = {
  big_query:
    'https://cloud.google.com/bigquery/docs/reference/standard-sql/functions-and-operators',
  mysql: 'https://dev.mysql.com/doc/refman/8.0/en/functions.html',
  postgres: 'https://www.postgresql.org/docs/9.2/functions.html',
};

export const SORT_OPTIONS_BY_ID = {
  DATE_CREATED: {
    id: 'DATE_CREATED',
    sortText: 'date created',
  },
  LAST_EDITED: {
    id: 'LAST_EDITED',
    sortText: 'last edited',
  },
  NAME: {
    id: 'NAME',
    sortText: 'name',
  },
};

export const FLOWS_ORDERED_SORT_OPTIONS = [
  SORT_OPTIONS_BY_ID.LAST_EDITED,
  SORT_OPTIONS_BY_ID.DATE_CREATED,
];

export const DATASETS_ORDERED_SORT_OPTIONS = [
  SORT_OPTIONS_BY_ID.LAST_EDITED,
  SORT_OPTIONS_BY_ID.NAME,
];

export type SortOptionType = typeof SORT_OPTIONS_BY_ID.DATE_CREATED;

export const DATA_PAGES = new Set([
  ROUTES.DATA_SOURCE_LIST,
  ROUTES.ADD_DATA_SOURCE,
  '/datasets-view-page',
]);
export const EXPLORATION_PAGES = new Set([ROUTES.DATASOURCE, '/flows']);
export const DASHBOARD_PAGES = new Set([ROUTES.DASHBOARD_LIST, '/dashboards']);

type FormattedDatabaseNameDictionary = {
  [databaseType in DatabaseTypes]: string;
};

export const FORMATTED_DATABASE_NAME_DICTIONARY: FormattedDatabaseNameDictionary = {
  [DatabaseTypes.MYSQL]: 'MySQL',
  [DatabaseTypes.BIG_QUERY]: 'Google BigQuery',
  [DatabaseTypes.POSTGRES]: 'PostgreSQL',
  [DatabaseTypes.SQLITE]: 'SQLite',
  [DatabaseTypes.REDSHIFT]: 'Redshift',
};

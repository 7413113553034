/** @format */

import React from 'react';
import cx from 'classnames';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import { INodeDefaultProps } from 'trust-kaz-react-flow-chart';

const styles = (theme: Theme) => ({
  root: {
    position: 'absolute' as 'absolute',
    transition: 'box-shadow 0.3s ease 0s, margin-top 0.3s ease 0s',
    // @ts-ignore
    backgroundColor: theme.palette.white,
    borderRadius: 8,
    // @ts-ignore
    border: `1px solid ${theme.palette.grey.border}`,

    '&.selected': {
      // @ts-ignore
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 20px',
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.grey.blueprintDarkWhite,
    },
  },
});

type PassedProps = {
  innerRef: any;
};

type Props = PassedProps & INodeDefaultProps & WithStyles<typeof styles>;

type State = {};

class FlowNodeWrapperComponent extends React.Component<Props, State> {
  render() {
    const { classes, innerRef, children, isSelected, onClick, ...otherProps } = this.props;

    // @ts-ignore
    otherProps.className = cx(otherProps.className, classes.root, {
      selected: isSelected,
    });

    return (
      <div
        ref={innerRef}
        {...otherProps}
        onClick={(e) => {
          if (!isSelected && e.shiftKey) {
            otherProps.config.joinGraphNodes(otherProps.node.id);
            e.stopPropagation();
          } else {
            onClick(e);
          }
        }}>
        {children}
      </div>
    );
  }
}

const FlowNodeWrapper = withStyles(styles)(FlowNodeWrapperComponent);

const NodeCustom = React.forwardRef(
  (passedProps: INodeDefaultProps, ref: React.Ref<HTMLDivElement>) => {
    // @ts-ignore
    return <FlowNodeWrapper innerRef={ref} {...passedProps} />;
  },
);

export default NodeCustom;

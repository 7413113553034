/** @format */

import { SortOperationInstructions } from 'constants/types';
import { OperationFooterErrorInformation } from 'utils/operationErrorInfoUtils/generalOperationErrorInfoUtil';

enum FOOTER_ERROR_TEXT {
  NO_ERROR = 'No Error',
  SELECT_COLUMN_TO_SORT_BY = 'Please select atleast one column to sort by',
}

export const getSortOperationFooterErrorInformation = (
  instructions: SortOperationInstructions,
): OperationFooterErrorInformation => {
  const operationFooterErrorInformation: OperationFooterErrorInformation = {
    footerErrorState: false,
    footerErrorText: FOOTER_ERROR_TEXT.NO_ERROR,
  };

  if (instructions.sortColumns[0].column === null) {
    operationFooterErrorInformation.footerErrorState = true;
    operationFooterErrorInformation.footerErrorText = FOOTER_ERROR_TEXT.SELECT_COLUMN_TO_SORT_BY;
  }
  return operationFooterErrorInformation;
};

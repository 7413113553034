/** @format */

import { defineAction } from 'actions/actionUtils';

export const checkUserDashboardAccess = defineAction(
  'CHECK_USER_DASHBOARD_ACCESS',
  'share',
  'check_dashboard_access',
  'GET',
);

export const fetchSharedDashboard = defineAction(
  'FETCH_SHARED_DASHBOARD',
  'share',
  'dashboard',
  'GET',
);

export const fetchSharedDashboardOperationRowCount = defineAction(
  'FETCH_SHARED_DASHBOARD_OPERATION_ROW_COUNT',
  'share',
  'dashboard_operation_row_count',
  'POST',
);

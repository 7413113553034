/** @format */

import React from 'react';
import styled from 'styled-components';

import { PortDefault, IPortDefaultProps } from 'trust-kaz-react-flow-chart';

const PortDefaultOuter = styled.div`
  width: 24px;
  height: 24px;
  background: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #bdbdbd;
`;

const NodePorts = (props: IPortDefaultProps) =>
  props.port.type === 'input' ? (
    <PortDefaultOuter>
      <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
        <path
          fill="cornflowerblue"
          d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
        />
      </svg>
    </PortDefaultOuter>
  ) : (
    PortDefault(props)
  );

export default NodePorts;

/** @format */
import React, { SetStateAction, Dispatch } from 'react';
import cx from 'classnames';
import { ActionFn } from 'actions/actionUtils';
import { Theme, makeStyles } from '@material-ui/core/index';
import { FlowReducerState } from 'reducers/flowReducer';
import { SelectedNode, NODE_POSITIONS, FlowGraph } from './flowGraphPageV3';
import FlowNodeV3, { CONNECTOR_LINE_THICKNESS } from 'pages/flowGraphPage/flowNodeV3';
import { Operation, DatasetFlowNode } from 'actions/types';
import { isDatasetFlowNode, getNodePosition } from './flowGraphPageUtils';
import { BACKGROUND_CANVAS } from './flowGraphContent';

const useStyles = makeStyles((theme: Theme) => ({
  loadingBody: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  treesContainer: {
    position: 'relative',
  },
  treeListContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: 'fit-content',
  },
  treeContainer: {
    margin: `0px ${theme.spacing(20)}px`,
  },
  parentChildContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'fit-content',
  },
  siblingContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  nodeLineContainer: {
    position: 'relative',
  },
  connectorLine: {
    position: 'absolute',
    border: `${CONNECTOR_LINE_THICKNESS}px solid ${theme.palette.darkBlue}`,
  },
  connectorFirst: {
    right: 0,
    width: '50%',
  },
  connectorMiddle: {
    width: '100%',
  },
  connectorLast: {
    width: '50%',
  },
}));

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export type Props = {
  flowDataLoading: boolean;
  flowDataError: boolean;
  selectedNode?: SelectedNode;
  addStepModalOpen: boolean;
  addDatasetModalOpen: boolean;
  setAddStepModalOpen: Dispatch<SetStateAction<boolean>>;
  flowId: string;
  flowData: FlowReducerState;
  updateFlowNodePos: ActionFn;
  datasetFlowNode: DatasetFlowNode;
  flowGraph: FlowGraph;
};

const renderNodeConnector = (position: NODE_POSITIONS, classes: ReturnType<typeof useStyles>) => {
  switch (position) {
    case NODE_POSITIONS.FIRST:
      return (
        <div
          id={BACKGROUND_CANVAS}
          className={cx(classes.connectorLine, classes.connectorFirst)}></div>
      );
    case NODE_POSITIONS.MIDDLE:
      return (
        <div
          id={BACKGROUND_CANVAS}
          className={cx(classes.connectorLine, classes.connectorMiddle)}></div>
      );
    case NODE_POSITIONS.LAST:
      return (
        <div
          id={BACKGROUND_CANVAS}
          className={cx(classes.connectorLine, classes.connectorLast)}></div>
      );
    case NODE_POSITIONS.ONLY_CHILD:
    case NODE_POSITIONS.ROOT:
      return;
    default:
      throw new Error('Node Position not recognized');
  }
};

const renderNode = (
  node: Operation | DatasetFlowNode,
  classes: ReturnType<typeof useStyles>,
  position: NODE_POSITIONS,
  props: Props,
) => {
  const { selectedNode, addDatasetModalOpen, addStepModalOpen, flowGraph } = props;
  const nodeChildren = isDatasetFlowNode(node) ? node.operation_nodes : node.child_operations;
  return (
    <div key={node.id} id={BACKGROUND_CANVAS} className={classes.nodeLineContainer}>
      {renderNodeConnector(position, classes)}
      <div id={BACKGROUND_CANVAS} className={classes.parentChildContainer}>
        <FlowNodeV3
          node={node}
          isChildNode={isDatasetFlowNode(node)}
          childNodeCount={nodeChildren.length}
          isSelected={!!selectedNode && selectedNode.id === node.id}
          selectedNode={selectedNode}
          addDatasetModalOpen={addDatasetModalOpen}
          addStepModalOpen={addStepModalOpen}
        />
        {flowGraph.nodeMap && nodeChildren && (
          <div id={BACKGROUND_CANVAS} className={classes.siblingContainer}>
            {nodeChildren.map((childNode: { id: number }, index: number) => {
              return renderNode(
                flowGraph.nodeMap![childNode.id],
                classes,
                getNodePosition(index, nodeChildren.length),
                props,
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

const FlowGraphTree = (props: Props) => {
  const { datasetFlowNode } = props;
  const classes = useStyles();
  return (
    <div id={BACKGROUND_CANVAS} className={classes.treeContainer}>
      {renderNode(datasetFlowNode, classes, NODE_POSITIONS.ROOT, props)}
    </div>
  );
};

export default FlowGraphTree;

/** @format */

import React, { ReactNode, useEffect } from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { AppToaster } from 'toaster';
import { Intent } from '@blueprintjs/core';

import StarParallaxBg from 'components/marketing/starParallaxBg';
import NavHeader from 'components/marketing/navHeader';
import SignupHero from 'components/marketing/signupHero';
import AboutUsPersonInfo from 'components/marketing/aboutUsPersonInfo';
import MidPageSignupBanner from 'components/marketing/midPageSignupBanner';
import Footer from 'components/marketing/footer';

import GaryHeadshot from 'images/headshot_gary.png';
import AlexisHeadshot from 'images/headshot_alexis.png';
import JonahHeadshot from 'images/headshot_jonah.png';
import RohanHeadshot from 'images/headshot_rohan.png';
import AndrewHeadshot from 'images/headshot_andrew.png';
import CarlyHeadshot from 'images/headshot_carly.png';
import AnujHeadshot from 'images/headshot_anuj.png';

import { HEADER_PAGES } from 'components/marketing/navHeader';
import { pageView } from 'analytics/exploAnalytics';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.black100,
    height: '100vh',
    padding: `0 ${theme.spacing(7)}px`,
    display: 'flex',
    justifyContent: 'center',
    fontFamily: "'Inter', sans-serif",
    perspective: '1px',
    overflowY: 'auto',
  },
  container: {
    width: '100%',
    maxWidth: 1040,
    zIndex: 2,
  },
  descriptionBottomText: {
    marginTop: theme.spacing(5),
  },
  headshotsContainer: {
    display: 'grid',
    gridTemplateColumns: '0.33fr 0.33fr 0.33fr',
    gridColumnGap: theme.spacing(15),
    gridRowGap: theme.spacing(17),

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '0.5fr 0.5fr',
    },
  },
  signupBanner: {
    marginTop: 160,

    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
    },
  },
  signupFooter: {
    marginTop: 160,

    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
    },
  },
  footer: {
    marginTop: 120,
    marginBottom: 40,
  },
}));

type Props = {
  className?: string;
  children: ReactNode;
};

const HomePage = (props: Props) => {
  const classes = useStyles();

  useEffect(() => {
    pageView('About Us');
  });

  return (
    <div className={classes.root}>
      <StarParallaxBg />
      <div className={classes.container}>
        <NavHeader selectedHeaderPage={HEADER_PAGES.ABOUT_US} />
        <SignupHero
          title1="Meet the "
          title2="team"
          description={
            <div>
              <div>
                As former consultants, analysts, developers, and designers and we’ve experienced the
                difficulty of effectively using data.
              </div>
              <div className={classes.descriptionBottomText}>
                We’re building Explo to <b>make data analysis accessible to everyone.</b>
              </div>
            </div>
          }
          onSignupSuccess={renderSuccessSignUpToast}
          onSignupFail={renderFailedSignUpToast}
          noSignup
        />
        <div className={classes.headshotsContainer}>
          <AboutUsPersonInfo
            name="Alexis"
            imgUrl={AlexisHeadshot}
            description={
              <>
                Currently a student at <b>MIT</b> studying Computation and Cognition.
              </>
            }
          />

          <AboutUsPersonInfo
            name="Andrew"
            imgUrl={AndrewHeadshot}
            description={
              <>
                Previously an <b>Analytics Consultant</b> at McKinsey & Co, served multiple Fortune
                500 companies.
              </>
            }
          />

          <AboutUsPersonInfo
            name="Anuj"
            imgUrl={AnujHeadshot}
            description={
              <>
                Previously a <b>Software Engineer</b> at The Pill Club and Facebook.
              </>
            }
          />

          <AboutUsPersonInfo
            name="Carly"
            imgUrl={CarlyHeadshot}
            description={
              <>
                Previously a <b>Product Designer</b> at the Chan Zuckerberg Initiative, PlanGrid,
                and PointClickCare.
              </>
            }
          />

          <AboutUsPersonInfo
            name="Gary"
            imgUrl={GaryHeadshot}
            description={
              <>
                Previously a <b>Tech Lead</b> at Palantir (focused on DoD projects), a Software
                Engineer at Google[X].
              </>
            }
          />
          <AboutUsPersonInfo
            name="Jonah"
            imgUrl={JonahHeadshot}
            description={
              <>
                Previously an <b>Investor</b> at Macquarie Capital, co-founded 116th & Partners.
              </>
            }
          />

          <AboutUsPersonInfo
            name="Rohan"
            imgUrl={RohanHeadshot}
            description={
              <>
                Previously a <b>Tech Lead</b> at the Chan Zuckerberg Initiative, a Forward Deployed
                Engineer at Palantir.
              </>
            }
          />
        </div>
        <MidPageSignupBanner
          className={classes.signupBanner}
          calltoActionText="Book a call to chat with us about your data"
          btnText="Book a Call"
          onSignupSuccess={renderSuccessSignUpToast}
          onSignupFail={renderFailedSignUpToast}
        />

        <Footer className={classes.footer} />
      </div>
    </div>
  );
};

const renderSuccessSignUpToast = (email: string): void => {
  AppToaster.show({
    message: `Thanks for signing up, ${email}.  We'll be in touch shortly!`,
    icon: 'tick-circle',
    timeout: 5000,
    intent: Intent.SUCCESS,
  });
};

const renderFailedSignUpToast = (msg: string): void => {
  AppToaster.show({
    message: msg,
    icon: 'issue',
    timeout: 5000,
    intent: Intent.DANGER,
  });
};

export default HomePage;

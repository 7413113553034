/** @format */

import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  ButtonGroup,
  FormGroup,
  H3,
  InputGroup,
  Intent,
  NonIdealState,
  Position,
  Spinner,
  Tooltip,
  RadioGroup,
  Radio,
} from '@blueprintjs/core';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withStyles, WithStyles } from '@material-ui/styles';
import cx from 'classnames';
import { ThunkDispatch } from 'redux-thunk';
import _ from 'underscore';

import { bulkFetchDatasetsSuccess } from 'actions/datasetCacheActions';
import { Dataset } from 'actions/types';
import SelectDatasetModal from 'components/fileSystem/selectDatasetModal';
import CancelClauseButton from 'components/flowBuilder/editOperationDrawer/cancelClauseButton';
import EditDrawerDataPreview from 'components/flowBuilder/editOperationDrawer/editDrawerDataPreview';
import OperationDropdownInput from 'components/flowBuilder/operationDropdownInput';
import {
  FLYOUT_DATA_PREVIEW_HEADER,
  UNION_COLUMN_MATCH_TYPES,
  UNION_COLUMN_MATCHES,
} from 'constants/flowConstants';
import {
  ColumnInfo,
  ColumnToUnionOn,
  Schema,
  SelectedDropdownInputItem,
  UnionOperationInstructions,
} from 'constants/types';
import { DatasetCacheReducerState } from 'reducers/datasetCacheReducer';
import { ReduxState } from 'reducers/rootReducer';
import {
  getUnionOperationFooterErrorInformation,
  getUnionOperationRowErrorInformation,
} from 'utils/operationErrorInfoUtils/unionOperationErrorInfoUtil';
import OperationDrawerFooter from './operationDrawerFooter';

const styles = (theme: Theme) =>
  createStyles({
    columnsToUnionOnContainer: {
      margin: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
      marginBottom: theme.spacing(6),
      backgroundColor: theme.palette.white,
      boxShadow: theme.customShadows.basic,
      borderRadius: 4,
    },
    unionTableButtonGroup: {
      marginRight: theme.spacing(2),
      height: '30px',
    },
    unionTableButton: {
      marginLeft: theme.spacing(6),
    },
    radioGroupSpacing: {
      marginLeft: theme.spacing(12),
    },
    tableSelectModalHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: `${theme.spacing(3)}px 0`,
      boxSizing: 'content-box',
    },
    tableSelectModalHeaderTitle: {
      marginRight: theme.spacing(4),
    },
    modalHeaderButton: {
      marginRight: theme.spacing(4),
    },
    root: {
      height: '100%',
    },
    body: {
      height: 'calc(100% - 54px)',
      overflowY: 'scroll',
    },
    drawerFooter: {
      padding: `${theme.spacing(3)}px ${theme.spacing(6)}px`,
      display: 'flex',
      justifyContent: 'flex-end',
      borderTop: `1px solid ${theme.palette.grey.border}`,
      backgroundColor: theme.palette.white,
    },
    selectionTitle: {
      margin: theme.spacing(6),
    },
    selectionHeaderTitle: {
      margin: theme.spacing(6),
      marginRight: theme.spacing(2),
    },
    clauseTable: {
      width: '100%',
    },
    addClauseButton: {
      margin: theme.spacing(3),
      marginLeft: 52,
    },
    filtersFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    noColsSelected: {
      height: 200,
      marginBottom: theme.spacing(10),
    },
    selectionHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    flyoutSpacing: {
      marginBottom: theme.spacing(6),
    },
    loadingBody: {
      height: '40vh',
      display: 'flex',
      justifyContent: 'center',
    },
    columnName: {
      width: '100',
    },
    matchOptionPanel: {
      margin: theme.spacing(6),
      backgroundColor: theme.palette.white,
      borderRadius: 4,
      boxShadow: theme.customShadows.basic,
      padding: theme.spacing(4),
      paddingBottom: 1,
    },
    matchOptionRadio: {
      marginBottom: theme.spacing(6),
    },
    formGroupValidationErrorState: {
      marginBottom: 0,
    },
    formGroupValidationNoError: {
      marginBottom: 20,
    },
    buttonErrorState: {
      boxShadow: `inset 0 0 0 1px ${theme.palette.dangerRed} !important`,
    },
  });

type PassedProps = {
  datasetCacheData: DatasetCacheReducerState;
  instructions: UnionOperationInstructions;
  operationId: number;
  sourceDataset: Dataset;
  sourceDataSourceId: number;
  onSubmit: (newInstructions: UnionOperationInstructions) => void;
};

type Props = PassedProps &
  WithStyles<typeof styles> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type State = {
  isUnionTableLoading: boolean;
  instructions: UnionOperationInstructions;
  tableSelectModalOpen: boolean;
};

class UnionEditOperationBody extends React.Component<Props, State> {
  readonly state: State = {
    isUnionTableLoading: false,
    instructions: cloneDeep(this.props.instructions),
    tableSelectModalOpen: false,
  };

  render() {
    const { classes, onSubmit, sourceDataset, datasetCacheData } = this.props;
    const { instructions } = this.state;
    const { footerErrorState, footerErrorText } = getUnionOperationFooterErrorInformation(
      instructions,
    );

    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <EditDrawerDataPreview
            sourceDataset={sourceDataset}
            dataPreviewHeader={FLYOUT_DATA_PREVIEW_HEADER}
          />
          <div className={classes.selectionHeader}>
            <H3 className={classes.selectionHeaderTitle}>Select a table to Union on:</H3>
            {this.renderTableSelector()}
          </div>
          {this.renderUnionTable()}
          {datasetCacheData[instructions.unionTable.datasetId] && (
            <div className={classes.flyoutSpacing}>
              <div>
                <H3 className={classes.selectionHeaderTitle}>Column matching</H3>
                {this.renderMatchButtons()}
              </div>
              {this.renderColumnsToUnionOnSelector()}
            </div>
          )}
        </div>
        <OperationDrawerFooter
          errorState={footerErrorState}
          errorText={footerErrorText}
          onSubmit={() => onSubmit(instructions)}
        />
      </div>
    );
  }

  getUnionColMatch = (
    resultColumnName: string,
    sourceColumn: string | null,
    unionColumn: string | null,
  ): ColumnToUnionOn => {
    return {
      resultColumnName: resultColumnName,
      sourceColumn: sourceColumn,
      unionColumn: unionColumn,
    };
  };

  matchByName = (unionTableSchema: any) => {
    const { sourceDataset } = this.props;
    const resultTableSecondHalfColumnsToUnionOn: ColumnToUnionOn[] = [];

    const unionTableDictionary: { [name: string]: ColumnInfo } = _.indexBy(
      unionTableSchema,
      'name',
    );

    const resultTableFirstHalfColumnsToUnionOn = sourceDataset.cached_schema!.map(
      (sourceColumn, index) => {
        if (sourceColumn.name in unionTableDictionary) {
          const unionCol: ColumnInfo = unionTableDictionary[sourceColumn.name];
          delete unionTableDictionary[sourceColumn.name];
          if (sourceColumn.type === unionCol.type) {
            return this.getUnionColMatch(sourceColumn.name, sourceColumn.name, unionCol.name);
          } else {
            // adding a _0 to dedup
            resultTableSecondHalfColumnsToUnionOn.push(
              this.getUnionColMatch(`${unionCol.name}_0`, null, unionCol.name),
            );
            return this.getUnionColMatch(sourceColumn.name, sourceColumn.name, null);
          }
        } else {
          return this.getUnionColMatch(sourceColumn.name, sourceColumn.name, null);
        }
      },
    );

    for (const unionCol in unionTableDictionary) {
      resultTableSecondHalfColumnsToUnionOn.push(
        this.getUnionColMatch(
          unionTableDictionary[unionCol].name,
          null,
          unionTableDictionary[unionCol].name,
        ),
      );
    }

    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.columnsToUnionOn = resultTableFirstHalfColumnsToUnionOn.concat(
        resultTableSecondHalfColumnsToUnionOn,
      );
      return { instructions };
    });
  };

  matchByPosition = (unionTableSchema: any) => {
    const { sourceDataset } = this.props;
    const resultTableSecondHalfColumnsToUnionOn: ColumnToUnionOn[] = [];
    const sourceSchemaSet = new Set(sourceDataset.cached_schema!.map((schema) => schema.name));

    const resultTableFirstHalfColumnsToUnionOn = sourceDataset.cached_schema!.map(
      (sourceColumn, index) => {
        if (index >= unionTableSchema.length) {
          return this.getUnionColMatch(sourceColumn.name, sourceColumn.name, null);
        } else if (sourceColumn.type === unionTableSchema[index].type) {
          return this.getUnionColMatch(
            sourceColumn.name,
            sourceColumn.name,
            unionTableSchema[index].name,
          );
        } else {
          resultTableSecondHalfColumnsToUnionOn.push(
            // adding a _0 to dedup
            this.getUnionColMatch(
              sourceSchemaSet.has(unionTableSchema[index].name)
                ? `${unionTableSchema[index].name}_0`
                : unionTableSchema[index].name,
              null,
              unionTableSchema[index].name,
            ),
          );
          return this.getUnionColMatch(sourceColumn.name, sourceColumn.name, null);
        }
      },
    );

    for (var i = sourceDataset.cached_schema!.length; i < unionTableSchema.length; i++) {
      resultTableSecondHalfColumnsToUnionOn.push(
        this.getUnionColMatch(
          sourceSchemaSet.has(unionTableSchema[i].name)
            ? `${unionTableSchema[i].name}_0`
            : unionTableSchema[i].name,
          null,
          unionTableSchema[i].name,
        ),
      );
    }

    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.columnsToUnionOn = resultTableFirstHalfColumnsToUnionOn.concat(
        resultTableSecondHalfColumnsToUnionOn,
      );
      return { instructions };
    });
  };

  computeResultTableColumns = (unionColumnMatchType: string, unionTableSchema: any) => {
    switch (unionColumnMatchType) {
      case UNION_COLUMN_MATCH_TYPES.NAME.id:
        this.matchByName(unionTableSchema);
        break;
      case UNION_COLUMN_MATCH_TYPES.POSITION.id:
        this.matchByPosition(unionTableSchema);
        break;
      case UNION_COLUMN_MATCH_TYPES.MANUAL.id:
        // manual select is initialized with match by name
        this.matchByName(unionTableSchema);
        break;
      default:
        throw new Error(
          'Internal Error: Match Type is not recognized when computing the resulting table columns',
        );
    }
  };

  handleUnionColumnMatchTypeChange = (unionColumnMatchType: string) => {
    const { datasetCacheData } = this.props;
    const { instructions } = this.state;

    this.setState((prevState) => {
      prevState.instructions.unionColumnMatchType = unionColumnMatchType;
      return { instructions: prevState.instructions };
    });
    this.computeResultTableColumns(
      unionColumnMatchType,
      datasetCacheData[instructions.unionTable.datasetId]!.cached_schema,
    );
  };

  renderMatchButtons = () => {
    const { instructions } = this.state;
    const { classes } = this.props;

    return (
      <RadioGroup
        className={classes.matchOptionPanel}
        onChange={(e: any) => this.handleUnionColumnMatchTypeChange(e.target.value)}
        selectedValue={instructions.unionColumnMatchType}>
        {UNION_COLUMN_MATCHES.map((matchType) => (
          <Radio className={classes.matchOptionRadio} key={matchType.id} value={matchType.id}>
            <b>{matchType.name}</b>
            {'   '}
            <span className="bp3-text-muted">{matchType.description}</span>
          </Radio>
        ))}
      </RadioGroup>
    );
  };

  renderUnionTable = () => {
    const { classes, datasetCacheData } = this.props;
    const { isUnionTableLoading, instructions } = this.state;
    if (isUnionTableLoading) {
      return (
        <div className={classes.loadingBody}>
          <Spinner intent={Intent.PRIMARY} size={Spinner.SIZE_STANDARD} />
        </div>
      );
    }
    const unionTable = datasetCacheData[instructions.unionTable.datasetId];
    if (!unionTable) {
      return (
        <NonIdealState
          className={classes.noColsSelected}
          icon="search"
          title="Select a table to get started"
          description="The table you select will be used as the union table."
        />
      );
    }
    return (
      <EditDrawerDataPreview
        sourceDataset={unionTable}
        dataPreviewHeader={
          (instructions.unionTable && instructions.unionTable.name) || 'Union Table Data'
        }
      />
    );
  };

  renderColumnsToUnionOnSelector = () => {
    const { classes } = this.props;
    const { instructions } = this.state;

    if (instructions.unionColumnMatchType !== UNION_COLUMN_MATCH_TYPES.MANUAL.id) return;

    return (
      <div>
        <H3 className={classes.selectionTitle}>Match columns manually</H3>
        <div className={classes.columnsToUnionOnContainer}>
          <table className={cx(classes.clauseTable, 'bp3-html-table')}>
            <thead>
              <tr>
                <th></th>
                <th>Resulting Column Name</th>
                <th>Source Column</th>
                <th>Union Column</th>
              </tr>
            </thead>
            <tbody>{instructions.columnsToUnionOn.map(this.renderUnionClause)}</tbody>
          </table>
          <div className={classes.filtersFooter}>
            <Button className={classes.addClauseButton} icon="add" onClick={this.addUnionClause}>
              Add Union Clause
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderUnionClause = (unionClause: ColumnToUnionOn, index: number) => {
    const { classes, operationId, sourceDataset, datasetCacheData } = this.props;
    const { instructions } = this.state;
    const {
      resultColNameErrorText,
      resultColNameInputIntent,
      isSourceColButtonErrorState,
      isUnionColButtonErrorState,
    } = getUnionOperationRowErrorInformation(unionClause, index);

    return (
      <tr key={`filter_clause_${operationId}}_${index}`}>
        <CancelClauseButton index={index} onClick={() => this.removeUnionClause(index)} />
        <td>
          <FormGroup
            className={
              resultColNameErrorText
                ? classes.formGroupValidationErrorState
                : classes.formGroupValidationNoError
            }
            helperText={resultColNameErrorText}
            intent={resultColNameInputIntent}
            labelFor="text-input">
            <InputGroup
              type="text"
              intent={resultColNameInputIntent}
              value={unionClause.resultColumnName}
              className={cx('bp3-fill')}
              onChange={(event: any) => {
                event.persist();
                this.setState((prevState) => {
                  const { instructions } = prevState;
                  instructions.columnsToUnionOn[index].resultColumnName = event.target.value;
                  return { instructions };
                });
              }}
            />
          </FormGroup>
        </td>
        <td>
          {this.renderColumnSelector(
            index,
            unionClause.sourceColumn!,
            sourceDataset.cached_schema!,
            false,
            this.selectSourceColumn,
            isSourceColButtonErrorState,
          )}
        </td>
        <td>
          {this.renderColumnSelector(
            index,
            unionClause.unionColumn!,
            datasetCacheData[instructions.unionTable.datasetId]!.cached_schema!,
            true,
            this.selectUnionColumn,
            isUnionColButtonErrorState,
          )}
        </td>
      </tr>
    );
  };

  renderColumnSelector = (
    index: number,
    selectedColumn: string,
    tableSchema: Schema,
    isUnionColumnSelector: boolean,
    columnSelector: Function,
    errorState?: boolean,
  ) => {
    const unionTableSchemaByColumnName = _.indexBy(tableSchema, 'name');

    return (
      <OperationDropdownInput
        buttonErrorState={errorState}
        selectedItem={
          selectedColumn
            ? ({ name: selectedColumn, id: selectedColumn } as SelectedDropdownInputItem)
            : undefined
        }
        onChange={(item) => {
          columnSelector(index, unionTableSchemaByColumnName[item.id]);
        }}
        options={this.filterColumnOptions(index, isUnionColumnSelector)}
        noSelectionText="Null"
      />
    );
  };

  filterColumnOptions = (index: number, isUnionColumnSelector: boolean) => {
    const { instructions } = this.state;
    const { sourceDataset, datasetCacheData } = this.props;
    const unionTableSchema: Schema | null | undefined = datasetCacheData[
      instructions.unionTable.datasetId
    ]!.cached_schema;

    if (!unionTableSchema) {
      throw new Error(
        'Internal Error: The selected Union Table Schema is Null, Please Contact Explo Support',
      );
    }
    const adjacentCol: ColumnInfo | undefined = _.findWhere(
      isUnionColumnSelector ? sourceDataset.cached_schema! : unionTableSchema,
      {
        name: isUnionColumnSelector
          ? instructions.columnsToUnionOn[index].sourceColumn
          : instructions.columnsToUnionOn[index].unionColumn,
      },
    );
    const currentTableSchema = isUnionColumnSelector
      ? unionTableSchema
      : sourceDataset.cached_schema!;
    const columnsToDisplay = adjacentCol
      ? currentTableSchema.filter((col) => col.type === adjacentCol.type)
      : currentTableSchema;
    return columnsToDisplay.map((col) => {
      return { name: col.name, id: col.name };
    });
  };

  selectSourceColumn = (index: number, columnObj: ColumnInfo) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      if (!instructions.columnsToUnionOn[index].resultColumnName) {
        instructions.columnsToUnionOn[index].resultColumnName = columnObj.name;
      }
      instructions.columnsToUnionOn[index].sourceColumn = columnObj.name;
      return { instructions };
    });
  };

  selectUnionColumn = (index: number, columnObj: ColumnInfo) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      if (!instructions.columnsToUnionOn[index].resultColumnName) {
        instructions.columnsToUnionOn[index].resultColumnName = columnObj.name;
      }
      instructions.columnsToUnionOn[index].unionColumn = columnObj.name;
      return { instructions };
    });
  };

  addUnionClause = () => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.columnsToUnionOn.push({
        resultColumnName: '',
        unionColumn: '',
        sourceColumn: '',
      });
      return { instructions };
    });
  };

  removeUnionClause = (index: number) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.columnsToUnionOn.splice(index, 1);
      return { instructions };
    });
  };

  renderTableSelector = () => {
    const { classes } = this.props;
    const { tableSelectModalOpen, instructions } = this.state;

    const selectedTable = instructions.unionTable;

    let button = (
      <Button
        className={cx(classes.unionTableButton, {
          [classes.buttonErrorState]: selectedTable.name === '',
        })}
        icon="th"
        onClick={() => this.setState({ tableSelectModalOpen: true })}>
        {selectedTable.name ? selectedTable.name : 'Select Table'}
      </Button>
    );
    if (selectedTable.datasetId) {
      button = (
        <>
          <ButtonGroup className={classes.unionTableButtonGroup}>
            {button}
            <Tooltip content="View the dataset" position={Position.TOP}>
              <a
                href={`/datasets/${selectedTable.datasetId}`}
                target="_blank"
                rel="noopener noreferrer">
                <Button icon="eye-open" />
              </a>
            </Tooltip>
          </ButtonGroup>
        </>
      );
    }

    return (
      <>
        {button}
        <SelectDatasetModal
          isOpen={tableSelectModalOpen}
          onClose={() => this.setState({ tableSelectModalOpen: false })}
          renderDatasetActions={this.renderSelectDatasetActions}
          editingDisabled
        />
      </>
    );
  };

  renderSelectDatasetActions = (selectedDataset: any | null, onClose: () => void) => {
    return (
      <Button
        intent={Intent.PRIMARY}
        disabled={selectedDataset === null || !selectedDataset.results_last_updated_at}
        onClick={() => {
          this.onUnionTableSelect(selectedDataset);
          onClose();
        }}>
        Submit
      </Button>
    );
  };

  onUnionTableSelect = (dataset: any) => {
    const { bulkFetchDatasetsSuccess } = this.props;
    const { instructions } = this.state;

    this.setState({ tableSelectModalOpen: false });
    if (instructions.unionTable.datasetId === dataset.id) {
      return;
    }

    this.setState(
      (prevState) => {
        const { instructions } = prevState;
        instructions.unionTable.datasetId = dataset.id;
        instructions.unionTable.name = dataset.name || dataset.data_source_table_name;
        instructions.columnsToUnionOn = [
          {
            unionColumn: '',
            sourceColumn: '',
          },
        ];
        return { instructions, isUnionTableLoading: false };
      },
      () => {
        bulkFetchDatasetsSuccess([dataset]);
        this.computeResultTableColumns(
          this.state.instructions.unionColumnMatchType,
          dataset.cached_schema,
        );
      },
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  dataSourceList: state.dataSourceList,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  bulkFetchDatasetsSuccess: (datasets: Array<any>) => dispatch(bulkFetchDatasetsSuccess(datasets)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(UnionEditOperationBody));

/** @format */

import React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';

import StepBlockTag from 'components/flowBuilder/stepBlockTag';

import { schemaClauseInstructionsComplete } from 'utils/flowUtils';

import { InstructionComponentProps } from 'components/flowGraph/operationInstructionDescription';
import { ChangeSchemaOperationInstructions } from 'constants/types';

const styles = (theme: Theme) => createStyles({});

type PassedProps = InstructionComponentProps<ChangeSchemaOperationInstructions>;

type Props = PassedProps & WithStyles<typeof styles>;

class ChangeSchemaOperationInstructionDescription extends React.Component<Props> {
  render() {
    const { operationId, instructions } = this.props;

    // Ignore the changes in changeSchemaList that have default values.
    const completeClauses = instructions.changeSchemaList.filter(schemaClauseInstructionsComplete);

    return completeClauses.length > 0 ? (
      <StepBlockTag
        key={`change_schema_instructions_${operationId}`}
        content={`${completeClauses.length} ${
          completeClauses.length > 1 ? 'columns' : 'column'
        } edited`}
      />
    ) : (
      <></>
    );
  }
}

export default withStyles(styles)(ChangeSchemaOperationInstructionDescription);

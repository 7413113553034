/** @format */

import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { sha256 } from 'js-sha256';
import { withRouter } from 'react-router-dom';
import { AppToaster } from 'toaster';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { FormGroup, Intent } from '@blueprintjs/core';
import { ReduxState } from 'reducers/rootReducer';

import SetupFlowPage from 'components/pages/setupFlowPage';
import InputField from 'explo-ds/forms/marketing/inputField';
import Button from 'explo-ds/buttons/button';

import { loginUser } from 'actions/authAction';
import { pageView } from 'analytics/exploAnalytics';

import { ROUTES } from 'constants/routes';

const useStyles = makeStyles((theme: Theme) => ({
  signupInput: {
    backgroundColor: theme.palette.grey200,
    width: '100%',
    height: 40,
    marginTop: 2,
  },
  passwordInputGroup: {
    marginTop: theme.spacing(5),
  },
  signInButton: {
    width: '100%',
    marginTop: theme.spacing(5),
  },
}));

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

const SignIngPageV2 = (props: Props) => {
  return <SetupFlowPage title="Sign in" pageBody={<SignInBody {...props} />} />;
};

const SignInBody = (props: Props) => {
  const { loginUser, loginLoading } = props;

  const [signupEmail, setSignupEmail] = useState('');
  const [password, setPassword] = useState('');

  const classes = useStyles();

  useEffect(() => {
    pageView('Login');

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.keyCode === 13) {
        onSubmit(signupEmail, password);
        return;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  const onSubmit = (email: string, password: string) => {
    loginUser(
      email,
      sha256(password),
      (user) => {
        setSignupEmail('');
        setPassword('');
        // @ts-ignore
        window.location = ROUTES.HOME_APP_PAGE;
      },
      (response: any) => {
        if (Object.keys(response).length > 0) {
          const key = Object.keys(response)[0];
          renderFailedToast(response[key][0]);
        }
      },
    );
  };

  return (
    <div>
      <FormGroup className="bp3-dark" label="Email address">
        <InputField
          value={signupEmail}
          onChange={(e: any) => setSignupEmail(e.target.value)}
          className={classes.signupInput}
          placeholder=""
        />
      </FormGroup>

      <FormGroup className={cx('bp3-dark', classes.passwordInputGroup)} label="Password">
        <InputField
          value={password}
          type="password"
          onChange={(e: any) => setPassword(e.target.value)}
          className={classes.signupInput}
          placeholder=""
        />
      </FormGroup>
      <Button
        className={classes.signInButton}
        intent={Intent.PRIMARY}
        onClick={() => {
          onSubmit(signupEmail, password);
        }}
        large
        loading={loginLoading}>
        Sign In
      </Button>
    </div>
  );
};

const renderFailedToast = (msg: string): void => {
  AppToaster.show({
    message: msg,
    icon: 'issue',
    timeout: 5000,
    intent: Intent.DANGER,
  });
};

const mapStateToProps = (state: ReduxState) => ({
  loginLoading: state.currentUser.loginLoading,
});

const mapDispatchToProps = {
  loginUser,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignIngPageV2));

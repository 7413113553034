/** @format */

import { Action } from 'reducers/rootReducer';
import _ from 'underscore';
import { Dataset, ACTION, FlowInfo } from 'actions/types';
import Fuse from 'fuse.js';

export type datasourceDictionary = { [id: string]: any };

interface DataSourcePageInfo {
  flows?: FlowInfo[];
  team_resource_ids: any;
  user_created_folders_info: any;
  user_resource_ids: any;
}

export interface WorkspaceReducerState {
  selectedDataSourceId: number | null;
  datasourceDictionary: datasourceDictionary;
  data: any | null;
  CSVUploadDatasource?: any;

  loadingIndividualActions: boolean;
  error: boolean;
  isWorkspaceSearchOpen: boolean;
  userCreatedFoldersIsCollapsed?: boolean[];
  userFlowsExpanded: boolean;
  teamFlowsExpanded: boolean;
  searchIndex: any | null;
  loadingCSVDatasource: boolean;
  // allFlows?: FlowInfo[];
  dataSourcePageInfo: DataSourcePageInfo;
}

const workspaceReducerInitialState: WorkspaceReducerState = {
  selectedDataSourceId: null,
  datasourceDictionary: {},
  data: null,
  loadingIndividualActions: false,
  error: false,
  isWorkspaceSearchOpen: false,
  userFlowsExpanded: true,
  teamFlowsExpanded: true,
  searchIndex: null,
  loadingCSVDatasource: false,
  dataSourcePageInfo: {} as any,
};

const getDatasetFromWorkspace = (workspaceData: WorkspaceReducerState, datasetId: number) => {
  if (!workspaceData.data) {
    return;
  }
  return _.findWhere(workspaceData.data.source_datasets.concat(workspaceData.data.saved_datasets), {
    id: datasetId,
  });
};

export default (state: WorkspaceReducerState = workspaceReducerInitialState, action: Action) => {
  const newState = Object.assign({}, state);
  const { payload } = action;

  switch (action.type) {
    case 'SWITCH_WORKSPACE_DATA':
      newState.data = newState.datasourceDictionary[payload.dataSourceId];
      const datasets = newState.data.source_datasets.concat(newState.data.saved_datasets);
      newState.searchIndex = new Fuse(datasets, {
        isCaseSensitive: false,
        threshold: 0.3,
        ignoreLocation: true,
        includeMatches: true,
        keys: ['name'],
      });
      return newState;
    case 'SWITCH_SELECTED_DATA_SOURCE':
      newState.selectedDataSourceId = payload.dataSourceId;
      return newState;
    case 'FETCH_WORKSPACE_DATA_REQUEST':
      newState.error = false;
      if (payload.id === 'csv') newState.loadingCSVDatasource = true;
      return newState;
    case 'FETCH_WORKSPACE_DATA_ERROR':
      newState.error = true;
      if (payload.id === 'csv') newState.loadingCSVDatasource = false;

      return newState;
    case 'FETCH_WORKSPACE_DATA_SUCCESS':
      newState.data = {
        // ...newState.data,
        ...payload.workspace,
      };

      const newDatasets = newState.data.source_datasets.concat(newState.data.saved_datasets);
      newDatasets.forEach((dataset: Dataset) => {
        if (!dataset.name) dataset.name = dataset.data_source_table_name;
      });
      newDatasets.forEach((dataset: Dataset) => {
        if (!dataset.name) dataset.name = dataset.data_source_table_name;
      });
      newState.searchIndex = new Fuse(newDatasets, {
        isCaseSensitive: false,
        threshold: 0.3,
        ignoreLocation: true,
        includeMatches: true,
        keys: ['name'],
      });
      newState.error = false;
      newState.dataSourcePageInfo.user_resource_ids = new Set(
        newState.dataSourcePageInfo.user_resource_ids,
      );
      newState.dataSourcePageInfo.team_resource_ids = new Set(
        newState.dataSourcePageInfo.team_resource_ids,
      );
      newState.datasourceDictionary[payload.workspace.id] = newState.data;

      if (payload.id === 'csv') {
        newState.CSVUploadDatasource = {
          ...payload.workspace,
        };
        newState.loadingCSVDatasource = false;
      }

      return newState;
    case 'CREATE_NEW_DATASET_SUCCESS':
      if (newState.data === null) {
        return newState;
      }

      newState.data.source_datasets = [
        {
          id: payload.data.id,
          name: payload.data.name,
          uploading: true,
        },
      ].concat(newState.data.source_datasets);

      if (payload.workspace === 'personal') {
        newState.dataSourcePageInfo.user_resource_ids.add(payload.data.id);
      } else if (payload.workspace === 'team') {
        newState.dataSourcePageInfo.team_resource_ids.add(payload.data.id);
      }

      return newState;
    case 'DATASET_UPLOAD_COMPLETE':
      if (newState.data === null) {
        return newState;
      }
      const datasetsById = _.indexBy(newState.data.source_datasets, 'id');
      const dataset: any = datasetsById[payload.datasetId];
      dataset.cached_preview = payload.previewData.cached_preview;
      dataset.cached_schema = payload.previewData.cached_schema;
      dataset.num_total_rows = payload.previewData.num_total_rows;
      dataset.total_row_count = payload.previewData.num_total_rows;
      dataset.creator_user_id = payload.previewData.creator_user_id;
      dataset.new = true;
      dataset.uploading = false;
      dataset.results_last_updated_at = true;

      return newState;
    case 'CREATE_FLOW_FROM_DATA_SET_SUCCESS':
      const parent_folder: any = _.findWhere(
        newState.dataSourcePageInfo.user_created_folders_info,
        {
          id: payload.parent_folder_id,
        },
      );
      if (parent_folder) parent_folder.resource_ids.add(payload.id);
      newState.dataSourcePageInfo.flows!.push({
        id: payload.id,
        name: payload.name,
        source_ids: [],
      } as any);
      return newState;
    case 'FETCH_DATASET_ROW_COUNT_SUCCESS':
      if (newState.data === null) {
        return newState;
      }
      let allDsById: { [id: number]: any } = _.indexBy(newState.data.source_datasets, 'id');
      allDsById = _.extend(allDsById, _.indexBy(newState.data.saved_datasets, 'id'));
      if (allDsById[payload.id]) {
        allDsById[payload.id].total_row_count = action.payload.row_count;
      }
      return newState;
    case 'GET_EPHEMERAL_DATASET_PREVIEW_REQUEST':
      if (newState.data === null) {
        return newState;
      }
      let allDataById: { [id: number]: any } = _.indexBy(newState.data.source_datasets, 'id');
      allDataById = _.extend(allDataById, _.indexBy(newState.data.saved_datasets, 'id'));
      if (allDataById[payload.id]) {
        allDataById[payload.id].loading = true;
      }
      return newState;
    case 'GET_EPHEMERAL_DATASET_PREVIEW_SUCCESS':
      if (newState.data === null) {
        return newState;
      }
      let allDSetsById: { [id: number]: any } = _.indexBy(newState.data.source_datasets, 'id');
      allDSetsById = _.extend(allDSetsById, _.indexBy(newState.data.saved_datasets, 'id'));
      if (allDSetsById[payload.id]) {
        allDSetsById[payload.id].cached_preview = action.payload.preview_rows;
        allDSetsById[payload.id].loading = false;
      }
      return newState;
    case 'GET_DATASET_PREVIEW_SUCCESS':
      if (newState.data === null) {
        return newState;
      }
      let allDatasetsById: { [id: number]: any } = _.indexBy(newState.data.source_datasets, 'id');
      allDatasetsById = _.extend(allDatasetsById, _.indexBy(newState.data.saved_datasets, 'id'));
      if (allDatasetsById[payload.id]) {
        allDatasetsById[payload.id].cached_preview = action.payload.preview_rows;
        allDatasetsById[payload.id].loading = false;
        allDatasetsById[payload.id].error = false;
        allDatasetsById[payload.id].results_last_updated_at = true;
      }
      return newState;
    case 'GET_DATASET_PREVIEW_ERROR':
      let sourceDatasetsById: { [id: number]: any } = _.indexBy(
        newState.data.source_datasets,
        'id',
      );
      const everyDatasetById = _.extend(
        sourceDatasetsById,
        _.indexBy(newState.data.saved_datasets, 'id'),
      );
      if (everyDatasetById[payload.id]) {
        everyDatasetById[payload.id].loading = false;
        everyDatasetById[payload.id].error = true;
      }
      return newState;
    case 'RENAME_FLOW_REQUEST':
      newState.loadingIndividualActions = true;
      return newState;
    case 'RENAME_FLOW_ERROR':
      newState.loadingIndividualActions = false;
      return newState;
    case 'RENAME_FLOW_SUCCESS':
      newState.loadingIndividualActions = false;
      if (newState.data) {
        const flowToRename: any = _.findWhere(newState.data.flows, { id: payload.id });
        if (flowToRename) {
          flowToRename.name = payload.new_name;
        }
      }
      return newState;
    case 'RENAME_DATASET_REQUEST':
      let datasetToRename: any = getDatasetFromWorkspace(newState, payload.id);
      if (datasetToRename) {
        datasetToRename.actionLoading = true;
      }
      return newState;
    case 'RENAME_DATASET_SUCCESS':
      let renamedDataset: any = getDatasetFromWorkspace(newState, payload.id);
      if (renamedDataset) {
        renamedDataset.actionLoading = false;
        renamedDataset.name = payload.new_name;
      }
      return newState;
    case 'RENAME_DATASET_ERROR':
      let datasetFailedRename: any = getDatasetFromWorkspace(newState, payload.id);
      if (datasetFailedRename) {
        datasetFailedRename.actionLoading = false;
      }
      return newState;
    case 'DELETE_DATASET_REQUEST':
      let datasetToDelete: any = getDatasetFromWorkspace(newState, payload.id);
      if (datasetToDelete) {
        datasetToDelete.actionLoading = true;
      }
      return newState;
    case 'DELETE_DATASET_SUCCESS':
      if (newState.data) {
        if (newState.data.source_datasets) {
          let new_source_datasets = newState.data.source_datasets.filter(
            (dataset: any) => dataset['id'] !== payload.id,
          );
          newState.data.source_datasets = new_source_datasets;
        }
        if (newState.data.saved_datasets) {
          let new_saved_datasets = newState.data.saved_datasets.filter(
            (dataset: any) => dataset['id'] !== payload.id,
          );
          newState.data.saved_datasets = new_saved_datasets;
        }
      }
      return newState;
    case 'DELETE_DATASET_ERROR':
      let datasetFailedDelete: any = getDatasetFromWorkspace(newState, payload.id);
      if (datasetFailedDelete) {
        datasetFailedDelete.actionLoading = false;
      }
      return newState;
    case 'DELETE_FLOW_REQUEST':
      newState.loadingIndividualActions = true;
      return newState;
    case 'DELETE_FLOW_SUCCESS':
      if (newState.data) {
        newState.data.flows = newState.data.flows.filter((flow: any) => flow.id !== payload.id);
      }
      newState.loadingIndividualActions = false;
      return newState;
    case 'DELETE_FLOW_ERROR':
      newState.loadingIndividualActions = false;
      return newState;
    case 'SAVE_AS_DATASET_SUCCESS':
      newState.data.saved_datasets.push(payload.saved_dataset_data);
      return newState;
    case 'MOVE_RESOURCE_REQUEST':
      newState.loadingIndividualActions = true;
      return newState;
    case 'MOVE_RESOURCE_SUCCESS':
      if (newState.dataSourcePageInfo.team_resource_ids.has(payload.r_id)) {
        newState.dataSourcePageInfo.team_resource_ids.delete(payload.r_id);
        newState.dataSourcePageInfo.user_resource_ids.add(payload.r_id);
      } else {
        newState.dataSourcePageInfo.user_resource_ids.delete(payload.r_id);
        newState.dataSourcePageInfo.team_resource_ids.add(payload.r_id);
      }
      newState.loadingIndividualActions = false;
      return newState;
    case 'BULK_MOVE_RESOURCE_REQUEST':
      newState.loadingIndividualActions = true;
      return newState;
    case 'BULK_MOVE_RESOURCE_SUCCESS':
      payload.r_ids.forEach((rid: number) => {
        if (newState.dataSourcePageInfo.team_resource_ids.has(rid)) {
          newState.dataSourcePageInfo.team_resource_ids.delete(rid);
          newState.dataSourcePageInfo.user_resource_ids.add(rid);
        } else {
          newState.dataSourcePageInfo.user_resource_ids.delete(rid);
          newState.dataSourcePageInfo.team_resource_ids.add(rid);
        }
      });
      newState.loadingIndividualActions = false;
      return newState;
    case 'SYNC_DATA_SOURCE_SUCCESS':
      if (!newState.data) {
        return newState;
      }

      const sourceDatasetsByIds: { [id: number]: any } = _.indexBy(
        newState.data.source_datasets,
        'id',
      );
      // Preserving user defined name, if applicable.
      _.forEach(payload.updated_datasets, (dataset: Dataset) => {
        if (dataset.id in sourceDatasetsByIds && sourceDatasetsByIds[dataset.id].name !== '') {
          dataset.name = sourceDatasetsByIds[dataset.id].name;
        }
      });
      newState.data.source_datasets = payload.updated_datasets.concat(payload.new_datasets);
      return newState;
    case 'UPDATE_WORKSPACE_SEARCH_STATE':
      newState.isWorkspaceSearchOpen = payload.isWorkspaceSearchOpen;
      return newState;
    case `${ACTION.GET_EXPLORATIONS}_SUCCESS`:
      newState.dataSourcePageInfo = payload;

      // newState.allFlows = payload.flows;
      // newState.data = {
      //   // ...newState.data,
      //   ...payload,
      // };
      newState.error = false;
      if (!newState.dataSourcePageInfo) return newState;
      newState.dataSourcePageInfo.user_resource_ids = new Set(
        newState.dataSourcePageInfo.user_resource_ids,
      );
      newState.dataSourcePageInfo.team_resource_ids = new Set(
        newState.dataSourcePageInfo.team_resource_ids,
      );
      newState.dataSourcePageInfo.user_created_folders_info.forEach((folder_info: any) => {
        folder_info.resource_ids = new Set(folder_info.resource_ids);
      });
      newState.userCreatedFoldersIsCollapsed = _.times(
        newState.dataSourcePageInfo.user_created_folders_info.length,
        _.constant(false),
      );
      return newState;
    case 'UPDATE_CREATED_FOLDERS_IS_COLLAPSED':
      newState.userCreatedFoldersIsCollapsed = payload.userCreatedFoldersIsCollapsed;
      return newState;
    case 'UPDATE_USER_FLOWS_EXPANDED':
      newState.userFlowsExpanded = payload.userFlowsExpanded;
      return newState;
    case 'UPDATE_TEAM_FLOWS_EXPANDED':
      newState.teamFlowsExpanded = payload.teamFlowsExpanded;
      return newState;
    default:
      return state;
  }
};

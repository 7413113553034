/** @format */

import { OperationFooterErrorInformation } from 'utils/operationErrorInfoUtils/generalOperationErrorInfoUtil';

enum FOOTER_ERROR_TEXT {
  NO_ERROR = 'No Error',
  SELECT_COLUMN_TO_GROUP_BY = 'Please select a column to group by',
  CONFIGURE_VALUES = 'Please configure the chart values',
}

export const getPivotChartOperationFooterErrorInformation = (
  instructions: any,
): OperationFooterErrorInformation => {
  const operationFooterErrorInformation: OperationFooterErrorInformation = {
    footerErrorState: false,
    footerErrorText: FOOTER_ERROR_TEXT.NO_ERROR,
  };
  if (instructions.xAxisColumn === null) {
    operationFooterErrorInformation.footerErrorState = true;
    operationFooterErrorInformation.footerErrorText = FOOTER_ERROR_TEXT.SELECT_COLUMN_TO_GROUP_BY;
  } else if (
    instructions.aggregation.aggedOnColumn === null ||
    instructions.aggregation.type === null
  ) {
    operationFooterErrorInformation.footerErrorState = true;
    operationFooterErrorInformation.footerErrorText = FOOTER_ERROR_TEXT.CONFIGURE_VALUES;
  }
  return operationFooterErrorInformation;
};

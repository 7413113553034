/** @format */

import { SetupFlowStepKey } from 'constants/setupPageConstants';
import { Schema, TableData, OperationInstructions } from 'constants/types';

export enum ACTION {
  // Dashboard
  FETCH_DASHBOARD_LIST = 'FETCH_DASHBOARD_LIST',
  FETCH_DASHBOARD = 'FETCH_DASHBOARD',
  DELETE_DASHBOARD = 'DELETE_DASHBOARD',
  RENAME_DASHBOARD = 'RENAME_DASHBOARD',
  UPDATE_DASHBOARD_LAYOUT = 'UPDATE_DASHBOARD_LAYOUT',
  ADD_OPERATION_TO_DASHBOARD = 'ADD_OPERATION_TO_DASHBOARD',
  REMOVE_OPERATION_FROM_DASHBOARD = 'REMOVE_OPERATION_FROM_DASHBOARD',
  CREATE_DASHBOARD = 'CREATE_DASHBOARD',
  UPDATE_OPERATION_TITLE = 'UPDATE_OPERATION_TITLE',

  // Covid
  FETCH_COVID_OPERATIONS = 'FETCH_COVID_OPERATIONS',
  FETCH_COVID_DAILY_STATS = 'FETCH_COVID_DAILY_STATS',
  FETCH_FLOW_DATA = 'FETCH_FLOW_DATA',

  // Flow Graph
  COMPUTE_EXISTING_STEP_PREVIEW = 'COMPUTE_EXISTING_STEP_PREVIEW',
  UPDATE_FLOW_OPERATION = 'UPDATE_FLOW_OPERATION',
  REFRESH_FLOW = 'REFRESH_FLOW',

  GET_EXPLORATIONS = 'GET_EXPLORATIONS',
  FETCH_WORKSPACE_DATA = 'FETCH_WORKSPACE_DATA',
  FETCH_DATA_SOURCE_LIST = 'FETCH_DATA_SOURCE_LIST',
  CREATE_FLOW_FROM_DATA_SET = 'CREATE_FLOW_FROM_DATA_SET',
}

// Serializer Types:
interface setupInfo {
  setup_flow_step_key: SetupFlowStepKey;
}

export interface User {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  email_verified?: boolean;
  profile_data?: object;
  is_superuser?: boolean;
  root_folder_id?: number;
  has_team?: boolean;
  setup_info?: setupInfo;
}

export interface Team {
  id: number;
  team_name: string;
  logo_url: string;
  required_email_domain: string | null;
  invite_code: string;
  team_members: User[];
  team_folder: Folder;
}

export interface Folder {
  id: number;
  name: string;
}

export interface Dataset {
  id: number;
  name: string;
  ephemeral: boolean;
  cached_schema: Schema | null;
  cached_preview: TableData | null;
  cached_query: string | null;
  total_row_count: number | null;
  data_source_table_name: string;
  data_source_id: number;
  results_last_updated_at: string;
  is_source_dataset: boolean;
  results_job_error: any;
  loading: boolean;
  is_explo_source: boolean;
  error?: boolean;
  uploading?: boolean;
  actionLoading?: boolean;
  new?: boolean;
  creator_user_id?: number;
}

export interface DatasetFlowNode {
  id: number;
  dataset: Dataset;
  sourceDataset?: Dataset;
  order: number;
  flow_position_x: number | null; // depreciated
  flow_position_y: number | null; // depreciated
  linked_operations: { id: number }[];
  operation_nodes: { id: number; order?: number }[];
  compute_loading?: boolean;
  rowError?: boolean;
}

export interface Flow {
  id: number;
  name: string;
  source_type?: string;
  operations?: Operation[];
  dataset_nodes: DatasetFlowNode[];
  data_source: DataSource;
  last_refreshed_at?: string;
}

export interface FlowMini {
  id: number;
  name: string;
  source_type?: string;
}

export interface FlowViewConfig {
  scale: number;
  offset_left: number;
  offset_top: number;
  selected_operation_id: number | null;
}

export interface FlowInfo {
  id: number;
  name: string;
  source_ids: number[];
  created: string;
  modified: string;
  num_datasets: number;
  num_graph_ops: number;
  num_total_ops: number;
}

export interface DataSource {
  id: number;
  source_type?: string;
  team_id: number;
  explo_source: boolean;
  name: string;
  loading?: boolean;
  created: string;
  dataset_count: number;
}

export interface OperationType {
  id: string;
  name: string;
  icon?: string;
  disabled?: string;
}

export interface Operation {
  child_operations: { id: number; order?: number }[];
  collapsed?: boolean;
  compute_loading?: boolean;
  error_msg?: string | null;
  flow_id: number;
  flow_position_x?: number | null; // depreciated
  flow_position_y?: number | null; // depreciated
  flow: Flow;
  id: number;
  instructions: OperationInstructions;
  operation_type: string | number;
  order: number;
  result_num_rows?: number;
  results_dataset: Dataset;
  results_job_error?: any;
  results_table?: TableData;
  sourceDataset?: Dataset;
  title?: string;
  up_to_date: boolean;
  rowError?: boolean;
}

export interface PreviewData {
  cached_preview: TableData;
  cached_schema: Schema;
  num_total_rows: number;
  creator_user_id?: number;
}

export interface Report {
  name: string;
  sections: ReportSection[];
}

export interface ReportSection {
  title: string;
  description: string;
  order: number;
  operations: Operation[];
}

export interface DashboardItem {
  id: number;
  name: string;
}

export interface CovidStats {
  positive__sum: number;
  positive_increase__sum: number;
  death__sum: number;
  death_increase__sum: number;
}

export interface DashboardFilterField {
  id: string;
  operation_id: number;
  dashboard_filter_id: number;
  column_name: string;
  column_type: string;
  value?: any;
  filter_operator?: any;
  source_type: string;
}

export interface DashboardFilter {
  id: number;
  dashboard_id: number;
  name: string;
  filter_type: string;
  value?: any;
  filter_operator: any;
  operation_fields: { [operation_id: string]: DashboardFilterField };
}

/** @format */

import React from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

import SectionTitle from 'explo-ds/typography/marketing/sectionTitle';
import IntegrationDescription from 'explo-ds/typography/marketing/integrationDescription';

import Postgres from 'images/marketing/integration_logos/postgres.png';
import MySQL from 'images/marketing/integration_logos/mysql.png';
import BigQuery from 'images/marketing/integration_logos/bigquery.png';
import Snowflake from 'images/marketing/integration_logos/snowflake.png';
import Mongo from 'images/marketing/integration_logos/mongo.png';
import Azure from 'images/marketing/integration_logos/azure.png';
import Redshift from 'images/marketing/integration_logos/redshift.png';

import Shopify from 'images/marketing/integration_logos/shopify.png';
import Segment from 'images/marketing/integration_logos/segment.png';
import Klaviyo from 'images/marketing/integration_logos/klaviyo.png';
import GoogleAnalytics from 'images/marketing/integration_logos/google_analytics.png';
import Salesforce from 'images/marketing/integration_logos/salesforce.png';
import Stripe from 'images/marketing/integration_logos/stripe.png';
import GoogleSheets from 'images/marketing/integration_logos/google_sheets.png';
import Excel from 'images/marketing/integration_logos/excel.png';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  title1: {
    color: theme.palette.white,
    fontWeight: 'bold',
  },
  title2: {
    color: theme.palette.white,
  },
  integrationRow: {
    display: 'grid',
    gridTemplateColumns: '0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.3fr',
    gridColumnGap: theme.spacing(6),
    marginTop: 45,
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '0.25fr 0.25fr 0.25fr 0.25fr',
      gridRowGap: theme.spacing(6),
    },
  },
  integrationImg: {
    width: '100%',
  },
  integrationDescription: {
    color: theme.palette.grey100,
  },
  integrationDescriptionContainer: {
    [theme.breakpoints.down('xs')]: {
      gridColumnStart: 1,
      gridColumnEnd: 5,
      textAlign: 'center',
    },
  },
}));

type Props = {
  className?: string;
};

const IntegrateSourcesSection = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.root, props.className)}>
      <div>
        <SectionTitle className={classes.title1}>Easily Integrate </SectionTitle>
        <SectionTitle className={classes.title2}>multiple data sources</SectionTitle>
      </div>
      <div className={classes.integrationRow}>
        <img className={classes.integrationImg} src={Postgres} alt="postgres logo" />
        <img className={classes.integrationImg} src={MySQL} alt="mysql logo" />
        <img className={classes.integrationImg} src={BigQuery} alt="big query logo" />
        <img className={classes.integrationImg} src={Snowflake} alt="snowflake logo" />
        <img className={classes.integrationImg} src={Mongo} alt="mongo logo" />
        <img className={classes.integrationImg} src={Azure} alt="azure logo" />
        <img className={classes.integrationImg} src={Redshift} alt="redshift logo" />
        <div></div>
        <div className={classes.integrationDescriptionContainer}>
          <IntegrationDescription className={classes.integrationDescription}>
            Seamlessly connect to your <b>database</b>
          </IntegrationDescription>
        </div>
      </div>
      <div className={classes.integrationRow}>
        <img className={classes.integrationImg} src={Shopify} alt="Shopify logo" />
        <img className={classes.integrationImg} src={Segment} alt="Segment logo" />
        <img className={classes.integrationImg} src={Klaviyo} alt="Klaviyo query logo" />
        <img className={classes.integrationImg} src={GoogleAnalytics} alt="GoogleAnalytics logo" />
        <img className={classes.integrationImg} src={Salesforce} alt="Salesforce logo" />
        <img className={classes.integrationImg} src={Stripe} alt="Stripe logo" />
        <img className={classes.integrationImg} src={GoogleSheets} alt="GoogleSheets logo" />
        <img className={classes.integrationImg} src={Excel} alt="Excel logo" />
        <div className={classes.integrationDescriptionContainer}>
          <IntegrationDescription className={classes.integrationDescription}>
            Analyze your data from <b>across platforms</b>
          </IntegrationDescription>
        </div>
      </div>
    </div>
  );
};

export default IntegrateSourcesSection;

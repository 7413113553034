/** @format */

import React, { Component } from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import { InputGroup, Button } from '@blueprintjs/core';

const styles = (theme: Theme) => ({
  name: {
    textTransform: 'uppercase' as 'uppercase',
    fontWeight: 'bold' as 'bold',
    fontSize: 12,
    marginBottom: theme.spacing(1),
  },
  raiseName: {},
});

type PassedProps = {
  name: string;
  initialValue?: string;
  onNewValueSubmitted: (newValue: string) => void;
};

type Props = WithStyles<typeof styles> & PassedProps;

type State = {
  value?: string;
  inputFocused: boolean;
};

export class InputWithName extends Component<Props, State> {
  inputRef: any;

  state: State = {
    value: this.props.initialValue,
    inputFocused: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState({ value: this.props.initialValue });
      this.inputRef.blur();
    }
  }

  render = () => {
    const { value } = this.state;
    const { name, classes, initialValue } = this.props;

    return (
      <div>
        <div className={classes.name}>{name}</div>
        <InputGroup
          value={value}
          onFocus={() => {
            this.setState({ inputFocused: true });
          }}
          onBlur={() => {
            this.setState({ value: initialValue, inputFocused: false });
          }}
          onKeyPress={this.onKeyPress}
          onChange={(e: any) => {
            this.setState({ value: e.target.value });
          }}
          rightElement={this.renderRightElement()}
          inputRef={(ref) => (this.inputRef = ref)}
        />
      </div>
    );
  };

  renderRightElement = () => {
    const { inputFocused } = this.state;
    const { onNewValueSubmitted } = this.props;

    const icon = inputFocused ? 'key-enter' : 'cross';
    const disabled = inputFocused;
    const onClick = inputFocused ? undefined : () => onNewValueSubmitted('');

    return <Button icon={icon} disabled={disabled} minimal onClick={onClick} />;
  };

  onKeyPress = (event: any) => {
    const { onNewValueSubmitted } = this.props;
    const { value } = this.state;
    var code = event.keyCode || event.which;
    if (code === 13 && value) {
      onNewValueSubmitted(value || '');
    }
  };
}

export default withStyles(styles)(InputWithName);

/** @format */

import React from 'react';
import _ from 'underscore';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import { Intent, NonIdealState } from '@blueprintjs/core';

import { pageView } from 'analytics/exploAnalytics';

import TextFieldModal from 'components/TextFieldModal';
import ConfirmationModal from 'components/modals/confirmationModal';

import FlowsSection from 'components/dataSource/flowsSection';
import SortByButton from 'components/dataSource/sortByButton';
import { AppToaster } from 'toaster';
import { trackEvent } from 'analytics/exploAnalytics';
import { FlowInfo, ACTION } from 'actions/types';
import DeleteFlowConfirmationModal from 'components/modals/deleteFlowConfirmationModal';
import DeleteDatasetConfirmationModal from 'components/modals/deleteDatasetConfirmationModal';
import ShareFlowConfirmationModal from 'components/modals/shareFlowConfirmationModal';

import {
  renameDataset,
  deleteDataset,
  renameFlow,
  moveResource,
  bulkMoveResource,
} from 'actions/fileSystemActions';
import { createFlowFromDataset } from 'actions/flowActions';
import { Dataset } from 'actions/types';
import {
  setUserCreatedFoldersIsCollapsed,
  setUserFlowsExpanded,
  setTeamFlowsExpanded,
} from 'actions/dataSourceActions';

import {
  SORT_OPTIONS_BY_ID,
  SortOptionType,
  FLOWS_ORDERED_SORT_OPTIONS,
} from 'constants/flowConstants';

import LoadingSpinner from 'images/loading_spinner.gif';
import { createLoadingSelector } from 'reducers/api/selectors';
import NewFlowModal from 'components/dataSource/newFlowModal';

const styles = (theme: Theme) => ({
  root: {
    height: 'calc(100vh - 80px)',
    display: 'flex',
    overflowY: 'auto' as 'auto',
    width: '100%',
  },
  pageBody: {
    margin: `${theme.spacing(5)}px ${theme.spacing(6)}px`,
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
  },
  body: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
  },
  bodyContainer: {},
  loadingBody: {
    height: '90vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  actionButton: {
    marginRight: theme.spacing(3),
  },
  sectionHeader: {
    margin: `0 ${theme.spacing(6)}px`,
  },
  flowsSection: {
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(5),
  },
  flowPanelContainers: {
    margin: theme.spacing(6),
    marginTop: theme.spacing(5),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: theme.spacing(4),
    rowGap: `${theme.spacing(4)}px`,
  },
  flowPanel: {
    width: '100%',
  },
  datasetActions: {
    paddingLeft: theme.spacing(2),
  },
  noFlowsInFolder: {
    marginTop: theme.spacing(10),
  },
  nonIdealStateError: {
    paddingTop: theme.spacing(42),
  },
  flowActionsBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  loadingSpinner: {
    width: 75,
  },
  startAnExplorationButton: {
    height: theme.spacing(10),
    width: 170,
    backgroundColor: theme.palette.exploBlue,
    color: theme.palette.white,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    marginLeft: theme.spacing(6),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  pageHeader: {
    marginTop: theme.spacing(3),
    fontWeight: 500,
    fontSize: 24,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    alignItems: 'center',
  },
  spinner: {
    backgroundColor: theme.palette.white,
  },
});

type MatchParams = {
  dataSourceId: string;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams> &
  WithStyles<typeof styles>;

type State = {
  selectedDataset: any | null;
  workspaceId: number | null;
  selectedFlowsSort: SortOptionType;
  uploadFileModalOpen: boolean;
  uploadFileFile: any | null;
  createFlowModalOpen: boolean;
  renameDatasetModalOpen: boolean;
  deleteDatasetModalOpen: boolean;
  moveDatasetModalOpen: boolean;
  renameFlowModalOpen: boolean;
  moveFlowModalOpen: boolean;
  deleteFlowModalOpen: boolean;
  editingFlow: FlowInfo | null;
  newFlowModalOpen: boolean;
  destFolderId?: number;
};

class DataSourcePage extends React.Component<Props, State> {
  state: State = {
    selectedDataset: null,
    workspaceId: null,
    selectedFlowsSort: SORT_OPTIONS_BY_ID.LAST_EDITED,
    uploadFileModalOpen: false,
    uploadFileFile: null,
    createFlowModalOpen: false,
    renameDatasetModalOpen: false,
    deleteDatasetModalOpen: false,
    moveDatasetModalOpen: false,
    renameFlowModalOpen: false,
    moveFlowModalOpen: false,
    deleteFlowModalOpen: false,
    editingFlow: null,
    newFlowModalOpen: false,
  };

  componentDidMount() {
    pageView('Data Source');
  }

  render() {
    const { classes, workspaceData, workspaceDataLoading, createFlowLoading } = this.props;

    if (workspaceData.error === true) {
      return (
        <div className={classes.nonIdealStateError}>
          <NonIdealState
            icon={'error'}
            title="Data source not found."
            description={"The data source either doesn't exist or hasn't been shared with you."}
          />
        </div>
      );
    } else if (workspaceDataLoading || createFlowLoading) {
      return (
        <div className={classes.loadingBody}>
          <img className={classes.loadingSpinner} src={LoadingSpinner} alt="loading spinner" />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <div className={classes.pageBody}>
          {this.renderHeader()}
          {this.renderSortByButton()}
          {this.renderBody()}
          {this.renderCreateFlowModal()}
          {this.renderRenameFlowModal()}
          {this.renderMoveFlowModal()}
          {this.renderDeleteFlowModal()}
          {this.renderNewFlowModal()}
          {this.renderRenameDatasetModal()}
          {this.renderDeleteDatasetModal()}
          {this.renderMoveDatasetModal()}
        </div>
      </div>
    );
  }

  renderHeader = () => {
    const { classes } = this.props;
    return (
      <div className={classes.headerContainer}>
        <div className={classes.pageHeader}>Explorations</div>
        <div
          onClick={() => this.setState({ newFlowModalOpen: true })}
          className={classes.startAnExplorationButton}>
          Start an Exploration
        </div>
      </div>
    );
  };

  currentDataSourceName = () => {
    const { dataSourceList } = this.props;
    const dataSourceById = _.indexBy(dataSourceList, 'id');
    const currentDataSource = dataSourceById[this.props.match.params.dataSourceId];
    return currentDataSource && currentDataSource.name;
  };

  renderBody = () => {
    const { classes } = this.props;

    return (
      <div className={classes.bodyContainer}>
        <div className={classes.body}>{this.renderFlows()}</div>
      </div>
    );
  };

  renderNewFlowModal = () => {
    const { newFlowModalOpen, destFolderId } = this.state;

    return (
      <NewFlowModal
        //@ts-ignore
        selectDatasetModalOpen={newFlowModalOpen}
        setDatasetModalOpen={(newFlowModalOpen: boolean) => this.setState({ newFlowModalOpen })}
        destFolderId={destFolderId}
      />
    );
  };

  isSourceDatasetAndNotExploSource = (isSourceDataset: boolean) => {
    const { workspaceData } = this.props;

    if (isSourceDataset) {
      if (!(workspaceData.data && workspaceData.data.explo_source)) {
        return true;
      }
    }
    return false;
  };

  createFlow = (flowName: string) => {
    const { createFlowFromDataset, currentUser } = this.props;
    const { selectedDataset } = this.state;

    if (selectedDataset === null) {
      return;
    }

    const definedFolderId: number = currentUser.root_folder_id || -1;
    createFlowFromDataset(selectedDataset.id, definedFolderId, flowName, (flowId: number) =>
      this.props.history.push(`/flows/${flowId}`),
    );
  };

  renderSortByButton = () => {
    const { selectedFlowsSort } = this.state;
    const { classes } = this.props;

    return (
      <SortByButton
        className={classes.flowActionsBar}
        selectedSort={selectedFlowsSort}
        setSort={(newSort) => this.setState({ selectedFlowsSort: newSort })}
        sortOptions={FLOWS_ORDERED_SORT_OPTIONS}
      />
    );
  };

  renderFlows = () => {
    const {
      classes,
      workspaceData,
      currentUser,
      teamData,
      userFlowsExpanded,
      teamFlowsExpanded,
      setUserFlowsExpanded,
      setTeamFlowsExpanded,
    } = this.props;
    const { selectedFlowsSort } = this.state;

    const allFlows = this.getFlows();

    const teamResourceIds = new Set(
      workspaceData.dataSourcePageInfo ? workspaceData.dataSourcePageInfo!.team_resource_ids : [],
    );
    const teamFlows = _.filter(allFlows, (flow: any) => teamResourceIds.has(flow.id));
    const userFlows = _.filter(allFlows, (flow: any) => !teamResourceIds.has(flow.id));
    const teamName = teamData.data ? `${teamData.data.team_name} Team` : 'Team';

    const sharedProps = {
      className: classes.flowsSection,
      selectedFlowsSort: selectedFlowsSort,
      onRenameClicked: (flow: FlowInfo) =>
        this.setState({ renameFlowModalOpen: true, editingFlow: flow }),
      onMoveClicked: (flow: FlowInfo) =>
        this.setState({ moveFlowModalOpen: true, editingFlow: flow }),
      onDeleteClicked: (flow: FlowInfo) => {
        this.setState({ deleteFlowModalOpen: true, editingFlow: flow });
      },
    };

    return (
      <>
        <FlowsSection
          {...sharedProps}
          flows={userFlows}
          title="My Explorations"
          isExpanded={userFlowsExpanded}
          toggleExpanded={() => setUserFlowsExpanded(!userFlowsExpanded)}
          isPersonalFlows
          openNewFlowModal={() =>
            this.setState({ newFlowModalOpen: true, destFolderId: currentUser.root_folder_id })
          }
        />
        <FlowsSection
          {...sharedProps}
          flows={teamFlows}
          title={`${teamName} Explorations`}
          isExpanded={teamFlowsExpanded}
          toggleExpanded={() => setTeamFlowsExpanded(!teamFlowsExpanded)}
          openNewFlowModal={() =>
            this.setState({
              newFlowModalOpen: true,
              destFolderId: teamData.data ? teamData.data.team_folder.id : undefined,
            })
          }
        />
        {workspaceData.dataSourcePageInfo!.user_created_folders_info.map(
          (folderInfo: any, index: number) =>
            this.renderFlowsSection(folderInfo, sharedProps, allFlows, index),
        )}
      </>
    );
  };

  renderFlowsSection = (folderInfo: any, sharedProps: any, allFlows: any[], index: number) => {
    const { userCreatedFoldersIsCollapsed, setUserCreatedFoldersIsCollapsed } = this.props;
    return (
      <FlowsSection
        key={folderInfo.id}
        {...sharedProps}
        flows={_.filter(allFlows, (flow: any) => folderInfo.resource_ids.has(flow.id))}
        title={folderInfo.name}
        isExpanded={!userCreatedFoldersIsCollapsed![index]}
        toggleExpanded={() => {
          if (userCreatedFoldersIsCollapsed) {
            userCreatedFoldersIsCollapsed[index] = !userCreatedFoldersIsCollapsed[index];
            setUserCreatedFoldersIsCollapsed(userCreatedFoldersIsCollapsed);
          }
        }}
        openNewFlowModal={() =>
          this.setState({ newFlowModalOpen: true, destFolderId: folderInfo.id })
        }
      />
    );
  };

  getFlows = () => {
    const { workspaceData } = this.props;
    return workspaceData.dataSourcePageInfo ? workspaceData.dataSourcePageInfo.flows! : [];
  };

  getDatasetNamesById = (): { [id: number]: any } => {
    const { workspaceData } = this.props;

    if (workspaceData.data === null) {
      return {};
    }
    const datasets = workspaceData.data.source_datasets.concat(workspaceData.data.saved_datasets);

    let datasetNamesById: { [id: number]: string } = {};
    datasets.forEach((dataset: Dataset) => (datasetNamesById[dataset.id] = dataset.name));

    return datasetNamesById;
  };

  closeRenameDatasetModal = () => {
    this.setState({
      renameDatasetModalOpen: false,
      selectedDataset: null,
    });
  };

  openRenameDatasetModal = (selectedDataset: any | null) => {
    this.setState({
      renameDatasetModalOpen: true,
      selectedDataset,
    });
  };

  openDeleteDatasetModal = (selectedDataset: any | null) => {
    this.setState({
      deleteDatasetModalOpen: true,
      selectedDataset,
    });
  };

  openMoveDatasetModal = (selectedDataset: any | null) => {
    this.setState({
      moveDatasetModalOpen: true,
      selectedDataset,
    });
  };

  renameDataset = (resourceId: number | null, oldName: string, newName: string) => {
    this.props.renameDataset(
      { id: resourceId, postData: { name: newName } },
      () => this.successToast('Resource was succesfully renamed.'),
      (errorData: any) => this.errorToast(errorData),
    );

    trackEvent('Rename resource', {
      resource_id: resourceId,
      orig_name: oldName,
      new_name: newName,
      resource_type: 'dataset',
    });
  };

  moveResource = (
    resourceId: number,
    currFolderId: number,
    destFolderId: number,
    resourceType: string,
  ) => {
    const args = {
      id: resourceId,
      postData: {
        curr_folder_id: currFolderId,
        dest_folder_id: destFolderId,
      },
    };

    this.props.moveResource(args, undefined, undefined);
    trackEvent('Move resource', {
      resource_id: resourceId,
      curr_folder: currFolderId,
      dest_folder: destFolderId,
      resource_type: resourceType,
    });
  };

  deleteDataset = (resourceId: number, name: string, resourceType: string) => {
    this.props.deleteDataset(
      { id: resourceId },
      () => {
        if (resourceType === 'dataset') {
          this.setState({
            selectedDataset: null,
          });
        }
        this.successToast('Resource, ' + name + ', was successfully deleted');
      },
      (response: any) => this.errorToast(response),
    );
    trackEvent('Delete resource', {
      resource_id: resourceId,
      name: name,
      resource_type: resourceType,
    });
  };

  errorToast = (responseData: any) => {
    AppToaster.show({
      // Sometimes an object is inputted and, if not, the direct error message is inputed.
      message: responseData['msg'] || responseData,
      icon: 'error',
      timeout: 5000,
      intent: Intent.DANGER,
    });
  };

  successToast = (msg = 'Success!') => {
    AppToaster.show({
      message: msg,
      icon: 'tick',
      timeout: 3000,
      intent: Intent.SUCCESS,
    });
  };

  renderCreateFlowModal = () => {
    return (
      <TextFieldModal
        modalOpen={this.state.createFlowModalOpen}
        closeModal={() => this.setState({ createFlowModalOpen: false })}
        modalTitle="Create Exploration"
        textFieldPlaceholder="Exploration Name"
        buttonName="Create"
        onSubmit={this.createFlow}
      />
    );
  };

  renderRenameDatasetModal = () => {
    const { selectedDataset, renameDatasetModalOpen } = this.state;
    if (!renameDatasetModalOpen) return;

    return (
      <TextFieldModal
        modalOpen={renameDatasetModalOpen}
        closeModal={this.closeRenameDatasetModal}
        modalTitle="Rename Dataset"
        textFieldPlaceholder="New Name"
        resourceName={
          selectedDataset && (selectedDataset.name || selectedDataset.data_source_table_name)
        }
        buttonName="Rename"
        onSubmit={(newName: string) =>
          this.renameDataset(selectedDataset.id, selectedDataset.name, newName)
        }
        errorState={this.textFieldModalErrorState}
      />
    );
  };

  textFieldModalErrorState = (textFieldVal?: string) => {
    const { workspaceData } = this.props;
    let allDatasets = workspaceData.data.source_datasets.concat(workspaceData.data.saved_datasets);
    const isErrorState =
      !!textFieldVal && _.contains(_.pluck(allDatasets, 'name'), textFieldVal.trim());
    return {
      isErrorState,
      errorMsg: isErrorState ? 'Name already exists' : undefined,
    };
  };

  renderRenameFlowModal = () => {
    const { renameFlowModalOpen, editingFlow } = this.state;
    if (!renameFlowModalOpen || !editingFlow) {
      return;
    }

    return (
      <TextFieldModal
        modalOpen={renameFlowModalOpen}
        closeModal={() => {
          this.setState({ renameFlowModalOpen: false, editingFlow: null });
        }}
        modalTitle="Rename Exploration"
        textFieldPlaceholder="New Name"
        resourceName={editingFlow && editingFlow.name}
        buttonName="Rename"
        onSubmit={(newName: string) =>
          this.props.renameFlow(
            { id: editingFlow.id, postData: { name: newName } },
            () => this.successToast('Flow was succesfully renamed.'),
            (errorData: any) => this.errorToast(errorData),
          )
        }
      />
    );
  };

  renderMoveFlowModal = () => {
    const { workspaceData, currentUser, teamData, bulkMoveResource } = this.props;
    const { moveFlowModalOpen, editingFlow } = this.state;

    if (!editingFlow || !workspaceData.data || !teamData.data || !currentUser.root_folder_id) {
      return;
    }

    const movingFlowInfo = !workspaceData.dataSourcePageInfo!.team_resource_ids.has(editingFlow.id)
      ? {
          destinationName: `${teamData.data.team_name} Team Flows`,
          destinationFolderId: teamData.data.team_folder.id,
          currFolderId: currentUser.root_folder_id,
          movingToTeam: true,
        }
      : {
          destinationName: 'Personal Flows',
          destinationFolderId: currentUser.root_folder_id,
          currFolderId: teamData.data.team_folder.id,
          movingToTeam: false,
        };

    return (
      <ShareFlowConfirmationModal
        isOpen={moveFlowModalOpen}
        onClose={() => this.setState({ moveFlowModalOpen: false })}
        destinationName={movingFlowInfo.destinationName}
        destinationFolderId={movingFlowInfo.destinationFolderId}
        currFolderId={movingFlowInfo.currFolderId}
        bulkMoveResource={bulkMoveResource}
        // @ts-ignore
        flow={editingFlow}
        datasetNamesById={this.getDatasetNamesById()}
        movingToTeam={movingFlowInfo.movingToTeam}
        userResourceIds={workspaceData.dataSourcePageInfo!.user_resource_ids}
      />
    );
  };

  renderMoveDatasetModal = () => {
    const { workspaceData, teamData, currentUser } = this.props;
    const { moveDatasetModalOpen, selectedDataset } = this.state;

    if (!selectedDataset || !workspaceData.data || !teamData.data || !currentUser.root_folder_id) {
      return;
    }

    const movingDatasetInfo = !workspaceData.dataSourcePageInfo!.team_resource_ids.has(
      selectedDataset.id,
    )
      ? {
          destinationName: `${teamData.data.team_name} Team Datasets`,
          destinationFolderId: teamData.data.team_folder.id,
          currFolderId: currentUser.root_folder_id,
        }
      : {
          destinationName: 'Personal Datasets',
          destinationFolderId: currentUser.root_folder_id,
          currFolderId: teamData.data.team_folder.id,
        };

    return (
      <ConfirmationModal
        modalOpen={moveDatasetModalOpen}
        closeModal={() => this.setState({ moveDatasetModalOpen: false })}
        modalTitle="Share Dataset Confirmation"
        modalText={`Are you sure you want to move this dataset to ${movingDatasetInfo.destinationName}.`}
        submitBtnText="Move"
        danger={false}
        onSubmit={() =>
          this.moveResource(
            selectedDataset.id,
            movingDatasetInfo.currFolderId,
            movingDatasetInfo.destinationFolderId,
            'dataset',
          )
        }
      />
    );
  };

  renderDeleteDatasetModal = () => {
    const { deleteDatasetModalOpen, selectedDataset } = this.state;

    if (!deleteDatasetModalOpen || !selectedDataset) {
      return;
    }

    return (
      <DeleteDatasetConfirmationModal
        dataset={selectedDataset}
        isOpen={deleteDatasetModalOpen}
        onClose={() => this.setState({ deleteDatasetModalOpen: false })}
        deleteDataset={this.deleteDataset}
      />
    );
  };

  renderDeleteFlowModal = () => {
    const { deleteFlowModalOpen, editingFlow } = this.state;

    if (!deleteFlowModalOpen || !editingFlow) {
      return;
    }

    return (
      <DeleteFlowConfirmationModal
        flowId={editingFlow.id}
        isOpen={deleteFlowModalOpen}
        onClose={() => this.setState({ deleteFlowModalOpen: false })}
        onDelete={() => {}}
      />
    );
  };
}

const workspaceDataLoadingSelector = createLoadingSelector([ACTION.GET_EXPLORATIONS]);
const datasourceListLoadingSelector = createLoadingSelector([ACTION.FETCH_DATA_SOURCE_LIST]);
const mapStateToProps = (state: ReduxState) => ({
  workspaceDataLoading: workspaceDataLoadingSelector(state),
  datasourceListLoading: datasourceListLoadingSelector(state),
  createFlowLoading: createLoadingSelector([ACTION.CREATE_FLOW_FROM_DATA_SET], false)(state),
  dataSourceList: state.dataSourceList,
  workspaceData: state.workspaceData,
  currentUser: state.currentUser,
  flowData: state.flowData,
  teamData: state.teamData,
  userCreatedFoldersIsCollapsed: state.workspaceData.userCreatedFoldersIsCollapsed,
  userFlowsExpanded: state.workspaceData.userFlowsExpanded,
  teamFlowsExpanded: state.workspaceData.teamFlowsExpanded,
});

const mapDispatchToProps = {
  renameDataset,
  deleteDataset,
  renameFlow,
  createFlowFromDataset,
  moveResource,
  bulkMoveResource,
  setUserCreatedFoldersIsCollapsed,
  setUserFlowsExpanded,
  setTeamFlowsExpanded,
};

export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DataSourcePage)),
);

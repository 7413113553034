/** @format */

import { defineAction } from 'actions/actionUtils';
import { PreviewData } from 'actions/types';

export const deleteDataset = defineAction('DELETE_DATASET', 'fs/resource', '', 'DELETE');

export const deleteFlow = defineAction('DELETE_FLOW', 'fs/resource', '', 'DELETE');

export const renameDataset = defineAction('RENAME_DATASET', 'fs/resource', 'rename', 'POST');

export const renameFlow = defineAction('RENAME_FLOW', 'fs/resource', 'rename', 'POST');

export const createNewDatasetSuccess = (newResourceData: any, workspaceName: string) => ({
  type: 'CREATE_NEW_DATASET_SUCCESS',
  payload: {
    data: newResourceData,
    workspace: workspaceName,
  },
});

export const datasetUploadComplete = (datasetId: number, previewData: PreviewData) => ({
  type: 'DATASET_UPLOAD_COMPLETE',
  payload: {
    datasetId,
    previewData,
  },
});

export const saveAsDataset = defineAction(
  'SAVE_AS_DATASET',
  'operations',
  'save_as_dataset',
  'POST',
);

export const moveResource = defineAction('MOVE_RESOURCE', 'fs/resource', 'move', 'POST');

export const bulkMoveResource = defineAction(
  'BULK_MOVE_RESOURCE',
  'filesystem',
  'bulk_move_resource',
  'POST',
);

/** @format */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ReduxState } from 'reducers/rootReducer';

import SetupFlowPage from 'components/pages/setupFlowPage';

import { verifyEmail } from 'auth/userAuth';
import { verifyEmail as verifyEmailAction } from 'actions/authAction';
import { pageView } from 'analytics/exploAnalytics';

import { ROUTES } from 'constants/routes';

type MatchParams = {
  verification_id: string;
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps<MatchParams>;

const VerifyEmailPageV2 = (props: Props) => {
  return (
    <SetupFlowPage
      showLogoutButton
      title="Verifying now..."
      pageBody={<VerifyEmailPageBody {...props} />}
    />
  );
};

const VerifyEmailPageBody = (props: Props) => {
  const { currentUser, history, verifyEmailAction } = props;
  const { verification_id } = props.match.params;

  useEffect(() => {
    pageView('Verify Email');
    if (currentUser.email_verified) {
      history.push(ROUTES.JOIN_TEAM);
    }

    verifyEmail(
      verification_id,
      (data) => {
        verifyEmailAction();
        history.push(ROUTES.JOIN_TEAM);
      },
      (data) => {},
    );
  }, [currentUser, history, verifyEmailAction, verification_id]);

  return <div></div>;
};

const mapStateToProps = (state: ReduxState) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  verifyEmailAction,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VerifyEmailPageV2));

/** @format */

import React from 'react';
import _ from 'underscore';
import cx from 'classnames';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import {
  Spinner,
  Intent,
  InputGroup,
  NonIdealState,
  Tag,
  Tab,
  Tabs,
  Tooltip,
} from '@blueprintjs/core';

import BaseDataTable from 'components/dataTable/baseDataTable';
import LoadingDataTable from 'components/dataTable/loadingDataTable';
import DatasetRow from 'components/fileSystem/datasetRow';
import TablePageSelector from 'components/fileSystem/tablePageSelector';
import { Dataset, DataSource } from 'actions/types';
import { DatasetTabMap } from 'constants/types';
import { DataSourcesListReducerState } from 'reducers/dataSourcesListReducer';

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    height: 'calc(100% - 44px)',
    backgroundColor: theme.palette.grey.modalBG,
  },
  sideBarContainer: {
    width: '40%',
    backgroundColor: theme.palette.white,
    borderRight: `1px solid ${theme.palette.grey.border}`,
    zIndex: 1,
  },
  datasetView: {
    width: '60%',
    height: '100%',
  },
  datasetActions: {
    height: 45,
    width: '100%',
    background: theme.palette.white,
    borderTop: `1px solid ${theme.palette.grey.border}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(3)}px`,
  },
  searchContainer: {
    padding: theme.spacing(2),
  },
  searchActionsContainer: {},
  searchActions: {
    marginBottom: theme.spacing(2),
  },
  datasourceList: {
    overflow: 'auto',
    paddingLeft: '8px !important',
    borderLeft: `1px solid ${theme.palette.grey.border}`,
    width: '100%',
  },
  datasetList: {
    overflow: 'auto',
    paddingLeft: '8px !important',
    borderLeft: `1px solid ${theme.palette.grey.border}`,
    width: '100%',
    height: '116% !important',
  },
  datasetListSingleColumn: {
    height: 'calc(100% - 12px) !important',
  },
  nonIdealStateDatasetListWithSearchActions: {
    height: '99%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.grey.light,
  },
  nonIdealStateDatasetList: {
    height: 'calc(100% - 46px)',
    width: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.grey.light,
  },
  datasetListTabsWithSearchActions: {
    height: 'calc(100% - 84px)',
    width: '100%',
    padding: theme.spacing(2),
    paddingBottom: 0,
    overflow: 'hidden' as 'hidden',
    overflowY: 'auto' as 'auto',
  },
  datasetListTabs: {
    height: 'calc(100% - 46px)',
    width: '100%',
    padding: theme.spacing(2),
    paddingBottom: 0,

    '& .bp3-tab-list': {
      width: '45%',
      overflow: 'scroll',
    },
  },
  datasetMenuItem: {
    padding: theme.spacing(4),
    '&:hover': {
      backgroundColor: 'rgba(167,182,194,.3)',
      cursor: 'pointer',
    },
    '&:active': {
      backgroundColor: 'rgba(115,134,148,.3)',
    },
    '&.selected': {
      color: theme.palette.white,
      // @ts-ignore
      backgroundColor: theme.palette.primary.main,
    },
  },
  datasetListSectionHeader: {
    fontWeight: 700,
    margin: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  datasetMenuItemSectionHeader: {
    padding: theme.spacing(3),
    borderTop: `1px solid ${theme.palette.grey.border}`,
    borderBottom: `1px solid ${theme.palette.grey.border}`,
    backgroundColor: theme.palette.grey.modalBG,
    fontWeight: 600,
  },
  datasetPreview: {
    width: '100%',
    height: 'calc(100% - 45px)',
  },
  datasetPreviewTable: {
    height: '100%',
    borderRadius: 0,
  },
  datasetListLoadingSpinner: {},
  noDatasetsState: {
    justifyContent: 'flex-start',
    marginTop: '10vh',
    padding: '0 28px',
    height: 'calc(100% - 10vh)',
  },
  numRows: {},
  datasetRowTooltipTarget: {
    width: '100%',
    display: 'block !important',
  },
  tabNames: {
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    'white-space': 'nowrap',
  },
  pageNumberRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageNumberEditableText: {
    marginLeft: 5,
    marginRight: 5,

    '& .bp3-editable-text-content': {
      textAlign: 'center' as 'center',
    },
  },
  rowCountSpinner: {
    marginRight: theme.spacing(1),
  },
  flexContainer: {
    display: 'flex',
  },
  searchBarAndSort: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

type PassedProps = {
  className?: string;
  selectedDataset: Dataset | null;
  onDatasetSelected: (dataset: any | null) => void;
  searchQuery: string;
  onQueryChange: (query: string) => void;
  datasetsLoading: boolean;
  datasetTabsList: DatasetTabMap[];
  datasetActions?: React.ReactElement;
  searchActions?: React.ReactElement;
  isExploSource: boolean;
  openRenameDatasetModal?: (selectedDataset: Dataset) => void;
  openDeleteDatasetModal?: (selectedDataset: Dataset) => void;
  openMoveDatasetModal?: (selectedDataset: Dataset) => void;
  editingDisabled?: boolean;
  teamResourceIds?: Set<number>;
  maxPageNumber: number;
  getEphemeralDatasetPreview: (args: any) => void;
  datasetOrderDropdown: React.ReactElement;
  currentUserId?: number;
  selectFromAllDatasources?: boolean;
  dataSourceList?: DataSourcesListReducerState;
  onNewDataSourceSelect?: (dataSourceId: number) => void;
  isDatasourceLoading?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  currentPageNumber: number;
  pageNumberInputVal: number;
  pageNumberInputIsInvalid: boolean;
};

class DatasetSelectorPanel extends React.Component<Props, State> {
  state: State = {
    currentPageNumber: 1,
    pageNumberInputVal: 1,
    pageNumberInputIsInvalid: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.selectedDataset !== this.props.selectedDataset) {
      this.setState({
        currentPageNumber: 1,
        pageNumberInputVal: 1,
        pageNumberInputIsInvalid: false,
      });
    }
  }

  render() {
    const { classes, className } = this.props;

    return (
      <div className={cx(classes.root, className)}>
        {this.renderSideBar()}
        <div className={classes.datasetView}>
          {this.renderDatasetPreview()}
          {this.renderDatasetActions()}
        </div>
      </div>
    );
  }

  tabNameRefs: { [key: number]: HTMLDivElement } = {};

  renderSideBar = () => {
    const {
      classes,
      searchQuery,
      onQueryChange,
      datasetOrderDropdown,
      selectFromAllDatasources,
    } = this.props;

    return (
      <div className={classes.sideBarContainer}>
        <div className={classes.searchContainer}>
          {this.renderSearchActions()}
          <div className={classes.searchBarAndSort}>
            <InputGroup
              type="search"
              placeholder="Search"
              leftIcon="search"
              value={searchQuery}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onQueryChange(e.target.value)}
            />
            {datasetOrderDropdown}
          </div>
        </div>
        {selectFromAllDatasources ? this.renderDataSourceList() : this.renderDatasetList()}
      </div>
    );
  };

  renderSearchActions = () => {
    const { classes, searchActions } = this.props;

    if (!searchActions) {
      return;
    }

    return (
      <div className={classes.searchActionsContainer}>
        <div className={classes.searchActions}>{searchActions}</div>
      </div>
    );
  };

  renderDataSourceList = () => {
    const { dataSourceList, classes, searchActions, onNewDataSourceSelect } = this.props;

    const nonIdealContainerClasses = cx({
      [classes.nonIdealStateDatasetList]: !searchActions,
      [classes.nonIdealStateDatasetListWithSearchActions]: !!searchActions,
    });

    const entireTabContainerClasses = cx({
      [classes.datasetListTabs]: !searchActions,
      [classes.datasetListTabsWithSearchActions]: !!searchActions,
    });

    // should change this to the loading selector..
    if (!dataSourceList) {
      return (
        <div className={nonIdealContainerClasses}>
          <Spinner className={classes.datasetListLoadingSpinner} intent={Intent.PRIMARY} />
        </div>
      );
    }

    return (
      <Tabs
        onChange={onNewDataSourceSelect}
        defaultSelectedTabId="all"
        className={entireTabContainerClasses}
        vertical={true}
        renderActiveTabPanelOnly>
        {dataSourceList.map((datasource: DataSource, key: number) => (
          <Tab
            id={datasource.id}
            key={`${datasource.id}`}
            title={this.createTabNameTooltip(datasource.dataset_count, datasource.name, key)}
            panelClassName={classes.datasourceList}
            panel={this.renderDatasetList()}
          />
        ))}
      </Tabs>
    );
  };

  renderDatasetList = () => {
    const {
      classes,
      datasetTabsList,
      datasetsLoading,
      searchActions,
      isDatasourceLoading,
      selectFromAllDatasources,
      searchQuery,
    } = this.props;

    const nonIdealContainerClasses = cx({
      [classes.nonIdealStateDatasetList]: !searchActions,
      [classes.nonIdealStateDatasetListWithSearchActions]: !!searchActions,
    });

    const entireTabContainerClasses = cx({
      [classes.datasetListTabs]: !searchActions,
      [classes.datasetListTabsWithSearchActions]: !!searchActions,
    });

    if (datasetsLoading || isDatasourceLoading) {
      return (
        <div className={nonIdealContainerClasses}>
          <Spinner className={classes.datasetListLoadingSpinner} intent={Intent.PRIMARY} />
        </div>
      );
    }

    if (datasetTabsList.length === 0) {
      if (!!searchQuery) {
        return (
          <div className={nonIdealContainerClasses}>
            <NonIdealState
              className={classes.noDatasetsState}
              icon="zoom-out"
              title="No datasets found"
            />
          </div>
        );
      }
      return (
        <div className={nonIdealContainerClasses}>
          <NonIdealState
            className={classes.noDatasetsState}
            icon="error"
            title="No datasets available in this data source"
          />
        </div>
      );
    }

    const totalDatasetCount = _.reduce(
      datasetTabsList,
      (total, datasetTab) => {
        return datasetTab.data.length + total;
      },
      0,
    );

    return (
      <Tabs
        renderActiveTabPanelOnly
        defaultSelectedTabId="all"
        className={entireTabContainerClasses}
        vertical={true}>
        <Tab
          id="all"
          key="all"
          title={`(${totalDatasetCount}) All`}
          panelClassName={cx(classes.datasetList, {
            [classes.datasetListSingleColumn]: !selectFromAllDatasources,
          })}
          panel={this.renderAllDatasetsTab()}
        />
        {datasetTabsList.map((datasetMap: DatasetTabMap, key: number) => (
          <Tab
            id={`${datasetMap.name}`}
            key={`${datasetMap.name}`}
            title={this.createTabNameTooltip(datasetMap.data.length, datasetMap.name, key)}
            panelClassName={cx(classes.datasetList, {
              [classes.datasetListSingleColumn]: !selectFromAllDatasources,
            })}
            panel={<>{datasetMap.data.map(this.renderDatasetMenuItem)}</>}
          />
        ))}
      </Tabs>
    );
  };

  createTabNameTooltip = (datasetCount: number, tabName: string, key: number) => {
    const { classes } = this.props;

    return (
      <Tooltip
        content={`(${datasetCount}) ${tabName}`}
        targetClassName={classes.datasetRowTooltipTarget}
        disabled={!this.tabNameTooltipCanBeOpen(key)}>
        <div
          className={classes.tabNames}
          ref={(tabName) => {
            if (tabName) {
              this.tabNameRefs[key] = tabName;
            }
          }}>
          {`(${datasetCount}) ${tabName}`}
        </div>
      </Tooltip>
    );
  };

  tabNameTooltipCanBeOpen = (key: number) => {
    const tabRef = this.tabNameRefs[key];
    if (tabRef) {
      return tabRef.scrollWidth > tabRef.clientWidth;
    }
  };

  renderAllDatasetsTab = () => {
    const { classes, datasetTabsList } = this.props;

    return (
      <>
        {datasetTabsList.map((datasetMap: DatasetTabMap) => {
          return (
            <div key={datasetMap.name}>
              <div className={classes.datasetListSectionHeader}>{datasetMap.name}</div>
              {datasetMap.data.map(this.renderDatasetMenuItem)}
            </div>
          );
        })}
      </>
    );
  };

  renderDatasetMenuItem = (dataset: Dataset) => {
    const {
      selectedDataset,
      onDatasetSelected,
      isExploSource,
      openRenameDatasetModal,
      openDeleteDatasetModal,
      openMoveDatasetModal,
      editingDisabled,
      teamResourceIds,
      currentUserId,
    } = this.props;
    return (
      <DatasetRow
        currentUserId={currentUserId}
        key={`select-dataset-${dataset.id}`}
        dataset={dataset}
        datasetSelected={selectedDataset ? selectedDataset.id === dataset.id : undefined}
        onDatasetSelected={onDatasetSelected}
        isExploSource={isExploSource}
        openRenameDatasetModal={openRenameDatasetModal}
        openDeleteDatasetModal={openDeleteDatasetModal}
        openMoveDatasetModal={openMoveDatasetModal}
        editingDisabled={editingDisabled}
        teamResourceIds={teamResourceIds}
        datasetLoading={dataset.uploading || dataset.actionLoading}
      />
    );
  };

  renderDatasetToken = (dataset: any) => {
    const { selectedDataset } = this.props;
    const intent = selectedDataset && selectedDataset.id === dataset.id ? 'none' : Intent.PRIMARY;

    if (dataset.uploading) {
      return <Spinner intent={intent} size={20} />;
    } else if (dataset.new) {
      return <Tag intent={intent}>NEW</Tag>;
    }
  };

  renderDatasetPreview = () => {
    const { classes, selectedDataset, datasetTabsList } = this.props;

    if (datasetTabsList.length === 0) {
      return <div className={classes.datasetPreview}></div>;
    }
    if (selectedDataset === null) {
      return (
        <div className={classes.datasetPreview}>
          <NonIdealState icon="th" title="Select a dataset to preview" />
        </div>
      );
    }

    // @ts-ignore
    const dataTableLoading = selectedDataset.uploading || selectedDataset.loading;
    const DataTable = dataTableLoading ? LoadingDataTable : BaseDataTable;

    if (!dataTableLoading && selectedDataset.error) {
      return (
        <div className={classes.datasetPreview}>
          <NonIdealState
            icon="error"
            title="Dataset failed to preview"
            description={
              selectedDataset.results_job_error
                ? selectedDataset.results_job_error.message
                : 'Unknown error.  Please contact Explo support.'
            }
          />
        </div>
      );
    }

    return (
      <div className={classes.datasetPreview}>
        <DataTable
          className={classes.datasetPreviewTable}
          selectedColumns={new Set([])}
          headerList={selectedDataset.cached_schema!}
          rows={selectedDataset.cached_preview!}
          loading={dataTableLoading}
          maxRows={50}
          unrestrictedHeight
        />
      </div>
    );
  };

  renderDatasetActions = () => {
    const { classes, datasetActions, selectedDataset } = this.props;

    return (
      <div className={classes.datasetActions}>
        {selectedDataset ? (
          <>
            {this.renderRowCount()}
            {this.renderTablePageSelector()}
          </>
        ) : (
          <div></div>
        )}
        <div>{datasetActions}</div>
      </div>
    );
  };

  renderTablePageSelector = () => {
    const { selectedDataset, maxPageNumber } = this.props;
    const { pageNumberInputIsInvalid, pageNumberInputVal, currentPageNumber } = this.state;

    return (
      <TablePageSelector
        selectedDataset={selectedDataset}
        currentPageNumber={currentPageNumber}
        changeSelectedDatasetViewPage={this.changeSelectedDatasetViewPage}
        pageNumberInputVal={pageNumberInputVal}
        maxPageNumber={maxPageNumber}
        pageNumberInputIsInvalid={pageNumberInputIsInvalid}
        changePageNumberInputVal={this.changePageNumberInputVal}
        changePageNumberisInvalid={this.changePageNumberisInvalid}
      />
    );
  };

  renderRowCount = () => {
    const { classes, selectedDataset } = this.props;

    return (
      <div className={classes.numRows}>
        {selectedDataset && selectedDataset.total_row_count ? (
          `${selectedDataset.total_row_count.toLocaleString()} total rows`
        ) : (
          <div className={classes.flexContainer}>
            <Spinner className={classes.rowCountSpinner} size={17} />
            {'total rows'}
          </div>
        )}
      </div>
    );
  };

  changePageNumberInputVal = (pageNumber: number) => {
    this.setState({ pageNumberInputVal: pageNumber });
  };

  changePageNumberisInvalid = (val: boolean) => {
    this.setState({ pageNumberInputIsInvalid: val });
  };

  changeSelectedDatasetViewPage = (newPage: number) => {
    const { selectedDataset, getEphemeralDatasetPreview } = this.props;
    if (selectedDataset) {
      this.setState({
        currentPageNumber: newPage,
        pageNumberInputVal: newPage,
        pageNumberInputIsInvalid: false,
      });
      getEphemeralDatasetPreview({
        id: selectedDataset.id,
        postData: { offset: (newPage - 1) * 50 },
      });
    }
  };
}

export default withStyles(styles)(DatasetSelectorPanel);

/** @format */

import React from 'react';
import moment from 'moment';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { SetupFlowStepKey } from 'constants/setupPageConstants';

import { ROUTES } from 'constants/routes.tsx';
import PageWithNavBar from 'components/navBar/pageWithNavBar';

const PrivateRoute = ({
  component: Component,
  withNavigation,
  noMatch,
  currentUser,
  permissionFns,
  ...rest
}) => {
  const routeComponent = (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser.id === null || currentUser.id === undefined) {
          if (props.location.pathname === ROUTES.LOGIN) {
            return <Component {...props} />;
          }
          return noMatch ? <Component {...props} /> : <Redirect to={{ pathname: ROUTES.LOGIN }} />;
        } else if (props.location.pathname === ROUTES.LOGIN) {
          return <Redirect to={{ pathname: ROUTES.HOME_APP_PAGE }} />;
        } else if (
          !currentUser.email_verified &&
          props.location.pathname !== ROUTES.CHECK_YOUR_EMAIL
        ) {
          return <Redirect to={{ pathname: ROUTES.CHECK_YOUR_EMAIL }} />;
        } else if (
          !currentUser.email_verified &&
          props.location.pathname === ROUTES.CHECK_YOUR_EMAIL
        ) {
          return <Component {...props} />;
        } else if (!currentUser.has_team && props.location.pathname !== ROUTES.JOIN_TEAM) {
          return <Redirect to={{ pathname: ROUTES.JOIN_TEAM }} />;
        } else if (!currentUser.has_team && props.location.pathname === ROUTES.JOIN_TEAM) {
          return <Component {...props} />;
        } else if (
          currentUser.team &&
          currentUser.team.trial_expiration_date &&
          moment().isAfter(moment(currentUser.team.trial_expiration_date))
        ) {
          return props.location.pathname === ROUTES.TRIAL_EXPIRED ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: ROUTES.TRIAL_EXPIRED }} />
          );
        } else if (
          currentUser.setup_info.setup_flow_step_key !== SetupFlowStepKey.POST_SETUP_FLOW_PAGE &&
          props.location.pathname !== ROUTES.SETUP
        ) {
          return <Redirect to={{ pathname: ROUTES.SETUP }} />;
        } else if (
          currentUser.setup_info.setup_flow_step_key === SetupFlowStepKey.POST_SETUP_FLOW_PAGE &&
          props.location.pathname === ROUTES.SETUP
        ) {
          return <Redirect to={{ pathname: ROUTES.HOME_APP_PAGE }} />;
        } else {
          if (permissionFns) {
            for (let i = 0; i < permissionFns.length; i += 1) {
              const userPassesPermission = permissionFns[i](currentUser);
              if (!userPassesPermission) {
                return <Redirect to={{ pathname: ROUTES.HOME }} />;
              }
            }
          }
          return noMatch ? (
            <Redirect to={{ pathname: ROUTES.HOME_APP_PAGE }} />
          ) : (
            <Component {...props} />
          );
        }
      }}
    />
  );
  // return withNavigation ? <PageWithLeftNav>{routeComponent}</PageWithLeftNav> : routeComponent;
  return withNavigation ? <PageWithNavBar>{routeComponent}</PageWithNavBar> : routeComponent;
  // return routeComponent;
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

export default connect(mapStateToProps)(PrivateRoute);

/** @format */

import React, { Component } from 'react';
import _ from 'underscore';
import moment from 'moment';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import DatePicker from 'react-datepicker';

import OperationDropdownInput from 'components/flowBuilder/operationDropdownInput';

import { FILTER_OPERATORS, DATETIME } from 'constants/flowConstants';

const VIEW_DATE_FORMAT = 'MM/DD/YYYY'; // we might want to support granular hours/minutes in the future
const DATE_FORMAT = 'YYYY-MM-DD';

const styles = (theme: Theme) => ({
  name: {
    textTransform: 'uppercase' as 'uppercase',
    fontWeight: 'bold' as 'bold',
    fontSize: 12,
    marginBottom: theme.spacing(1),
  },
  raiseName: {},
  datePicker: {
    padding: '6px 10px 5px',
    borderWidth: 0,
    borderRadius: 3,
    fontSize: 14,
    height: 30,
    boxShadow: `0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2)`,
    width: 'inherit',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  operatorSelector: {
    marginRight: theme.spacing(2),
  },
});

type PassedProps = {
  name: string;
  initialValue?: { startDate: string; endDate?: string };
  onNewValueSubmitted: (newValue: any) => void;
  onFilterOperatorSelect: (newOperator: any) => void;
  operator: any;
};

type Props = WithStyles<typeof styles> & PassedProps;

type State = {
  value?: { startDate: string; endDate?: string } | null;
  inputFocused: boolean;
};

const convertDashDateToSlashDate = (date: string) => {
  return date.replace(/-/g, '/');
};

export class InputDateWithName extends Component<Props, State> {
  inputRef: any;

  state: State = {
    value: this.props.initialValue,
    inputFocused: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState({ value: this.props.initialValue });
    }
  }

  render = () => {
    const { value } = this.state;
    const { name, classes, onFilterOperatorSelect, onNewValueSubmitted, operator } = this.props;

    const relevantOperators = _.filter(FILTER_OPERATORS, (op) =>
      op.supported_column_types.has(DATETIME),
    );

    return (
      <div>
        <div className={classes.name}>{name}</div>
        <div className={classes.inputContainer}>
          <OperationDropdownInput
            className={classes.operatorSelector}
            selectedItem={operator}
            onChange={onFilterOperatorSelect}
            options={relevantOperators.map((op) => ({ name: op.selectionValue, id: op.id }))}
            noSelectionText=""
            hideBtnCaret
            filterable={false}
          />
          <DatePicker
            className={classes.datePicker}
            selected={value ? new Date(convertDashDateToSlashDate(value.startDate)) : null}
            onChange={(date) => {
              this.setState({
                value: date ? { startDate: moment(date).format(DATE_FORMAT) } : null,
              });
            }}
            onCalendarClose={() => {
              onNewValueSubmitted(value);
            }}
            placeholderText={VIEW_DATE_FORMAT}
            showMonthDropdown
            showYearDropdown
          />
        </div>
      </div>
    );
  };
}

export default withStyles(styles)(InputDateWithName);

/** @format */

import Cookies from 'js-cookie';
import * as Redux from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import axios, { Method, AxiosError, AxiosResponse } from 'axios';
import _ from 'underscore';

interface ActionFnArgs {
  id?: string | number | null;
  postData?: any;
}

export type ActionFn = (
  args?: ActionFnArgs,
  onSuccess?: (data: any) => void,
  onError?: (errorMsg: any) => void,
) => void;

export const defineAction = (
  actionName: string,
  urlBeforeId: string,
  urlAfterId: string,
  requestType: Method,
) => {
  const actionRequest = (args: any) => ({
    type: `${actionName}_REQUEST`,
    payload: {
      ...args,
    },
  });

  const actionSuccess = (args: any, response?: object) => ({
    type: `${actionName}_SUCCESS`,
    payload: {
      ...args,
      ...response,
    },
  });

  const actionError = (args: any, response?: object) => ({
    type: `${actionName}_ERROR`,
    payload: {
      ...args,
      ...response,
    },
  });

  const actionFn: ActionFn = (
    args: ActionFnArgs = {},
    onSuccess?: (data: any) => void,
    onError?: (errorMsg: any) => void,
  ) => {
    let url = args.id ? `${urlBeforeId}/${args.id}/` : `${urlBeforeId}/`;
    if (urlAfterId && urlAfterId !== '') {
      url += `${urlAfterId}/`;
    }
    return (dispatch: Redux.Dispatch<any>) => {
      dispatch(actionRequest(args));
      const authToken = Cookies.get('spheres_auth_token');

      return axios({
        url: process.env.REACT_APP_API_URL + url,
        method: requestType,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authToken ? 'Token ' + authToken : '',
        },
        data: args.postData ? args.postData : undefined,
      })
        .then((response: AxiosResponse) => {
          const { data } = response;
          if (!data.success) {
            dispatch(actionError(args, data));
            onError && onError(data);
          } else {
            dispatch(actionSuccess(args, data));
            onSuccess && onSuccess(data);
          }
        })
        .catch((error: AxiosError) => {
          console.log(error);
          dispatch(actionError(args));
          onError && onError(error.response && error.response.data);
        });
    };
  };

  return actionFn;
};

type OtherDispatchersType = (dispatch: Redux.Dispatch<any>) => { [action: string]: any };

export const defineDispatchProps = (
  actionMap: { [actionName: string]: ActionFn },
  otherDispatchers: OtherDispatchersType | null = null,
) => {
  const dispatchFn = (dispatch: ThunkDispatch<{}, {}, any>) => {
    const actionProps: { [actionName: string]: ActionFn } = {};
    _.each(Object.keys(actionMap), (actionName: string) => {
      actionProps[actionName] = (
        args?: any,
        onSuccess?: (data: any) => void,
        onError?: (errorMsg: any) => void,
      ) => dispatch(actionMap[actionName](args, onSuccess, onError));
    });
    const otherDispatcherFns = otherDispatchers ? otherDispatchers(dispatch) : {};
    return _.extend(actionProps, otherDispatcherFns);
  };

  return dispatchFn;
};

/** @format */

import React from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AppToaster } from 'toaster';

import {
  Spinner,
  Intent,
  Tabs,
  Tab,
  H2,
  H3,
  H5,
  Tag,
  NonIdealState,
  Divider,
} from '@blueprintjs/core';
import { withStyles } from '@material-ui/styles';

import { fetchUserTeam } from 'actions/teamActions';
import { pageView } from 'analytics/exploAnalytics';

const styles = (theme) => ({
  body: {
    backgroundColor: theme.palette.white,
    boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.25)',
    borderRadius: 4,
    margin: `${theme.spacing(6)}px auto`,
    width: 1100,
    padding: theme.spacing(8),
    height: 'calc(100vh - 50px - 48px)',
    minHeight: 500,
    maxHeight: '87vh',
  },
  loadingBody: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  pageTitle: {
    marginBottom: theme.spacing(5),
  },
  section: {
    marginBottom: theme.spacing(8),
  },
  sectionTitle: {
    marginBottom: theme.spacing(5),
  },
  teamMemberContainer: {
    padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`,
  },
  tabsContainer: {
    height: 'calc(100% - 50px)',
  },
  tabBodyContainer: {
    height: '100%',
    overflow: 'auto',
  },
});

const SETTINGS_TABS = {
  TEAM_INFO: {
    id: 'TEAM_INFO',
    name: 'Team Information',
  },
  TEAM_MEMBERS: {
    id: 'TEAM_MEMBERS',
    name: 'Team Members',
  },
};

class TeamPage extends React.Component {
  constructor(props) {
    super(props);

    props.fetchUserTeam();

    this.state = {
      selectedTabId: SETTINGS_TABS.TEAM_MEMBERS.ID,
    };
  }

  componentDidMount() {
    pageView('Team');
  }

  render = () => {
    const { classes, teamData } = this.props;

    if (teamData.loading) {
      return (
        <div className={classes.loadingBody}>
          <Spinner intent={Intent.PRIMARY} size={Spinner.SIZE_STANDARD} />
        </div>
      );
    } else {
      return (
        <div className={classes.body}>
          <H2 className={classes.pageTitle}>{teamData.data.team_name}</H2>
          <Tabs
            className={classes.tabsContainer}
            id="team-settings-panel"
            onChange={(id) => this.setState({ selectedTabId: id })}
            selectedTabId={this.state.selectedTabId}
            vertical={true}
            large={true}>
            <Tab
              id={SETTINGS_TABS.TEAM_MEMBERS.id}
              title={SETTINGS_TABS.TEAM_MEMBERS.name}
              panel={this.renderTeamMembersPanel()}
            />
            <Tab
              id={SETTINGS_TABS.TEAM_INFO.id}
              title={SETTINGS_TABS.TEAM_INFO.name}
              panel={this.renderTeamInfoPanel()}
            />
          </Tabs>
        </div>
      );
    }
  };

  renderTeamInfoPanel = () => {
    const { classes } = this.props;

    return (
      <div className={classes.tabBodyContainer}>
        <H3>Edit Team Information</H3>
      </div>
    );
  };

  renderTeamMembersPanel = () => {
    const { classes } = this.props;
    return (
      <div className={classes.tabBodyContainer}>
        {this.renderInviteMembersSection()}
        {this.renderTeamMembersSection()}
      </div>
    );
  };

  renderInviteMembersSection = () => {
    const { classes, teamData } = this.props;
    return (
      <div className={classes.section}>
        <H3 className={classes.sectionTitle}>Invite more people to your team</H3>
        <H5>
          Invite Code:
          <CopyToClipboard
            text={teamData.data.invite_code}
            onCopy={() =>
              AppToaster.show({
                icon: 'clipboard',
                intent: Intent.PRIMARY,
                message: 'Invite code copied to clipboard',
                timeout: 5000,
              })
            }>
            <Tag interactive={true} large={true} minimal={true} intent={Intent.PRIMARY}>
              {teamData.data.invite_code}
            </Tag>
          </CopyToClipboard>
        </H5>
        <div>
          Have your team members make an account at{' '}
          <a href="/signup" target="_blank" rel="noopener noreferrer">
            this link
          </a>
          . After making an account, they can use the code above to join your team.
        </div>
      </div>
    );
  };

  renderTeamMembersSection = () => {
    const { classes } = this.props;

    return (
      <div className={classes.section}>
        <H3 className={classes.sectionTitle}>Current Team Members</H3>
        <Divider />
        {this.renderTeamMemberList()}
      </div>
    );
  };

  renderTeamMemberList = () => {
    const { teamData, currentUser } = this.props;
    const otherTeamMembers = _.filter(
      teamData.data.team_members,
      (user) => user.id !== currentUser.id,
    );

    if (otherTeamMembers.length === 0) {
      return (
        <NonIdealState
          icon="new-person"
          title="No team members."
          description="Invite the rest of your team to start collaborating and sharing data anaylsis!"
        />
      );
    }

    return teamData.data.team_members.map(this.renderTeamMember);
  };

  renderTeamMember = (user) => {
    const { classes } = this.props;

    return (
      <div key={`team-user-member-${user.id}`}>
        <div className={classes.teamMemberContainer}>
          {user.first_name} {user.last_name}
        </div>
        <Divider />
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  teamData: state.teamData,
  currentUser: state.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserTeam: () => dispatch(fetchUserTeam()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TeamPage));

/** @format */

import React from 'react';
import _ from 'underscore';
import cx from 'classnames';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';

import StepBlockTag from 'components/flowBuilder/stepBlockTag';

import { joinClauseComplete } from 'utils/flowUtils';

import { InstructionComponentProps } from 'components/flowGraph/operationInstructionDescription';
import { EnrichOperationInstructions } from 'constants/types';

const styles = (theme: Theme) =>
  createStyles({
    fillerText: {
      fontSize: 16,
      fontWeight: 400,
      margin: `0 ${theme.spacing(2)}px`,
    },
    startingFillterText: {
      marginLeft: 0,
    },
  });

type PassedProps = InstructionComponentProps<EnrichOperationInstructions>;

type Props = PassedProps & WithStyles<typeof styles>;

class JoinOperationInstructionDescription extends React.Component<Props> {
  render() {
    const { classes, operationId, instructions } = this.props;
    const completeClauses = instructions.columnsToJoinOn.filter(joinClauseComplete);

    if (!instructions.joinTable.datasetId) {
      return <></>;
    }
    const stepBuilderBlockInfo = [
      <div
        className={cx(classes.fillerText, classes.startingFillterText)}
        key={`join_with_${operationId}`}>
        with
      </div>,
    ];
    stepBuilderBlockInfo.push(
      <StepBlockTag
        key={`table_name_${operationId}`}
        interactive={true}
        toolTipContent={'View the dataset'}
        href={`/datasets/${instructions.joinTable.datasetId}`}
        content={instructions.joinTable.name}
      />,
    );
    if (completeClauses.length === 0) {
      return stepBuilderBlockInfo;
    }
    stepBuilderBlockInfo.push(
      <div className={classes.fillerText} key={`join_on_${operationId}`}>
        on
      </div>,
    );
    _.each(completeClauses, (clause, index) => {
      stepBuilderBlockInfo.push(
        <StepBlockTag
          key={`clause_${index}_${operationId}`}
          content={`${clause.sourceColumn} = ${clause.joinColumn}`}
        />,
      );

      if (index !== completeClauses.length - 1) {
        stepBuilderBlockInfo.push(
          <div className={classes.fillerText} key={`clause_${index}_${operationId}_and`}>
            and
          </div>,
        );
      }
    });
    return stepBuilderBlockInfo;
  }
}

export default withStyles(styles)(JoinOperationInstructionDescription);

/** @format */

import React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { H1, Intent, Button, Spinner } from '@blueprintjs/core';
import { createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      minHeight: '100vh',
      backgroundColor: theme.palette.primary.veryLight,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '50px 0',
    },
    header: {
      marginBottom: theme.spacing(7),
    },
    subHeader: {
      marginBottom: theme.spacing(7),
      color: theme.palette.grey.blueprintMutedGray,
    },
    buttonSelection: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    nextButton: { minWidth: 120 },
    altButton: { marginLeft: theme.spacing(5) },
    contentAboveHeader: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(10),
    },
  });

interface PassedProps {
  header: string;
  subHeader?: string;
  nextButtonText?: string;
  onNextButtonPress?: () => void;
  setupPageLoading: boolean;
  onSkipButtonPress: () => void;
  onBackButtonPress: () => void;
  isSkippingEnabled: boolean;
  isBackButtonEnabled: boolean;
  altButtonText?: string;
  onAltButtonPress: () => void;
  renderMiddleContent?: () => any;
  renderContentAboveHeader?: () => any;
}

type Props = PassedProps & WithStyles<typeof styles>;

class SetupFlowGenericDisplay extends React.PureComponent<Props> {
  render() {
    const { classes, header, subHeader, setupPageLoading, renderMiddleContent } = this.props;
    return (
      <div className={classes.root}>
        {setupPageLoading ? (
          <Spinner intent={Intent.PRIMARY} size={Spinner.SIZE_STANDARD} />
        ) : (
          <div className={classes.content}>
            {this.renderContentAboveHeader()}
            <H1 className={classes.header}>{header}</H1>
            {subHeader && <div className={classes.subHeader}>{subHeader}</div>}
            {renderMiddleContent && renderMiddleContent()}
            {this.renderButtonSelection()}
            {this.renderSkipButton()}
            {this.renderBackButton()}
          </div>
        )}
      </div>
    );
  }

  renderContentAboveHeader = () => {
    const { classes, renderContentAboveHeader } = this.props;
    if (!renderContentAboveHeader) {
      return;
    }
    return <div className={classes.contentAboveHeader}>{renderContentAboveHeader()}</div>;
  };

  renderButtonSelection = () => {
    const {
      classes,
      nextButtonText,
      onNextButtonPress,
      altButtonText,
      onAltButtonPress,
    } = this.props;
    if (!nextButtonText && !altButtonText) {
      return;
    }
    return (
      <div className={classes.buttonSelection}>
        {nextButtonText && (
          <Button
            className={classes.nextButton}
            intent={Intent.PRIMARY}
            onClick={onNextButtonPress}>
            {nextButtonText}
          </Button>
        )}
        {altButtonText && (
          <Button className={classes.altButton} intent={Intent.NONE} onClick={onAltButtonPress}>
            {altButtonText}
          </Button>
        )}
      </div>
    );
  };

  renderSkipButton = () => {
    const { isSkippingEnabled, onSkipButtonPress } = this.props;
    if (!isSkippingEnabled) {
      return;
    }
    return (
      <Button intent={Intent.NONE} onClick={onSkipButtonPress} minimal={true}>
        Skip for now
      </Button>
    );
  };

  renderBackButton = () => {
    const { isBackButtonEnabled, onBackButtonPress } = this.props;
    if (!isBackButtonEnabled) {
      return;
    }
    return (
      <Button intent={Intent.NONE} onClick={onBackButtonPress} minimal={true}>
        Go back
      </Button>
    );
  };
}

export default withStyles(styles)(SetupFlowGenericDisplay);

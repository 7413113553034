/** @format */

import React, { ReactNode } from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { AppToaster } from 'toaster';
import { Intent } from '@blueprintjs/core';

import StarParallaxBg from 'components/marketing/starParallaxBg';
import NavHeader from 'components/marketing/navHeader';
import SignupHero from 'components/marketing/signupHero';
import ProductFeatureSection from 'components/marketing/productFeatureSection';
import SignupFooter from 'components/marketing/signupFooter';
import Footer from 'components/marketing/footer';
import IntegrateAlertingSection from 'components/marketing/integrateAlertingSection';

import { HEADER_PAGES } from 'components/marketing/navHeader';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.black100,
    height: '100vh',
    padding: `0 ${theme.spacing(7)}px`,
    display: 'flex',
    justifyContent: 'center',
    fontFamily: "'Inter', sans-serif",
    perspective: '1px',
    overflowY: 'auto',
  },
  container: {
    width: '100%',
    maxWidth: 1040,
    zIndex: 2,
  },
  ecommBenefitsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: theme.spacing(17),
    gridRowGap: theme.spacing(17),
    marginTop: 150,

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
      marginTop: 100,
    },
  },
  integrationAlertingSection: {
    marginTop: 160,

    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
    },
  },
  signupBanner: {
    marginTop: 160,

    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
    },
  },
  signupFooter: {
    marginTop: 160,

    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
    },
  },
  footer: {
    marginTop: 120,
    marginBottom: 40,
  },
}));

type Props = {
  className?: string;
  children: ReactNode;
};

const AlertingPage = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <StarParallaxBg />

      <div className={classes.container}>
        <NavHeader selectedHeaderPage={HEADER_PAGES.USE_CASES} />
        <SignupHero
          caption="Limited Beta Release"
          title1="Never miss "
          title2="broken data pipelines"
          description="Closely monitor your data pipelines and external data sources. Get notified when schemas change, data becomes stale, anomalies are introduced, and more."
          onSignupSuccess={renderSuccessSignUpToast}
          onSignupFail={renderFailedSignUpToast}
        />
        <div className={classes.ecommBenefitsContainer}>
          <ProductFeatureSection
            // className={classes.prepareDataSection}
            titleBeginning="Alert"
            titleEnd=" on breaking changes"
            description="Receive alerts for schema changes or other breaking changes and understand the downstream impact on your transformations and pipelines."
          />
          <ProductFeatureSection
            // className={classes.analyzeDataSection}
            titleBeginning="Catch"
            titleEnd=" silent errors"
            description="Some data problems don't break pipelines — monitor for silent errors, like stale data, duplicated data, and data anomalies."
          />
          <ProductFeatureSection
            // className={classes.visualizeDataSection}
            titleBeginning="Write "
            titleEnd=" quality tests"
            description="Choose from our suite of pre-made tests or write your own custom tests. Autogenerate tests for your data source, instead of manually creating them."
          />
        </div>

        <IntegrateAlertingSection className={classes.integrationAlertingSection} />

        <SignupFooter
          className={classes.signupBanner}
          onSignupSuccess={renderSuccessSignUpToast}
          onSignupFail={renderFailedSignUpToast}
        />
        <Footer className={classes.footer} />
      </div>
    </div>
  );
};

const renderSuccessSignUpToast = (email: string): void => {
  AppToaster.show({
    message: `Thanks for signing up, ${email}.  We'll be in touch shortly!`,
    icon: 'tick-circle',
    timeout: 5000,
    intent: Intent.SUCCESS,
  });
};

const renderFailedSignUpToast = (msg: string): void => {
  AppToaster.show({
    message: msg,
    icon: 'issue',
    timeout: 5000,
    intent: Intent.DANGER,
  });
};

export default AlertingPage;

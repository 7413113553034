/** @format */

import { trackEvent } from 'analytics/exploAnalytics';
import { AppToaster } from 'toaster';
import { sha256 } from 'js-sha256';

import { Intent } from '@blueprintjs/core';

export const getHubspotCookie = () => {
  const cookies = '; ' + document.cookie;
  const cookiePart = cookies.split('; ' + process.env.REACT_APP_HUBSPOT_COOKIE_NAME + '=').pop();
  if (cookiePart) {
    return cookiePart.split(';').shift();
  } else {
    return '';
  }
};

const pingSlack = (email: string): void => {
  if (!process.env.REACT_APP_SLACK_PING_URL) {
    return;
  }

  fetch(process.env.REACT_APP_SLACK_PING_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      text: `${email} just signed up for Explo!`,
    }),
  })
    .then((resp) => resp.json())
    .then((data) => {})
    .catch((error) => {});
};

export const addEmailToWaitlist = (
  email: string,
  onSuccess: () => void,
  onError: (response: any) => void,
  source: string,
) => {
  fetch(
    'https://api.hsforms.com/submissions/v3/integration/submit/' +
      process.env.REACT_APP_HUBSPOT_PORTAL_ID +
      '/' +
      process.env.REACT_APP_HUBSPOT_BETA_SIGNUP_FORM_GUID,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        submittedAt: Date.now(),
        fields: [
          {
            name: 'email',
            value: email,
          },
          {
            name: 'lifecyclestage',
            value: 'Subscriber',
          },
        ],
        context: {
          hutk: getHubspotCookie(),
          pageUri: 'https://explo.co/',
          pageName: 'Explo',
        },
      }),
    },
  )
    .then((resp) => resp.json())
    .then((data) => {})
    .catch((error) => {});

  fetch(process.env.REACT_APP_API_URL + 'waitlist/add_email/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      email: email,
    }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (!data.success) {
        onError(data);
      } else {
        trackEvent('Landing page sign up', {
          sign_up_email: email,
          source: source,
        });

        pingSlack(email);
        onSuccess();
      }
    })
    .catch((error) => {});
};

export const onRegistrationSubmit = (
  termsChecked: boolean,
  password: string,
  password2: string,
  signupEmail: string,
  firstName: string,
  lastName: string,
  registerUser: any,
  onSuccess: () => void,
  onError: (errorMsg: string) => void,
) => {
  if (!password || !password2 || !signupEmail || !firstName || !lastName) {
    AppToaster.show({
      message: 'Please fille out all fields before submitting.',
      icon: 'error',
      timeout: 5000,
      intent: Intent.DANGER,
    });
  } else if (!termsChecked) {
    AppToaster.show({
      message: 'Please read and accept the terms of service before submitting.',
      icon: 'error',
      timeout: 5000,
      intent: Intent.DANGER,
    });
  } else if (password !== password2) {
    AppToaster.show({
      message: 'Passwords do not match.',
      icon: 'error',
      timeout: 5000,
      intent: Intent.DANGER,
    });
  } else {
    fetch(
      'https://api.hsforms.com/submissions/v3/integration/submit/' +
        process.env.REACT_APP_HUBSPOT_PORTAL_ID +
        '/' +
        process.env.REACT_APP_HUBSPOT_ACCOUNT_SIGNUP_FORM_GUID,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          submittedAt: Date.now(),
          fields: [
            {
              name: 'email',
              value: signupEmail,
            },
            {
              name: 'firstname',
              value: firstName,
            },
            {
              name: 'lastname',
              value: lastName,
            },
          ],
          context: {
            hutk: getHubspotCookie(),
            pageUri: 'https://explo.co/',
            pageName: 'Explo',
          },
        }),
      },
    )
      .then((resp) => resp.json())
      .then((data) => {})
      .catch((error) => {});

    registerUser(
      firstName,
      lastName,
      signupEmail,
      sha256(password),
      sha256(password2),
      () => {
        onSuccess();
      },
      (response: any) => {
        if (Object.keys(response).length > 0) {
          const key = Object.keys(response)[0];
          onError(response[key][0]);
          AppToaster.show({
            message: response[key][0],
            icon: 'error',
            timeout: 5000,
            intent: Intent.DANGER,
          });
        }
      },
    );
  }
};

/** @format */

import {
  Button,
  H3,
  Icon,
  Menu,
  MenuItem,
  NumericInput,
  Position,
  Tooltip,
} from '@blueprintjs/core';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Dataset } from 'actions/types';
import cx from 'classnames';
import CancelClauseButton from 'components/flowBuilder/editOperationDrawer/cancelClauseButton';
import EditDrawerDataPreview from 'components/flowBuilder/editOperationDrawer/editDrawerDataPreview';
import OperationDropdownInput from 'components/flowBuilder/operationDropdownInput';
import {
  AGGREGATIONS_LIST,
  DATE_PIVOT_AGGS,
  DATE_TYPES,
  FLYOUT_DATA_PREVIEW_HEADER,
  NON_NUMBER_AGGREGATIONS_LIST,
  NUMBER_TYPES,
  OPERATION_TYPES,
} from 'constants/flowConstants';
import {
  AggregationColumnInfo,
  ColumnInfo,
  DataType,
  PivotOperationAggregation,
  PivotOperationInstructions,
  PivotOperationInstructionsDTO,
  SelectedDropdownInputItem,
} from 'constants/types';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import _ from 'underscore';
import {
  getAggRowErrorInformation,
  getPivotOperationFooterErrorInformation,
  getPivotRowErrorInformation,
} from 'utils/operationErrorInfoUtils/pivotOperationErrorInfoUtil';
import OperationDrawerFooter from './operationDrawerFooter';
const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    body: {
      height: 'calc(100% - 54px)',
      overflowY: 'auto',
    },
    clauseTable: {
      width: '100%',
      backgroundColor: theme.palette.white,
      borderRadius: '4px',
      boxShadow: theme.customShadows.basic,
    },
    sectionTitleWithInput: {
      whiteSpace: 'nowrap',
      marginRight: theme.spacing(10),
    },
    sectionTitleWithInputContainer: {
      margin: theme.spacing(6),
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    sectionTitle: {
      margin: theme.spacing(6),
    },
    menuList: {
      maxHeight: 200,
      overflow: 'auto',
    },
    columnSelector: {},
    columnSelectorInput: {
      fontSize: 14,
    },
    tagInputColSelector: {
      minWidth: 200,
    },
    selectedColsContainer: {
      margin: `0 ${theme.spacing(6)}px`,
    },
    noColsSelected: {
      height: 200,
      marginBottom: theme.spacing(10),
    },
    pivotedColTableName: {
      fontSize: 16,
      height: 30,
      display: 'flex',
      alignItems: 'center',
    },
    pivotColNameCol: {
      width: 224,
    },
    pivotedColNoAggText: {
      fontSize: 18,
      height: 30,
      display: 'flex',
      alignItems: 'center',
    },
    bucketSizeIntegerInput: {
      width: 150,
    },
    helpTooltip: {
      marginLeft: theme.spacing(2),
    },
    helpTooltipPopover: {
      width: 250,
    },
    aggregationSelectorContainer: {
      margin: `0 ${theme.spacing(6)}px`,
      marginBottom: theme.spacing(6),
      backgroundColor: theme.palette.white,
      borderRadius: '4px',
      boxShadow: theme.customShadows.basic,
    },
    aggregationSelectorTable: {
      width: '100%',
    },
    addAggregationBtnContainer: {
      padding: theme.spacing(3),
    },
    buttonErrorState: {
      boxShadow: `inset 0 0 0 1px ${theme.palette.dangerRed} !important`,
    },
  });

type PassedProps = {
  instructions: PivotOperationInstructionsDTO;
  operationId: number;
  sourceDataset: Dataset;
  onSubmit: (newInstructions: PivotOperationInstructionsDTO) => void;
  noPivotedCol?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  instructions: PivotOperationInstructions;
};

const deriveInstructionsFromDTO = (instructionsDTO: PivotOperationInstructionsDTO) => {
  return {
    pivotedOnCols:
      instructionsDTO.pivotedOnCols && Object.values(instructionsDTO.pivotedOnCols).length
        ? Object.values(instructionsDTO.pivotedOnCols)
        : [
            {
              name: 'Select a Column',
              type: '',
            },
          ],
    aggregations:
      instructionsDTO.aggregations && instructionsDTO.aggregations.length
        ? cloneDeep(instructionsDTO.aggregations)
        : [{ aggedOnColumn: null, type: null }],
  };
};

class PivotOperationBody extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      instructions: deriveInstructionsFromDTO(props.instructions),
    };
  }

  render() {
    const { classes, sourceDataset, noPivotedCol } = this.props;
    const { instructions } = this.state;
    const { footerErrorState, footerErrorText } = getPivotOperationFooterErrorInformation(
      instructions,
      !!noPivotedCol,
    );

    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <EditDrawerDataPreview
            sourceDataset={sourceDataset}
            dataPreviewHeader={FLYOUT_DATA_PREVIEW_HEADER}
          />
          {!noPivotedCol && (
            <>
              <div className={classes.sectionTitleWithInputContainer}>
                <H3 className={classes.sectionTitleWithInput}>Group by</H3>
              </div>
              {this.renderPivotColumnsSelector()}
            </>
          )}

          <H3 className={classes.sectionTitle}>Select values</H3>
          {this.renderAggregationsSelector()}
        </div>
        <OperationDrawerFooter
          operationType={noPivotedCol ? OPERATION_TYPES.CALCULATE.id : OPERATION_TYPES.PIVOT.id}
          errorState={footerErrorState}
          errorText={footerErrorText}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }

  onSubmit = () => {
    const { onSubmit } = this.props;
    const { instructions } = this.state;
    const filteredAggregationInstructions: AggregationColumnInfo[] = _.filter(
      instructions.pivotedOnCols,
      (AggregationColumnInfo) => !!AggregationColumnInfo.type,
    );
    const DTOInstructions: PivotOperationInstructionsDTO = {
      pivotedOnCols: _.indexBy(filteredAggregationInstructions, 'name'),
      aggregations: cloneDeep(instructions.aggregations),
    };
    onSubmit(DTOInstructions);
  };

  renderPivotColumnsSelector = () => {
    const { classes } = this.props;
    const { pivotedOnCols } = this.state.instructions;

    return (
      <div className={classes.aggregationSelectorContainer}>
        <table className={cx(classes.aggregationSelectorTable, 'bp3-html-table')}>
          <thead>
            <tr>
              <th></th>
              <th>Column</th>
              <th>
                Bucket Size
                <Tooltip
                  className={classes.helpTooltip}
                  popoverClassName={classes.helpTooltipPopover}
                  position={Position.TOP}
                  content="Number fields and date fields may be grouped into buckets. Bucket sizes determine how we group the results in the selected columns to pivot.">
                  <Icon icon="help" />
                </Tooltip>
              </th>
            </tr>
          </thead>
          <tbody>{Object.values(pivotedOnCols).map(this.renderPivotColumnRow)}</tbody>
        </table>
        <div className={classes.addAggregationBtnContainer}>
          <Button icon="add" onClick={this.addNewColumnToPivot}>
            Add New Aggregation
          </Button>
        </div>
      </div>
    );
  };

  renderPivotColumnRow = (col: AggregationColumnInfo, index: number) => {
    const { classes, operationId, sourceDataset } = this.props;
    const { isPivotColButtonErrorState, isDateButtonErrorState } = getPivotRowErrorInformation(
      col,
      index,
    );
    return (
      <tr key={`pivot_agg_${operationId}}_${index}`}>
        <CancelClauseButton
          index={index}
          onClick={() => {
            this.setState((prevState) => {
              const { instructions } = prevState;
              instructions.pivotedOnCols.splice(index, 1);
              return { instructions };
            });
          }}
        />
        <td className={classes.pivotColNameCol}>
          <OperationDropdownInput
            buttonErrorState={isPivotColButtonErrorState}
            selectedItem={col.type ? { ...col, id: col.name } : undefined}
            onChange={(item) => {
              this.setState((prevState) => {
                const { instructions } = prevState;
                instructions.pivotedOnCols[index] = _.findWhere(sourceDataset.cached_schema!, {
                  name: item.name,
                })!;
                return { instructions };
              });
            }}
            noSelectionText="Select a Column"
            options={sourceDataset.cached_schema!.map((col) => ({ ...col, id: col.name }))}
          />
        </td>
        <td className={classes.pivotColNameCol}>
          {this.renderAggSelector(col, index, isDateButtonErrorState)}
        </td>
      </tr>
    );
  };

  renderAggregationsSelector = () => {
    const { classes } = this.props;
    const { aggregations } = this.state.instructions;

    return (
      <div className={classes.aggregationSelectorContainer}>
        <table className={cx(classes.aggregationSelectorTable, 'bp3-html-table')}>
          <thead>
            <tr>
              <th></th>
              <th>Column</th>
              <th>Calculation Type</th>
            </tr>
          </thead>
          <tbody>{aggregations.map(this.renderAggregation)}</tbody>
        </table>
        <div className={classes.addAggregationBtnContainer}>
          <Button
            icon="add"
            onClick={() => {
              this.setState((prevState) => {
                const { instructions } = prevState;
                instructions.aggregations.push({
                  type: null,
                  aggedOnColumn: null,
                });
                return { instructions };
              });
            }}>
            Add New Value
          </Button>
        </div>
      </div>
    );
  };

  renderAggregation = (aggregation: PivotOperationAggregation, index: number) => {
    const { classes, operationId, sourceDataset } = this.props;
    const { isSelectColButtonErrorState, isAggButtonErrorState } = getAggRowErrorInformation(
      aggregation,
      index,
    );
    return (
      <tr key={`pivot_agg_${operationId}}_${index}`}>
        <CancelClauseButton
          index={index}
          onClick={() => {
            this.setState((prevState) => {
              const { instructions } = prevState;
              instructions.aggregations.splice(index, 1);
              return { instructions };
            });
          }}
        />
        <td className={classes.pivotColNameCol}>
          <OperationDropdownInput
            buttonErrorState={isSelectColButtonErrorState}
            selectedItem={
              aggregation.aggedOnColumn
                ? ({
                    ...aggregation.aggedOnColumn,
                    id: aggregation.aggedOnColumn.name,
                  } as SelectedDropdownInputItem)
                : undefined
            }
            onChange={(item) => {
              this.setAggColumn(item, index);
            }}
            noSelectionText="Select a Column"
            options={sourceDataset.cached_schema!.map((col) => ({ ...col, id: col.name }))}
          />
        </td>
        <td className={classes.pivotColNameCol}>
          <OperationDropdownInput
            buttonErrorState={isAggButtonErrorState}
            disabled={aggregation.aggedOnColumn === null}
            selectedItem={aggregation.type as SelectedDropdownInputItem}
            onChange={(item) => {
              this.setState((prevState) => {
                const { instructions } = prevState;
                instructions.aggregations[index].type = item;
                return { instructions };
              });
            }}
            noSelectionText="Select an Aggregation"
            options={
              this.getAggListForColType(
                aggregation.aggedOnColumn && (aggregation.aggedOnColumn.type as DataType),
              ) as SelectedDropdownInputItem[]
            }
          />
        </td>
      </tr>
    );
  };

  getAggListForColType = (colType: DataType | null) => {
    if (!colType) {
      return [];
    }
    if (NUMBER_TYPES.has(colType)) {
      return AGGREGATIONS_LIST;
    } else {
      return NON_NUMBER_AGGREGATIONS_LIST;
    }
  };

  renderPivotColumnItem = (col: ColumnInfo, { handleClick }: { handleClick: any }) => (
    <MenuItem
      active={col.name in this.state.instructions.pivotedOnCols}
      key={`shared-resource-${col.name}`}
      text={col.name}
      onClick={handleClick}
    />
  );

  renderAggSelector = (
    colData: AggregationColumnInfo,
    index: number,
    isDateButtonErrorState: boolean,
  ) => {
    const { classes } = this.props;

    if (DATE_TYPES.has(colData.type)) {
      return (
        <OperationDropdownInput
          buttonErrorState={isDateButtonErrorState}
          selectedItem={colData.aggBucket as SelectedDropdownInputItem}
          onChange={(item) => this.setPivotColAggBucket(item, index)}
          noSelectionText="Select Date Group"
          options={DATE_PIVOT_AGGS}
        />
      );
    } else if (NUMBER_TYPES.has(colData.type)) {
      return (
        <NumericInput
          className={classes.bucketSizeIntegerInput}
          placeholder={'1, 10, 100...'}
          value={(colData.aggBucket as number) || 0}
          onValueChange={(newNum) => this.setPivotColAggBucket(newNum, index)}
          min={0}
        />
      );
    } else {
      return '-';
    }
  };

  pivotColItemListRenderer = ({ filteredItems, items, query, renderItem, itemsParentRef }: any) => {
    const { classes } = this.props;
    let itemsList;
    if (filteredItems.length === 0) {
      itemsList = <MenuItem disabled={true} text="No results." />;
    } else if (query.length === 0) {
      itemsList = items.map((item: any) => renderItem(item));
    } else {
      itemsList = filteredItems.map((item: any) => renderItem(item));
    }

    return (
      <Menu className={classes.menuList} ref={(ref) => (itemsParentRef = ref)}>
        {itemsList}
      </Menu>
    );
  };

  setPivotColAggBucket = (agg: number, index: number) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.pivotedOnCols[index].aggBucket = agg;
      return { instructions };
    });
  };

  addNewColumnToPivot = () => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.pivotedOnCols.push({
        name: 'Select a Column',
        type: '',
      });
      return { instructions };
    });
  };

  setAggColumn = (item: any, index: number) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      const prevAggColumn = instructions.aggregations[index].aggedOnColumn;
      if (prevAggColumn && prevAggColumn.type !== item.type) {
        instructions.aggregations[index].type = null;
      }
      instructions.aggregations[index].aggedOnColumn = item;
      return { instructions };
    });
  };
}

export default withStyles(styles)(PivotOperationBody);

/** @format */

import React from 'react';
import {
  Button,
  NonIdealState,
  H5,
  Intent,
  MenuItem,
  NumericInput,
  Spinner,
  Checkbox,
  Menu,
  InputGroup,
  Popover,
  Position,
  PopoverInteractionKind,
  IconName,
  Alignment,
  Icon,
  Tag,
  FormGroup,
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { AppToaster } from 'toaster';
import { trackEvent } from 'analytics/exploAnalytics';
import { connect } from 'react-redux';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { withStyles, WithStyles } from '@material-ui/styles';
import BaseDataTable from 'components/dataTable/baseDataTable';
import LoadingDataTable from 'components/dataTable/loadingDataTable';
import {
  TableData,
  DelimiterType,
  TableRow,
  SchemaChange,
  Schema,
  ColumnInfo,
  SchemaDataType,
  TimeStampItem,
} from 'constants/types';
import { PreviewData } from 'actions/types';
import {
  DELIMITERS,
  DATALIB_TYPES_TO_EXPLO_TYPES,
  SCHEMA_DATA_TYPES_BY_ID,
  SELECTABLE_DATA_TYPES,
  STRING,
  TIMESTAMP,
} from 'constants/flowConstants';
import { getTablePreview, uploadFileAsDataset } from 'utils/fileUtils';
import _ from 'underscore';
import { pollForResultsData } from 'utils/flowUtils';
import { safeScrollToColumn } from 'utils/general';
import { ColumnHeaderCell, Table } from '@blueprintjs/table';
import CustomTimestampFormatModal from 'components/customTimestampFormatModal';
import cx from 'classnames';
import { isFirstCharacterANumber } from 'utils/general';
import CustomCheckbox from 'components/checkbox';
import { ReduxState } from 'reducers/rootReducer';

var dl = require('datalib');

const styles = (theme: Theme) =>
  createStyles({
    body: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      overflow: 'hidden',
      borderRadius: 5,
    },
    fileUploadModalBody: {
      borderTop: `1px solid ${theme.palette.grey.border}`,
      borderRadius: 0,
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden',
      height: '100%',
    },
    optionsBar: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: OPTIONS_BAR_WIDTH,
      padding: `0px ${theme.spacing(6)}px ${theme.spacing(3)}px ${theme.spacing(6)}px`,
      borderRight: `1px solid ${theme.palette.grey.border}`,
      overflow: 'auto',
    },
    setupFlowOptionsBar: {
      backgroundColor: theme.palette.grey.modalBG,
    },
    datasetTableBody: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,
      overflowX: 'auto',
    },
    datasetTableContainer: {
      display: 'flex',
      flex: 1,
    },
    datasetTable: {
      height: '100%',
    },
    datasetTableFooter: {
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      backgroundColor: theme.palette.grey.light,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    modalFooter: {
      padding: `${theme.spacing(3)}px ${theme.spacing(6)}px`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderTop: `1px solid ${theme.palette.grey.border}`,
      backgroundColor: theme.palette.white,
      borderRadius: '0px 0px 6px 6px',
    },
    modalFooterActions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cancelButton: {
      marginRight: 10,
    },
    rowCount: {
      padding: `0 ${theme.spacing(1)}px`,
      fontSize: 14,
      color: theme.palette.black,
      fontWeight: 300,
      display: 'flex',
      alignItems: 'center',
    },
    fileNameText: {
      marginTop: theme.spacing(5),
    },
    headerText: {
      marginTop: theme.spacing(10),
    },
    optionsSubText: {
      fontSize: 11,
      marginBottom: theme.spacing(1),
    },
    delimiterPopover: {
      width: '100%',
    },
    fileConfigurationSection: {
      display: 'flex',
      flexDirection: 'column',
    },
    rowsToRemoveToggle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginTop: theme.spacing(1),
      minHeight: 30,
    },
    removeTopRowsCheckbox: {
      marginBottom: 0,
    },
    numericInputContainer: {
      width: 70,
      marginLeft: 15,
    },
    topRowsToRemoveText: {
      fontSize: 16,
      marginLeft: 15,
    },
    editSchemaGrid: {
      display: 'grid',
      gridTemplateColumns: `150px 145px 30px`,
      gridGap: '15px 10px',
      alignItems: 'center',
    },
    schemaEditClause: {
      display: 'flex',
    },
    subMenuItemContainer: {
      overflowY: 'scroll',
    },
    checkboxSpacing: {
      marginBottom: 0,
    },
    dataTypeDropDown: {
      width: 135,
    },
    dataTypeDropDownErrorState: {
      boxShadow: `inset 0 0 0 1px ${theme.palette.dangerRed} !important`,
    },
    dataTypeDropDownErrorText: {
      opacity: 0.6,
    },
    inputHeaderContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    inputWarningText: {
      fontSize: 12,
      display: 'flex',
      flexDirection: 'row',
    },
    danger: {
      color: theme.palette.dangerRed,
    },
    removeText: {
      display: 'flex',
      justifyContent: 'flex-end',
      color: '#5C7080',
      fontSize: 12,
      marginTop: 10,
      fontWeight: 'normal',
    },
    warningIcon: {
      marginRight: 5,
    },
    delimiterSelectorText: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    suggestedText: {
      fontSize: 10,
      lineHeight: 2,
      color: theme.palette.success.main,
    },
    formGroupValidationErrorState: {
      marginBottom: 0,
    },
    formGroupValidationNoError: {
      marginBottom: 20,
    },
    columnNameInputContainer: {
      marginBottom: 0,
    },
    inputElementErrorState: {
      marginBottom: 20,
    },
  });

const INFERENCE_ID = 'INFERENCE';
const INFERENCE = {
  id: INFERENCE_ID,
  name: 'Infer The Type',
  value: null,
};

const PREVIEW_ROW_COUNT = 50;
const MAX_NUMBER_OF_ROWS_TO_SKIP = 20;
const OPTIONS_BAR_WIDTH = 400;

enum FailedSubmissionAttempt {
  NONE,
  SELECTED_TYPES_INCOMPLETE,
}

type PassedProps = {
  file: File;
  onCancel: () => void;
  onSave: () => void;
  currentFolderId: number;
  createNewDatasetSuccess: (resourceData: any, workspaceName: string) => void;
  datasetUploadComplete: (datasetId: number, previewData: PreviewData) => void;
  setupFlowStyling?: boolean;
  existingUploadedCSVNames?: string[];
};

type Props = PassedProps & WithStyles<typeof styles> & ReturnType<typeof mapStateToProps>;

type State = {
  existingUploadedCSVNames?: string[];
  tableDataSchema?: SchemaChange[];
  tableData?: TableData;
  file: File;
  fileName: string;
  numOfRowsToSkip: number;
  selectedDelimiterType: DelimiterType;
  suggestedDelimiterType?: DelimiterType;
  isTableLoading: boolean;
  isExtraHeaderRowEnabled: boolean;
  topRowsToRemoveChecked: boolean;
  poppedRow?: string[];
  preservedHeaderRow?: string[];
  selectedColumnName?: string;
  inferedColumnTypes?: string[];
  failedSubmissionAttempt: FailedSubmissionAttempt;
  isCustomTimestampFormatModalOpen: boolean;
  customDateColumnIndex?: number;
};

class FileUploadPanel extends React.Component<Props, State> {
  previewTableRef?: any = null;
  readonly state: State = {
    file: this.props.file,
    fileName: this.props.file.name,
    numOfRowsToSkip: 0,
    selectedDelimiterType: INFERENCE,
    isTableLoading: true,
    isExtraHeaderRowEnabled: false,
    topRowsToRemoveChecked: false,
    failedSubmissionAttempt: FailedSubmissionAttempt.NONE,
    isCustomTimestampFormatModalOpen: false,
    existingUploadedCSVNames: this.props.existingUploadedCSVNames,
  };

  componentDidMount() {
    const { file } = this.props;
    file && this.getTablePreview(this.state.selectedDelimiterType, this.state.numOfRowsToSkip);
  }

  setStateWithNewFile = (file: File) => {
    this.setState({
      file,
      fileName: file.name,
      selectedDelimiterType: INFERENCE,
      numOfRowsToSkip: 0,
      isExtraHeaderRowEnabled: false,
      topRowsToRemoveChecked: false,
    });
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.file !== this.props.file) {
      this.setStateWithNewFile(this.props.file);
    } else if (this.state.file !== prevState.file) {
      this.setState({ isTableLoading: true });
      this.getTablePreview(this.state.selectedDelimiterType, this.state.numOfRowsToSkip);
    }
  }

  inferDataTypes = (tableData: TableData): string[] | undefined => {
    let formattedTableData: any[] = [];
    if (tableData.length === 0) {
      return;
    }
    const tableKeys = _.times(tableData[0].length, (index: number) => {
      return `column${index + 1}`;
    });

    tableData.forEach((row) => {
      formattedTableData.push(_.object(tableKeys, row));
    });

    return Object.values(dl.type.inferAll(formattedTableData));
  };

  onParsingSuccess = (results: any, numOfRowsToSkip: number) => {
    const { selectedDelimiterType, suggestedDelimiterType } = this.state;
    const tableData: TableData = results.data;
    tableData.splice(0, numOfRowsToSkip);
    const preservedHeaderRow: string[] = tableData.shift();

    const inferedColumnTypes: string[] | undefined = this.inferDataTypes(tableData);

    const tableDataSchema: SchemaChange[] = preservedHeaderRow
      ? preservedHeaderRow.map((header: string, index: number) => ({
          col: header,
          newType: inferedColumnTypes
            ? DATALIB_TYPES_TO_EXPLO_TYPES[inferedColumnTypes[index]]
            : STRING,
          safeCast: true,
          newColName: null,
          keepCol: true,
        }))
      : [];
    const newSelectedDelimiterType = _.find(Object.values(DELIMITERS), (delimiterType) => {
      return delimiterType.value === results.meta.delimiter;
    })!;
    const newSuggestedDelimiterType =
      selectedDelimiterType.id === INFERENCE_ID ? newSelectedDelimiterType : suggestedDelimiterType;

    this.setState({
      selectedDelimiterType: newSelectedDelimiterType,
      suggestedDelimiterType: newSuggestedDelimiterType,
      tableDataSchema,
      tableData,
      preservedHeaderRow,
      isTableLoading: false,
      inferedColumnTypes,
    });
  };

  getTablePreview = (delimiterType: DelimiterType, numOfRowsToSkip: number) => {
    getTablePreview(
      this.state.file,
      delimiterType.value,
      PREVIEW_ROW_COUNT + numOfRowsToSkip,
      (results: any) => this.onParsingSuccess(results, numOfRowsToSkip),
    );
  };
  render() {
    const { onCancel, classes, setupFlowStyling, loadingCSVDatasource } = this.props;
    const { tableData } = this.state;
    return (
      <div className={cx(classes.body, { [classes.fileUploadModalBody]: !setupFlowStyling })}>
        <div className={classes.modalContent}>
          {this.renderOptionsBar()}
          <div className={classes.datasetTableBody}>
            {this.renderDatasetPreview()}
            {!this.isTableDataEmpty() && (
              <div className={classes.datasetTableFooter}>
                <div className={classes.rowCount}>
                  {tableData ? `previewing ${tableData.length + 1} rows` : <Spinner size={17} />}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={classes.modalFooter}>
          <Tag
            icon="help"
            minimal
            large
            intent={Intent.NONE}
            interactive
            onClick={() => {
              trackEvent('Help Center Clicked', {
                source: 'CSV Upload Panel',
                type: 'csv_upload',
              });
              window.open('https://help.explo.co/how-do-i-upload-a-csv-file');
            }}>
            Problems uploading your CSV? Visit the Help Center
          </Tag>
          <div className={classes.modalFooterActions}>
            <Button className={classes.cancelButton} intent={Intent.NONE} onClick={onCancel}>
              Cancel
            </Button>
            <Button
              loading={loadingCSVDatasource}
              disabled={this.isTableDataEmpty()}
              intent={Intent.PRIMARY}
              onClick={this.onSubmit}>
              Save
            </Button>
          </div>
        </div>
        {this.renderCustomTimestampFormatModal()}
      </div>
    );
  }

  itemRenderer = (delimitertype: DelimiterType, { handleClick }: any) => {
    const { selectedDelimiterType } = this.state;
    const { suggestedDelimiterType } = this.state;
    const currentDelimiterIsSuggested =
      suggestedDelimiterType && suggestedDelimiterType.name === delimitertype.name;

    return (
      <MenuItem
        active={selectedDelimiterType && selectedDelimiterType.id === delimitertype.id}
        text={delimitertype.name}
        key={delimitertype.id}
        onClick={handleClick}
        labelElement={currentDelimiterIsSuggested && this.renderSuggestedMenuItemIcon()}
      />
    );
  };

  renderDelimiterSelector = () => {
    const { classes } = this.props;
    const { selectedDelimiterType } = this.state;
    return (
      <Select
        popoverProps={{ targetClassName: classes.delimiterPopover }}
        activeItem={selectedDelimiterType}
        items={Object.keys(DELIMITERS).map((key) => DELIMITERS[key])}
        itemRenderer={this.itemRenderer}
        onItemSelect={(selectedDelimiterType: DelimiterType) => {
          this.setState({ selectedDelimiterType });
          this.getTablePreview(selectedDelimiterType, this.state.numOfRowsToSkip);
        }}
        scrollToActiveItem={true}
        filterable={false}>
        <Button
          alignText={Alignment.LEFT}
          rightIcon="caret-down"
          text={this.renderDelimiterSelectorText(selectedDelimiterType.name)}
          fill
        />
      </Select>
    );
  };

  renderDelimiterSelectorText = (delimiterTypeName: string) => {
    const { classes } = this.props;
    const { suggestedDelimiterType } = this.state;
    return (
      <div className={classes.delimiterSelectorText}>
        {delimiterTypeName}
        {suggestedDelimiterType && suggestedDelimiterType.name === delimiterTypeName && (
          <div className={classes.suggestedText}>Suggested</div>
        )}
      </div>
    );
  };

  numHeaderRowsInput = () => {
    const { topRowsToRemoveChecked, numOfRowsToSkip, tableData } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.rowsToRemoveToggle}>
        <Checkbox
          className={classes.removeTopRowsCheckbox}
          large={true}
          checked={topRowsToRemoveChecked}
          label="Remove Top Rows"
          onChange={() => {
            this.toggleTopRowsToRemove();
          }}
        />
        {topRowsToRemoveChecked && (
          <div className={classes.numericInputContainer}>
            <NumericInput
              max={Math.min(MAX_NUMBER_OF_ROWS_TO_SKIP, tableData!.length + numOfRowsToSkip)}
              fill={true}
              placeholder={'0, 1, 2...'}
              value={numOfRowsToSkip}
              onValueChange={(numOfRowsToSkip) => {
                this.changeNumHeaderRowsToSkip(numOfRowsToSkip);
              }}
              min={0}
            />
          </div>
        )}
      </div>
    );
  };

  changeNumHeaderRowsToSkip = (numOfRowsToSkip: number) => {
    numOfRowsToSkip = Math.min(numOfRowsToSkip, 20);
    this.setState({ isExtraHeaderRowEnabled: false, numOfRowsToSkip });
    this.getTablePreview(this.state.selectedDelimiterType, numOfRowsToSkip);
  };

  toggleTopRowsToRemove = () => {
    this.setState((prevState) => {
      let { topRowsToRemoveChecked } = prevState;
      topRowsToRemoveChecked = !topRowsToRemoveChecked;
      return { topRowsToRemoveChecked };
    });
    this.changeNumHeaderRowsToSkip(0);
  };

  handleHeaderRowToggle = () => {
    this.setState({ isTableLoading: true });
    if (!this.state.isExtraHeaderRowEnabled) {
      this.addExtraHeaderRow();
    } else {
      this.removeExtraHeaderRow();
    }
  };

  addExtraHeaderRow = () => {
    this.setState((prevState) => {
      const { tableData, tableDataSchema, isExtraHeaderRowEnabled, preservedHeaderRow } = prevState;
      tableData!.unshift(preservedHeaderRow);
      const poppedRow: TableRow =
        tableData!.length < PREVIEW_ROW_COUNT - 1 ? undefined : tableData!.splice(-1, 1);
      return {
        tableData,
        tableDataSchema: tableDataSchema!.map((schemaChange) => ({
          col: '',
          newType: schemaChange.newType,
          safeCast: schemaChange.safeCast,
          newColName: null,
          keepCol: schemaChange.keepCol,
        })),
        isExtraHeaderRowEnabled: !isExtraHeaderRowEnabled,
        isTableLoading: false,
        poppedRow,
      };
    });
  };

  removeExtraHeaderRow = () => {
    this.setState((prevState) => {
      const { isExtraHeaderRowEnabled, tableData, poppedRow, tableDataSchema } = prevState;
      const newtableDataSchema: SchemaChange[] = tableData!
        .shift()
        .map((header: string, index: number) => {
          const schemaChange = tableDataSchema![index];
          return {
            col: header,
            newType: schemaChange.newType,
            safeCast: schemaChange.safeCast,
            newColName: null,
            keepCol: schemaChange.keepCol,
          };
        });
      if (poppedRow) {
        tableData!.push(poppedRow[0]);
      }
      return {
        isExtraHeaderRowEnabled: !isExtraHeaderRowEnabled,
        isTableLoading: false,
        tableData,
        tableDataSchema: newtableDataSchema,
      };
    });
  };

  getCSVNameError = (fileName: string) => {
    const { existingUploadedCSVNames, CSVUploadDatasource } = this.props;
    const existingCSVNames = CSVUploadDatasource
      ? _.pluck(
          CSVUploadDatasource.source_datasets.concat(CSVUploadDatasource.saved_datasets),
          'name',
        )
      : existingUploadedCSVNames;
    let inputErrorInfo: {
      nameErrorExists: boolean;
      nameErrorText?: string;
    } = {
      nameErrorExists: false,
      nameErrorText: undefined,
    };
    if (fileName === '') {
      inputErrorInfo.nameErrorExists = true;
      inputErrorInfo.nameErrorText = 'Please enter a CSV name';
    } else if (!!existingCSVNames && _.contains(existingCSVNames, fileName.trim())) {
      inputErrorInfo.nameErrorExists = true;
      inputErrorInfo.nameErrorText = 'CSV Filename Already Exists';
    }
    return inputErrorInfo;
  };

  renderOptionsBar = () => {
    const { classes, setupFlowStyling } = this.props;
    const { tableDataSchema, fileName, failedSubmissionAttempt } = this.state;

    const numberOfUnmatchedColumns = this.getSelectedUnmatchedColumns(tableDataSchema).length;
    const { nameErrorExists, nameErrorText } = this.getCSVNameError(fileName);
    return (
      <div className={cx(classes.optionsBar, { [classes.setupFlowOptionsBar]: setupFlowStyling })}>
        <H5 className={classes.fileNameText}>File Name</H5>
        <FormGroup
          className={
            nameErrorExists
              ? classes.formGroupValidationErrorState
              : classes.formGroupValidationNoError
          }
          helperText={nameErrorText}
          intent={nameErrorExists ? Intent.DANGER : Intent.NONE}
          labelFor="text-input">
          <InputGroup
            intent={nameErrorExists ? Intent.DANGER : Intent.NONE}
            type="text"
            value={fileName}
            onChange={this.setFileName}
          />
        </FormGroup>
        <H5 className={classes.headerText}>Select a Delimiter</H5>
        {this.renderDelimiterSelector()}
        <H5 className={classes.headerText}>Headings</H5>
        <Checkbox
          large={true}
          checked={this.state.isExtraHeaderRowEnabled}
          label="Add Header Row"
          onChange={this.handleHeaderRowToggle}
        />
        {this.numHeaderRowsInput()}
        <div className={cx(classes.headerText, classes.inputHeaderContainer)}>
          <H5>Columns</H5>
          {numberOfUnmatchedColumns > 0 && (
            <div
              className={cx(classes.inputWarningText, {
                [classes.danger]:
                  failedSubmissionAttempt === FailedSubmissionAttempt.SELECTED_TYPES_INCOMPLETE,
              })}>
              <Icon iconSize={12} className={classes.warningIcon} icon="error" />{' '}
              <b>
                {numberOfUnmatchedColumns} unmatched column{numberOfUnmatchedColumns > 1 && 's'}
              </b>
              , please select their data type
            </div>
          )}
        </div>
        <div className={classes.removeText}>Remove</div>
        <div className={classes.editSchemaGrid}>
          {tableDataSchema && tableDataSchema.map(this.renderSchemaEdit)}
        </div>
      </div>
    );
  };

  getSelectedUnmatchedColumns = (tableDataSchema?: SchemaChange[]) => {
    return tableDataSchema
      ? tableDataSchema.filter((schemaChange) => {
          return schemaChange.newType === null && schemaChange.keepCol;
        })
      : [];
  };

  handleRemoveColumnToggle = (index: number) => {
    this.setState((prevState) => {
      const { tableDataSchema } = prevState;
      let selectedColumnName = undefined;
      tableDataSchema![index].keepCol = !tableDataSchema![index].keepCol;

      // if the user is checking the box, scroll to the column and highlight it
      if (!tableDataSchema![index].keepCol) {
        safeScrollToColumn(index, this.previewTableRef);
        selectedColumnName = tableDataSchema![index].col;
      }
      return { tableDataSchema, selectedColumnName };
    });
  };

  getInputErrorInfo = (schemaChange: SchemaChange) => {
    let inputErrorInfo = {
      inputErrorExists: false,
      inputErrorText: 'None',
    };
    if (schemaChange.col === '') {
      inputErrorInfo.inputErrorExists = true;
      inputErrorInfo.inputErrorText = 'No empty Column Names';
    } else if (isFirstCharacterANumber(schemaChange.col)) {
      inputErrorInfo.inputErrorExists = true;
      inputErrorInfo.inputErrorText = 'No leading numbers';
    }
    return inputErrorInfo;
  };

  renderSchemaEdit = (schemaChange: SchemaChange, index: number) => {
    const { inputErrorExists, inputErrorText } = this.getInputErrorInfo(schemaChange);
    return [
      this.renderSchemaInputText(schemaChange, index, inputErrorExists, inputErrorText),
      this.renderTypeSelection(schemaChange, index, inputErrorExists),
      <CustomCheckbox
        key={_.uniqueId('keep_col_checkbox_')}
        isChecked={!schemaChange.keepCol}
        onChange={() => {
          this.handleRemoveColumnToggle(index);
        }}
      />,
    ];
  };

  renderTypeSelection = (schemaChange: SchemaChange, index: number, inputErrorExists: boolean) => {
    const { failedSubmissionAttempt } = this.state;
    const { classes } = this.props;
    const isButtonInErrorState =
      failedSubmissionAttempt === FailedSubmissionAttempt.SELECTED_TYPES_INCOMPLETE &&
      schemaChange.newType === null &&
      schemaChange.keepCol;
    return (
      <Popover
        key={`col_type_selection_${index}`}
        content={this.constructTypeDropdownMenuItems(schemaChange, index)}
        position={Position.BOTTOM_LEFT}
        autoFocus={false}
        interactionKind={PopoverInteractionKind.CLICK}
        disabled={!schemaChange.keepCol}>
        <Button
          icon={
            schemaChange.newType
              ? (SCHEMA_DATA_TYPES_BY_ID[schemaChange.newType!].icon as IconName)
              : undefined
          }
          className={cx(classes.dataTypeDropDown, {
            [classes.dataTypeDropDownErrorState]: isButtonInErrorState,
            [classes.inputElementErrorState]: inputErrorExists,
          })}
          alignText={Alignment.LEFT}
          rightIcon="caret-down"
          text={
            <div className={isButtonInErrorState ? classes.dataTypeDropDownErrorText : undefined}>
              {this.formatTypeMapForButton(schemaChange)}
            </div>
          }
          disabled={!schemaChange.keepCol}
          onFocus={() => {
            safeScrollToColumn(index, this.previewTableRef);
            this.setState({ selectedColumnName: schemaChange.col });
          }}
        />
      </Popover>
    );
  };

  formatTypeMapForButton = (schemaChange: SchemaChange) => {
    if ('subitem' in schemaChange && schemaChange['subitem']) {
      return SCHEMA_DATA_TYPES_BY_ID[schemaChange['newType']!].name + ': ' + schemaChange['format'];
    } else if (schemaChange['newType']) {
      return SCHEMA_DATA_TYPES_BY_ID[schemaChange['newType']!].name;
    } else {
      return 'Select type';
    }
  };

  constructTypeDropdownMenuItems = (schemaChange: SchemaChange, index: number) => {
    return (
      <Menu>
        {SELECTABLE_DATA_TYPES.map((dataType) =>
          this.constructTopLevelMenuItem(dataType, schemaChange, index),
        )}
      </Menu>
    );
  };

  constructTopLevelMenuItem = (type: string, schemaChange: SchemaChange, index: number) => {
    const { inferedColumnTypes } = this.state;
    const schemaDataTypes = SCHEMA_DATA_TYPES_BY_ID[type];
    const hasSubitems = 'subitems' in schemaDataTypes && schemaDataTypes['subitems'];
    return (
      <MenuItem
        active={schemaChange.newType === type}
        text={schemaDataTypes.name}
        key={_.uniqueId(`${type}_`)}
        popoverProps={{ openOnTargetFocus: false }}
        onClick={() => {
          this.setState({ selectedColumnName: undefined });
          return hasSubitems ? null : this.onTopLevelMenuItemSelect(index, type);
        }}
        icon={schemaDataTypes.icon as IconName}
        labelElement={
          this.isSuggestedElement(type, index, inferedColumnTypes) &&
          this.renderSuggestedMenuItemIcon()
        }>
        {hasSubitems ? this.constructSubMenuItems(schemaDataTypes, schemaChange, index) : null}
      </MenuItem>
    );
  };

  isSuggestedElement = (type: string, index: number, inferedColumnTypes?: string[]) => {
    if (!inferedColumnTypes) {
      return false;
    }

    // Check for !== 'date' b/c in DATALIB_TYPES_TO_EXPLO_TYPES we map from date to String
    const isCurrentTypeEqualToInferedType =
      DATALIB_TYPES_TO_EXPLO_TYPES[inferedColumnTypes[index]] === type &&
      inferedColumnTypes[index] !== 'date';

    const isInferedTypeDateAndCurrentTypeTimestamp =
      inferedColumnTypes[index] === 'date' && type === 'TIMESTAMP';
    return isCurrentTypeEqualToInferedType || isInferedTypeDateAndCurrentTypeTimestamp;
  };

  renderSuggestedMenuItemIcon = () => {
    return <Icon color="#4caf50" icon="dot" />;
  };

  constructSubMenuItems = (
    schemaDataTypes: SchemaDataType,
    schemaChange: SchemaChange,
    index: number,
  ) => {
    const { classes } = this.props;
    const { tableData } = this.state;

    const subMenuItems = schemaDataTypes['subitems']!.map((subitem: TimeStampItem) => {
      return (
        <MenuItem
          active={schemaChange.format && schemaChange.format === subitem['format']}
          text={subitem.name}
          key={_.uniqueId(`${subitem.name}_`)}
          onClick={() => this.onSubMenuItemSelect(index, subitem)}
        />
      );
    });

    subMenuItems.push(
      <Button
        fill
        disabled={tableData!.length === 0}
        key={_.uniqueId('col_subtype_selection_')}
        icon={'plus'}
        text="Custom Format"
        intent={Intent.PRIMARY}
        alignText={Alignment.LEFT}
        onClick={() =>
          this.setState({ isCustomTimestampFormatModalOpen: true, customDateColumnIndex: index })
        }
      />,
    );

    return <div className={classes.subMenuItemContainer}>{subMenuItems}</div>;
  };

  renderCustomTimestampFormatModal = () => {
    const {
      isCustomTimestampFormatModalOpen,
      customDateColumnIndex,
      tableData,
      tableDataSchema,
    } = this.state;
    if (!isCustomTimestampFormatModalOpen) return;

    return (
      <CustomTimestampFormatModal
        modalOpen={isCustomTimestampFormatModalOpen}
        closeModal={() => this.setState({ isCustomTimestampFormatModalOpen: false })}
        onSubmit={(timestampFormat: string) => {
          this.onSubMenuItemSelect(customDateColumnIndex!, {
            format: timestampFormat,
            name: timestampFormat,
            parentType: TIMESTAMP,
          });
        }}
        customDateColumnName={tableDataSchema![customDateColumnIndex!].col}
        schemaChange={tableDataSchema![customDateColumnIndex!]}
        previewDate={tableData![0][customDateColumnIndex!]}
      />
    );
  };

  renderSchemaInputText = (
    schemaChange: SchemaChange,
    index: number,
    inputErrorExists: boolean,
    inputErrorText: string,
  ) => {
    const { classes } = this.props;
    return (
      <FormGroup
        key={`col_name_${index}`}
        className={classes.columnNameInputContainer}
        intent={inputErrorExists ? Intent.DANGER : Intent.NONE}
        helperText={inputErrorExists ? inputErrorText : undefined}
        labelFor="text-input">
        <InputGroup
          intent={inputErrorExists ? Intent.DANGER : Intent.NONE}
          type="text"
          value={schemaChange.col}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const colName = event.target.value;
            this.setColName(colName, index);
          }}
          disabled={!schemaChange.keepCol}
          onFocus={() => {
            safeScrollToColumn(index, this.previewTableRef);
            this.setState({ selectedColumnName: schemaChange.col });
          }}
          onBlur={() => {
            this.setState({ selectedColumnName: undefined });
          }}
        />
      </FormGroup>
    );
  };

  setFileName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileName = event.target.value;
    this.setState({ fileName });
  };
  uploadErrors = (tableDataSchema: SchemaChange[], file: File) => {
    const { fileName } = this.state;

    if (_.findWhere(tableDataSchema, { col: '' })) {
      return {
        uploadError: true,
        errorText:
          'Please ensure all columns have a filled in header name in order to upload the CSV.',
      };
    } else if (
      !!_.find(tableDataSchema, (schemaChange) => isFirstCharacterANumber(schemaChange.col))
    ) {
      return {
        uploadError: true,
        errorText: 'Please ensure there are no columns that start with a number',
      };
    } else if (!_.findWhere(tableDataSchema, { keepCol: true })) {
      return {
        uploadError: true,
        errorText: 'Please ensure the CSV you are uploading has atleast one column',
      };
    } else if (_.findWhere(tableDataSchema, { newType: null, keepCol: true })) {
      this.setState({ failedSubmissionAttempt: FailedSubmissionAttempt.SELECTED_TYPES_INCOMPLETE });
      return {
        uploadError: true,
        errorText: 'Please ensure that all columns have a selected type',
      };
    } else if (file.size === 0) {
      return {
        uploadError: true,
        errorText: 'Please upload a CSV that is not Empty in order to upload.',
      };
    } else if (this.getCSVNameError(fileName).nameErrorExists) {
      return {
        uploadError: true,
        errorText: 'Please enter a valid CSV file name.',
      };
    }
    return { uploadError: false, errorText: 'No Upload Error' };
  };

  onSubmit = () => {
    const { onSave, currentFolderId, createNewDatasetSuccess, datasetUploadComplete } = this.props;
    const {
      file,
      fileName,
      numOfRowsToSkip,
      selectedDelimiterType,
      tableDataSchema,
      isExtraHeaderRowEnabled,
    } = this.state;
    const { uploadError, errorText } = this.uploadErrors(tableDataSchema!, file);
    if (uploadError) {
      AppToaster.show({
        message: errorText,
        icon: 'error',
        timeout: 5000,
        intent: Intent.DANGER,
      });
    } else {
      Object.defineProperty(file, 'name', {
        writable: true,
        value: fileName.trim(),
      });
      uploadFileAsDataset(
        file,
        currentFolderId,
        numOfRowsToSkip + (isExtraHeaderRowEnabled ? 0 : 1),
        selectedDelimiterType.id,
        _.pluck(tableDataSchema ? tableDataSchema : [], 'col'),
        (resourceData: any) => {
          createNewDatasetSuccess(resourceData, 'personal');
          pollForResultsData(
            resourceData.id,
            this.getOperationsFromSchemaChange(tableDataSchema!),
            (data: PreviewData) => {
              datasetUploadComplete(resourceData.id, {
                cached_preview: data.cached_preview,
                cached_schema: data.cached_schema,
                num_total_rows: data.num_total_rows,
                creator_user_id: resourceData.creator_user_id,
              });
            },
          );
        },
      );
      onSave();
    }
  };

  getOperationsFromSchemaChange = (changeSchemaList: SchemaChange[]) => {
    return [
      {
        operation_type: 'CHANGE_SCHEMA',
        instructions: {
          changeSchemaList,
        },
      },
    ];
  };

  isTableDataEmpty = () => {
    const { preservedHeaderRow } = this.state;
    return !preservedHeaderRow;
  };

  renderDatasetPreview = () => {
    const { classes } = this.props;
    const {
      tableDataSchema,
      tableData,
      isTableLoading,
      selectedColumnName,
      failedSubmissionAttempt,
    } = this.state;
    const DataTable: any = isTableLoading ? LoadingDataTable : BaseDataTable;

    return this.isTableDataEmpty() ? (
      <NonIdealState
        className={classes.datasetTable}
        icon="th-disconnect"
        title="No Table Data to Display"
        description="You either uploaded an empty file or skipped more than the total number of csv rows in the uploaded file"
      />
    ) : (
      <DataTable
        className={classes.datasetTableContainer}
        extractCellData={(rowIndex: number, colIndex: number, rows: TableData) => {
          return rows[rowIndex][colIndex];
        }}
        disableRowHeader={true}
        headerList={tableDataSchema ? this.convertFromSchemaChangeToSchema(tableDataSchema) : []}
        rows={tableData}
        loading={isTableLoading}
        maxRows={PREVIEW_ROW_COUNT}
        renderCustomColumnHeaderCell={this.renderCustomColumnHeaderCell}
        setTableRef={(ref: Table) => (this.previewTableRef = ref)}
        selectedColumns={new Set([selectedColumnName])}
        grayedColumns={new Set(this.getRemovedColumns(tableDataSchema))}
        redColumns={
          failedSubmissionAttempt === FailedSubmissionAttempt.SELECTED_TYPES_INCOMPLETE &&
          new Set(_.pluck(this.getSelectedUnmatchedColumns(tableDataSchema), 'col'))
        }
        preventTimeStampCasting
        fill
      />
    );
  };

  getRemovedColumns = (tableDataSchema?: SchemaChange[]) => {
    return tableDataSchema
      ? _.pluck(
          tableDataSchema.filter((schemaChange: SchemaChange) => {
            return !schemaChange.keepCol;
          }),
          'col',
        )
      : [];
  };

  convertFromSchemaChangeToSchema = (schemaChangeList: SchemaChange[]): Schema => {
    return schemaChangeList.map((schemaChange) => ({
      name: schemaChange.col,
      type: schemaChange.newType!,
    }));
  };

  renderCustomColumnHeaderCell = (
    columnInfo: ColumnInfo,
    renderColumnHeaderTextFunction: any,
    index: number,
  ) => {
    const { tableDataSchema } = this.state;
    return () => (
      <ColumnHeaderCell
        name={columnInfo.name}
        // @ts-ignore
        truncated={false}
        nameRenderer={renderColumnHeaderTextFunction}
        menuIcon="chevron-down"
        menuRenderer={() => {
          return this.constructTypeDropdownMenuItems(tableDataSchema![index], index);
        }}
      />
    );
  };

  renderDataTypesMenu = (index: number) => {
    return (
      <Menu>
        {SELECTABLE_DATA_TYPES.map((dataType: string) => {
          return this.renderDataTypesMenuItem(dataType, index);
        })}
      </Menu>
    );
  };

  renderDataTypesMenuItem = (dataType: string, index: number) => {
    return (
      <MenuItem
        icon={SCHEMA_DATA_TYPES_BY_ID[dataType].icon}
        text={SCHEMA_DATA_TYPES_BY_ID[dataType].name}
        onClick={(e: any) => {
          this.setHeaderType(dataType, index);
        }}
      />
    );
  };

  setHeaderType = (dataType: string, index: number) => {
    this.setState((prevState) => {
      const { tableDataSchema } = prevState;
      tableDataSchema![index].newType = dataType;
      return { tableDataSchema };
    });
  };

  setColName = (colName: string, index: number) => {
    this.setState((prevState) => {
      const { tableDataSchema } = prevState;
      tableDataSchema![index].col = colName;
      return { tableDataSchema, selectedColumnName: colName };
    });
  };

  onTopLevelMenuItemSelect = (index: number, type: string) => {
    this.setState((prevState) => {
      const { tableDataSchema } = prevState;
      tableDataSchema![index].newType = type;
      tableDataSchema![index].subitem = false;
      tableDataSchema![index].format = null;
      return { tableDataSchema };
    });
  };

  onSubMenuItemSelect = (index: number, subitem: TimeStampItem) => {
    this.setState((prevState) => {
      const { tableDataSchema } = prevState;
      tableDataSchema![index].newType = subitem.parentType;
      tableDataSchema![index].subitem = true;
      tableDataSchema![index].format = subitem.format;
      return { tableDataSchema };
    });
  };
}

const mapStateToProps = (state: ReduxState) => ({
  loadingCSVDatasource: state.workspaceData.loadingCSVDatasource,
  CSVUploadDatasource: state.workspaceData.CSVUploadDatasource,
});

export default connect(mapStateToProps, {})(withStyles(styles)(FileUploadPanel));

/** @format */
import React, { useContext } from 'react';
import { Operation } from 'actions/types';
import TextFieldModal from 'components/TextFieldModal';
import { AppToaster } from 'toaster';
import { Intent } from '@blueprintjs/core';
import { FlowGraphApi } from './flowGraphPageContext';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface PassedProps {
  saveDatasetModalOpen: boolean;
  toggleSaveDatasetModal: (modalOpen: boolean) => void;
  operation: Operation;
}

type Props = PassedProps & RouteComponentProps;

const saveAsDatasetModalOnClick = (
  datasetName: string,
  saveAsDataset: any,
  createFlowFromDataset: any,
  operation: Operation,
  history: any,
) => {
  const resultsDataId = operation.results_dataset && operation.results_dataset.id;

  saveAsDataset(
    {
      id: operation.id,
      postData: {
        dataset_id: resultsDataId,
        name: datasetName,
      },
    },
    (data: any) => {
      const datasetId: number = data.saved_dataset_id;
      const folderId: number = data.saved_dataset_folder_id;
      AppToaster.show({
        message: 'Successfully saved new dataset: ' + datasetName,
        intent: Intent.SUCCESS,
        timeout: 7000,
        action: {
          icon: 'data-lineage',
          text: 'Start Exploration',
          onClick: () => {
            createFlowFromDataset(
              datasetId,
              folderId,
              datasetName + ' Exploration',
              (flowId: number) => history.push(`/flows/${flowId}`),
            );
          },
        },
      });
    },
  );
};

const SaveDatasetModal = (props: Props) => {
  const { saveDatasetModalOpen, toggleSaveDatasetModal, operation, history } = props;
  const { saveAsDataset, createFlowFromDataset } = useContext(FlowGraphApi)!;
  if (!saveDatasetModalOpen) return <></>;
  return (
    <TextFieldModal
      modalOpen={saveDatasetModalOpen}
      closeModal={() => toggleSaveDatasetModal(false)}
      modalTitle="Save this result as a dataset"
      textFieldLabel="Dataset Name"
      buttonName="Save"
      onSubmit={(datasetName: string) =>
        saveAsDatasetModalOnClick(
          datasetName,
          saveAsDataset,
          createFlowFromDataset,
          operation,
          history,
        )
      }
    />
  );
};

export default withRouter(SaveDatasetModal);

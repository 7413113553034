/** @format */

import loadingReducer from 'reducers/api/loadingReducer';
import authReducer from 'reducers/authReducer';
import covidReducer from 'reducers/covidReducer';
import dashboardReducer from 'reducers/dashboardReducer';
import datasetCacheReducer from 'reducers/datasetCacheReducer';
import datasetReducer from 'reducers/datasetReducer';
import dataSourceReducer from 'reducers/dataSourceReducer';
import dataSourcesListReducer from 'reducers/dataSourcesListReducer';
import flowReducer from 'reducers/flowReducer';
import onboardingReducer from 'reducers/onboardingReducer';
import supportedDataSourcesReducer from 'reducers/supportedDataSourcesReducer';
import teamDataReducer from 'reducers/teamDataReducer';
import workspaceReducer from 'reducers/workspaceReducer';
import shareReducer from 'reducers/shareReducer';
import operationSourceColumnsReducer from 'reducers/operationSourceColumnsReducer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  loading: loadingReducer,
  workspaceData: workspaceReducer,
  currentUser: authReducer,
  datasetData: datasetReducer,
  flowData: flowReducer,
  dashboardData: dashboardReducer,
  datasetCacheData: datasetCacheReducer,
  dataSourceList: dataSourcesListReducer,
  dataSource: dataSourceReducer,
  supportedDataSources: supportedDataSourcesReducer,
  teamData: teamDataReducer,
  covidData: covidReducer,
  onboardingData: onboardingReducer,
  shareData: shareReducer,
  operationSourceColumnsData: operationSourceColumnsReducer,
});

export type ReduxState = ReturnType<typeof rootReducer>;

export default rootReducer;

export interface Action {
  type?: string;
  payload: any;
}

/** @format */

import React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';

import StepBlockTag from 'components/flowBuilder/stepBlockTag';

import { InstructionComponentProps } from 'components/flowGraph/operationInstructionDescription';
import { UnionOperationInstructions } from 'constants/types';

const styles = (theme: Theme) =>
  createStyles({
    fillerText: {
      fontSize: 16,
      fontWeight: 400,
      marginRight: theme.spacing(2),
    },
  });

type PassedProps = InstructionComponentProps<UnionOperationInstructions>;

type Props = PassedProps & WithStyles<typeof styles>;

class UnionOperationInstructionDescription extends React.Component<Props> {
  render() {
    const { classes, operationId, instructions } = this.props;

    if (!instructions.unionTable.datasetId) {
      return <></>;
    }
    const stepBuilderBlockInfo = [
      <div className={classes.fillerText} key={`union_with_${operationId}`}>
        with
      </div>,
    ];
    stepBuilderBlockInfo.push(
      <StepBlockTag
        key={`table_name_${operationId}`}
        content={instructions.unionTable.name}
        href={`/datasets/${instructions.unionTable.datasetId}`}
      />,
    );
    return stepBuilderBlockInfo;
  }
}

export default withStyles(styles)(UnionOperationInstructionDescription);

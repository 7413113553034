/** @format */
import _ from 'underscore';
import { Action } from 'reducers/rootReducer';
import { DataSource } from 'actions/types';
import cloneDeep from 'lodash/cloneDeep';
import { CSV_UPLOADS_DS_NAME } from 'constants/flowConstants';

export type DataSourcesListReducerState = DataSource[];

const dataSourcesListReducerInitialState: DataSourcesListReducerState = [];

const getDataSource = (dataSources: DataSourcesListReducerState, dataSourceId: number) => {
  return _.first(dataSources.filter((dataSource: DataSource) => dataSource.id === dataSourceId));
};

export default (
  state: DataSourcesListReducerState = dataSourcesListReducerInitialState,
  action: Action,
): DataSourcesListReducerState => {
  const { payload } = action;
  const newState = cloneDeep(state);

  switch (action.type) {
    case 'CREATE_NEW_DATASET_SUCCESS':
      const CSVDataSource = _.findWhere(newState, { name: CSV_UPLOADS_DS_NAME });
      CSVDataSource!.dataset_count += 1;
      return newState;
    case 'FETCH_DATA_SOURCE_LIST_SUCCESS':
      payload.dataSources.sort((val1: DataSource, val2: DataSource) => {
        return val1.name === CSV_UPLOADS_DS_NAME ? -1 : val2.name === CSV_UPLOADS_DS_NAME ? 1 : 0;
      });
      return payload.dataSources;
    case 'CONNECT_DATA_SOURCE_SUCCESS':
      newState.push(payload.data_source);
      return newState;
    case 'DELETE_DATA_SOURCE_REQUEST':
      let deleteDataSourceReq: DataSource | undefined = getDataSource(newState, payload.id);
      if (deleteDataSourceReq) {
        deleteDataSourceReq.loading = true;
      }
      return newState;
    case 'DELETE_DATA_SOURCE_SUCCESS':
      let deleteDataSourceSuc: DataSource | undefined = getDataSource(newState, payload.id);
      if (deleteDataSourceSuc) {
        deleteDataSourceSuc.loading = false;
      }
      return newState.filter((dataSource: DataSource) => dataSource.id !== payload.id);
    case 'DELETE_DATA_SOURCE_ERROR':
      let deleteDataSourceErr: DataSource | undefined = getDataSource(newState, payload.id);
      if (deleteDataSourceErr) {
        deleteDataSourceErr.loading = false;
      }
      return newState;
    case 'SYNC_DATA_SOURCE_REQUEST':
      let syncDataSourceReq: DataSource | undefined = getDataSource(newState, payload.id);
      if (syncDataSourceReq) {
        syncDataSourceReq.loading = true;
      }
      return newState;
    case 'SYNC_DATA_SOURCE_SUCCESS':
      let syncDataSourceSuc: DataSource | undefined = getDataSource(newState, payload.id);
      if (syncDataSourceSuc) {
        syncDataSourceSuc.loading = false;
      }
      return newState;
    case 'SYNC_DATA_SOURCE_ERROR':
      let syncDataSourceErr: DataSource | undefined = getDataSource(newState, payload.id);
      if (syncDataSourceErr) {
        syncDataSourceErr.loading = false;
      }
      return newState;
    default:
      return newState;
  }
};

/** @format */

import React, { RefObject } from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { AppToaster } from 'toaster';

import { Dataset } from 'actions/types';

import {
  Icon,
  Spinner,
  Tag,
  Intent,
  Popover,
  Button,
  Menu,
  MenuItem,
  Position,
  Tooltip,
  IconName,
} from '@blueprintjs/core';

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    border: `1px solid ${theme.palette.grey.border}`,
    borderRadius: 4,
    color: theme.palette.black,
    '&.create': {
      backgroundColor: 'inherit',
      border: `1px solid ${theme.palette.grey.blueprintGrey}`,
      borderStyle: 'dashed',
    },
    '&:hover': {
      color: theme.palette.black,
      backgroundColor: theme.palette.grey.light,
      textDecoration: 'none',
    },
    '&:active': {
      backgroundColor: theme.palette.grey.middle,
    },
  },
  header: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 54,
  },
  name: {
    fontSize: 16,
    'white-space': 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 250,
  },
  sourceTable: {
    fontSize: 14,
    padding: `0 ${theme.spacing(3)}px`,
    paddingBottom: theme.spacing(3),
    color: theme.palette.grey.darkest,
    'white-space': 'nowrap',
    textOverflow: 'ellipsis',
    width: 250,
    overflow: 'hidden',
  },
  iconWithText: {
    marginRight: theme.spacing(2),
  },
  menu: {},
  menuHidden: {
    visibility: 'hidden' as 'hidden',
  },
  datasetMenuItem: {
    padding: theme.spacing(4),
    '&:hover': {
      backgroundColor: 'rgba(167,182,194,.3)',
      cursor: 'pointer',
    },
    '&:active': {
      backgroundColor: 'rgba(115,134,148,.3)',
    },
    '&.selected': {
      color: theme.palette.white,
      // @ts-ignore
      backgroundColor: theme.palette.primary.main,
    },
  },
  datasetMenuItemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  datasetMenuItemRightSide: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  datasetMenuItemTitle: {
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    'white-space': 'nowrap',
  },
  datasetMenuItemIcon: {
    marginRight: theme.spacing(2),
  },
  datasetToken: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  menuItemTooltipFirstDummy: {
    display: 'none',
  },
  menuItemTooltips: {
    display: 'block !important',
  },
  disabledMenuItem: {
    cursor: 'not-allowed',
    color: 'rgba(92, 112, 128, 0.6) !important',
    background: 'none',
    '&:hover': {
      backgroundColor: theme.palette.white,
      color: 'rgba(92, 112, 128, 0.6) !important',
      cursor: 'not-allowed',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  regularIcon: {
    marginRight: '7px',
    marginTop: '2px',
    color: '#5c7080 !important',
  },
  disabledIcon: {
    marginRight: '7px',
    marginTop: '2px',
    color: 'rgba(92, 112, 128, 0.6) !important',
  },
  datasetRowTooltip: {
    width: '100%',
  },
});

type PassedProps = {
  className?: string;
  datasetSelected?: boolean;
  dataset: Dataset;
  onDatasetSelected: (dataset: Dataset | null) => void;
  key: string;
  isExploSource: boolean;
  openRenameDatasetModal?: (selectedDataset: Dataset) => void;
  openDeleteDatasetModal?: (selectedDataset: Dataset) => void;
  openMoveDatasetModal?: (selectedDataset: Dataset) => void;
  editingDisabled?: boolean;
  teamResourceIds?: Set<number>;
  datasetLoading?: boolean;
  currentUserId?: number;
};

type State = {
  mouseHover: boolean;
  actionsMenuOpen: boolean;
};

type Props = PassedProps & WithStyles<typeof styles> & RouteComponentProps;

class DatasetRow extends React.Component<Props, State> {
  state: State = {
    mouseHover: false,
    actionsMenuOpen: false,
  };

  rowRef: RefObject<HTMLDivElement> = React.createRef();

  render() {
    const { classes, datasetSelected, dataset, onDatasetSelected, key } = this.props;

    const name = dataset.name || dataset.data_source_table_name;

    return (
      <Tooltip
        content={name}
        targetClassName={classes.datasetRowTooltip}
        disabled={!this.datasetRowNameTooltipCanBeOpen()}>
        <div
          className={cx(classes.datasetMenuItem, {
            selected: datasetSelected,
          })}
          onClick={() => onDatasetSelected(dataset)}
          onMouseOver={this.onMouseOver}
          onMouseLeave={this.onMouseOut}
          key={key}>
          <div className={classes.datasetMenuItemContainer}>
            <div className={classes.datasetMenuItemTitle} ref={this.rowRef}>
              <Icon icon="th" className={classes.datasetMenuItemIcon} />
              {name}
            </div>
            <div className={classes.datasetMenuItemRightSide}>
              {this.renderDatasetToken()}
              {this.renderDatasetActions()}
              {this.renderDatasetLoadingSpinner()}
            </div>
          </div>
        </div>
      </Tooltip>
    );
  }

  datasetRowNameTooltipCanBeOpen = () => {
    if (this.rowRef.current) {
      return this.rowRef.current.scrollWidth > this.rowRef.current.clientWidth;
    }
    return false;
  };

  renderDatasetActions = () => {
    const {
      classes,
      dataset,
      teamResourceIds,
      editingDisabled,
      openRenameDatasetModal,
      openMoveDatasetModal,
      openDeleteDatasetModal,
      datasetLoading,
    } = this.props;
    const { mouseHover, actionsMenuOpen } = this.state;

    if (editingDisabled || datasetLoading) {
      return;
    }
    return (
      <Popover
        hoverCloseDelay={0}
        disabled={!mouseHover}
        isOpen={actionsMenuOpen}
        onClose={() => this.setState({ actionsMenuOpen: false })}
        position={Position.BOTTOM}>
        <Button
          className={cx(classes.menu, { [classes.menuHidden]: !mouseHover })}
          icon="more"
          minimal
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ actionsMenuOpen: !actionsMenuOpen });
          }}
        />
        <Menu>
          {/* Tooltips are not supported in MenuItems (https://github.com/palantir/blueprint/issues/926).  Having a demo initial ToolTip fixes the bugs. */}
          <Tooltip content={''} className={classes.menuItemTooltipFirstDummy}>
            ''
          </Tooltip>
          {this.renderTooltipWrappedMenuItem(
            'rename',
            'Rename',
            'edit',
            false,
            false,
            openRenameDatasetModal,
          )}
          {this.renderTooltipWrappedMenuItem(
            'share',
            teamResourceIds && teamResourceIds.has(dataset.id) ? 'Make private' : 'Share with team',
            teamResourceIds && teamResourceIds.has(dataset.id) ? 'person' : 'people',
            true,
            true,
            openMoveDatasetModal,
          )}
          {this.renderTooltipWrappedMenuItem(
            'delete',
            'Delete',
            'trash',
            true,
            true,
            openDeleteDatasetModal,
          )}
        </Menu>
      </Popover>
    );
  };

  renderTooltipWrappedMenuItem = (
    tooltipAction: string,
    menuItemAction: string,
    actionIcon: string,
    isDisabledForDataSource: boolean,
    actionOnlyPerformedByCreator: boolean,
    openModalFunction?: (selectedDataset: any | null) => void,
  ) => {
    const { classes, dataset, currentUserId } = this.props;
    const { actionsMenuOpen } = this.state;

    const menuItemDisabled =
      isDisabledForDataSource && this.isSourceDatasetAndNotExploSource(dataset.is_source_dataset);

    return (
      <Tooltip
        targetClassName={classes.menuItemTooltips}
        content={
          menuItemDisabled
            ? `Can't ${tooltipAction} datasets that're directly pulled from data sources.`
            : ''
        }
        position={Position.RIGHT}>
        <MenuItem
          text={
            <>
              <Icon
                icon={actionIcon as IconName}
                className={menuItemDisabled ? classes.disabledIcon : classes.regularIcon}
              />
              {menuItemAction}
            </>
          }
          className={menuItemDisabled ? classes.disabledMenuItem : ''}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            if (!menuItemDisabled && openModalFunction) {
              if (actionOnlyPerformedByCreator && dataset.creator_user_id !== currentUserId) {
                this.errorToast(
                  `You do not have permission to perform this action because this dataset was uploaded by another user.`,
                );
              } else {
                openModalFunction(dataset);
                this.setState({ actionsMenuOpen: !actionsMenuOpen });
              }
            }
          }}
        />
      </Tooltip>
    );
  };

  errorToast = (message: string) => {
    AppToaster.show({
      message: message,
      icon: 'error',
      timeout: 5000,
      intent: Intent.DANGER,
    });
  };

  isSourceDatasetAndNotExploSource = (isSourceDataset: boolean) => {
    const { isExploSource } = this.props;
    if (isSourceDataset && !isExploSource) {
      return true;
    }
    return false;
  };

  renderDatasetToken = () => {
    const { datasetSelected, classes, dataset } = this.props;
    const intent = datasetSelected ? 'none' : Intent.PRIMARY;

    if (dataset.new) {
      return (
        <div className={classes.datasetToken}>
          <Tag intent={intent}>NEW</Tag>
        </div>
      );
    }
  };

  renderDatasetLoadingSpinner = () => {
    const { datasetLoading, datasetSelected, classes } = this.props;

    const intent = datasetSelected ? 'none' : Intent.PRIMARY;

    if (datasetLoading) {
      return (
        <div className={classes.datasetToken}>
          <Spinner intent={intent} size={20} />
        </div>
      );
    }
  };

  onMouseOver = () => {
    const { mouseHover } = this.state;
    !mouseHover && this.setState({ mouseHover: true });
  };

  onMouseOut = () => {
    const { mouseHover } = this.state;
    mouseHover && this.setState({ mouseHover: false });
  };
}

export default withRouter(withStyles(styles)(DatasetRow));

/** @format */

import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withStyles, WithStyles } from '@material-ui/styles';
import OperationInputTable, {
  ColumnConfiguration,
  OperationInputType,
} from 'components/flowBuilder/editOperationDrawer/operationInputTable';
import { SORT_ORDERS_BY_TYPE } from 'constants/flowConstants';
import { Schema, SortClause, SortOrder } from 'constants/types';
import React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    columnSelectCol: {
      width: 200,
    },
    orderSelectCol: {
      width: 200,
    },
  });

interface PassedProps {
  sortClauses: SortClause[];
  updateInstructions: (tableElements: SortClause[]) => void;
  cachedSchema: Schema;
}

type Props = PassedProps & WithStyles<typeof styles>;

class SortOperationInputTable extends React.Component<Props> {
  render() {
    const { sortClauses, updateInstructions, cachedSchema } = this.props;
    return (
      <OperationInputTable
        tableHeader="Select columns to sort"
        operationInputTableRows={sortClauses}
        updateInstructions={updateInstructions}
        removeColumnsEnabled
        cachedSchema={cachedSchema}
        disableAddElementIfRowsExcedeSchema={true}
        columnConfigurations={[
          this.getTextColumnConfiguration(),
          this.getSortColumnConfiguration(cachedSchema),
          this.getOrderColumnConfiguration(),
        ]}
        addElementButtonText="Add Column"
        createNewOperationInputTableRow={() => ({
          column: null,
          order: SortOrder.ASC,
        })}
      />
    );
  }

  getSortColumnConfiguration = (cachedSchema: Schema): ColumnConfiguration => {
    const { classes } = this.props;
    return {
      columnName: 'Column',
      columnInputType: OperationInputType.DROPDOWN,
      className: classes.columnSelectCol,
      columnSettings: {
        items: cachedSchema,
        itemDisplayName: 'name',
        instructionUpdateKey: 'column',
        noSelectionText: 'Column',
        errorIfFirstRowEmpty: true,
      },
    };
  };

  getOrderColumnConfiguration = (): ColumnConfiguration => {
    const { classes } = this.props;
    return {
      columnName: 'Order',
      columnInputType: OperationInputType.DROPDOWN,
      className: classes.orderSelectCol,
      columnSettings: {
        instructionUpdateKey: 'order',
        disabled: (rowIndex: number, operationInputTableRows: SortClause[]) => {
          const selectedColumn = operationInputTableRows[rowIndex].column;
          return selectedColumn === null;
        },
        selectedItem: (rowIndex: number, operationInputTableRows: SortClause[]) => {
          const selectedOrder = operationInputTableRows[rowIndex].order;
          const selectedColumn = operationInputTableRows[rowIndex].column;
          const sortOrders = selectedColumn ? SORT_ORDERS_BY_TYPE[selectedColumn.type] : [];
          return selectedOrder
            ? selectedOrder === SortOrder.ASC
              ? sortOrders[0]
              : sortOrders[1]
            : undefined;
        },
        getInstructionFromInputItem: (
          rowIndex: number,
          operationInputTableRows: SortClause[],
          selectedDropdownInputItem: any,
        ) => {
          return selectedDropdownInputItem.order;
        },
        options: (rowIndex: number, operationInputTableRows: SortClause[]) => {
          const selectedColumn = operationInputTableRows[rowIndex].column;
          return selectedColumn ? SORT_ORDERS_BY_TYPE[selectedColumn.type] : [];
        },
        noSelectionText: 'Select Order',
        showIcon: true,
      },
    };
  };

  getTextColumnConfiguration = () => {
    return {
      columnName: '',
      columnInputType: OperationInputType.DISPLAY,
      columnSettings: {
        displayContent: (rowIndex: number) => {
          return rowIndex === 0 ? 'Sort by' : 'then by';
        },
      },
    };
  };
}

export default withStyles(styles)(SortOperationInputTable);

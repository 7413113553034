/** @format */

import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { Spinner } from '@blueprintjs/core';

import BaseDataTable from 'components/dataTable/baseDataTable';
import LoadingDataTable from 'components/dataTable/loadingDataTable';
import { MAX_ROWS_TO_PREVIEW } from 'constants/flowConstants';
import { Theme, WithStyles } from '@material-ui/core/index';
import { Schema } from 'constants/types';
import FlowGraphDatasetActions from './flowGraphDatasetActions';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    tableFooter: {
      borderBottomRightRadius: 8,
      borderBottomLeftRadius: 8,
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      height: 34,
      backgroundColor: theme.palette.grey.slight,
      display: 'flex',
      justifyContent: 'flex-start',
      color: theme.palette.grey800,
    },
    rowCount: {
      padding: `0 ${theme.spacing(1)}px`,
      fontSize: 14,
      fontWeight: 300,
      display: 'flex',
      alignItems: 'center',
    },
    rowCountError: {
      color: theme.palette.dangerRed,
    },
    rowCountSpinner: {
      marginRight: theme.spacing(1),
    },
    header: {
      padding: theme.spacing(5),
      borderBottom: '1px solid rgba(16, 22, 26, 0.15)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    titleText: {
      fontWeight: 'bold',
      fontSize: 18,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    operationName: {
      marginRight: theme.spacing(2),
    },
  });

type PassedProps = {
  previewData: any;
  schema: Schema;
  datasetName: string;
  sourceDataRowCount: number;
  datasetFlowNodeId: number;
  isSelected: boolean;
  addDatasetModalOpen: boolean;
  addStepModalOpen: boolean;
  rowError?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles>;

class FlowSourceDataView extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.renderHeader()}
        {this.renderPreviewTable()}
        {this.renderDatasetActions()}
      </div>
    );
  }

  renderHeader = () => {
    const { classes } = this.props;
    return (
      <div className={classes.header}>
        <div className={classes.titleText}>{this.props.datasetName || ''}</div>
      </div>
    );
  };

  renderDatasetActions = () => {
    const { datasetFlowNodeId, isSelected, addDatasetModalOpen, addStepModalOpen } = this.props;
    return (
      <FlowGraphDatasetActions
        datasetFlowNodeId={datasetFlowNodeId}
        isSelected={isSelected}
        addDatasetModalOpen={addDatasetModalOpen}
        addStepModalOpen={addStepModalOpen}
      />
    );
  };

  renderPreviewTable = () => {
    const { classes, previewData, schema } = this.props;
    const DataTable = !previewData ? LoadingDataTable : BaseDataTable;

    if (previewData) {
      return (
        <>
          <div>
            <DataTable
              loading={!previewData}
              rows={previewData || []}
              headerList={schema}
              maxRows={MAX_ROWS_TO_PREVIEW}
              noBorderRadius
              fill
              columnWidthFill
              truncateEmptyRowSpace
            />
          </div>
          <div className={classes.tableFooter}>{this.renderRowCount()}</div>
        </>
      );
    }
  };

  renderRowCount = () => {
    const { classes, sourceDataRowCount, rowError } = this.props;
    if (rowError) {
      return (
        <div className={classes.rowCount}>
          <div className={classes.rowCountError}>Error fetching row count</div>
        </div>
      );
    }
    return (
      <div className={classes.rowCount}>
        {sourceDataRowCount ? (
          sourceDataRowCount.toLocaleString()
        ) : (
          <Spinner className={classes.rowCountSpinner} size={17} />
        )}{' '}
        resulting rows{' '}
        {sourceDataRowCount && sourceDataRowCount > MAX_ROWS_TO_PREVIEW
          ? `(previewing ${MAX_ROWS_TO_PREVIEW} rows)`
          : ''}
      </div>
    );
  };
}

export default withStyles(styles)(FlowSourceDataView);

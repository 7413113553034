/** @format */

import React, { useState, SetStateAction, Dispatch } from 'react';
import { Button, FormGroup, InputGroup, Dialog, Intent, H5, H6, Icon } from '@blueprintjs/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { AppToaster } from 'toaster';
// no TS for this library exists
// @ts-ignore
import { strptime } from 'strtime';
import { SchemaChange } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    paddingBottom: '0px !important',
    height: '75vh',
    justifyContent: 'space-between',
  },
  dialogBody: {
    margin: theme.spacing(5),
    height: '75%',
  },
  formatOptionsTable: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.white,
    height: '45%',
  },
  formatOptionsHeader: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 1fr',
    borderBottom: `1px solid ${theme.palette.grey.border}`,
    padding: theme.spacing(2),
  },
  formatOptionsTableRows: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 1fr',
    padding: theme.spacing(2),
    rowGap: `${theme.spacing(3)}px`,
    overflowY: 'auto',
  },
  bodySubHeader: {
    marginTop: theme.spacing(7),
  },
  formGroupValidationErrorState: {
    marginBottom: theme.spacing(3),
  },
  formGroupValidationNoError: {
    marginBottom: theme.spacing(8),
  },
  previewRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(3),
  },
  cellPreviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
  },
  previewContent: {
    borderRadius: 3,
    backgroundColor: 'rgb(138, 155, 168, 0.15)',
    padding: theme.spacing(3),
    alignSelf: 'stretch',
  },
  arrowIcon: {
    margin: `${theme.spacing(6)}px ${theme.spacing(3)}px 0px ${theme.spacing(3)}px`,
    alignSelf: 'center',
  },
  renderPreviewInfo: {
    fontSize: 10,
  },
  dialogFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: theme.palette.white,
    borderRadius: '0px 0px 6px 6px',
    padding: theme.spacing(3),
    borderTop: `1px solid ${theme.palette.grey.border}`,
  },
  saveButton: {
    marginLeft: theme.spacing(4),
  },
}));

interface FormatOption {
  format: string;
  description: string;
  example: string;
}

const FORMAT_OPTIONS: FormatOption[] = [
  {
    format: '%a',
    description: 'National abbreviated weekday name',
    example: 'Mon',
  },
  {
    format: '%A',
    description: 'National full weekday name',
    example: 'Monday',
  },
  {
    format: '%w',
    description: 'Weekday as a decimal number with Sunday as 0 (0-6)',
    example: '5',
  },
  {
    format: '%y',
    description: 'Year, last two digits (00-99)',
    example: '11',
  },
  {
    format: '%Y',
    description: 'Year',
    example: '2016',
  },
  {
    format: '%b',
    description: 'National abbreviated month name',
    example: 'Sep',
  },
  {
    format: '%B',
    description: 'National full month name',
    example: 'September',
  },
  {
    format: '%m',
    description: 'Month as a decimal number (01-12)',
    example: '08',
  },
  {
    format: '%d',
    description: 'Day of the month, zero-padded (01-31)',
    example: '22',
  },
  {
    format: '%e',
    description: 'Day of the month, space-padded (1-31)',
    example: '22',
  },
  {
    format: '%%',
    description: 'The % character.',
    example: '%',
  },
  {
    format: '%D',
    description: 'Short for MM/DD/YY',
    example: '07/30/09',
  },
  {
    format: '%F',
    description: 'Short for YYYY-MM-DD',
    example: '2011-09-22',
  },
  {
    format: '%g',
    description: 'Week-based year, last two digits (00-99)',
    example: '16',
  },
  {
    format: '%G',
    description: 'Week-based year, with century.',
    example: '2016',
  },
  {
    format: '%h',
    description: 'The same as %b.',
    example: 'Sep',
  },
  {
    format: '%H',
    description: 'Hour in 24h format (00-23)',
    example: '16',
  },
  {
    format: '%I',
    description: 'Hour in 12h format (01-12)',
    example: '08',
  },
  {
    format: '%j',
    description: 'Day of the year (001-366)',
    example: '145',
  },
  {
    format: '%M',
    description: 'Minute (00-59)',
    example: '52',
  },
  {
    format: '%n',
    description: "Newline character ('\n')",
    example: ' ',
  },
  {
    format: '%p',
    description: 'AM or PM designation',
    example: 'AM',
  },
  {
    format: '%r',
    description: '12-hour clock time',
    example: '02:55:02 PM',
  },
  {
    format: '%R',
    description: '24-hour HH:MM time, equivalent to %H:%M',
    example: '12:44',
  },
  {
    format: '%S',
    description: 'Second (00-61)',
    example: '06',
  },

  {
    format: '%s',
    description: 'Unix time.',
    example: '1455803239',
  },
  {
    format: '%t',
    description: "Horizontal-tab character ('\t')",
    example: ' ',
  },
  {
    format: '%T',
    description: 'ISO 8601 time format (HH:MM:SS)',
    example: '18:25:34',
  },
  {
    format: '%u',
    description: 'ISO 8601 weekday 1-7 (Monday is 1)',
    example: '6',
  },
  {
    format: '%U',
    description: 'Week number with the first Sunday as the first day of week one (00-53)',
    example: '30',
  },
  {
    format: '%V',
    description: 'ISO 8601 week number (00-53)',
    example: '12',
  },
  {
    format: '%W',
    description: 'Week number with the first Monday as the first day of week one (00-53)',
    example: '50',
  },
  {
    format: '%x',
    description: 'National date representation',
    example: '05/28/11',
  },
  {
    format: '%X',
    description: 'National time representation',
    example: '12:22:02',
  },
  {
    format: '%z',
    description: 'The time zone offset from UTC',
    example: '+0100',
  },
  {
    format: '%Z',
    description: 'Timezone name or abbreviation; if timezone cannot be determined, no characters',
    example: 'CEST',
  },
];

type Props = {
  modalOpen: boolean;
  closeModal: () => void;
  onSubmit: (timestampFormat: string) => void;
  schemaChange?: SchemaChange;
  previewDate: string;
  customDateColumnName: string;
};

const renderDialogFooter = (
  onSubmit: (timestampFormat: string) => void,
  closeModal: () => void,
  isDateInvalid: boolean,
  classes: any,
  userDateFormatInput?: string,
) => {
  return (
    <div className={classes.dialogFooter}>
      <Button intent={Intent.NONE} onClick={() => closeModal()}>
        Cancel
      </Button>
      <Button
        className={classes.saveButton}
        intent={Intent.PRIMARY}
        onClick={() => {
          if (isDateInvalid) {
            AppToaster.show({
              message: `Please enter a valid date format. If problems persist, the content you are formatting may not be a date.`,
              icon: 'error',
              timeout: 7000,
              intent: Intent.DANGER,
            });
          } else {
            onSubmit(userDateFormatInput!);
            closeModal();
          }
        }}>
        Save
      </Button>
    </div>
  );
};

const renderFormatOptions = (classes: any) => {
  return (
    <div className={classes.formatOptionsTable}>
      <div className={classes.formatOptionsHeader}>
        <div>Format</div>
        <div>Description</div>
        <div>Example</div>
      </div>
      <div className={classes.formatOptionsTableRows}>
        {FORMAT_OPTIONS.map(renderFormatOptionRow)}
      </div>
    </div>
  );
};

const renderFormatOptionRow = (formatOption: FormatOption) => {
  return (
    <React.Fragment key={formatOption.format}>
      <div>{formatOption.format}</div>
      <div>{formatOption.description}</div>
      <div>{formatOption.example}</div>
    </React.Fragment>
  );
};

const renderCellPreviewContainer = (
  previewHeader: string,
  previewContent: string,
  classes: any,
) => {
  return (
    <div className={classes.cellPreviewContainer}>
      <H6>{previewHeader}</H6>
      <div className={classes.previewContent}>{previewContent}</div>
    </div>
  );
};

const renderPreviewRow = (previewDate: string, previewDateConverted: string, classes: any) => {
  return (
    <div className={classes.previewRowContainer}>
      {renderCellPreviewContainer('Cell Preview', previewDate, classes)}
      <Icon iconSize={Icon.SIZE_LARGE} className={classes.arrowIcon} icon={'arrow-right'} />
      {renderCellPreviewContainer('Conversion Preview', previewDateConverted, classes)}
    </div>
  );
};

const renderUserFormatInput = (
  isDateInvalid: boolean,
  setUserDateFormatInput: Dispatch<SetStateAction<undefined | string>>,
  classes: any,
  userDateFormatInput?: string,
) => {
  return (
    <FormGroup
      className={
        isDateInvalid ? classes.formGroupValidationErrorState : classes.formGroupValidationNoError
      }
      helperText={isDateInvalid ? 'Please enter a valid date format' : undefined}
      intent={isDateInvalid ? Intent.DANGER : Intent.NONE}
      labelFor="text-input">
      <InputGroup
        type="text"
        intent={isDateInvalid ? Intent.DANGER : Intent.NONE}
        value={userDateFormatInput}
        fill
        placeholder="%m/%d/%y"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setUserDateFormatInput(event.target.value);
        }}
      />
    </FormGroup>
  );
};

const CustomTimestampFormatModal = (props: Props) => {
  const {
    modalOpen,
    closeModal,
    onSubmit,
    schemaChange,
    previewDate,
    customDateColumnName,
  } = props;
  const classes = useStyles();
  const [userDateFormatInput, setUserDateFormatInput] = useState<string | undefined>(
    schemaChange && 'subitem' in schemaChange && schemaChange['subitem']
      ? schemaChange['format']
      : undefined,
  );
  let previewDateConverted: Date | null = null;
  let isDateInvalid = false;

  // strptime library has no error handling
  try {
    previewDateConverted = strptime(previewDate, userDateFormatInput);
  } catch {
    isDateInvalid = true;
  }

  return (
    <Dialog
      className={classes.dialog}
      isOpen={modalOpen}
      onClose={closeModal}
      title="Custom Timestamp Format">
      <div className={classes.dialogBody}>
        <H5>Format Options</H5>
        {renderFormatOptions(classes)}
        <H5 className={classes.bodySubHeader}>{customDateColumnName}</H5>
        {renderUserFormatInput(isDateInvalid, setUserDateFormatInput, classes, userDateFormatInput)}
        {renderPreviewRow(
          previewDate,
          isDateInvalid
            ? 'Invalid Format'
            : // We take the date and convert it to ISO format 2020-06-08T23:09:07Z
              // the T and Z look ugly so we take them out
              previewDateConverted!.toISOString().replace(/([TZ])/g, ' '),
          classes,
        )}
        <div className={classes.renderPreviewInfo}>
          These two dates should match, when they do you're work is done.
        </div>
      </div>
      {renderDialogFooter(onSubmit, closeModal, isDateInvalid, classes, userDateFormatInput)}
    </Dialog>
  );
};

export default CustomTimestampFormatModal;

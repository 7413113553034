/** @format */

import React, { useState } from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { Intent } from '@blueprintjs/core';

import IntegrationDescription from 'explo-ds/typography/marketing/integrationDescription';
import InputField from 'explo-ds/forms/marketing/inputField';
import Button from 'explo-ds/buttons/button';

import { addEmailToWaitlist } from 'utils/landingPageUtils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  integrationDescription: {
    color: theme.palette.grey100,
  },
  signupContainer: {
    marginTop: 100,
    backgroundColor: theme.palette.grey300,
    borderRadius: 12,
    padding: theme.spacing(10),
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '0.3fr 0.1fr 0.35fr 0.25fr',
    gridColumnGap: theme.spacing(5),

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      textAlign: 'center',
    },
  },

  signupInput: {
    backgroundColor: theme.palette.grey200,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
    },
  },

  signupButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

type Props = {
  className?: string;
  calltoActionText: string;
  btnText: string;
  onSignupSuccess: (email: string) => void;
  onSignupFail: (errorMsg: string) => void;
};

const MidPageSignupBanner = (props: Props) => {
  const [signupEmail, setSignupEmail] = useState('');

  const emailEntered = (email: string) => {
    addEmailToWaitlist(
      email,
      () => {
        setSignupEmail('');
        props.onSignupSuccess(signupEmail);
        window.location.href = 'https://form.typeform.com/to/jyPnu9GP';
      },
      (response: any) => {
        props.onSignupFail(response.msg);
      },
      'landing_page',
    );
  };

  const classes = useStyles();
  return (
    <div className={cx(classes.signupContainer, props.className)}>
      <div>
        <IntegrationDescription className={classes.integrationDescription}>
          <b>{props.calltoActionText}</b>
        </IntegrationDescription>
      </div>
      <div></div>
      <InputField
        value={signupEmail}
        onChange={(e: any) => setSignupEmail(e.target.value)}
        className={classes.signupInput}
        placeholder="Enter your email address"
      />
      <Button
        className={classes.signupButton}
        intent={Intent.PRIMARY}
        onClick={() => emailEntered(signupEmail)}
        large>
        {props.btnText}
      </Button>
    </div>
  );
};

export default MidPageSignupBanner;

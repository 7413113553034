/** @format */

import React, { useState } from 'react';
import { Intent, H3 } from '@blueprintjs/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { AppToaster } from 'toaster';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { DataSource, ACTION } from 'actions/types';
import PageListItem from 'components/pageListItem';
import DeleteDataSourceConfirmationModal from 'components/modals/deleteDataSourceConfirmationModal';
import {
  deleteDataSource,
  syncDataSource,
  setSelectedDataSource,
  fetchDataSourceList,
  fetchWorkspaceData,
} from 'actions/dataSourceActions';
import { ROUTES } from 'constants/routes';
import FileUploadButton from 'components/fileSystem/fileUploadButton';
import ConfirmationModal from 'components/modals/confirmationModal';
import FileUploadModal from 'components/fileSystem/fileUploadModal';
import { createNewDatasetSuccess, datasetUploadComplete } from 'actions/fileSystemActions';
import { createLoadingSelector } from 'reducers/api/selectors';
import LoadingSpinner from 'images/loading_spinner.gif';
import { CSV_UPLOADS_DS_NAME, FORMATTED_DATABASE_NAME_DICTIONARY } from 'constants/flowConstants';
import moment from 'moment';
import { DatabaseTypes } from 'components/onboardingSetup/setupFlowDatabaseSelection';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.grey.pageBackground,
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(5)}px ${theme.spacing(6)}px`,
    overflowY: 'auto',
  },
  loadingBody: {
    height: '75%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingSpinner: {
    width: 75,
  },
  navBarButton: {
    color: theme.palette.white,
  },
  dataSourceListContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  dataSourceItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.white,
    borderRadius: 8,
    padding: `${theme.spacing(4)}px 0px`,
    marginBottom: theme.spacing(4),
  },
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(9),
  },
  itemHeading: {
    marginLeft: theme.spacing(4),
  },
  connectDataButton: {
    height: theme.spacing(10),
    width: 170,
    backgroundColor: theme.palette.exploBlue,
    color: theme.palette.white,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    marginLeft: theme.spacing(6),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dataSourceListActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  uploadCSVButton: {
    backgroundColor: `${theme.palette.grey.slight} !important`,
    borderRadius: 3,
    width: 115,
    height: theme.spacing(8),
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  headerText: {
    fontWeight: 500,
    fontSize: `24px !important`,
    marginBottom: 0,
  },
}));

type Props = ReturnType<typeof mapStateToProps> & RouteComponentProps & typeof mapDispatchToProps;

const syncDataSourceOnSuccess = (data: any) => {
  AppToaster.show({
    message: `Data Source successfully synced ${data.new_datasets.length} new datasets and updated ${data.updated_datasets.length} datasets.`,
    icon: 'updated',
    timeout: 5000,
    intent: Intent.SUCCESS,
  });
};

const renderDataSource = (
  dataSource: DataSource,
  props: Props,
  classes: any,
  setDeleteConfirmationModalOpen: any,
  setActionableDataSource: any,
) => {
  const { syncDataSource, history } = props;
  return (
    <PageListItem
      key={dataSource.id}
      title={dataSource.name}
      subTitle={
        dataSource.source_type &&
        FORMATTED_DATABASE_NAME_DICTIONARY[dataSource.source_type as DatabaseTypes]
      }
      secondText={`${dataSource.dataset_count.toString()} tables`}
      thirdText={`Connected ${moment(dataSource.created).fromNow()}`}
      loading={dataSource.loading}
      onClick={() => {
        setSelectedDataSource(dataSource);
        history.push(`/datasets-view-page/${dataSource.id}`);
      }}
      disableActionsMenu={dataSource.name === CSV_UPLOADS_DS_NAME}
      itemActions={[
        {
          text: 'Delete Data Source',
          onClick: () => {
            if (!dataSource.explo_source) {
              setDeleteConfirmationModalOpen(true);
              setActionableDataSource(dataSource);
            }
          },
        },
        {
          text: 'Sync New Tables',
          onClick: () => {
            if (!dataSource.explo_source) {
              syncDataSource(
                {
                  id: dataSource.id,
                },
                syncDataSourceOnSuccess,
              );
              setActionableDataSource(dataSource);
            }
          },
        },
      ]}
    />
  );
};

const renderDataSourceList = (
  props: Props,
  classes: any,
  setDeleteConfirmationModalOpen: any,
  setActionableDataSource: any,
) => {
  const { dataSourceList, dataSourceListLoading } = props;
  if (dataSourceListLoading) {
    return (
      <div className={classes.loadingBody}>
        <img className={classes.loadingSpinner} src={LoadingSpinner} alt="loading spinner" />
      </div>
    );
  }
  return (
    <div className={classes.dataSourceListContainer}>
      {dataSourceList.map((dataSource: DataSource) =>
        renderDataSource(
          dataSource,
          props,
          classes,
          setDeleteConfirmationModalOpen,
          setActionableDataSource,
        ),
      )}
    </div>
  );
};

const renderDeleteDataSourceConfirmationModal = (
  props: Props,
  deleteConfirmationModalOpen: boolean,
  setDeleteConfirmationModalOpen: any,
  actionableDataSource?: DataSource,
) => {
  if (!deleteConfirmationModalOpen || !actionableDataSource) {
    return;
  }
  const { deleteDataSource } = props;
  return (
    <DeleteDataSourceConfirmationModal
      isOpen={deleteConfirmationModalOpen}
      onClose={() => setDeleteConfirmationModalOpen(false)}
      deleteDataSource={() => {
        deleteDataSource({
          id: actionableDataSource.id,
        });
      }}
    />
  );
};

const onFileUpload = (
  file: File,
  setUploadFile: any,
  setUploadFileWarningOpen: any,
  setUploadFileModalOpen: any,
) => {
  if (file.name.split('.').pop() !== 'csv') {
    setUploadFileWarningOpen(true);
  } else {
    setUploadFileModalOpen(true);
  }
  setUploadFile(file);
};

const renderFileUploadWarning = (
  uploadFileWarningOpen: boolean,
  setUploadFileWarningOpen: any,
  setUploadFileModalOpen: any,
) => {
  if (!uploadFileWarningOpen) return;

  return (
    <ConfirmationModal
      modalOpen={uploadFileWarningOpen}
      closeModal={() => setUploadFileWarningOpen(false)}
      modalTitle="Warning"
      modalText="The File you are uploading might not be a supported format."
      submitBtnText="Continue"
      danger
      onSubmit={() => {
        setUploadFileWarningOpen(false);
        setUploadFileModalOpen(true);
      }}
    />
  );
};

const renderUploadFileModal = (
  props: Props,
  uploadFileModalOpen: boolean,
  setUploadFileModalOpen: any,
  uploadFile?: File,
) => {
  const { currentUser, createNewDatasetSuccess, datasetUploadComplete } = props;

  if (!uploadFileModalOpen) {
    return;
  }

  return (
    <FileUploadModal
      file={uploadFile!}
      isOpen={uploadFileModalOpen}
      onModalClose={() => setUploadFileModalOpen(false)}
      currentFolderId={currentUser.root_folder_id!}
      createNewDatasetSuccess={createNewDatasetSuccess}
      datasetUploadComplete={datasetUploadComplete}
    />
  );
};

const DataSourceListPage = (props: Props) => {
  const classes = useStyles();
  const { history } = props;
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [actionableDataSource, setActionableDataSource] = useState(undefined);
  const [uploadFile, setUploadFile] = useState(undefined);
  const [uploadFileWarningOpen, setUploadFileWarningOpen] = useState(false);
  const [uploadFileModalOpen, setUploadFileModalOpen] = useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.pageHeader}>
        <H3 className={classes.headerText}>Data</H3>
        <div className={classes.dataSourceListActions}>
          <FileUploadButton
            buttonText="Upload a CSV"
            className={classes.uploadCSVButton}
            minimal
            uploadFile={(file: File) =>
              onFileUpload(file, setUploadFile, setUploadFileWarningOpen, setUploadFileModalOpen)
            }
          />
          <div
            className={classes.connectDataButton}
            onClick={() => {
              history.push(ROUTES.ADD_DATA_SOURCE);
            }}>
            Connect a Database
          </div>
        </div>
      </div>
      {renderDataSourceList(
        props,
        classes,
        setDeleteConfirmationModalOpen,
        setActionableDataSource,
      )}
      {renderDeleteDataSourceConfirmationModal(
        props,
        deleteConfirmationModalOpen,
        setDeleteConfirmationModalOpen,
        actionableDataSource,
      )}
      {renderFileUploadWarning(
        uploadFileWarningOpen,
        setUploadFileWarningOpen,
        setUploadFileModalOpen,
      )}
      {renderUploadFileModal(props, uploadFileModalOpen, setUploadFileModalOpen, uploadFile)}
    </div>
  );
};

const dataSourceListLoadingSelector = createLoadingSelector([ACTION.FETCH_DATA_SOURCE_LIST]);
const mapStateToProps = (state: ReduxState) => ({
  dataSourceList: state.dataSourceList,
  workspaceData: state.workspaceData,
  currentUser: state.currentUser,
  datasourceDictionary: state.workspaceData.datasourceDictionary,
  dataSourceListLoading: dataSourceListLoadingSelector(state),
});

const mapDispatchToProps = {
  deleteDataSource,
  syncDataSource,
  createNewDatasetSuccess,
  datasetUploadComplete,
  setSelectedDataSource,
  fetchDataSourceList,
  fetchWorkspaceData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataSourceListPage));

/** @format */

import React, { useRef, useContext } from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import TransformOperationNode from 'pages/flowGraphPage/transformOperationNode';
import GraphNode from 'pages/flowGraphPage/graphNode';
import FlowSourceDataView from 'pages/flowGraphPage/flowSourceDataView';
import { GRAPH_OPERATIONS_SET } from 'constants/flowConstants';
import { makeStyles } from '@material-ui/core/index';
import { Operation, DatasetFlowNode } from 'actions/types';
import FlowGraphAddStepButton from 'pages/flowGraphPage/flowGraphAddStepButton';
import {
  isDatasetFlowNode,
  useOutsideAlerter,
  joinFlowGraphNodes,
} from 'pages/flowGraphPage/flowGraphPageUtils';
import cx from 'classnames';
import { NODE_TYPES, SelectedNode } from 'pages/flowGraphPage/flowGraphPageV3';
import { FlowGraphApi } from 'pages/flowGraphPage/flowGraphPageContext';
import { BACKGROUND_CANVAS } from 'pages/flowGraphPage/flowGraphContent';

export const NODE_WIDTH = 800;
export const VERTICAL_NODE_MARGIN = 60;
export const HORIZONTAL_NODE_MARGIN = 80;
export const CONNECTOR_LINE_THICKNESS = 4;
export const FLOW_NODE_BORDER_RADIUS = 8;

const useStyles = makeStyles((theme: Theme) => ({
  flowNodeRoot: {
    display: 'flex',
    alignSelf: 'stretch',
    justifyContent: 'center',
    position: 'relative',
    margin: `${VERTICAL_NODE_MARGIN / 2}px ${HORIZONTAL_NODE_MARGIN / 2}px`,
  },
  datasetFlowNodeTopMargin: {
    marginTop: theme.spacing(2),
  },
  nodeContainer: {
    width: NODE_WIDTH,
    borderRadius: FLOW_NODE_BORDER_RADIUS,
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.grey.lightBorder}`,
    backgroundColor: theme.palette.white,
  },
  errorBorder: {
    border: `1px solid ${theme.palette.dangerRed}`,
  },
  nodeContainerSelected: {
    borderColor: theme.palette.darkBlue,
  },
  connectorLine: {
    position: 'absolute',
    border: `${CONNECTOR_LINE_THICKNESS}px solid ${theme.palette.darkBlue}`,
  },
  verticalConnector: {
    height: VERTICAL_NODE_MARGIN / 2,
    left: `calc(50% - ${CONNECTOR_LINE_THICKNESS / 2}px)`,
  },
  topConnect: {
    top: -VERTICAL_NODE_MARGIN / 2,
  },
  bottomConnect: {
    bottom: -VERTICAL_NODE_MARGIN / 2,
  },
  horizontalConnect: {
    bottom: -VERTICAL_NODE_MARGIN / 2,
  },
  addStepModal: {
    maxWidth: 1100,
    width: '80%',
    padding: 0,
  },
  topConnectorDot: {
    position: 'absolute',
    backgroundColor: theme.palette.darkBlue,
    left: `calc(50% - 6px)`,
    top: -8,
    height: 16,
    width: 16,
    borderRadius: '50%',
  },
}));

type PassedProps = {
  node: Operation | DatasetFlowNode;
  isChildNode: boolean;
  childNodeCount: number;
  isSelected: boolean;
  selectedNode?: SelectedNode;
  addDatasetModalOpen: boolean;
  addStepModalOpen: boolean;
};

type Props = PassedProps;

const renderNode = (node: Operation | DatasetFlowNode, props: Props) => {
  const { addDatasetModalOpen, addStepModalOpen, isSelected } = props;
  if (isDatasetFlowNode(node)) {
    const { dataset } = node;

    return (
      <FlowSourceDataView
        previewData={dataset.cached_preview}
        schema={dataset.cached_schema!}
        datasetName={dataset.name || dataset.data_source_table_name}
        sourceDataRowCount={dataset && dataset.total_row_count!}
        datasetFlowNodeId={node.id}
        isSelected={isSelected}
        addDatasetModalOpen={addDatasetModalOpen}
        addStepModalOpen={addStepModalOpen}
        rowError={node.rowError}
      />
    );
  } else {
    node = node as Operation;
    if (GRAPH_OPERATIONS_SET.has(node.operation_type as string)) {
      return (
        <GraphNode
          resultsJobError={node.results_job_error}
          operation={node}
          operationType={node.operation_type}
          operationInstructions={node.instructions}
          // the price we pay for using both null and undefined in our code base
          graphData={
            node.results_dataset
              ? node.results_dataset.cached_preview
                ? node.results_dataset.cached_preview
                : undefined
              : undefined
          }
          resultSchema={
            node.results_dataset
              ? node.results_dataset.cached_schema
                ? node.results_dataset.cached_schema
                : undefined
              : undefined
          }
          addDatasetModalOpen={addDatasetModalOpen}
          addStepModalOpen={addStepModalOpen}
          isSelected={isSelected}
          sourceDataset={node.sourceDataset}
        />
      );
    }

    return (
      <TransformOperationNode
        operation={node}
        operationType={node.operation_type as string}
        instructions={node.instructions}
        dataset={node.results_dataset}
        computeLoading={node.compute_loading}
        operationInstructions={node.instructions}
        sourceDataset={node.sourceDataset}
        addDatasetModalOpen={addDatasetModalOpen}
        addStepModalOpen={addStepModalOpen}
        isSelected={isSelected}
      />
    );
  }
};

const renderTopConnector = (classes: ReturnType<typeof useStyles>, isChildNode: boolean) => {
  if (isChildNode) return;
  return (
    <>
      <div
        id={BACKGROUND_CANVAS}
        className={cx(classes.connectorLine, classes.verticalConnector, classes.topConnect)}></div>
      <div className={classes.topConnectorDot}></div>
    </>
  );
};

const renderBottomConnector = (classes: ReturnType<typeof useStyles>, isLeafNode: boolean) => {
  if (isLeafNode) return;
  return (
    <div
      id={BACKGROUND_CANVAS}
      className={cx(classes.connectorLine, classes.verticalConnector, classes.bottomConnect)}></div>
  );
};

const FlowNodeV3 = (props: Props) => {
  const classes = useStyles();
  const { node, isChildNode, childNodeCount, isSelected, selectedNode } = props;
  const { setSelectedNode, openAddStepModal, addFlowStep } = useContext(FlowGraphApi)!;
  const newSelectedNode: SelectedNode = {
    id: node.id,
    type: isDatasetFlowNode(node) ? NODE_TYPES.DATASET_FLOW_NODE : NODE_TYPES.OPERATION,
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setSelectedNode);

  return (
    <div
      id={BACKGROUND_CANVAS}
      className={cx(classes.flowNodeRoot, {
        [classes.datasetFlowNodeTopMargin]: isDatasetFlowNode(node),
      })}>
      <div
        ref={wrapperRef}
        className={cx(classes.nodeContainer, {
          [classes.nodeContainerSelected]: isSelected,
          [classes.errorBorder]: isDatasetFlowNode(node)
            ? node.dataset.results_job_error
            : node.results_job_error,
        })}
        onClick={(e: any) => {
          if (!isSelected && e.shiftKey) {
            joinFlowGraphNodes(node, addFlowStep, selectedNode);
            e.stopPropagation();
          } else {
            setSelectedNode(newSelectedNode);
          }
        }}>
        {renderNode(node, props)}
      </div>
      <FlowGraphAddStepButton
        openAddStepModal={() => {
          setSelectedNode(newSelectedNode);
          openAddStepModal();
        }}
      />
      {renderTopConnector(classes, isChildNode)}
      {renderBottomConnector(classes, childNodeCount === 0)}
    </div>
  );
};

export default FlowNodeV3;

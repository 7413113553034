/** @format */

import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { withStyles, WithStyles } from '@material-ui/styles';
import { PreviewData } from 'actions/types';
import FileUploadPanel from 'components/fileSystem/fileUploadPanel';

const styles = (theme: Theme) =>
  createStyles({
    dialogPanel: {
      height: '90vh',
      width: '90vw',
      paddingBottom: 0,
    },
  });

type PassedProps = {
  file: File;
  isOpen: boolean;
  onModalClose: () => void;
  currentFolderId: number;
  createNewDatasetSuccess: (resourceData: any, workspaceName: string) => void;
  datasetUploadComplete: (datasetId: number, previewData: PreviewData) => void;
  existingUploadedCSVNames?: string[];
};

type Props = PassedProps & WithStyles<typeof styles>;

class FileUploadModal extends React.Component<Props> {
  render() {
    const {
      onModalClose,
      isOpen,
      classes,
      file,
      currentFolderId,
      createNewDatasetSuccess,
      datasetUploadComplete,
      existingUploadedCSVNames,
    } = this.props;
    return (
      <Dialog
        className={classes.dialogPanel}
        isOpen={isOpen}
        onClose={onModalClose}
        icon="inbox"
        title="CSV File Upload">
        <FileUploadPanel
          file={file}
          onCancel={onModalClose}
          onSave={onModalClose}
          currentFolderId={currentFolderId}
          createNewDatasetSuccess={createNewDatasetSuccess}
          datasetUploadComplete={datasetUploadComplete}
          existingUploadedCSVNames={existingUploadedCSVNames}
        />
      </Dialog>
    );
  }
}

export default withStyles(styles)(FileUploadModal);

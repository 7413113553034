/** @format */

export const RESOURCE_TYPES = {
  FOLDER: 'folder',
  FLOW: 'flow',
  EXPLORATION: 'exploration',
  DATASET: 'dataset',
  DASHBOARD: 'dashboard',
};

export const ROOT_FOLDER_NAME = 'My Files';

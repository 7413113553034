/** @format */

import { defineAction } from 'actions/actionUtils';
import { ACTION } from 'actions/types';

export const fetchDashboardList = defineAction(
  ACTION.FETCH_DASHBOARD_LIST,
  'dashboards',
  '',
  'GET',
);

export const fetchDashboard = defineAction(ACTION.FETCH_DASHBOARD, 'dashboards', '', 'GET');

export const deleteDashboard = defineAction(ACTION.DELETE_DASHBOARD, 'fs/resource', '', 'DELETE');

export const renameDashboard = defineAction(
  ACTION.RENAME_DASHBOARD,
  'fs/resource',
  'rename',
  'POST',
);

export const updateDashboardLayout = defineAction(
  ACTION.UPDATE_DASHBOARD_LAYOUT,
  'dashboards',
  '',
  'PUT',
);

export const addOperationToDashboard = defineAction(
  ACTION.ADD_OPERATION_TO_DASHBOARD,
  'dashboards',
  'add_operation',
  'POST',
);

export const removeOperationFromDashboard = defineAction(
  ACTION.REMOVE_OPERATION_FROM_DASHBOARD,
  'dashboards',
  'remove_operation',
  'DELETE',
);

export const createDashboard = defineAction(ACTION.CREATE_DASHBOARD, 'dashboards', '', 'POST');

export const updateOperationTitle = defineAction(
  ACTION.UPDATE_OPERATION_TITLE,
  'dashboards',
  'update_operation_title',
  'POST',
);

export const refreshDashboardOperation = defineAction(
  'REFRESH_DASHBOARD_OPERATION',
  'operations',
  'refresh',
  'POST',
);

export const refreshDashboard = defineAction('REFRESH_DASHBOARD', 'dashboards', 'refresh', 'POST');

export const getDashboardShareHash = defineAction(
  'GET_DASHBOARD_SHARE_HASH',
  'dashboards',
  'get_share_hash',
  'GET',
);

export const createDashboardFilter = defineAction(
  'CREATE_DASHBOARD_FILTER',
  'dashboards',
  'create_filter',
  'POST',
);

export const deleteDashboardFilter = defineAction(
  'DELETE_DASHBOARD_FILTER',
  'dashboards',
  'delete_filter',
  'DELETE',
);

export const updateDashboardFilterField = defineAction(
  'UPDATE_DASHBOARD_FILTER_FIELD',
  'dashboards',
  'update_filter_field',
  'POST',
);

export const updateFilterValue = (filterId: number, newValue: string) => ({
  type: 'UPDATE_FILTER_VALUE',
  payload: {
    filterId,
    newValue,
  },
});

export const updateFilterOperator = (filterId: number, newOperator: any) => ({
  type: 'UPDATE_FILTER_OPERATOR',
  payload: {
    filterId,
    newOperator,
  },
});

export const setProjectsIsCollapsed = (projectsIsCollapsed: boolean[]) => ({
  type: 'UPDATE_PROJECTS_IS_COLLAPSED',
  payload: {
    projectsIsCollapsed,
  },
});

export const fetchOperationSourceColumns = defineAction(
  'FETCH_DASHBOARD_SOURCE_COLUMNS',
  'dashboards',
  'operation_source_dataset_columns',
  'POST',
);

export const fetchDashboardOperationRowCount = defineAction(
  'FETCH_DASHBOARD_OPERATION_ROW_COUNT',
  'operations',
  'dashboard_row_count',
  'POST',
);

/** @format */

import React from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import { BP_COLORS } from 'constants/colorConstants';
import { DataSource } from 'actions/types';
import { deleteDataSource, syncDataSource } from 'actions/dataSourceActions';
import DeleteDataSourceConfirmationModal from 'components/modals/deleteDataSourceConfirmationModal';
import { AppToaster } from 'toaster';
import { Intent, Spinner } from '@blueprintjs/core';

import {
  Icon,
  Popover,
  Button,
  Menu,
  MenuItem,
  Position,
  Tooltip,
  IconName,
} from '@blueprintjs/core';

const styles = (theme: Theme) => ({
  root: {},
  sectionMenuSelected: {
    backgroundColor: 'rgba(255,255,255,.16)',
  },
  menuItemTextContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  datasourcesMenu: {
    visibility: 'hidden' as 'hidden',
  },
  sectionMenuIcon: {
    width: 10,
    height: 10,
    borderRadius: 2,
    marginRight: theme.spacing(2),
  },
  sectionMenuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '89%',
  },
  menuItemTooltipFirstDummy: {
    display: 'none',
  },
  menuItemTooltips: {
    display: 'block !important',
  },
  regularIcon: {
    marginRight: 7,
    marginTop: 2,
    color: `${theme.palette.grey.blueprintGrey} !important`,
  },
  disabledIcon: {
    marginRight: 7,
    marginTop: 2,
    color: `${theme.palette.grey.disabledText} !important`,
  },
  disabledMenuItem: {
    cursor: 'not-allowed',
    color: `${theme.palette.grey.disabledText} !important`,
    background: 'none',
    '&:hover': {
      backgroundColor: theme.palette.white,
      color: `${theme.palette.grey.disabledText} !important`,
      cursor: 'not-allowed',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  datasourceName: {
    width: 'calc(100% - 18px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

type PassedProps = {
  className?: string;
  dataSource: DataSource;
  selectedDataSourceId: number | null;
  switchWorkspaces: (dataSourceId: number) => void;
  dataSourceList: DataSource[];
};

type State = {
  mouseHover: boolean;
  actionsMenuOpen: boolean;
  deleteConfirmationModalOpen: boolean;
};

type Props = PassedProps &
  WithStyles<typeof styles> &
  RouteComponentProps &
  typeof mapDispatchToProps;

class DataSourceMenuItem extends React.Component<Props, State> {
  state: State = {
    mouseHover: false,
    actionsMenuOpen: false,
    deleteConfirmationModalOpen: false,
  };

  render() {
    return (
      <>
        {this.renderDeleteDataSourceConfirmationModal()}
        {this.renderMenuItem()}
      </>
    );
  }

  renderMenuItem = () => {
    const { classes, selectedDataSourceId, dataSource, switchWorkspaces } = this.props;

    return (
      <MenuItem
        className={cx({ [classes.sectionMenuSelected]: selectedDataSourceId === dataSource.id })}
        key={`data-source-menu-${dataSource.id}`}
        onClick={() => {
          this.props.history.push(`/datasources/${dataSource.id}`);
          switchWorkspaces(dataSource.id);
        }}
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.onMouseOut}
        text={this.renderMenuItemText()}
      />
    );
  };

  renderMenuItemText = () => {
    const { classes, dataSource } = this.props;

    return (
      <div className={classes.menuItemTextContainer}>
        {this.renderDataSourceName()}
        {dataSource.loading ? (
          <Spinner intent={Intent.PRIMARY} size={20} />
        ) : (
          this.renderDataSourceActionsMenu()
        )}
      </div>
    );
  };

  renderDeleteDataSourceConfirmationModal = () => {
    const { deleteConfirmationModalOpen } = this.state;

    if (!deleteConfirmationModalOpen) {
      return;
    }
    return (
      <DeleteDataSourceConfirmationModal
        isOpen={deleteConfirmationModalOpen}
        onClose={() => {
          this.setState({ deleteConfirmationModalOpen: false });
        }}
        deleteDataSource={this.deleteDataSource}
      />
    );
  };

  renderDataSourceName = () => {
    const { classes, dataSource } = this.props;

    return (
      <div className={classes.sectionMenuItem}>
        <span
          className={classes.sectionMenuIcon}
          style={{ backgroundColor: this.getDataSourceColor(dataSource) }}
        />
        <div className={classes.datasourceName}>{dataSource.name}</div>
      </div>
    );
  };

  renderDataSourceActionsMenu = () => {
    const { classes } = this.props;
    const { mouseHover, actionsMenuOpen } = this.state;

    return (
      <Popover
        hoverCloseDelay={0}
        inheritDarkTheme={false}
        disabled={!mouseHover}
        isOpen={actionsMenuOpen}
        onClose={() => this.setState({ actionsMenuOpen: false })}
        position={Position.BOTTOM}>
        <Button
          className={cx({ [classes.datasourcesMenu]: !mouseHover })}
          icon="more"
          minimal
          small
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ actionsMenuOpen: !actionsMenuOpen });
          }}
        />
        <Menu>
          {/* Tooltips are not supported in MenuItems (https://github.com/palantir/blueprint/issues/926).  Having a demo initial ToolTip fixes the bugs. */}
          <Tooltip content={''} className={classes.menuItemTooltipFirstDummy}>
            ''
          </Tooltip>
          {this.renderTooltipWrappedMenuItem(
            'Can not delete an Explo provided data source.',
            'Delete Data Source',
            'trash',
            this.onMenuItemDeleteClicked,
          )}
          {this.renderTooltipWrappedMenuItem(
            'Can not sync new tables for CSV Uploads.',
            'Sync New Tables',
            'refresh',
            this.onMenuItemSyncClicked,
          )}
        </Menu>
      </Popover>
    );
  };

  renderTooltipWrappedMenuItem = (
    tooltipContent: string,
    menuItemAction: string,
    actionIcon: string,
    onClickFunction: () => void,
  ) => {
    const { classes, dataSource } = this.props;

    return (
      <Tooltip
        targetClassName={classes.menuItemTooltips}
        content={dataSource.explo_source ? tooltipContent : ''}
        position={Position.RIGHT}>
        <MenuItem
          text={
            <>
              <Icon
                icon={actionIcon as IconName}
                className={dataSource.explo_source ? classes.disabledIcon : classes.regularIcon}
              />
              {menuItemAction}
            </>
          }
          className={dataSource.explo_source ? classes.disabledMenuItem : ''}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            onClickFunction();
          }}
        />
      </Tooltip>
    );
  };

  deleteDataSource = () => {
    const { deleteDataSource, dataSource } = this.props;
    const args = {
      id: dataSource.id,
    };
    deleteDataSource(args, this.deleteDataSourceSuccess);
  };

  deleteDataSourceSuccess = () => {
    const { dataSourceList, switchWorkspaces, dataSource } = this.props;

    const newDataSourceList = dataSourceList.filter((ds: DataSource) => ds.id !== dataSource.id);

    const currentDataSource = newDataSourceList[0];
    if (currentDataSource) {
      this.props.history.push(`/datasources/${currentDataSource.id}`);
      switchWorkspaces(currentDataSource.id);
    } else {
      this.props.history.push(`/add-data-source`);
    }
  };

  getDataSourceColor = (dataSource: DataSource) => {
    const colorIndex = dataSource.id % BP_COLORS.length;
    return BP_COLORS[colorIndex].primary;
  };

  onMenuItemDeleteClicked = () => {
    const { dataSource } = this.props;
    const { actionsMenuOpen } = this.state;
    if (!dataSource.explo_source) {
      this.setState({ deleteConfirmationModalOpen: true, actionsMenuOpen: !actionsMenuOpen });
    }
  };

  onMenuItemSyncClicked = () => {
    const { dataSource, syncDataSource } = this.props;
    const { actionsMenuOpen } = this.state;

    if (!dataSource.explo_source) {
      const args = {
        id: dataSource.id,
      };
      syncDataSource(args, this.syncDataSourceOnSuccess);
      this.setState({ actionsMenuOpen: !actionsMenuOpen });
    }
  };

  syncDataSourceOnSuccess = (data: any) => {
    AppToaster.show({
      message: `Data Source successfully synced ${data.new_datasets.length} new datasets and updated ${data.updated_datasets.length} datasets.`,
      icon: 'updated',
      timeout: 5000,
      intent: Intent.SUCCESS,
    });
  };

  onMouseOver = () => {
    const { mouseHover } = this.state;
    !mouseHover && this.setState({ mouseHover: true });
  };

  onMouseOut = () => {
    const { mouseHover } = this.state;
    mouseHover && this.setState({ mouseHover: false });
  };
}

const mapDispatchToProps = {
  deleteDataSource,
  syncDataSource,
};

export default withRouter(
  connect(undefined, mapDispatchToProps)(withStyles(styles)(DataSourceMenuItem)),
);

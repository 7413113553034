/** @format */

import React from 'react';
import cx from 'classnames';
import _ from 'underscore';
import moment from 'moment';
import { withStyles, WithStyles } from '@material-ui/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { H4, Icon, Button } from '@blueprintjs/core';

import { FlowInfo } from 'actions/types';

import FlowPanel from 'components/dataSource/flowPanel';
import CreateFlowPanel from 'components/dataSource/createFlowPanel';

import { SortOptionType, SORT_OPTIONS_BY_ID } from 'constants/flowConstants';

import Blank from 'images/flow_visualizations/blank.png';
import Dataset from 'images/flow_visualizations/dataset.png';
import Step1 from 'images/flow_visualizations/1_step.png';
import Step2 from 'images/flow_visualizations/2_steps.png';
import Step3Plus from 'images/flow_visualizations/3_plus_steps.png';
import Graph1 from 'images/flow_visualizations/1_graph.png';
import Graph2 from 'images/flow_visualizations/2_graphs.png';
import Graph3Plus from 'images/flow_visualizations/3_plus_graphs.png';
import Strand2 from 'images/flow_visualizations/2_strands.png';
import Strand2Graph from 'images/flow_visualizations/2_strands_graph.png';
import Strand3Plus from 'images/flow_visualizations/3_plus_strands.png';
import Strand3PlusGraph from 'images/flow_visualizations/3_plus_strands_graph.png';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    sectionHeader: {
      marginBottom: theme.spacing(5),
      display: 'flex',
      alignItems: 'center',
    },
    caretIcon: {
      marginRight: theme.spacing(2),
    },
    shareIcon: {
      marginLeft: theme.spacing(3),
    },
    flowsContainer: {
      display: 'grid',
      gridTemplateColumns:
        'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)',
      gridRowGap: theme.spacing(10),
      gridColumnGap: theme.spacing(10),
    },
  });

type PassedProps = {
  className?: string;
  flows: FlowInfo[];
  title: string;
  isExpanded: boolean;
  toggleExpanded: () => void;
  isPersonalFlows?: boolean;
  selectedFlowsSort: SortOptionType;
  onRenameClicked: (flow: FlowInfo) => void;
  onMoveClicked: (flow: FlowInfo) => void;
  onDeleteClicked: (flow: FlowInfo) => void;
  openNewFlowModal: () => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

class FlowsSection extends React.PureComponent<Props> {
  render() {
    const { classes, className } = this.props;
    return (
      <div className={cx(classes.root, className)}>
        {this.renderHeader()}
        {this.renderFlows()}
      </div>
    );
  }

  renderHeader = () => {
    const { classes, isExpanded, title, toggleExpanded, isPersonalFlows } = this.props;
    return (
      <H4 className={classes.sectionHeader}>
        <Button
          className={classes.caretIcon}
          icon={<Icon icon={isExpanded ? 'caret-down' : 'caret-right'} iconSize={18} />}
          minimal
          onClick={toggleExpanded}
        />
        {title}
        <Icon
          className={classes.shareIcon}
          iconSize={18}
          icon={isPersonalFlows ? 'person' : 'people'}
        />
      </H4>
    );
  };

  renderFlows = () => {
    const {
      classes,
      flows,
      isPersonalFlows,
      onRenameClicked,
      onDeleteClicked,
      onMoveClicked,
      isExpanded,
      openNewFlowModal,
    } = this.props;

    if (!isExpanded) {
      return;
    }

    const orderedFlows = _.sortBy(flows, this.getFlowSortByType);
    orderedFlows.reverse();

    return (
      <div className={classes.flowsContainer}>
        {orderedFlows.map((flow, index) => (
          <FlowPanel
            name={flow.name}
            id={flow.id}
            key={`data-source-flow-${flow.id}-${index}`}
            onRenameClicked={() => onRenameClicked(flow)}
            onMoveClicked={() => onMoveClicked(flow)}
            onDeleteClicked={() => onDeleteClicked(flow)}
            isPersonalFlowPanel={!!isPersonalFlows}
            extraInfoText={this.getExtraInfoText(flow)}
            flowVizImg={this.getFlowVizImg(flow)}
          />
        ))}
        <CreateFlowPanel openNewFlowModal={openNewFlowModal} />
      </div>
    );
  };

  getFlowSortByType = (flow: FlowInfo) => {
    const { selectedFlowsSort } = this.props;

    switch (selectedFlowsSort.id) {
      case SORT_OPTIONS_BY_ID.DATE_CREATED.id:
        return moment(flow.created);
      case SORT_OPTIONS_BY_ID.LAST_EDITED.id:
        return moment(flow.modified);
      default:
        throw new Error('Invalid flow sort option selected: ' + selectedFlowsSort.id);
    }
  };

  getExtraInfoText = (flow: FlowInfo) => {
    const { selectedFlowsSort } = this.props;

    switch (selectedFlowsSort.id) {
      case SORT_OPTIONS_BY_ID.DATE_CREATED.id:
        const createdAt = moment(flow.created);
        return `Created ${createdAt.fromNow()}`;
      case SORT_OPTIONS_BY_ID.LAST_EDITED.id:
        const modifiedAt = moment(flow.modified);
        return `Last edited ${modifiedAt.fromNow()}`;
      default:
        throw new Error('Invalid flow sort option selected: ' + selectedFlowsSort.id);
    }
  };

  getFlowVizImg = (flow: FlowInfo) => {
    const { num_datasets, num_graph_ops, num_total_ops } = flow;

    if (num_datasets === 0) {
      return Blank;
    } else if (num_datasets === 1) {
      if (num_total_ops === 0) {
        return Dataset;
      }

      if (num_graph_ops === 0) {
        if (num_total_ops === 1) {
          return Step1;
        } else if (num_total_ops === 2) {
          return Step2;
        } else if (num_total_ops >= 3) {
          return Step3Plus;
        }
      } else {
        if (num_total_ops < 4) {
          return Graph1;
        } else if (num_total_ops < 8) {
          if (num_graph_ops === 1) {
            return Graph1;
          }
          return Graph2;
        } else {
          if (num_graph_ops === 1) {
            return Graph1;
          } else if (num_graph_ops === 2) {
            return Graph2;
          }
          return Graph3Plus;
        }
      }
    } else if (num_datasets === 2) {
      if (num_graph_ops >= 1) {
        return Strand2Graph;
      } else {
        return Strand2;
      }
    } else {
      if (num_graph_ops >= 1) {
        return Strand3PlusGraph;
      } else {
        return Strand3Plus;
      }
    }

    return Blank;
  };
}

export default withStyles(styles)(FlowsSection);

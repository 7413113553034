/** @format */

import React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import ShareLeftNav from 'components/pages/shareLeftNav';

const styles = (theme: Theme) => ({
  root: {
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
  body: {
    backgroundColor: theme.palette.primary.veryLight,
    height: '100vh',
    width: 'calc(100vw - 250px)',
    overflow: 'auto',
  },
});

type PassedProps = {
  children: React.ReactNode;
};

type Props = PassedProps & WithStyles<typeof styles>;

export default withStyles(styles)(({ classes, children }: Props) => (
  <div className={classes.root}>
    <ShareLeftNav></ShareLeftNav>
    <div className={classes.body}>{children}</div>
  </div>
));

/** @format */

import React, { useState } from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { Intent } from '@blueprintjs/core';

import InputField from 'explo-ds/forms/marketing/inputField';
import Button from 'explo-ds/buttons/button';

import { addEmailToWaitlist } from 'utils/landingPageUtils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.grey300,
    borderRadius: 12,
    padding: theme.spacing(10),
  },
  title: {
    fontSize: 40,
    fontWeight: 'bold',
    color: theme.palette.white,
  },
  description: {
    fontSize: 18,
    lineHeight: '24px',
    color: theme.palette.grey100,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
    maxWidth: '75%',
  },
  signupInputContainer: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  signupInput: {
    width: 400,
    marginRight: theme.spacing(5),
    backgroundColor: theme.palette.grey200,

    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: theme.spacing(3),
      width: '100%',
      textAlign: 'center',
    },
  },

  signupButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

type Props = {
  className?: string;
  onSignupSuccess: (email: string) => void;
  onSignupFail: (errorMsg: string) => void;
};

const SignupFooter = (props: Props) => {
  const [signupEmail, setSignupEmail] = useState('');

  const emailEntered = (email: string) => {
    addEmailToWaitlist(
      email,
      () => {
        setSignupEmail('');
        props.onSignupSuccess(signupEmail);
        window.location.href = 'https://form.typeform.com/to/jyPnu9GP';
      },
      (response: any) => {
        props.onSignupFail(response.msg);
      },
      'landing_page',
    );
  };

  const classes = useStyles();
  return (
    <div className={cx(classes.root, props.className)}>
      <div className={classes.title}>Discover new insights</div>
      <div className={classes.description}>
        Start analyzing your data in new ways without relying on data teams. We’re making data
        accessible to everyone. Find out how Explo can work for you and your team.
      </div>
      <div className={classes.signupInputContainer}>
        <InputField
          value={signupEmail}
          onChange={(e: any) => setSignupEmail(e.target.value)}
          className={classes.signupInput}
          placeholder="Enter your email address"
        />
        <Button
          className={classes.signupButton}
          intent={Intent.PRIMARY}
          onClick={() => emailEntered(signupEmail)}
          large>
          Request Early Access
        </Button>
      </div>
    </div>
  );
};

export default SignupFooter;

/** @format */

import React, { useEffect, useState, useMemo, useCallback } from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import {
  addFlowStep,
  fetchFlowData,
  fetchDatasetRowCount,
  updateFlowOperation,
  deleteFlowOperation,
  updateOperationPreview,
  computeExistingStepPreview,
  downloadResultsTableAsCsv,
  stepPreviewFailedCallback,
  createFlowFromDataset,
  addDatasetToFlow,
  fetchOperationRowCount,
  refreshFlowOperation,
  refreshDatasetFlowNode,
  refreshFlow,
} from 'actions/flowActions';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Operation, DatasetFlowNode, ACTION, Flow } from 'actions/types';
import { createLoadingSelector } from 'reducers/api/selectors';
import { bulkFetchDatasets } from 'actions/datasetCacheActions';
import { updateFlowNodePos, updateFlowViewConfig } from 'actions/flowGraphActions';
import FlowGraphPageHeader from 'pages/flowGraphPage/flowGraphPageHeader';
import {
  fetchWorkspaceData,
  switchSelectedDataSourceId,
  switchWorkspaceData,
} from 'actions/dataSourceActions';
import { renameFlow, saveAsDataset } from 'actions/fileSystemActions';
import FlowGraphAddStepModal from 'pages/flowGraphPage/flowGraphAddStepModal';
import { useCmdKAlerter } from './flowGraphPageUtils';
import { pageView } from 'analytics/exploAnalytics';
import { FlowGraphApi } from './flowGraphPageContext';
import FlowGraphContent, { BACKGROUND_CANVAS } from './flowGraphContent';
import GraphCanvas from 'components/flowGraph/graphCanvas';
import { ROUTES } from 'constants/routes';
import { OPERATION_TYPES } from 'constants/flowConstants';

export enum NODE_TYPES {
  DATASET_FLOW_NODE = 'dataset',
  OPERATION = 'operation',
}

export enum NODE_POSITIONS {
  FIRST,
  MIDDLE,
  LAST,
  ROOT,
  ONLY_CHILD,
}

export interface NodeMap {
  [nodeId: string]: Operation;
}

export interface FlowGraph {
  datasetFlowNodes: DatasetFlowNode[];
  // nodeMap does not include datasetNodes hence, it could be empty (no operations)
  nodeMap?: NodeMap;
}

export interface SelectedNode {
  id: number;
  type: NODE_TYPES;
}

const useStyles = makeStyles((theme: Theme) => ({
  graphPageRoot: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

type MatchParams = {
  flowId: string;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof flowGraphMapDispatchToProps &
  RouteComponentProps<MatchParams>;

const FlowGraphPageV3 = (props: Props) => {
  const classes = useStyles();
  const {
    fetchFlowData,
    fetchDatasetRowCount,
    addFlowStep,
    fetchWorkspaceData,
    switchSelectedDataSourceId,
    addDatasetToFlow,
    refreshFlowOperation,
    refreshDatasetFlowNode,
    refreshFlow,
    match,
    flowData,
    flowDataLoading,
    flowDataError,
    history,
    updateFlowNodePos,
    fetchOperationRowCount,
    datasourceDictionary,
    switchWorkspaceData,
    bulkFetchDatasets,
  } = props;

  const [selectedNode, setSelectedNode] = useState<SelectedNode>();
  const [addDatasetModalOpen, setAddDatasetModalOpen] = useState(false);
  const [addStepModalOpen, setAddStepModalOpen] = useState(false);
  const [canvasYScrollOffset, setCanvasYScrollOffset] = useState(0);
  const closeAddStepModal = useCallback(() => setAddStepModalOpen(false), []);
  const openAddStepModal = useCallback(() => setAddStepModalOpen(true), []);
  const unselectNode = useCallback(() => setSelectedNode(undefined), []);
  const flowId: number = parseInt(match.params.flowId);

  pageView('Flow Graph');
  useCmdKAlerter(openAddStepModal, flowData.flowInfo, selectedNode);

  // https://medium.com/trabe/passing-callbacks-down-with-react-hooks-4723c4652aff
  const getFlowGraphApi = useMemo(
    () => ({
      closeAddStepModal,
      openAddStepModal,
      unselectNode,
      setSelectedNode,
      ...props,
    }),
    [closeAddStepModal, openAddStepModal, unselectNode, setSelectedNode, props],
  );

  useEffect(() => {
    fetchFlowData(flowId, (flowData: Flow) => {
      switchSelectedDataSourceId(flowData.data_source.id);
      if (!datasourceDictionary[flowData.data_source.id]) {
        fetchWorkspaceData({ id: flowData.data_source.id });
      } else {
        switchWorkspaceData(flowData.data_source.id.toString());
      }

      flowData.dataset_nodes.map((datasetNode: DatasetFlowNode) =>
        fetchDatasetRowCount({ id: datasetNode.dataset.id, datasetNodeId: datasetNode.id }),
      );
      if (!flowData.operations) throw new Error(`flowData.operations undefined`);

      const combineTableOpsDataSetIds: Array<number> = [];
      flowData.operations.forEach((operation: Operation) => {
        operation.results_dataset && fetchOperationRowCount({ id: operation.id });

        if (
          operation.operation_type === OPERATION_TYPES.ENRICH.id &&
          operation.instructions.joinTable.datasetId
        ) {
          combineTableOpsDataSetIds.push(operation.instructions.joinTable.datasetId);
        } else if (
          operation.operation_type === OPERATION_TYPES.UNION.id &&
          operation.instructions.unionTable.datasetId
        ) {
          combineTableOpsDataSetIds.push(operation.instructions.unionTable.datasetId);
        }
      });

      if (combineTableOpsDataSetIds.length > 0) {
        bulkFetchDatasets({ postData: { dataset_ids: combineTableOpsDataSetIds } });
      }
    });
  }, [
    flowId,
    fetchFlowData,
    fetchWorkspaceData,
    switchSelectedDataSourceId,
    fetchDatasetRowCount,
    fetchOperationRowCount,
    datasourceDictionary,
    switchWorkspaceData,
    bulkFetchDatasets,
  ]);

  useEffect(() => {
    if (flowData.recentlyAddedOpId) {
      setSelectedNode({
        id: flowData.recentlyAddedOpId!,
        type: NODE_TYPES.OPERATION,
      });
    }
  }, [flowData.recentlyAddedOpId]);

  return (
    <div className={classes.graphPageRoot} id={BACKGROUND_CANVAS}>
      <FlowGraphApi.Provider value={getFlowGraphApi}>
        <FlowGraphPageHeader
          addDatasetModalOpen={addDatasetModalOpen}
          addDatasetToFlow={addDatasetToFlow}
          fetchDatasetRowCount={fetchDatasetRowCount}
          fetchOperationRowCount={fetchOperationRowCount}
          refreshDatasetFlowNode={refreshDatasetFlowNode}
          refreshFlow={refreshFlow}
          refreshFlowOperation={refreshFlowOperation}
          setAddDatasetModalOpen={setAddDatasetModalOpen}
          displayBottomBorder={canvasYScrollOffset !== 0}
          navigateBackToDataSource={() => {
            history.push(ROUTES.DATASOURCE);
          }}
        />
        <GraphCanvas
          setCanvasYScrollOffset={setCanvasYScrollOffset}
          flowDataLoading={flowDataLoading}>
          <FlowGraphContent
            flowDataLoading={flowDataLoading}
            flowDataError={!!flowDataError}
            selectedNode={selectedNode}
            addStepModalOpen={addStepModalOpen}
            addDatasetModalOpen={addDatasetModalOpen}
            setAddStepModalOpen={setAddStepModalOpen}
            flowId={flowId.toString()}
            flowData={flowData}
            updateFlowNodePos={updateFlowNodePos}
          />
        </GraphCanvas>
        <FlowGraphAddStepModal
          addStepModalOpen={addStepModalOpen}
          flowId={flowId.toString()}
          addFlowStep={addFlowStep}
          selectedNode={selectedNode}
          flowData={flowData}
        />
      </FlowGraphApi.Provider>
    </div>
  );
};

const isFlowDataLoadingSelector = createLoadingSelector([ACTION.FETCH_FLOW_DATA]);

const mapStateToProps = (state: ReduxState) => ({
  flowData: state.flowData,
  flowDataLoading: isFlowDataLoadingSelector(state),
  flowDataError: state.flowData.error,
  datasetCacheData: state.datasetCacheData,
  selectedDataSource: state.dataSource.selectedDataSource,
  datasourceDictionary: state.workspaceData.datasourceDictionary,
});

export const flowGraphMapDispatchToProps = {
  updateFlowViewConfig,
  updateFlowNodePos,
  fetchFlowData,
  fetchDatasetRowCount,
  addFlowStep,
  updateFlowOperation,
  bulkFetchDatasets,
  deleteFlowOperation,
  updateOperationPreview,
  computeExistingStepPreview,
  downloadResultsTableAsCsv,
  stepPreviewFailedCallback,
  saveAsDataset,
  renameFlow,
  fetchWorkspaceData,
  switchSelectedDataSourceId,
  createFlowFromDataset,
  addDatasetToFlow,
  fetchOperationRowCount,
  refreshFlowOperation,
  refreshDatasetFlowNode,
  refreshFlow,
  switchWorkspaceData,
};

export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, flowGraphMapDispatchToProps)(FlowGraphPageV3),
);

/** @format */

import { DATETIME } from 'constants/flowConstants';
import {
  AggregationColumnInfo,
  PivotOperationAggregation,
  PivotOperationInstructions,
} from 'constants/types';
import _ from 'underscore';
import { OperationFooterErrorInformation } from 'utils/operationErrorInfoUtils/generalOperationErrorInfoUtil';

enum FOOTER_ERROR_TEXT {
  NO_ERROR = 'No Error',
  SELECT_PIVOT_COLUMN = 'Please select a column to group by',
  FILL_ROWS = 'Please fill in the incomplete rows to submit',
}

export interface PivotRowErrorInformation {
  isPivotColButtonErrorState: boolean;
  isDateButtonErrorState: boolean;
}

export interface AggRowErrorInformation {
  isSelectColButtonErrorState: boolean;
  isAggButtonErrorState: boolean;
}

export const getPivotRowErrorInformation = (
  pivotCol: AggregationColumnInfo,
  index: number,
): PivotRowErrorInformation => {
  let pivotOperationRowErrorInformation: PivotRowErrorInformation = {
    isPivotColButtonErrorState: false,
    isDateButtonErrorState: false,
  };

  // If its the first row and its empty, give an error
  if (index === 0 && pivotCol.type === '') {
    pivotOperationRowErrorInformation.isPivotColButtonErrorState = true;
    return pivotOperationRowErrorInformation;
  }

  // If date is not selected, then give an error
  if (pivotCol.type === DATETIME && pivotCol.aggBucket === undefined) {
    pivotOperationRowErrorInformation.isDateButtonErrorState = true;
  }

  return pivotOperationRowErrorInformation;
};

export const getAggRowErrorInformation = (aggCol: PivotOperationAggregation, index: number) => {
  const aggRowErrorInformation = {
    isSelectColButtonErrorState: false,
    isAggButtonErrorState: false,
  };

  // If the row is empty
  if (aggCol.aggedOnColumn === null && aggCol.type === null) {
    if (index === 0) {
      aggRowErrorInformation.isSelectColButtonErrorState = true;
      aggRowErrorInformation.isAggButtonErrorState = true;
    }
    return aggRowErrorInformation;
  }

  // if the column is filled but the calculation type is not, then give an error
  if (aggCol.aggedOnColumn !== null && aggCol.type === null) {
    aggRowErrorInformation.isAggButtonErrorState = true;
  }

  return aggRowErrorInformation;
};

export const getPivotOperationFooterErrorInformation = (
  instructions: PivotOperationInstructions,
  noPivotedCol: boolean,
): OperationFooterErrorInformation => {
  const operationFooterErrorInformation: OperationFooterErrorInformation = {
    footerErrorState: false,
    footerErrorText: FOOTER_ERROR_TEXT.NO_ERROR,
  };

  const errorInAggTableRows = !!_.find(instructions.aggregations, (aggRow, index) => {
    const { isSelectColButtonErrorState, isAggButtonErrorState } = getAggRowErrorInformation(
      aggRow,
      index,
    );
    return isSelectColButtonErrorState || isAggButtonErrorState;
  });
  if (noPivotedCol) {
    if (errorInAggTableRows) {
      operationFooterErrorInformation.footerErrorState = true;
      operationFooterErrorInformation.footerErrorText = FOOTER_ERROR_TEXT.FILL_ROWS;
    }
    return operationFooterErrorInformation;
  }

  const errorInPivotTableRows = !!_.find(
    instructions.pivotedOnCols,
    (pivotCol: AggregationColumnInfo, index) => {
      const { isPivotColButtonErrorState, isDateButtonErrorState } = getPivotRowErrorInformation(
        pivotCol,
        index,
      );
      return isPivotColButtonErrorState || isDateButtonErrorState;
    },
  );

  if (errorInPivotTableRows || errorInAggTableRows) {
    operationFooterErrorInformation.footerErrorState = true;
    operationFooterErrorInformation.footerErrorText = FOOTER_ERROR_TEXT.FILL_ROWS;
  }
  return operationFooterErrorInformation;
};

/** @format */

import { Button } from '@blueprintjs/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  cancelClauseCol: {
    width: theme.spacing(7),
    paddingRight: '0px !important',
  },
  cancelClauseColPlaceholder: {
    width: theme.spacing(10),
  },
}));

type Props = {
  index: number;
  onClick: () => void;
};

const CancelClauseButton = (props: Props) => {
  const classes = useStyles();
  const { index, onClick } = props;
  return index === 0 ? (
    <td className={classes.cancelClauseColPlaceholder}></td>
  ) : (
    <td className={classes.cancelClauseCol}>
      <Button icon="cross" minimal={true} onClick={onClick} />
    </td>
  );
};

export default CancelClauseButton;

/** @format */

import React from 'react';
import { withStyles } from '@material-ui/styles';

import StepBlockTag from 'components/flowBuilder/stepBlockTag';
import ClauseBuilder from 'components/flowBuilder/clauseBuilder';
import PivotChartEditOperationBody from 'components/flowBuilder/editOperationDrawer/pivotChartEditOperationBody';

import { pivotChartInstructionsComplete, pivotChartInstructionsChanged } from 'utils/flowUtils';
import { trackEvent } from 'analytics/exploAnalytics';

const styles = (theme) => ({
  fillerText: {
    fontSize: 16,
    fontWeight: 400,
  },
});

class PivotChartClauseBuilder extends React.Component {
  constructor(props) {
    super(props);

    if (props.openFlyout) {
      props.clearRecentlyAddedOpId();
    }

    this.state = {
      editDrawerOpen: props.openFlyout || false,
    };
  }

  render() {
    const { computeLoading, instructions, summaryView } = this.props;

    return (
      <ClauseBuilder
        computeLoading={computeLoading}
        editDrawerOpen={this.state.editDrawerOpen}
        openDrawer={() => this.setState({ editDrawerOpen: true })}
        closeDrawer={() => this.setState({ editDrawerOpen: false })}
        editOperationDrawerTitle="Configure the pivot chart"
        tooltipSuggestionIsOpen={!pivotChartInstructionsComplete(instructions)}
        editOperationBody={this.renderOperationEditBody()}
        clauseText={this.renderPivotChartText()}
        summaryView={summaryView}
      />
    );
  }

  renderPivotChartText = () => {
    const { classes, instructions, operationId } = this.props;

    const phrases = [
      <div className={classes.fillerText} key={`pivot_chart_on_${operationId}`}>
        on
      </div>,
    ];
    if (instructions.xAxisColumn) {
      phrases.push(
        <StepBlockTag
          key={`pivot_chart_xaxis_1_${operationId}`}
          interactive={true}
          content={instructions.xAxisColumn.name}
        />,
      );
    }

    phrases.push(
      <div className={classes.fillerText} key={`pivot_chart__with_${operationId}`}>
        with
      </div>,
    );
    if (instructions.aggregation.type && instructions.aggregation.aggedOnColumn) {
      phrases.push(
        <StepBlockTag
          key={`pivot_chart_agg_type_${operationId}`}
          interactive={true}
          content={`${instructions.aggregation.type.name}( ${this.renderCalculateTextParameters(
            instructions.aggregation,
          )} )`}
        />,
      );
    }

    return phrases.length > 3 && phrases; // if no clauses were added, don't return anything
  };

  renderCalculateTextParameters = (agg) => {
    return agg.aggedOnColumn;
  };

  renderOperationEditBody = () => {
    const {
      updateOperationInstructionAndCompute,
      sourceDataset,
      instructions,
      operationId,
    } = this.props;

    return (
      <PivotChartEditOperationBody
        instructions={instructions}
        operationId={operationId}
        sourceDataset={sourceDataset}
        onSubmit={(newInstructions) => {
          const instructionHasChanged = pivotChartInstructionsChanged(
            instructions,
            newInstructions,
          );
          updateOperationInstructionAndCompute(
            operationId,
            newInstructions,
            instructionHasChanged && pivotChartInstructionsComplete(newInstructions),
          );
          this.setState({ editDrawerOpen: false });
          trackEvent('Update and compute pivot chart', {
            operation_id: operationId,
            new_instructions: newInstructions,
            line_chart_instructions_changed: instructionHasChanged,
          });
        }}
      />
    );
  };
}

export default withStyles(styles)(PivotChartClauseBuilder);

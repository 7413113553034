/** @format */

import {
  Button,
  FormGroup,
  H3,
  IconName,
  InputGroup,
  Intent,
  Menu,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  Position,
  Alignment,
} from '@blueprintjs/core';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Dataset } from 'actions/types';
import cx from 'classnames';
import EditDrawerDataPreview from 'components/flowBuilder/editOperationDrawer/editDrawerDataPreview';
import OperationDrawerFooter from 'components/flowBuilder/editOperationDrawer/operationDrawerFooter';
import {
  FLYOUT_DATA_PREVIEW_HEADER,
  SCHEMA_DATA_TYPES_BY_ID,
  SELECTABLE_DATA_TYPES,
  OPERATION_TYPES,
  TIMESTAMP,
} from 'constants/flowConstants';
import {
  ChangeSchemaOperationInstructions,
  ColumnInfo,
  DataType,
  SchemaChange,
  SchemaDataType,
  TimeStampItem,
} from 'constants/types';
import React from 'react';
import _ from 'underscore';
import {
  getChangeSchemaOperationRowErrorInformation,
  isChangeSchemaOperationInstructionsIncomplete,
} from 'utils/operationErrorInfoUtils/changeSchemaOperationErrorInfoUtil';
import CustomTimestampFormatModal from 'components/customTimestampFormatModal';
import CustomCheckbox from 'components/checkbox';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    body: {
      height: 'calc(100% - 54px)',
      overflowY: 'auto',
    },
    selectionTitle: {
      margin: theme.spacing(6),
    },
    schemaClausesContainer: {
      margin: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
      marginBottom: theme.spacing(6),
      backgroundColor: theme.palette.white,
      boxShadow: theme.customShadows.basic,
      borderRadius: 4,
    },
    selectOrDeselectButton: {
      color: `${theme.palette.blue} !important`,
      '&:hover': {
        cursor: 'pointer',
      },
      fontSize: 11,
      position: 'absolute',
    },
    selectOrDeselectButtonPlaceholder: {
      height: theme.spacing(3),
    },
    clauseTable: {
      width: '100%',
      position: 'relative',
    },
    addClauseButton: {
      margin: theme.spacing(3),
      marginLeft: 52,
    },
    keepColColumn: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      paddingTop: `${theme.spacing(2)} !important`,
    },
    columnNameHeader: {
      width: '60%',
    },
    columnTypeHeader: {
      width: '35%',
    },
    subMenuItemContainer: {
      overflowY: 'scroll',
    },
    formGroupValidationErrorState: {
      marginBottom: 0,
    },
    formGroupValidationNoError: {
      marginBottom: 20,
    },
  });

type PassedProps = {
  operationId: number;
  instructions: ChangeSchemaOperationInstructions;
  sourceDataset: Dataset;
  onSubmit: (newInstructions: ChangeSchemaOperationInstructions) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

export type SchemaChangeMap = {
  [columnName: string]: SchemaChange;
};

type State = {
  instructions: SchemaChangeMap;
  isCustomTimestampFormatModalOpen: boolean;
  customDateColumnName?: string;
  selectAllIsAvailable: boolean;
};

class ChangeSchemaEditOperationBody extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const schemaChangeMap: SchemaChangeMap = {};
    props.instructions.changeSchemaList
      .filter((obj: SchemaChange) => obj.col)
      .forEach((obj: SchemaChange) => (schemaChangeMap[obj.col] = { ...obj }));

    this.state = {
      instructions: schemaChangeMap,
      isCustomTimestampFormatModalOpen: false,
      selectAllIsAvailable: true,
    };
  }

  render() {
    const { classes, sourceDataset } = this.props;
    const { instructions, selectAllIsAvailable } = this.state;

    const { isFooterError, footerErrorText } = isChangeSchemaOperationInstructionsIncomplete(
      instructions,
      sourceDataset.cached_schema!,
    );

    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <EditDrawerDataPreview
            sourceDataset={sourceDataset}
            dataPreviewHeader={FLYOUT_DATA_PREVIEW_HEADER}
          />

          <H3 className={classes.selectionTitle}>Columns changes</H3>
          <div className={classes.schemaClausesContainer}>
            <table
              className={cx(classes.clauseTable, 'bp3-html-table', 'bp3-html-table-condensed')}>
              <thead>
                <tr>
                  <th>Remove</th>
                  <th className={classes.columnNameHeader}>Column Name</th>
                  <th className={classes.columnTypeHeader}>Column Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    onClick={() => {
                      sourceDataset.cached_schema!.forEach((columnInfo: ColumnInfo) => {
                        this.toggleCheckbox(instructions, columnInfo.name, !selectAllIsAvailable);
                        return null;
                      });
                    }}>
                    <div className={classes.selectOrDeselectButton}>
                      {selectAllIsAvailable ? 'Select All' : 'Deselect All'}
                    </div>
                    <div className={classes.selectOrDeselectButtonPlaceholder}></div>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {sourceDataset.cached_schema!.map(this.renderSchemaClause)}
              </tbody>
            </table>
          </div>
        </div>
        <OperationDrawerFooter
          operationType={OPERATION_TYPES.CHANGE_SCHEMA.id}
          errorState={isFooterError}
          errorText={footerErrorText}
          onSubmit={this.onSubmit}
        />
        {this.renderCustomTimestampFormatModal()}
      </div>
    );
  }

  renderCustomTimestampFormatModal = () => {
    const { isCustomTimestampFormatModalOpen, customDateColumnName, instructions } = this.state;
    const { sourceDataset } = this.props;
    if (!isCustomTimestampFormatModalOpen) return;

    return (
      <CustomTimestampFormatModal
        modalOpen={isCustomTimestampFormatModalOpen}
        closeModal={() => this.setState({ isCustomTimestampFormatModalOpen: false })}
        onSubmit={(timestampFormat: string) => {
          this.onSubMenuItemSelect(customDateColumnName!, {
            format: timestampFormat,
            name: timestampFormat,
            parentType: TIMESTAMP,
          });
        }}
        customDateColumnName={customDateColumnName!}
        schemaChange={instructions[customDateColumnName!]}
        previewDate={sourceDataset.cached_preview![0][customDateColumnName!]}
      />
    );
  };
  onSubmit = () => {
    const { onSubmit } = this.props;
    const { instructions } = this.state;
    const newChangeSchemaList = Object.values(instructions);
    newChangeSchemaList.map((changeObj) => {
      if (changeObj.newColName) {
        changeObj.newColName = changeObj.newColName.replace(/\W+/g, '_');
      }
      return null;
    });
    const formattedInstructions = { changeSchemaList: newChangeSchemaList };
    onSubmit(formattedInstructions);
  };

  constructMenuItems = (colName: string, schemaObj: ColumnInfo) => {
    return (
      <Menu>
        {SELECTABLE_DATA_TYPES.map((dataType) =>
          this.constructTopLevelMenuItem(dataType, colName, schemaObj),
        )}
      </Menu>
    );
  };

  constructTopLevelMenuItem = (type: string, colName: string, schemaObj: ColumnInfo) => {
    const { instructions } = this.state;
    const schemaDataTypes = SCHEMA_DATA_TYPES_BY_ID[type];
    const hasSubitems = 'subitems' in schemaDataTypes && schemaDataTypes['subitems'];
    return (
      <MenuItem
        active={
          colName in instructions && instructions[colName]['newType']
            ? instructions[colName]['newType'] === type
            : schemaObj.type === type
        }
        text={schemaDataTypes.name}
        key={_.uniqueId(`${type}_`)}
        popoverProps={{ openOnTargetFocus: false }}
        onClick={() => (hasSubitems ? null : this.onTopLevelMenuItemSelect(colName, type))}
        icon={schemaDataTypes.icon as IconName}>
        {hasSubitems ? this.constructSubMenuItems(schemaDataTypes, colName) : null}
      </MenuItem>
    );
  };

  constructSubMenuItems = (schemaDataTypes: SchemaDataType, colName: string) => {
    const { classes, sourceDataset } = this.props;
    const { instructions } = this.state;

    const subMenuItems = schemaDataTypes['subitems']!.map((subitem: TimeStampItem) => {
      return (
        <MenuItem
          active={
            colName in instructions &&
            instructions[colName]['format'] &&
            instructions[colName]['format'] === subitem['format']
          }
          text={subitem.name}
          key={_.uniqueId(`${subitem.name}_`)}
          onClick={() => this.onSubMenuItemSelect(colName, subitem)}
        />
      );
    });

    subMenuItems.push(
      <Button
        fill
        disabled={sourceDataset.cached_preview!.length === 0}
        key={_.uniqueId(`${colName}_`)}
        icon={'plus'}
        text="Custom Format"
        intent={Intent.PRIMARY}
        alignText={Alignment.LEFT}
        onClick={() =>
          this.setState({ isCustomTimestampFormatModalOpen: true, customDateColumnName: colName })
        }
      />,
    );

    return <div className={classes.subMenuItemContainer}>{subMenuItems}</div>;
  };

  onSubMenuItemSelect = (colName: string, subitem: TimeStampItem) => {
    const { instructions } = this.state;
    if (colName in instructions) {
      instructions[colName]['newType'] = subitem.parentType;
      instructions[colName]['subitem'] = true;
      instructions[colName]['format'] = subitem.format;
    } else {
      instructions[colName] = {
        col: colName,
        newType: subitem.parentType,
        safeCast: true,
        newColName: null,
        keepCol: true,
        subitem: true,
        format: subitem.format,
      };
    }
    this.setState({ instructions: instructions });
  };

  onTopLevelMenuItemSelect = (colName: string, type: DataType) => {
    const { instructions } = this.state;
    if (colName in instructions) {
      instructions[colName]['newType'] = type;
      instructions[colName]['subitem'] = false;
      instructions[colName]['format'] = null;
    } else {
      instructions[colName] = {
        col: colName,
        newType: type,
        safeCast: true,
        newColName: null,
        keepCol: true,
        subitem: false,
        format: null,
      };
    }
    this.setState({ instructions: instructions });
  };

  renderInputGroup = (
    name: string,
    instructions: SchemaChangeMap,
    intent: Intent,
    disabled: boolean,
  ) => {
    return (
      <InputGroup
        disabled={disabled}
        intent={intent}
        type="text"
        value={
          name in instructions && instructions[name]['newColName'] !== null
            ? instructions[name]['newColName']!
            : name
        }
        small={true}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const colName = event.target.value;
          if (name in instructions) {
            instructions[name]['newColName'] = colName;
          } else {
            instructions[name] = {
              col: name,
              newType: null,
              safeCast: false,
              newColName: colName,
              keepCol: true,
            };
          }
          this.setState({ instructions: instructions });
        }}
      />
    );
  };

  toggleCheckbox = (instructions: SchemaChangeMap, colName: string, toggleValue?: boolean) => {
    if (colName in instructions) {
      if ('keepCol' in instructions[colName]) {
        instructions[colName]['keepCol'] =
          toggleValue !== undefined ? toggleValue : !instructions[colName]['keepCol'];
      } else {
        instructions[colName]['keepCol'] = toggleValue !== undefined ? toggleValue : false;
      }
    } else {
      instructions[colName] = {
        col: colName,
        newType: null,
        safeCast: false,
        newColName: null,
        keepCol: toggleValue !== undefined ? toggleValue : false,
      };
    }

    this.setState({
      instructions: instructions,
      selectAllIsAvailable: !!_.findWhere(Object.values(instructions), { keepCol: true }),
    });
  };

  renderSchemaClause = (schemaObj: ColumnInfo, index: number) => {
    const { classes, operationId } = this.props;
    const { instructions } = this.state;
    const name = schemaObj.name;
    const { validationText, inputIntent } = getChangeSchemaOperationRowErrorInformation(
      name,
      instructions,
    );

    const columnRemoved =
      name in instructions && 'keepCol' in instructions[name]
        ? !instructions[name]['keepCol']
        : false;

    return (
      <tr key={`filter_clause_${operationId}}_${index}`}>
        <td className={classes.keepColColumn}>
          <CustomCheckbox
            isChecked={columnRemoved}
            onChange={() => {
              this.toggleCheckbox(instructions, name);
            }}
          />
        </td>
        <td>
          <FormGroup
            className={
              validationText
                ? classes.formGroupValidationErrorState
                : classes.formGroupValidationNoError
            }
            helperText={columnRemoved ? '' : validationText}
            intent={inputIntent}
            disabled={columnRemoved}
            labelFor="text-input">
            {this.renderInputGroup(name, instructions, inputIntent, columnRemoved)}
          </FormGroup>
        </td>
        <td>
          <Popover
            content={this.constructMenuItems(name, schemaObj)}
            position={Position.BOTTOM_LEFT}
            autoFocus={false}
            interactionKind={PopoverInteractionKind.CLICK}
            disabled={columnRemoved}>
            <Button
              disabled={columnRemoved}
              icon={
                (name in instructions && instructions[name]['newType']
                  ? SCHEMA_DATA_TYPES_BY_ID[instructions[name]['newType']!].icon
                  : SCHEMA_DATA_TYPES_BY_ID[schemaObj.type].icon) as IconName
              }
              rightIcon="caret-down"
              text={
                name in instructions && instructions[name]['newType']
                  ? this.formatTypeMapForButton(instructions[name])
                  : SCHEMA_DATA_TYPES_BY_ID[schemaObj.type].name
              }
            />
          </Popover>
        </td>
      </tr>
    );
  };

  formatTypeMapForButton = (typeMap: SchemaChange) => {
    if ('subitem' in typeMap && typeMap['subitem']) {
      return SCHEMA_DATA_TYPES_BY_ID[typeMap['newType']!].name + ': ' + typeMap['format'];
    } else {
    }
    return SCHEMA_DATA_TYPES_BY_ID[typeMap['newType']!].name;
  };
}

export default withStyles(styles)(ChangeSchemaEditOperationBody);

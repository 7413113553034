/** @format */

import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { ReduxState } from 'reducers/rootReducer';
import { makeStyles } from '@material-ui/core/styles';
import { Intent } from '@blueprintjs/core';

import Button from 'explo-ds/buttons/button';

import ExploLogo from 'images/branding/logo_primary.png';

export const HEADER_PAGES = {
  ABOUT_US: 'about_us',
  USE_CASES: 'use_cases',
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(10),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  logo: {
    height: 30,
  },
  rightNavOptions: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(5),
    },
  },
  navHeaderLink: {
    color: `${theme.palette.grey100} !important`,
    display: 'flex',
    alignItems: 'center',

    '&.selected': {
      fontWeight: 'bold',
    },

    '&:hover': {
      color: `${theme.palette.grey100} !important`,
    },
  },
  useCaseWrapper: {
    marginLeft: theme.spacing(10),
  },
  goToAppBtn: {
    marginLeft: theme.spacing(10),
  },
  dropdownContent: {
    '&.bp3-popover.bp3-minimal': {
      marginTop: `${theme.spacing(2)}px !important`,
    },
  },
  dropdownMenu: {
    backgroundColor: theme.palette.black100,
    color: theme.palette.grey100,
  },
  dropdownOption: {
    width: 'fit-content',
    minWidth: 110,
  },
}));

type PassedProps = {
  className?: string;
  selectedHeaderPage?: string;
};

type Props = PassedProps & ReturnType<typeof mapStateToProps>;

const NavHeader = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <a href="/">
          <img className={classes.logo} src={ExploLogo} alt="logo for explo" />
        </a>
      </div>
      <div className={classes.rightNavOptions}>
        <div>
          <Button
            minimal
            className={cx(classes.navHeaderLink, {
              selected: HEADER_PAGES.ABOUT_US === props.selectedHeaderPage,
            })}
            href="/about-us">
            About Us
          </Button>
        </div>
        <Button
          large
          className={cx(classes.goToAppBtn, 'dark-mode')}
          intent={Intent.PRIMARY}
          href={props.userLoggedIn ? '/home' : '/login'}>
          {props.userLoggedIn ? 'Go To App' : 'Sign In'}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  userLoggedIn: state.currentUser.id !== null && state.currentUser.id !== undefined,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavHeader);

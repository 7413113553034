/** @format */

import React from 'react';
import Chart from 'chart.js';
import _ from 'underscore';
import moment from 'moment';

import { numberWithCommas } from 'utils/general';
import { DATE_TYPES, DEFAULT_DATE_FORMAT } from 'constants/flowConstants';
import { chartConfigIsSame, colorLuminance, indexOffirstLineOfAxis } from 'utils/graphUtils';

import { COLOR_LIST } from 'constants/colorConstants';

class LineOrBarChart extends React.PureComponent {
  chartRef = React.createRef();
  chart = null;

  componentWillMount() {
    const { chartType } = this.props;

    Chart.pluginService.register({
      afterDraw: function (chart, easing) {
        if (chartType !== 'line') {
          return;
        }
        if (chart.tooltip._active && chart.tooltip._active.length && chart.scales['left-axis']) {
          const activePoint = chart.controller.tooltip._active[0];
          const ctx = chart.ctx;
          const x = activePoint.tooltipPosition().x;
          const topY = chart.scales['left-axis'].top;
          const bottomY = chart.scales['left-axis'].bottom;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = 'rgba(155, 155, 155, 0.5)';
          ctx.stroke();
          ctx.restore();
        }
      },
    });
  }

  componentDidMount() {
    const myChartRef = this.chartRef.current.getContext('2d');

    this.chart = new Chart(myChartRef, this.getChartConfig());
  }

  componentDidUpdate() {
    const { data, xAxis, schema } = this.props;
    const colByName = _.indexBy(schema, 'name');
    const xAxisColName = colByName['x_axis_col'] ? 'x_axis_col' : xAxis;

    if (data[0][xAxisColName] === undefined) {
      return;
    }

    const newConfig = this.getChartConfig();

    // Don't update the chart if the chart config did not change
    if (chartConfigIsSame(newConfig, this.chart)) {
      return;
    }

    this.chart.options = newConfig.options;
    this.chart.data = newConfig.data;
    this.chart.update();
  }

  formatLabel = (value, colType, xAxisFormat) => {
    if (DATE_TYPES.has(colType)) {
      const dateVal = moment(value);
      return dateVal.isValid()
        ? dateVal.format(xAxisFormat || DEFAULT_DATE_FORMAT)
        : 'Invalid Date';
    }

    return value;
  };

  getChartConfig = () => {
    const { chartType, data, xAxis, xAxisFormat, columns, schema, multiYAxis } = this.props;
    const cleanColumns = columns.filter((config) => {
      return !!config.column && data[0][config.column.name] !== undefined;
    });
    const colByName = _.indexBy(schema, 'name');
    const xAxisColName = colByName['x_axis_col'] ? 'x_axis_col' : xAxis;
    const xAxisCol = colByName[xAxisColName];

    return {
      type: chartType,
      data: {
        //Bring in data
        labels: data.map((obj) => this.formatLabel(obj[xAxisColName], xAxisCol.type, xAxisFormat)),
        datasets: cleanColumns.map((config, index) => {
          const axisId = multiYAxis ? config.yAxis || 'left-axis' : 'left-axis';
          return {
            label: config.display_name || config.column.name,
            data: data.map((obj) => obj[config.column.name]),
            yAxisID: axisId,
            ...this.getColumnConfig(index),
          };
        }),
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        hover: {
          mode: 'index',
          intersect: false,
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: xAxis,
              },
              ticks: this.getXAxisTicksConfig(),
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: this.getYAxes(),
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              const label = data.datasets[tooltipItem.datasetIndex].label;
              return label + ': ' + numberWithCommas(value);
            },
            labelColor: function (tooltipItem, chart) {
              const primaryColor =
                chartType === 'line'
                  ? chart.config.data.datasets[tooltipItem.datasetIndex].borderColor
                  : chart.config.data.datasets[tooltipItem.datasetIndex].backgroundColor;
              return {
                borderColor: primaryColor,
                backgroundColor: primaryColor,
              };
            },
          },
          mode: 'index',
          intersect: false,
          backgroundColor: 'rgba(0, 0, 0, 0.65)',
          titleFontSize: 14,
          titleMarginBottom: 10,
          bodyFontSize: 14,
          bodySpacing: 8,
        },
      },
    };
  };

  getXAxisTicksConfig = () => {
    const config = {
      autoSkip: true,
      maxTicksLimit: 8,
    };
    if (window.innerWidth > 1000) {
      config.maxRotation = 0;
    }

    return config;
  };

  getColumnConfig = (index) => {
    const { chartType, columns } = this.props;

    let primaryColor = columns[index].color || COLOR_LIST[index & COLOR_LIST.length].primary;
    let secondaryColor = colorLuminance(primaryColor, 0.5);

    if (chartType === 'bar') {
      return {
        backgroundColor: primaryColor,
        hoverBackgroundColor: secondaryColor,
      };
    } else if (chartType === 'line') {
      return {
        borderColor: primaryColor,
        pointBackgroundColor: '#FFF',
        pointHoverBackgroundColor: '#FFF',
        pointHoverRadius: 6,
        fill: false,
      };
    }
  };

  getYAxes = () => {
    const { multiYAxis, columns } = this.props;

    const yAxes = [
      {
        ticks: {
          beginAtZero: true,
          userCallback: function (value) {
            return numberWithCommas(value);
          },
          fontColor: multiYAxis
            ? columns[indexOffirstLineOfAxis(columns, 'left-axis')].color
            : undefined,
          fontSize: 13,
        },
        id: 'left-axis',
        position: 'left',
      },
    ];

    if (multiYAxis) {
      yAxes.push({
        ticks: {
          beginAtZero: true,
          userCallback: function (value) {
            return numberWithCommas(value);
          },
          fontColor: columns[indexOffirstLineOfAxis(columns, 'right-axis')].color,
          fontSize: 13,
        },
        id: 'right-axis',
        position: 'right',
        gridLines: {
          drawOnChartArea: false,
        },
      });
    }

    return yAxes;
  };

  render() {
    return <canvas id="myChart" height="297px" ref={this.chartRef} />;
  }
}

export default LineOrBarChart;

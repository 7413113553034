/** @format */

import { Button, H3 } from '@blueprintjs/core';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withStyles, WithStyles } from '@material-ui/styles';
import cx from 'classnames';
import OperationDropdownInput from 'components/flowBuilder/operationDropdownInput';
import {
  OperationDropdownInputType,
  OperationInputTableRow,
  Schema,
  SelectedDropdownInputItem,
} from 'constants/types';
import React from 'react';
import _ from 'underscore';

const styles = (theme: Theme) =>
  createStyles({
    selectionTitle: {
      margin: theme.spacing(6),
    },
    tableContainer: {
      margin: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
      marginBottom: theme.spacing(6),
      backgroundColor: theme.palette.white,
      boxShadow: theme.customShadows.basic,
      borderRadius: 4,
    },
    clauseTable: {
      width: '100%',
    },
    addClauseButton: {
      margin: theme.spacing(3),
      marginLeft: 52,
    },
    cancelClauseCol: {
      width: 30,
      paddingRight: '0px !important',
    },
    sortColumnsFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    displayColumn: {
      width: 100,
      paddingTop: `${theme.spacing(4)}px !important`,
    },
  });

export enum OperationInputType {
  DROPDOWN,
  CHECKBOX,
  DISPLAY,
}

type UserSelectFunction<ReturnType> = (
  rowIndex: number,
  operationInputTableRows: OperationInputTableRow[],
) => ReturnType;

type directValueOrFunction<T> = UserSelectFunction<T> | T;
interface InputSettingsType {
  instructionUpdateKey: InstructionUpdateKey;
}

type ItemDisplayName = keyof OperationDropdownInputType;

interface CheckboxInputSettings extends InputSettingsType {}

interface DropdownInputSettingsBase extends InputSettingsType {
  noSelectionText?: directValueOrFunction<string>;
  showIcon?: directValueOrFunction<boolean>;
  disabled?: directValueOrFunction<boolean>;
}

interface DropdownInputSettings extends DropdownInputSettingsBase {
  selectedItem?: directValueOrFunction<SelectedDropdownInputItem | undefined>;
  getInstructionFromInputItem: (
    index: number,
    operationInputTableRows: OperationInputTableRow[],
    selectedDropdownInputItem: SelectedDropdownInputItem,
  ) => any;
  selectedOptionNames?: directValueOrFunction<Set<string>>;
  options?: directValueOrFunction<SelectedDropdownInputItem[]>;
}

interface DropdownInputMinimalSettings extends DropdownInputSettingsBase {
  items: OperationDropdownInputType[];
  itemDisplayName: ItemDisplayName;
  errorIfFirstRowEmpty: boolean;
}

interface DisplaySettings {
  displayContent: (rowIndex: number) => string;
}

type ColumnInputSettings =
  | DropdownInputSettings
  | DropdownInputMinimalSettings
  | CheckboxInputSettings;

type ColumnSettings = ColumnInputSettings | DisplaySettings;
type InstructionUpdateKey = keyof OperationInputTableRow;

export interface ColumnConfiguration {
  columnName: string;
  columnInputType: OperationInputType;
  className?: string;
  columnSettings: ColumnSettings;
  width?: number;
}

type ColumnConfigurations = ColumnConfiguration[];

type PassedProps = {
  tableHeader?: string;
  operationInputTableRows: OperationInputTableRow[];
  updateInstructions: (tableElements: OperationInputTableRow[]) => void;
  removeColumnsEnabled: boolean;
  cachedSchema: Schema;
  disableAddElementIfRowsExcedeSchema?: boolean;
  columnConfigurations: ColumnConfigurations;
  addElementButtonText: string;
  createNewOperationInputTableRow: () => OperationInputTableRow;
};

type Props = PassedProps & WithStyles<typeof styles>;

class OperationInputTable extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      tableHeader,
      columnConfigurations,
      addElementButtonText,
      operationInputTableRows,
      removeColumnsEnabled,
    } = this.props;
    return (
      <div>
        <H3 className={classes.selectionTitle}>{tableHeader}</H3>
        <div className={classes.tableContainer}>
          <table className={cx(classes.clauseTable, 'bp3-html-table')}>
            <thead>
              <tr>
                {columnConfigurations.map((columnConfiguration) => (
                  <th key={`de-dupcol-config-${columnConfiguration.columnName}`}>
                    {columnConfiguration.columnName}
                  </th>
                ))}
                {removeColumnsEnabled && <th></th>}
              </tr>
            </thead>
            <tbody>{operationInputTableRows.map(this.renderOperationInputTableRow)}</tbody>
          </table>
          {this.renderAddElementButton(addElementButtonText, operationInputTableRows)}
        </div>
      </div>
    );
  }

  renderAddElementButton = (
    addElementButtonText: string,
    operationInputTableRows: OperationInputTableRow[],
  ) => {
    const { classes, cachedSchema, disableAddElementIfRowsExcedeSchema } = this.props;

    return (
      <div className={classes.sortColumnsFooter}>
        <Button
          disabled={
            disableAddElementIfRowsExcedeSchema &&
            operationInputTableRows.length >= cachedSchema.length
          }
          className={classes.addClauseButton}
          icon="add"
          onClick={this.addElement}>
          {addElementButtonText}
        </Button>
      </div>
    );
  };

  addElement = () => {
    const {
      updateInstructions,
      createNewOperationInputTableRow,
      operationInputTableRows,
    } = this.props;
    operationInputTableRows.push(createNewOperationInputTableRow());
    updateInstructions(operationInputTableRows);
  };

  useFunctionOrValue = (
    object: directValueOrFunction<any>,
    index: number,
    operationInputTableRows: OperationInputTableRow[],
  ): any => {
    if (object === null) return null;
    return object instanceof Function ? object(index, operationInputTableRows) : object;
  };

  renderOperationDropdownInputWithManualSettings = (
    columnConfiguration: ColumnConfiguration,
    rowIndex: number,
    operationInputTableRows: OperationInputTableRow[],
  ) => {
    const dropdownInputSettings = columnConfiguration.columnSettings as DropdownInputSettings;
    return (
      <td className={columnConfiguration.className}>
        <OperationDropdownInput
          selectedItem={this.useFunctionOrValue(
            dropdownInputSettings.selectedItem,
            rowIndex,
            operationInputTableRows,
          )}
          onChange={(selectedDropdownInputItem: SelectedDropdownInputItem) => {
            const instructionPart = dropdownInputSettings.getInstructionFromInputItem(
              rowIndex,
              operationInputTableRows,
              selectedDropdownInputItem,
            );
            this.updateTableElement(
              instructionPart,
              dropdownInputSettings.instructionUpdateKey,
              operationInputTableRows,
              rowIndex,
            );
          }}
          selectedOptionNames={this.useFunctionOrValue(
            dropdownInputSettings.selectedOptionNames,
            rowIndex,
            operationInputTableRows,
          )}
          options={this.useFunctionOrValue(
            dropdownInputSettings.options,
            rowIndex,
            operationInputTableRows,
          )}
          noSelectionText={this.useFunctionOrValue(
            dropdownInputSettings.noSelectionText,
            rowIndex,
            operationInputTableRows,
          )}
          showIcon={this.useFunctionOrValue(
            dropdownInputSettings.showIcon,
            rowIndex,
            operationInputTableRows,
          )}
          disabled={this.useFunctionOrValue(
            dropdownInputSettings.disabled,
            rowIndex,
            operationInputTableRows,
          )}
        />
      </td>
    );
  };
  getOperationDropdownInputSelectedItem = (
    operationInputTableRows: OperationInputTableRow[],
    rowIndex: number,
    instructionUpdateKey: InstructionUpdateKey,
    itemDisplayName: ItemDisplayName,
  ) => {
    const selectedElement = operationInputTableRows[rowIndex][instructionUpdateKey] as any;
    return selectedElement
      ? { name: selectedElement[itemDisplayName], id: selectedElement[itemDisplayName] }
      : undefined;
  };

  getOperationDropdownInputSelectedOptionNames = (
    operationInputTableRows: OperationInputTableRow[],
    instructionUpdateKey: InstructionUpdateKey,
    itemDisplayName: ItemDisplayName,
  ) => {
    return new Set(
      operationInputTableRows
        .filter((col: OperationInputTableRow) => col[instructionUpdateKey])
        .map((col: any) => col[instructionUpdateKey][itemDisplayName]),
    );
  };

  getOperationDropdownInputOptions = (
    itemDisplayName: ItemDisplayName,
    items: OperationDropdownInputType[],
  ) => {
    return items.map((item: any) => ({
      name: item[itemDisplayName],
      id: item[itemDisplayName],
    }));
  };

  renderOperationDropdownInputWithMinimalSettings = (
    columnConfiguration: ColumnConfiguration,
    rowIndex: number,
    operationInputTableRows: OperationInputTableRow[],
  ) => {
    let dropdownInputSettings = columnConfiguration.columnSettings as DropdownInputMinimalSettings;

    return (
      <td
        className={columnConfiguration.className}
        key={_.uniqueId('operation_dropdown_input_min_settings_')}>
        <OperationDropdownInput
          buttonErrorState={
            dropdownInputSettings.errorIfFirstRowEmpty &&
            rowIndex === 0 &&
            operationInputTableRows[rowIndex][dropdownInputSettings.instructionUpdateKey] === null
          }
          selectedItem={this.getOperationDropdownInputSelectedItem(
            operationInputTableRows,
            rowIndex,
            dropdownInputSettings.instructionUpdateKey,
            dropdownInputSettings.itemDisplayName,
          )}
          onChange={(selectedDropdownInputItem: SelectedDropdownInputItem) => {
            const itemDictionary = _.indexBy(
              dropdownInputSettings.items,
              dropdownInputSettings.itemDisplayName,
            );
            this.updateTableElement(
              itemDictionary[selectedDropdownInputItem.id],
              dropdownInputSettings.instructionUpdateKey,
              operationInputTableRows,
              rowIndex,
            );
          }}
          selectedOptionNames={this.getOperationDropdownInputSelectedOptionNames(
            operationInputTableRows,
            dropdownInputSettings.instructionUpdateKey,
            dropdownInputSettings.itemDisplayName,
          )}
          options={this.getOperationDropdownInputOptions(
            dropdownInputSettings.itemDisplayName,
            dropdownInputSettings.items,
          )}
          noSelectionText={this.useFunctionOrValue(
            dropdownInputSettings.noSelectionText,
            rowIndex,
            operationInputTableRows,
          )}
          showIcon={this.useFunctionOrValue(
            dropdownInputSettings.showIcon,
            rowIndex,
            operationInputTableRows,
          )}
          disabled={this.useFunctionOrValue(
            dropdownInputSettings.disabled,
            rowIndex,
            operationInputTableRows,
          )}
        />
      </td>
    );
  };
  renderOperationDropdownInput = (
    columnConfiguration: ColumnConfiguration,
    rowIndex: number,
    operationInputTableRows: OperationInputTableRow[],
  ) => {
    // items in minimal settings
    if ('items' in columnConfiguration.columnSettings) {
      return this.renderOperationDropdownInputWithMinimalSettings(
        columnConfiguration,
        rowIndex,
        operationInputTableRows,
      );
    } else {
      return this.renderOperationDropdownInputWithManualSettings(
        columnConfiguration,
        rowIndex,
        operationInputTableRows,
      );
    }
  };

  renderTextDisplay = (
    columnConfiguration: ColumnConfiguration,
    rowIndex: number,
    operationInputTableRows: OperationInputTableRow[],
  ) => {
    const { classes } = this.props;
    const displaySettings = columnConfiguration.columnSettings as DisplaySettings;
    return (
      <td
        className={classes.displayColumn}
        style={columnConfiguration.width ? { width: columnConfiguration.width } : undefined}>
        {displaySettings.displayContent(rowIndex)}
      </td>
    );
  };

  renderColumnConfiguration = (
    columnConfiguration: ColumnConfiguration,
    rowIndex: number,
    columnIndex: number,
  ) => {
    const { operationInputTableRows } = this.props;
    switch (columnConfiguration.columnInputType) {
      case OperationInputType.DROPDOWN:
        return this.renderOperationDropdownInput(
          columnConfiguration,
          rowIndex,
          operationInputTableRows,
        );
      case OperationInputType.DISPLAY:
        return this.renderTextDisplay(columnConfiguration, rowIndex, operationInputTableRows);
      default:
        throw new Error('Column input type not recognized');
    }
  };

  renderRemoveColumnOption = (rowIndex: number) => {
    const { classes } = this.props;
    return (
      <td className={classes.cancelClauseCol}>
        {rowIndex !== 0 && (
          <Button icon="cross" minimal={true} onClick={() => this.removeTableElement(rowIndex)} />
        )}
      </td>
    );
  };

  renderOperationInputTableRow = (
    operationInputTableRow: OperationInputTableRow,
    rowIndex: number,
  ) => {
    const { columnConfigurations, removeColumnsEnabled } = this.props;
    return (
      <tr key={`table_row_${rowIndex}`}>
        {columnConfigurations.map((columnConfiguration: ColumnConfiguration, columnIndex: number) =>
          this.renderColumnConfiguration(columnConfiguration, rowIndex, columnIndex),
        )}
        {removeColumnsEnabled && this.renderRemoveColumnOption(rowIndex)}
      </tr>
    );
  };

  updateTableElement = (
    instructionPart: any,
    instructionUpdateKey: InstructionUpdateKey,
    operationInputTableRows: OperationInputTableRow[],
    index: number,
  ) => {
    const { updateInstructions } = this.props;
    operationInputTableRows[index][instructionUpdateKey] = instructionPart;
    updateInstructions(operationInputTableRows);
  };

  removeTableElement = (rowIndex: number) => {
    const { operationInputTableRows, updateInstructions } = this.props;
    operationInputTableRows.splice(rowIndex, 1);
    updateInstructions(operationInputTableRows);
  };
}

export default withStyles(styles)(OperationInputTable);

/** @format */

import { Action } from 'reducers/rootReducer';
import { Team } from 'actions/types';
export interface TeamDataReducerState {
  loading: boolean;
  data?: Team;
}

const teamDataReducerInitialState: TeamDataReducerState = {
  loading: false,
};

export default (
  state: TeamDataReducerState = teamDataReducerInitialState,
  action: Action,
): TeamDataReducerState => {
  const newState = Object.assign({}, state);
  const { payload } = action;

  switch (action.type) {
    case 'FETCH_USER_TEAM_REQUEST':
      newState.loading = true;
      return newState;
    case 'FETCH_USER_TEAM_SUCCESS':
      newState.loading = false;
      newState.data = payload.team;
      return newState;
    default:
      return state;
  }
};

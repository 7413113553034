/** @format */

import React from 'react';
import _ from 'underscore';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import ClauseBuilder from 'components/flowBuilder/clauseBuilder';
import PivotOperationBody from 'components/flowBuilder/editOperationDrawer/pivotOperationBody';
import StepBlockTag from 'components/flowBuilder/stepBlockTag';

import {
  calculateInstructionsComplete,
  calculateInstructionsChanged,
  pivotAggComplete,
} from 'utils/flowUtils';
import { trackEvent } from 'analytics/exploAnalytics';
import { PivotOperationInstructionsDTO, PivotOperationAggregation } from 'constants/types';
import { Dataset } from 'actions/types';

const styles = (theme: Theme) => ({
  fillerText: {
    fontSize: 16,
    fontWeight: 400,
  },
});

type PassedProps = {
  clearRecentlyAddedOpId: () => {
    type: string;
  };
  computeLoading?: boolean;
  instructions: PivotOperationInstructionsDTO;
  openFlyout?: boolean;
  operationId: number;
  scrollToColumn: (columnName: string) => void;
  sourceDataset: Dataset;
  updateOperationInstructionAndCompute: (
    operationId: number,
    newInstructions: PivotOperationInstructionsDTO,
    shouldCompute: boolean,
  ) => void;
  summaryView?: boolean;
};

type State = {
  editDrawerOpen: boolean;
};

type Props = PassedProps & WithStyles<typeof styles>;

class CalculateOperationClauseBuilder extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    if (props.openFlyout) {
      props.clearRecentlyAddedOpId();
    }

    this.state = {
      editDrawerOpen: props.openFlyout || false,
    };
  }

  render() {
    const { instructions, computeLoading, summaryView } = this.props;

    return (
      <ClauseBuilder
        computeLoading={computeLoading}
        editDrawerOpen={this.state.editDrawerOpen}
        openDrawer={() => this.setState({ editDrawerOpen: true })}
        closeDrawer={() => this.setState({ editDrawerOpen: false })}
        editOperationDrawerTitle="Define the column statistics"
        tooltipSuggestionIsOpen={!calculateInstructionsComplete(instructions)}
        editOperationBody={this.renderOperationEditBody()}
        clauseText={this.renderCalculateText()}
        summaryView={summaryView}
      />
    );
  }

  renderCalculateText = (): JSX.Element[] | null => {
    const { classes, operationId, instructions } = this.props;

    const phrases = [
      <div className={classes.fillerText} key={`col_stats_where_${operationId}`}>
        where
      </div>,
    ];
    const completeAggs = instructions.aggregations.filter(pivotAggComplete);
    _.each(completeAggs, (agg, index) => {
      phrases.push(
        <StepBlockTag
          key={`col_stats_agg_${index}_${operationId}`}
          content={`${agg.type!.name}( ${this.renderCalculateTextParameters(agg)} )`}
        />,
      );

      if (index !== completeAggs.length - 1) {
        phrases.push(
          <div className={classes.fillerText} key={`col_stats_where_${index}_${operationId}_and`}>
            and
          </div>,
        );
      }
    });

    return phrases.length > 2 ? phrases : null; // if no clauses were added, don't return anything
  };

  renderCalculateTextParameters = (agg: PivotOperationAggregation) => {
    return agg.aggedOnColumn!.name;
  };

  renderOperationEditBody = () => {
    const {
      sourceDataset,
      instructions,
      operationId,
      updateOperationInstructionAndCompute,
    } = this.props;

    return (
      <PivotOperationBody
        operationId={operationId}
        instructions={instructions}
        sourceDataset={sourceDataset}
        onSubmit={(newInstructions) => {
          const instructionHasChanged = calculateInstructionsChanged(instructions, newInstructions);
          updateOperationInstructionAndCompute(
            operationId,
            newInstructions,
            instructionHasChanged && calculateInstructionsComplete(newInstructions),
          );
          this.setState({ editDrawerOpen: false });
          trackEvent('Update and compute Calculate', {
            operation_id: operationId,
            new_instructions: newInstructions,
            formula_instructions_changed: instructionHasChanged,
          });
        }}
        noPivotedCol={true}
      />
    );
  };
}

export default withStyles(styles)(CalculateOperationClauseBuilder);

/** @format */
import React, { SetStateAction, Dispatch } from 'react';

import { Intent, Spinner, NonIdealState } from '@blueprintjs/core';
import { ActionFn } from 'actions/actionUtils';
import { Theme, makeStyles } from '@material-ui/core/index';
import { FlowReducerState } from 'reducers/flowReducer';
import { SelectedNode, FlowGraph } from './flowGraphPageV3';
import { CONNECTOR_LINE_THICKNESS } from 'pages/flowGraphPage/flowNodeV3';
import { DatasetFlowNode } from 'actions/types';
import { deriveFlowGraphFromFlowData } from './flowGraphPageUtils';
import FlowGraphTree from './flowGraphTree';

// if the user clicks on a div with this ID, that will deselect the currently selected node
export const BACKGROUND_CANVAS = 'BACKGROUND_CANVAS';

const useStyles = makeStyles((theme: Theme) => ({
  loadingBody: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  treesContainer: {
    position: 'relative',
  },
  treeListContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: 'fit-content',
  },
  treeContainer: {
    margin: `0px ${theme.spacing(20)}px`,
  },
  parentChildContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'fit-content',
  },
  siblingContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  nodeLineContainer: {
    position: 'relative',
  },
  connectorLine: {
    position: 'absolute',
    border: `${CONNECTOR_LINE_THICKNESS}px solid ${theme.palette.darkBlue}`,
  },
  connectorFirst: {
    right: 0,
    width: '50%',
  },
  connectorMiddle: {
    width: '100%',
  },
  connectorLast: {
    width: '50%',
  },
}));

type Props = {
  flowDataLoading: boolean;
  flowDataError: boolean;
  selectedNode?: SelectedNode;
  addStepModalOpen: boolean;
  addDatasetModalOpen: boolean;
  setAddStepModalOpen: Dispatch<SetStateAction<boolean>>;
  flowId: string;
  flowData: FlowReducerState;
  updateFlowNodePos: ActionFn;
};

const renderFlowTrees = (classes: ReturnType<typeof useStyles>, props: Props) => {
  const { flowData } = props;
  const flowGraph: FlowGraph = flowData && deriveFlowGraphFromFlowData(flowData);
  return (
    <div id={BACKGROUND_CANVAS} className={classes.treesContainer}>
      <div id={BACKGROUND_CANVAS} className={classes.treeListContainer}>
        {flowGraph.datasetFlowNodes.map((datasetFlowNode: DatasetFlowNode, index: number) => (
          <FlowGraphTree
            key={datasetFlowNode.id}
            {...props}
            flowGraph={flowGraph}
            datasetFlowNode={datasetFlowNode}
          />
        ))}
      </div>
    </div>
  );
};

const FlowGraphContent = (props: Props) => {
  const classes = useStyles();
  const { flowDataLoading, flowDataError } = props;

  if (flowDataLoading) {
    return (
      <div className={classes.loadingBody}>
        <Spinner intent={Intent.PRIMARY} size={Spinner.SIZE_STANDARD} />
      </div>
    );
  } else if (flowDataError) {
    return <NonIdealState icon="error" title="Error loading the flowData" />;
  } else {
    return renderFlowTrees(classes, props);
  }
};

export default FlowGraphContent;

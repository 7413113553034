/** @format */

import { defineAction } from 'actions/actionUtils';

export const fetchDatabaseHostedSelectionList = defineAction(
  'FETCH_DATABASE_HOSTED_SELECTION_LIST',
  'onboarding',
  '',
  'GET',
);

export const updateSetupFlowStepKey = defineAction(
  'UPDATE_SETUP_FLOW_STEP_KEY',
  'onboarding',
  'update',
  'PUT',
);

export const createDatabaseHostedSelectionModel = defineAction(
  'CREATE_DATABASE_HOSTED_SELECTION_MODEL',
  'onboarding',
  'create_database_hosted_selection_model',
  'POST',
);

export const setupFlowCSVFileUpload = (file: File) => ({
  type: 'SETUP_FLOW_CSV_FILE_UPLOAD',
  payload: {
    file,
  },
});

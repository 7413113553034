/** @format */

import {
  Button,
  ButtonGroup,
  H3,
  Intent,
  NonIdealState,
  Position,
  Spinner,
  Tooltip,
  IconName,
} from '@blueprintjs/core';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withStyles, WithStyles } from '@material-ui/styles';
import { bulkFetchDatasetsSuccess } from 'actions/datasetCacheActions';
import { Dataset } from 'actions/types';
import cx from 'classnames';
import SelectDatasetModal from 'components/fileSystem/selectDatasetModal';
import CancelClauseButton from 'components/flowBuilder/editOperationDrawer/cancelClauseButton';
import EditDrawerDataPreview from 'components/flowBuilder/editOperationDrawer/editDrawerDataPreview';
import OperationDropdownInput from 'components/flowBuilder/operationDropdownInput';
import { FLYOUT_DATA_PREVIEW_HEADER, JOINS, JOIN_TYPES } from 'constants/flowConstants';
import { ColumnInfo, ColumnToJoinOn, EnrichOperationInstructions, JoinType } from 'constants/types';
import { SCHEMA_DATA_TYPES_BY_ID } from 'constants/flowConstants';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import _ from 'underscore';
import {
  getJoinOperationFooterErrorInformation,
  getJoinOperationRowErrorInformation,
} from 'utils/operationErrorInfoUtils/joinOperationErrorInfoUtil';
import OperationDrawerFooter from './operationDrawerFooter';

const styles = (theme: Theme) =>
  createStyles({
    columnsToJoinOnContainer: {
      margin: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
      marginBottom: theme.spacing(6),
      backgroundColor: theme.palette.white,
      boxShadow: theme.customShadows.basic,
      borderRadius: 4,
    },
    joinTableButtonGroup: {
      marginRight: theme.spacing(2),
      height: '30px',
    },
    joinTableButton: {
      marginLeft: theme.spacing(6),
    },
    tableSelectModalHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: `${theme.spacing(3)}px 0`,
      boxSizing: 'content-box',
    },
    tableSelectModalHeaderTitle: {
      marginRight: theme.spacing(4),
    },
    modalHeaderButton: {
      marginRight: theme.spacing(4),
    },
    root: {
      height: '100%',
    },
    body: {
      height: 'calc(100% - 54px)',
      overflowY: 'auto',
    },
    selectionTitle: {
      margin: theme.spacing(6),
    },
    tableHeaderTitle: {
      margin: theme.spacing(6),
      marginRight: theme.spacing(2),
    },
    clauseTable: {
      width: '100%',
    },
    addClauseButton: {
      margin: theme.spacing(3),
      marginLeft: 52,
    },
    filtersFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    noColsSelected: {
      height: 200,
      marginBottom: theme.spacing(10),
    },
    selectTableHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    formControl: {
      margin: theme.spacing(6),
    },
    flyoutSpacing: {
      marginBottom: theme.spacing(6),
    },
    loadingBody: {
      height: '40vh',
      display: 'flex',
      justifyContent: 'center',
    },
    buttonErrorState: {
      boxShadow: `inset 0 0 0 1px ${theme.palette.dangerRed} !important`,
    },
  });

type PassedProps = {
  instructions: EnrichOperationInstructions;
  joinDataset: Dataset | null;
  operationId: number;
  sourceDataset: Dataset;
  sourceDataSourceId: number;
  onSubmit: (newInstructions: EnrichOperationInstructions) => void;
};

type Props = PassedProps &
  WithStyles<typeof styles> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type State = {
  joinDataset: Dataset | null;
  isJoinTableLoading: boolean;
  instructions: EnrichOperationInstructions;
  tableSelectModalOpen: boolean;
};

class JoinEditOperationBody extends React.Component<Props, State> {
  readonly state: State = {
    joinDataset: this.props.joinDataset,
    isJoinTableLoading: false,
    instructions: cloneDeep(this.props.instructions),
    tableSelectModalOpen: false,
  };

  render() {
    const { classes, onSubmit, sourceDataset } = this.props;
    const { instructions, joinDataset } = this.state;
    const sourceSchemaByColumnName = _.indexBy(sourceDataset.cached_schema!, 'name');
    const joinSchemaByColumnName = joinDataset ? _.indexBy(joinDataset.cached_schema!, 'name') : {};
    const { footerErrorState, footerErrorText } = getJoinOperationFooterErrorInformation(
      instructions,
      sourceSchemaByColumnName,
      joinSchemaByColumnName,
    );
    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <EditDrawerDataPreview
            sourceDataset={sourceDataset}
            dataPreviewHeader={FLYOUT_DATA_PREVIEW_HEADER}
          />
          <div className={classes.selectTableHeader}>
            <H3 className={classes.tableHeaderTitle}>Select a Join Table:</H3>
            {this.renderTableSelector()}
          </div>
          {this.renderJoinTable()}
          {joinDataset && joinDataset.cached_schema && (
            <div className={classes.flyoutSpacing}>
              <H3 className={classes.selectionTitle}>Configure Join</H3>
              {this.rendercolumnsToJoinOnelector(footerErrorText)}
              <H3 className={classes.selectionTitle}>Select the Join Type</H3>
              {this.renderJoinTypeSelector()}
            </div>
          )}
        </div>
        <OperationDrawerFooter
          errorState={footerErrorState}
          errorText={footerErrorText}
          onSubmit={() => onSubmit(instructions)}
        />
      </div>
    );
  }

  updateJoinType = (selectedJoinType: JoinType) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.joinType = selectedJoinType.id;
      return { instructions };
    });
  };

  renderJoinTypeSelector = () => {
    const { classes } = this.props;
    const { instructions } = this.state;
    return (
      <OperationDropdownInput
        className={classes.formControl}
        showIcon={true}
        selectedItem={instructions.joinType ? JOIN_TYPES[instructions.joinType] : undefined}
        onChange={this.updateJoinType}
        options={JOINS}
        noSelectionText="Join Type"
      />
    );
  };

  renderJoinTable = () => {
    const { classes } = this.props;
    const { instructions, joinDataset, isJoinTableLoading } = this.state;
    if (isJoinTableLoading) {
      return (
        <div className={classes.loadingBody}>
          <Spinner intent={Intent.PRIMARY} size={Spinner.SIZE_STANDARD} />
        </div>
      );
    }
    if (!joinDataset || !joinDataset.cached_schema) {
      return (
        <NonIdealState
          className={classes.noColsSelected}
          icon="search"
          title="Select a table to get started"
          description="The table you select will be used as the join table."
        />
      );
    }

    return (
      <EditDrawerDataPreview
        sourceDataset={joinDataset}
        dataPreviewHeader={
          (instructions.joinTable && instructions.joinTable.name) || 'Join Table Data'
        }
      />
    );
  };

  rendercolumnsToJoinOnelector = (footerErrorText?: string) => {
    const { classes } = this.props;
    const { instructions } = this.state;

    return (
      <div className={classes.columnsToJoinOnContainer}>
        <table className={cx(classes.clauseTable, 'bp3-html-table')}>
          <thead>
            <tr>
              <th></th>
              <th>Source Column</th>
              <th>Join Column</th>
            </tr>
          </thead>
          <tbody>
            {instructions.columnsToJoinOn.map((joinClause: ColumnToJoinOn, index: number) =>
              this.renderJoinClause(joinClause, index, footerErrorText),
            )}
          </tbody>
        </table>
        <div className={classes.filtersFooter}>
          <Button className={classes.addClauseButton} icon="add" onClick={this.addJoinClause}>
            Add Join Clause
          </Button>
        </div>
      </div>
    );
  };

  renderJoinClause = (joinClause: ColumnToJoinOn, index: number, footerErrorText?: string) => {
    const { operationId, sourceDataset } = this.props;
    const { joinDataset } = this.state;
    const sourceSchemaByColumnName = _.indexBy(sourceDataset.cached_schema!, 'name');
    const joinSchemaByColumnName = joinDataset ? _.indexBy(joinDataset.cached_schema!, 'name') : {};

    const {
      isSourceColButtonErrorState,
      isJoinColButtonErrorState,
      errorText,
    } = getJoinOperationRowErrorInformation(
      joinClause,
      index,
      sourceSchemaByColumnName,
      joinSchemaByColumnName,
    );

    return (
      <tr key={`filter_clause_${operationId}}_${index}`}>
        <CancelClauseButton index={index} onClick={() => this.removeJoinClause(index)} />
        <td>
          {this.renderSourceColumnSelector(
            joinClause.sourceColumn,
            index,
            errorText === footerErrorText && isSourceColButtonErrorState,
          )}
        </td>
        <td>
          {this.renderJoinColumnSelector(
            joinClause.joinColumn,
            index,
            errorText === footerErrorText && isJoinColButtonErrorState,
          )}
        </td>
      </tr>
    );
  };

  renderSourceColumnSelector = (
    selectedColumn: string,
    index: number,
    buttonErrorState: boolean,
  ) => {
    const { sourceDataset } = this.props;
    const schemaByColumnName = _.indexBy(sourceDataset.cached_schema!, 'name');

    return (
      <OperationDropdownInput
        buttonErrorState={buttonErrorState}
        selectedItem={
          selectedColumn
            ? {
                name: selectedColumn,
                id: selectedColumn,
                icon: SCHEMA_DATA_TYPES_BY_ID[schemaByColumnName[selectedColumn].type]
                  .icon as IconName,
              }
            : undefined
        }
        onChange={(item) => {
          this.selectSourceColumn(index, schemaByColumnName[item.id]);
        }}
        options={sourceDataset.cached_schema!.map((col) => ({
          name: col.name,
          id: col.name,
          icon: SCHEMA_DATA_TYPES_BY_ID[schemaByColumnName[col.name].type].icon as IconName,
        }))}
        noSelectionText="Source Column"
        showIcon={true}
      />
    );
  };

  renderJoinColumnSelector = (selectedColumn: string, index: number, buttonErrorState: boolean) => {
    const { joinDataset } = this.state;
    if (!joinDataset || !joinDataset.cached_schema) {
      throw new Error(
        'Internal Error: The joinTableSchema is missing when a Join Table is Selected. Please try again or contact support',
      );
    }
    const joinTableSchemaByColumnName = _.indexBy(joinDataset.cached_schema, 'name');

    return (
      <OperationDropdownInput
        buttonErrorState={buttonErrorState}
        selectedItem={
          selectedColumn
            ? {
                name: selectedColumn,
                id: selectedColumn,
                icon: SCHEMA_DATA_TYPES_BY_ID[joinTableSchemaByColumnName[selectedColumn].type]
                  .icon as IconName,
              }
            : undefined
        }
        onChange={(item) => {
          this.selectJoinColumn(index, joinTableSchemaByColumnName[item.id]);
        }}
        options={joinDataset.cached_schema.map((col) => ({
          name: col.name,
          id: col.name,
          icon: SCHEMA_DATA_TYPES_BY_ID[joinTableSchemaByColumnName[col.name].type]
            .icon as IconName,
        }))}
        noSelectionText="Join Column"
        showIcon={true}
      />
    );
  };

  selectSourceColumn = (index: number, columnObj: ColumnInfo) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.columnsToJoinOn[index].sourceColumn = columnObj.name;
      return { instructions };
    });
  };

  selectJoinColumn = (index: number, columnObj: ColumnInfo) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.columnsToJoinOn[index].joinColumn = columnObj.name;
      return { instructions };
    });
  };

  addJoinClause = () => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.columnsToJoinOn.push({
        joinColumn: '',
        sourceColumn: '',
      });
      return { instructions };
    });
  };

  removeJoinClause = (index: number) => {
    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.columnsToJoinOn.splice(index, 1);
      return { instructions };
    });
  };

  renderTableSelector = () => {
    const { classes } = this.props;
    const { tableSelectModalOpen, instructions } = this.state;

    const selectedTable = instructions.joinTable;

    let button = (
      <Button
        className={cx(classes.joinTableButton, {
          [classes.buttonErrorState]: instructions.joinTable.name === '',
        })}
        icon="th"
        onClick={() => this.setState({ tableSelectModalOpen: true })}>
        {selectedTable.name ? selectedTable.name : 'Select Table'}
      </Button>
    );
    if (selectedTable.datasetId) {
      button = (
        <>
          <ButtonGroup className={classes.joinTableButtonGroup}>
            {button}
            <Tooltip content="View the dataset" position={Position.TOP}>
              <a
                href={`/datasets/${selectedTable.datasetId}`}
                target="_blank"
                rel="noopener noreferrer">
                <Button icon="eye-open" />
              </a>
            </Tooltip>
          </ButtonGroup>
        </>
      );
    }

    return (
      <>
        {button}
        {tableSelectModalOpen && (
          <SelectDatasetModal
            isOpen={tableSelectModalOpen}
            onClose={() => this.setState({ tableSelectModalOpen: false })}
            renderDatasetActions={this.renderSelectDatasetActions}
            editingDisabled
          />
        )}
      </>
    );
  };

  renderSelectDatasetActions = (selectedDataset: any | null, onClose: () => void) => {
    return (
      <Button
        intent={Intent.PRIMARY}
        disabled={selectedDataset === null || !selectedDataset.results_last_updated_at}
        onClick={() => {
          this.onJoinTableSelect(selectedDataset);
          onClose();
        }}>
        Submit
      </Button>
    );
  };

  onJoinTableSelect = (dataset: any) => {
    const { instructions } = this.state;

    this.setState({ tableSelectModalOpen: false });
    if (instructions.joinTable.datasetId === dataset.id) {
      return;
    }

    this.setState((prevState) => {
      const { instructions } = prevState;
      instructions.joinTable.datasetId = dataset.id;
      instructions.joinTable.name = dataset.name || dataset.data_source_table_name;
      instructions.columnsToJoinOn = [{ joinColumn: '', sourceColumn: '' }];
      return {
        instructions,
        tableSelectModalOpen: false,
        joinDataset: dataset,
      };
    });
    this.props.bulkFetchDatasetsSuccess([dataset]);
  };
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  bulkFetchDatasetsSuccess: (datasets: Array<any>) => dispatch(bulkFetchDatasetsSuccess(datasets)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(JoinEditOperationBody));

/** @format */

import {
  FILTER_OPERATORS_BY_ID,
  TIMESTAMP,
  STRING,
  INTEGER_DATA_TYPE,
  FLOAT,
  DATE,
  DATETIME,
  BOOLEAN,
  UNSIGNED_INTEGER,
} from 'constants/flowConstants';

export const DASHBOARD_FILTER_TYPES_BY_ID = {
  DATE: {
    id: 'DATE',
    name: 'Dates',
    description: 'Date range, relative date, time of day, etc.',
  },
  VALUES: {
    id: 'VALUES',
    name: 'Categories',
    description: 'Type, Model, Rating, etc.',
  },
  NUMBERS: {
    id: 'NUMBERS',
    name: 'Numbers',
    description: 'Price, Aggregations, Page Views, etc.',
  },
};

export const DASHBOARD_FILTER_TYPES = [
  DASHBOARD_FILTER_TYPES_BY_ID.DATE,
  DASHBOARD_FILTER_TYPES_BY_ID.VALUES,
  DASHBOARD_FILTER_TYPES_BY_ID.NUMBERS,
];

export const INITIAL_OPERATOR_BY_FILTER_TYPE = {
  [DASHBOARD_FILTER_TYPES_BY_ID.DATE.id]: FILTER_OPERATORS_BY_ID.DATE_IS,
  [DASHBOARD_FILTER_TYPES_BY_ID.VALUES.id]: FILTER_OPERATORS_BY_ID.STRING_IS,
  [DASHBOARD_FILTER_TYPES_BY_ID.NUMBERS.id]: FILTER_OPERATORS_BY_ID.NUMBER_EQ,
};

export const DASHBOARD_FILTER_TYPES_TO_DATA_TYPES = {
  [DASHBOARD_FILTER_TYPES_BY_ID.DATE.id]: [TIMESTAMP, DATE, DATETIME],
  [DASHBOARD_FILTER_TYPES_BY_ID.VALUES.id]: [STRING, BOOLEAN],
  [DASHBOARD_FILTER_TYPES_BY_ID.NUMBERS.id]: [INTEGER_DATA_TYPE, FLOAT, UNSIGNED_INTEGER],
};

/** @format */

import { Operation, DashboardFilter } from 'actions/types';
import cloneDeep from 'lodash/cloneDeep';
import { Action } from 'reducers/rootReducer';
import _ from 'underscore';
import { v4 } from 'uuid';

import { INITIAL_OPERATOR_BY_FILTER_TYPE } from 'constants/dashboardConstants';

export interface DashboardInfo {
  name: string;
  id: number;
  loading?: boolean;
  created: string;
  modified: string;
}

export interface DashboardPageInfo {
  name: string;
  id: number;
  grid_configuration: any;
  operations: any[];
  filters: DashboardFilter[];
}

export interface DashboardReducerState {
  error?: boolean;
  data?: DashboardPageInfo;
  dashboardList?: DashboardInfo[];
  dashboardGroupingsList?: any;
  selectedDashBoardId?: number | null;
  projectsIsCollapsed?: boolean[];
}

const dashboardReducerInitialState: DashboardReducerState = {
  selectedDashBoardId: null,
};

export default (state = dashboardReducerInitialState, action: Action): DashboardReducerState => {
  const newState = cloneDeep(state);

  switch (action.type) {
    case 'FETCH_DASHBOARD_REQUEST':
      newState.selectedDashBoardId = Number.parseInt(action.payload.id);
      return newState;
    case 'FETCH_DASHBOARD_SUCCESS':
      newState.data = action.payload.dashboard;
      newState.data!.filters.map(
        (filter) =>
          // @ts-ignore
          (filter.filter_operator = INITIAL_OPERATOR_BY_FILTER_TYPE[filter.filter_type]),
      );
      return newState;
    case 'FETCH_DASHBOARD_ERROR':
      newState.error = true;
      return newState;
    case 'UPDATE_DASHBOARD_LAYOUT_REQUEST':
      newState.data!.grid_configuration = action.payload.postData.layout_config;
      return newState;
    case 'FETCH_DASHBOARD_LIST_SUCCESS':
      newState.dashboardList = action.payload.dashboard_list;
      newState.dashboardGroupingsList = action.payload.dashboard_groupings_list;
      newState.projectsIsCollapsed = _.times(
        action.payload.dashboard_list.length,
        _.constant(false),
      );
      return newState;
    case 'UPDATE_OPERATION_TITLE_SUCCESS':
      _.each(newState.data!.operations, (operation: Operation) => {
        if (operation.id === action.payload.postData.operation_id) {
          operation.title = action.payload.postData.title;
        }
      });
      return newState;
    case 'REMOVE_OPERATION_FROM_DASHBOARD_REQUEST':
      const removeOpId = action.payload.postData.operation_id;
      const removeIndex = _.findIndex(
        newState.data!.operations,
        (op: Operation) => op.id === removeOpId,
      );
      newState.data!.operations.splice(removeIndex, 1);

      if (newState.data) {
        _.map(newState.data.filters, (filter) => {
          delete filter.operation_fields[removeOpId];
        });
      }

      return newState;
    case 'CREATE_DASHBOARD_SUCCESS':
      if (newState.dashboardList) {
        const newDashboard = action.payload.new_dashboard;
        newDashboard.new = true;
        newState.dashboardList.push(newDashboard);
      }
      return newState;
    case 'SWITCH_SELECTED_DATA_SOURCE':
      newState.selectedDashBoardId = null;
      return newState;
    case 'REFRESH_DASHBOARD_OPERATION_REQUEST':
      const operationIndex = _.findIndex(
        newState.data!.operations,
        (op: Operation) => op.id === action.payload.id,
      );
      newState.data!.operations[operationIndex].loading = true;
      return newState;
    case 'REFRESH_DASHBOARD_OPERATION_SUCCESS':
      const opIndex = _.findIndex(
        newState.data!.operations,
        (op: Operation) => op.id === action.payload.id,
      );
      const newOpData = {
        ...newState.data!.operations[opIndex],
        ...action.payload.operation,
        loading: false,
      };
      newState.data!.operations[opIndex] = newOpData;
      return newState;
    case 'REFRESH_DASHBOARD_OPERATION_ERROR':
      const opIn = _.findIndex(
        newState.data!.operations,
        (op: Operation) => op.id === action.payload.id,
      );
      newState.data!.operations[opIn].loading = false;
      return newState;
    case 'RENAME_DASHBOARD_REQUEST':
    case 'DELETE_DASHBOARD_REQUEST':
      _.findWhere(newState.dashboardList!, { id: parseInt(action.payload.id) })!.loading = true;
      return newState;
    case 'RENAME_DASHBOARD_ERROR':
    case 'DELETE_DASHBOARD_ERROR':
      _.findWhere(newState.dashboardList!, { id: parseInt(action.payload.id) })!.loading = false;
      return newState;
    case 'RENAME_DASHBOARD_SUCCESS':
      let dashboardInfo = _.findWhere(newState.dashboardList!, { id: parseInt(action.payload.id) });
      dashboardInfo!.loading = false;
      dashboardInfo!.name = action.payload.postData.name;
      newState.data!.name = action.payload.postData.name;
      return newState;
    case 'DELETE_DASHBOARD_SUCCESS':
      newState.dashboardList = _.reject(newState.dashboardList!, (dashboardInfo) => {
        return dashboardInfo.id === parseInt(action.payload.id);
      });
      return newState;
    case 'CREATE_DASHBOARD_FILTER_SUCCESS':
      if (newState.data) {
        newState.data.filters.push({
          ...action.payload.dashboard_filter,
          filter_operator:
            // @ts-ignore
            INITIAL_OPERATOR_BY_FILTER_TYPE[action.payload.dashboard_filter.filter_type],
        });
      }
      return newState;
    case 'DELETE_DASHBOARD_FILTER_SUCCESS':
      if (newState.data) {
        newState.data.filters = _.reject(
          newState.data.filters,
          (filter) => filter.id === action.payload.postData.filter_id,
        );
      }
      return newState;
    case 'UPDATE_DASHBOARD_FILTER_FIELD_REQUEST':
      if (newState.data) {
        const {
          filter_id,
          operation_id,
          col_name,
          col_type,
          source_type,
        } = action.payload.postData;
        const filter = _.findWhere(newState.data.filters, {
          id: action.payload.postData.filter_id,
        });
        const filterField = filter!.operation_fields[operation_id];
        if (filterField) {
          filter!.operation_fields[operation_id] = {
            ...filterField,
            column_name: col_name,
            column_type: col_type,
          };
        } else {
          filter!.operation_fields[operation_id] = {
            id: v4(),
            dashboard_filter_id: filter_id,
            operation_id: operation_id,
            column_name: col_name,
            column_type: col_type,
            source_type: source_type,
          };
        }
      }
      return newState;
    case 'UPDATE_DASHBOARD_FILTER_FIELD_SUCCESS':
      if (newState.data) {
        const { operation_id } = action.payload.postData;
        const filter = _.findWhere(newState.data.filters, {
          id: action.payload.postData.filter_id,
        });
        const filterField = filter!.operation_fields[operation_id];
        filterField.id = action.payload.filter_field.id;
      }
      return newState;
    case 'UPDATE_FILTER_VALUE':
      if (newState.data) {
        const filter = _.findWhere(newState.data.filters, {
          id: action.payload.filterId,
        });
        filter!.value = action.payload.newValue;
      }
      return newState;
    case 'UPDATE_FILTER_OPERATOR':
      if (newState.data) {
        const filter = _.findWhere(newState.data.filters, {
          id: action.payload.filterId,
        });
        filter!.filter_operator = action.payload.newOperator;
      }
      return newState;
    case 'FETCH_DASHBOARD_OPERATION_ROW_COUNT_SUCCESS':
      const rowCountOp: Operation = _.find(
        newState.data!.operations,
        (op: Operation) => op.id === action.payload.id,
      );
      rowCountOp.results_dataset.total_row_count = action.payload.row_count;
      return newState;
    case 'UPDATE_PROJECTS_IS_COLLAPSED':
      newState.projectsIsCollapsed = action.payload.projectsIsCollapsed;
      return newState;
    default:
      return newState;
  }
};

/** @format */

import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Drawer } from '@blueprintjs/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '80% !important',
    maxWidth: 900,
  },
  drawerBody: {
    height: 'calc(100vh - 40px)',
    backgroundColor: theme.palette.primary.veryLight,
  },
}));

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  editOperationBody: ReactNode;
};

const EditOperationDrawer = (props: Props) => {
  const classes = useStyles();

  // optimization to not waste cycles rendering this in the background if it is not open
  if (!props.isOpen) {
    return <></>;
  }

  return (
    <Drawer
      className={classes.root}
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={props.title}>
      <div className={classes.drawerBody}>{props.editOperationBody}</div>
    </Drawer>
  );
};

export default EditOperationDrawer;

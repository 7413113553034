/** @format */

import React, { useState, ReactNode } from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

import Caption from 'explo-ds/typography/marketing/caption';
import Title from 'explo-ds/typography/marketing/title';
import TitleDescription from 'explo-ds/typography/marketing/titleDescription';
import InputField from 'explo-ds/forms/marketing/inputField';
import Button from 'explo-ds/buttons/button';

import { Intent } from '@blueprintjs/core';

import { addEmailToWaitlist } from 'utils/landingPageUtils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: 175,

    [theme.breakpoints.down('xs')]: {
      marginTop: 50,
    },
  },
  logo: {
    height: 30,
  },
  captionContainer: {},
  captionText: {
    color: theme.palette.exploBlue100,

    animation: 'captionFloatIn',
    animationDuration: '1.5s',
    animationDelay: '0s',
    transitionTimingFunction: 'ease-out',
  },
  titleContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),

    animation: 'headerFloatIn',
    animationDuration: '1.5s',
    animationDelay: '0s',
    transitionTimingFunction: 'ease-out',
  },
  title1: {
    color: theme.palette.white,
  },
  title2: {
    color: theme.palette.exploBlue,
  },
  titleDescriptionContainer: {
    marginBottom: theme.spacing(24),
    maxWidth: '80%',

    animation: 'descriptionFloatIn',
    animationDuration: '1.5s',
    animationDelay: '0.4s',
    animationFillMode: 'both',
    transitionTimingFunction: 'ease-out',

    [theme.breakpoints.down('xs')]: {
      maxWidth: 'none',
      marginBottom: theme.spacing(10),
    },
  },
  titleDescription: {
    color: theme.palette.grey100,
  },
  signupInputContainer: {
    display: 'flex',
    alignItems: 'center',

    animation: 'signupFlowIn',
    animationDuration: '1.5s',
    animationDelay: '0.6s',
    animationFillMode: 'both',
    transitionTimingFunction: 'ease-out',

    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  signupInput: {
    width: 400,
    marginRight: theme.spacing(5),

    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: theme.spacing(3),
      width: '100%',
      textAlign: 'center',
    },
  },

  signupButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

type Props = {
  className?: string;
  caption?: string;
  title1: string;
  title2: string;
  description: ReactNode;
  onSignupSuccess: (email: string) => void;
  onSignupFail: (errorMsg: string) => void;
  noSignup?: boolean;
};

const SignupHero = (props: Props) => {
  const [signupEmail, setSignupEmail] = useState('');

  const emailEntered = (email: string) => {
    addEmailToWaitlist(
      email,
      () => {
        setSignupEmail('');
        props.onSignupSuccess(signupEmail);
        window.location.href = 'https://form.typeform.com/to/jyPnu9GP';
      },
      (response: any) => {
        props.onSignupFail(response.msg);
      },
      'landing_page',
    );
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.captionContainer}>
        {props.caption && <Caption className={classes.captionText}>{props.caption}</Caption>}
      </div>
      <div className={classes.titleContainer}>
        <Title className={classes.title1}>{props.title1}</Title>
        <Title className={classes.title2}>{props.title2}</Title>
      </div>
      <div className={classes.titleDescriptionContainer}>
        <TitleDescription className={classes.titleDescription}>
          {props.description}
        </TitleDescription>
      </div>
      {!props.noSignup && (
        <div className={classes.signupInputContainer}>
          <InputField
            className={classes.signupInput}
            value={signupEmail}
            onChange={(e: any) => setSignupEmail(e.target.value)}
            placeholder="Enter your email address"
          />
          <Button
            className={classes.signupButton}
            intent={Intent.PRIMARY}
            onClick={() => emailEntered(signupEmail)}
            large>
            Request Early Access
          </Button>
        </div>
      )}
    </div>
  );
};

export default SignupHero;

/** @format */

import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import _ from 'underscore';
import cx from 'classnames';
import { Button, H3, Icon, Position, Switch, Tooltip } from '@blueprintjs/core';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withStyles, WithStyles } from '@material-ui/styles';

import { Dataset } from 'actions/types';
import CancelClauseButton from 'components/flowBuilder/editOperationDrawer/cancelClauseButton';
import EditDrawerDataPreview from 'components/flowBuilder/editOperationDrawer/editDrawerDataPreview';
import FilterClauseEditorRow from 'components/flowBuilder/editOperationDrawer/filterClauseEditorRow';
import OperationDropdownInput from 'components/flowBuilder/operationDropdownInput';
import {
  FILTER_OPERATORS,
  FILTER_OPERATOR_TYPES,
  FILTER_OPS_NO_VALUE,
  FLYOUT_DATA_PREVIEW_HEADER,
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
} from 'constants/flowConstants';
import { FilterClause, FilterOperatorType } from 'constants/types';
import {
  getFilterOperationFooterErrorInformation,
  getFilterRowErrorInformation,
} from 'utils/operationErrorInfoUtils/filterOperationErrorInfoUtil';
import OperationDrawerFooter from './operationDrawerFooter';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    body: {
      height: 'calc(100% - 54px)',
      overflowY: 'auto',
    },
    selectionTitle: {
      margin: theme.spacing(6),
    },
    filterClausesContainer: {
      margin: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
      marginBottom: theme.spacing(6),
      backgroundColor: theme.palette.white,
      boxShadow: theme.customShadows.basic,
      borderRadius: 4,
    },
    clauseTable: {
      width: '100%',
    },
    addClauseButton: {
      margin: theme.spacing(3),
      marginLeft: 52,
    },
    matchOnSwitch: {
      margin: theme.spacing(3),
      marginLeft: theme.spacing(1),
      marginRight: 52,
    },
    filtersFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    filtersFooterMatchOn: {
      display: 'flex',
    },
    matchOnExplanation: {
      display: 'flex',
      margin: theme.spacing(3),
      marginRight: theme.spacing(1),
    },
    matchOnText: {
      paddingRight: 8,
    },
  });

type PassedProps = {
  filterClauses: FilterClause[];
  matchOnAll: boolean;
  operationId: number;
  sourceDataset: Dataset;
  onSubmit: (newInstructions: any) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  filterClauses: any;
  matchOnAll: any;
};

class FilterEditOperationBody extends React.Component<Props, State> {
  readonly state: State = {
    filterClauses: cloneDeep(this.props.filterClauses),
    matchOnAll: cloneDeep(this.props.matchOnAll),
  };

  render() {
    const { classes, onSubmit, sourceDataset } = this.props;
    const { filterClauses, matchOnAll } = this.state;
    const { footerErrorState, footerErrorText } = getFilterOperationFooterErrorInformation(
      filterClauses,
    );

    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <EditDrawerDataPreview
            dataPreviewHeader={FLYOUT_DATA_PREVIEW_HEADER}
            sourceDataset={sourceDataset}
          />

          <H3 className={classes.selectionTitle}>Selected Filter Conditions</H3>
          <div className={classes.filterClausesContainer}>
            <table className={cx(classes.clauseTable, 'bp3-html-table')}>
              <thead>
                <tr>
                  <th></th>
                  <th>Column</th>
                  <th>Type</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>{filterClauses.map(this.renderFilterClause)}</tbody>
            </table>
            <div className={classes.filtersFooter}>
              <Button className={classes.addClauseButton} icon="add" onClick={this.addFilterClause}>
                Add a condition
              </Button>

              <div className={classes.filtersFooterMatchOn}>
                <div className={classes.matchOnExplanation}>
                  <div className={classes.matchOnText}>Require all filter conditions are met</div>
                  <Tooltip
                    position={Position.TOP}
                    content="Choose to match on all of the above conditions or any one of the conditions.">
                    <Icon icon="help" />
                  </Tooltip>
                </div>
                <Switch
                  className={classes.matchOnSwitch}
                  checked={this.state.matchOnAll}
                  onChange={() => {
                    this.setState({
                      matchOnAll: !matchOnAll,
                    });
                  }}
                  innerLabelChecked="All"
                  innerLabel="Any"
                />
              </div>
            </div>
          </div>
        </div>
        <OperationDrawerFooter
          errorState={footerErrorState}
          errorText={footerErrorText}
          onSubmit={() => onSubmit({ filterClauses, matchOnAll })}
        />
      </div>
    );
  }

  renderColumnSelector = (
    selectedColumn: any,
    index: number,
    isFilterColumnButtonErrorState: boolean,
  ) => {
    const { sourceDataset } = this.props;
    const schemaByColumnName = _.indexBy(sourceDataset.cached_schema!, 'name');

    return (
      <OperationDropdownInput
        buttonErrorState={isFilterColumnButtonErrorState}
        selectedItem={selectedColumn && { name: selectedColumn.name, id: selectedColumn.name }}
        onChange={(item: any) => {
          this.selectFilterColumn(index, schemaByColumnName[item.id]);
        }}
        options={sourceDataset.cached_schema!.map((col) => ({ name: col.name, id: col.name }))}
        noSelectionText="Column"
      />
    );
  };

  renderOperatorSelector = (
    selectedOperator: any,
    selectedColumn: any,
    index: number,
    disabled: boolean,
    isFilterOperationButtonErrorState: boolean,
  ) => {
    const relevantOperators = _.filter(FILTER_OPERATORS, (op) =>
      op.supported_column_types.has(selectedColumn.type),
    );

    return (
      <OperationDropdownInput
        disabled={disabled}
        buttonErrorState={!disabled && isFilterOperationButtonErrorState}
        selectedItem={selectedOperator || null}
        onChange={(item: any) => {
          this.selectFilterOperator(index, FILTER_OPERATOR_TYPES[item.id]);
        }}
        options={relevantOperators.map((op) => ({ name: op.selectionValue, id: op.id }))}
        noSelectionText="Operator"
      />
    );
  };

  renderFilterClause = (filterClause: FilterClause, index: number) => {
    const { operationId } = this.props;
    const {
      isFilterColumnButtonErrorState,
      isFilterOperationButtonErrorState,
      filterValueErrorText,
      filterValueInputIntent,
      filterValueStartDateError,
      filterValueEndDateError,
    } = getFilterRowErrorInformation(filterClause, index);
    return (
      <tr key={`filter_clause_${operationId}}_${index}`}>
        <CancelClauseButton index={index} onClick={() => this.removeFilterClause(index)} />
        <td>
          {this.renderColumnSelector(
            filterClause.filterColumn,
            index,
            isFilterColumnButtonErrorState,
          )}
        </td>
        <td>
          {this.renderOperatorSelector(
            filterClause.filterOperation,
            filterClause.filterColumn,
            index,
            !filterClause.filterColumn || !filterClause.filterColumn.name,
            isFilterOperationButtonErrorState,
          )}
        </td>
        <td>
          <FilterClauseEditorRow
            filterValueErrorText={filterValueErrorText ? filterValueErrorText : undefined}
            filterValueInputIntent={filterValueInputIntent}
            filterValueStartDateError={filterValueStartDateError}
            filterValueEndDateError={filterValueEndDateError}
            filterOperation={filterClause.filterOperation}
            filterColumn={filterClause.filterColumn}
            filterValue={filterClause.filterValue}
            index={index}
            updateFilterValue={this.updateFilterValue}
            updateStartDate={this.updateStartDate}
            updateEndDate={this.updateEndDate}
          />
        </td>
      </tr>
    );
  };

  addFilterClause = () => {
    this.setState((prevState) => {
      const { filterClauses } = prevState;
      filterClauses.push({
        filterColumn: '',
        filterOperation: '',
        filterValue: '',
      });
      return { filterClauses };
    });
  };

  removeFilterClause = (index: number) => {
    this.setState((prevState) => {
      const { filterClauses } = prevState;
      filterClauses.splice(index, 1);
      return { filterClauses };
    });
  };

  selectFilterColumn = (index: number, columnObj: any) => {
    this.setState((prevState) => {
      const { filterClauses } = prevState;
      if (
        filterClauses[index].filterColumn &&
        filterClauses[index].filterColumn.type !== columnObj.type
      ) {
        filterClauses[index].filterColumn = columnObj;
        filterClauses[index].filterValue = '';
        filterClauses[index].filterOperation = '';
      } else {
        filterClauses[index].filterColumn = columnObj;
      }
      return { filterClauses };
    });
  };

  selectFilterOperator = (index: number, filterOperatorType: FilterOperatorType) => {
    this.setState((prevState) => {
      const { filterClauses } = prevState;
      filterClauses[index].filterOperation = filterOperatorType;
      if (FILTER_OPS_NO_VALUE.has(filterOperatorType.id)) {
        filterClauses[index].filterValue = '';
      } else if (FILTER_OPS_DATE_PICKER.has(filterOperatorType.id)) {
        filterClauses[index].filterValue = { startDate: null };
      } else if (FILTER_OPS_DATE_RANGE_PICKER.has(filterOperatorType.id)) {
        filterClauses[index].filterValue = { startDate: null, endDate: null };
      }
      return { filterClauses };
    });
  };

  updateFilterValue = (index: number, value: any) => {
    this.setState((prevState) => {
      const { filterClauses } = prevState;
      filterClauses[index].filterValue = value;
      return { filterClauses };
    });
  };

  updateStartDate = (index: number, startDate: string | null) => {
    this.setState((prevState) => {
      const { filterClauses } = prevState;
      if (!filterClauses[index].filterValue) {
        filterClauses[index].filterValue = {
          startDate,
        };
      } else {
        filterClauses[index].filterValue.startDate = startDate;
      }
      return { filterClauses };
    });
  };

  updateEndDate = (index: number, endDate: string | null) => {
    this.setState((prevState) => {
      const { filterClauses } = prevState;
      if (!filterClauses[index].filterValue) {
        filterClauses[index].filterValue = {
          endDate,
        };
      } else {
        filterClauses[index].filterValue.endDate = endDate;
      }
      return { filterClauses };
    });
  };
}

export default withStyles(styles)(FilterEditOperationBody);

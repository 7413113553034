/** @format */

import React, { Component } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import { Intent } from '@blueprintjs/core';
import Button from 'explo-ds/buttons/button';

import ExploLogoWhite from 'images/branding/logo_primary.png';

const styles = (theme: Theme) => ({
  root: {
    width: 250,
    height: '100vh',
    backgroundColor: theme.palette.grey900,
    boxShadow: theme.customShadows.sideNav,
    zIndex: 1,
  },
  leftNavBody: {
    height: 'calc(100vh - 46px)',
    overflow: 'auto',
    padding: theme.spacing(4),
  },
  bodyText: {
    marginBottom: theme.spacing(4),
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    height: 46,
  },
  logoImg: {
    width: 100,
    marginBottom: -5,
  },
  navDivider: {
    backgroundImage: 'linear-gradient(90deg,rgba(16,22,26,0) 0,rgba(16,22,26,.4) 40%)',
    width: '100%',
    height: 1,
  },
});

type PassedProps = {};

type Props = PassedProps & WithStyles<typeof styles>;

export class SharedPageWithLeftNav extends Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <div className={cx(classes.root, 'bp3-dark')}>
        {this.renderLogoContainer()}
        <div className={classes.navDivider}></div>
        {this.renderNavBody()}
      </div>
    );
  }

  renderLogoContainer = () => {
    const { classes } = this.props;

    return (
      <div className={classes.logoContainer}>
        <img src={ExploLogoWhite} className={classes.logoImg} alt="explo logo" />
      </div>
    );
  };

  renderNavBody = () => {
    const { classes } = this.props;

    return (
      <div className={classes.leftNavBody}>
        <div className={classes.bodyText}>
          This dashboard is view-only because you are not logged in.<br></br>
          <br></br> Create an account to start analyzing data in Explo
        </div>
        <Link to="/signup">
          <Button fill intent={Intent.PRIMARY}>
            Create Account
          </Button>
        </Link>
      </div>
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(SharedPageWithLeftNav));

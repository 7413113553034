/** @format */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import DotsDropdown from '../../components/flowGraph/dotsDropdown';
import { MenuItem } from '@blueprintjs/core';
import { removeDatasetFromFlow } from 'actions/flowActions';
import ConfirmationModal from 'components/modals/confirmationModal';
import { ReduxState } from 'reducers/rootReducer';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  operationActionsIcon: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(2),
  },
}));

interface PassedProps {
  datasetFlowNodeId: number;
  isSelected: boolean;
  addDatasetModalOpen: boolean;
  addStepModalOpen: boolean;
}

type Props = PassedProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

const getNodeActions = (
  setDeleteDatasetModalOpen: any,
  props: Props,
): React.ComponentProps<typeof MenuItem>[] => {
  const { flowData } = props;
  return [
    {
      label: '⌫',
      text: 'Delete',
      disabled: flowData.datasetNodes.length === 1,
      onClick: () => setDeleteDatasetModalOpen(true),
    },
  ];
};

const FlowGraphDatasetActions = (props: Props) => {
  const {
    removeDatasetFromFlow,
    datasetFlowNodeId,
    isSelected,
    isWorkspaceSearchOpen,
    flowData,
    addDatasetModalOpen,
    addStepModalOpen,
  } = props;
  const classes = useStyles();
  const [deleteDatasetModalOpen, setDeleteDatasetModalOpen] = useState(false);

  // listens for delete key
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        (e.keyCode === 8 || e.keyCode === 46) &&
        !isWorkspaceSearchOpen &&
        !addDatasetModalOpen &&
        !addStepModalOpen &&
        isSelected &&
        flowData.datasetNodes.length !== 1
      ) {
        setDeleteDatasetModalOpen(true);
        e.preventDefault();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    setDeleteDatasetModalOpen,
    isSelected,
    isWorkspaceSearchOpen,
    flowData.datasetNodes.length,
    addDatasetModalOpen,
    addStepModalOpen,
  ]);

  return (
    <>
      <DotsDropdown
        nodeActions={getNodeActions(setDeleteDatasetModalOpen, props)}
        className={classes.operationActionsIcon}
      />
      <ConfirmationModal
        modalOpen={deleteDatasetModalOpen}
        closeModal={() => setDeleteDatasetModalOpen(false)}
        modalTitle="Are you sure you want to delete this dataset?"
        modalText="Deleting this dataset will delete all subsequent operations on the exploration. This action cannot be undone"
        submitBtnText="Remove Dataset"
        danger
        onSubmit={() => removeDatasetFromFlow({ postData: { dataset_node_id: datasetFlowNodeId } })}
      />
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  flowData: state.flowData,
  isWorkspaceSearchOpen: state.workspaceData.isWorkspaceSearchOpen,
});

const mapDispatchToProps = {
  removeDatasetFromFlow,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlowGraphDatasetActions);

/** @format */

import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Drawer } from '@blueprintjs/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import FilterEditOperationBody from 'components/flowBuilder/editOperationDrawer/filterEditOperationBody';
import { Operation, Dataset } from 'actions/types';
import { OPERATION_TYPES } from 'constants/flowConstants';
import {
  FilterOperationInstructions,
  FilterClause,
  FormulaOperationInstructions,
  EnrichOperationInstructions,
  UnionOperationInstructions,
  PivotOperationInstructionsDTO,
  ChangeSchemaOperationInstructions,
  SortOperationInstructions,
  DedupOperationInstructions,
} from 'constants/types';
import {
  filterInstructionsChanged,
  formulaInstructionsChanged,
  joinInstructionsChanged,
  joinInstructionsComplete,
  unionInstructionsChanged,
  unionInstructionsComplete,
  calculateInstructionsChanged,
  calculateInstructionsComplete,
  pivotInstructionsChanged,
  schemaInstructionsChanged,
  pivotInstructionsComplete,
  sortInstructionsChanged,
  sortInstructionsComplete,
  dedupInstructionsChanged,
  dedupInstructionsComplete,
  pivotChartInstructionsChanged,
  pivotChartInstructionsComplete,
  lineOrBarChartInstructionsChanged,
  lineOrBarChartInstructionsComplete,
  axisPivotChartInstructionsChanged,
  axisPivotChartInstructionsComplete,
} from 'utils/flowUtils';
import { trackEvent } from 'analytics/exploAnalytics';
import { updateOperationInstructionAndCompute } from 'pages/flowGraphPage/flowGraphPageUtils';
import { ReduxState } from 'reducers/rootReducer';
import { FlowGraphApi } from './flowGraphPageContext';
import FormulaOperationBody from 'components/flowBuilder/editOperationDrawer/formulaOperationBody';
import JoinEditOperationBody from 'components/flowBuilder/editOperationDrawer/joinEditOperationBody';
import UnionEditOperationBody from 'components/flowBuilder/editOperationDrawer/unionEditOperationBody';
import PivotOperationBody from 'components/flowBuilder/editOperationDrawer/pivotOperationBody';
import ChangeSchemaEditOperationBody from 'components/flowBuilder/editOperationDrawer/changeSchemaEditOperationBody';
import SortEditOperationBody from 'components/flowBuilder/editOperationDrawer/sortEditOperationBody';
import DedupOperationBody from 'components/flowBuilder/editOperationDrawer/dedupOperationBody';
import PivotChartEditOperationBody from 'components/flowBuilder/editOperationDrawer/pivotChartEditOperationBody';
import LineChartEditOperationBody from 'components/flowBuilder/editOperationDrawer/lineChartEditOperationBody';
import AxisPivotChartEditOperationBody from 'components/flowBuilder/editOperationDrawer/axisPivotChartEditOperationBody';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '80% !important',
    maxWidth: 900,
  },
  drawerBody: {
    height: 'calc(100vh - 40px)',
    backgroundColor: theme.palette.primary.veryLight,
  },
}));

interface PassedProps {
  operation: Operation;
  isOpen: boolean;
  closeEditDrawer: () => void;
  sourceDataset: Dataset;
}

type Props = PassedProps & ReturnType<typeof mapStateToProps>;

const FlowGraphStepDrawerBody = (props: Props) => {
  const classes = useStyles();
  const { operation, isOpen, closeEditDrawer, sourceDataset, flowData, datasetCacheData } = props;
  const { updateFlowOperation, fetchOperationRowCount, computeExistingStepPreview } = useContext(
    FlowGraphApi,
  )!;
  let title: string = '';
  let editOperationBody: any;
  const updateAndComputeOperationInstructions: any = (
    operationId: number,
    newInstructions: any,
    shouldCompute: boolean,
  ) =>
    updateOperationInstructionAndCompute(
      operationId,
      newInstructions,
      shouldCompute,
      flowData,
      updateFlowOperation,
      fetchOperationRowCount,
      computeExistingStepPreview,
    );
  const sharedProps = {
    instructions: operation.instructions,
    operationId: operation.id,
    sourceDataset: sourceDataset,
  };

  if (!isOpen) return <></>;

  // hard to hide the ugliness of this switch statement
  switch (operation.operation_type) {
    case OPERATION_TYPES.FILTER.id:
      title = 'Define the filter operation';
      const matchOnAll = Array.isArray(operation.instructions)
        ? true
        : operation.instructions.matchOnAll;
      const filterClauses: FilterClause[] = Array.isArray(operation.instructions)
        ? operation.instructions
        : operation.instructions.filterClauses;
      editOperationBody = (
        <FilterEditOperationBody
          filterClauses={filterClauses}
          matchOnAll={matchOnAll}
          operationId={operation.id}
          sourceDataset={sourceDataset}
          onSubmit={(newInstructions: FilterOperationInstructions) => {
            const instructionHasChanged = filterInstructionsChanged(
              {
                matchOnAll: matchOnAll,
                filterClauses: filterClauses,
              },
              newInstructions,
            );
            updateAndComputeOperationInstructions(
              operation.id,
              newInstructions,
              instructionHasChanged,
            );
            closeEditDrawer();
            trackEvent('Update and compute filter', {
              operation_id: operation.id,
              new_instructions: newInstructions,
              filter_instructions_changed: instructionHasChanged,
            });
          }}
        />
      );
      break;
    case OPERATION_TYPES.FORMULA.id:
      title = 'Enter custom formulas';
      editOperationBody = (
        <FormulaOperationBody
          {...sharedProps}
          onSubmit={(newInstructions: FormulaOperationInstructions) => {
            const instructionHasChanged = formulaInstructionsChanged(
              operation.instructions,
              newInstructions,
            );
            updateAndComputeOperationInstructions(
              operation.id,
              newInstructions,
              instructionHasChanged,
            );
            closeEditDrawer();
            trackEvent('Update and compute formula', {
              operation_id: operation.id,
              new_instructions: newInstructions,
              formula_instructions_changed: instructionHasChanged,
            });
          }}
        />
      );
      break;
    case OPERATION_TYPES.ENRICH.id:
      title = 'Define the join operation';
      if (!(operation.instructions.joinTable && operation.instructions.joinTable.datasetId)) {
        operation.instructions.joinTable = {
          flowId: '',
          operationId: '',
          datasetId: '',
          name: '',
        };
      }
      editOperationBody = (
        <JoinEditOperationBody
          joinDataset={
            datasetCacheData && datasetCacheData[operation.instructions.joinTable.datasetId]
          }
          {...sharedProps}
          sourceDataSourceId={sourceDataset.data_source_id}
          onSubmit={(newInstructions: EnrichOperationInstructions) => {
            newInstructions.joinTable.flowId = flowData.flowInfo!.id;
            newInstructions.joinTable.operationId = operation.id;
            const instructionHasChanged = joinInstructionsChanged(
              operation.instructions,
              newInstructions,
            );
            updateAndComputeOperationInstructions(
              operation.id,
              newInstructions,
              instructionHasChanged && joinInstructionsComplete(newInstructions),
            );
            closeEditDrawer();
            trackEvent('Update and compute join', {
              operation_id: operation.id,
              new_instructions: newInstructions,
              join_instructions_changed: instructionHasChanged,
            });
          }}
        />
      );
      break;
    case OPERATION_TYPES.UNION.id:
      title = 'Define the union operation';
      editOperationBody = (
        <UnionEditOperationBody
          datasetCacheData={datasetCacheData}
          {...sharedProps}
          sourceDataSourceId={sourceDataset.data_source_id}
          onSubmit={(newInstructions: UnionOperationInstructions) => {
            newInstructions.unionTable.flowId = flowData.flowInfo!.id;
            newInstructions.unionTable.operationId = operation.id;

            const instructionHasChanged = unionInstructionsChanged(
              operation.instructions,
              newInstructions,
            );
            updateAndComputeOperationInstructions(
              operation.id,
              newInstructions,
              instructionHasChanged && unionInstructionsComplete(newInstructions),
            );
            closeEditDrawer();
            trackEvent('Update and compute union', {
              operation_id: operation.id,
              new_instructions: newInstructions,
              union_instructions_changed: instructionHasChanged,
            });
          }}
        />
      );
      break;
    case OPERATION_TYPES.CALCULATE.id:
      title = 'Define the column statistics';
      editOperationBody = (
        <PivotOperationBody
          {...sharedProps}
          onSubmit={(newInstructions: any) => {
            const instructionHasChanged = calculateInstructionsChanged(
              operation.instructions,
              newInstructions,
            );
            updateAndComputeOperationInstructions(
              operation.id,
              newInstructions,
              instructionHasChanged && calculateInstructionsComplete(newInstructions),
            );
            closeEditDrawer();
            trackEvent('Update and compute Calculate', {
              operation_id: operation.id,
              new_instructions: newInstructions,
              formula_instructions_changed: instructionHasChanged,
            });
          }}
          noPivotedCol={true}
        />
      );
      break;
    case OPERATION_TYPES.PIVOT.id:
      title = 'Define the pivot operation';
      editOperationBody = (
        <PivotOperationBody
          {...sharedProps}
          onSubmit={(newInstructions: PivotOperationInstructionsDTO) => {
            const instructionHasChanged = pivotInstructionsChanged(
              operation.instructions,
              newInstructions,
            );
            updateAndComputeOperationInstructions(
              operation.id,
              newInstructions,
              instructionHasChanged && pivotInstructionsComplete(newInstructions),
            );
            closeEditDrawer();
            trackEvent('Update and compute Pivot', {
              operation_id: operation.id,
              new_instructions: newInstructions,
              formula_instructions_changed: instructionHasChanged,
            });
          }}
        />
      );
      break;
    case OPERATION_TYPES.CHANGE_SCHEMA.id:
      title = 'Edit the columns';
      editOperationBody = (
        <ChangeSchemaEditOperationBody
          {...sharedProps}
          onSubmit={(newInstructions: ChangeSchemaOperationInstructions) => {
            const instructionHasChanged = schemaInstructionsChanged(
              operation.instructions,
              newInstructions,
            );

            updateAndComputeOperationInstructions(
              operation.id,
              newInstructions,
              instructionHasChanged,
            );
            closeEditDrawer();
            trackEvent('Update and compute schema change', {
              operation_id: operation.id,
              new_instructions: newInstructions,
              filter_instructions_changed: instructionHasChanged,
            });
          }}
        />
      );
      break;
    case OPERATION_TYPES.SORT.id:
      title = 'Define the sort operation';
      editOperationBody = (
        <SortEditOperationBody
          {...sharedProps}
          onSubmit={(newInstructions: SortOperationInstructions) => {
            const instructionsHasChanged = sortInstructionsChanged(
              operation.instructions,
              newInstructions,
            );
            updateAndComputeOperationInstructions(
              operation.id,
              newInstructions,
              instructionsHasChanged && sortInstructionsComplete(newInstructions),
            );
            closeEditDrawer();
            trackEvent('Update and compute sort', {
              operation_id: operation.id,
              new_instructions: newInstructions,
              sort_instructions_changed: instructionsHasChanged,
            });
          }}
        />
      );
      break;
    case OPERATION_TYPES.DEDUP.id:
      title = 'Define the remove duplicates operation';
      editOperationBody = (
        <DedupOperationBody
          {...sharedProps}
          onSubmit={(newInstructions: DedupOperationInstructions) => {
            const instructionHasChanged = dedupInstructionsChanged(
              operation.instructions,
              newInstructions,
            );
            updateAndComputeOperationInstructions(
              operation.id,
              newInstructions,
              instructionHasChanged && dedupInstructionsComplete(newInstructions),
            );
            closeEditDrawer();
            trackEvent('Update and compute Dedup', {
              operation_id: operation.id,
              new_instructions: newInstructions,
              formula_instructions_changed: instructionHasChanged,
            });
          }}
        />
      );
      break;
    case OPERATION_TYPES.BAR_CHART.id:
      title = 'Configure the Pivot Chart';
      editOperationBody = (
        <PivotChartEditOperationBody
          {...sharedProps}
          onSubmit={(newInstructions: any) => {
            const instructionHasChanged = pivotChartInstructionsChanged(
              operation.instructions,
              newInstructions,
            );
            updateAndComputeOperationInstructions(
              operation.id,
              newInstructions,
              instructionHasChanged && pivotChartInstructionsComplete(newInstructions),
            );
            closeEditDrawer();
            trackEvent('Update and compute pivot chart', {
              operation_id: operation.id,
              new_instructions: newInstructions,
              line_chart_instructions_changed: instructionHasChanged,
            });
          }}
        />
      );
      break;
    case OPERATION_TYPES.LINE_CHART.id:
      title = 'Configure the Line Chart';
      editOperationBody = (
        <LineChartEditOperationBody
          {...sharedProps}
          selectionTitle="Configure values"
          addSelectionBtn="Add Value"
          onSubmit={(newInstructions: any) => {
            const instructionHasChanged = lineOrBarChartInstructionsChanged(
              operation.instructions,
              newInstructions,
            );
            updateAndComputeOperationInstructions(
              operation.id,
              newInstructions,
              instructionHasChanged && lineOrBarChartInstructionsComplete(newInstructions),
            );
            closeEditDrawer();
            trackEvent('Update and compute line chart', {
              operation_id: operation.id,
              new_instructions: newInstructions,
              line_chart_instructions_changed: instructionHasChanged,
            });
          }}
        />
      );
      break;
    case OPERATION_TYPES.GROUPED_BAR_CHART.id:
      title = 'Cpnfigure the Bar Chart';
      editOperationBody = (
        <LineChartEditOperationBody
          {...sharedProps}
          selectionTitle="Configure values"
          addSelectionBtn="Add Value"
          onSubmit={(newInstructions: any) => {
            const instructionHasChanged = lineOrBarChartInstructionsChanged(
              operation.instructions,
              newInstructions,
            );
            updateAndComputeOperationInstructions(
              operation.id,
              newInstructions,
              instructionHasChanged && lineOrBarChartInstructionsComplete(newInstructions),
            );
            closeEditDrawer();
            trackEvent('Update and compute grouped bar chart', {
              operation_id: operation.id,
              new_instructions: newInstructions,
              grouped_bar_chart_instructions_changed: instructionHasChanged,
            });
          }}
        />
      );
      break;
    case OPERATION_TYPES.AXIS_PIVOT_CHART.id:
      title = 'Configure the Axis Pivot Table';
      editOperationBody = (
        <AxisPivotChartEditOperationBody
          {...sharedProps}
          onSubmit={(newInstructions) => {
            const instructionHasChanged = axisPivotChartInstructionsChanged(
              operation.instructions,
              newInstructions,
            );
            updateAndComputeOperationInstructions(
              operation.id,
              newInstructions,
              instructionHasChanged && axisPivotChartInstructionsComplete(newInstructions),
            );
            closeEditDrawer();
            trackEvent('Update and compute axis puvot chart', {
              operation_id: operation.id,
              new_instructions: newInstructions,
              line_chart_instructions_changed: instructionHasChanged,
            });
          }}
        />
      );
      break;
    default:
      throw new Error('Operation Type not found');
  }

  return (
    <Drawer className={classes.root} isOpen={isOpen} onClose={closeEditDrawer} title={title}>
      <div className={classes.drawerBody}>{editOperationBody}</div>
    </Drawer>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  flowData: state.flowData,
  datasetCacheData: state.datasetCacheData,
});

export default connect(mapStateToProps, {})(FlowGraphStepDrawerBody);

/** @format */

import React, { Component } from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { ReduxState } from 'reducers/rootReducer';
import { connect } from 'react-redux';

import OperationDropdownInput from 'components/flowBuilder/operationDropdownInput';

import { filterDashboardFilterColsForType } from 'utils/dashboardUtils';
import { fetchOperationSourceColumns } from 'actions/dashboardActions';

import { Schema } from 'constants/types';
import { SCHEMA_DATA_TYPES_BY_ID } from 'constants/flowConstants';

const styles = (theme: Theme) => ({
  filterColumnSelectContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100% - 54px)',
  },
  filterColumnNoColumnsText: {
    fontSize: 16,
    fontWeight: 100,
    color: theme.palette.grey.disabledText,
  },
});

type PassedProps = {
  editingFilterType?: string;
  editingFilterCol?: { name: string; type: string; sourceType: string };
  onFilterColumnSelect: (colName: string, colType: string, sourceType: string) => void;
  resultSchema: Schema;
  operationId: number;
};

type Props = WithStyles<typeof styles> &
  PassedProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

type State = {};

export class OperationFilterColumnSelect extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    props.fetchOperationSourceColumns({ postData: { operation_id: props.operationId } });
  }

  render = () => {
    const {
      classes,
      operationId,
      resultSchema,
      editingFilterType,
      onFilterColumnSelect,
      editingFilterCol,
      columnsByOperation,
    } = this.props;
    if (!resultSchema) return;

    let menuItems: any = [];
    const availableCols = filterDashboardFilterColsForType(resultSchema, editingFilterType!);
    const colOptions = availableCols.map((col) => ({
      name: col.name,
      id: `results-${col.name}`,
      type: col.type,
      icon: SCHEMA_DATA_TYPES_BY_ID[col.type].icon,
      sourceType: 'results',
    }));
    if (colOptions.length > 0) {
      menuItems = [{ name: 'Results Columns', id: 'results-columns', type: 'divider' }].concat(
        colOptions,
      );
    }

    if (columnsByOperation[operationId]) {
      const sourceSchema = columnsByOperation[operationId];
      const availableSourceCols = filterDashboardFilterColsForType(
        sourceSchema,
        editingFilterType!,
      );
      const sourceColOptions = availableSourceCols.map((col) => ({
        name: col.name,
        id: `source-${col.name}`,
        type: col.type,
        icon: SCHEMA_DATA_TYPES_BY_ID[col.type].icon,
        sourceType: 'source',
      }));
      if (sourceColOptions.length > 0) {
        menuItems = menuItems
          .concat([{ name: 'Source Columns', id: 'source-columns', type: 'divider' }])
          .concat(sourceColOptions);
      }
    }

    return (
      <div className={classes.filterColumnSelectContainer}>
        {menuItems.length > 0 ? (
          <OperationDropdownInput
            selectedItem={
              editingFilterCol
                ? {
                    ...editingFilterCol,
                    id: `${editingFilterCol.sourceType}-${editingFilterCol.name}`,
                    icon: SCHEMA_DATA_TYPES_BY_ID[editingFilterCol.type].icon,
                  }
                : undefined
            }
            onChange={(item: any) => onFilterColumnSelect(item.name, item.type, item.sourceType)}
            options={menuItems}
            noSelectionText="Select a column"
            showIcon
            filterable={false}
            dontShiftSelected
          />
        ) : (
          <div className={classes.filterColumnNoColumnsText}>
            No columns of the correcty type available to filter
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  columnsByOperation: state.operationSourceColumnsData.columnsByOperation,
});

const mapDispatchToProps = {
  fetchOperationSourceColumns,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(OperationFilterColumnSelect));

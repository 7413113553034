/** @format */

import { CovidStats, Report } from 'actions/types';
import { Action } from 'reducers/rootReducer';

export interface CovidDataReducerState {
  report?: Report;
  stats?: CovidStats;
  dateFor?: string;
}

export default (state: CovidDataReducerState = {}, action: Action): CovidDataReducerState => {
  const { payload } = action;

  switch (action.type) {
    case 'FETCH_COVID_OPERATIONS_SUCCESS':
      return { ...state, report: payload.report };
    case 'FETCH_COVID_DAILY_STATS_SUCCESS':
      return { ...state, stats: payload.stats, dateFor: payload.date_for };
    default:
      return state;
  }
};

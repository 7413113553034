/** @format */

import React from 'react';
import _ from 'underscore';
import cx from 'classnames';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';

import StepBlockTag from 'components/flowBuilder/stepBlockTag';

import { sortClauseComplete } from 'utils/flowUtils';

import { InstructionComponentProps } from 'components/flowGraph/operationInstructionDescription';
import { SortOperationInstructions, SortClause } from 'constants/types';

const styles = (theme: Theme) =>
  createStyles({
    fillerText: {
      fontSize: 16,
      fontWeight: 400,
      margin: `0 ${theme.spacing(2)}px`,
    },
    startingFillterText: {
      marginLeft: 0,
    },
  });

type PassedProps = InstructionComponentProps<SortOperationInstructions>;

type Props = PassedProps & WithStyles<typeof styles>;

class SortOperationInstructionDescription extends React.Component<Props> {
  render() {
    const { classes, operationId, instructions } = this.props;

    const completeClauses = instructions.sortColumns.filter(sortClauseComplete);
    const clauses = [
      <div
        className={cx(classes.fillerText, classes.startingFillterText)}
        key={`sort_by${operationId}`}>
        by
      </div>,
    ];
    _.each(completeClauses, (clause: SortClause, index: number) => {
      if (index !== 0) {
        clauses.push(
          <div className={classes.fillerText} key={`sort_clause_${index}_${operationId}_filler`}>
            , then by
          </div>,
        );
      }

      clauses.push(
        <StepBlockTag
          key={`sort_clause_${index}_${operationId}`}
          content={`${clause.column!.name} (${clause.order!})`}
        />,
      );
    });

    return clauses.length > 1 ? <>{clauses}</> : <></>;
  }
}

export default withStyles(styles)(SortOperationInstructionDescription);

/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { SCHEMA_DATA_TYPES_BY_ID } from 'constants/flowConstants';
import { IconName, Tag } from '@blueprintjs/core';
import { Schema } from 'constants/types';
import cx from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  tableColumnHeaderText: {
    display: 'flex',
    alignItems: 'center',
  },
  tableColumnHeaderTypeIcon: {
    marginRight: theme.spacing(2),
    minWidth: 'unset',
  },
  tableColumnHeaderInnerText: {
    height: 30,
  },
  grayOut: {
    opacity: 0.3,
  },
}));

type Props = {
  headerList: Schema;
  index: number;
  header: string;
  grayOut?: boolean;
};

const ColumnHeaderText = (props: Props) => {
  const { headerList, index, header, grayOut } = props;
  const classes = useStyles();
  let schemaType = SCHEMA_DATA_TYPES_BY_ID[headerList[index].type];
  if (!schemaType) {
    schemaType = SCHEMA_DATA_TYPES_BY_ID.STRING;
  }
  return (
    <div
      className={cx('bp3-table-truncated-text', classes.tableColumnHeaderText, {
        [classes.grayOut]: grayOut,
      })}>
      <Tag
        className={classes.tableColumnHeaderTypeIcon}
        icon={schemaType.icon as IconName}
        minimal={true}></Tag>

      {`${header}`}
      <div className={classes.tableColumnHeaderInnerText}></div>
    </div>
  );
};

export default ColumnHeaderText;

/** @format */

import { Action } from 'reducers/rootReducer';
import { Schema } from 'constants/types';

export interface OperationSourceColumnsReducerState {
  loading: boolean;
  columnsByOperation: { [operationId: string]: Schema };
}

const operationSourceColumnsReducer: OperationSourceColumnsReducerState = {
  loading: false,
  columnsByOperation: {},
};

export default (
  state: OperationSourceColumnsReducerState = operationSourceColumnsReducer,
  action: Action,
): OperationSourceColumnsReducerState => {
  const newState = Object.assign({}, state);
  const { payload } = action;

  switch (action.type) {
    case 'FETCH_DASHBOARD_SOURCE_COLUMNS_SUCCESS':
      newState.columnsByOperation[payload.postData.operation_id] = payload.dataset_schema;
      return newState;
    default:
      return state;
  }
};

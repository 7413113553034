/** @format */

import React from 'react';
import _ from 'underscore';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { ReduxState } from 'reducers/rootReducer';
import { connect } from 'react-redux';

import ConfirmationModal from 'components/modals/confirmationModal';

import { fetchDatasetRelatedFlows } from 'actions/datasetActions';

import { FlowInfo } from 'actions/types';

const styles = (theme: Theme) => ({
  root: {},
  listOverFlow: {
    overflow: 'auto',
  },
  datasetShareList: {
    paddingTop: 10,
  },
});

type PassedProps = {
  flow: FlowInfo;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  destinationName: string;
  destinationFolderId: number;
  currFolderId: number;
  bulkMoveResource: (
    args: any,
    onSuccess?: (data: any) => void,
    onError?: (errorMsg: any) => void,
  ) => void;
  datasetNamesById: { [id: number]: any };
  movingToTeam: boolean;
  userResourceIds: Set<number>;
};

type Props = PassedProps & WithStyles<typeof styles> & typeof mapDispatchToProps;
type State = {};

class ShareFlowConfirmationModal extends React.Component<Props, State> {
  state: State = {};

  render() {
    const { isOpen, onClose, title } = this.props;
    return (
      <ConfirmationModal
        modalOpen={isOpen}
        closeModal={onClose}
        modalTitle={title || 'Share Flow Confirmation'}
        modalText={this.getModalText()}
        submitBtnText="Move"
        danger={false}
        onSubmit={() => this.shareResources()}
      />
    );
  }

  shareResources = () => {
    const { bulkMoveResource, currFolderId, destinationFolderId, flow, movingToTeam } = this.props;

    let rids: number[] = [flow.id];
    if (movingToTeam) {
      rids = rids.concat(this.getFlowDatasetsInUserFolder());
    }

    const args = {
      postData: {
        curr_folder_id: currFolderId,
        dest_folder_id: destinationFolderId,
        r_ids: rids,
      },
    };
    bulkMoveResource(args, undefined, undefined);
  };

  getFlowDatasetsInUserFolder = () => {
    const { flow, userResourceIds } = this.props;
    return _.filter(flow.source_ids, (id: number) => userResourceIds.has(id));
  };

  getModalText = () => {
    const { flow, destinationName } = this.props;

    return flow.source_ids && _.size(flow.source_ids) > 0 ? (
      <div>
        <div>{`Are you sure you want to move this flow to ${destinationName}?`}</div>
        {this.renderSharePersonalDatasetsMessage()}
      </div>
    ) : (
      <div>{`Are you sure you want to move this flow to ${destinationName}?`}</div>
    );
  };

  // This verifies that you are moving things to the team folder and there are datasets in your personal folder to move over as well.
  renderSharePersonalDatasetsMessage = () => {
    const { movingToTeam, classes, datasetNamesById } = this.props;

    if (!movingToTeam) {
      return;
    }

    const flowDatasetsInUserFolder: number[] = this.getFlowDatasetsInUserFolder();
    if (flowDatasetsInUserFolder.length === 0) {
      return;
    }

    return (
      <>
        <div className={classes.datasetShareList}>
          This will explicitly share this flow's underlying datasets that are in your personal
          folder:
        </div>
        <ul className={classes.listOverFlow}>
          {_.map(flowDatasetsInUserFolder, (id: number) => {
            return (
              <li key={id}>
                <a href={`/datasets/${id}`} target="_blank" rel="noopener noreferrer">
                  {datasetNamesById[id]}
                </a>
              </li>
            );
          })}
        </ul>
      </>
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = {
  fetchDatasetRelatedFlows,
};

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ShareFlowConfirmationModal));

/** @format */

import { Button, Intent, Tag } from '@blueprintjs/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import React from 'react';
import { trackEvent } from 'analytics/exploAnalytics';

import { OPERATION_TYPES } from 'constants/flowConstants';

const useStyles = makeStyles((theme: Theme) => ({
  drawerFooter: {
    padding: `${theme.spacing(3)}px ${theme.spacing(6)}px`,
    borderTop: `1px solid ${theme.palette.grey.border}`,
    backgroundColor: theme.palette.white,
    display: 'flex',
    justifyContent: 'space-between',
  },
  drawerActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  validationErrorTag: {
    marginRight: theme.spacing(2),
  },
}));

interface Props {
  operationType?: string;
  errorState?: boolean;
  errorText?: string;
  onSubmit: () => void;
}

const OperationDrawerFooter = (props: Props) => {
  const { errorText, onSubmit, errorState, operationType } = props;
  const classes = useStyles();

  const helpCenterLink = operationType && OPERATION_TYPES[operationType].help_center_link;

  return (
    <div className={classes.drawerFooter}>
      {helpCenterLink ? (
        <Tag
          icon="help"
          minimal
          large
          intent={Intent.NONE}
          interactive
          onClick={() => {
            trackEvent('Help Center Clicked', {
              operation_type: operationType,
              source: 'Operation Edit Body',
              type: 'step_help',
            });
            window.open(helpCenterLink);
          }}>
          Experiencing issues? Learn more.
        </Tag>
      ) : (
        <div></div>
      )}

      <div className={classes.drawerActions}>
        {errorState && (
          <Tag
            className={classes.validationErrorTag}
            minimal={true}
            large={true}
            intent={Intent.DANGER}>
            {errorText}
          </Tag>
        )}
        <Button disabled={errorState} intent={Intent.PRIMARY} onClick={onSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default OperationDrawerFooter;

/** @format */

import { Intent } from '@blueprintjs/core';
import { UNION_COLUMN_MATCH_TYPES } from 'constants/flowConstants';
import { ColumnToUnionOn, UnionOperationInstructions } from 'constants/types';
import _ from 'underscore';
import { OperationFooterErrorInformation } from 'utils/operationErrorInfoUtils/generalOperationErrorInfoUtil';

enum ROW_ERROR_TEXT {
  EMPTY_RESULT_COLUMN_NAME = 'Please give the column a name',
  RESULT_COLUMN_BEGINS_WITH_NUMBER = 'Please use a column name that does not begin with a number',
}

enum FOOTER_ERROR_TEXT {
  NO_ERROR = 'No Error',
  SELECT_TABLE = 'Please select a table to union with',
  FILL_ROWS = 'Please fill in the incomplete rows to submit',
}

export interface UnionOperationRowErrorInformation {
  resultColNameErrorText: string | null;
  resultColNameInputIntent: Intent;
  isSourceColButtonErrorState: boolean;
  isUnionColButtonErrorState: boolean;
}

export const getUnionOperationRowErrorInformation = (
  columnToUnionOn: ColumnToUnionOn,
  index: number,
): UnionOperationRowErrorInformation => {
  let rowErrorInformation: UnionOperationRowErrorInformation = {
    resultColNameErrorText: null,
    resultColNameInputIntent: Intent.NONE,
    isSourceColButtonErrorState: false,
    isUnionColButtonErrorState: false,
  };

  // If the row is empty, but its not the first row - that should not throw an error
  if (
    index !== 0 &&
    (columnToUnionOn.resultColumnName === '' || columnToUnionOn.resultColumnName === undefined) &&
    (columnToUnionOn.unionColumn === null || columnToUnionOn.unionColumn === '') &&
    (columnToUnionOn.sourceColumn === null || columnToUnionOn.sourceColumn === '')
  ) {
    return rowErrorInformation;
  }

  // If the result column name is empty or starts with a number, give an error
  if (columnToUnionOn.resultColumnName === '' || columnToUnionOn.resultColumnName === undefined) {
    rowErrorInformation.resultColNameErrorText = ROW_ERROR_TEXT.EMPTY_RESULT_COLUMN_NAME;
    rowErrorInformation.resultColNameInputIntent = Intent.DANGER;
  } else if (!isNaN(parseInt(columnToUnionOn.resultColumnName.charAt(0)))) {
    rowErrorInformation.resultColNameErrorText = ROW_ERROR_TEXT.RESULT_COLUMN_BEGINS_WITH_NUMBER;
    rowErrorInformation.resultColNameInputIntent = Intent.DANGER;
  }

  // if the user fills the result column name in but both dropdowns are empty,
  // then give an error for both dropdowns
  if (
    columnToUnionOn.resultColumnName &&
    (columnToUnionOn.unionColumn === null || columnToUnionOn.unionColumn === '') &&
    (columnToUnionOn.sourceColumn === null || columnToUnionOn.sourceColumn === '')
  ) {
    rowErrorInformation.isUnionColButtonErrorState = true;
    rowErrorInformation.isSourceColButtonErrorState = true;
  }
  return rowErrorInformation;
};

export const getUnionOperationFooterErrorInformation = (
  instructions: UnionOperationInstructions,
): OperationFooterErrorInformation => {
  const operationFooterErrorInformation: OperationFooterErrorInformation = {
    footerErrorState: false,
    footerErrorText: FOOTER_ERROR_TEXT.NO_ERROR,
  };
  if (instructions.unionTable.name === '') {
    return {
      footerErrorState: true,
      footerErrorText: FOOTER_ERROR_TEXT.SELECT_TABLE,
    };
  } else {
    // if I find an incomplete row, then the instructions are incomplete
    const isInstructionsIncomplete = !!_.find(
      instructions.columnsToUnionOn,
      (columnToUnionOn, index) => {
        const {
          resultColNameErrorText,
          isSourceColButtonErrorState,
          isUnionColButtonErrorState,
        } = getUnionOperationRowErrorInformation(columnToUnionOn, index);
        return (
          !!resultColNameErrorText || isUnionColButtonErrorState || isSourceColButtonErrorState
        );
      },
    );
    if (
      instructions.unionColumnMatchType === UNION_COLUMN_MATCH_TYPES.MANUAL.id &&
      isInstructionsIncomplete
    ) {
      operationFooterErrorInformation.footerErrorState = true;
      operationFooterErrorInformation.footerErrorText = FOOTER_ERROR_TEXT.FILL_ROWS;
    }
  }
  return operationFooterErrorInformation;
};

/** @format */

import React from 'react';

import { Button } from '@blueprintjs/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withStyles, WithStyles } from '@material-ui/styles';

const styles = (theme: Theme) => ({
  input: {
    display: 'none',
  },
});

type PassedProps = {
  className?: any;
  uploadFile?: (file: File) => void;
  buttonText?: string;
  fill?: boolean;
  minimal?: boolean;
  loading?: boolean;
  buttonElement?: any;
};

type Props = PassedProps & WithStyles<typeof styles>;

class FileUploadButton extends React.Component<Props> {
  fileUploaderInputRef: HTMLInputElement | null = null;

  render() {
    const { classes, className, fill, minimal, loading, buttonElement } = this.props;
    return (
      <>
        <input
          className={classes.input}
          type="file"
          id="file-upload-button"
          name="file_upload"
          onChange={this.onFileSelected}
          onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
            // @ts-ignore
            event.target.value = null;
          }}
          ref={(ref) => (this.fileUploaderInputRef = ref)}
        />
        {buttonElement ? (
          <div onClick={() => this.fileUploaderInputRef!.click()}>{buttonElement}</div>
        ) : (
          <Button
            minimal={minimal}
            loading={loading}
            className={className}
            onClick={() => this.fileUploaderInputRef!.click()}
            fill={fill}>
            {this.props.buttonText ? this.props.buttonText : 'Upload DataSet'}
          </Button>
        )}
      </>
    );
  }

  onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = this.fileUploaderInputRef!.files![0];
    this.props.uploadFile!(file);
  };
}

export default withStyles(styles)(FileUploadButton);

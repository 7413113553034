/** @format */

import React from 'react';
import _ from 'underscore';

import ClauseBuilder from 'components/flowBuilder/clauseBuilder';
import PivotOperationBody from 'components/flowBuilder/editOperationDrawer/pivotOperationBody';
import StepBlockTag from 'components/flowBuilder/stepBlockTag';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withStyles, WithStyles } from '@material-ui/styles';
import { PivotOperationAggregation, PivotOperationInstructionsDTO } from 'constants/types';

import {
  pivotInstructionsComplete,
  pivotInstructionsChanged,
  pivotAggComplete,
} from 'utils/flowUtils';
import { trackEvent } from 'analytics/exploAnalytics';
import { Dataset } from 'actions/types';

const styles = (theme: Theme) => ({
  fillerText: {
    fontSize: 16,
    fontWeight: 400,
  },
});

type PassedProps = {
  clearRecentlyAddedOpId: () => {
    type: string;
  };
  computeLoading?: boolean;
  instructions: PivotOperationInstructionsDTO;
  openFlyout?: boolean;
  operationId: number;
  scrollToColumn: (columnName: string) => void;
  sourceDataset: Dataset;
  updateOperationInstructionAndCompute: (
    operationId: number,
    newInstructions: any,
    shouldCompute: boolean,
  ) => void;
  summaryView?: boolean;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  editDrawerOpen: boolean;
};

class PivotOperationClauseBuilder extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    if (props.openFlyout) {
      props.clearRecentlyAddedOpId();
    }

    this.state = {
      editDrawerOpen: props.openFlyout || false,
    };
  }

  render() {
    const { instructions, computeLoading, summaryView } = this.props;

    return (
      <ClauseBuilder
        computeLoading={computeLoading}
        editDrawerOpen={this.state.editDrawerOpen}
        openDrawer={() => this.setState({ editDrawerOpen: true })}
        closeDrawer={() => this.setState({ editDrawerOpen: false })}
        editOperationDrawerTitle="Define the pivot operation"
        tooltipSuggestionIsOpen={!pivotInstructionsComplete(instructions)}
        editOperationBody={this.renderOperationEditBody()}
        clauseText={this.renderPivotText()}
        summaryView={summaryView}
      />
    );
  }

  renderPivotText = (): JSX.Element[] | null => {
    const { classes, operationId, instructions } = this.props;

    const phrases = [
      <div className={classes.fillerText} key={`pivot_on_${operationId}`}>
        on
      </div>,
    ];
    _.each(Object.keys(instructions.pivotedOnCols), (colName, index) => {
      phrases.push(
        <StepBlockTag
          key={`pivot_col_${index}_${operationId}`}
          interactive={true}
          onClick={() => this.props.scrollToColumn(colName)}
          content={colName}
        />,
      );

      if (index !== Object.keys(instructions.pivotedOnCols).length - 1) {
        phrases.push(
          <div className={classes.fillerText} key={`pivot_filler_on${index}_${operationId}_and`}>
            and
          </div>,
        );
      }
    });

    phrases.push(
      <div className={classes.fillerText} key={`pivot_with_${operationId}`}>
        with
      </div>,
    );
    const completeAggs = instructions.aggregations.filter(pivotAggComplete);
    _.each(completeAggs, (agg, index: number) => {
      phrases.push(
        <StepBlockTag
          key={`pivot_agg_${index}_${operationId}`}
          content={`${agg.type!.name}( ${this.renderCalculateTextParameters(agg)} )`}
        />,
      );

      if (index !== completeAggs.length - 1) {
        phrases.push(
          <div className={classes.fillerText} key={`pivot_filler_with_${index}_${operationId}_and`}>
            and
          </div>,
        );
      }
    });

    return phrases.length > 2 ? phrases : null; // if no clauses were added, don't return anything
  };

  renderCalculateTextParameters = (agg: PivotOperationAggregation) => {
    return agg.aggedOnColumn!.name;
  };

  renderOperationEditBody = () => {
    const {
      sourceDataset,
      instructions,
      operationId,
      updateOperationInstructionAndCompute,
    } = this.props;

    return (
      <PivotOperationBody
        instructions={instructions}
        operationId={operationId}
        sourceDataset={sourceDataset}
        onSubmit={(newInstructions: PivotOperationInstructionsDTO) => {
          const instructionHasChanged = pivotInstructionsChanged(instructions, newInstructions);
          updateOperationInstructionAndCompute(
            operationId,
            newInstructions,
            instructionHasChanged && pivotInstructionsComplete(newInstructions),
          );
          this.setState({ editDrawerOpen: false });
          trackEvent('Update and compute Pivot', {
            operation_id: operationId,
            new_instructions: newInstructions,
            formula_instructions_changed: instructionHasChanged,
          });
        }}
      />
    );
  };
}

export default withStyles(styles)(PivotOperationClauseBuilder);

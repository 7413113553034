/** @format */

import React from 'react';
import { withRouter } from 'react-router-dom';
import ClauseBuilder from 'components/flowBuilder/clauseBuilder';
import UnionEditOperationBody from 'components/flowBuilder/editOperationDrawer/unionEditOperationBody';
import StepBlockTag from 'components/flowBuilder/stepBlockTag';
import { unionInstructionsChanged, unionInstructionsComplete } from 'utils/flowUtils';
import { trackEvent } from 'analytics/exploAnalytics';
import { withStyles, WithStyles } from '@material-ui/styles';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { UnionOperationInstructions } from 'constants/types';
import { DatasetCacheReducerState } from 'reducers/datasetCacheReducer';
import { Dataset } from 'actions/types';

const styles = (theme: Theme) =>
  createStyles({
    fillerText: {
      fontSize: 16,
      fontWeight: 400,
      marginRight: theme.spacing(2),
    },
  });

type PassedProps = {
  clearRecentlyAddedOpId: () => {
    type: string;
  };
  computeLoading?: boolean;
  datasetCacheData: DatasetCacheReducerState;
  flowId: number;
  instructions: UnionOperationInstructions;
  openFlyout?: boolean;
  operationId: number;
  scrollToColumn: (columnName: string) => void;
  sourceDataset: Dataset;
  sourceDataSourceId: number;
  updateOperationInstructionAndCompute: (
    operationId: number,
    newInstructions: any,
    shouldCompute: boolean,
  ) => void;
  summaryView?: boolean;
};
type Props = PassedProps & WithStyles<typeof styles> & RouteComponentProps;

type State = {
  editDrawerOpen: boolean;
};

class UnionOperationClauseBuilder extends React.PureComponent<Props, State> {
  readonly state: State = {
    editDrawerOpen: this.props.openFlyout || false,
  };
  constructor(props: Props) {
    super(props);

    if (props.openFlyout) {
      props.clearRecentlyAddedOpId();
    }
  }

  render() {
    const { computeLoading, instructions, summaryView } = this.props;
    const { editDrawerOpen } = this.state;

    return (
      <ClauseBuilder
        computeLoading={computeLoading}
        editDrawerOpen={editDrawerOpen}
        openDrawer={() => this.setState({ editDrawerOpen: true })}
        closeDrawer={() => this.setState({ editDrawerOpen: false })}
        editOperationDrawerTitle="Define the union operation"
        tooltipSuggestionIsOpen={
          !instructions.unionTable || !unionInstructionsComplete(instructions)
        }
        editOperationBody={this.renderOperationEditBody()}
        clauseText={this.renderUnionText()}
        summaryView={summaryView}
      />
    );
  }

  renderOperationEditBody = () => {
    const {
      sourceDataset,
      instructions,
      operationId,
      sourceDataSourceId,
      datasetCacheData,
    } = this.props;

    return (
      <UnionEditOperationBody
        datasetCacheData={datasetCacheData}
        instructions={instructions}
        operationId={operationId}
        sourceDataset={sourceDataset}
        sourceDataSourceId={sourceDataSourceId}
        onSubmit={(newInstructions: UnionOperationInstructions) => {
          const {
            operationId,
            updateOperationInstructionAndCompute,
            flowId,
            instructions,
          } = this.props;
          newInstructions.unionTable.flowId = flowId;
          newInstructions.unionTable.operationId = operationId;

          const instructionHasChanged = unionInstructionsChanged(instructions, newInstructions);
          updateOperationInstructionAndCompute(
            operationId,
            newInstructions,
            instructionHasChanged && unionInstructionsComplete(newInstructions),
          );
          this.setState({ editDrawerOpen: false });
          trackEvent('Update and compute union', {
            operation_id: operationId,
            new_instructions: newInstructions,
            union_instructions_changed: instructionHasChanged,
          });
        }}
      />
    );
  };

  renderUnionText = (): JSX.Element[] | null => {
    const { classes, operationId, instructions } = this.props;

    if (!instructions.unionTable.datasetId) {
      return null;
    }
    const stepBuilderBlockInfo = [
      <div className={classes.fillerText} key={`union_with_${operationId}`}>
        with
      </div>,
    ];
    stepBuilderBlockInfo.push(
      <StepBlockTag
        key={`table_name_${operationId}`}
        interactive={true}
        toolTipContent={'View the dataset'}
        href={`/datasets/${instructions.unionTable.datasetId}`}
        content={instructions.unionTable.name}
      />,
    );
    return stepBuilderBlockInfo;
  };
}

export default withRouter(withStyles(styles)(UnionOperationClauseBuilder));

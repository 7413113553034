/** @format */

import React from 'react';
import _ from 'underscore';
import { withRouter } from 'react-router-dom';
import { mapValues } from 'lodash';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import { pageView } from 'analytics/exploAnalytics';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import styled from 'styled-components';
import { FlowChart, IChart, ICanvasOuterDefaultProps } from 'trust-kaz-react-flow-chart';
import { NonIdealState, Spinner, Intent } from '@blueprintjs/core';

// import GraphCanvas from 'components/flowGraph/graphCanvas';
import FlowNodeWrapper from 'components/flowGraph/flowNodeWrapper';
import NodePorts from 'components/flowGraph/nodePorts';
import FlowDatasetPreviewPanel from 'components/flowGraph/flowDatasetPreviewPanel';

import PageHeader from 'components/pages/pageHeader';
import { fetchFlowDataPublic, fetchDatasetRowCountPublic } from 'actions/flowActions';
import { GRAPH_OPERATIONS_SET } from 'constants/flowConstants';
import { Operation, Flow, DatasetFlowNode, Dataset } from 'actions/types';
import { FlowReducerState } from 'reducers/flowReducer';
import * as actions from 'components/flowGraph/graphActions';

// const SIDEBAR_WIDTH = 0;
// const HEADER_HEIGHT = 64;

const styles = (theme: Theme) => ({
  root: {},
  loadingBody: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  addStepModal: {
    maxWidth: 1100,
    width: '80%',
    padding: 0,
  },
  flowGraphContainer: {
    position: 'relative' as 'relative',
  },
});

type MatchParams = {
  flowId: string;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams> &
  WithStyles<typeof styles>;

type State = {
  count?: number;
  flowId: string | number;
  chartState: IChart | null;
  graphScale: number | null;
};

export const CanvasInner = styled.div`
  position: relative;
  outline: 1px dashed rgba(0, 0, 0, 0);
  width: 10000px;
  height: 10000px;
` as any;

export const CanvasOuter = styled.div<ICanvasOuterDefaultProps>`
  position: relative;
  background-size: 20px 20px;
  background-color: rgba(0, 0, 0, 0.08);
  background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0.2) 1px, transparent 0),
    linear-gradient(180deg, hsla(0, 0%, 100%, 0.2) 1px, transparent 0);
  width: 100%;
  overflow: hidden;
` as any;

class PublicFlowGraph extends React.Component<Props, State> {
  readonly state: State = {
    flowId: this.props.match.params.flowId,
    chartState: null,
    graphScale: null,
  };

  constructor(props: Props) {
    super(props);

    PublicFlowGraph.loadFlowData(props);

    this.onScaleChange = _.debounce(this.onScaleChange, 250);
    this.onGraphPosChange = _.debounce(this.onGraphPosChange, 500);
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: any) {
    const nextFlowId = nextProps.match.params.flowId;

    if (nextFlowId !== prevState.flowId) {
      PublicFlowGraph.loadFlowData(nextProps);

      return {
        flowId: nextFlowId,
      };
    }
    return null;
  }

  static loadFlowData = (props: Props) => {
    props.fetchFlowDataPublic(parseInt(props.match.params.flowId), (flowData: Flow) => {
      flowData.dataset_nodes.map((datasetNode: DatasetFlowNode) => {
        props.fetchDatasetRowCountPublic({
          id: datasetNode.dataset.id,
          datasetNodeId: datasetNode.id,
        });
        return null;
      });

      if (!flowData.operations) {
        throw new Error(`flowData.operations undefined`);
      }
    });
  };

  componentDidMount() {
    pageView('View Only Flow Graph');
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.props.flowData.flowInfo) {
      return;
    }
    if (
      !_.isEqual(prevProps.flowData, this.props.flowData) ||
      !_.isEqual(prevProps.datasetCacheData, this.props.datasetCacheData)
    ) {
      const newChartState = this.constructChartConfig(this.props.flowData);
      this.setState({
        chartState: newChartState,
        graphScale: this.props.flowData.flowViewConfig!.scale,
      });
    }
  }

  render() {
    const { flowData } = this.props;
    return (
      <>
        <PageHeader
          title={flowData.flowInfo ? flowData.flowInfo.name : undefined}
          titleIcon="data-lineage"
        />
        {this.renderFlowBody()}
      </>
    );
  }

  renderFlowBody = () => {
    const { classes, flowData } = this.props;
    const { chartState } = this.state;

    if (flowData.error) {
      return this.renderFailedFlow();
    } else if (flowData.loading) {
      return (
        <div className={classes.loadingBody}>
          <Spinner intent={Intent.PRIMARY} size={Spinner.SIZE_STANDARD} />
        </div>
      );
    } else if (chartState) {
      return (
        <div className={classes.flowGraphContainer}>
          {/* <GraphCanvas
            initialConfig={flowData.flowViewConfig!}
            offsetTop={HEADER_HEIGHT}
            offsetLeft={SIDEBAR_WIDTH}
            onScaleChange={this.onScaleChange}
            onGraphPosChange={this.onGraphPosChange}
            chartState={chartState}
            elemBelowControls={this.renderPreviewTable(chartState)}>
            {this.renderFlow()}
          </GraphCanvas> */}
        </div>
      );
    }
  };

  renderPreviewTable = (chartState: IChart) => {
    const nodeId = chartState.selected.id;

    if (!nodeId) {
      return <FlowDatasetPreviewPanel />;
    }

    const nodeType = nodeId.split('-')[0];
    const node = chartState.nodes[nodeId];

    if (!node) {
      return <FlowDatasetPreviewPanel />;
    }

    let dataset, operationType, operationInstructions, computeLoading;
    if (nodeType === 'operation') {
      const operation = node.properties.operation;
      dataset = operation.results_dataset;
      operationType = operation.operation_type;
      operationInstructions = operation.instructions;
      computeLoading = operation.loading;

      if (operationType && GRAPH_OPERATIONS_SET.has(operationType)) {
        dataset = undefined;
      }
    } else if (nodeType === 'dataset') {
      dataset = node.properties.dataset;
    }

    return (
      <FlowDatasetPreviewPanel
        dataset={dataset}
        operationType={operationType}
        operationInstructions={operationInstructions}
        computeLoading={computeLoading}
      />
    );
  };

  onScaleChange = (scale: number) => {
    this.setState({
      graphScale: scale,
    });
  };

  onGraphPosChange = (posX: number, posY: number) => {};

  saveNodePosition = (nodeId: string, posX: number, posY: number) => {};

  renderFailedFlow = () => {
    return (
      <div style={{ height: '100vh' }}>
        <NonIdealState
          icon={'error'}
          title="Exploration not found."
          description={"The exploration either doesn't exist or hasn't been shared with you."}
        />
      </div>
    );
  };

  renderFlow() {
    const { flowData } = this.props;
    const { graphScale } = this.state;

    if (!flowData.flowInfo) {
      throw new Error(`flowData.flowInfo is null: ${this.props.flowData}`);
    }

    if (!this.state.chartState) {
      return;
    }

    return (
      <FlowChart
        config={{
          gridSize: 2000,
          saveNodePosition: this.saveNodePosition,
          scale: graphScale || 1,
          portsDisabled: true,
        }}
        chart={this.state.chartState}
        Components={{
          Node: FlowNodeWrapper,
          // @ts-ignore
          // NodeInner: FlowNodeV2,
          // @ts-ignore
          CanvasInner: CanvasInner,
          CanvasOuter: CanvasOuter,
          Port: NodePorts,
        }}
        callbacks={this.getGraphStateActions()}
      />
    );
  }

  getGraphStateActions = () => {
    return mapValues(actions, (func: any) => (...args: any) =>
      this.setState((oldState) => {
        const { chartState } = oldState;
        return {
          chartState: func(...args)(chartState),
        };
      }),
    ) as typeof actions;
  };

  constructChartConfig = (flowData: FlowReducerState): IChart => {
    const { chartState } = this.state;

    const chartConfig: IChart = {
      offset: {
        x: 0,
        y: 0,
      },
      nodes: {},
      links: {},
      selected: chartState ? chartState.selected : {},
      hovered: {},
    };

    if (flowData.recentlyAddedOpId) {
      chartConfig.selected = {
        type: 'node',
        id: `operation-${flowData.recentlyAddedOpId}`,
      };
    }

    flowData.datasetNodes.map((datasetNode, index) => {
      const datasetNodeId = `dataset-${datasetNode.id}`;

      const existingChartNode =
        chartState && chartState.nodes[datasetNodeId]
          ? chartState.nodes[datasetNodeId]
          : { position: { x: null, y: null }, size: {}, properties: {} };

      const ports = {
        output: {
          id: 'output',
          type: 'output',
          position: { x: 550, y: 350 },
        },
      };

      chartConfig.nodes[datasetNodeId] = {
        id: datasetNodeId,
        type: 'dataset',
        position: {
          x: existingChartNode.position.x || datasetNode.flow_position_x || 200 + 600 * index,
          y: existingChartNode.position.y || datasetNode.flow_position_y || 200,
        },
        size: {
          width: 1100,
          height: 350,
        },
        ports: ports,
        properties: {
          dataset: datasetNode.dataset,
        },
      };

      datasetNode.linked_operations.map(({ id }) => {
        const linkedOpNodeId = `operation-${id}`;
        const linkId = `link_from_${datasetNodeId}_to_${linkedOpNodeId}`;

        chartConfig.links[linkId] = {
          id: linkId,
          from: {
            nodeId: datasetNodeId,
            portId: 'output',
          },
          to: {
            nodeId: linkedOpNodeId,
            portId: 'input',
          },
        };
        return null;
      });

      datasetNode.operation_nodes.map(({ id }) => {
        this.configureFlowFromDatasetNode(chartConfig, datasetNodeId, id, datasetNode, flowData);
        return null;
      });

      return null;
    });

    return chartConfig;
  };

  configureFlowFromDatasetNode = (
    chartConfig: IChart,
    datasetNodeId: string,
    opId: number,
    datasetNode: DatasetFlowNode,
    flowData: FlowReducerState,
  ) => {
    const opQueue = [
      {
        parentNodeId: datasetNodeId,
        sourceData: datasetNode.dataset,
        operation: flowData.flowOperations[opId],
      },
    ];
    let nextOpConfig;
    let operation;
    let opNodeId: string;
    let linkId: string;
    let sourceData: Dataset;
    let opNum = 1;

    while (opQueue.length > 0) {
      nextOpConfig = opQueue.shift()!;
      operation = nextOpConfig.operation;

      if (!operation) {
        continue;
      }

      opNodeId = `operation-${operation.id}`;
      linkId = `link_from_${nextOpConfig.parentNodeId}_to_${opNodeId}`;
      sourceData = nextOpConfig.sourceData;

      chartConfig.nodes[opNodeId] = this.getOpNodeConfig(
        opNodeId,
        operation,
        sourceData,
        flowData,
        opNum,
      );

      chartConfig.links[linkId] = {
        id: linkId,
        from: {
          nodeId: nextOpConfig.parentNodeId,
          portId: 'output',
        },
        to: {
          nodeId: opNodeId,
          portId: 'input',
        },
      };

      if (operation.child_operations) {
        if (
          operation.results_dataset &&
          !GRAPH_OPERATIONS_SET.has(operation.operation_type as string)
        ) {
          sourceData = operation.results_dataset;
        }

        for (var i = 0; i < operation.child_operations.length; i++) {
          var { id } = operation.child_operations[i];
          opQueue.push({
            parentNodeId: opNodeId,
            sourceData: sourceData,
            operation: flowData.flowOperations[id],
          });
        }
      }
      opNum = opNum + 1;
    }
  };

  getOpNodeConfig = (
    opNodeId: string,
    operation: Operation,
    sourceData: Dataset,
    flowData: FlowReducerState,
    index: number,
  ) => {
    const { chartState } = this.state;

    const existingChartNode =
      chartState && chartState.nodes[opNodeId]
        ? chartState.nodes[opNodeId]
        : { position: { x: null, y: null }, size: {}, properties: {} };

    const ports = {
      input: {
        id: 'input',
        type: 'input',
        position: { x: 550, y: 0 },
      },
      output: {
        id: 'output',
        type: 'output',
        position: { x: 550, y: 350 },
      },
    };

    return {
      id: opNodeId,
      type: 'operation',
      position: {
        x: existingChartNode.position.x || operation.flow_position_x || 200,
        y: existingChartNode.position.y || operation.flow_position_y || 200 + 450 * index,
      },
      size: {
        width: 1100,
        height: 349,
      },
      ports: ports,
      properties: {
        // performance optimization in graphActions:onDragNodeStop to prevent saving (x,y)
        // coordinated too often
        last_x: operation.flow_position_x || 200,
        last_y: operation.flow_position_y || 200 + 450 * index,
        operation: operation,
        sourceData: sourceData,
        addFlowStep: this.addFlowStep,
        newFlowStepLoading: flowData.newFlowStepLoading,
        computeExistingStepPreview: this.computeExistingStepPreview,
        updateOperationInstructionAndCompute: this.updateOperationInstructionAndCompute,
        datasetCacheData: this.props.datasetCacheData,
        lastStep: true,
      },
    };
  };

  updateOperationInstructionAndCompute = (
    operationId: number,
    newInstructions: any,
    shouldCompute: boolean,
  ) => {};

  addFlowStep = (selectedOperationType: string) => {};

  computeExistingStepPreview = (operationId: number) => {};
}

const mapStateToProps = (state: ReduxState) => ({
  flowData: state.flowData,
  datasetCacheData: state.datasetCacheData,
});

const mapDispatchToProps = {
  fetchFlowDataPublic,
  fetchDatasetRowCountPublic,
};

export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PublicFlowGraph)),
);

/** @format */

import React, { ReactElement } from 'react';
import _ from 'underscore';
import { makeStyles } from '@material-ui/core/styles';
import { IconName } from '@blueprintjs/icons';
import { Icon, Popover, Button, Menu, MenuItem, Classes } from '@blueprintjs/core';
import { withStyles } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import cx from 'classnames';

const headerStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(6)}px`,
    borderBottom: `1px solid ${theme.palette.grey.border}`,
    backgroundColor: theme.palette.white,
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(14),
    marginBottom: theme.spacing(2),
    fontSize: 16,
    fontWeight: 600,
  },
  titleIcon: {
    marginRight: theme.spacing(2),
  },
  actionBtn: {
    marginLeft: theme.spacing(1),
  },
  pageTitleTextInput: {
    marginTop: theme.spacing(5),
    marginBottom: 0,
    height: theme.spacing(10),
  },
  loadingSkeletonPlaceholder: {
    width: theme.spacing(20),
    height: theme.spacing(5),
  },
}));

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#D6E8FD',
  },
  barColorPrimary: {
    backgroundColor: '#348CF7',
  },
})(LinearProgress);

type Props = {
  headerActions?: ReactElement;
  loadingBar?: boolean;
  loadingSkeleton?: boolean;
  title?: string;
  titleActions?: React.ComponentProps<typeof MenuItem>[];
  titleIcon: IconName;
};

const PageHeader = (props: Props) => {
  const { headerActions, title, titleActions, titleIcon, loadingBar, loadingSkeleton } = props;
  const classes = headerStyles();

  const renderTitleActions = (titleActions: React.ComponentProps<typeof MenuItem>[]) => {
    return (
      <Popover>
        <Button minimal small className={classes.actionBtn} icon="caret-down" />
        <Menu>
          {titleActions.map((actionConfig) => (
            <MenuItem key={_.uniqueId('flow_action_item')} {...actionConfig} />
          ))}
        </Menu>
      </Popover>
    );
  };

  const renderLoadingBar = () => (
    <div>
      <ColorLinearProgress />
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.headerContent}>
        <div className={classes.headerTitle}>
          <Icon icon={titleIcon} className={classes.titleIcon} />
          <div
            className={cx({
              [Classes.SKELETON]: loadingSkeleton,
              [classes.loadingSkeletonPlaceholder]: loadingSkeleton,
            })}>
            {title}
          </div>
          {titleActions && renderTitleActions(titleActions)}
        </div>
        <div className={cx({ [Classes.SKELETON]: loadingSkeleton })}>{headerActions}</div>
      </div>
      {loadingBar && renderLoadingBar()}
    </div>
  );
};

export default PageHeader;

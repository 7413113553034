/** @format */

import React, { Component } from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import {
  Dialog,
  Classes,
  FormGroup,
  InputGroup,
  RadioGroup,
  Radio,
  Button,
  Intent,
} from '@blueprintjs/core';

import {
  DASHBOARD_FILTER_TYPES,
  DASHBOARD_FILTER_TYPES_TO_DATA_TYPES,
} from 'constants/dashboardConstants';
import { findCommonElements } from 'utils/general';

const styles = (theme: Theme) => ({
  root: {},
  filterTypeName: {
    fontWeight: 'bold' as 'bold',
    marginTop: -19,
  },
  filterTypeDescription: {},
});

type PassedProps = {
  isOpen: boolean;
  onClose: () => void;
  onFilterSubmit: (filterName: string, filterType: string) => void;
  acceptableFilterTypes: string[];
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  filterName: string;
  filterType?: string;
  filterNameError?: string;
};

export class ConfigureDashboardFilterModal extends Component<Props, State> {
  state: State = {
    filterName: '',
    filterType: undefined,
    filterNameError: undefined,
  };

  render = () => {
    const { filterName, filterType, filterNameError } = this.state;
    const { classes, isOpen, onClose, acceptableFilterTypes } = this.props;

    return (
      <Dialog isOpen={isOpen} onClose={onClose} title="Configure Dashboard Filter">
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label="Filter name"
            labelFor="filter-name"
            intent={filterNameError ? Intent.DANGER : undefined}
            helperText={filterNameError}>
            <InputGroup
              id="filter-name"
              placeholder="What are you filtering?"
              value={filterName}
              intent={filterNameError ? Intent.DANGER : undefined}
              onChange={(e: any) => this.setState({ filterName: e.target.value })}
            />
          </FormGroup>
          <RadioGroup
            label="What type of data do you want to filter?"
            onChange={(e: any) => this.setState({ filterType: e.target.value })}
            selectedValue={filterType}>
            {DASHBOARD_FILTER_TYPES.map((filterType) => {
              return (
                <Radio
                  value={filterType.id}
                  key={`dashboard-filter-type-${filterType.id}`}
                  disabled={
                    !findCommonElements(
                      DASHBOARD_FILTER_TYPES_TO_DATA_TYPES[filterType.id],
                      acceptableFilterTypes,
                    )
                  }>
                  <div className={classes.filterTypeName}>{filterType.name}</div>
                  <div className={classes.filterTypeDescription}>{filterType.description}</div>
                </Radio>
              );
            })}
          </RadioGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button intent={Intent.NONE} onClick={onClose}>
              Cancel
            </Button>
            <Button disabled={!filterType} intent={Intent.PRIMARY} onClick={this.onFilterSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  onFilterSubmit = () => {
    const { filterName, filterType } = this.state;
    const { onFilterSubmit, onClose } = this.props;

    if (filterName.length === 0) {
      this.setState({ filterNameError: 'You must enter a filter name' });
      return;
    }

    onFilterSubmit(filterName, filterType!);
    onClose();
  };
}

export default withStyles(styles)(ConfigureDashboardFilterModal);

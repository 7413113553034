/** @format */

import React, { useState, useEffect, useContext } from 'react';
import DotsDropdown from '../../components/flowGraph/dotsDropdown';
import { connect } from 'react-redux';
import ConfirmationModal from 'components/modals/confirmationModal';
import AddToDashboardModal from 'components/flowBuilder/addToDashboardModal';
import { FlowGraphApi } from './flowGraphPageContext';
import { ReduxState } from 'reducers/rootReducer';
import { Dataset, Operation } from 'actions/types';
import { GRAPH_OPERATIONS_SET } from 'constants/flowConstants';
import SaveDatasetModal from './saveDatasetModal';
import { MenuItem, Intent } from '@blueprintjs/core';
import FlowGraphStepDrawerBody from './flowGraphStepDrawerBody';
import GenerateSQLModal from 'pages/flowGraphPage/generateSQLModal';
import { AppToaster } from 'toaster';
import { trackEvent } from 'analytics/exploAnalytics';
import { clearRecentlyAddedOpId } from 'actions/flowActions';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

const ALLOWED_CSV_DOWNLOAD_DATA_SOURCES = ['big_query', 'redshift', 'snowflake'];

const useStyles = makeStyles((theme: Theme) => ({
  operationActionsIcon: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(2),
  },
}));

interface PassedProps {
  operation: Operation;
  addDatasetModalOpen: boolean;
  addStepModalOpen: boolean;
  isSelected: boolean;
  sourceDataset?: Dataset;
  isResultsEmpty?: boolean;
  editDrawerOpen: boolean;
  setEditDrawerOpen: (isOpen: boolean) => void;
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & PassedProps;

const csvDownloadSuccessText = (signedUrl: string) => {
  return (
    <div>
      If the CSV didn't automatically download, click on
      <a href={signedUrl} style={{ paddingLeft: 4 }} target="_blank" rel="noopener noreferrer">
        this link
      </a>
      .
    </div>
  );
};

const getNodeActions = (
  setDeleteSubsequentStepsModalOpen: any,
  setAddToDashboardModalOpen: any,
  setSaveDatasetModalOpen: any,
  setEditDrawerOpen: any,
  setGenerateSqlModalOpen: any,
  operationCSVDownloading: boolean,
  setOperationCSVDownloading: any,
  downloadResultsTableAsCsv: any,
  operation: Operation,
  props: Props,
): React.ComponentProps<typeof MenuItem>[] => {
  const { dataSourceType, isResultsEmpty } = props;
  const dataTableLoading = operation.results_dataset ? operation.results_dataset.loading : true;
  const resultsDataId = operation.results_dataset && operation.results_dataset.id;
  const isGraphOp = GRAPH_OPERATIONS_SET.has(operation.operation_type as string);
  const nodeActions: React.ComponentProps<typeof MenuItem>[] = [
    {
      text: 'Edit',
      onClick: () => setEditDrawerOpen(true),
    },
    {
      disabled: dataTableLoading || !resultsDataId || isResultsEmpty,
      text: 'Add to Dashboard',
      onClick: () => setAddToDashboardModalOpen(true),
    },
  ];
  if (!isGraphOp) {
    nodeActions.push({
      disabled: isGraphOp || dataTableLoading || isResultsEmpty,
      text: 'Save as Dataset',
      onClick: () => setSaveDatasetModalOpen(true),
    });
    if (dataSourceType && ALLOWED_CSV_DOWNLOAD_DATA_SOURCES.indexOf(dataSourceType) >= 0) {
      nodeActions.push({
        disabled: dataTableLoading || operationCSVDownloading || isResultsEmpty,
        text: 'Download CSV',
        onClick: () => {
          downloadResultsTableAsCsv(
            resultsDataId,
            (bool: boolean) => setOperationCSVDownloading(bool),
            (signedUrl: string) => {
              AppToaster.show({
                message: csvDownloadSuccessText(signedUrl),
                intent: Intent.NONE,
                timeout: 5000,
              });
            },
            (errorMsg: string) => {
              AppToaster.show({
                message: errorMsg,
                intent: Intent.DANGER,
                timeout: 5000,
              });
            },
          );
          trackEvent('Download operation as CSV', {
            datasetId: operation.results_dataset.id,
          });
        },
      });
    }
    nodeActions.push({
      disabled: dataTableLoading || operationCSVDownloading || !operation.results_dataset,
      text: 'Generate SQL Query',
      onClick: () => setGenerateSqlModalOpen(true),
    });
  }
  nodeActions.push({
    label: '⌫',
    text: 'Delete',
    onClick: () => setDeleteSubsequentStepsModalOpen(true),
  });
  return nodeActions;
};

const FlowGraphStepActions = (props: Props) => {
  const {
    isWorkspaceSearchOpen,
    sourceDataset,
    operation,
    addDatasetModalOpen,
    addStepModalOpen,
    isSelected,
    editDrawerOpen,
    setEditDrawerOpen,
    clearRecentlyAddedOpId,
  } = props;
  const classes = useStyles();

  const [deleteSubsequentStepsModalOpen, setDeleteSubsequentStepsModalOpen] = useState(false);
  const [addToDashboardModalOpen, setAddToDashboardModalOpen] = useState(false);
  const [saveDatasetModalOpen, setSaveDatasetModalOpen] = useState(false);
  const [generateSqlModalOpen, setGenerateSqlModalOpen] = useState(false);
  const [operationCSVDownloading, setOperationCSVDownloading] = useState(false);

  const { deleteFlowOperation, downloadResultsTableAsCsv } = useContext(FlowGraphApi)!;

  const closeEditDrawer = () => {
    clearRecentlyAddedOpId();
    setEditDrawerOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        !editDrawerOpen &&
        !isWorkspaceSearchOpen &&
        !addDatasetModalOpen &&
        !addToDashboardModalOpen &&
        !deleteSubsequentStepsModalOpen &&
        !saveDatasetModalOpen &&
        !addStepModalOpen
      ) {
        if ((e.keyCode === 8 || e.keyCode === 46) && isSelected) {
          setDeleteSubsequentStepsModalOpen(true);
          e.preventDefault();
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    setDeleteSubsequentStepsModalOpen,
    isWorkspaceSearchOpen,
    addDatasetModalOpen,
    addToDashboardModalOpen,
    deleteSubsequentStepsModalOpen,
    saveDatasetModalOpen,
    addStepModalOpen,
    isSelected,
    editDrawerOpen,
  ]);

  return (
    <>
      <DotsDropdown
        nodeActions={getNodeActions(
          setDeleteSubsequentStepsModalOpen,
          setAddToDashboardModalOpen,
          setSaveDatasetModalOpen,
          setEditDrawerOpen,
          setGenerateSqlModalOpen,
          operationCSVDownloading,
          setOperationCSVDownloading,
          downloadResultsTableAsCsv,
          operation,
          props,
        )}
        className={classes.operationActionsIcon}
      />
      <ConfirmationModal
        modalOpen={deleteSubsequentStepsModalOpen}
        closeModal={() => setDeleteSubsequentStepsModalOpen(false)}
        modalTitle="Are you sure?"
        modalText="This action will delete the selected step and all subsequent steps below it."
        submitBtnText="Delete the steps"
        danger={true}
        onSubmit={() => deleteFlowOperation(operation.id)}
      />
      <SaveDatasetModal
        saveDatasetModalOpen={saveDatasetModalOpen}
        toggleSaveDatasetModal={setSaveDatasetModalOpen}
        operation={operation}
      />
      <AddToDashboardModal
        closeModal={() => setAddToDashboardModalOpen(false)}
        modalOpen={addToDashboardModalOpen}
        operationId={operation.id}
      />
      <FlowGraphStepDrawerBody
        operation={operation}
        isOpen={editDrawerOpen}
        closeEditDrawer={closeEditDrawer}
        sourceDataset={sourceDataset!}
      />
      <GenerateSQLModal
        operation={operation}
        generateSqlModalOpen={generateSqlModalOpen}
        setGenerateSqlModalOpen={setGenerateSqlModalOpen}
      />
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  isWorkspaceSearchOpen: state.workspaceData.isWorkspaceSearchOpen,
  dataSourceType: state.flowData.flowInfo!.data_source.source_type,
});

const mapDispatchToProps = {
  clearRecentlyAddedOpId,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlowGraphStepActions);

/** @format */

import React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { ReduxState } from 'reducers/rootReducer';
import { connect } from 'react-redux';

import ConfirmationModal from 'components/modals/confirmationModal';

import { fetchDatasetRelatedFlows } from 'actions/datasetActions';

import { FlowMini } from 'actions/types';

const styles = (theme: Theme) => ({
  root: {},
});

type PassedProps = {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  deleteDataSource: () => void;
};

type Props = PassedProps & WithStyles<typeof styles> & typeof mapDispatchToProps;

type State = {
  deleteDatasetRelatedFlows: FlowMini[] | null;
};

class DeleteDataSourceConfirmationModal extends React.Component<Props, State> {
  state: State = {
    deleteDatasetRelatedFlows: null,
  };

  render() {
    const { isOpen, onClose, title, deleteDataSource } = this.props;
    return (
      <ConfirmationModal
        modalOpen={isOpen}
        closeModal={onClose}
        modalTitle={title || 'Are you sure you want to delete this Data Source?'}
        modalText={
          'This will delete all flows and datasets within the data source.  This can not be undone.'
        }
        submitBtnText="Delete"
        danger
        onSubmit={() => deleteDataSource()}
      />
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = {
  fetchDatasetRelatedFlows,
};

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(DeleteDataSourceConfirmationModal));

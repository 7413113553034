/** @format */

import React from 'react';
import cx from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

import SectionTitle from 'explo-ds/typography/marketing/sectionTitle';
import FaqQuestionDropdown from 'components/marketing/faqQuestionDropdown';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  title: {
    color: theme.palette.white,
    marginBottom: theme.spacing(10),
  },
  faqQuestion: {
    marginBottom: theme.spacing(3),
  },
}));

type Props = {
  className?: string;
};

const FaqSection = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.root, props.className)}>
      <div className={classes.title}>
        <SectionTitle>
          Find out how to <b>get started</b>
        </SectionTitle>
      </div>
      <div>
        <FaqQuestionDropdown
          className={classes.faqQuestion}
          question="Who should use Explo?"
          answer="Explo is built for your entire company, from data analysts to business users and execs. Our interface is intuitive enough for excel users to pick up quickly and powerful enough for your data team to leverage."
        />
        <FaqQuestionDropdown
          className={classes.faqQuestion}
          question="What if my team doesn't use a database?"
          answer="If you don't have a database set up, you can connect Explo directly to your data sources through our integrations. We can also help you set up a data warehouse to centralize all of you data."
        />
        <FaqQuestionDropdown
          className={classes.faqQuestion}
          question="What if all my data is in spreadsheets?"
          answer="You can upload individual CSV files to Explo or link your Google sheets directly. Swap in updated files and all the analysis will be updated without the need for repeating work."
        />
        <FaqQuestionDropdown
          question="Our data exists across multiple platforms, how do I bring my data into Explo?"
          answer="Explo supports integrations to over 160 different data sources including Stripe, Shopify, Hubspot and more. We can also help you pull data from multiple databases into a single data warehouse so you can analyze all your data together."
        />
      </div>
    </div>
  );
};

export default FaqSection;

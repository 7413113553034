/** @format */

import React from 'react';
import _ from 'underscore';

import ClauseBuilder from 'components/flowBuilder/clauseBuilder';
import SortEditOperationBody from 'components/flowBuilder/editOperationDrawer/sortEditOperationBody';
import StepBlockTag from 'components/flowBuilder/stepBlockTag';

import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { SortOperationInstructions, SortClause } from 'constants/types';

import {
  sortClauseComplete,
  sortInstructionsComplete,
  sortInstructionsChanged,
} from 'utils/flowUtils';
import { trackEvent } from 'analytics/exploAnalytics';
import { Dataset } from 'actions/types';

const styles = (theme: Theme) =>
  createStyles({
    fillerText: {
      fontSize: 16,
      fontWeight: 400,
    },
  });

type PassedProps = {
  clearRecentlyAddedOpId: () => {
    type: string;
  };
  computeLoading?: boolean;
  instructions: SortOperationInstructions;
  openFlyout?: boolean;
  operationId: number;
  scrollToColumn: (columnName: string) => void;
  sourceDataset: Dataset;
  updateOperationInstructionAndCompute: (
    operationId: number,
    newInstructions: SortOperationInstructions,
    shouldCompute: boolean,
  ) => void;
  summaryView?: boolean;
};
type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  editDrawerOpen: boolean;
};

class SortOperationClauseBuilder extends React.Component<Props, State> {
  readonly state: State = {
    editDrawerOpen: this.props.openFlyout || false,
  };
  constructor(props: Props) {
    super(props);

    if (props.openFlyout) {
      props.clearRecentlyAddedOpId();
    }
  }

  render() {
    const { computeLoading, summaryView } = this.props;

    return (
      <ClauseBuilder
        computeLoading={computeLoading}
        editDrawerOpen={this.state.editDrawerOpen}
        openDrawer={() => this.setState({ editDrawerOpen: true })}
        closeDrawer={() => this.setState({ editDrawerOpen: false })}
        editOperationDrawerTitle="Define the sort operation"
        tooltipSuggestionIsOpen={false}
        editOperationBody={this.renderOperationEditBody()}
        clauseText={this.renderSortText()}
        summaryView={summaryView}
      />
    );
  }

  renderSortText = (): JSX.Element[] | null => {
    const { classes, operationId, instructions, scrollToColumn } = this.props;

    const completeClauses = instructions.sortColumns.filter(sortClauseComplete);
    const clauses = [
      <div className={classes.fillerText} key={`sort_by${operationId}`}>
        by
      </div>,
    ];
    _.each(completeClauses, (clause: SortClause, index: number) => {
      if (index !== 0) {
        clauses.push(
          <div className={classes.fillerText} key={`sort_clause_${index}_${operationId}_filler`}>
            , then by
          </div>,
        );
      }

      clauses.push(
        <StepBlockTag
          key={`sort_clause_${index}_${operationId}`}
          interactive={true}
          onClick={() => scrollToColumn(clause.column!.name)}
          content={`${clause.column!.name} (${clause.order!})`}
        />,
      );
    });

    return clauses.length > 1 ? clauses : null; // if no clauses were added, don't return anything
  };

  renderOperationEditBody = () => {
    const { sourceDataset, instructions, operationId } = this.props;

    return (
      <SortEditOperationBody
        instructions={instructions}
        operationId={operationId}
        sourceDataset={sourceDataset}
        onSubmit={(newInstructions: SortOperationInstructions) => {
          const { updateOperationInstructionAndCompute, operationId, instructions } = this.props;
          const instructionsHasChanged = sortInstructionsChanged(instructions, newInstructions);
          updateOperationInstructionAndCompute(
            operationId,
            newInstructions,
            instructionsHasChanged && sortInstructionsComplete(newInstructions),
          );
          this.setState({ editDrawerOpen: false });
          trackEvent('Update and compute sort', {
            operation_id: operationId,
            new_instructions: newInstructions,
            sort_instructions_changed: instructionsHasChanged,
          });
        }}
      />
    );
  };
}

export default withStyles(styles)(SortOperationClauseBuilder);

/** @format */

import React from 'react';

class Footer extends React.PureComponent {
  render() {
    return (
      <div className="footer">
        <a href="mailto:support@explo.co" target="_blank" rel="noopener noreferrer">
          Contact
        </a>
        <a href="/terms_of_service.pdf" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>
        <a href="/privacy_policy.pdf" target="_blank" rel="noopener noreferrer">
          Privacy Policy{' '}
          <span role="img" aria-label="owl emoji">
            🦉
          </span>
        </a>
      </div>
    );
  }
}

export default Footer;

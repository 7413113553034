/** @format */

import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import _ from 'underscore';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withStyles, WithStyles } from '@material-ui/styles';

import { NonIdealState, Spinner, Intent, InputGroup } from '@blueprintjs/core';

import PageHeader from 'components/pages/pageHeader';
import BaseDataTable from 'components/dataTable/baseDataTable';
import LoadingDataTable from 'components/dataTable/loadingDataTable';
import SchemaColumnEditor from 'components/schemaEditing/schemaColumnEditor';

import { fetchDatasetData, getDatasetPreview } from 'actions/datasetActions';
import { fetchDatasetRowCount } from 'actions/flowActions';
import { datasetUploadComplete } from 'actions/fileSystemActions';
import { pageView } from 'analytics/exploAnalytics';

import { Dataset } from 'actions/types';

const styles = (theme: Theme) => ({
  loadingBody: {
    height: 'calc(100vh - 50px - 64px)',
    display: 'flex',
    justifyContent: 'center',
  },
  contentsPanel: {
    boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.25)',
    borderRadius: 4,
    overflow: 'hidden',
    margin: theme.spacing(6),
    width: 'fit-content',
  },
  contentsFooter: {
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    backgroundColor: theme.palette.grey.light,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rowCount: {
    padding: `0 ${theme.spacing(1)}px`,
    fontSize: 14,
    color: theme.palette.black,
    fontWeight: 300,
    display: 'flex',
    alignItems: 'center',
  },
  rowCountSpinner: {
    marginRight: theme.spacing(1),
  },
  schemaEditorInterface: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  previewPanel: {
    height: 'calc(100vh - 114px - 48px - 49px)',
    maxWidth: 'calc(100vh - 114px - 48px)',
  },
  schemaPanel: {
    backgroundColor: theme.palette.white,
    minWidth: 350,
    maxWidth: 450,
    height: `calc(100vh - 114px - 48px - 49px)`, // account for the top bars + margin + callout component
  },
  datasetTable: {
    height: 'calc(100% - 34px)',
  },
  schemaHeader: {
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
    fontSize: 16,
    fontWeight: 'bold' as 'bold',
    color: theme.palette.grey.darkest,
    borderBottom: `1px solid ${theme.palette.grey.border}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

type MatchParams = {
  datasetId: string;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams> &
  WithStyles<typeof styles>;

type State = {
  datasetId: string;
  columnSearchValue: string;
};

class DatasetPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      datasetId: props.match.params.datasetId,
      columnSearchValue: '',
    };
  }

  componentDidMount() {
    pageView('Dataset');

    DatasetPage.fetchDatasetData(this.state.datasetId, this.props);
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const nextDatasetId = nextProps.match.params.datasetId;

    if (nextDatasetId !== prevState.datasetId) {
      DatasetPage.fetchDatasetData(nextDatasetId, nextProps);

      return {
        datasetId: nextDatasetId,
      };
    }
    return null;
  }

  static fetchDatasetData = (datasetId: string, props: Props) => {
    props.fetchDatasetData({ id: datasetId }, (response) => {
      const { dataset } = response;
      if (dataset.loading) {
        props.getDatasetPreview({ id: datasetId });
      }
      if (!dataset.total_row_count) {
        props.fetchDatasetRowCount({ id: datasetId });
      }
    });
  };

  render() {
    const { classes, datasetData } = this.props;

    if (datasetData.error) {
      return this.renderFailedDatasetPreview();
    } else if (datasetData.loading || !datasetData.datasetInfo) {
      return (
        <div className={classes.loadingBody}>
          <Spinner intent={Intent.PRIMARY} size={Spinner.SIZE_STANDARD} />
        </div>
      );
    } else if (datasetData.datasetInfo.loading) {
      return this.renderUploadDatasetLoadingPreview();
    } else {
      return this.renderDataset(datasetData.datasetInfo);
    }
  }

  renderFailedDatasetPreview = () => {
    return (
      <div style={{ height: '100vh' }}>
        <NonIdealState
          icon={'error'}
          title="Dataset not found."
          description={"The dataset either doesn't exist or hasn't been shared with you."}
        />
      </div>
    );
  };

  renderUploadDatasetLoadingPreview = () => {
    return (
      <div style={{ height: '100vh' }}>
        <NonIdealState
          icon={<Spinner size={Spinner.SIZE_LARGE} intent={Intent.PRIMARY} />}
          title="Dataset upload in progress"
          description={
            'The dataset you uploaded is being processed and placed in the database. This process may take a few minutes.'
          }
        />
      </div>
    );
  };

  renderDataset = (dataset: Dataset) => {
    const { classes } = this.props;

    return (
      <>
        <PageHeader title={this.getDatasetName()} titleIcon="th" />
        <div className={classes.schemaEditorInterface}>
          {this.renderDatasetSchemaEditor(dataset)}
          {this.renderDatasetPreview(dataset)}
        </div>
      </>
    );
  };

  getDatasetName = () => {
    const { datasetData } = this.props;
    if (!datasetData.datasetInfo) {
      return '';
    }

    return datasetData
      ? datasetData.datasetInfo.name || datasetData.datasetInfo.data_source_table_name
      : '';
  };

  renderDatasetSchemaEditor = (dataset: Dataset) => {
    const { columnSearchValue } = this.state;
    const { classes } = this.props;
    const { cached_schema } = dataset!;

    if (!cached_schema) {
      return;
    }

    return (
      <div className={cx(classes.contentsPanel, classes.schemaPanel)}>
        <div className={classes.schemaHeader}>
          <div>Schema</div>
          <InputGroup
            type="search"
            placeholder="Search columns"
            leftIcon="search"
            value={columnSearchValue}
            onChange={(e: any) => this.setState({ columnSearchValue: e.target.value })}
          />
        </div>
        <SchemaColumnEditor
          colNameList={this.filterVisibleColumns(columnSearchValue, _.pluck(cached_schema, 'name'))}
          colByName={_.indexBy(cached_schema, 'name')}
        />
        <div className={classes.contentsFooter}>
          <div className={classes.rowCount}>{cached_schema.length} columns</div>
        </div>
      </div>
    );
  };

  filterVisibleColumns = (query: string, schemaColumns: string[]) => {
    return _.filter(schemaColumns, (columnName) => {
      if (query.length === 0) {
        return true;
      }
      return columnName.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    });
  };

  renderDatasetPreview = (dataset: Dataset) => {
    const { classes, datasetData } = this.props;
    const { cached_preview, cached_schema, results_last_updated_at } = dataset;
    const updatedDate = moment(results_last_updated_at);
    const isLoading = cached_preview!.length === 0 || datasetData.schemaChangeLoading;

    const DataTable = isLoading ? LoadingDataTable : BaseDataTable;
    const isSourceDataset = dataset.is_source_dataset;
    const rowCount = dataset.total_row_count;

    return (
      <div className={cx(classes.contentsPanel, classes.previewPanel)}>
        <DataTable
          className={classes.datasetTable}
          headerList={cached_schema!}
          rows={cached_preview!}
          loading={isLoading}
          maxRows={50}
        />
        <div className={classes.contentsFooter}>
          <div>
            {isSourceDataset ? 'Updated' : 'Uploaded'} {updatedDate.fromNow()}
          </div>
          <div className={classes.rowCount}>
            {rowCount ? rowCount : <Spinner className={classes.rowCountSpinner} size={17} />} total
            rows (previewing {cached_preview!.length} rows)
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  datasetData: state.datasetData,
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  fetchDatasetRowCount,
  fetchDatasetData,
  getDatasetPreview,
  datasetUploadComplete,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(withRouter(withStyles(styles)(DatasetPage)));

/** @format */

import React from 'react';
import _ from 'underscore';
import { makeStyles } from '@material-ui/core/styles';

import { MenuItem, Menu, Spinner } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';

const useStyles = makeStyles((theme) => ({
  menuList: {
    maxHeight: 200,
    overflow: 'auto',
  },
}));

const SimpleSuggestInput = (props) => {
  const classes = useStyles();
  const simpleFilterPredicate = (query, item) => {
    return item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  const itemRenderer = (item, { handleClick, query }) => {
    let itemText;
    if (query.length > 0) {
      const text = item.name.split(query);
      itemText = [];
      for (let i = 0; i <= text.length - 2; i++) {
        itemText.push(text[i]);
        itemText.push(<b key={_.uniqueId('query_text')}>{query}</b>);
      }
      itemText.push(text[text.length - 1]);
    } else {
      itemText = item.name;
    }

    return (
      <MenuItem
        active={props.selectedItem && props.selectedItem.id === item.id}
        text={itemText}
        key={_.uniqueId(`${item.id}_`)}
        onClick={handleClick}
        icon={item.icon}
      />
    );
  };

  const itemListRenderer = ({ filteredItems, items, query, renderItem, itemsParentRef }) => {
    let itemsList;
    if (filteredItems.length === 0) {
      itemsList = <MenuItem disabled={true} text="No results." />;
    } else if (query.length === 0) {
      itemsList = items.map((item) => renderItem(item));
    } else {
      itemsList = filteredItems.map((item) => renderItem(item));
    }

    return (
      <Menu className={classes.menuList} ref={(ref) => (itemsParentRef = ref)}>
        {itemsList}
        {props.onCreateNewItem &&
          query &&
          createNewItem(query, false, () => props.onCreateNewItem(query))}
      </Menu>
    );
  };

  const createNewItem = (query, active, handleClick) => {
    return (
      <MenuItem
        icon="add"
        text={`Create dashboard: ${query}`}
        active={active}
        onClick={handleClick}
      />
    );
  };

  return (
    <Suggest
      className={props.className}
      disabled={props.loading}
      fill={props.fill}
      items={props.options}
      itemPredicate={simpleFilterPredicate}
      itemRenderer={itemRenderer}
      itemListRenderer={itemListRenderer}
      defaultSelectedItem={props.selectedItem}
      inputValueRenderer={(col) => col.name}
      noResults={<MenuItem disabled={true} text="No results." />}
      onItemSelect={props.onChange}
      inputProps={{
        rightElement: props.loading && <Spinner size={Spinner.SIZE_SMALL} />,
      }}
    />
  );
};

export default SimpleSuggestInput;

/** @format */

import React from 'react';
import cx from 'classnames';
import _ from 'underscore';
import { withStyles, WithStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { clearRecentlyAddedOpId } from 'actions/flowActions';
import BaseDataTable from 'components/dataTable/baseDataTable';
import LoadingDataTable from 'components/dataTable/loadingDataTable';
import FilterOperationClauseBuilder from 'components/flowBuilder/filterOperationClauseBuilder';
import JoinOperationClauseBuilder from 'components/flowBuilder/joinOperationClauseBuilder';
import UnionOperationClauseBuilder from 'components/flowBuilder/unionOperationClauseBuilder';
import PivotOperationClauseBuilder from 'components/flowBuilder/pivotOperationClauseBuilder';
import CalculateOperationClauseBuilder from 'components/flowBuilder/calculateOperationClauseBuilder';
import ChangeSchemaOperationClauseBuilder from 'components/flowBuilder/changeSchemaOperationClauseBuilder';
import DedupOperationClauseBuilder from 'components/flowBuilder/dedupOperationClauseBuilder';
import FormulaOperationClauseBuilder from 'components/flowBuilder/formulaOperationClauseBuilder';
import SortOperationClauseBuilder from 'components/flowBuilder/sortOperationClauseBuilder';
import OperationFilterColumnSelect from 'components/dashboard/operationFIlterColumnSelect';

import {
  Button,
  Menu,
  MenuItem,
  Popover,
  Callout,
  Intent,
  EditableText,
  Icon,
  Tag,
  IconName,
  Spinner,
} from '@blueprintjs/core';
import { Table } from '@blueprintjs/table';

import {
  filterClauseComplete,
  sortClauseComplete,
  formulaClauseComplete,
  schemaClauseInstructionsComplete,
  joinClauseComplete,
  dedupClauseComplete,
} from 'utils/flowUtils';
import { safeScrollToColumn } from 'utils/general';

import ColumnHeaderText from 'components/dataTable/columnHeaderText';
import {
  OPERATION_TYPES,
  OPERATIONS_BY_ID,
  SCHEMA_DATA_TYPES_BY_ID,
  MAX_ROWS_TO_PREVIEW,
} from 'constants/flowConstants';
import { DatasetCacheReducerState } from 'reducers/datasetCacheReducer';
import { ThunkDispatch } from 'redux-thunk';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import {
  FilterOperationInstructions,
  FilterClause,
  ColumnToJoinOn,
  TableData,
  OperationInstructions,
  Schema,
  SortClause,
  AggregationColumnInfo,
} from 'constants/types';
import { Dataset } from 'actions/types';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      height: 366,
    },
    tableFooter: {
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      backgroundColor: theme.palette.grey.light,
      display: 'flex',
      justifyContent: 'space-between',
    },
    header: {
      padding: theme.spacing(3),
      borderBottom: '1px solid rgba(16, 22, 26, 0.15)',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    titleText: {
      fontWeight: 'bold',
      fontSize: 18,
      display: 'flex',
      alignItems: 'baseline',
      width: '100%',
    },
    rowCount: {
      padding: `0 ${theme.spacing(1)}px`,
      fontSize: 14,
      color: theme.palette.black,
      fontWeight: 300,
      display: 'flex',
      alignItems: 'center',
    },
    upToDate: {
      padding: `0 ${theme.spacing(1)}px`,
      fontSize: 14,
      color: theme.palette.white,
      fontWeight: 600,
      marginRight: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
    operationName: {
      marginRight: theme.spacing(2),
      whiteSpace: 'nowrap',
    },
    errorBody: {
      padding: theme.spacing(3),
    },
    tableColumnHeaderText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    tableColumnHeaderTypeIcon: {
      marginRight: theme.spacing(2),
      minWidth: 'unset',
    },
    endorsedIcon: {
      marginLeft: theme.spacing(1),
    },
    iconHeaderText: {
      overflow: 'hidden',
    },
    tableFooterLeft: {
      display: 'flex',
      flexDirection: 'row',
    },
    summaryContainer: {
      height: 350,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    summaryNameContainer: {
      // @ts-ignore
      color: theme.palette.grey.blueprintGrey,
      padding: theme.spacing(10),
      fontWeight: 'bold',
      fontSize: 60,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '80%',
    },
    opIconContainer: {
      width: 100,
      height: 100,
      //@ts-ignore
      backgroundColor: theme.palette.grey.blueprintGrey,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 16,
      marginRight: theme.spacing(10),
    },
    opIcon: {
      // @ts-ignore
      color: theme.palette.white,
    },
    opName: {},
    summaryActions: {
      // @ts-ignore
      borderLeft: `1px solid ${theme.palette.grey.border}`,
      width: '20%',
      height: '100%',
    },
    menuPopoverSummary: {
      height: '100%',
    },
    summaryActionContainer: {
      padding: theme.spacing(5),
      height: '50%',
      // @ts-ignore
      borderBottom: `1px solid ${theme.palette.grey.border}`,

      '&:last-of-type': {
        borderBottom: 'none',
      },
    },
    rowCountSpinner: {
      marginRight: theme.spacing(2),
    },
  });

type PassedProps = {
  summaryView?: boolean;
  computeLoading?: boolean;
  dashboardView?: boolean;
  datasetCacheData: DatasetCacheReducerState;
  dataTableLoading: boolean;
  flowId: number;
  lastStep: boolean;
  numRows: number;
  operationId: number;
  operationInstructions: OperationInstructions;
  operationType: string | number;
  previewData?: TableData;
  removeOperationFromDashboard?: () => void;
  resultDatasetSaved: boolean;
  resultSchema: Schema;
  resultsDataId: number;
  resultsJobError: any;
  sourceDataset: Dataset;
  sourceDataSourceId: number;
  title?: string;
  updateOperationInstructionAndCompute: (
    operationId: number,
    newInstructions: OperationInstructions,
    shouldCompute: boolean,
  ) => void;
  updateOperationTitle?: (newTitle: string) => void;
  upToDate: boolean;
  viewOnly?: boolean;
  refreshDashboardPanel?: (operationId: number) => void;
  editFiltersOn?: boolean;
  editingFilterType?: string;
  editingFilterCol?: { name: string; type: string; sourceType: string };
  onFilterColumnSelect: (colName: string, colType: string, sourceType: string) => void;
};

type Props = PassedProps &
  ReturnType<typeof mapDispatchToProps> &
  WithStyles<typeof styles> &
  RouteComponentProps;

class StepBuilderBlock extends React.Component<Props> {
  previewTableRef?: any = null;

  shouldComponentUpdate = (nextProps: Props) => {
    const unimportantProps = [
      'removeOperationFromDashboard',
      'updateOperationInstructionAndCompute',
      'updateOperationTitle',
    ];
    if (!_.isEqual(_.omit(this.props, unimportantProps), _.omit(nextProps, unimportantProps))) {
      return true;
    }
    return false;
  };

  render() {
    const { classes, summaryView, dashboardView } = this.props;

    if (dashboardView) {
      return this.renderDashboardView();
    }

    if (summaryView) {
      return this.renderSummaryView();
    }

    return (
      <div className={classes.root}>
        <div className={cx(classes.header)}>
          <div className={cx(classes.titleText)}>
            {this.renderOperationName()}
            {this.renderOperationInstructionSelection()}
          </div>
        </div>
        {this.renderOperationPreviewTable()}
      </div>
    );
  }

  renderSummaryView = () => {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.summaryContainer}>
          {this.renderOperationNameSummary()}
          <div className={classes.summaryActions}>
            <div className={classes.summaryActionContainer}>
              {this.renderOperationInstructionSelection()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderDashboardView = () => {
    const { classes, editFiltersOn } = this.props;

    return (
      <div className={classes.root}>
        <div className={cx(classes.header)}>
          <div className={cx(classes.titleText)}>{this.renderEditableOperationTitle()}</div>
          {this.renderDashboardMenu()}
        </div>
        {!editFiltersOn && this.renderOperationPreviewTable()}
        {editFiltersOn && this.renderFilterColumnSelect()}
      </div>
    );
  };

  renderFilterColumnSelect = () => {
    const {
      operationId,
      resultSchema,
      editingFilterType,
      onFilterColumnSelect,
      editingFilterCol,
    } = this.props;
    if (!resultSchema) return;

    return (
      <OperationFilterColumnSelect
        operationId={operationId}
        resultSchema={resultSchema}
        editingFilterType={editingFilterType}
        editingFilterCol={editingFilterCol}
        onFilterColumnSelect={onFilterColumnSelect}
      />
    );
  };

  renderOperationNameSummary = () => {
    const { classes, operationType } = this.props;
    const opInfo = OPERATIONS_BY_ID[operationType];
    return (
      <div className={classes.summaryNameContainer}>
        <div className={classes.opIconContainer}>
          <Icon icon={opInfo.icon as any} className={classes.opIcon} iconSize={50} />
        </div>
        <div className={classes.opName}>{opInfo.name}</div>
      </div>
    );
  };

  renderCustomNameRenderer = (header: string, index: number) => {
    const { classes, operationType, operationInstructions, resultSchema } = this.props;

    switch (operationType) {
      case OPERATION_TYPES.UNION.id:
        if (
          index < operationInstructions.columnsToUnionOn.length &&
          operationInstructions.columnsToUnionOn[index].sourceColumn &&
          operationInstructions.columnsToUnionOn[index].unionColumn
        ) {
          return (
            <div className={cx('bp3-table-truncated-text', classes.tableColumnHeaderText)}>
              <div className={classes.iconHeaderText}>
                <Tag
                  className={classes.tableColumnHeaderTypeIcon}
                  icon={SCHEMA_DATA_TYPES_BY_ID[resultSchema[index].type].icon as IconName}
                  minimal={true}></Tag>
                {`${header}`}
              </div>
              <Icon className={classes.endorsedIcon} intent={Intent.PRIMARY} icon="endorsed" />
            </div>
          );
        } // eslint-disable-next-line no-fallthrough
      default:
        return <ColumnHeaderText headerList={resultSchema} index={index} header={header} />;
    }
  };

  renderOperationPreviewTable = () => {
    const {
      classes,
      previewData,
      resultSchema,
      numRows,
      dataTableLoading,
      resultsJobError,
      operationType,
      computeLoading,
      upToDate,
    } = this.props;
    const DataTable = !previewData ? LoadingDataTable : BaseDataTable;

    if (resultsJobError) {
      return (
        <div className={classes.errorBody}>
          <Callout
            intent={Intent.DANGER}
            icon="error"
            title="An error occured while computing the results">
            {/* {resultsJobError.message} */}
            Check that the columns being used have not changed. If this problem continues to exist,
            please reach out to support@explo.co.
          </Callout>
        </div>
      );
    }

    if (dataTableLoading || previewData) {
      return (
        <>
          <div>
            <DataTable
              loading={!previewData || computeLoading}
              rows={previewData || []}
              headerList={resultSchema}
              maxRows={MAX_ROWS_TO_PREVIEW}
              selectedColumns={this.getSelectedPreviewCols()}
              setTableRef={(ref: Table) => (this.previewTableRef = ref)}
              renderCustomNameRenderer={computeLoading ? null : this.renderCustomNameRenderer}
            />
          </div>
          <div className={classes.tableFooter}>
            <div className={classes.tableFooterLeft}>
              {upToDate ? (
                <div></div>
              ) : (
                <Tag className={classes.upToDate} intent={Intent.NONE}>
                  Out of Date
                </Tag>
              )}
              {operationType === OPERATION_TYPES.UNION.id ? (
                <div className={classes.rowCount}>
                  <Icon intent={Intent.PRIMARY} icon="endorsed" />
                  {` means that the column matched`}
                </div>
              ) : null}
            </div>
            {this.renderRowCount(numRows, computeLoading)}
          </div>
        </>
      );
    }
  };

  renderRowCount = (numRows: number, loading?: boolean) => {
    const { classes } = this.props;

    if (loading || numRows === null || numRows === undefined) {
      return (
        <div className={classes.rowCount}>
          <Spinner size={14} className={classes.rowCountSpinner} /> total rows
        </div>
      );
    }

    return (
      <div className={classes.rowCount}>
        {numRows && numRows.toLocaleString()} total rows{' '}
        {numRows > MAX_ROWS_TO_PREVIEW ? ` (previewing ${MAX_ROWS_TO_PREVIEW} rows)` : ''}
      </div>
    );
  };

  renderDashboardMenu = () => {
    const {
      flowId,
      removeOperationFromDashboard,
      history,
      viewOnly,
      operationId,
      refreshDashboardPanel,
    } = this.props;
    if (viewOnly) {
      return <Button icon="more" disabled={true} />;
    }

    return (
      <Popover>
        <Button icon="more" />

        <Menu key="menu">
          <MenuItem
            icon="refresh"
            text="Refresh Table"
            onClick={() => refreshDashboardPanel && refreshDashboardPanel(operationId)}
          />
          <MenuItem
            icon="data-lineage"
            text="View Exploration"
            onClick={() => {
              history.push(`/flows/${flowId}`);
            }}
          />

          <MenuItem
            icon="delete"
            text="Remove from Dashboard"
            onClick={removeOperationFromDashboard}
          />
        </Menu>
      </Popover>
    );
  };

  renderOperationName = () => {
    const { classes, operationType } = this.props;
    return <div className={cx(classes.operationName)}>{OPERATIONS_BY_ID[operationType].name}</div>;
  };

  renderEditableOperationTitle = () => {
    const { updateOperationTitle, title, viewOnly } = this.props;
    if (!updateOperationTitle) {
      throw new Error(
        'Error: If using StepBuilderBlock as a dashboard block, then updateOperationTitle must be passed in to the props',
      );
    }
    return (
      <EditableText
        placeholder="Edit Title..."
        selectAllOnFocus={true}
        onConfirm={(newTitle) => updateOperationTitle(newTitle)}
        defaultValue={title || ''}
        disabled={viewOnly}
      />
    );
  };

  renderOperationInstructionSelection = () => {
    const {
      summaryView,
      operationId,
      operationType,
      computeLoading,
      clearRecentlyAddedOpId,
      operationInstructions,
      updateOperationInstructionAndCompute,
      sourceDataset,
    } = this.props;

    const sharedProps = {
      clearRecentlyAddedOpId: clearRecentlyAddedOpId,
      computeLoading: computeLoading,
      instructions: operationInstructions,
      operationId: operationId,
      sourceDataset: sourceDataset,
      scrollToColumn: this.safeScrollToColumn,
      updateOperationInstructionAndCompute: updateOperationInstructionAndCompute,
      summaryView,
    };
    switch (operationType) {
      case OPERATION_TYPES.FILTER.id:
        const matchOnAll = Array.isArray(operationInstructions)
          ? true
          : operationInstructions.matchOnAll;
        const filterClauses: FilterClause[] = Array.isArray(operationInstructions)
          ? operationInstructions
          : operationInstructions.filterClauses;

        const formattedInstructions: FilterOperationInstructions = {
          matchOnAll: matchOnAll,
          filterClauses: filterClauses,
        };
        return (
          <FilterOperationClauseBuilder {...sharedProps} instructions={formattedInstructions} />
        );
      case OPERATION_TYPES.FORMULA.id:
        return <FormulaOperationClauseBuilder {...sharedProps} />;
      case OPERATION_TYPES.ENRICH.id:
        if (!(operationInstructions.joinTable && operationInstructions.joinTable.datasetId)) {
          operationInstructions.joinTable = {
            flowId: '',
            operationId: '',
            datasetId: '',
            name: '',
          };
        }
        return (
          <JoinOperationClauseBuilder
            {...sharedProps}
            datasetCacheData={this.props.datasetCacheData}
            flowId={this.props.flowId}
            sourceDataSourceId={this.props.sourceDataSourceId}
          />
        );
      case OPERATION_TYPES.UNION.id:
        return (
          <UnionOperationClauseBuilder
            {...sharedProps}
            datasetCacheData={this.props.datasetCacheData}
            flowId={this.props.flowId}
            sourceDataSourceId={this.props.sourceDataSourceId}
          />
        );
      case OPERATION_TYPES.CALCULATE.id:
        return <CalculateOperationClauseBuilder {...sharedProps} />;
      case OPERATION_TYPES.PIVOT.id:
        return <PivotOperationClauseBuilder {...sharedProps} />;
      case OPERATION_TYPES.CHANGE_SCHEMA.id:
        return <ChangeSchemaOperationClauseBuilder {...sharedProps} />;
      case OPERATION_TYPES.SORT.id:
        return <SortOperationClauseBuilder {...sharedProps} />;
      case OPERATION_TYPES.DEDUP.id:
        return <DedupOperationClauseBuilder {...sharedProps} />;
      default:
        return 'Error';
    }
  };

  getSelectedPreviewCols = () => {
    const { operationType, operationInstructions } = this.props;

    const selectedCols: Set<string> = new Set([]);
    if (operationType === OPERATION_TYPES.FILTER.id) {
      const filterClauses: FilterClause[] = Array.isArray(operationInstructions)
        ? operationInstructions
        : operationInstructions.filterClauses;
      _.each(filterClauses, (clause: any) => {
        if (filterClauseComplete(clause)) {
          selectedCols.add(clause.filterColumn.name);
        }
      });
    } else if (operationType === OPERATION_TYPES.SORT.id) {
      _.each(operationInstructions.sortColumns, (clause: SortClause) => {
        if (sortClauseComplete(clause)) {
          selectedCols.add(clause.column!.name);
        }
      });
    } else if (operationType === OPERATION_TYPES.FORMULA.id) {
      _.each(operationInstructions, (clause: any) => {
        if (formulaClauseComplete(clause)) {
          selectedCols.add(clause.newColName);
        }
      });
    } else if (operationType === OPERATION_TYPES.CHANGE_SCHEMA.id) {
      const schemaChanges: any[] = operationInstructions.changeSchemaList.filter(
        schemaClauseInstructionsComplete,
      );
      _.each(schemaChanges, (change: any) => {
        if (!('keepCol' in change && change['keepCol'] === false)) {
          selectedCols.add(change['newColName'] || change['col']);
        }
      });
    } else if (operationType === OPERATION_TYPES.ENRICH.id) {
      _.each(operationInstructions.columnsToJoinOn, (joinClause: ColumnToJoinOn) => {
        if (joinClauseComplete(joinClause)) {
          selectedCols.add(joinClause.sourceColumn);
          selectedCols.add(`${joinClause.joinColumn}_0`);
        }
      });
    } else if (operationType === OPERATION_TYPES.DEDUP.id) {
      _.each(operationInstructions.colsToDedup, (dedupClause: AggregationColumnInfo) => {
        if (dedupClauseComplete(dedupClause)) {
          selectedCols.add(dedupClause.name);
        }
      });
      _.each(operationInstructions.sortColumns, (sortClause: SortClause) => {
        if (sortClauseComplete(sortClause)) {
          selectedCols.add(sortClause.column!.name);
        }
      });
    }
    return selectedCols;
  };

  safeScrollToColumn = (columnName: string) => {
    const { resultSchema } = this.props;
    const columnIndex = _.pluck(resultSchema, 'name').indexOf(columnName);
    safeScrollToColumn(columnIndex, this.previewTableRef);
  };
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  clearRecentlyAddedOpId: () => dispatch(clearRecentlyAddedOpId()),
});

export default withRouter(
  connect(undefined, mapDispatchToProps)(withStyles(styles)(StepBuilderBlock)),
);

/** @format */

import React, { useEffect, useState } from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ReduxState } from 'reducers/rootReducer';
import { ControlGroup, Button, InputGroup, FormGroup, Intent, Divider } from '@blueprintjs/core';
import { AppToaster } from 'toaster';

import SetupFlowPage from 'components/pages/setupFlowPage';

import {
  updateSetupFlowStepKey,
  fetchDatabaseHostedSelectionList,
} from 'actions/onboardingActions';
import { logoutUser } from 'actions/authAction';
import { createTeam, addUserToTeam } from 'actions/authAction';
import { SetupFlowStepKey } from 'constants/setupPageConstants';
import { isUUID } from 'utils/general';

import { pageView } from 'analytics/exploAnalytics';
import { ROUTES } from 'constants/routes';

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & RouteComponentProps;

const JoinTeamPage = (props: Props) => {
  return (
    <SetupFlowPage
      showLogoutButton
      title="Join or create a team for your organization"
      pageBody={<JoinTeamPageBody {...props} />}
    />
  );
};

const JoinTeamPageBody = (props: Props) => {
  const {
    currentUser,
    updateSetupFlowStepKey,
    createTeam,
    addUserToTeam,
    fetchDatabaseHostedSelectionList,
  } = props;
  const [teamName, setTeamName] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  useEffect(() => {
    pageView('Join Team');
  }, []);

  const onCreateTeam = (event: any) => {
    createTeam(
      teamName,
      () => {
        updateSetupFlowStepKey(
          {
            postData: { updated_setup_flow_key: SetupFlowStepKey.POST_SETUP_FLOW_PAGE },
          },
          () => {
            // @ts-ignore
            window.location = ROUTES.HOME_APP_PAGE;
          },
        );
      },
      (errorMsg) => {
        AppToaster.show({
          icon: 'error',
          message: errorMsg,
          timeout: 10000,
          intent: Intent.DANGER,
        });
      },
    );

    event.preventDefault();
  };

  const onJoinTeam = (event: any) => {
    if (isUUID(inviteCode)) {
      addUserToTeam(
        currentUser.id!,
        inviteCode,
        () => {
          fetchDatabaseHostedSelectionList({}, (response: any) => {
            const setupFlowKey = _.contains(
              _.pluck(response.databases_hosted, 'connected_to_explo'),
              true,
            )
              ? SetupFlowStepKey.WELCOME_TO_EXPLO_DB_ALREADY_CONNECTED
              : SetupFlowStepKey.WELCOME_TO_EXPLO;
            updateSetupFlowStepKey(
              {
                postData: { updated_setup_flow_key: setupFlowKey },
              },
              () => {
                fetchDatabaseHostedSelectionList();
                // @ts-ignore
                window.location = ROUTES.SETUP;
              },
            );
          });
        },
        (errorMsg) => {
          AppToaster.show({
            icon: 'error',
            message:
              errorMsg && errorMsg.status === 403
                ? `You are already logged in with ${currentUser.email}. 
                  If this is the wrong account, please logout with the link in the top right.`
                : 'Incorrect invite code. Please ensure there are no spaces or tabs.',
            timeout: 10000,
            intent: Intent.DANGER,
          });
        },
      );
    } else {
      AppToaster.show({
        icon: 'error',
        message: 'Invalid Invite Code',
        timeout: 10000,
        intent: Intent.DANGER,
      });
    }

    event.preventDefault();
  };

  return (
    <div className="bp3-dark">
      <div className="flex-box center-horizontal">
        <FormGroup label="Join an existing team" labelFor="invite_code" inline={true}>
          <ControlGroup fill={true} vertical={false}>
            <InputGroup
              id="invite_code"
              onChange={(e: any) => setInviteCode(e.target.value)}
              name="invite_code"
              value={inviteCode}
              placeholder="Invite code"
              leftIcon="people"
            />
            <Button
              onClick={onJoinTeam}
              icon="arrow-right"
              disabled={inviteCode === '' || currentUser.createTeamLoading}
              loading={currentUser.joinTeamLoading}
            />
          </ControlGroup>
        </FormGroup>
      </div>

      <Divider />

      <div className="flex-box center-horizontal m-t-5">
        <FormGroup label="Create a new team" labelFor="team_name" inline={true}>
          <ControlGroup fill={true} vertical={false}>
            <InputGroup
              disabled={true}
              id="team_name"
              onChange={(e: any) => setTeamName(e.target.value)}
              name="team_name"
              value={teamName}
              placeholder="Team name"
              leftIcon="star"
            />
            <Button
              onClick={onCreateTeam}
              icon="arrow-right"
              disabled={teamName === '' || currentUser.joinTeamLoading}
              loading={currentUser.createTeamLoading}
            />
          </ControlGroup>
        </FormGroup>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  logoutUser,
  fetchDatabaseHostedSelectionList,
  updateSetupFlowStepKey,
  createTeam,
  addUserToTeam,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(JoinTeamPage));

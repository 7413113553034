/** @format */

import React, { Component } from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import InputWithName from 'components/dashboard/inputWithName';
import InputDateWithName from 'components/dashboard/inputDateWithName';
import InputWithNameAndOperator from 'components/dashboard/inputWithNameAndOperator';

import { DASHBOARD_FILTER_TYPES_BY_ID } from 'constants/dashboardConstants';

const styles = (theme: Theme) => ({});

type PassedProps = {
  type: string;
  name: string;
  value?: any;
  onFilterValueSubmit: (newValue: string) => void;
  onFilterOperatorSelect: (newOperator: any) => void;
  operator: any;
};

type Props = WithStyles<typeof styles> & PassedProps;

type State = {};

export class DashboardFilter extends Component<Props, State> {
  render = () => {
    const { type, name, value, onFilterValueSubmit, onFilterOperatorSelect, operator } = this.props;
    if (type === DASHBOARD_FILTER_TYPES_BY_ID.VALUES.id) {
      return (
        <InputWithName name={name} initialValue={value} onNewValueSubmitted={onFilterValueSubmit} />
      );
    } else if (type === DASHBOARD_FILTER_TYPES_BY_ID.DATE.id) {
      return (
        <InputDateWithName
          name={name}
          initialValue={value}
          onNewValueSubmitted={onFilterValueSubmit}
          onFilterOperatorSelect={onFilterOperatorSelect}
          operator={operator}
        />
      );
    } else if (type === DASHBOARD_FILTER_TYPES_BY_ID.NUMBERS.id) {
      return (
        <InputWithNameAndOperator
          name={name}
          initialValue={value}
          onNewValueSubmitted={onFilterValueSubmit}
          onFilterOperatorSelect={onFilterOperatorSelect}
          operator={operator}
        />
      );
    }
  };
}

export default withStyles(styles)(DashboardFilter);

/** @format */

import React from 'react';
import SetupFlowGenericDisplay from 'components/onboardingSetup/setupFlowGenericDisplay';
import {
  SETUP_FLOW_KEY_TO_PAGE_CONTENT,
  SetupFlowStepKey,
  SetupFlowPageContent,
} from 'constants/setupPageConstants';
import { ReduxState } from 'reducers/rootReducer';
import { connect } from 'react-redux';
import { updateSetupFlowStepKey } from 'actions/onboardingActions';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { withStyles, WithStyles } from '@material-ui/styles';
import ExploLogoWhite from 'images/branding/logo_primary.png';

import cx from 'classnames';

enum ButtonTypes {
  NEXT,
  SKIP,
  ALT,
  BACK,
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      height: '100vh',
      overflow: 'hidden',
    },
    sideBarContainer: {
      width: 250,
      height: '100vh',
      backgroundColor: theme.palette.grey900,
      boxShadow: theme.customShadows.sideNav,
      zIndex: 1,
    },
    mainBody: {
      width: '100%',
      height: '100vh',
      overflow: 'auto',
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    },
    logoImg: {
      width: 100,
      marginBottom: -5,
    },
  });

type MatchParams = {};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams> &
  WithStyles<typeof styles>;

class SetupPage extends React.Component<Props> {
  render() {
    let { setupFlowStepKey, setupPageLoading, classes } = this.props;

    if (process.env.NODE_ENV === 'development' && setupFlowStepKey === undefined) {
      setupFlowStepKey = SetupFlowStepKey.WELCOME_TO_EXPLO;
    }

    const setupFlowPageContent = SETUP_FLOW_KEY_TO_PAGE_CONTENT[setupFlowStepKey];

    return (
      <div className={classes.root}>
        <div className={cx(classes.sideBarContainer, 'bp3-dark')}>{this.renderLogoContainer()}</div>
        <div className={classes.mainBody}>
          <SetupFlowGenericDisplay
            header={setupFlowPageContent.header}
            subHeader={setupFlowPageContent.subHeader}
            nextButtonText={setupFlowPageContent.nextButtonText}
            isSkippingEnabled={!!setupFlowPageContent.skipButtonSetupFlowKey}
            isBackButtonEnabled={!!setupFlowPageContent.backButtonSetupFlowKey}
            renderMiddleContent={setupFlowPageContent.renderMiddleContent}
            onNextButtonPress={this.onNextButtonPress}
            onSkipButtonPress={this.onSkipButtonPress}
            onBackButtonPress={this.onBackButtonPress}
            altButtonText={setupFlowPageContent.altButtonText}
            renderContentAboveHeader={setupFlowPageContent.renderContentAboveHeader}
            onAltButtonPress={this.onAltButtonPress}
            setupPageLoading={setupPageLoading}
          />
        </div>
      </div>
    );
  }

  renderLogoContainer = () => {
    const { classes } = this.props;
    return (
      <div className={classes.logoContainer}>
        <img src={ExploLogoWhite} className={classes.logoImg} alt="explo logo" />
      </div>
    );
  };
  onNextButtonPress = () => {
    const { history } = this.props;
    this.transitionToNextPageInFlow(ButtonTypes.NEXT, (updatedSetupFlowKey: SetupFlowStepKey) => {
      return () => {
        if (updatedSetupFlowKey === SetupFlowStepKey.POST_SETUP_FLOW_PAGE) {
          history.push(`/home`);
        }
      };
    });
  };

  onSkipButtonPress = () => {
    this.transitionToNextPageInFlow(ButtonTypes.SKIP);
  };

  onAltButtonPress = () => {
    this.transitionToNextPageInFlow(ButtonTypes.ALT);
  };

  onBackButtonPress = () => {
    this.transitionToNextPageInFlow(ButtonTypes.BACK);
  };

  transitionToNextPageInFlow = (
    buttonType: ButtonTypes,
    onSuccess?: (updatedSetupFlowKey: SetupFlowStepKey) => () => void,
    onError?: () => void,
  ) => {
    let { updateSetupFlowStepKey, setupFlowStepKey } = this.props;
    if (process.env.NODE_ENV === 'development' && setupFlowStepKey === undefined) {
      setupFlowStepKey = SetupFlowStepKey.WELCOME_TO_EXPLO;
    }

    const setupFlowPageContent = SETUP_FLOW_KEY_TO_PAGE_CONTENT[setupFlowStepKey];
    const updatedSetupFlowKey = this.deriveFlowKeyFromButtonType(buttonType, setupFlowPageContent);

    if (updatedSetupFlowKey !== null) {
      updateSetupFlowStepKey(
        {
          postData: { updated_setup_flow_key: updatedSetupFlowKey },
        },
        onSuccess && onSuccess(updatedSetupFlowKey!),
        onError,
      );
    }
  };

  deriveFlowKeyFromButtonType = (
    buttonType: ButtonTypes,
    setupFlowPageContent: SetupFlowPageContent,
  ): SetupFlowStepKey | undefined => {
    switch (buttonType) {
      case ButtonTypes.ALT:
        return setupFlowPageContent.altButtonSetupFlowKey;
      case ButtonTypes.SKIP:
        return setupFlowPageContent.skipButtonSetupFlowKey;
      case ButtonTypes.NEXT:
        return setupFlowPageContent.nextButtonSetupFlowKey;
      case ButtonTypes.BACK:
        return setupFlowPageContent.backButtonSetupFlowKey;
      default:
        throw new Error('Button Type not Recognized');
    }
  };
}

const mapStateToProps = (state: ReduxState) => ({
  setupFlowStepKey: state.currentUser.setup_info!.setup_flow_step_key,
  setupPageLoading: state.onboardingData.setupPageLoading,
});

const mapDispatchToProps = {
  updateSetupFlowStepKey,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SetupPage));
